import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Text, Flex, Icon } from "../fundamentals";
import { Button } from "../elements";
import { Headings, UiLabels } from "../../localisation";
import { selectAllPaymentInvitesList } from "../../store/selectors";
import moment from "moment";
import {
  downloadPaymentInvites,
  sendPaymentInviteReminder,
  downloadPaymentInviteInvoice,
  updatePaymentInviteRequest,
  openModal,
} from "../../store/actions";
import { DEFAULT_PAYMENT_INVITE_STATUSES_SELLER } from "../../config";
import { Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

const StyledActionsMenu = styled(({ hoverBgColor, hoverColor, borderRadius, ...rest }) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...rest}
  />
))((props) => ({
  "& .MuiPaper-root": {
    borderRadius: props.borderRadius ?? "8px",
    backgroundColor: props.bg ? props.bg : "#5E54FF",
    color: props.bg ? "black" : "white",
    marginTop: "10px",
    width: props.width ? props.width : 180,
    "& .MuiMenu-list": {
      padding: "0 0",
    },
    "& .MuiMenuItem-root": {
      paddingLeft: props.pl ? props.pl : "25px",
      height: "48px",
      "&:hover": {
        backgroundColor: props.hoverBgColor ?? "#7274FF",
        color: props.hoverColor ?? "white",
      },
    },
  },
}));

const ContactsMenu = styled(({ hoverBgColor, hoverColor, borderRadius, ...rest }) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...rest}
  />
))((props) => ({
  "& .MuiPaper-root": {
    backgroundColor: "white",
    color: "black",
    marginTop: "5px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
    width: "fit-content",
    "& .MuiMenu-list": {
      padding: "0 0",
    },
    "& .MuiMenuItem-root": {
      "&:hover": {
        backgroundColor: "white",
      },
    },
  },
}));

export const ActivityArtworkSalesScreen = ({ headerTextStyle }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  // In order to dynamically render the actions menu options, we need to track the index of the menu that should open every time (https://stackoverflow.com/a/67211346/18711161)
  const [actionsMenuIndex, setActionsMenuIndex] = useState(-1);
  const [contactsAnchorEl, setContactsAnchorEl] = useState(null);
  const [isContactsShown, setIsContactsShown] = useState(false);
  const paymentInvites = useSelector(selectAllPaymentInvitesList);
  const paymentInviteState = useSelector((state) => state.payment_invite);

  const displayContactLabel = (paymentInvite, contactIndex) => {
    const currentContact = paymentInvite?.contacts[contactIndex]?._id;
    let label = "";
    if (!currentContact) {
      label = "Loading...";
    } else if (currentContact?.organisation && !currentContact?.firstName && !currentContact?.lastName) {
      label = currentContact?.organisation;
    } else if (currentContact?.firstName && !currentContact?.lastName) {
      label = currentContact?.firstName;
    } else if (currentContact?.firstName && currentContact?.lastName) {
      label = `${currentContact?.firstName} ${currentContact?.lastName}`;
    } else if (!currentContact?.firstName && currentContact?.lastName) {
      label = currentContact?.lastName;
    } else {
      label = currentContact?.email;
    }
    return label;
  };

  const handleOpenContacts = (e) => {
    setIsContactsShown(true);
    setContactsAnchorEl(e.currentTarget);
  };

  const handleCloseContacts = (e) => {
    setIsContactsShown(false);
    setContactsAnchorEl(null);
  };

  const getStatusName = (statusArray) => {
    if (
      statusArray?.length > 1 &&
      statusArray[statusArray.length - 1]?.status_name === "Viewed" &&
      statusArray[statusArray.length - 2]?.status_name !== "Offer"
    ) {
      return statusArray[statusArray.length - 2]?.status_name;
    } else {
      return statusArray.find((statusItem) => statusItem.is_latest)?.status_name;
    }
  };

  const handleActionsMenuOpen = (index, event) => {
    setAnchorEl(event.currentTarget);
    setActionsMenuIndex(index);
  };

  const handleActionClick = (actionId, paymentInvite) => {
    if (actionId === "downloadInvoice") {
      dispatch(downloadPaymentInviteInvoice(paymentInvite));
    } else if (actionId === "sendReminder") {
      dispatch(sendPaymentInviteReminder(paymentInvite._id));
    } else if (actionId === "markAsInTransit") {
      dispatch(openModal("paymentInviteInTransit", { paymentInviteId: paymentInvite._id }));
    } else if (actionId === "duplicate") {
      dispatch(updatePaymentInviteRequest({ paymentInviteId: paymentInvite._id, status: "Duplicate" }));
    } else if (actionId === "contactUs") {
      dispatch(openModal("contactUs"));
    } else if (actionId === "seeOffer") {
      dispatch(openModal("payment_invite.seller.see_offer", { paymentInviteId: paymentInvite._id }));
    } else if (actionId === "seeEnquiry") {
      dispatch(openModal("payment_invite.seller.see_enquiry", { paymentInviteId: paymentInvite._id }));
    }
    setAnchorEl(null);
  };

  useEffect(() => {
      dispatch(downloadPaymentInvites());
  }, []);

  return (
    <Box>
      <Text style={{ ...headerTextStyle, fontSize: "26px" }} mb="xxxl">
        {Headings.artworkSales}
      </Text>
      {paymentInviteState.isLoading ? (
        <Flex justifyContent="center" alignItems="center" pb="mega" height="100vh">
          <CircularProgress sx={{ color: "#5E54FF" }} />
        </Flex>
      ) : (
        // !paymentInvites.length ? (
        //   <Box>
        //     <Text mb="m">
        //       You have no Payment Invites. You can create your first Payment Invite by selecting one or multiple artworks.
        //     </Text>
        //     <Button buttonStyle="primary" size="medium" onClick={() => history.push("/artworks")}>
        //       {ButtonLabels.goToArtworks}
        //     </Button>
        //   </Box>
        <Box>
          <Flex justifyContent="space-between" fontSize="smaller" color="#2B2A2E" pl="l" pr="l" mb="xs">
            <Text width="90px">{UiLabels.dateGenerated}</Text>
            <Text width="180px">{UiLabels.artistNames}</Text>
            <Text width="180px">{UiLabels.artworkTitles}</Text>
            <Text width="170px">{UiLabels.recipients}</Text>
            <Text width="145px">{UiLabels.status}</Text>
            <Box width="28px"></Box>
          </Flex>
          {paymentInvites.map((paymentInvite, index) => (
            <Box
              fontSize="small"
              p="36px 32px"
              border="1px solid #C3C2C9"
              borderRadius="8px"
              mb={index !== paymentInvites.length - 1 ? "xs" : null}
              key={index}
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Text width="90px">{moment(paymentInvite?.dateCreated).format("DD/MM/YY")}</Text>
                <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" width="180px">
                  {paymentInvite?.artworks
                    .map((artwork) => (artwork._id?.artist?.name ? artwork._id.artist.name : "Loading..."))
                    .join(", ")}
                </Text>
                <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" width="180px">
                  {paymentInvite?.artworks
                    .map((artwork) =>
                      artwork._id?.artworkValues?.title ? artwork._id.artworkValues.title : "Loading..."
                    )
                    .join(", ")}
                </Text>
                <Flex width="170px" alignItems="center">
                  <Box p="4px 8px" background="#DBDADF" borderRadius="100px">
                    <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth="135px">
                      {displayContactLabel(paymentInvite, 0)}
                    </Text>
                  </Box>
                  {paymentInvite?.contacts.length > 1 && (
                    <Button
                      buttonStyle="text"
                      alignSelf="center"
                      onClick={isContactsShown ? handleCloseContacts : handleOpenContacts}
                    >
                      <Icon
                        color="grey.80"
                        cursor="pointer"
                        size="18"
                        icon={isContactsShown ? "ChevronUp" : "ChevronDown"}
                      ></Icon>
                    </Button>
                  )}
                </Flex>
                {paymentInvite?.contacts.length > 1 && (
                  <ContactsMenu
                    anchorEl={contactsAnchorEl}
                    open={Boolean(contactsAnchorEl)}
                    onClose={handleCloseContacts}
                  >
                    {paymentInvite?.contacts.map(
                      (contact, index) =>
                        index > 0 && (
                          <MenuItem disableRipple key={contact}>
                            <Box p="4px 8px" background="#DBDADF" borderRadius="100px" fontSize="small">
                              <Text>{displayContactLabel(paymentInvite, index)}</Text>
                            </Box>
                          </MenuItem>
                        )
                    )}
                  </ContactsMenu>
                )}
                <Box width="145px">
                  <Box
                    background={DEFAULT_PAYMENT_INVITE_STATUSES_SELLER[getStatusName(paymentInvite?.status)]?.color}
                    p="4px 12px"
                    borderRadius="4px"
                    width="fit-content"
                  >
                    <Text>{DEFAULT_PAYMENT_INVITE_STATUSES_SELLER[getStatusName(paymentInvite.status)]?.label}</Text>
                  </Box>
                </Box>
                <Button
                  buttonStyle="primary"
                  width="28px"
                  height="28px"
                  onClick={(event) => handleActionsMenuOpen(index, event)}
                  disabled={
                    paymentInvite?.hasOwnProperty("status") &&
                    paymentInvite?.status.filter(
                      (status) => status.status_name === "Duplicate" || status.status_name === "EnquiryAnswered"
                    ).length > 0
                  }
                >
                  <Icon icon="MoreHorizontal" size="small" cursor="pointer"></Icon>
                </Button>
                <StyledActionsMenu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && index === actionsMenuIndex}
                  onClose={() => setAnchorEl(null)}
                >
                  {/* Show the action options available depending on the status */}
                  {DEFAULT_PAYMENT_INVITE_STATUSES_SELLER[getStatusName(paymentInvite?.status)]?.actions.map(
                    (action) => (
                      <MenuItem
                        disableRipple
                        key={action.id}
                        onClick={() => handleActionClick(action.id, paymentInvite)}
                      >
                        {action.label}
                      </MenuItem>
                    )
                  )}
                </StyledActionsMenu>
              </Flex>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
