import {
  UPDATE_SEARCH_TERM,
  UPDATE_SEARCH_TERM_CONTACT,
  UPDATE_SORT_BY_OPTION,
  UPDATE_SEARCH_TERM_ARTIST,
  UPDATE_SEARCH_TERM_LIST,
  UPDATE_SEARCH_TERM_VIEWING_ROOM,
} from "./types";

export const updateSearchTerm = (searchTerm) => {
  return { type: UPDATE_SEARCH_TERM, payload: { searchTerm } };
};

export const updateSearchTermContact = (searchTerm) => {
  return { type: UPDATE_SEARCH_TERM_CONTACT, payload: { searchTerm } };
};

export const updateSortByOption = (sortBy) => {
  return { type: UPDATE_SORT_BY_OPTION, payload: { sortBy } };
};

export const updateSearchTermArtist = (searchTerm) => {
  return { type: UPDATE_SEARCH_TERM_ARTIST, payload: { searchTerm } };
};

export const updateSearchTermList = (searchTerm) => {
  return { type: UPDATE_SEARCH_TERM_LIST, payload: { searchTerm } };
};

export const updateSearchTermViewingRoom = (searchTerm) => {
  return { type: UPDATE_SEARCH_TERM_VIEWING_ROOM, payload: { searchTerm } };
};