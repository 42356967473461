import React from "react";
import styled from "styled-components";

import { Instructions, LinkLabels } from "../../localisation";

import { Box, Flex, Image, Text } from "../fundamentals";
import { Button } from "../elements";
import { LoginForm } from "../forms";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const StyledLoginScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const LoginScreen = (props) => {
  const isMobile = useIsScreenMobile();
  const history = useHistory();
  const prevLocation = props.location.state?.from;
  return (
    <StyledLoginScreen bg="white">
      <Helmet>
        <title>Sign In to Your Account | simplify.art</title>
        <meta name="description" content="Sign in to your simplify.art account to access your collection" />
      </Helmet>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Image
          src={
            process.env.PUBLIC_URL +
            `/images/brand-identity/${isMobile ? "logo-icon-black.svg" : "logo_horizontal-black.svg"}`
          }
          alt="logo"
          width={isMobile ? "25px" : "184px"}
          height={isMobile ? "35px" : ""}
          p={isMobile ? "35px 30px" : "35px 50px"}
        />

        <Box display="flex" alignItems="center" mr={isMobile ? "l" : "xxxl"}>
          {!isMobile && (
            <Text size="heading3" mr="s">
              {Instructions.noAccount}
            </Text>
          )}
          <Button
            buttonStyle="default"
            type="button"
            size={isMobile ? "small" : "medium"}
            fontSize={isMobile ? "13px" : "20px"}
            bg="white"
            p={isMobile ? "5px 12px" : "8.5px 20px"}
            fontWeight="700"
            onClick={(e) => {
              e.preventDefault();
              history.push("/register", {from: prevLocation});
            }}
          >
            {LinkLabels.signup}
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" pt="mega">
        <Box
          width={isMobile ? "325px" : "500px"}
          height={isMobile ? "280px" : "401px"}
          bg="white"
          p={isMobile ? "m" : "60px 60px 35px 60px"}
          mt={isMobile ? "l" : "xxl"}
          boxShadow="mediumLarge"
          borderRadius="15px"
        >
          <LoginForm prevLocation={prevLocation}/>
        </Box>
      </Box>
    </StyledLoginScreen>
  );
};

export default LoginScreen;
