import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { WithRequireAccess } from "../hocs";
import { Headings, Messages, LinkLabels, Instructions } from "../../localisation";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Typography } from "@mui/material";

import { Box, Flex, Text, Image, Link } from "../fundamentals";
import { Button } from "../elements";

const StyledWelcomeLayout = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const accountExistStyle = {
  fontFamily: "DM Sans",
  fontSize: "20px",
  lineHeight: "26px",
  fontWeight: "400",
  marginRight: "20px",
};

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "32px",
    lineHeight: mobile ? "26px" : "40px",
    marginBottom: "24px",
    fontWeight: "400",
    textAlign: "center",
  };
};

const WelcomeScreen = () => {
  const history = useHistory();
  const isMobile = useIsScreenMobile();

  useEffect(() => {
    setTimeout(() => history.replace("/artworks"), 5000);
  }, []);

  return (
    <StyledWelcomeLayout bg="white">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Image
          src={
            process.env.PUBLIC_URL +
            `/images/brand-identity/${isMobile ? "logo-icon-black.svg" : "logo_horizontal-black.svg"}`
          }
          alt="logo"
          width={isMobile ? "25px" : "184px"}
          height={isMobile ? "35px" : ""}
          p={isMobile ? "35px 30px" : "35px 50px"}
        />

        <Box display="flex" alignItems="center" mr={isMobile ? "l" : "xxxl"}>
          {!isMobile && <Typography sx={accountExistStyle}>{Instructions.accountExists}</Typography>}

          <Button
            buttonStyle="default"
            type="button"
            size={isMobile ? "small" : "medium"}
            fontSize={isMobile ? "13px" : "20px"}
            bg="white"
            p={isMobile ? "5px 12px" : "8.5px 20px"}
            fontWeight="700"
            onClick={(e) => {
              e.preventDefault();
              history.push("/login");
            }}
          >
            {LinkLabels.signinText}
          </Button>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" pt="mega">
        <Box
          width={isMobile ? "325px" : "500px"}
          bg="white"
          p={isMobile ? "m" : "56px 94px"}
          mt={isMobile ? "l" : "xxl"}
          boxShadow="mediumLarge"
          borderRadius="15px"
        >
          <Typography sx={headerTextStyle(isMobile)}>{Headings.accountCreated}</Typography>
          <Text fontFamily="bodyDmSans" fontSize={isMobile ? "body" : "heading3"} textAlign="center" mb="m">
            {Messages.welcomeRedirectText}
          </Text>
          <Text fontFamily="bodyDmSans" fontSize={isMobile ? "body" : "heading3"} textAlign="center">
            {Messages.welcomeNotRedirectedText}
            <Link to="/artworks" replace>
              {LinkLabels.here}
            </Link>
          </Text>
        </Box>
      </Box>
    </StyledWelcomeLayout>
  );
};

export default WithRequireAccess(WelcomeScreen, 0);
