import _ from "lodash";
import { createSelector } from "reselect";
import { selectSearch } from "./searchSelectors";

export const selectArtworks = (state) => state.discovery.artworks;

export const selectEvents = (state) => state.discovery.events;

export const selectEventFilters = (state) => state.discovery.filters.events;

export const selectEventId = (state, eventId) => eventId;
export const selectEventFilterIds = (state, eventFilterIds) => eventFilterIds;

export const selectRecommendationEngine = (state) => state.discovery.recommendationId;

export const selectDiscoveryLoad = createSelector(
  selectArtworks,
  selectRecommendationEngine,
  (artworks, recommendationId) => {
    return { artworks: artworks, recommendationId: recommendationId };
  }
);

export const selectEventById = createSelector(selectEvents, selectEventId, (events, eventId) => {
  return events.filter((event) => event._id === eventId);
});

export const selectEventFiltersById = createSelector(selectEvents, selectEventFilterIds, (events, eventFilterIds) => {
  return events.filter(event => eventFilterIds.includes(event._id));
});

