import { useEffect, useState } from "react";
import { WithRequireAccess } from "../hocs";
import { NavigationBar } from "../react-material/NavigationBar";
import { Headings, ButtonLabels, Messages } from "../../localisation";
import { Typography } from "@mui/material";
import { Button } from "../elements";
import { useHistory } from "react-router-dom";
import { Flex, Box, Text, Link } from "../fundamentals";
import { selectViewingRoomById } from "../../store/selectors";
import { useSelector } from "react-redux";
import { QRCode } from "../elements";

const ViewingRoomLinkScreen = (props) => {
  const history = useHistory();
  const [isCopyButtonClicked, setIsCopyButtonClicked] = useState(false);
  const viewingRoomId = props?.location?.state?.viewingRoomId;
  const wasViewingRoomDraft = props?.location?.state?.wasViewingRoomDraft;
  const viewingRoomUrl = `${process.env.REACT_APP_CLIENT_URL}/viewingroom?viewingRoomId=${viewingRoomId}`;
  const actionType = props?.location?.state?.actionType;
  const viewingRoom = useSelector((state) => selectViewingRoomById(state, viewingRoomId));

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(viewingRoomUrl);
    setIsCopyButtonClicked(true);
    setTimeout(() => setIsCopyButtonClicked(false), 3000);
  };

  useEffect(() => {
    if (!viewingRoomId) {
      history.push("/artworks");
    }
  }, []);

  return (
    <Flex flexDirection="column" width="100%" margin="0 auto" position="relative">
      <Box maxWidth="98%">
        <NavigationBar />
      </Box>
      <Flex flexDirection="column" backgroundColor="white" position="absolute" bottom="0" top="80px" width="100vw">
        <Box pl="xxl" pr="xxl" pt="m" pb="m">
          <Button buttonStyle="formBack" size="small" icon="ArrowLeft" pl="0" onClick={() => history.push("/artworks")}>
            {ButtonLabels.backToArtworks}
          </Button>
          <Typography variant="h2">{Headings.viewingRoomLink}</Typography>
        </Box>
        <Box p="l" borderRadius="7px" border="1px solid #C3C2C9" alignSelf="center" mt="xxxl">
          <Text fontSize="20px" mb="xxs">
            {actionType?.includes("EDIT") && !wasViewingRoomDraft
              ? Headings.viewingRoomUpdated
              : Headings.viewingRoomCreated}
          </Text>
          <Text fontSize="small" color="grey.80" mb="xl">
            {Messages.viewingRoomPreviewAndShare}
          </Text>
          <Flex alignItems="center">
            <Box mr="xxs" border="1px solid #9AA1FF" borderRadius="8px" padding="8px 12px">
              <Text
                fontSize="small"
                color="blue.40"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                maxWidth="40ch"
              >
                {viewingRoomUrl}
              </Text>
            </Box>
            <Link href={viewingRoomUrl} target="_blank">
              <Button mr="xxs" buttonStyle="secondary" size="medium" fontWeight="500">
                {ButtonLabels.view}
              </Button>
            </Link>
            <Button
              buttonStyle="primary"
              size="medium"
              fontWeight="500"
              disabled={isCopyButtonClicked}
              icon={isCopyButtonClicked ? "Check" : null}
              flip={true}
              iconOffset="xxs"
              onClick={handleCopyButtonClick}
            >
              {isCopyButtonClicked ? ButtonLabels.linkCopied : ButtonLabels.copyLink}
            </Button>
          </Flex>
          <Box backgroundColor="grey.10" borderRadius="7px" mt="m" mb="xl" p="s">
            <QRCode value={viewingRoomUrl} imageTitle={viewingRoom?.title} isDownloadable={true} />
          </Box>
          <Button buttonStyle="secondary" size="medium" onClick={() => history.push("./artworks")}>
            {ButtonLabels.done}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default WithRequireAccess(ViewingRoomLinkScreen, 1);
