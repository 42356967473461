import { replace } from "connected-react-router";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { SimplifyApi, createToast } from "../../utils";
import { addActionToQueue, addSelectedList } from "../actions";
import {
  createListLocally,
  createListSuccess,
  createListFailure,
  getListsSuccess,
  getListsFailure,
  deleteList,
  deleteListFailure,
  deleteListSuccess,
  editListFailure,
  editListLocally,
  editListSuccess,
  downloadListPdf,
} from "../actions";
import {
  CREATE_LIST_REQUEST,
  GET_LISTS_REQUEST,
  EDIT_LIST_REQUEST,
  DELETE_LISTS,
  DOWNLOAD_LIST_PDF,
} from "../actions/types";
import { selectListById } from "../selectors/listSelector";
import { Notifications } from "../../localisation";
import { saveAs } from "file-saver";
import moment from "moment";

//SERVICES
const createListService = (artworks, category, title, fieldTypes, listValues) => {
  return SimplifyApi.post("/v1/exported_documents", { artworks, category, title, fieldTypes, ...listValues });
};

const getListsService = () => {
  return SimplifyApi.get("/v1/exported_documents");
};

const editListService = (list) => SimplifyApi.patch("/v1/exported_documents", { ...list });

const deleteListService = (exportedDocumentId) => {
  return SimplifyApi.delete(`/v1/exported_documents/${exportedDocumentId}`);
};

//SAGAS
export function* createListSaga(action) {
  const { payload } = action;
  const { artworks, category, title, tempListId, fieldTypes, ...listValues } = payload;
  try {
    let list;

    list = yield select((state) => selectListById(state, tempListId));

    if (!list) {
      yield put(createListLocally(payload));

      yield all([put(addSelectedList(tempListId))]);
      list = yield select((state) => selectListById(state, tempListId));
    }
    const response = yield call(createListService, artworks, category, title, fieldTypes, listValues);

    yield put(createListSuccess(tempListId, response.data));
  } catch (error) {
    yield put(createListFailure());
    throw error;
  }
}

function* getListsSaga() {
  try {
    const response = yield call(getListsService);
    yield put(getListsSuccess(response.data.exportedDocuments));
  } catch (error) {
    yield put(getListsFailure());
    throw error;
  }
}

export function* editListSaga(action) {
  try {
    const { isDownload, isNewList, ...restPayload } = action.payload;
    yield put(editListLocally(restPayload));

    const list = yield select((state) => selectListById(state, restPayload.id));

    if (list.isFromServer) {
      const response = yield call(editListService, { exportedDocument: list });

      yield put(editListSuccess(list._id, response.data));
      if (isDownload) {
        const editedList = yield select((state) => selectListById(state, list._id));
        yield put(downloadListPdf(editedList));
      }
    }
    createToast({ type: "success", ...Notifications.listUpdateSuccess });
  } catch (error) {
    createToast({ type: "error", ...Notifications.generic });
    yield put(editListFailure());
    throw error;
  }
}

function* deleteListsSaga(action) {
  const { listIds } = action.payload;

  try {
    for (const listId of listIds) {
      yield put(addActionToQueue(deleteList(listId), listId));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteListSaga(payload) {
  const { listId } = payload;
  try {
    const listForDelete = yield select((state) => selectListById(state, listId));

    if (listForDelete.isFromServer) {
      yield call(deleteListService, listId);
    }

    yield put(deleteListSuccess(listId));
    yield put(replace("/lists"));
  } catch (error) {
    yield put(deleteListFailure(listId));
    throw error;
  }
}

function* downloadListPdfSaga(action) {
  const list = action.payload;
  let path;
  if (list.path.includes("simplify.art")) {
    path = list.path.split("simplify.art/")[1];
  } else {
    path = list.path;
  }
  try {
    yield call(
      saveAs,
      `${process.env.REACT_APP_S3_URL}${path}`,
      `${list.category}-${moment(new Date()).format("DD/MM/YYYY")}`
    );
  } catch (error) {
    createToast({ type: "error", ...Notifications.generic });
    throw error;
  }
}

function* listSaga() {
  yield takeEvery(GET_LISTS_REQUEST, getListsSaga);
  yield takeEvery(CREATE_LIST_REQUEST, createListSaga);
  yield takeEvery(EDIT_LIST_REQUEST, editListSaga);
  yield takeEvery(DELETE_LISTS, deleteListsSaga);
  yield takeEvery(DOWNLOAD_LIST_PDF, downloadListPdfSaga);
}

export default listSaga;
