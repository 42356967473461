import React, { useEffect } from "react";
import { Box, Typography, Stack, InputBase, InputAdornment, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import _ from "lodash";
import { WithRequireAccess } from "../hocs";
import { NavigationBar } from "../react-material/NavigationBar";
import { Modal } from "../react-material";
import { useDispatch, useSelector } from "react-redux";
import { UiLabels, ButtonLabels, DynamicLabels, Warnings, Messages } from "../../localisation";
import { selectSearch, selectAllListsList, selectSelectedListIds } from "../../store/selectors";
import { 
  getListsRequest,
  clearAllSelectedLists,
  updateSearchTermList,
  selectAllLists,
  clearAllSelectedArtworks,
  deleteLists,
  openModal,
} from "../../store/actions";
import { useHistory } from "react-router-dom";
import { Trash2 } from "react-feather";
import { Button as StyleButton } from "../elements";
import { ListsTable } from "../react-material/ListsTable/ListsTable";
import { DeleteModal } from "../modals";
import { Flex, Box as StyledBox, Link, Text, Image } from "../fundamentals";

const inputbaseStyle = {
  bgcolor: "#FFFFFF",
  p: "8px 16px",
  borderRadius: "16px",
  width: "275px",
  height: "32px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
};

const textStyle = (selected) => {
  return { fontSize: "16px", lineHeight: "24px", color: selected ? "white" : "#6A6870" };
};

const containerStyle = (selected) => {
  return {
    width: "100%",
    mt: "1.6rem",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: selected && "#5E54FF",
  };
};

const buttonStyle = {
  borderRadius: "16px",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "16px",
  borderColor: "transparent",
};

const deleteButtonStyle = {
  backgroundColor: "#7274FF",
  color: "#FFFFFF",
  ":hover": {
    backgroundColor: "#7274FF",
    color: "#FFFFFF",
    borderColor: "transparent",
  },
  ":active": {
    opacity: 0.8,
    borderColor: "transparent",
  },
};

const listHeadingCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title",
  },
  {
    id: "artists",
    numeric: false,
    disablePadding: false,
    label: "Artists",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Document Type",
  },
  {
    id: "artworks",
    numeric: false,
    disablePadding: false,
    label: "No. of Artworks",
  },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: false,
    label: "Date Created",
  },
  {
    id: "_lastModified",
    numeric: false,
    disablePadding: false,
    label: "Last Modified",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

const ListsScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lists = useSelector(selectAllListsList);
  const listIds = _.map(lists, ({ _id }) => _id) || [];
  const selectedLists = useSelector(selectSelectedListIds) || [];
  const selected = selectedLists.length > 0;
  const search = useSelector(selectSearch);
  const isSearchTermEmpty = search.searchTermList !== "";

  const handleOnSearch = _.debounce((searchTerm) => dispatch(updateSearchTermList(searchTerm)), 50);

  const renderEmptyListList = () => {
    return (
      <Flex flexDirection="column" alignItems="center" height="100%" width="100%">
        <Image
          src={process.env.PUBLIC_URL + "/images/icons/brand-icons/empty-state-illustration.svg"}
          alt="brand"
          width="130px"
          height="180px"
          mt="xxxl"
        />

        <Text fontFamily="bodyDmSans" size="heading2" mt="m">
          {isSearchTermEmpty ? Messages.noListsFound : Messages.noLists}
        </Text>
        <Text width="320px" maxHeight="100px" overflow="hidden" overflowY="hidden" mt="s" textAlign="center">
          {isSearchTermEmpty ? Messages.emptySearchList(search.searchTermList) : Messages.createLists}
        </Text>
        <Flex alignItems="center" mt="xs">
          {isSearchTermEmpty ? (
            <StyleButton
              icon="XCircle"
              buttonStyle="secondary"
              size="medium"
              mr="s"
              onClick={() => dispatch(updateSearchTermList(""))}
            >
              {ButtonLabels.clearSearch}
            </StyleButton>
          ) : (
            <Link href="https://www.simplify.art/help" target="_blank" mr="s">
              <StyleButton icon="HelpCircle" buttonStyle="secondary" size="medium">
                {ButtonLabels.help}
              </StyleButton>
            </Link>
          )}
          <StyleButton buttonStyle="primary" size="medium" onClick={() => history.push("/artworks")}>
            {ButtonLabels.goToArtworks}
          </StyleButton>
        </Flex>
      </Flex>
    );
  };

  useEffect(() => {
    dispatch(clearAllSelectedLists());
    dispatch(clearAllSelectedArtworks());
    dispatch(getListsRequest());
  }, []);

  const showLists = (selected) => {
    if (selected) {
      return selectedLists.length + " " + (selectedLists.length > 1 ? UiLabels.listsSelected : UiLabels.listSelected);
    } else {
      return listIds?.length + " " + UiLabels.lists;
    }
  };

  const handleSelectAll = () => {
    dispatch(selectAllLists(listIds));
  };

  const deleteSelectedLists = () => {
    dispatch(deleteLists(selectedLists));
    dispatch(clearAllSelectedLists());
  };

  const handleCancelSelectAll = () => {
    dispatch(clearAllSelectedLists());
  };

  return (
    <Box sx={{ width: "100%" }}>
      <NavigationBar />
      <Box mt="1rem">
        <Typography variant="h2" pl="48px">
          {UiLabels.lists}
        </Typography>
        {/* search bar */}
        <Box sx={containerStyle(selected)}>
          <Stack pl="48px" height="64px" direction="row" alignItems="center" spacing={2}>
            <Typography sx={textStyle(selected)}>{showLists(selected)}</Typography>
            <InputBase
              placeholder="Search Lists"
              sx={inputbaseStyle}
              value={search.searchTermList}
              onChange={(e) => handleOnSearch(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#55535A" }} />
                </InputAdornment>
              }
            />
          </Stack>

          <Stack direction="row" gap="40px" alignItems="center" mr="48px">
            {selected ? (
              <>
                <Button
                  onClick={() => dispatch(openModal("delete_list"))}
                  variant="outlined"
                  sx={{
                    ...buttonStyle,
                    ...deleteButtonStyle,
                  }}
                  startIcon={<Trash2 color="white" size="16px" />}
                >
                  {ButtonLabels.delete}
                </Button>

                <StyleButton
                  buttonStyle="text"
                  mt="20px"
                  size="small"
                  icon="X"
                  inverted={true}
                  onClick={handleCancelSelectAll}
                >
                  {ButtonLabels.cancel}
                </StyleButton>
              </>
            ) : (
              <StyleButton buttonStyle="text" size="medium" mt="15px" onClick={handleSelectAll}>
                {ButtonLabels.selectAll}
              </StyleButton>
            )}
          </Stack>
        </Box>
      </Box>
      {Object.keys(lists).length > 0 ? (
        <Box>
          <ListsTable headCells={listHeadingCells} lists={lists} />
        </Box>
      ) : (
        <Box>{renderEmptyListList()}</Box>
      )}
      <Modal id="delete_list">
        <DeleteModal
          headerText={DynamicLabels.popupHeaderForDelete(selectedLists.length > 1 ? "lists" : "list", selectedLists.length)}
          bodyText={Warnings.popupWarning}
          onDelete={deleteSelectedLists}
        />
      </Modal>
    </Box>
  );
};

export default WithRequireAccess(ListsScreen, 1);
