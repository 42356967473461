import { Box, Text, Flex } from "../../fundamentals";
import { ButtonLabels, Headings, InputLabels } from "../../../localisation";
import { Button } from "../../elements";
import { ChevronDown } from "react-feather";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { ReactHookFormCheckBox } from "../Checkbox";
import { closeDrawer, updateDiscoveryFilters, getDiscoveryLoadRequest } from "../../../store/actions";
import { selectEventFiltersById } from "../../../store/selectors";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const accordionStyle = {
  border: "none",
  borderBottom: "1px solid #E8E7EA",
  paddingTop: "28px",
  paddingBottom: "28px",
  maxWidth: "100%",
};

const accordionSummaryStyle = {
  padding: "0px",
  minHeight: "0",
  height: "0",
  alignItems: "start",
  "& .MuiAccordionSummary-content": {
    margin: "0",
  },
  "&:hover": {
    backgroundColor: "white",
  },
};

const accordionDetailsStyle = {
  padding: "0px 14px",
  marginTop: "12px",
};

export const DiscoveryFiltersDrawer = ({ expandedFilter, setExpandedFilter }) => {
  const dispatch = useDispatch();
  const discoveryState = useSelector((state) => state.discovery);
  const { sessionId, filters, events } = discoveryState;
  const selectedEventFilters = useSelector((state) => selectEventFiltersById(state, filters.events));

  //   In order to use multiple checkboxes for the same field with React Hook Form,
  //   we are registering the values as an object. Thus, the filters.events array needs
  //   to be transformed to an object in order to use it for default values below

  const selectedEventsObject = {};
  for (const id of filters.events) {
    selectedEventsObject[id] = true;
  }

  const defaultFilters = {
    events: selectedEventsObject,
  };

  const handleChange = (filter) => (event, isExpanded) => {
    setExpandedFilter(isExpanded ? filter : null);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { ...defaultFilters } });

  const submit = (values) => {
    const selectedEvents = events.filter((event) => values.events[event._id]);
    const selectedEventIds = selectedEvents.map((event) => event._id);
    dispatch(updateDiscoveryFilters({ events: selectedEventIds }));
    dispatch(getDiscoveryLoadRequest(sessionId, selectedEventIds, false));
    dispatch(closeDrawer());
  };

  const handleClearAllFiltersClick = () => {
    dispatch(updateDiscoveryFilters({ events: [], media: [], size: [], price: { min: 0, max: 0 } }));
    dispatch(getDiscoveryLoadRequest(sessionId, [], false));
    dispatch(closeDrawer());
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      {/* Heading */}
      <Box padding="20px" borderBottom="1px solid #E8E7EA">
        <Text fontFamily="bodyDmSans" fontSize="20px">
          {Headings.filterAndSort}
        </Text>
      </Box>
      {/* Filters */}
      <Box paddingLeft="20px" paddingRight="20px" maxHeight="480px" overflowY="auto">
        <MuiAccordion
          sx={accordionStyle}
          disableGutters
          elevation={0}
          defaultExpanded={expandedFilter === "event"}
          expanded={expandedFilter === "event"}
          onChange={handleChange("event")}
        >
          <MuiAccordionSummary
            expandIcon={<ChevronDown size="16" color={expandedFilter === "event" ? "#5E54FF" : "black"} />}
            aria-controls="event-filter-options"
            id="event-filter"
            sx={accordionSummaryStyle}
          >
            <Box fontSize="small" fontFamily="body" marginTop="0px">
              <Text fontWeight="500" mb="6px" color={expandedFilter === "event" ? "#5E54FF" : "black"}>
                {InputLabels.event}
              </Text>
              {expandedFilter !== "event" && (
                <Flex flexWrap="wrap">
                  {selectedEventFilters.map((event, index) => (
                    <Flex key={event._id} fontWeight="400" mr={index !== selectedEventFilters.length - 1 && "xxxs"}>
                      <Text>{event.eventName}</Text>
                      {index !== selectedEventFilters.length - 1 && <Text>,</Text>}
                    </Flex>
                  ))}
                </Flex>
              )}
            </Box>
          </MuiAccordionSummary>
          <MuiAccordionDetails sx={accordionDetailsStyle}>
            {events.map((event, index) => (
              <Box key={event._id}>
                <ReactHookFormCheckBox
                  name={`events.${event._id}`}
                  control={control}
                  errors={errors}
                  size="14"
                  label={event.eventName}
                  suffix={event.count}
                  suffixStyling={{ position: "absolute", right: "14px" }}
                />
              </Box>
            ))}
          </MuiAccordionDetails>
        </MuiAccordion>
      </Box>
      {/* Button container */}
      <Box padding="20px" paddingTop="28px">
        <Button type="submit" buttonStyle="primary" size="small" width="100%" fontWeight="500" mb="xs">
          {ButtonLabels.view}
        </Button>
        <Button
          type="button"
          buttonStyle="secondary"
          size="small"
          width="100%"
          fontWeight="500"
          onClick={handleClearAllFiltersClick}
        >
          {ButtonLabels.clearAll}
        </Button>
      </Box>
    </form>
  );
};
