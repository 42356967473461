import css from "@styled-system/css";
import styled from "styled-components";

import { CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";

export const StripeCardInput = styled(CardElement)`
  &.StripeElement {
    box-sizing: border-box;
    height: ${(props) => (props.height ? props.height : "32px")};
    border-radius: 2px;
    border-bottom: 2px solid;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${css({
      bg: "#F3F4FF",
      borderBottomColor: "black",
      "&:focus-within": {
        borderBottomColor: "blue.60",
      },
    })}
  }

  &.StripeElement--focus {
    border-bottom: 2px solid;
    ${css({
      borderBottomColor: "blue.60",
    })}
  }

  &.StripeElement--invalid {
    ${css({
      borderBottomColor: "red",
    })}
  }

  &.StripeElement--webkit-autofill {
  }
`;

export const StripeCardNumberInput = styled(CardNumberElement)`
  &.StripeElement {
    box-sizing: border-box;
    height: ${(props) => (props.height ? props.height : "32px")};
    border-radius: 2px;
    border-bottom: 2px solid;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -15px;
    ${css({
      bg: "#F3F4FF",
      borderBottomColor: "black",
      "&:focus-within": {
        borderBottomColor: "blue.60",
      },
    })}
  }

  &.StripeElement--focus {
    border-bottom: 2px solid;
    ${css({
      borderBottomColor: "blue.60",
    })}
  }

  &.StripeElement--invalid {
    ${css({
      borderBottomColor: "red",
    })}
  }

  &.StripeElement--webkit-autofill {
  }
`;

export const StripeCardExpiryInput = styled(CardExpiryElement)`
  &.StripeElement {
    box-sizing: border-box;
    height: ${(props) => (props.height ? props.height : "32px")};
    border-radius: 2px;
    border-bottom: 2px solid;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    ${css({
      bg: "#F3F4FF",
      borderBottomColor: "black",
      "&:focus-within": {
        borderBottomColor: "blue.60",
      },
    })}
  }

  &.StripeElement--focus {
    border-bottom: 2px solid;
    ${css({
      borderBottomColor: "blue.60",
    })}
  }

  &.StripeElement--invalid {
    ${css({
      borderBottomColor: "red",
    })}
  }

  &.StripeElement--webkit-autofill {
  }
`;

export const StripeCardCvcInput = styled(CardCvcElement)`
  &.StripeElement {
    box-sizing: border-box;
    height: ${(props) => (props.height ? props.height : "32px")};
    border-radius: 2px;
    border-bottom: 2px solid;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    ${css({
      bg: "#F3F4FF",
      borderBottomColor: "black",
      "&:focus-within": {
        borderBottomColor: "blue.60",
      },
    })}
  }

  &.StripeElement--focus {
    border-bottom: 2px solid;
    ${css({
      borderBottomColor: "blue.60",
    })}
  }

  &.StripeElement--invalid {
    ${css({
      borderBottomColor: "red",
    })}
  }

  &.StripeElement--webkit-autofill {
  }
`;
