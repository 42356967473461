import { Box, Text, ImageWithCache } from "../fundamentals";
import { Button } from "../elements";
import { ButtonLabels, InputLabels } from "../../localisation";
import { useDispatch } from "react-redux";
import { closeModal } from "../../store/actions";

const labelStyle = {
  fontFamily: "Inter",
  fontSize: "13px",
  color: "#95939C",
  marginBottom: "8px",
};
export const ArtworkDetailsModal = (props) => {
  const dispatch = useDispatch();
  const artwork = props;
  const { images = [] } = artwork;
  const [firstImage = {}] = images;

  return (
    <Box width="600px">
      {artwork?.images?.length > 0 && (
        <ImageWithCache
          image={firstImage}
          artworkId={artwork?.id}
          urlSuffix="/preview.jpg"
          maxHeight="210px"
          userSelect="none"
          mb="l"
          imageKey={artwork?.id}
        />
      )}
      <Box mb="l">
        <Text>{artwork?.artist}</Text>
        <Text mt="xxs">{artwork?.artworkValues?.title}</Text>
        {artwork?.artworkValues?.year && (
          <Text mt="xxs" color="grey.80">
            {artwork?.artworkValues?.year}
          </Text>
        )}
      </Box>
      {artwork?.artworkValues?.publicPrice && (
        <Box mb="l">
          <Text style={labelStyle}>{InputLabels.totalPublicPrice}</Text>
          <Text>{artwork?.artworkValues?.totalPublicPrice?.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {artwork?.artworkValues?.totalPublicPrice?.currency} </Text>
        </Box>
      )}
      <Box mb="l">
        <Text style={labelStyle}>{InputLabels.publicWorkDescription}</Text>
        {artwork?.artworkValues?.workDescription && <Text>{artwork?.artworkValues?.workDescription}</Text>}
      </Box>
      <Button buttonStyle="secondary" size="small" fontWeight="500" onClick={()=> dispatch(closeModal())}>
        {ButtonLabels.close}
      </Button>
    </Box>
  );
};
