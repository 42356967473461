import React from "react";
import ReactDOM from "react-dom";
import ResizeObserver from "resize-observer-polyfill";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import styledTheme from "./theme";
import { theme } from "./main/components/react-material/theme";
import { ToastProvider } from "react-toast-notifications";
import { Notification } from "./main/components/elements";
import { Detector } from "react-detect-offline";
import * as serviceWorker from "./serviceWorkerRegistration";
import { SW_CONFIG } from "./main/config";
import App from "./main/root/App";
import { configureStore, persistStoreState, history } from "./main/store";
import { changeConnectionStatus } from "./main/store/actions";
import reportWebVitals from "./reportWebVitals";
import TagManager from 'react-gtm-module'

import "typeface-inter";
import "./globalStyle.css";

//TEMP: needed to allow global access to toast manager
window.__react_toast_provider = React.createRef();

window.ResizeObserver = ResizeObserver;

// add tag manager
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_TAG
};

TagManager.initialize(tagManagerArgs);

const store = configureStore();
let persistor = persistStoreState(store);

// Prevent iOS textfield auto-zooming
if(navigator.userAgent.indexOf('iPhone') > -1 ) {
  document
    .querySelector("[name=viewport]")
    .setAttribute("content","width=device-width, initial-scale=1, maximum-scale=1");
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <StyledThemeProvider theme={styledTheme}>
              <ToastProvider
                ref={window.__react_toast_provider}
                autoDismiss
                autoDismissTimeout={3500}
                components={{ Toast: Notification }}
                placement="top-right"
              >
                <Detector
                  onChange={(isConnected) => store.dispatch(changeConnectionStatus(isConnected))}
                  render={() => <App />}
                />
              </ToastProvider>
            </StyledThemeProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.register(SW_CONFIG);
reportWebVitals(console.log);
