import { Box, Text } from "../fundamentals";
import { Button } from "../elements";
import { Headings, ButtonLabels, Notifications, Messages } from "../../localisation";
import { Typography } from "@mui/material";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import {useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions";

const headerStyle = (isMobile) => {
    return {
      fontFamily: "DM Sans",
      fontSize: isMobile ? "20px" : "32px",
      lineHeight: isMobile ? "26px" : "40px",
      fontWeight: "400",
      marginBottom: isMobile ? "12px" : "24px"
    };
  };

const UnsuccessfulUpgradeModal = () => {
    const isMobile = useIsScreenMobile();
    const history = useHistory();
    const dispatch = useDispatch();

    const handleClick = () => {
        window.dataLayer.push({'event': 'upgradeUnsuccessful'})
        dispatch(openModal("upgrade"))
      }

    return (
        <Box width={isMobile ? "360px" : "480px"}>
            <Typography sx={headerStyle(isMobile)}>{Headings.upgradeIncomplete}</Typography>
            <Text mb={isMobile? "s" : "m"} lineHeight="body">{Notifications.upgradeUnsuccessful.text}</Text>
            <Text mb={isMobile? "s" : "m"} lineHeight="body">{Messages.retryUpgrade}</Text>
            <Button
                type="button"
                buttonStyle="form"
                size="medium"
                onClick={handleClick}
            >
                {ButtonLabels.backToRetryUpgrade}
            </Button>
        </Box>
    )
};

export { UnsuccessfulUpgradeModal };