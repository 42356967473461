import React, { useState, useEffect } from "react";
import { Flex, Box, Text, Icon, Link } from "../../../fundamentals";
import { PageTransitions, Button } from "../../../elements";
import {
  Headings,
  Info,
  PaymentInviteFeatures,
  ButtonLabels,
  LinkLabels,
  Testimonials,
  MarketplaceOnboardingSteps,
  UiLabels,
} from "../../../../localisation";
import { Typography } from "@mui/material";
import { useIsScreenMobile } from "../../../hooks/useMediaQuery";
import { BasicInfoForm } from "./BasicInfoForm";
import { AdditionalInfoForm } from "./AdditionalInfoForm";
import { PayoutDetailsForm } from "./PayoutDetailsForm";
import { stripePromise } from "../../../../config";
import { Elements } from "@stripe/react-stripe-js";
import { TermsAndConditions } from "../../../modules/TermsAndConditionsPaymentInvites";

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "24px",
    lineHeight: mobile ? "26px" : "32px",
    fontWeight: "400",
    marginBottom: "30px",
  };
};

const subheaderTextStyle = (mobile) => {
  return {
    fontFamily: "Inter",
    fontSize: mobile ? "14px" : "16px",
    lineHeight: mobile ? "14px" : "16px",
    fontWeight: "500",
    marginBottom: "24px",
  };
};

const fieldStyle = {
  flexGrow: 1,
  ".MuiFilledInput-root": {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F3F4FF",
      borderBottom: "2px solid #C8CEFF",
      marginBottom: "-2px",
    },
    "&:before": {
      content: "none",
    },
    "&.Mui-focused": {
      borderBottom: "none",
      backgroundColor: "#F3F4FF",
      marginBottom: "-2px",
    },
  },

  "& .Mui-error": {
    ".MuiFilledInput-input": {
      marginBottom: "3px",
    },
  },
};

const stackStyle = {
  borderBottom: "1px solid #C3C2C9",
  alignItems: "center",
  justifyContent: "start",
  height: "58px",
};
const labelStyle = {
  width: "151px",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#6A6870",
  fontFamily: "Inter",
};

const paymentInviteFeatures = [
  {
    title: PaymentInviteFeatures.poweredByStripe,
    description: PaymentInviteFeatures.poweredByStripeDesc,
    icon: "Shield",
  },
  {
    title: PaymentInviteFeatures.bankTransfer,
    description: PaymentInviteFeatures.bankTransferDesc,
    icon: "CreditCard",
  },
  {
    title: PaymentInviteFeatures.statusTracking,
    description: PaymentInviteFeatures.statusTrackingDesc,
    icon: "Repeat",
  },
  {
    title: PaymentInviteFeatures.idVerification,
    description: PaymentInviteFeatures.idVerificationDesc,
    icon: "UserCheck",
  },
];

const onboardingSteps = [
  {
    title: MarketplaceOnboardingSteps.stepOne,
    description: MarketplaceOnboardingSteps.stepOneDesc,
  },
  {
    title: MarketplaceOnboardingSteps.stepTwo,
    description: MarketplaceOnboardingSteps.stepTwoDesc,
  },
  {
    title: MarketplaceOnboardingSteps.stepThree,
    description: MarketplaceOnboardingSteps.stepThreeDesc,
  },
  {
    title: MarketplaceOnboardingSteps.stepFour,
    description: MarketplaceOnboardingSteps.stepFourDesc,
  },
  {
    title: MarketplaceOnboardingSteps.stepFive,
    description: MarketplaceOnboardingSteps.stepFiveDesc,
  },
];

const renderProgressBar = (page) => {
  return (
    <Flex alignItems="end" fontSize="small" fontWeight="500">
      {page === "terms_and_conditions" ? (
        <Text color="blue.60" mr="xs">
          {UiLabels.acceptTerms}
        </Text>
      ) : (
        <Flex mr="xs">
          <Icon icon="Check" size="16" color="blue.40" mr="xxxs"></Icon>
          <Text color="blue.40">{UiLabels.acceptTerms}</Text>
        </Flex>
      )}
      <Icon icon="ChevronRight" size="14" color="grey.40" mr="xs"></Icon>
      {page === "terms_and_conditions" ? (
        <Text color="grey.40" mr="xs">
          {UiLabels.basicDetails}
        </Text>
      ) : page === "basic_info" || page === "additional_info" ? (
        <Text color="blue.60" mr="xs">
          {UiLabels.basicDetails}
        </Text>
      ) : (
        <Flex mr="xs">
          <Icon icon="Check" size="16" color="blue.40" mr="xxxs"></Icon>
          <Text color="blue.40">{UiLabels.basicDetails}</Text>
        </Flex>
      )}
      <Icon icon="ChevronRight" size="14" color="grey.40" mr="xs"></Icon>
      {page !== "payout_details" ? (
        <Text color="grey.40" mr="xs">
          {UiLabels.idVerificationAndPayoutDetails}
        </Text>
      ) : (
        <Flex mr="xs">
          <Icon icon="Check" size="16" color="blue.40" mr="xxxs"></Icon>
          <Text color="blue.40">{UiLabels.idVerificationAndPayoutDetails}</Text>
        </Flex>
      )}
      <Icon icon="ChevronRight" size="14" color="grey.40" mr="xs"></Icon>
      {page !== "confirm" ? (
        <Text color="grey.40">{UiLabels.confirm}</Text>
      ) : (
        <Text color="blue.60">{UiLabels.confirm}</Text>
      )}
    </Flex>
  );
};

const OnboardingForm = () => {
  const isMobile = useIsScreenMobile();
  const [page, setPage] = useState("introduction");

  // useEffect(() => {
  //   if (page === "basic_info" && marketplace.loaded) {
  //     setPage("id_verification")
  //     // setPage("payout_details"); //Uncomment this line and comment out the above line to get to payout details after basicInfo form is submitted
  //   } else if (page === "id_verification" && marketplace.loaded) {
  //     setPage("payout_details");
  //   }
  // }, [marketplace.loaded]);

  // useEffect(() => {
  //   if (page === "id_verification" && marketplace.loaded) {
  //     setPage("id_verification")
  // }, [marketplace.loaded]);

  return (
    <Box>
      <PageTransitions
        page={page}
        initialWidth="880px"
        transition="width 0.2s ease-out"
        timeout="0"
        childAlignment="start"
      >
        {/* Screen 1 */}
        <Flex page="introduction" width="880px">
          <Box width="50%" padding="xxl">
            <Flex justifyContent="space-between" fontSize="small" mb="s">
              <Flex alignItems="center">
                <Box borderRadius="50%" backgroundColor="blue.30" padding="xxxs" mr="xxs">
                  <Icon icon="CheckCircle" size="14" color="blue.60" />
                </Box>
                <Text fontWeight="500">{Headings.activateSales}</Text>
              </Flex>
            </Flex>
            <Typography sx={headerTextStyle(isMobile)}>{Headings.sellArtworksWithSimplify}</Typography>
            <Box fontSize="small" mb="30px">
              <Text>{Info.paymentInviteInfo}</Text>
              <Box mt="m">
                {paymentInviteFeatures.map((feature) => {
                  return (
                    <Flex mb="18px" key={feature.title}>
                      <Box mr="s">
                        <Icon icon={feature.icon} size="20" />
                      </Box>
                      <Box>
                        <Text mb="xxxs" fontWeight="500">
                          {feature.title}
                        </Text>
                        <Text>{feature.description}</Text>
                      </Box>
                    </Flex>
                  );
                })}
              </Box>
            </Box>
            <Button
              buttonStyle="primary"
              size="small"
              fontWeight="500"
              mb="xs"
              onClick={() => setPage("onboarding_steps")}
            >
              {ButtonLabels.continue}
            </Button>
            <Link href="https://www.simplify.art/sell-your-art" target="_blank">
              <Button type="button" buttonStyle="text" fontFamily="Inter" icon="HelpCircle" iconSize="16" padding="0">
                {LinkLabels.learnMoreSimplifySelling}
              </Button>
            </Link>
          </Box>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            backgroundColor="blue.20"
            width="50%"
            borderTopRightRadius="10px"
            borderBottomRightRadius="10px"
          >
            <Flex flexDirection="column" bg="blue.60" width="240px" p="36px 44px" borderRadius="12px" color="white">
              <Text fontSize="smaller" lineHeight="button" mb="xs">
                {Testimonials.simplify_ceo.quote_one}
              </Text>
              <Text fontFamily="bodyDmSans" fontSize="10px" fontWeight="700">
                {Testimonials.simplify_ceo.name}
              </Text>
              <Text fontFamily="bodyDmSans" fontSize="10px">
                {Testimonials.simplify_ceo.role}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {/* Next Screens */}
        <OnboardingStepsPage page="onboarding_steps" setPage={setPage} />
        <TermsAndConditionsPage page="terms_and_conditions" setPage={setPage} />
        <BasicInfoPage page="basic_info" setPage={setPage} />
        <AdditionalInfoPage page="additional_info" />
        <PayoutDetailsPage page="payout_details" />
        <ConfirmationPage page="confirm" />
      </PageTransitions>
    </Box>
  );
};

const OnboardingStepsPage = ({ page, setPage }) => {
  const isMobile = useIsScreenMobile();
  return (
    <Flex page={page} width="880px">
      <Box width="50%" padding="xxl">
        <Flex alignItems="center" fontSize="small" mb="s">
          <Box borderRadius="50%" backgroundColor="blue.30" padding="xxxs" mr="xxs">
            <Icon icon="CheckCircle" size="14" color="blue.60" />
          </Box>
          <Text fontWeight="500">{Headings.activateSales}</Text>
        </Flex>
        <Typography sx={headerTextStyle(isMobile)}>{Headings.getStarted}</Typography>
        <Box fontSize="small" mb="30px">
          <Text>{Info.activateSalesAccount}</Text>
          <Box mt="m">
            {onboardingSteps.map((step, index) => {
              return (
                <Flex mb="18px" key={step} alignItems="start">
                  {index !== onboardingSteps.length - 1 ? (
                    <Flex
                      mr="s"
                      backgroundColor="blue.20"
                      p="2px"
                      minWidth={"26px"}
                      minHeight={"26px"}
                      borderRadius="50%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Text color="blue.60" fontWeight="700">
                        {index + 1}
                      </Text>
                    </Flex>
                  ) : (
                    <Flex
                      backgroundColor="blue.20"
                      mr="s"
                      borderRadius="50%"
                      minWidth={"26px"}
                      minHeight={"26px"}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Icon icon="CheckCircle" size="14" color="blue.60" />
                    </Flex>
                  )}
                  <Box>
                    <Text mb="xxxs" fontWeight="500">
                      {step.title}
                    </Text>
                    <Text>{step.description}</Text>
                  </Box>
                </Flex>
              );
            })}
          </Box>
        </Box>
        <Button
          buttonStyle="primary"
          size="small"
          fontWeight="500"
          mb="xs"
          onClick={() => setPage("terms_and_conditions")}
        >
          {ButtonLabels.startActivation}
        </Button>
        <Link href="https://www.simplify.art/sell-your-art" target="_blank">
          <Button type="button" buttonStyle="text" fontFamily="Inter" icon="HelpCircle" iconSize="16" padding="0">
            {LinkLabels.learnMoreSimplifySelling}
          </Button>
        </Link>
      </Box>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        backgroundColor="blue.20"
        width="50%"
        borderTopRightRadius="10px"
        borderBottomRightRadius="10px"
      >
        <Flex flexDirection="column" bg="blue.60" width="240px" p="36px 44px" borderRadius="12px" color="white">
          <Text fontSize="smaller" lineHeight="button" mb="xs">
            {Testimonials.simplify_ceo.quote_two}
          </Text>
          <Text fontFamily="bodyDmSans" fontSize="10px" fontWeight="700">
            {Testimonials.simplify_ceo.name}
          </Text>
          <Text fontFamily="bodyDmSans" fontSize="10px">
            {Testimonials.simplify_ceo.role}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const TermsAndConditionsPage = ({ page, setPage }) => {
  const isMobile = useIsScreenMobile();
  return (
    <Box page={page} width="924px" p="xxl">
      <Flex justifyContent="space-between" alignItems="center" fontSize="small" mb="s">
        <Flex alignItems="center">
          <Box borderRadius="50%" backgroundColor="blue.30" padding="xxxs" mr="xxs">
            <Icon icon="CheckCircle" size="14" color="blue.60" />
          </Box>
          <Text fontWeight="500">{Headings.activateSales}</Text>
        </Flex>
        {renderProgressBar(page)}
      </Flex>
      <Typography sx={headerTextStyle(isMobile)}>{Headings.acceptTerms}</Typography>
      <Box mt="30px" border="1px solid #C3C2C9" borderRadius="7px" padding="l">
        <Typography sx={subheaderTextStyle(isMobile)}>{Headings.summaryOfTemrs}</Typography>
        <Box mb="m" border="1px solid #C3C2C9" borderRadius="12px" padding="m" height="400px" overflowY="auto">
          <TermsAndConditions />
        </Box>
        <Link href="https://www.simplify.art/imprint#payment-invites-terms" target="_blank">
          <Button type="button" buttonStyle="text" fontFamily="Inter" icon="ArrowRight" iconSize="14" padding="0">
            {LinkLabels.readFullTerms}
          </Button>
        </Link>
      </Box>
      <Flex justifyContent="space-between" mt="30px">
        <Link href="https://www.simplify.art/sell-your-art" target="_blank">
          <Button type="button" buttonStyle="text" fontFamily="Inter" icon="HelpCircle" iconSize="16" padding="0">
            {LinkLabels.learnMoreSimplifySelling}
          </Button>
        </Link>
        <Button type="button" buttonStyle="primary" size="small" onClick={() => setPage("basic_info")}>
          {ButtonLabels.agree}
        </Button>
      </Flex>
    </Box>
  );
};

const BasicInfoPage = ({ page, setPage }) => {
  const isMobile = useIsScreenMobile();
  return (
    <Box page={page} width="924px" p="xxl">
      <Flex justifyContent="space-between" alignItems="center" fontSize="small" mb="s">
        <Flex alignItems="center">
          <Box borderRadius="50%" backgroundColor="blue.30" padding="xxxs" mr="xxs">
            <Icon icon="CheckCircle" size="14" color="blue.60" />
          </Box>
          <Text fontWeight="500">{Headings.activateSales}</Text>
        </Flex>
        {renderProgressBar(page)}
      </Flex>
      <Typography sx={headerTextStyle(isMobile)}>{Headings.enterDetails}</Typography>
      <BasicInfoForm
        fieldStyle={fieldStyle}
        stackStyle={stackStyle}
        labelStyle={labelStyle}
        form="payment_invite.onboard"
        setPage={setPage}
        nextPage="additional_info"
      />
    </Box>
  );
};

const AdditionalInfoPage = ({ page }) => {
  const isMobile = useIsScreenMobile();
  return (
    <Box page={page} width="924px" p="xxl">
      <Flex justifyContent="space-between" alignItems="center" fontSize="small" mb="s">
        <Flex alignItems="center">
          <Box borderRadius="50%" backgroundColor="blue.30" padding="xxxs" mr="xxs">
            <Icon icon="CheckCircle" size="14" color="blue.60" />
          </Box>
          <Text fontWeight="500">{Headings.activateSales}</Text>
        </Flex>
        {renderProgressBar(page)}
      </Flex>
      <Typography sx={headerTextStyle(isMobile)}>{Headings.enterDetails}</Typography>
      <AdditionalInfoForm
        fieldStyle={fieldStyle}
        stackStyle={stackStyle}
        labelStyle={labelStyle}
        form="payment_invite.onboard"
      />
    </Box>
  );
};

const PayoutDetailsPage = ({ page }) => {
  const isMobile = useIsScreenMobile();
  return (
    <Box page={page} width="880px">
      <Typography sx={headerTextStyle(isMobile)}>{Headings.providePayoutDetails}</Typography>
      <Box width="460px" fontSize="small" mt="xs">
        <Text>{Info.providePayoutDetails}</Text>
      </Box>
      <Elements stripe={stripePromise}>
        <PayoutDetailsForm
          fieldStyle={fieldStyle}
          stackStyle={stackStyle}
          labelStyle={labelStyle}
          form="payment_invite.onboard.payout_details"
        />
      </Elements>
    </Box>
  );
};

const ConfirmationPage = ({ page }) => {
  const isMobile = useIsScreenMobile();
  return (
    <Box page={page}>
      <Typography sx={headerTextStyle(isMobile)}>{Headings.confirmSetup}</Typography>
      <Text>Confirm</Text>
    </Box>
  );
};

export { OnboardingForm };
