import React from "react";
import { Flex, Box, Text } from "../../../fundamentals";
import { Button } from "../../../elements";
import { ButtonLabels, InputLabels } from "../../../../localisation";
import { Stack } from "@mui/material";
import { reduxForm } from "redux-form";
import { updateCardAccount } from "../../../../store/actions";
import { useElements, useStripe, IbanElement } from "@stripe/react-stripe-js";

const IBAN_STYLE = {
  base: {
    flexgrow: 1,
    color: "#32325d",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4",
    },
    ":-webkit-autofill": {
      color: "#32325d",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
    ":-webkit-autofill": {
      color: "#fa755a",
    },
  },
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ["SEPA"],
  style: IBAN_STYLE,
};

const Form = ({ handleSubmit, submitting, form }) => {
  const stripe = useStripe();
  const elements = useElements();

  const submit = (values, dispatch) => {
    if (submitting) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    return dispatch(updateCardAccount.request({ stripe, elements }));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box mt="30px" border="1px solid #C3C2C9" borderRadius="7px" padding="l">
        <Stack direction="column">
            <label>
              {InputLabels.iBan}
              <IbanElement options={IBAN_ELEMENT_OPTIONS} />
            </label>
        </Stack>
      </Box>
      <Flex justifyContent="end" mt="30px">
        {/* <Button type="button" buttonStyle="text" fontFamily="Inter" icon="HelpCircle" iconSize="16" padding="0">
          {LinkLabels.learnHowPaymentInvitesWork}
        </Button> */}
        <Button
          type="submit"
          buttonStyle="primary"
          size="small"
          disabled={submitting}
          icon={submitting ? "Loader" : null}
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
        >
          {ButtonLabels.submit}
        </Button>
      </Flex>
    </form>
  );
};

let PayoutDetailsForm = reduxForm({
  form: "payment_invite.onboard.payout_details",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Form);

export { PayoutDetailsForm };
