import {
  OPEN_MODAL,
  CLOSE_MODAL,
  SHOW_REMOTE_ALERT,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_FAILURE,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAILURE,
  OPEN_DRAWER,
  CLOSE_DRAWER,
  SIGN_OUT_SUCCESS,
} from "../actions/types";

const UI_INITIAL_STATE = {
  modal: false,
  modalProps: {},
  remoteAlert: {},
  paymentMethodLoading: true,
  subscriptionLoading: true,
  drawerId: null,
  drawerProps: {},
};

export default function uiReducer(state = UI_INITIAL_STATE, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, ...action.payload };
    case CLOSE_MODAL:
      return { ...state, modal: false, modalProps: {} };
    case SHOW_REMOTE_ALERT:
      return { ...state, remoteAlert: action.payload.remoteAlert };

    case GET_PAYMENT_METHOD:
      return { ...state, paymentMethodLoading: true };
    case GET_PAYMENT_METHOD_SUCCESS:
    case GET_PAYMENT_METHOD_FAILURE:
      return { ...state, paymentMethodLoading: false };

    case GET_SUBSCRIPTION:
      return { ...state, subscriptionLoading: true };
    case GET_SUBSCRIPTION_SUCCESS:
    case GET_SUBSCRIPTION_FAILURE:
      return { ...state, subscriptionLoading: false };
      case OPEN_DRAWER:
      return { ...state, ...action.payload };
    case CLOSE_DRAWER:
      return { ...state, drawerId: null, drawerProps: {} };

    case SIGN_OUT_SUCCESS:
      return UI_INITIAL_STATE;
    default:
      return state;
  }
}
