import {
    CREATE_ARTIST,
    CREATE_ARTIST_LOCALLY,
    CREATE_ARTIST_SUCCESS,
    CREATE_ARTIST_FAILURE,
    DELETE_ARTISTS,
    DELETE_ARTIST,
    DELETE_ARTIST_FAILURE,
    DELETE_ARTIST_SUCCESS,
    EDIT_ARTIST,
    EDIT_ARTIST_FAILURE,
    EDIT_ARTIST_LOCALLY,
    EDIT_ARTIST_SUCCESS,
    UPLOAD_ARTIST_IMAGE,
    UPLOAD_ARTIST_IMAGE_FAILURE,
    UPLOAD_ARTIST_IMAGE_SUCCESS,
    // SYNC_ARTISTS,
    DOWNLOAD_ARTISTS,
    DOWNLOAD_ARTISTS_SUCCESS,
  } from "./types";

  export const createArtist = ({ status, ...formValues }, tempArtistId) => {
    return {
      type: CREATE_ARTIST,
      payload: {
        tempArtistId,
        dateCreated: new Date().toISOString(),
        status,
        // image,
        ...formValues,
      },
    };
  };
  
  export const createArtistLocally = (artist) => {
    return {
      type: CREATE_ARTIST_LOCALLY,
      payload: { ...artist },
    };
  };

  export const createArtistSuccess = (tempArtistId, response) => {
    return { type: CREATE_ARTIST_SUCCESS, payload: { tempArtistId, ...response } };
  };
  
  export const createArtistFailure = () => {
    return { type: CREATE_ARTIST_FAILURE, payload: {} };
  };
  
  export const uploadArtistImage = (ArtistId, imageLocalId, image) => {
    return { type: UPLOAD_ARTIST_IMAGE, payload: { ArtistId, imageLocalId, image } };
  };
  
  export const uploadArtistImageSuccess = (artistId, connectionId, imageToConnect) => {
    return { type: UPLOAD_ARTIST_IMAGE_SUCCESS, payload: { artistId, connectionId, imageToConnect } };
  };
  
  export const uploadArtistImageFailure = () => {
    return { type: UPLOAD_ARTIST_IMAGE_FAILURE, payload: {} };
  };

  export const editArtist = ({ id, ...formValues }) => {
    return {
      type: EDIT_ARTIST,
      payload: {
        id,
        lastModified: new Date().toISOString(),
        ...formValues,
      },
    };
  };
  
  export const editArtistLocally = (artist) => {
    return { type: EDIT_ARTIST_LOCALLY, payload: { ...artist } };
  };
  
  export const editArtistSuccess = (artistId, response) => {
    return { type: EDIT_ARTIST_SUCCESS, payload: { artistId, response } };
  };
  
  export const editArtistFailure = () => {
    return { type: EDIT_ARTIST_FAILURE, payload: {} };
  };
  
  export const deleteArtists = (artistIds) => {
    return { type: DELETE_ARTISTS, payload: { artistIds } };
  };
  
  export const deleteArtist = (artistId) => {
    return { type: DELETE_ARTIST, payload: { artistId } };
  };
  
  export const deleteArtistSuccess = (artistId) => {
    return { type: DELETE_ARTIST_SUCCESS, payload: { artistId } };
  };
  
  export const deleteArtistFailure = (artistId) => {
    return { type: DELETE_ARTIST_FAILURE, payload: { artistId } };
  };
  
  // export const syncArtists = (artists) => {
  //   return { type: SYNC_ARTISTS, payload: { artists } };
  // };
  
  export const downloadArtists = () => {
    return { type: DOWNLOAD_ARTISTS };
  };
  export const downloadArtistsSuccess = (artists) => {
    return { type: DOWNLOAD_ARTISTS_SUCCESS, payload: artists };
  };
  export const downloadArtistsFailure = () => {
    return { type: DOWNLOAD_ARTISTS, payload: [] };
  };