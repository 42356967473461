import React from "react";
import { useSelector } from "react-redux";
import { Flex, Box } from "../fundamentals";
import { DynamicHeadings } from "../../localisation";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Typography } from "@mui/material";
import { selectPaymentInviteById } from "../../store/selectors";
import { PaymentInviteModalInfo } from "../modules";
import { BuyerOfferForm } from "../forms/PaymentInviteForms/BuyerOfferForm";

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "24px",
    lineHeight: mobile ? "26px" : "32px",
    marginBottom: "30px",
    fontWeight: "400",
  };
};

export const PaymentInviteBuyerOfferModal = ({ paymentInviteId, isMarketPlace, placeId }) => {
  const isMobile = useIsScreenMobile();
  const paymentInvite = useSelector((state) => selectPaymentInviteById(state, paymentInviteId));

  const artworkCurrency = paymentInvite?.artworks.reduce((acc, item) => {
    if (!acc && item.price.currency) {
      return item.price.currency;
    }
    return acc;
  }, null);

  const initialValues = {
    offeredAmount: { currency: artworkCurrency },
  };

  // const paymentInviteArtworks = useSelector(
  //   (state) => state.payment_invite_artworks?.paymentInviteArtworks.byId[paymentInviteId]
  // );

  //Create a combined artworks array from paymentInvite.artworks and paymentInviteArtworks that will contain the properties of both
  // let combinedArtworksArray = [];
  // if (paymentInviteArtworks) {
  //   combinedArtworksArray = paymentInvite?.artworks?.map(({ _id, price }) => {
  //     const restOfProperties = paymentInviteArtworks?.find((artwork) => artwork._id === _id);
  //     return { _id, price, ...restOfProperties };
  //   });
  // }

  // const calculateSingleArtworkSubtotal = (artworkId) => {
  //   const artwork = paymentInvite.artworks.find((artwork) => artwork._id === artworkId);

  //   if (!artwork) {
  //     return null;
  //   }

  //   const discountTotal = artwork.discounts.reduce((total, discount) => {
  //     return total + Number(artwork.price.value) * (discount.value / 100);
  //   }, 0);

  //   const totalPrice = Number(artwork.price.value) - discountTotal;

  //   return totalPrice;
  // };

  return (
    <Flex justifyContent="space-between" width="992px" height="800px">
      {/* Left Side */}
      <Box p="xxl" marginRight="xxs" overflow="auto" overflowX="hidden" width="55%">
        <Typography sx={headerTextStyle(isMobile)}>
          {DynamicHeadings.makeOfferTo(paymentInvite?._user?.organisation?.name || paymentInvite?._user?.name)}
        </Typography>
        {/* Artwork boxes currently hidden */}
        {/* <Flex mt="18px" p="xxxs" mb="m" width="460px" overflowX="auto">
          {combinedArtworksArray.map((artwork, index) => {
            const { images = [] } = artwork;
            const [firstImage = {}] = images;
            console.log("ARTWORK", artwork);
            return (
              <Box
                key={artwork._id}
                boxShadow="small"
                width="212px"
                mr={index === combinedArtworksArray.length - 1 ? "0" : "m"}
                mb="xxs"
                padding="s"
              >
                <Flex justifyContent="space-between" mb="xxs" alignItems="start">
                  <Box minWidth="74px" mr="xs">
                    <ImageWithCache
                      image={firstImage}
                      artworkId={artwork.id}
                      urlSuffix="/preview.jpg"
                      width="100%"
                      userselect="none"
                    />
                  </Box>
                  <Box>
                    <Text fontSize="smaller">{artwork.artist}</Text>
                    <Text
                      fontSize="smaller"
                      color="grey.80"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      maxWidth="14ch"
                    >
                      {artwork.artworkValues.title}
                    </Text>
                  </Box>
                </Flex>
                <Box fontSize="smaller">
                  <Flex justifyContent="space-between">
                    <Text>{UiLabels.artworkPrice}:</Text>
                    <Text>
                      {currencySymbol}
                      {artwork.price.value}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between" mt="xxxs">
                    <Text>{UiLabels.subTotal}:</Text>
                    <Text color="blue.60">
                      {currencySymbol}
                      {calculateSingleArtworkSubtotal(artwork._id)}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            );
          })}
        </Flex> */}
        <Box width="460px">
          <BuyerOfferForm
            paymentInviteId={paymentInviteId}
            isMarketPlace={isMarketPlace}
            placeId={placeId}
            initialValues={initialValues}
          />
        </Box>
      </Box>

      {/* Right Side */}
      <PaymentInviteModalInfo isOffer={true} />
    </Flex>
  );
};
