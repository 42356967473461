import React from "react";
import { Flex, Box, Link } from "../../../fundamentals";
import { Button } from "../../../elements";
import { ButtonLabels, LinkLabels, InputErrors, InputLabels } from "../../../../localisation";
import { Typography, Stack } from "@mui/material";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Input, RenderError } from "../../../react-material/Input";
import { ReduxFormDateInput } from "../../../react-material/DateInput/ReduxFormDateInput";
import { connect } from "react-redux";
import { createMarketPlaceAccountRequest } from "../../../../store/actions";
// import { ImageUpload } from "../../../modules";
import { DateTime } from "luxon";

const types = [
  {
    label: "Individual",
    value: "individual",
  },
  {
    label: "Company",
    value: "company",
  },
];

const Form = ({
  handleSubmit,
  submitting,
  form,
  fieldStyle,
  stackStyle,
  labelStyle,
  // files,
  country,
  businessType,
}) => {

  const eighteenYearsAgo = DateTime.now().minus({years: 18});

  // Logic for rendering dynamically created fields - temporarily not used

  // const requiredFields = useSelector((state) => state.marketplace.onboarding.fieldsRequired);
  // const [categorisedFields, setCategorisedFields] = useState({});

  // useEffect(() => {
  //   const filteredFields = Object.entries(requiredFields)
  //     //Get only those fields where visible is set to true and file_upload is set to false
  //     .filter(([key, value]) => value.visible == "true" && value.file_upload == "false")
  //     // Create a categorisedFields object where every key represents a section and
  //     // the value of each key is an array of the fields belonging to that section
  //     .sort(([keyA, valueA], [keyB, valueB]) => {
  //       if (valueA.section === "Personal Details") {
  //         return -1;
  //       }
  //       if (valueB.section === "Personal Details") {
  //         return 1;
  //       }
  //       return 0;
  //     })
  //     .reduce((acc, [key, value]) => {
  //       const { section, ...rest } = value;
  //       if (!acc[section]) {
  //         acc[section] = [];
  //       }
  //       acc[section].push(rest);
  //       return acc;
  //     }, {});

  //   setCategorisedFields(filteredFields);
  // }, [requiredFields]);

  const submit = (values, dispatch) => {
    if (!country || !businessType) {
      return;
    }
    return dispatch(createMarketPlaceAccountRequest({ ...values, country, businessType }));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box mt="30px" border="1px solid #C3C2C9" borderRadius="7px" padding="l" width="100%">
        {/* Fields dynamically created - temporarily not used */}
        {/*  <Stack direction="column">
          {Object.keys(categorisedFields).map((category, index) => {
            return (
              <Box key={category} mb={index === Object.keys(categorisedFields).length - 1 ? "0" : "50px"}>
                {categorisedFields[category].map((field) => {
                  return (
                    <Stack sx={stackStyle} direction="row" key={field}>
                      <Typography sx={labelStyle}>{field.name}</Typography>
                      <Field
                        name={field.field}
                        component={field.type === "Date" ? DateInput : Input}
                        sx={fieldStyle}
                        formName={form}
                        placeholder={field.type === "Date" ? "DD/MM/YYYY" : ""}
                        maxDate={field.type === "Date" && today}
                        startDate={field.type === "Date" && eighteenYearsAgo}
                      />
                    </Stack>
                  );
                })}
              </Box>
            );
          })} 
        </Stack> */}

        {/* Business Type: Individual */}
        {businessType === "individual" && (
          <Stack direction="column">
            {/* First name */}
            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.firstName}</Typography>
                <Field name="first_name" component={Input} sx={fieldStyle} formName={form} hideError={true} />
              </Stack>
              <Field name="first_name" component={RenderError} />
            </Box>
            {/* Last name */}
            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.lastName}</Typography>
                <Field name="last_name" component={Input} sx={fieldStyle} formName={form} hideError={true} />
              </Stack>
              <Field name="last_name" component={RenderError} />
            </Box>
            {/* Phone */}
            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.phoneNumber}</Typography>
                <Field name="phone" component={Input} sx={fieldStyle} formName={form} hideError={true} />
              </Stack>
              <Field name="phone" component={RenderError} />
            </Box>
            {/* Date of birth */}
            <Box>
            <Stack sx={stackStyle} direction="row">
              <Typography sx={labelStyle}>{InputLabels.dateOfBirth}</Typography>
              <Field 
                      name="dob" 
                      component={ReduxFormDateInput}
                      hideError={true}
                      customRootStyles={{paddingLeft:"12px"}}
                      customInputStyles={{maxWidth: "110px"}}
                      maxDate={eighteenYearsAgo}
                    />
            </Stack>
            <Field name="dob" component={RenderError} />
            </Box>
            {/* Address */}
            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.addressLine1}</Typography>
                <Field name="address.line1" formName={form} component={Input} sx={fieldStyle} hideError={true} />
              </Stack>
              <Field name="address.line1" component={RenderError} />
            </Box>

            <Stack direction="row" spacing={1}>
              <Box width="50%">
                <Stack direction="row" sx={stackStyle}>
                  <Typography sx={labelStyle}>{InputLabels.city}</Typography>
                  <Field name="address.city" component={Input} sx={{...fieldStyle, maxWidth:"205px"}} hideError={true} />
                </Stack>
                <Field name="address.city" component={RenderError} />
              </Box>

              <Box width="50%">
                <Stack direction="row" sx={stackStyle}>
                  <Typography sx={labelStyle}>{InputLabels.postCode}</Typography>
                  <Field
                    name="address.postal_code"
                    formName={form}
                    component={Input}
                    sx={{...fieldStyle, maxWidth:"205px"}}
                    hideError={true}
                  />
                </Stack>
                <Field name="address.postal_code" component={RenderError} />
              </Box>
            </Stack>

            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.state}</Typography>
                <Field name="address.state" formName={form} component={Input} sx={fieldStyle} hideError={true} />
              </Stack>
              <Field name="address.state" component={RenderError} />
            </Box>
            {/* Business website */}
            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.businessWebsite}</Typography>
                <Field name="url" formName={form} component={Input} sx={fieldStyle} hideError={true} />
              </Stack>
              <Field name="url" component={RenderError} />
            </Box>
            {/* Tax ID */}
            <Stack sx={stackStyle} direction="row">
              <Typography sx={labelStyle}>{InputLabels.taxId}</Typography>
              <Field name="tax_id" formName={form} component={Input} sx={fieldStyle} />
            </Stack>
            {/* VAT Number */}
            <Stack sx={stackStyle} direction="row">
              <Typography sx={labelStyle}>{InputLabels.vatNumber}</Typography>
              <Field name="vat" formName={form} component={Input} sx={fieldStyle} />
            </Stack>
          </Stack>
        )}

        {/* Business Type: Company */}
        {businessType === "company" && (
          <Stack direction="column">
            {/* Company name */}
            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.companyName}</Typography>
                <Field name="name" component={Input} sx={fieldStyle} formName={form} hideError={true} />
              </Stack>
              <Field name="name" component={RenderError} />
            </Box>
            {/* Phone */}
            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.phoneNumber}</Typography>
                <Field name="phone" component={Input} sx={fieldStyle} formName={form} hideError={true} />
              </Stack>
              <Field name="phone" component={RenderError} />
            </Box>
            {/* Address */}
            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.addressLine1}</Typography>
                <Field name="address.line1" formName={form} component={Input} sx={fieldStyle} hideError={true} />
              </Stack>
              <Field name="address.line1" component={RenderError} />
            </Box>

            <Stack direction="row" spacing={1}>
              <Box width="50%">
                <Stack direction="row" sx={stackStyle}>
                  <Typography sx={labelStyle}>{InputLabels.city}</Typography>
                  <Field name="address.city" component={Input} sx={{...fieldStyle, maxWidth:"200px"}} hideError={true} />
                </Stack>
                <Field name="address.city" component={RenderError} />
              </Box>

              <Box width="50%">
                <Stack direction="row" sx={stackStyle}>
                  <Typography sx={labelStyle}>{InputLabels.postCode}</Typography>
                  <Field
                    name="address.postal_code"
                    formName={form}
                    component={Input}
                    sx={{...fieldStyle, maxWidth:"205px"}}
                    hideError={true}
                  />
                </Stack>
                <Field name="address.postal_code" component={RenderError} />
              </Box>
            </Stack>

            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.state}</Typography>
                <Field name="address.state" formName={form} component={Input} sx={fieldStyle} hideError={true} />
              </Stack>
              <Field name="address.state" component={RenderError} />
            </Box>
            {/* Business website */}
            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.businessWebsite}</Typography>
                <Field name="url" formName={form} component={Input} sx={fieldStyle} hideError={true} />
              </Stack>
              <Field name="url" component={RenderError} />
            </Box>
            {/* Tax ID */}
            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.taxId}</Typography>
                <Field name="tax_id" formName={form} component={Input} sx={fieldStyle} hideError={true} />
              </Stack>
              <Field name="tax_id" component={RenderError} />
            </Box>
            {/* Company Registration Number */}
            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.registrationNumber}</Typography>
                <Field name="reg_number" formName={form} component={Input} sx={fieldStyle} hideError={true} />
              </Stack>
              <Field name="reg_number" component={RenderError} />
            </Box>
            {/* VAT Number */}
            <Stack sx={stackStyle} direction="row">
              <Typography sx={labelStyle}>{InputLabels.vatNumber}</Typography>
              <Field name="vat" formName={form} component={Input} sx={fieldStyle} />
            </Stack>
          </Stack>
        )}
      </Box>
      {/* Document upload - temporarily not used */}
      {/* <Box mt="30px" border="1px solid #C3C2C9" borderRadius="7px" padding="l">
        <Text mb="xs">Upload Document</Text>
        <FieldArray
          name="files"
          component={ImageUpload}
          form={form}
          urlSuffix="/document.pdf"
          contained
          isLogoUpload={false}
        />
      </Box> */}
      <Flex justifyContent="space-between" mt="30px">
        <Link href="https://www.simplify.art/sell-your-art" target="_blank">
          <Button type="button" buttonStyle="text" fontFamily="Inter" icon="HelpCircle" iconSize="16" padding="0">
            {LinkLabels.learnMoreSimplifySelling}
          </Button>
        </Link>
        <Button
          type="submit"
          buttonStyle="primary"
          size="small"
          disabled={submitting}
          icon={submitting ? "Loader" : null}
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
        >
          {ButtonLabels.next}
        </Button>
      </Flex>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const {
    businessType,
    first_name,
    last_name,
    name,
    phone,
    dob,
    address: { line1, city, postal_code, state } = {},
    url,
    tax_id,
    reg_number,
    vat,
  } = values;

  //Validation checks for both individual and company
  if (!phone) {
    errors.phone = InputErrors.noPhoneNumber;
  } else if (!phone.startsWith("+")) {
    errors.phone = InputErrors.invalidPhoneNumber;
  }
  if (!line1) errors.address = { line1: InputErrors.noAddress };
  if (!city) errors.address = { city: InputErrors.noCity };
  if (!postal_code) errors.address = { postal_code: InputErrors.noPostalCode };
  if (!state) errors.address = { state: InputErrors.noState };
  if (!url) {
    errors.url = InputErrors.noWebsite;
  } else if (!url.startsWith("https://") && !url.startsWith("http://") && !url.startsWith("www.")) {
    errors.url = InputErrors.wrongUrl;
  }

  // Validation for individual only
  if (businessType === "individual") {
    if (!first_name) errors.first_name = InputErrors.noFirstName;
    if (!last_name) errors.last_name = InputErrors.noLastName;
    if (!dob) errors.dob = InputErrors.noDob;

    // Validation for company only
  } else if (businessType === "company") {
    if (!name) errors.name = InputErrors.noCompanyName;
    if (!tax_id) errors.tax_id = InputErrors.noTaxId;
    if (!reg_number) errors.reg_number = InputErrors.noRegistrationNumber;
  } else {
  }

  return errors;
};

let AdditionalInfoForm = reduxForm({
  form: "payment_invite.onboard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

const selector = formValueSelector("payment_invite.onboard");

function mapStateToProps(state) {
  // const files = selector(state, "files");
  const country = selector(state, "country");
  const businessType = selector(state, "businessType");
  return { country, businessType };
}
AdditionalInfoForm = connect(mapStateToProps, null)(AdditionalInfoForm);

export { AdditionalInfoForm };
