import {
  OPEN_MODAL,
  CLOSE_MODAL,
  CHECK_FOR_REMOTE_ALERTS,
  SHOW_REMOTE_ALERT,
  OPEN_DRAWER,
  CLOSE_DRAWER,
} from "./types";

export const openModal = (modal, modalProps = {}) => {
  return { type: OPEN_MODAL, payload: { modal, modalProps } };
};

export const closeModal = () => {
  return { type: CLOSE_MODAL };
};

export const checkForRemoteAlerts = () => {
  return { type: CHECK_FOR_REMOTE_ALERTS };
};

export const showRemoteAlert = (remoteAlert) => {
  return { type: SHOW_REMOTE_ALERT, payload: { remoteAlert } };
};

export const openDrawer = (drawerId, drawerProps = {}) => {
  return { type: OPEN_DRAWER, payload: { drawerId, drawerProps } };
};

export const closeDrawer = () => {
  return { type: CLOSE_DRAWER };
};
