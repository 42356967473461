import React from "react";
import styled from "styled-components";

import { Instructions, LinkLabels, Info, PaymentInviteFeatures } from "../../localisation";

import { Box, Flex, Image, Text, Icon } from "../fundamentals";
import { Button } from "../elements";
import { SignUpBetaForm } from "../forms";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const StyledSignUpBetaScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const paymentInviteFeatures = [
  {
    title: PaymentInviteFeatures.poweredByStripe,
    description: PaymentInviteFeatures.poweredByStripeDesc,
    icon: "Shield",
  },
  {
    title: PaymentInviteFeatures.bankTransfer,
    description: PaymentInviteFeatures.bankTransferDesc,
    icon: "CreditCard",
  },
  {
    title: PaymentInviteFeatures.statusTracking,
    description: PaymentInviteFeatures.statusTrackingDesc,
    icon: "Repeat",
  },
  {
    title: PaymentInviteFeatures.idVerification,
    description: PaymentInviteFeatures.idVerificationDesc,
    icon: "UserCheck",
  },
];

const SignUpBetaScreen = (props) => {
  const isMobile = useIsScreenMobile();
  const history = useHistory();
  const prevLocation = props.location.state?.from.pathname;
  return (
    <StyledSignUpBetaScreen bg="white">
      <Helmet>
        <title>Join Payment Invite Beta | simplify.art</title>
        <meta name="description" content="Sign up to join Payment Invite beta" />
      </Helmet>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Image
          src={
            process.env.PUBLIC_URL +
            `/images/brand-identity/${isMobile ? "logo-icon-black.svg" : "logo_horizontal-black.svg"}`
          }
          alt="logo"
          width={isMobile ? "25px" : "184px"}
          height={isMobile ? "35px" : ""}
          p={isMobile ? "35px 30px" : "35px 50px"}
        />

        <Box display="flex" alignItems="center" mr={isMobile ? "l" : "xxxl"}>
          {!isMobile && (
            <Text size="heading3" mr="s">
              {Instructions.noAccount}
            </Text>
          )}
          <Button
            buttonStyle="default"
            type="button"
            size={isMobile ? "small" : "medium"}
            fontSize={isMobile ? "13px" : "20px"}
            bg="white"
            p={isMobile ? "5px 12px" : "8.5px 20px"}
            fontWeight="700"
            onClick={(e) => {
              e.preventDefault();
              history.push("/register");
            }}
          >
            {LinkLabels.signup}
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" pt="mega">
        <Flex
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between"
          width={isMobile ? "325px" : "880px"}
          backgroundColor="blue.60"
          borderRadius="15px"
          boxShadow="mediumLarge"
        >
          <Flex
          flexDirection="column"
            width={isMobile ? "325px" : "500px"}
            justifyContent="center"
            // height={isMobile ? "280px" : "401px"}
            backgroundColor="white"
            p={isMobile ? "m" : "xxxl"}
            
          >
            <SignUpBetaForm prevLocation={prevLocation} />
          </Flex>
          <Box color="white" fontSize="small" fontWeight="500" p={isMobile? "m" :"xl"} width={isMobile ? "325px" : "380px"}>
            <Text>{Info.paymentInviteInfo}</Text>
            <Box mt="m">
            {paymentInviteFeatures.map((feature) => {
                return (
                  <Flex mb="18px" key={feature.title}>
                    <Box mr="s">
                      <Icon icon={feature.icon} size="18"  />
                    </Box>
                    <Box>
                      <Text mb="xxxs" fontWeight="600">
                        {feature.title}
                      </Text>
                      <Text>{feature.description}</Text>
                    </Box>
                  </Flex>
                );
              })}
            </Box>
          </Box>
        </Flex>
      </Box>
    </StyledSignUpBetaScreen>
  );
};

export default SignUpBetaScreen;
