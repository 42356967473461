import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Flex, Box, Text, Icon } from "../fundamentals";
import { UiLabels, DynamicHeadings, ButtonLabels, InputLabels, InputPlaceholders } from "../../localisation";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Typography } from "@mui/material";
import { selectPaymentInviteById } from "../../store/selectors";
import { Button } from "../elements";
import { PaymentInviteModalInfo, PaymentInviteArtworkValuesTable } from "../modules";
import { Field, reduxForm } from "redux-form";
import { Box as MuiBox } from "@mui/material";
import { initiateInvitePayment } from "../../store/actions";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../config";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { StripeCardNumberInput, StripeCardExpiryInput, StripeCardCvcInput } from "../elements";

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "24px",
    lineHeight: mobile ? "26px" : "32px",
    marginBottom: "6px",
    fontWeight: "400",
  };
};

const ProgressBar = () => {
  return (
    <Flex fontSize="smaller" mb="l">
      <Text color="blue.60" mr="xxs">
        {UiLabels.information}
      </Text>
      <Icon icon="ChevronRight" color="grey.80" size="14" mr="xxs" />
      <Text color="blue.60" mr="xxs">
        {UiLabels.shipping}
      </Text>
      <Icon icon="ChevronRight" color="grey.80" size="14" mr="xxs" />
      <Text color="blue.60">{UiLabels.payment}</Text>
    </Flex>
  );
};

const Form = ({
  handleSubmit,
  submitting,
  paymentInviteId,
  paymentInvite,
  total,
  currency,
  placeId,
  isMarketPlace,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const isMobile = useIsScreenMobile();
  const artworkIds = paymentInvite?.artworks.map((artwork) => artwork._id.id);

  const submit = (values, dispatch) => {
    if (submitting) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    const amount = total * 100; //Base in cents

    if (!amount) {
      return;
    }

    return dispatch(
      initiateInvitePayment.request({
        ...values,
        stripe,
        elements,
        paymentInviteId,
        artworkIds,
        placeId,
        isMarketPlace,
        amount,
        currency,
        isMobile,
      })
    );
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="small" color="grey.80">
          {InputLabels.creditCardDetails}
        </Text>
        <MuiBox
          component="img"
          alt="stripe badge"
          src={process.env.PUBLIC_URL + "/images/icons/other-icons/stripe-badge.svg"}
          height="32px"
        ></MuiBox>
      </Flex>
      <Box mt="m">
        <Field
          name="cardNumber"
          height="50px"
          component={StripeCardNumberInput}
          options={{ placeholder: InputPlaceholders.creditCardNumber }}
        />

        <Flex justifyContent="space-between" mt="s" mb="m">
          <Field name="cardExpiryDate" height="50px" component={StripeCardExpiryInput} />
          <Field name="cardCvc" height="50px" component={StripeCardCvcInput} />
        </Flex>
      </Box>
      <Button
        type="submit"
        buttonStyle="primary"
        size="medium"
        fontWeight="500"
        disabled={submitting}
        icon={submitting ? "Loader" : null}
        iconSpinning={true}
        iconOffset="xs"
      >
        {ButtonLabels.pay}
      </Button>
    </form>
  );
};

export const PaymentInvitePaymentForm = reduxForm({
  form: "payment_invite.pay",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Form);

export const PaymentInviteBuyerGeneratedPaymentModal = ({ paymentInviteId, placeId, isMarketPlace }) => {
  const isMobile = useIsScreenMobile();
  const [total, setTotal] = useState(0);
  const paymentInvite = useSelector((state) => selectPaymentInviteById(state, paymentInviteId));

  //   const paymentInviteArtworks = useSelector(
  //     (state) => state.payment_invite_artworks?.paymentInviteArtworks.byId[paymentInviteId]
  //   );
  const currency = paymentInvite?.artworks.reduce((acc, item) => {
    if (!acc && item.price.currency) {
      return item.price.currency;
    }
    return acc;
  }, null);

  //Create a combined artworks array from paymentInvite.artworks and paymentInviteArtworks that will contain the properties of both
  //   let combinedArtworksArray = [];
  //   if (paymentInviteArtworks) {
  //     combinedArtworksArray = paymentInvite?.artworks?.map(({ _id, price }) => {
  //       const restOfProperties = paymentInviteArtworks?.find((artwork) => artwork._id === _id);
  //       return { _id, price, ...restOfProperties };
  //     });
  //   }


  //   const calculateSingleArtworkSubtotal = (artworkId) => {
  //     const artwork = paymentInvite.artworks.find((artwork) => artwork._id === artworkId);

  //     if (!artwork) {
  //       return null;
  //     }

  //     const discountTotal = artwork.discounts.reduce((total, discount) => {
  //       return total + Number(artwork.price.value) * (discount.value / 100);
  //     }, 0);

  //     const totalPrice = Number(artwork.price.value) - discountTotal;

  //     return totalPrice;
  //   };

  return (
    <Flex justifyContent="space-between" width="992px" height="800px">
      {/* Left Side */}

      <Box p="xxl" marginRight="xxs" overflow="auto" overflowX="hidden" width="55%">
        <Typography sx={headerTextStyle(isMobile)}>
          {DynamicHeadings.purchaseFrom(paymentInvite?._user?.organisation?.name || paymentInvite?._user?.name)}
        </Typography>
        <ProgressBar />
        {/* Artwork boxes currently hidden */}
        {/* <Flex mt="18px" p="xxxs" mb="m" width="460px" overflowX="auto">
          {combinedArtworksArray.map((artwork, index) => {
            const { images = [] } = artwork;
            const [firstImage = {}] = images;
            console.log("ARTWORK", artwork);
            return (
              <Box
                key={artwork._id}
                boxShadow="small"
                width="212px"
                mr={index === combinedArtworksArray.length - 1 ? "0" : "m"}
                mb="xxs"
                padding="s"
              >
                <Flex justifyContent="space-between" mb="xxs" alignItems="start">
                  <Box minWidth="74px" mr="xs">
                    <ImageWithCache
                      image={firstImage}
                      artworkId={artwork.id}
                      urlSuffix="/preview.jpg"
                      width="100%"
                      userselect="none"
                    />
                  </Box>
                  <Box>
                    <Text fontSize="smaller">{artwork.artist}</Text>
                    <Text
                      fontSize="smaller"
                      color="grey.80"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      maxWidth="14ch"
                    >
                      {artwork.artworkValues.title}
                    </Text>
                  </Box>
                </Flex>
                <Box fontSize="smaller">
                  <Flex justifyContent="space-between">
                    <Text>{UiLabels.artworkPrice}:</Text>
                    <Text>
                      {currencySymbol}
                      {artwork.price.value}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between" mt="xxxs">
                    <Text>{UiLabels.subTotal}:</Text>
                    <Text color="blue.60">
                      {currencySymbol}
                      {calculateSingleArtworkSubtotal(artwork._id)}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            );
          })}
        </Flex> */}
        <PaymentInviteArtworkValuesTable paymentInvite={paymentInvite} total={total} setTotal={setTotal} />
        <Box width="460px" border="1px solid #C8CEFF" borderRadius="8px" padding="l" mb="m" fontSize="small">
          <Flex justifyContent="space-between" pb="xs" borderBottom="1px solid #C3C2C9">
            <Text>{UiLabels.contact}</Text>
            <Text fontWeight="500">
              {paymentInvite?.contacts[paymentInvite?.contacts?.length - 1]?.email ||
                paymentInvite?.contacts[paymentInvite?.contacts?.length - 1]?._id?.email}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" mt="xs">
            <Text>{UiLabels.shipTo}</Text>
            <Text fontWeight="500">
              {paymentInvite?.transportMode === "simplifyShipping"
                ? `${paymentInvite?.shippingDetails?.address1}, ${paymentInvite?.shippingDetails?.postcode}, ${paymentInvite?.shippingDetails?.city}, ${paymentInvite?.shippingDetails?.country} `
                : paymentInvite?.transportMode === "ownShipping"
                ? UiLabels.ownShipping
                : UiLabels.personalHandover}
            </Text>
          </Flex>
        </Box>
        {/* Credit card details */}
        <Elements stripe={stripePromise}>
          <Box width="460px">
            <PaymentInvitePaymentForm
              paymentInviteId={paymentInviteId}
              paymentInvite={paymentInvite}
              placeId={placeId}
              isMarketPlace={isMarketPlace}
              total={total}
              currency={currency}
            />
          </Box>
        </Elements>
      </Box>

      {/* Right Side */}
      <PaymentInviteModalInfo />
    </Flex>
  );
};

