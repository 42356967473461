import { loadStripe } from "@stripe/stripe-js";
import { FeaturesInfo } from "../localisation";

export const STRIPE_PLANS = {
  artist_free: {
    plan: "artist-free",
    alternative_plan: null,
    price: 0,
    price_for_month:0,
    subtotal: 0,
    vat: 0,
    discount: null,
    label: "Artist Free",
    daysTillRenewal: null,
    billing_period: null,
    mr: "l",
    features: [
      FeaturesInfo.freeBuildDatabase,
      FeaturesInfo.freeShareArtworks,
      FeaturesInfo.freeSaveArtworks,
      FeaturesInfo.freeUseApp
    ],
  },
  artist_monthly: {
    plan: "artist-monthly",
    alternative_plan: "artist-yearly",
    price: 10,
    price_for_month:10,
    subtotal: 8,
    vat: 2,
    discount: null,
    label: "Artist All-Access",
    daysTillRenewal: 30,
    billing_period: "monthly",
    mr: "l",
    features: [
      FeaturesInfo.multiImages,
      FeaturesInfo.flexibleDocumentTemplates,
      FeaturesInfo.unlimitedViewingRooms,
      FeaturesInfo.editWorklists,
      FeaturesInfo.artistBiographicalInfo,
      FeaturesInfo.multiEditDescShort,
      FeaturesInfo.addContacts,
      FeaturesInfo.reducedFeesTo7,
      FeaturesInfo.dropboxIntegrationDescShort
    ],
  },
  artist_yearly: {
    plan: "artist-yearly",
    alternative_plan: "artist-monthly",
    price: 96,
    price_for_month: 8,
    subtotal: 76.8,
    vat: 19.2,
    discount: 15,
    label: "Artist All-Access",
    daysTillRenewal: 365,
    billing_period: "yearly",
    mr: "l",
    features: [
      FeaturesInfo.multiImages,
      FeaturesInfo.flexibleDocumentTemplates,
      FeaturesInfo.unlimitedViewingRooms,
      FeaturesInfo.editWorklists,
      FeaturesInfo.artistBiographicalInfo,
      FeaturesInfo.multiEditDescShort,
      FeaturesInfo.addContacts,
      FeaturesInfo.reducedFeesTo7,
      FeaturesInfo.dropboxIntegrationDescShort
    ],
  },
  collector_free: {
    plan: "collector-free",
    alternative_plan: null,
    price: 0,
    price_for_month:0,
    subtotal: 0,
    vat: 0,
    discount: null,
    label: "Collector Free",
    daysTillRenewal: null,
    billing_period: null,
    mr: "l",
    features: [
      FeaturesInfo.freeBuildDatabase,
      FeaturesInfo.freeShareArtworks,
      FeaturesInfo.freeSaveArtworks,
      FeaturesInfo.freeUseApp
    ],
  },
  collector_monthly: {
    plan: "collector-monthly",
    alternative_plan: "collector-yearly",
    price: 29,
    price_for_month:29,
    subtotal: 23.2,
    vat: 5.8,
    discount: null,
    label: "Collector All-Access",
    daysTillRenewal: 30,
    billing_period: "monthly",
    mr: "l",
    features: [
      FeaturesInfo.multiImages,
      FeaturesInfo.flexibleDocumentTemplates,
      FeaturesInfo.unlimitedViewingRooms,
      FeaturesInfo.editWorklists,
      FeaturesInfo.artistBiographicalInfo,
      FeaturesInfo.multiEditDescShort,
      FeaturesInfo.addContacts,
      FeaturesInfo.reducedFeesTo7,
      FeaturesInfo.dropboxIntegrationDescShort
    ],
  },
  collector_yearly: {
    plan: "collector-yearly",
    alternative_plan: "collector-monthly",
    price: 300,
    price_for_month: 25,
    subtotal: 240,
    vat: 60,
    discount: 15,
    label: "Collector All-Access",
    daysTillRenewal: 365,
    billing_period: "yearly",
    mr: "l",
    features: [
      FeaturesInfo.multiImages,
      FeaturesInfo.flexibleDocumentTemplates,
      FeaturesInfo.unlimitedViewingRooms,
      FeaturesInfo.editWorklists,
      FeaturesInfo.artistBiographicalInfo,
      FeaturesInfo.multiEditDescShort,
      FeaturesInfo.addContacts,
      FeaturesInfo.reducedFeesTo7,
      FeaturesInfo.dropboxIntegrationDescShort
    ],
  },
  gallerist_free: {
    plan: "gallerist-free",
    alternative_plan: null,
    price: 0,
    price_for_month:0,
    subtotal: 0,
    vat: 0,
    discount: null,
    label: "Gallerist Free",
    daysTillRenewal: null,
    billing_period: null,
    mr: "l",
    features: [
      FeaturesInfo.freeBuildDatabase,
      FeaturesInfo.freeShareArtworks,
      FeaturesInfo.freeSaveArtworks,
      FeaturesInfo.freeUseApp
    ],
  },
  gallerist_monthly: {
    plan: "gallerist-monthly",
    alternative_plan: "gallerist-yearly",
    price: 29,
    price_for_month:29,
    subtotal: 23.2,
    vat: 5.8,
    discount: null,
    label: "Gallerist All-Access",
    daysTillRenewal: 30,
    billing_period: "monthly",
    mr: "l",
    features: [
      FeaturesInfo.multiImages,
      FeaturesInfo.flexibleDocumentTemplates,
      FeaturesInfo.unlimitedViewingRooms,
      FeaturesInfo.editWorklists,
      FeaturesInfo.artistBiographicalInfo,
      FeaturesInfo.multiEditDescShort,
      FeaturesInfo.addContacts,
      FeaturesInfo.reducedFeesTo7,
      FeaturesInfo.dropboxIntegrationDescShort
    ],
  },
  gallerist_yearly: {
    plan: "gallerist-yearly",
    alternative_plan: "gallerist-monthly",
    price: 300,
    price_for_month: 25,
    subtotal: 240,
    vat: 60,
    discount: 15,
    label: "Gallerist All-Access",
    daysTillRenewal: 365,
    billing_period: "yearly",
    mr: "l",
    features: [
      FeaturesInfo.multiImages,
      FeaturesInfo.flexibleDocumentTemplates,
      FeaturesInfo.unlimitedViewingRooms,
      FeaturesInfo.editWorklists,
      FeaturesInfo.artistBiographicalInfo,
      FeaturesInfo.multiEditDescShort,
      FeaturesInfo.addContacts,
      FeaturesInfo.reducedFeesTo7,
      FeaturesInfo.dropboxIntegrationDescShort
    ],
  },
  art_professional_free: {
    plan: "art-professional-free",
    alternative_plan: null,
    price: 0,
    price_for_month:0,
    subtotal: 0,
    vat: 0,
    discount: null,
    label: "Art Professional Free",
    daysTillRenewal: null,
    billing_period: null,
    mr: "l",
    features: [
      FeaturesInfo.freeBuildDatabase,
      FeaturesInfo.freeShareArtworks,
      FeaturesInfo.freeSaveArtworks,
      FeaturesInfo.freeUseApp
    ],
  },
  art_professional_monthly: {
    plan: "art-professional-monthly",
    alternative_plan: "art-professional-yearly",
    price: 29,
    price_for_month:29,
    subtotal: 23.2,
    vat: 5.8,
    discount: null,
    label: "Art Professional All-Access",
    daysTillRenewal: 30,
    billing_period: "monthly",
    mr: "l",
    features: [
      FeaturesInfo.multiImages,
      FeaturesInfo.flexibleDocumentTemplates,
      FeaturesInfo.unlimitedViewingRooms,
      FeaturesInfo.editWorklists,
      FeaturesInfo.artistBiographicalInfo,
      FeaturesInfo.multiEditDescShort,
      FeaturesInfo.addContacts,
      FeaturesInfo.reducedFeesTo7,
      FeaturesInfo.dropboxIntegrationDescShort
    ],
  },
  art_professional_yearly: {
    plan: "art-professional-yearly",
    alternative_plan: "art-professional-monthly",
    price: 300,
    price_for_month: 25,
    subtotal: 240,
    vat: 60,
    discount: 15,
    label: "Art Professional All-Access",
    daysTillRenewal: 365,
    billing_period: "yearly",
    mr: "l",
    features: [
      FeaturesInfo.multiImages,
      FeaturesInfo.flexibleDocumentTemplates,
      FeaturesInfo.unlimitedViewingRooms,
      FeaturesInfo.editWorklists,
      FeaturesInfo.artistBiographicalInfo,
      FeaturesInfo.multiEditDescShort,
      FeaturesInfo.addContacts,
      FeaturesInfo.reducedFeesTo7,
      FeaturesInfo.dropboxIntegrationDescShort
    ],
  },
  art_institution_free: {
    plan: "art-institution-free",
    alternative_plan: null,
    price: 0,
    price_for_month:0,
    subtotal: 0,
    vat: 0,
    discount: null,
    label: "Art Institution Free",
    daysTillRenewal: null,
    billing_period: null,
    mr: "l",
    features: [
      FeaturesInfo.freeBuildDatabase,
      FeaturesInfo.freeShareArtworks,
      FeaturesInfo.freeSaveArtworks,
      FeaturesInfo.freeUseApp
    ],
  },
  art_institution_monthly: {
    plan: "art-institution-monthly",
    alternative_plan: "art-institution-yearly",
    price: 29,
    price_for_month: 29,
    subtotal: 23.2,
    vat: 5.8,
    discount: null,
    label: "Art Institution All-Access",
    daysTillRenewal: 30,
    billing_period: "monthly",
    mr: "l",
    features: [
      FeaturesInfo.multiImages,
      FeaturesInfo.flexibleDocumentTemplates,
      FeaturesInfo.unlimitedViewingRooms,
      FeaturesInfo.editWorklists,
      FeaturesInfo.artistBiographicalInfo,
      FeaturesInfo.multiEditDescShort,
      FeaturesInfo.addContacts,
      FeaturesInfo.reducedFeesTo7,
      FeaturesInfo.dropboxIntegrationDescShort
    ],
  },
  art_institution_yearly: {
    plan: "art-institution-yearly",
    alternative_plan: "art-institution-monthly",
    price: 300,
    price_for_month: 25,
    subtotal: 240,
    vat: 60,
    discount: 15,
    label: "Art Institution All-Access",
    daysTillRenewal: 365,
    billing_period: "yearly",
    mr: "l",
    features: [
      FeaturesInfo.multiImages,
      FeaturesInfo.flexibleDocumentTemplates,
      FeaturesInfo.unlimitedViewingRooms,
      FeaturesInfo.editWorklists,
      FeaturesInfo.artistBiographicalInfo,
      FeaturesInfo.multiEditDescShort,
      FeaturesInfo.addContacts,
      FeaturesInfo.reducedFeesTo7,
      FeaturesInfo.dropboxIntegrationDescShort
    ],
  },
};

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
