import _ from "lodash";
import { createSelector } from "reselect";
import { ViewingRoomContainsSearchTags } from "../../utils";
import { selectSearch } from "./searchSelectors";

export const selectViewingRooms = (state) => state.viewing_room.viewingRooms.byId;
export const selectViewingRoomId = (state, viewingRoomId) => viewingRoomId;
export const selectViewingRoomIds = (state, viewingRoomIds) => viewingRoomIds;

export const selectViewingRoomById = createSelector(selectViewingRooms, selectViewingRoomId, (viewingRooms, viewingRoomId) => {
  return viewingRooms?.[viewingRoomId];
});

export const selectViewingRoomsById = createSelector(selectViewingRooms, selectViewingRoomIds, (viewingRooms, viewingRoomIds) => {
  const selectedViewingRooms = _.map(viewingRoomIds, (viewingRoomId) => viewingRooms[viewingRoomId]);

  return selectedViewingRooms;
});

export const selectAllViewingRoomsList = createSelector(selectViewingRooms, selectSearch, (viewingRooms, search) => {
  let viewingRoomsList = _.map(viewingRooms, (viewingRoom) => {
    return viewingRoom;
  });
  return _(viewingRooms)
    .thru((viewingRooms) => {
      if (search.searchTermViewingRoom !== "") {
        const searchTags = search.searchTermViewingRoom.split(" ");

        const filter = viewingRoomsList.filter((viewingRoom) => ViewingRoomContainsSearchTags(searchTags, viewingRoom));
        return filter;
      }
      return viewingRooms;
    })
    .value();
});