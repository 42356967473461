import {
  CREATE_ONBOARDING_LAYOUT_REQUEST,
  CREATE_ONBOARDING_LAYOUT_SUCCESS,
  CREATE_ONBOARDING_LAYOUT_FAILURE,
  CREATE_MARKETPLACE_ACCOUNT_REQUEST,
  CREATE_MARKETPLACE_ACCOUNT_SUCCESS,
  CREATE_MARKETPLACE_ACCOUNT_FAILURE,
  UPDATE_CARD_ACCOUNT_REQUEST,
  UPDATE_CARD_ACCOUNT_SUCCESS,
  UPDATE_CARD_ACCOUNT_FAILURE,
} from "./types";
import {createFormAction} from "redux-form-saga";

export const createOnboardingLayoutRequest = ({ ...formValues }) => {
    return {
      type: CREATE_ONBOARDING_LAYOUT_REQUEST,
      payload: {
        dateCreated: new Date().toISOString(),
        ...formValues,
      },
    };
  };
  
  export const createOnboardingLayoutSuccess = (response) => {
    return {
      type: CREATE_ONBOARDING_LAYOUT_SUCCESS,
      payload: { ...response },
    };
  };
  
  export const createOnboardingLayoutFailure = () => {
    return { type: CREATE_ONBOARDING_LAYOUT_FAILURE, payload: {} };
  };

  export const createMarketPlaceAccountRequest = (payload) => {
    const { country, businessType, ...formValues} = payload;
    return {
      type: CREATE_MARKETPLACE_ACCOUNT_REQUEST,
      payload: {
        dateCreated: new Date().toISOString(),
        country,
        businessType,
        ...formValues,
      },
    };
  };

  export const createMarketPlaceAccountSuccess = (response) => {
    return {
      type: CREATE_MARKETPLACE_ACCOUNT_SUCCESS,
      payload: { ...response },
    };
  };
  
  export const createMarketPlaceAccountFailure = () => {
    return { type: CREATE_MARKETPLACE_ACCOUNT_FAILURE, payload: {} };
  };


  /*export const updateCardAccountSuccess = (response) => {
    return {
      type: UPDATE_CARD_ACCOUNT_SUCCESS,
      payload: { ...response },
    };
  };

  export const updateCardAccountFailure = () => {
    return { type: UPDATE_CARD_ACCOUNT_FAILURE, payload: {} };
  };*/

  export const updateCardAccount = createFormAction(UPDATE_CARD_ACCOUNT_REQUEST);