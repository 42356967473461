import { WithRequireAccess } from "../hocs";
import { NavigationBar } from "../react-material/NavigationBar";
import { Headings, ButtonLabels } from "../../localisation";
import { Typography } from "@mui/material";
import { ViewingRoomForm } from "../forms/ViewingRoomForm";
import { Button, Modal } from "../elements";
import { useHistory } from "react-router-dom";
import { Box } from "../fundamentals";
import { selectSelectedViewingRoomIds, selectViewingRoomById } from "../../store/selectors";
import { useSelector } from "react-redux";
import { ArtworkDetailsModal, AdditionalArtworksModal } from "../modals";
import { useForm } from "react-hook-form";
import { DateTime } from "luxon";

const EditViewingRoomScreen = () => {
  const history = useHistory();
  const selectedViewingRoomId = useSelector(selectSelectedViewingRoomIds)[0];
  const viewingRoom = useSelector((state) => selectViewingRoomById(state, selectedViewingRoomId));
  
  const defaultValues = {
      ...viewingRoom,
      dateValid: DateTime.fromISO(viewingRoom?.dateValid) ,
};

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting, },
  } = useForm({ defaultValues: { ...defaultValues } });

  return (
    <Box width="100%">
      <Box maxWidth="98%">
        <NavigationBar />
      </Box>
      <Box>
        <Box backgroundColor="white">
          <Box pl="xxl" pr="xxl" pt="m">
            <Button
              buttonStyle="formBack"
              size="small"
              icon="ArrowLeft"
              pl="0"
              onClick={() => history.push("/viewingrooms")}
            >
              {ButtonLabels.backToViewingRooms}
            </Button>
            <Typography variant="h2">{Headings.editViewingRoom}</Typography>
          </Box>
          <ViewingRoomForm
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            setValue={setValue}
            watch={watch}
            control={control}
            errors={errors}
            viewingRoom={viewingRoom}
          />
        </Box>
      </Box>
      <Modal id="artwork_details">
        <ArtworkDetailsModal />
      </Modal>
      <Modal id="additional_artworks" p="0">
        <AdditionalArtworksModal />
      </Modal>
    </Box>
  );
};

export default WithRequireAccess(EditViewingRoomScreen, 1);
