import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router-dom";
import { ArrowLeft, UploadCloud, X } from "react-feather";
import { useSelector } from "react-redux";
import { ButtonLabels, Headings, UiLabels } from "../../localisation";
import { createContact, addActionToQueue } from "../../store/actions";
import { WithRequireAccess } from "../hocs";
import { ContactForm } from "../react-material/forms/ContactForm";
import { NavigationBar } from "../react-material/NavigationBar/NavigationBar";
import { selectUser } from "../../store/selectors";
import { ContactSidebar } from "../modules";
import { Modal } from "../elements";
import { ContactUsFormModal } from "../modals";

const buttonStyle = {
  color: "white",
  fontFamily: "Inter",
  fontSize: "16px",
  lineHeight: "16px",
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.7,
  },
  "&:active": {
    opacity: 0.5,
  },
};

const submit = (values, dispatch) => {
  const { ContactGeneralFormSection = {}, ContactAdditionalFormSection = {} } = values;

  const { address1, address2, city, postalCode, state, country, ...ContactAdditionalFormSectionOtherValues } =
    ContactAdditionalFormSection;

  const types = ContactGeneralFormSection?.types?.map((data) => data.value);
  ContactGeneralFormSection.types = types;

  const tempContactId = uuid();
  return dispatch(
    addActionToQueue(
      createContact(
        {
          ...ContactGeneralFormSection,
          address: { address1, address2, city, postalCode, state, country },
          ...ContactAdditionalFormSectionOtherValues,
        },
        tempContactId
      ),
      tempContactId
    )
  );
};

const AddContactScreen = () => {
  const history = useHistory();
  const user = useSelector(selectUser);

  const renderFloatingActionPillButtons = (dispatch, form, submitting) => {
    return [
      <Button
        type="submit"
        variant="text"
        disabled={submitting}
        startIcon={<UploadCloud size="20px" />}
        sx={buttonStyle}
      >
        {ButtonLabels.saveContact}
      </Button>,

      <Button onClick={() => history.goBack()} type="button" variant="text" startIcon={<X size="20px" />} sx={buttonStyle}>
        {ButtonLabels.cancel}
      </Button>,
    ];
  };

  return (
    <Box width="100%">
      <Box maxWidth="98%" margin="0 auto">
        <NavigationBar />
      </Box>
      <Stack direction="row">
        <Stack pl="48px" pt="16px" pb="16px" alignItems="flex-start" flexGrow={1} minHeight="100vh" bgcolor="white">
          <Button
            sx={{ lineHeight: "16px", fontSize: "14px", pl: "0px" }}
            startIcon={<ArrowLeft style={{ position: "relative", right: "-5px", width: "16px", height: "16px" }} />}
            onClick={() => history.goBack()}
          >
            {ButtonLabels.backToContacts}
          </Button>
          <Stack direction="row">
            <Typography variant="h2" sx={{ color: "#6A6870" }}>
              {Headings.contacts}
            </Typography>
            <Typography variant="h2" sx={{ color: "#6A6870", m: "0 8px" }}>
              /
            </Typography>
            <Typography variant="h2">{Headings.newContact}</Typography>
          </Stack>
          <Stack mt="16px" mb="48px">
            <Typography sx={{ fontSize: "12px", fontWeight: 500, color: "#95939C" }}>{UiLabels.lastUpdated}</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {moment().format("MMMM Do YYYY, h:mm")} by {user.name}
            </Typography>
          </Stack>
          <ContactForm
            form="contact.add"
            selectedContactIds={[]}
            renderFloatingActionPillButtons={renderFloatingActionPillButtons}
            onSubmit={submit}
          />
        </Stack>
        <Stack>
          <ContactSidebar />
        </Stack>
      </Stack>
      <Modal id="contactUs">
        <ContactUsFormModal />
      </Modal>
    </Box>
  );
};

export default WithRequireAccess(AddContactScreen, 1);
