import React, { useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ReactHookFormInput } from "../../react-material/Input";
import { ReactHookFormInputSelect } from "../../react-material/InputSelect";
import { ReactHookFormCheckBox } from "../Checkbox";
import { ReactHookFormRadioSelect } from "../../react-material/RadioSelect/ReactHookFormRadioSelect";
import { InputLabels, InputErrors } from "../../../localisation";
import { useDispatch, useSelector } from "react-redux";
import { FloatingActionPill } from "../FloatingActionPill";
import { selectSelectedListIds, selectListById } from "../../../store/selectors";
import _ from "lodash";
import { Flex } from "../../fundamentals";

const labelStyle = {
  minWidth: "150px",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#6A6870",
  fontFamily: "Inter",
};

const stackStyle = {
  borderBottom: "1px solid #C3C2C9",
  alignItems: "center",
  justifyContent: "space-between",
  height: "50px",
};

const sectionHeadingStyle = {
  fontFamily: "Inter",
  marginBottom: "4px",
};

const inputStyle = {
  flexGrow: 1,
  position: "relative",
  marginBottom: "-1px",
};

const inputSelectStyle = {
  height: "50px",
  minWidth: "185px",
};

const RenderField = ({ section, field, fieldIndex, errors, control, setValue }) => {
  const fieldError = _.get(errors, field.fieldName);
  return (
    <Box marginBottom={fieldIndex === section.fields.length - 1 ? "0px" : "s"}>
      <Box marginBottom={fieldError && "20px"}>
        {/* Plain Input Field */}
        {field.field === "Input" && !field.isTextarea && (
          <Stack sx={stackStyle} direction="row">
            <Typography sx={labelStyle}>{field.label}</Typography>
            <ReactHookFormInput
              name={field.fieldName}
              control={control}
              errors={errors}
              isRequired={field.isRequired ? true : false}
              errorMessage={InputErrors.genericRequiredField}
              type={field.isNumber ? "number" : "text"}
              setValue={setValue}
              customRootStyles={{
                ...inputStyle,
                bottom: fieldError && "-12px",
                "&:hover": {
                  marginBottom: fieldError ? "-2px" : "-1px",
                  height: fieldError ? null : "49px",
                },
              }}
              customInputStyles={{
                textAlign: field.isNumber ? "right" : "left",
              }}
            />
          </Stack>
        )}
        {/* Textarea Input Field */}
        {field.field === "Input" && field.isTextarea && (
          <Box borderBottom="1px solid #C3C2C9" marginTop="15px">
            <Stack direction="column">
              <Typography sx={labelStyle}>{field.label}</Typography>
              <ReactHookFormInput
                name={field.fieldName}
                control={control}
                errors={errors}
                isRequired={field.isRequired ? true : false}
                errorMessage={InputErrors.genericRequiredField}
                multiline={true}
                setValue={setValue}
                customRootInputStyles={{
                  paddingTop: "0px",
                  "&:hover": {
                    backgroundColor: "#F3F4FF",
                    borderBottom: "2px solid #C8CEFF",
                    marginBottom: "-2px",
                  },
                  "&:before": {
                    content: "none",
                  },
                  "&:after": {
                    borderBottom: "2px solid #5E54FF",
                  },
                  "&.Mui-focused": {
                    borderBottom: "none",
                    marginBottom: "-2px",
                  },
                }}
                customInputStyles={{
                  paddingLeft: "0px",
                }}
              />
            </Stack>
          </Box>
        )}
        {/* Input Select Field */}
        {field.field === "InputSelect" && (
          <Stack sx={{ ...stackStyle, justifyContent: "start" }} direction="row">
            <Typography sx={labelStyle}>{field.label}</Typography>
            <ReactHookFormInputSelect
              name={field.fieldName}
              control={control}
              errors={errors}
              required={field.isRequired}
              errorMessage={InputErrors.genericRequiredField}
              options={field.options.map((option) => ({
                value: option.value,
                label: option.label,
              }))}
              customInputSelectStyle={inputSelectStyle}
            />
          </Stack>
        )}
        {/* Checkbox Field */}
        {field.field === "Checkbox" && (
          <ReactHookFormCheckBox
            name={field.fieldName}
            control={control}
            errors={errors}
            label={field.label}
            required={field.isRequired}
          />
        )}
        {/* Radio Select Field */}
        {field.field === "RadioSelect" && (
          <Box mt="32px">
            <ReactHookFormRadioSelect
              name={field.fieldName}
              control={control}
              errors={errors}
              label={field.label}
              required={field.isRequired}
              options={field.options}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const RenderSection = ({ section, errors, control, setValue }) => {
  return (
    <>
      {section.isSection && (
        <Typography
          sx={{
            ...sectionHeadingStyle,
            marginTop: "32px",
            fontSize: section.sectionLabel === "Include" ? "14px" : "16px",
            color: section.sectionLabel === "Include" ? "#6A6870" : "#2a2a2f",
          }}
        >
          {section.sectionLabel}
        </Typography>
      )}
      {section.fields.map((field, index) => (
        <RenderField
          key={`${index}-${field}`}
          section={section}
          field={field}
          fieldIndex={index}
          errors={errors}
          control={control}
          setValue={setValue}
        />
      ))}
    </>
  );
};

export const ListExportForm = ({
  handleSubmit,
  onSubmit,
  setValue,
  control,
  errors,
  renderFloatingActionPillButtons,
  submitRef,
}) => {
  const dispatch = useDispatch();
  const fixedPillRef = useRef(null);
  const selectedListId = useSelector(selectSelectedListIds)[0];
  const list = useSelector((state) => selectListById(state, selectedListId));

  return (
    // <Stack sx={{ width: "100%" }}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex flex="1" flexDirection="column">
        <Box marginBottom={errors.title && "20px"}>
          <Stack sx={stackStyle} direction="row">
            <Typography sx={labelStyle}>{InputLabels.title}</Typography>
            <ReactHookFormInput
              name="title"
              control={control}
              errors={errors}
              isRequired={true}
              errorMessage={InputErrors.noTitle}
              type="text"
              customRootStyles={{
                ...inputStyle,
                bottom: errors.title && "-12px",
                "&:hover": {
                  marginBottom: errors.title ? "-2px" : "-1px",
                  height: errors.title ? null : "49px",
                },
              }}
              setValue={setValue}
            />
          </Stack>
        </Box>
        {list?.fieldTypes.map((item, index) => (
          <RenderSection
            key={`${index}-${item}`}
            section={item}
            errors={errors}
            control={control}
            setValue={setValue}
          />
        ))}
        {/* {renderFloatingActionPillButtons && (
          <FloatingActionPill ref={fixedPillRef}>{renderFloatingActionPillButtons(dispatch)}</FloatingActionPill>
        )} */}
      </Flex>
      {/* Invisible button to submit from modal rendered in parent component */}
      <button ref={submitRef} type="submit" style={{ display: "none" }} />
    </form>
    // </Stack>
  );
};
