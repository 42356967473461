import DatePicker from "react-datepicker";
import styled from "styled-components";

const Wrapper = styled.div`
  .react-datepicker__input-container input {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 16px 6px;
  }
`;

const DateInput = ({ input, placeholder, minDate, maxDate, startDate }) => {
  return (
    <Wrapper>
      <DatePicker
        value={input.value}
        selected={input.value || null}
        onChange={input.onChange}
        placeholderText={placeholder}
        showIcon
        dateFormat="dd/MM/yyyy"
        minDate={minDate}
        maxDate={maxDate}
        startDate={startDate}
        popperProps={{strategy: 'fixed'}}
        popperPlacement="top" 
        popperModifiers={{ flip: { behavior: ["top"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }} 
/>
    </Wrapper>
  );
};

export { DateInput };
