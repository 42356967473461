import React, { useEffect } from "react";
import styled from "styled-components";
import { Box, Flex, Text } from "../fundamentals";
import { Modal } from "../elements";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OnboardingForm } from "../forms/PaymentInviteForms/Onboarding/OnboardingForm";
import { WithRequireAccess, } from "../hocs";
import { selectUser } from "../../store/selectors";
import { useHistory } from "react-router-dom";
import { NavigationBar } from "../react-material/NavigationBar";
import { AlertModal } from "../modals";
import { openModal } from "../../store/actions";

const StyledMarketplaceOnboardingScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const MarketplaceOnboardingScreen = () => {
  const user = useSelector(selectUser);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
        if ((user.account.marketplaceOnboarded && user.account.idVerified))
      history.push("/artworks");
  }, []);

  // useEffect(() => {
  //   dispatch(openModal("marketplace_onboarding"));
  // },[])

  return (
    <StyledMarketplaceOnboardingScreen bg="white">
      <NavigationBar />
      <Box display="flex" justifyContent="center" alignItems="center" pt="hyper">
        {/* Box to be replaced with a modal for logged in users */}
        <Box
          background="white"
          borderRadius="10px"
          boxShadow="medium"
        >
          <Box>
            <OnboardingForm />
          </Box>
        </Box>
        {/* <Modal p="0" id="marketplace_onboarding">
          <Box width="880px">
            <OnboardingForm />
          </Box>
        </Modal> */}
      </Box>
    </StyledMarketplaceOnboardingScreen>
  );
};

export default WithRequireAccess(MarketplaceOnboardingScreen, 0);