import React, { useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, TableRow, Stack, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Edit2, Trash2, Download } from "react-feather";
import { 
  replaceSelectedList,
  openModal,
  addSelectedList,
  removeSelectedList,
  addSelectedArtwork,
  clearAllSelectedArtworks,
  downloadListPdf
} from "../../../store/actions";
import { selectSelectedListIds } from "../../../store/selectors";
import DoneIcon from "@mui/icons-material/Done";
import { DateTime } from "luxon";

const ListItem = ({ list, index, deleteListRow }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [hoverList, handleOnHover] = useState({});
  const [hoverExportIcon, handleOnHoverExportIcon] = useState({});
  const [hoverEditIcon, handleOnHoverEditIcon] = useState({});
  const [hoverDeleteIcon, handleOnHoverDeleteIcon] = useState({});
  const selectedListIds = useSelector(selectSelectedListIds);
  const isListSelected = _.includes(selectedListIds, list._id);
  const labelId = `enhanced-table-checkbox-${index}`;
  const artistNames = list?.artworks.map(artwork => artwork?.artist?.name);
  const distinctArtistNames = [...new Set(artistNames)];

  const handleOnListClick = () => {
    dispatch(replaceSelectedList(list._id));
  };

  const handleOnExportListClick = () => {
    dispatch(downloadListPdf(list));
  }

  const handleOnEditListClick = () => {
    dispatch(replaceSelectedList(list._id));
    dispatch(clearAllSelectedArtworks());
    list.artworks.map(artwork => {
      dispatch(addSelectedArtwork(artwork.id))
    });
    history.push("/lists/edit");
  };

  const handleOnDeleteListClick = () => {
    deleteListRow([list._id]);
    dispatch(openModal("delete_list_row"));
  };

  const handleOnListSelect = (event) => {
    event.stopPropagation();

    if (isListSelected) {
      dispatch(removeSelectedList(list._id));
    } else {
      dispatch(addSelectedList(list._id));
    }
  };

  const displayListCategory = (category) => {
    switch (category) {
      case "portfolio":
        return "Portfolio";
        break;
      case "workList":
        return "Work List";
        break;
      case "transportList":
        return "Transport List";
        break;
      case "insuranceList":
        return "Insurance List";
        break;
      case "priceList":
        return "Price List";
        break;
      case "invoice":
        return "Invoice";
        break;
      case "loanAgreement":
        return "Loan Agreement";
        break;
      case "consignmentAgreement":
        return "Consignment Agreement";
        break;
      case "handoff":
        return "Handoff Document";
        break;
      case "certificate":
        return "Certificate";
        break;
      case "conditionReport":
        return "Condition Report";
        break;
      case "installationManual":
        return "Installation Manual";
        break;
      case "dataSheet":
        return "Date Sheet";
        break;
      case "storageLabels":
        return "Storage Label";
        break;
      case "wallLabels":
        return "Wall Label";
        break;
      default:
        return "List"
    };
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isListSelected}
      tabIndex={-1}
      key={list._id}
      selected={isListSelected}
      sx={{
        "&.MuiTableRow-root": {
          backgroundColor: isListSelected ? "#EFEFF0" : "#F4F4F5",
          "&:hover": { backgroundColor: "#EFEFF0" },
        },
      }}
      onMouseEnter={() => handleOnHover(list)}
      onMouseLeave={() => handleOnHover({})}
    >
      <TableCell padding="checkbox">
        {(hoverList._id === list._id || isListSelected) && (
          <Box
            sx={{
              borderRadius: "4px",
              width: "16px",
              height: "16px",
              backgroundColor: isListSelected && "#5E54FF",
              border: !isListSelected && "2px solid #95939C",
              p: isListSelected && ".1rem",
              ml: ".7rem",
            }}
            checked={isListSelected}
            onClick={handleOnListSelect}
          >
            {isListSelected && (
              <DoneIcon
                style={{
                  position: "relative",
                  top: "-1px",
                  left: "0px",
                  color: "white",
                  borderRadius: "4px",
                  width: "16px",
                  height: "16px",
                }}
              />
            )}
          </Box>
        )}
      </TableCell>
      <TableCell
        onClick={handleOnListClick}
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        sx={{ color: isListSelected && "#5E54FF" }}
      >
        {list?.title}
      </TableCell>
      <TableCell 
        onClick={handleOnListClick}
        sx={{ 
          color: isListSelected && "#5E54FF",
          whiteSpace:"nowrap" ,
          overflow:"hidden" ,
          textOverflow:"ellipsis" ,
          maxWidth:"10ch"
        }}
      >
        {distinctArtistNames.map(name => name).join(", ")}
      </TableCell>
      <TableCell onClick={handleOnListClick} sx={{ color: isListSelected && "#5E54FF" }}>
        {displayListCategory(list?.category)}
      </TableCell>
      <TableCell onClick={handleOnListClick} sx={{ color: isListSelected && "#5E54FF" }}>
        {list?.artworks.length}
      </TableCell>
      <TableCell onClick={handleOnListClick} sx={{ color: isListSelected && "#5E54FF" }}>
        {DateTime.fromISO(list?.dateCreated).toFormat("dd MMM yyyy")}
      </TableCell>
      <TableCell onClick={handleOnListClick} sx={{ color: isListSelected && "#5E54FF" }}>
        {DateTime.fromISO(list?._lastModified).toFormat("dd MMM yyyy")}
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} justifyContent="center">
          <IconButton 
            onClick={handleOnExportListClick}
            onMouseEnter={() => handleOnHoverExportIcon(list)}
            onMouseLeave={() => handleOnHoverExportIcon({})}
          >
            <Download style={{ cursor:"pointer", width: "20px", height: "20px", color: list._id === hoverExportIcon._id && "#5E54FF" }} />
          </IconButton>
          <IconButton 
            onClick={handleOnEditListClick}
            onMouseEnter={() => handleOnHoverEditIcon(list)}
            onMouseLeave={() => handleOnHoverEditIcon({})}
          >
            <Edit2 style={{ cursor:"pointer", width: "20px", height: "20px", color: list._id === hoverEditIcon._id && "#5E54FF" }} />
          </IconButton>
          <IconButton 
            onClick={handleOnDeleteListClick}
            onMouseEnter={() => handleOnHoverDeleteIcon(list)}
            onMouseLeave={() => handleOnHoverDeleteIcon({})}  
          >
            <Trash2 style={{ cursor:"pointer", width: "20px", height: "20px", color: list._id === hoverDeleteIcon._id && "#5E54FF" }} />
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export { ListItem };
