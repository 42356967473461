import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../store/actions";
import { Box, Text, Flex } from "../fundamentals";
import { Button } from "../elements";
import { ButtonLabels } from "../../localisation";

const TwoActionModal = ({
  title = "",
  message = "",
  highlightWords = [],
  primaryButtonText,
  primaryAction,
  secondaryButtonText,
  secondaryAction,
  width,
}) => {
  const dispatch = useDispatch();

  return (
    <Box width={width}>
      <Text size="heading3" mb="s" color="black">
        {title}
      </Text>
      <Text fontSize="small" mb="s">
        {message.split(" ").map((word, i) =>
          highlightWords.find((highlightWord) => highlightWord.match(word)) ? (
            <Text key={i} fontSize="small" color="black" fontWeight="500">
              {word + " "}
            </Text>
          ) : (
            <Text color="#6A6870" key={i}>
              {word + " "}
            </Text>
          )
        )}
      </Text>
      <Flex>
        <Button
          buttonStyle="primary"
          size="medium"
          onClick={() => {
            dispatch(closeModal());
            primaryAction();
          }}
          mt="s"
          mr="xs"
        >
          {primaryButtonText ?? ButtonLabels.continue}
        </Button>
        <Button
          buttonStyle="secondary"
          size="medium"
          onClick={() => {
            dispatch(closeModal());
            secondaryAction();
          }}
          mt="s"
        >
          {secondaryButtonText ?? ButtonLabels.cancel}
        </Button>
      </Flex>
    </Box>
  );
};

export { TwoActionModal };
