import { Tooltip as MuiTooltip } from "@mui/material";

const Tooltip = ({children, title, placement, color, bgColor, fontSize, fontWeight, padding, lineHeight, followCursor,  ...props}) => {
    return (
        <MuiTooltip
        {...props}
        title={title}
        arrow
        placement={placement || "bottom"}
        followCursor={followCursor ?? false}
        PopperProps={{
          sx: {
            "& .MuiTooltip-tooltip": {
              backgroundColor: bgColor ?? "#F3F4FF",
              color: color ?? "#2B2A2E",
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.16)",
              fontFamily: "Inter",
              fontWeight: fontWeight ?? "400",
              fontSize: fontSize ?? "12px",
              padding: padding ?? "6px 8px",
              lineHeight: lineHeight ?? "12px"
            },
            "& .MuiTooltip-arrow": {
              "&::before": {
                backgroundColor: bgColor ?? "#F3F4FF",
              },
            },
          },
        }}
        >
            {children}
        </MuiTooltip>
    )
};

export { Tooltip };