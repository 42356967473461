import produce from "immer";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import {
  CREATE_SESSION_DISCOVERY_SUCCESS,
  GET_DISCOVERY_LOAD_REQUEST,
  GET_DISCOVERY_LOAD_SUCCESS,
  GET_DISCOVERY_LOAD_FAILURE,
  GET_DISCOVERY_EVENTS_SUCCESS,
  GET_DISCOVERY_EVENTS_FAILURE,
  UPDATE_DISCOVERY_FILTERS,
  GET_DISCOVERY_FAVOURITES_REQUEST,
  GET_DISCOVERY_FAVOURITES_SUCCESS,
  GET_DISCOVERY_FAVOURITES_FAILURE,
  SIGN_OUT_SUCCESS,
} from "../actions/types";

const DISCOVERY_INITIAL_STATE = {
  sessionId: undefined,
  firstTime: false,
  isLoading: false,
  isLoadingMore: false,
  loaded: false,
  artworks: [],
  favourites: [],
  events: [],
  filters: {
    media: [],
    price: {
      min: 0,
      max: 0,
    },
    size: [],
    events: [],
    isFinal: false,
  },
};

const discoveryReducer = (state = DISCOVERY_INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case CREATE_SESSION_DISCOVERY_SUCCESS:
        return createSessionDiscoverySuccess(draftState, action);
      case GET_DISCOVERY_LOAD_REQUEST:
        return getDiscoveryLoadRequest(draftState, action);
      case GET_DISCOVERY_LOAD_SUCCESS:
        return getDiscoveryLoadSuccess(draftState, action);
      case GET_DISCOVERY_LOAD_FAILURE:
        return getDiscoveryLoadFailure(draftState, action);
      case GET_DISCOVERY_EVENTS_SUCCESS:
        return getDiscoveryEventsSuccess(draftState, action);
      case GET_DISCOVERY_EVENTS_FAILURE:
        return getDiscoveryEventsFailure(draftState, action);
      case UPDATE_DISCOVERY_FILTERS:
        return updateDiscoveryFilters(draftState, action);
        case GET_DISCOVERY_FAVOURITES_REQUEST:
        return getDiscoveryFavouritesRequest(draftState, action);
      case GET_DISCOVERY_FAVOURITES_SUCCESS:
        return getDiscoveryFavouritesSuccess(draftState, action);
      case GET_DISCOVERY_FAVOURITES_FAILURE:
        return getDiscoveryFavouritesFailure(draftState, action);
      case SIGN_OUT_SUCCESS:
        return DISCOVERY_INITIAL_STATE;
      default:
        return state;
    }
  });
};

const createSessionDiscoverySuccess = (draftState, action) => {
  const { id, firstTime } = action.payload;
  draftState.sessionId = id;
  draftState.firstTime = firstTime;
};

const getDiscoveryLoadRequest = (draftState, action) => {
  const { isLoadMore } = action.payload;
  return {
    ...draftState,
    isLoading: isLoadMore ? false : true,
    isLoadingMore: isLoadMore ? true : false,
  };
};

const getDiscoveryLoadSuccess = (draftState, action) => {
  const { artworks, recommendationId, isLoadMore, isFinal } = action.payload;
  const artworksList = artworks.map(({ images, ...artwork }) => {
    const imageList = images.map((image) => {
      return { key: uuid(), ...image, isFromServer: true, isModified: false, isActive: true };
    });
    return {
      ...artwork,
      images: _.sortBy(imageList, ["sortIndex"]),
    };
  });

  return {
    ...draftState,
    artworks: isLoadMore ? draftState.artworks.concat(artworksList) : artworksList,
    recommendationId,
    isLoading: false,
    isLoadingMore: false,
    loaded: true,
    isFinal
  };
};

const getDiscoveryLoadFailure = (draftState, action) => {
  return {
    ...draftState,
    isLoading: false,
    isLoadingMore: false,
    loaded: true,
  };
};

const getDiscoveryEventsSuccess = (draftState, action) => {
  const { events } = action.payload;

  return {
    ...draftState,
    events: _.sortBy(events, ["eventName"]),
  };
};

const getDiscoveryEventsFailure = (draftState, action) => {
  return {
    ...draftState,
    isLoading: false,
    loaded: true,
  };
};

const updateDiscoveryFilters = (draftState, action) => {
  const { events } = action.payload;
  return {
    ...draftState,
    filters: {
      ...draftState.filters,
      events,
    }
  };
};

const getDiscoveryFavouritesRequest = (draftState, action) => {
  return {
    ...draftState,
    isLoading: true,
  };
};

const getDiscoveryFavouritesSuccess = (draftState, action) => {
  const { artworks } = action.payload;
  const favouriteArtworksList = artworks.map(({ images, ...artwork }) => {
    const imageList = images.map((image) => {
      return { key: uuid(), ...image, isFromServer: true, isModified: false, isActive: true };
    });
    return {
      ...artwork,
      images: _.sortBy(imageList, ["sortIndex"]),
    };
  });

  return {
    ...draftState,
    favourites : favouriteArtworksList,
    isLoading: false,
    loaded: true,
  };
};

const getDiscoveryFavouritesFailure = (draftState, action) => {
  return {
    ...draftState,
    isLoading: false,
    loaded: true,
  };
};

export default discoveryReducer;
