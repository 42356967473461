import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { space, border, typography, color } from "styled-system";

const StyledHyperlink = styled.a`
  text-decoration: ${(props) => (props.textDecoration ? props.textDecoration : "none")};
  ${space}
  ${color}
  ${border}
  ${typography}
  &:hover {
    color: ${(props) => props.hoverColor || "#5e54ff"} ;
  }
  ${(props) => props.fontFamily && `font-family: ${props.fontFamily}`}
`;

const StyledRouterLink = styled(RouterLink)`
  ${(props) => props.textDecoration && `text-decoration: ${props.textDecoration};`}
  ${space}
  ${border}
  ${typography}
  ${color}
`;

const RoundStyledLink = styled(StyledRouterLink)`
  text-decoration: none;
  color: black;
  border: 3px solid black;
  border-radius: 20px;
  &:hover {
    color: white;
    background-color: black;
  }
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}` : `font-size: ${"1.3rem"}`)};
  ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight}` : `font-weight: ${600}`)};
`;

export const Link = ({ children, isRound = false, ...props }) => {
  if (isRound) return <RoundStyledLink {...props}>{children}</RoundStyledLink>;

  const StyledLink = props.to ? StyledRouterLink : StyledHyperlink;
  return <StyledLink {...props}>{children}</StyledLink>;
};
