import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Flex, Box, Text, Icon, ImageWithCache } from "../fundamentals";
import { Button } from "../elements";
import {
  Headings,
  ButtonLabels,
  LinkLabels,
  UiLabels,
  Messages,
  PaymentInviteSellerNextSteps,
} from "../../localisation";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Typography } from "@mui/material";
import { selectArtworksById, selectPaymentInviteById, selectContactsById } from "../../store/selectors";
import { closeModal } from "../../store/actions";
import { Popover } from "../react-material/Popover";
import getSymbolFromCurrency from "currency-symbol-map";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "24px",
    lineHeight: mobile ? "26px" : "32px",
    marginBottom: "12px",
    fontWeight: "400",
  };
};

const nextSteps = [
  {
    title: PaymentInviteSellerNextSteps.stepOne,
    description: PaymentInviteSellerNextSteps.stepOneDesc,
    icon: "CreditCard",
  },
  {
    title: PaymentInviteSellerNextSteps.stepTwo,
    description: PaymentInviteSellerNextSteps.stepTwoDesc,
    icon: "Truck",
  },
  {
    title: PaymentInviteSellerNextSteps.stepThree,
    description: PaymentInviteSellerNextSteps.stepThreeDesc,
    icon: "Image",
  },
  {
    title: PaymentInviteSellerNextSteps.stepFour,
    description: PaymentInviteSellerNextSteps.stepFourDesc,
    icon: "DollarSign",
  },
];

const PaymentInviteConfirmationModal = ({ paymentInviteId }) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const isMobile = useIsScreenMobile();
  const dispatch = useDispatch();
  const history = useHistory();
  const paymentInvite = useSelector((state) => selectPaymentInviteById(state, paymentInviteId));
  const artworksToBeSoldIds = paymentInvite?.artworks?.map((artwork) => artwork._id);
  const artworksToBeSold = useSelector((state) => selectArtworksById(state, artworksToBeSoldIds));
  const contactIds = paymentInvite?.contacts?.map((contact) => contact._id);
  const contacts = useSelector((state) => selectContactsById(state, contactIds));
  const currency = paymentInvite?.artworks[0]?.price?.currency;
  const currencySymbol = getSymbolFromCurrency(currency);

  // Calculate total of all artwork prices minus their discounts if these exist
  const totalArtworksPrice = paymentInvite?.artworks.reduce((total, artwork) => {
    const artworkPrice = artwork.price.value;
    // If the artwork has discounts, subtract them from the artwork price
    if (artwork.discounts && artwork.discounts.length > 0) {
      const discountTotal = artwork.discounts.reduce((total, discount) => {
        return total + discount.value;
      }, 0);
      return total + (artworkPrice - discountTotal);
    }
    // Otherwise, add the artwork price to the total
    return total + artworkPrice;
  }, 0);

  // Calculate the total cost
  const totalCost = paymentInvite?.costs.reduce((total, cost) => {
    return total + cost.value;
  }, 0);

  // Calculate fee
  const calculateProcessingFee = (percentage) => {
    const amount = ((totalArtworksPrice + totalCost) * percentage) / 100;
    return amount;
  };

  const handleFeeInfoClick = (e) => {
    setPopoverAnchorEl(e.currentTarget);
  };

  const handleGoToArtworkSalesClick = () => {
    history.push("/activity/sales");
    dispatch(closeModal("payment_invite_buyer_confirmation"));
  };

  return (
    <Flex justifyContent="space-between" width="880px">
      {/* Left side */}
      <Box mr="xxl">
        <Typography sx={headerTextStyle(isMobile)}>{Headings.confirmPaymentInviteShare}</Typography>
        <Flex mt="18px" mb="30px" width="440px" overflowX="auto" alignItems="start">
          {artworksToBeSold.map((artwork) => {
            const { images = [] } = artwork;
            const [firstImage = {}] = images;
            return (
              <Box key={artwork.id} boxShadow="small" minWidth="180px" maxWidth="180px" mr="m" mb="xxs" padding="s">
                <ImageWithCache
                  image={firstImage}
                  artworkId={artwork.id}
                  urlSuffix="/preview.jpg"
                  width="100%"
                  userselect="none"
                  mb="s"
                />
                <Text fontSize="small">{artwork.artist}</Text>
                <Text fontSize="smaller" color="grey.80">
                  {artwork.artworkValues.title}
                </Text>
                <Text fontSize="smaller" color="blue.60" fontWeight="500">
                  {currencySymbol}
                  {
                    paymentInvite?.artworks.filter((paymentInviteArtwork) => paymentInviteArtwork._id === artwork.id)[0]
                      .price.value
                  }
                </Text>
              </Box>
            );
          })}
        </Flex>
        <Box border="1px solid #C8CEFF" borderRadius="8px" padding="l" fontSize="small" mb="xs">
          <Flex justifyContent="space-between" mb="xs">
            <Text>{UiLabels.validUntil}</Text>
            <Text fontWeight="500" color="blue.60">
              {DateTime.fromISO(paymentInvite?.inviteValidity).toFormat("dd LLL yyyy")}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text>{UiLabels.recipients}</Text>
            <Box>
              {contacts.map((contact, index) => (
                <Text
                  key={contact.label}
                  textAlign="right"
                  fontWeight="500"
                  color="blue.60"
                  mb={index !== paymentInvite?.contacts.length - 1 ? "xxxs" : "0"}
                >
                  {contact.email}
                </Text>
              ))}
            </Box>
          </Flex>
        </Box>
        <Box border="1px solid #C8CEFF" borderRadius="8px" padding="l" fontSize="small" mb="30px">
          <Flex justifyContent="space-between" mb="xs">
            <Text>{UiLabels.totalBuyerPays}</Text>
            <Text fontWeight="500" color="blue.60">
              {currencySymbol}
              {totalArtworksPrice + totalCost + calculateProcessingFee(paymentInvite?.processingFee)}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" pb="xs" borderBottom="1px solid #C3C2C9">
            <Flex onClick={handleFeeInfoClick} cursor="pointer">
              <Text>{UiLabels.ourFee}</Text>
              <Icon icon="HelpCircle" size="14" ml="xxxs" color="blue.60" cursor="pointer"></Icon>
            </Flex>
            <Text fontWeight="500" color="blue.60">
              -{currencySymbol}
              {calculateProcessingFee(paymentInvite?.processingFee)}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" mt="xs" fontSize="medium" fontWeight="500">
            <Text>{UiLabels.totalYouReceive}</Text>
            <Text color="blue.60">
              {currencySymbol}
              {totalArtworksPrice + totalCost}
            </Text>
          </Flex>
        </Box>
        <Button buttonStyle="primary" size="small" fontWeight="500" onClick={handleGoToArtworkSalesClick}>
          {ButtonLabels.goToArtworkSales}
        </Button>
        <Popover
          popoverAnchorEl={popoverAnchorEl}
          setPopoverAnchorEl={setPopoverAnchorEl}
          text={Messages.paymentInviteMessage(paymentInvite?.processingFee)}
          linkText={LinkLabels.learnMore}
          link="https://www.simplify.art/payment-invites"
          icon="HelpCircle"
        />
      </Box>
      {/* Right Side */}
      <Flex
        flexDirection="column"
        backgroundColor="blue.60"
        width="45%"
        color="white"
        padding="xxl"
        justifyContent="end"
      >
        <Text fontFamily="bodyDmSans" fontSize="heading3">
          {Headings.nextSteps}
        </Text>
        {nextSteps.map((step) => (
          <Flex key={step.title} mt="m" alignItems="start">
            <Box borderRadius="50%" backgroundColor="white" padding="xxxs" mr="s">
              <Icon icon={step.icon} size="16" color="blue.60" />
            </Box>
            <Box>
              <Text fontWeight="500" mb="xxxs">
                {step.title}
              </Text>
              <Text fontSize="small">{step.description}</Text>
            </Box>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export { PaymentInviteConfirmationModal };
