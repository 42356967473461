import { v4 as uuid } from "uuid";
import {
  CREATE_ARTWORK,
  CREATE_ARTWORK_AND_COPY,
  CREATE_ARTWORK_AND_NEW,
  CREATE_ARTWORK_LOCALLY,
  CREATE_ARTWORK_SUCCESS,
  CREATE_ARTWORK_FAILURE,
  UPLOAD_ARTWORK_IMAGE,
  UPLOAD_ARTWORK_IMAGE_SUCCESS,
  UPLOAD_ARTWORK_IMAGE_FAILURE,
  UPDATE_ARTWORK_IMAGE_UPLOAD_PROGRESS,
  EDIT_ARTWORK,
  EDIT_ARTWORK_LOCALLY,
  EDIT_MULTIPLE_ARTWORKS,
  UPDATE_ARTWORK_AFTER_MULTI_EDIT,
  EDIT_ARTWORK_SUCCESS,
  EDIT_ARTWORK_FAILURE,
  UPDATE_ARTWORK_STATUS,
  DELETE_ARTWORKS,
  DELETE_ARTWORK,
  DELETE_ARTWORK_SUCCESS,
  DELETE_ARTWORK_FAILURE,
  SHARE_ARTWORK,
  SAVE_SHARED_ARTWORK,
  SAVE_SHARED_ARTWORK_LOCALLY,
  SAVE_SHARED_ARTWORK_SUCCESS,
  SAVE_SHARED_ARTWORK_FAILURE,
  CREATE_EXPORT,
  CREATE_EXPORT_SUCCESS,
  CREATE_EXPORT_FAILURE,
  CREATE_CSV,
  CREATE_CSV_SUCCESS,
  CREATE_CSV_FAILURE,
  LOCAL_UPLOAD_ARTWORK_IMAGE,
  DOWNLOAD_ARCHIVE,
  SYNC_ARCHIVE,
  IMPORT_ARCHIVE_REQUEST,
  IMPORT_ARCHIVE_SUCCESS,
  IMPORT_ARCHIVE_FAILURE,
  DOWNLOAD_ARCHIVE_TEMPLATE,
  EXPORT_ARCHIVE_REQUEST,
  EXPORT_ARCHIVE_SUCCESS,
  EXPORT_ARCHIVE_FAILURE,
} from "./types";
import { createFormAction } from "redux-form-saga";

export const createArtwork = ({ artist, status, customId, images, collection, ...formValues }, tempArtworkId) => {
  return {
    type: CREATE_ARTWORK,
    payload: {
      tempArtworkId,
      dateCreated: new Date().toISOString(),
      artist,
      status,
      customId,
      images,
      collection,
      artworkValues: formValues
    }
  };
};

export const createArtworkAndCopy = ({ artist, status, customId, images, collection, ...formValues }, tempArtworkId) => {
  return {
    type: CREATE_ARTWORK_AND_COPY,
    payload: {
      tempArtworkId,
      dateCreated: new Date().toISOString(),
      artist,
      status,
      customId,
      images,
      collection,
      artworkValues: formValues
    }
  };
};

export const createArtworkAndNew = ({ artist, status, customId, images, collection, ...formValues }, tempArtworkId) => {
  return {
    type: CREATE_ARTWORK_AND_NEW,
    payload: {
      tempArtworkId,
      dateCreated: new Date().toISOString(),
      artist,
      status,
      customId,
      images,
      collection,
      artworkValues: formValues
    }
  };
};

export const createArtworkLocally = artwork => {
  return {
    type: CREATE_ARTWORK_LOCALLY,
    payload: { ...artwork }
  };
};

export const createArtworkSuccess = (tempArtworkId, response) => {
  return { type: CREATE_ARTWORK_SUCCESS, payload: { tempArtworkId, ...response } };
};

export const createArtworkFailure = () => {
  return { type: CREATE_ARTWORK_FAILURE, payload: {} };
};

export const uploadArtworkImage = (artworkId, imageLocalId, image, type, documentConnectionId, documentType, description) => {
  return { type: UPLOAD_ARTWORK_IMAGE, payload: { artworkId, imageLocalId, image, type, documentConnectionId, documentType, description } };
};

export const uploadArtworkImageSuccess = (artworkId, connectionId, imageToConnect, type) => {
  return { type: UPLOAD_ARTWORK_IMAGE_SUCCESS, payload: { artworkId, connectionId, imageToConnect, type } };
};

export const uploadArtworkImageFailure = () => {
  return { type: UPLOAD_ARTWORK_IMAGE_FAILURE, payload: {} };
};

export const updateArtworkImageUploadProgress = (id, imageKey, progress) => {
  return { type: UPDATE_ARTWORK_IMAGE_UPLOAD_PROGRESS, payload: { id, imageKey, progress } };
};

export const deleteArtworks = artworkIds => {
  return { type: DELETE_ARTWORKS, payload: { artworkIds } };
};

export const deleteArtwork = artworkId => {
  return { type: DELETE_ARTWORK, payload: { artworkId } };
};

export const deleteArtworkSuccess = artworkId => {
  return { type: DELETE_ARTWORK_SUCCESS, payload: { artworkId } };
};

export const deleteArtworkFailure = artworkId => {
  return { type: DELETE_ARTWORK_FAILURE, payload: { artworkId } };
};

export const editMultipleArtworks = (artworkIds, artworkData) => {
  const { artist, status, images, collection, ...formValues } = artworkData;
  return {
    type: EDIT_MULTIPLE_ARTWORKS,
    payload: {
      artworkIds,
      multiEditArtworkData: {
        lastModified: new Date().toISOString(),
        artist,
        status,
        images,
        artworkCollection: collection.id,
        artworkValues: formValues
      }
    }
  };
};

export const updateArtworkAfterMultiEdit = artworkId => {
  return {
    type: UPDATE_ARTWORK_AFTER_MULTI_EDIT,
    payload: { artworkId }
  };
};

export const editArtwork = ({ id, artist, status, customId, images, collection, ...formValues }) => {
  return {
    type: EDIT_ARTWORK,
    payload: {
      id,
      lastModified: new Date().toISOString(),
      artist,
      status,
      customId,
      images,
      artworkCollection: collection.id,
      artworkValues: formValues
    }
  };
};

export const editArtworkLocally = artwork => {
  return { type: EDIT_ARTWORK_LOCALLY, payload: { ...artwork } };
};

export const editArtworkSuccess = (artworkId, response) => {
  return { type: EDIT_ARTWORK_SUCCESS, payload: { artworkId, response } };
};

export const editArtworkFailure = () => {
  return { type: EDIT_ARTWORK_FAILURE, payload: {} };
};

export const updateArtworkStatus = (paymentInviteId, artworkId, placeId, isMarketPlace, status) => {
  return { type: UPDATE_ARTWORK_STATUS, payload: {paymentInviteId, artworkId, placeId, isMarketPlace, status}}
};

export const downloadArchive = (collections, artists, artworks, shareRecords) => {
  return { type: DOWNLOAD_ARCHIVE, payload: { collections, artists, artworks, shareRecords } };
};

export const syncArchive = (artworks, artists, recievedRecords, sentRecords) => {
  return { type: SYNC_ARCHIVE, payload: { artworks, artists, recievedRecords, sentRecords } };
};

export const shareArtwork = createFormAction(SHARE_ARTWORK);

export const saveSharedArtwork = (collectionId, artworkId) => {
  return { type: SAVE_SHARED_ARTWORK, payload: { collectionId, artworkId } };
};

export const saveSharedArtworkLocally = (collectionId, artworkId) => {
  return { type: SAVE_SHARED_ARTWORK_LOCALLY, payload: { collectionId, artworkId } };
};

export const saveSharedArtworkSuccess = () => {
  return { type: SAVE_SHARED_ARTWORK_SUCCESS, payload: {} };
};

export const saveSharedArtworkFailure = () => {
  return { type: SAVE_SHARED_ARTWORK_FAILURE, payload: {} };
};

export const createExport = (exportType, options, isDownload, isNewList) => {
  return { type: CREATE_EXPORT, payload: { exportType, options, isDownload, isNewList } };
};
export const createExportSuccess = () => {
  return { type: CREATE_EXPORT_SUCCESS, payload: {} };
};
export const createExportFailure = () => {
  return { type: CREATE_EXPORT_FAILURE, payload: {} };
};

export const createCsv = options => {
  return { type: CREATE_CSV, payload: { options } };
};
export const createCsvSuccess = () => {
  return { type: CREATE_CSV_SUCCESS, payload: {} };
};
export const createCsvFailure = () => {
  return { type: CREATE_CSV_FAILURE, payload: {} };
};

export const localUploadArtworkImage = () => {
  return { type: LOCAL_UPLOAD_ARTWORK_IMAGE, payload: {} };
};

export const importArchiveRequest = (csvFile) => {
  return { type: IMPORT_ARCHIVE_REQUEST, payload: { csvFile} };
};
export const importArchiveSuccess = () => {
  return { type: IMPORT_ARCHIVE_SUCCESS, payload: {} };
};
export const importArchiveFailure = () => {
  return { type: IMPORT_ARCHIVE_FAILURE, payload: {} };
};

export const downloadArchiveTemplate = () => {
  return { type: DOWNLOAD_ARCHIVE_TEMPLATE, payload: {} };
}

export const exportArchiveRequest = () => {
  return { type: EXPORT_ARCHIVE_REQUEST, payload: {} };
};
export const exportArchiveSuccess = () => {
  return { type: EXPORT_ARCHIVE_SUCCESS, payload: {} };
};
export const exportArchiveFailure = () => {
  return { type: EXPORT_ARCHIVE_FAILURE, payload: {} };
};
