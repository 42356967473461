import { useState, useEffect } from "react";
import { Box, Flex, Text, Icon } from "../fundamentals";
import moment from "moment";
import { Button } from "../elements";
import { ButtonLabels, UiLabels, Headings } from "../../localisation";
import { DEFAULT_PAYMENT_INVITE_STATUSES_BUYER } from "../../config";
import { useSelector, useDispatch } from "react-redux";
import { selectPaymentInviteById, selectUser } from "../../store/selectors";
import { downloadPaymentInviteInvoice, closeModal } from "../../store/actions";
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import { useIsScreenMobile } from "../hooks/useMediaQuery";

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "24px",
    lineHeight: mobile ? "26px" : "32px",
    marginBottom: "12px",
    fontWeight: "400",
  };
};

const PaymentInviteStatusModal = ({ paymentInviteId, placeId, isMarketPlace }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsScreenMobile();
  const [paymentInviteStatus, setPaymentInviteStatus] = useState("Created");
  const user = useSelector(selectUser);
  const isLoggedInUser = Object.keys(user).length > 0;
  const paymentInvite = useSelector((state) => selectPaymentInviteById(state, paymentInviteId));

  const getStatusName = (statusArray) => {
    if (statusArray?.length > 1 && statusArray[statusArray.length - 1]?.status_name === "Viewed") {
      setPaymentInviteStatus(statusArray[statusArray.length - 2]?.status_name);
    } else {
      setPaymentInviteStatus(statusArray.find((statusItem) => statusItem.is_latest)?.status_name);
    }
  };

  const paymentInviteArtworks = useSelector(
    (state) => state.payment_invite_artworks?.paymentInviteArtworks.byId[paymentInviteId]
  );
  //Create a combined artworks array from paymentInvite.artworks and paymentInviteArtworks that will contain the properties of both
  let combinedArtworksArray = [];
  if (paymentInviteArtworks) {
    combinedArtworksArray = paymentInvite?.artworks?.map(({ _id, price }) => {
      const restOfProperties = paymentInviteArtworks?.find((artwork) => artwork._id === _id);
      return { _id, price, ...restOfProperties };
    });
  }

  const handleButtonClick = () => {
    dispatch(closeModal());
    if (isMarketPlace) {
      history.push("/discovery");
    } else if (placeId) {
      history.push(`/viewingroom?viewingRoomId=${placeId}`);
    } else if (isLoggedInUser) {
      dispatch(closeModal());
      history.push("/artworks");
    } else {
      history.push("/register");
    }
  };

  useEffect(() => {
    getStatusName(paymentInvite?.status);
  }, []);

  return (
    <Box width={isMobile ? "100%" : "600px"} p={isMobile ? "m" : "xxl"}>
      <Typography sx={{ ...headerTextStyle(isMobile), marginBottom: "30px" }}>{Headings.purchaseStatus}</Typography>
      <Box border="1px solid #C8CEFF" borderRadius="8px" padding={isMobile ? "s" : "l"} mb="xxs">
        <Flex fontSize="small" mb="xs">
          <Box width="105px" mr="m">
            <Text color="grey.80">{UiLabels.dateGenerated}</Text>
          </Box>
          <Text>{moment(paymentInvite?.dateCreated).format("DD MMMM Y")}</Text>
        </Flex>
        <Flex fontSize="small" mb="xs">
          <Box width="105px" mr="m">
            <Text color="grey.80">{UiLabels.seller}</Text>
          </Box>
          <Text>{paymentInvite?._user?.name}</Text>
        </Flex>
        {/* <Flex fontSize="small" mb="xs">
          <Box width="105px" mr="m">
            <Text color="grey.80">{UiLabels.salePrice}</Text>
          </Box>
          {isLoggedInUser ? (
            <Text>
              {currencySymbol}
              {subtotal + processingFee}
            </Text>
          ) : (
            <Text color="transparent" textShadow="0 0 6px #000">
              {currencySymbol}
              {subtotal + processingFee}
            </Text>
          )}
        </Flex> */}
        <Flex fontSize="small" mb="xs">
          <Box width="105px" mr="m">
            <Text color="grey.80">{UiLabels.artist_s}</Text>
          </Box>
          {isLoggedInUser ? (
            <Text>{combinedArtworksArray?.map((artwork) => artwork?.artist).join(", ")}</Text>
          ) : (
            <Text
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth={isMobile ? "20ch" : "302px"}
              color="transparent"
              textShadow="0 0 6px #000"
            >
              {combinedArtworksArray?.map((artwork) => artwork?.artist).join(", ")}
            </Text>
          )}
        </Flex>
        <Flex fontSize="small" mb="xs">
          <Box width="105px" mr="m">
            <Text color="grey.80">{UiLabels.artworkTitles}</Text>
          </Box>
          {isLoggedInUser ? (
            <Text>{combinedArtworksArray?.map((artwork) => artwork.artworkValues?.title).join(", ")}</Text>
          ) : (
            <Text
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth={isMobile ? "20ch" : "302px"}
              color="transparent"
              textShadow="0 0 6px #000"
            >
              {combinedArtworksArray?.map((artwork) => artwork.artworkValues?.title).join(", ")}
            </Text>
          )}
        </Flex>
      </Box>
      {/* {!isLoggedInUser && (
        <Flex fontSize="smaller" color="grey.80">
          <Icon icon="HelpCircle" size="14" mr="xxxs" />
          <Text>Start a free account via the button below to see more details about your purchase</Text>
        </Flex>
      )} */}
      <Box border="1px solid #C8CEFF" borderRadius="8px" fontSize="small" padding="l" mt="30px" mb="30px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text>{UiLabels.status}</Text>
          <Box
            background={DEFAULT_PAYMENT_INVITE_STATUSES_BUYER[paymentInviteStatus]?.color}
            p="4px 12px"
            borderRadius="4px"
            width="fit-content"
          >
            <Text>{DEFAULT_PAYMENT_INVITE_STATUSES_BUYER[paymentInviteStatus]?.label}</Text>
          </Box>
        </Flex>
      </Box>
      {paymentInviteStatus !== "Support" && (
        <Flex mb="30px">
          <Button
            buttonStyle="secondary"
            size="small"
            mr="xs"
            onClick={() => dispatch(downloadPaymentInviteInvoice(paymentInvite))}
          >
            {ButtonLabels.downloadInvoice}
          </Button>
          {paymentInviteStatus !== "Delivered" && paymentInviteStatus !== "Disputed" && (
            <Button
              buttonStyle="secondary"
              size="small"
              mr="xs"
              onClick={() => {
                dispatch(closeModal("payment_invite_buyer.status"));
                history.push(`/paymentinvite/status_update?paymentInviteId=${paymentInviteId}&status=delivered`);
              }}
            >
              {ButtonLabels.confirmDelivery}
            </Button>
          )}
          {paymentInviteStatus !== "Delivered" && paymentInviteStatus !== "Disputed" && (
            <Button
              buttonStyle="secondary"
              size="small"
              onClick={() => {
                dispatch(closeModal("payment_invite_buyer.status"));
                history.push(`/paymentinvite/status_update?paymentInviteId=${paymentInviteId}&status=disputed`);
              }}
            >
              {ButtonLabels.dispute}
            </Button>
          )}
        </Flex>
      )}
      <Button buttonStyle="primary" size="medium" fontWeight="500" onClick={handleButtonClick}>
        {isMarketPlace
          ? ButtonLabels.backToDiscovery
          : placeId
          ? ButtonLabels.backToViewingRoom
          : isLoggedInUser
          ? ButtonLabels.goToArtworks
          : ButtonLabels.startFreeAccount}
      </Button>
    </Box>
  );
};

export { PaymentInviteStatusModal };
