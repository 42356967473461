import produce from "immer";
import _ from "lodash";
import {
  DOWNLOAD_PAYMENT_INVITES,
  DOWNLOAD_PAYMENT_INVITES_SUCCESS,
  DOWNLOAD_PAYMENT_INVITES_FAILURE,
  GET_PAYMENT_INVITE_REQUEST,
  GET_PAYMENT_INVITE_SUCCESS,
  GET_PAYMENT_INVITE_FAILURE,
  CREATE_PAYMENT_INVITE_REQUEST,
  CREATE_PAYMENT_INVITE_SUCCESS,
  CREATE_PAYMENT_INVITE_FAILURE,
  MARK_PAYMENT_INVITE_IN_TRANSIT,
  SIGN_OUT_SUCCESS,
  UPDATE_PAYMENT_INVITE_REQUEST,
  UPDATE_PAYMENT_INVITE_SUCCESS,
  UPDATE_PAYMENT_INVITE_FAILURE,
} from "../actions/types";

const PAYMENT_INVITE_INITIAL_STATE = {
  paymentInvites: { byId: {} },
  isLoading: false,
  loaded: false,
};

const paymentInviteReducer = (state = PAYMENT_INVITE_INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case DOWNLOAD_PAYMENT_INVITES:
        return downloadPaymentInvitesRequest(draftState, action);
      case DOWNLOAD_PAYMENT_INVITES_SUCCESS:
        return downloadPaymentInvitesSuccess(draftState, action);
      case DOWNLOAD_PAYMENT_INVITES_FAILURE:
        return downloadPaymentInvitesFailure(draftState, action);
      case GET_PAYMENT_INVITE_REQUEST:
        return getPaymentInviteRequest(draftState, action);
      case GET_PAYMENT_INVITE_SUCCESS:
        return getPaymentInviteSuccess(draftState, action);
      case GET_PAYMENT_INVITE_FAILURE:
        return getPaymentInviteFailure(draftState, action);
      case CREATE_PAYMENT_INVITE_REQUEST:
        return createPaymentInviteRequest(draftState, action);
      case CREATE_PAYMENT_INVITE_SUCCESS:
        return createPaymentInviteSuccess(draftState, action);
      case CREATE_PAYMENT_INVITE_FAILURE:
        return createPaymentInviteFailure(draftState, action);
      case MARK_PAYMENT_INVITE_IN_TRANSIT:
        return updatePaymentInviteRequest(draftState, action);
      case UPDATE_PAYMENT_INVITE_REQUEST:
        return updatePaymentInviteRequest(draftState, action);
      case UPDATE_PAYMENT_INVITE_SUCCESS:
        return updatePaymentInviteSuccess(draftState, action);
      case UPDATE_PAYMENT_INVITE_FAILURE:
        return updatePaymentInviteFailure(draftState, action);
      case SIGN_OUT_SUCCESS:
        return PAYMENT_INVITE_INITIAL_STATE;
      default:
        return state;
    }
  });
};

const downloadPaymentInvitesRequest = (draftState, action) => {
  return {
    ...draftState,
    isLoading: true,
    loaded: false,
  };
};

const downloadPaymentInvitesSuccess = (draftState, action) => {
  const paymentInvitesObject = action.payload.reduce((accumulator, paymentInvite) => {
    accumulator[paymentInvite._id] = {
      ...paymentInvite,
      isFromServer: true,
      isModified: false,
      isActive: true,
    };
    return accumulator;
  }, {});

  draftState = {
    paymentInvites: {
      byId: paymentInvitesObject,
    },
    isLoading: false,
    loaded: true,
  };
  return draftState;
};

const downloadPaymentInvitesFailure = (draftState, action) => {
  return {
    ...draftState,
    isLoading: false,
    loaded: true,
  };
};

const getPaymentInviteRequest = (draftState, action) => {
  return {
    ...draftState,
    isLoading: true,
    loaded: false,
  };
};

const getPaymentInviteSuccess = (draftState, action) => {
  const { paymentInvite, publicLocationExists } = action.payload;
  draftState = {
    paymentInvites: {
      byId: {
        [paymentInvite._id]: {
          isFromServer: true,
          isModified: false,
          isActive: true,
          publicLocationExists,
          ...paymentInvite,
        },
      },
    },
    isLoading: false,
    loaded: true,
  };
  return draftState;
};

const getPaymentInviteFailure = (draftState, action) => {
  return {
    ...draftState,
    isLoading: false,
    loaded: true,
  };
};

const createPaymentInviteRequest = (draftState, action) => {
  return {
    ...draftState,
    isLoading: true,
    loaded: false,
  };
};

const createPaymentInviteSuccess = (draftState, action) => {
  const paymentInvite = action.payload;
  // const artworks = paymentInvite.artworks.map((artwork) => ({ [artwork._id]: { ...artwork } }));
  draftState = {
    paymentInvites: {
      byId: {
        [paymentInvite._id]: {
          isFromServer: true,
          id: paymentInvite._id,
          user: paymentInvite._user,
          ...paymentInvite,
        },
      },
    },
    isLoading: false,
    loaded: true,
  };
  return draftState;
};

const createPaymentInviteFailure = (draftState, action) => {
  return {
    ...draftState,
    isLoading: false,
    loaded: true,
  };
};

const updatePaymentInviteRequest = (draftState, action) => {
  return {
    ...draftState,
    isLoading: true,
    loaded: false,
  };
};

const updatePaymentInviteSuccess = (draftState, action) => {
  const { ...oldPaymentInviteData } = draftState.paymentInvites.byId?.[action.payload.id];
  const {
    id,
    response: { ...newPaymentInviteData },
  } = action.payload;

  draftState = {
    paymentInvites: {
      byId: {
        ...draftState.paymentInvites.byId,
        [id]: {
          ...oldPaymentInviteData,
          ...newPaymentInviteData,
        },
      },
    },
    isLoading: false,
    loaded: true,
  };
  return draftState;
};

const updatePaymentInviteFailure = (draftState, action) => {
  return {
    ...draftState,
    isLoading: false,
    loaded: true,
  };
};

export default paymentInviteReducer;
