export const allowedPublicUrls =  ["/signUpBeta",
  "/paymentinvite/pay",
  "/paymentinvite/status_update",
  "/viewingroom",
  "/viewingroom/details",
  "/marketplace",
  "/discovery",
  "/discovery/artworks/details",
  "/discovery/artworks/favourites",
  "/discovery/artworks/favourites/details",
  "/paymentinvite/paid",
  "/qr_redirect",
];