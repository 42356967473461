import React from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { withTheme } from "styled-components";
import { closeModal } from "../../store/actions";
import css from "@styled-system/css";
import { useIsScreenMobile } from "../hooks/useMediaQuery";

import ReactModal from "react-modal";
import { Box } from "../fundamentals";
import { Button } from "../elements";

const MODAL_OPTIONS = {
  image_viewer: {
    closeButtonPosition: "fixed",
    padding: "none",
    modalBackgroundColor: "transparent",
    modalRadius: "1px",
    overlayBackgroundColor: "rgba(0, 0, 0, 0.75)",
    closeButtonColor: "white",
  },
};

const Modal = ({ children, id, modal, modalProps, additionalCloseAction, closeButtonColor, closeButtonBorderColor, closeButtonHoverColor, ...props }) => {
  const dispatch = useDispatch();
  const isMobile = useIsScreenMobile();
  const {
    closeButtonPosition = "absolute",
    padding = "xxl",
    modalBackgroundColor = "white",
    overlayBackgroundColor = "rgba(243, 244, 255, 0.6)",
    modalRadius = `${props.modalRadius ?? "4px"}`,
  } = MODAL_OPTIONS[id] || {};
  return (
    <ReactModal
      isOpen={id === modal}
      shouldCloseOnOverlayClick={true}
      onRequestClose={
        ()=> {
          if (additionalCloseAction) {
            additionalCloseAction();
            dispatch(closeModal());
          } else {
            dispatch(closeModal());
          }
        }
      }
      id="modal-content"
      style={{
        overlay: {
          backgroundColor: overlayBackgroundColor,
          zIndex: "100",
          overflow: "auto",
          display: "flex",
          ...css({ fontFamily: "body" })(props),
        },
        content: {
          position: "relative",
          top: "unset",
          left: "unset",
          right: "unset",
          bottom: "unset",
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
          margin: "auto",
          overflow: "visible",
        },
      }}
    >
      <Box
        position="relative"
        boxShadow="medium"
        bg={modalBackgroundColor}
        borderRadius={modalRadius}
        overflow="hidden"
        p={padding}
        fontFamily="body"
        {...props}
      >
        <Button
          buttonStyle="text"
          p="0"
          size="small"
          position={closeButtonPosition}
          right={isMobile ? "16px" : "30px"}
          top={isMobile ? "16px" : "25px"}
          zIndex="100"
          icon="X"
          borderRadius="50%"
          border="2px solid #5E54FF"
          iconSize="12"
          iconStrokeWidth="4"
          color={closeButtonColor || "#5E54FF"}
          borderColor = {closeButtonBorderColor || "#5E54FF"}
          hoverColor={closeButtonHoverColor || "grey.80"}
          cursor="default"
          iconCursor="pointer"
          onClick={
            ()=> {
              if (additionalCloseAction) {
                additionalCloseAction();
                dispatch(closeModal());
              } else {
                dispatch(closeModal());
              }
            }
          }
        />
        <Box position="relative">{React.cloneElement(children, modalProps)}</Box>
      </Box>
    </ReactModal>
  );
};

function mapStateToProps(state) {
  const {
    ui: { modal, modalProps },
  } = state;
  return { modal, modalProps };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeModal: closeModal }, dispatch);
}

const ConnectedModal = connect(mapStateToProps, mapDispatchToProps)(withTheme(Modal));

export { ConnectedModal as Modal };
