import { useEffect } from "react";
import { WithRequireAccess } from "../hocs";
import { NavigationBar } from "../react-material/NavigationBar";
import { Headings, ButtonLabels } from "../../localisation";
import { Typography } from "@mui/material";
import { ViewingRoomForm } from "../forms/ViewingRoomForm";
import { Button, Modal } from "../elements";
import { useHistory } from "react-router-dom";
import { Box } from "../fundamentals";
import { ArtworkDetailsModal, AdditionalArtworksModal, TwoActionModal } from "../modals";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../store/actions";
import { selectUser } from "../../store/selectors";

const AddViewingRoomScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const goToOnboarding = () => {
    history.push("/marketplace/onboarding")
  };

  useEffect(() => {
    if (!user.account.idVerified || !user.account.marketplaceOnboarded) {
      dispatch(openModal("marketplace-onboarding-prompt"));
    }
  }, []);

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  return (
    <Box width="100%">
      <Box maxWidth="98%">
        <NavigationBar />
      </Box>
      <Box>
        <Box backgroundColor="white">
          <Box pl="xxl" pr="xxl" pt="m">
            <Button
              buttonStyle="formBack"
              size="small"
              icon="ArrowLeft"
              pl="0"
              onClick={() => history.push("/artworks")}
            >
              {ButtonLabels.backToArtworks}
            </Button>
            <Typography variant="h2">{Headings.createViewingRoom}</Typography>
          </Box>
          <ViewingRoomForm
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            setValue={setValue}
            watch={watch}
            control={control}
            errors={errors}
          />
        </Box>
      </Box>
      <Modal id="artwork_details">
        <ArtworkDetailsModal />
      </Modal>
      <Modal id="additional_artworks" p="0">
        <AdditionalArtworksModal />
      </Modal>
      <Modal id="marketplace-onboarding-prompt" width="540px">
        <TwoActionModal 
        title="Are you planning to sell artworks through Viewing Rooms?"
        message="Complete the onboarding process in order to sell your artworks"
        primaryButtonText="Continue to onboarding"
        primaryAction={goToOnboarding}
        secondaryButtonText="I won't sell this time"
        secondaryAction={()=>dispatch(closeModal())}
        />
      </Modal>
    </Box>
  );
};

export default WithRequireAccess(AddViewingRoomScreen, 1);
