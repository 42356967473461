import React, { useEffect } from "react";
import styled from "styled-components";
import { Box, Flex, Image, Text } from "../fundamentals";
import { Modal, Button,Drawer } from "../elements";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  PaymentInviteBuyerGeneratedGeneralInfoModal,
  PaymentInviteBuyerGeneratedShippingInfoModal,
  PaymentInviteBuyerGeneratedPaymentModal,
  PaymentInviteStatusModal,
  PaymentInviteRejectionModal,
} from "../modals";
import { getPaymentInviteRequest, closeModal } from "../../store/actions";
import { Instructions, LinkLabels, UiLabels } from "../../localisation";
import { DisputeForm } from "../forms/PaymentInviteForms/DisputeForm";
import { DeliveryConfirmationForm } from "../forms/PaymentInviteForms/DeliveryConfirmationForm";
import { selectPaymentInviteById } from "../..//store/selectors";
import CircularProgress from "@mui/material/CircularProgress";
import { DateTime } from "luxon";
import { PaymentInviteBuyerDrawer } from "../react-material/drawers";

const StyledPublicPaymentInviteScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const PublicPaymentInviteScreen = () => {
  const isMobile = useIsScreenMobile();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const paymentInviteState = useSelector((state) => state.payment_invite);
  const paymentInviteId = params.get("paymentInviteId");
  const paymentInvite = useSelector((state) => selectPaymentInviteById(state, paymentInviteId));
  const status = params.get("status");
  const latestStatus = paymentInvite?.status?.find((item) => item.is_latest === true)?.status_name;
  const paymentInviteCreationDate = DateTime.fromISO(paymentInvite?.dateCreated).toFormat("dd MMM yy");
  const paymentInviteValidity = new Date(paymentInvite?.inviteValidity).getTime();
  const inviteIsValid = new Date() < paymentInviteValidity;

  const disputeFormInitialValues = {
    subject: `Re: Dispute of Payment Invite generated on ${paymentInviteCreationDate}`,
  };

  useEffect(() => {
    dispatch(getPaymentInviteRequest({ paymentInviteId, isMobile }));
  }, [status]);

  useEffect(() => {
    if (latestStatus === "Delivered" || latestStatus === "Disputed") {
      history.replace(`/paymentinvite/pay?paymentInviteId=${paymentInviteId}`);
    }
    if (!inviteIsValid || !paymentInvite?.inviteValidity) {
      dispatch(closeModal("payment_invite_buyer"));
    }
  }, []);

  return (
    <StyledPublicPaymentInviteScreen bg="white">
      <Helmet>
        <title>Payment Invite | simplify.art</title>
        <meta name="description" content="Sign in to your simplify.art account to access your collection" />
      </Helmet>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Image
          src={
            process.env.PUBLIC_URL +
            `/images/brand-identity/${isMobile ? "logo-icon-black.svg" : "logo_horizontal-black.svg"}`
          }
          alt="logo"
          width={isMobile ? "25px" : "184px"}
          height={isMobile ? "35px" : ""}
          p={isMobile ? "35px 30px" : "35px 50px"}
        />

        <Box display="flex" alignItems="center" mr={isMobile ? "l" : "xxxl"}>
          {!isMobile && (
            <Text size="heading3" mr="s">
              {Instructions.noAccount}
            </Text>
          )}
          <Button
            buttonStyle="default"
            type="button"
            size={isMobile ? "small" : "medium"}
            fontSize={isMobile ? "13px" : "20px"}
            bg="white"
            p={isMobile ? "5px 12px" : "8.5px 20px"}
            fontWeight="700"
            onClick={(e) => {
              e.preventDefault();
              history.push("/register");
            }}
          >
            {LinkLabels.signup}
          </Button>
        </Box>
      </Box>
      {paymentInviteState.isLoading ? (
        <Flex justifyContent="center" alignItems="center" pb="mega" height="100vh">
          <CircularProgress sx={{ color: "#5E54FF" }} />
        </Flex>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" pb="mega">
          {/* The openModal action is called in the saga, when the getPaymentInvite action is successful */}
          {/* {!status && (
            <Box width="fit-content" bg="white" boxShadow="mediumLarge" borderRadius="10px">
              <PaymentInviteBuyerModal paymentInviteId={paymentInviteId} />
            </Box>
          )} */}
          {!inviteIsValid && (
            <Flex justifyContent="space-between" p="xxl">
              <Flex justifyContent="space-between" fontSize="body" fontWeight="500" mt="xs">
                <Text>{UiLabels.paymentInviteExpired}</Text>
              </Flex>
            </Flex>
          )}
          {status === "disputed" && (
            <Box display="flex" justifyContent="center" alignItems="center" pt="mega">
              <Box
                width={isMobile ? "325px" : "640px"}
                bg="white"
                p={isMobile ? "m" : "xxl"}
                mt={isMobile ? "l" : "xxl"}
                boxShadow="mediumLarge"
                borderRadius="10px"
              >
                <DisputeForm paymentInviteId={paymentInviteId} initialValues={disputeFormInitialValues} />
              </Box>
            </Box>
          )}
          {status === "delivered" && (
            <Box display="flex" justifyContent="center" alignItems="center" pt="mega">
              <Box
                width={isMobile ? "325px" : "440px"}
                bg="white"
                p={isMobile ? "m" : "xxl"}
                mt={isMobile ? "l" : "xxl"}
                boxShadow="mediumLarge"
                borderRadius="10px"
              >
                <DeliveryConfirmationForm paymentInviteId={paymentInviteId} paymentInvite={paymentInvite} />
              </Box>
            </Box>
          )}
        </Box>
      )}
      <Modal
        id="payment_invite_buyer"
        p="0px"
        closeButtonColor="white"
        closeButtonBorderColor="white"
        closeButtonHoverColor="white"
      >
        {/* Need to rename modals now that there is a singe flow both for seller generated and buyer generated PIs */}
        <PaymentInviteBuyerGeneratedGeneralInfoModal />
      </Modal>
      <Modal
        id="payment_invite_buyer.shipping"
        p="0px"
        closeButtonColor="white"
        closeButtonBorderColor="white"
        closeButtonHoverColor="white"
      >
        <PaymentInviteBuyerGeneratedShippingInfoModal />
      </Modal>
      <Modal
        id="payment_invite_buyer.pay"
        p="0px"
        closeButtonColor="white"
        closeButtonBorderColor="white"
        closeButtonHoverColor="white"
      >
        <PaymentInviteBuyerGeneratedPaymentModal />
      </Modal>
      <Modal id="payment_invite_buyer.status" p="0px">
        <PaymentInviteStatusModal />
      </Modal>
      <Modal id="payment_invite_buyer_reject">
        <PaymentInviteRejectionModal />
      </Modal>
      <Drawer id="payment_invite_buyer" padding="0">
        <PaymentInviteBuyerDrawer />
      </Drawer>
    </StyledPublicPaymentInviteScreen>
  );
};

export default PublicPaymentInviteScreen;
