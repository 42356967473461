import { useEffect } from "react";
import { RenderPlanCard } from "../../react-material/RenderPaymentPlan/RenderPlanCard";
import { Headings, Instructions, ButtonLabels, InputErrors } from "../../../localisation";
import { Button, RadioSelect } from "../../elements";
import { Flex, Text } from "../../fundamentals";
import { returnUpdatedStripePlansBasedOnUserRole } from "../../../utils";
import { useIsScreenMobile } from "../../hooks/useMediaQuery";
import { selectUser } from "../../../store/selectors";
import { updateBillingPeriod, closeModal } from "../../../store/actions";
import { connect, useSelector, useDispatch } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Typography } from "@mui/material";
import { BILLING_PERIOD_YEARLY, BILLING_PERIOD_MONTHLY } from "../../../constants";

const Form = ({ handleSubmit, submitting, headerTextStyle, instructionTextStyle, upgradeIcon, goToPage, plan }) => {
  const dispatch = useDispatch();
  const isMobile = useIsScreenMobile();
  const user = useSelector(selectUser); // current user
  const upgradeForm = useSelector((state) => state.form.upgrade);
  const billingPeriod = useSelector((state) => state.user.billingPeriod);
  const hasDiscount = user.account.hasOwnProperty("discount") && user.account?.discount?.value ? true : false;

  useEffect(() => {
    //Preselect the yearly option if the user has a discount code
      if (hasDiscount && billingPeriod !== BILLING_PERIOD_MONTHLY) {
        dispatch(updateBillingPeriod(BILLING_PERIOD_YEARLY));
      }
    }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Flex alignItems="center" marginBottom="16px">
        {upgradeIcon}
        <Text style={instructionTextStyle(isMobile)}>{Instructions.upgradeToAllAccess}</Text>
      </Flex>
      <Typography sx={headerTextStyle(isMobile)}>{Headings.selectSubscription}</Typography>
      {hasDiscount && (
        <Text color="amber.90" fontSize="smaller" pb="s">The discount is applied only on yearly subscriptions</Text>
      )}
      <Flex>
        <Field
          name="plan"
          options={returnUpdatedStripePlansBasedOnUserRole(user.role, billingPeriod)}
          direction="horizontal"
          customItemComponent={RenderPlanCard}
          component={RadioSelect}
          spacing="0"
        />
      </Flex>
      <Button
        buttonStyle="form"
        type="submit"
        size={isMobile ? "small" : "medium"}
        fontSize={isMobile ? "13px" : "16px"}
        mt="xs"
        disabled={submitting || !plan}
        icon={submitting ? "Loader" : null}
        onClick={(e) => {
          e.preventDefault();
          if (plan && plan.includes("free")) {
            dispatch(updateBillingPeriod(null));
            dispatch(closeModal());
          } else {
            dispatch(updateBillingPeriod(billingPeriod));
            goToPage("payment");
          }
        }}
      >
        {plan && plan.includes("free") ? ButtonLabels.cancelUpgrade : ButtonLabels.proceedToPayment}
      </Button>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  const { plan } = values;

  if (!plan) errors.plan = InputErrors.noPlan;

  return errors;
};

let BillingPeriodForm = reduxForm({
  form: "upgrade",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

const selector = formValueSelector("upgrade");

function mapStateToProps(state) {
  const plan = selector(state, "plan");
  return { plan, user: state.user };
}

BillingPeriodForm = connect(mapStateToProps, null)(BillingPeriodForm);

export { BillingPeriodForm };
