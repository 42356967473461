import React from "react";
import styled from "styled-components";
import { Box, Flex, Image, Text, Link } from "../fundamentals";
import { Instructions, LinkLabels, Headings, Messages, ButtonLabels } from "../../localisation";
import { Button } from "../elements";
import { push } from "connected-react-router";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";

const StyledUnsuccessfulAccountCreationScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const accountExistStyle = {
  fontFamily: "DM Sans",
  fontSize: "20px",
  lineHeight: "26px",
  fontWeight: "400",
  marginRight:'20px'
};

const headerTextStyle = (mobile) => {
    return {
      textAlign: "left",
      fontFamily: "DM Sans",
      fontSize: mobile ? "20px" : "32px",
      lineHeight: mobile ? "26px" : "40px",
      marginBottom: "24px",
      fontWeight: "400",
      textAlign: "center"
    };
  };

const UnsuccessfulAccountCreationScreen = () => {
  const isMobile = useIsScreenMobile();
  const history = useHistory();

  return (
    <StyledUnsuccessfulAccountCreationScreen bg="white">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        
        <Image
          src={
            process.env.PUBLIC_URL +
            `/images/brand-identity/${isMobile ? "logo-icon-black.svg" : "logo_horizontal-black.svg"}`
          }
          alt="logo"
          width={isMobile ? "25px" : "184px"}
          height={isMobile ? "35px" : ""}
          p={isMobile ? "35px 30px" : "35px 50px"}
        />

        <Box display="flex" alignItems="center" mr={isMobile ? "l" : "xxxl"}>
          {!isMobile && (
            <Typography sx={accountExistStyle}>{Instructions.accountExists}</Typography>
          )}

          <Button
            buttonStyle="default"
            type="button"
            size={isMobile ? "small" : "medium"}
            fontSize={isMobile ? "13px" : "20px"}
            bg="white"
            p={isMobile ? "5px 12px" : "8.5px 20px"}
            fontWeight="700"
            onClick={(e) => {
              e.preventDefault();
              history.push("/login");
            }}
          >
            {LinkLabels.signinText}
          </Button>
        </Box>
      </Box>

      <Flex justifyContent="center" alignItems="center" pt="mega">
        <Flex
            flexDirection="column" 
          width={isMobile ? "325px" : "500px"}
          bg="white"
          p={isMobile ? "m" : "56px 94px"}
          mt={isMobile ? "l" : "xxl"}
          boxShadow="mediumLarge"
          borderRadius="15px"
          alignItems="center"
        >
          <Typography sx={headerTextStyle(isMobile)}>{Headings.paymentIncomplete}</Typography>
          <Text 
            fontFamily="bodyDmSans"
            fontSize={isMobile ? "body": "heading3"}
            textAlign="center"
            mb="m"
            >
              {Messages.failedPayment}
            </Text>
          <Text
            fontFamily="bodyDmSans"
            fontSize={isMobile ? "body": "heading3"}
            textAlign="center"
            mb="m"
          >
            {Messages.retrySignup}
          </Text>
          <Button
            buttonStyle="form"
            type="button"
            size={isMobile ? "small" : "medium"}
            fontSize={isMobile ? "13px" : "16px"}
            flip={true}
            fontFamily="body"
            p={isMobile ? "5px 12px" : "8px 16px"}
            alignSelf="center"
          >
            {ButtonLabels.retrySignup}
          </Button>
        </Flex>
      </Flex>
    </StyledUnsuccessfulAccountCreationScreen>
  );
};

export default UnsuccessfulAccountCreationScreen;
