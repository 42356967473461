import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ProtectedRoute } from "../../components/hocs";
// screens
import ArchiveScreen from "../../components/screens/ArchiveScreen";
import AddArtworkScreen from "../../components/screens/AddArtworkScreen";
import ArtworkDetailsScreen from "../../components/screens/ArtworkDetailsScreen";
import EditMultipleArtworkScreen from "../../components/screens/EditMultipleArtworkScreen";
import ShareArtworkDetailsScreen from "../../components/screens/ShareArtworkDetailsScreen";

import ArtistsScreen from "../../components/screens/ArtistsScreen";
import AddArtistScreen from "../../components/screens/AddArtistScreen";
import EditArtistScreen from "../../components/screens/EditArtistScreen";

import ContactsScreen from "../../components/screens/ContactsScreen";
import AddContactScreen from "../../components/screens/AddContactScreen";
import EditContactScreen from "../../components/screens/EditContactScreen";

import SettingsScreen from "../../components/screens/SettingsScreen";
import ExpiredPaymentScreen from "../../components/screens/ExpiredPaymentScreen";
import ExpiredPaymentSuccessScreen from "../../components/screens/ExpiredPaymentSuccessScreen";
import ActivityScreen from "../../components/screens/ActivityScreen";

import MarketplaceOnboardingScreen from "../../components/screens/MarketplaceOnboardingScreen";
import MarketplaceOnboardingSuccessScreen from "../../components/screens/MarketplaceOnboardingSuccessScreen";

import ListsScreen from "../../components/screens/ListsScreen";
import EditListScreen from "../../components/screens/EditListScreen";

import ViewingRoomsScreen from "../../components/screens/ViewingRoomsScreen";
import AddViewingRoomScreen from "../../components/screens/AddViewingRoomScreen";
import ViewingRoomLinkScreen from "../../components/screens/ViewingRoomLinkScreen";
import EditViewingRoomScreen from "../../components/screens/EditViewingRoomScreen";

import AuthRouter from "./AuthRouter";

import { Messages } from "../../localisation";

const RootRouter = () => {
  return (
    <Switch>
      <ProtectedRoute path="/artworks" exact minAccessLevel={0} component={ArchiveScreen} />
      <ProtectedRoute path="/artists" exact minAccessLevel={1} component={ArtistsScreen} />
      <ProtectedRoute path="/artists/add" exact minAccessLevel={1} component={AddArtistScreen} />
      <ProtectedRoute path="/artists/edit" exact minAccessLevel={1} component={EditArtistScreen} />

      <ProtectedRoute path="/artworks/add" exact minAccessLevel={0} component={AddArtworkScreen} />
      <ProtectedRoute path="/artworks/details" exact minAccessLevel={0} component={ArtworkDetailsScreen} />
      <ProtectedRoute path="/artworks/multi-edit" exact minAccessLevel={1} component={EditMultipleArtworkScreen} />
      <ProtectedRoute path="/settings" exact minAccessLevel={0} component={SettingsScreen} />
      <ProtectedRoute path="/share/artworks/details" exact minAccessLevel={1} component={ShareArtworkDetailsScreen} />
      <ProtectedRoute path="/expired-payment-method" exact minAccessLevel={1} component={ExpiredPaymentScreen} />

      <ProtectedRoute path="/contacts" exact minAccessLevel={1} component={ContactsScreen} />
      <ProtectedRoute path="/contacts/add" exact minAccessLevel={1} component={AddContactScreen} />
      <ProtectedRoute path="/contacts/edit" exact minAccessLevel={1} component={EditContactScreen} />

      <ProtectedRoute path="/lists" exact minAccessLevel={1} component={ListsScreen}/>
      <ProtectedRoute path="/lists/edit" exact minAccessLevel={1} component={EditListScreen}/>
     
      <ProtectedRoute
        path="/expired-payment-method/success"
        exact
        minAccessLevel={1}
        component={ExpiredPaymentSuccessScreen}
      />

      <ProtectedRoute path="/upgrade" exact minAccessLevel={0} component={ArchiveScreen} />

      <ProtectedRoute path="/activity" exact minAccessLevel={0} component={ActivityScreen}/>
      <ProtectedRoute path="/activity/shared" exact minAccessLevel={0} component={ActivityScreen}/>
      <ProtectedRoute path="/activity/acquisitions" exact minAccessLevel={0} component={ActivityScreen}/>
      <ProtectedRoute path="/activity/sales" exact minAccessLevel={0} component={ActivityScreen}/>

      <ProtectedRoute path="/marketplace/onboarding" exact minAccessLevel={0} component={MarketplaceOnboardingScreen}/>
      <ProtectedRoute path="/marketplace/onboardingSuccess" exact minAccessLevel={0} component={MarketplaceOnboardingSuccessScreen}/>

      <ProtectedRoute path="/viewingrooms" exact minAccessLevel={1} component={ViewingRoomsScreen}/>
      <ProtectedRoute path="/viewingrooms/add" exact minAccessLevel={1} component={AddViewingRoomScreen}/>
      <ProtectedRoute path="/viewingrooms/link" exact minAccessLevel={1} component={ViewingRoomLinkScreen}/>
      <ProtectedRoute path="/viewingrooms/edit" exact minAccessLevel={1} component={EditViewingRoomScreen}/>

      {/*Not Implemented Yet*/}
      {/*<ProtectedRoute path="/viewingroom/:id" exact component={ViewingRoomLayout} />*/}

      <Route render={(props) => <AuthRouter {...props} />} />

      <Redirect from="*" to="/artworks" />
    </Switch>
  );
};

export default RootRouter;
