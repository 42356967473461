import React, { useEffect } from "react";
import { Box, Flex, Text, Icon } from "../fundamentals";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectUserArtist, selectArtistById } from "../../store/selectors";
import { ButtonLabels, Headings, InputErrors } from "../../localisation";
import { Button } from "../elements";
import { addActionToQueue, createArtist, editArtist } from "../../store/actions";
import { ArtistForm } from "../react-material/forms/ArtistForm.jsx";
import { useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { v4 as uuid } from "uuid";

const ArtistProfileScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);
  const artist = useSelector(selectUserArtist);
  const selectedArtist = useSelector((state) => selectArtistById(state, artist?.id));  //select the artist in order for editArtist saga to work

  const defaultValues = {
    id: artist?.id,
    publishToDiscovery: artist?.publishToDiscovery,
    name: artist?.name ? artist?.name : user?.name,
    yearBirth: artist?.yearBirth,
    cityBirth: artist?.cityBirth,
    countryBirth: artist?.countryBirth,
    yearDeath: artist?.yearDeath,
    cityDeath: artist?.cityDeath,
    countryDeath: artist?.countryDeath,
    currentCity: artist?.currentCity,
    currentCountry: artist?.currentCountry,
    instagramUrl: artist?.instagramUrl,
    websiteUrl: artist?.websiteUrl,
    bio: artist?.bio,
    education: artist?.education?.length
      ? artist.education.map((educationItem) => ({
          startDate: educationItem.startDate ? DateTime.fromISO(educationItem.startDate) : null,
          endDate: educationItem.endDate ? DateTime.fromISO(educationItem.endDate) : null,
          institution: educationItem.institution,
          course: educationItem.course,
          city: educationItem.city,
          country: educationItem.country,
        }))
      : [
          {
            startDate: null,
            endDate: null,
            institution: "",
            course: "",
            city: "",
            country: "",
          },
        ],
    exhibitions: artist?.exhibitions?.length
      ? artist.exhibitions.map((exhibitionsItem) => ({
          exhibitionType: exhibitionsItem.exhibitionType,
          partner: exhibitionsItem.partner,
          startDate: exhibitionsItem.startDate ? DateTime.fromISO(exhibitionsItem.startDate) : null,
          endDate: exhibitionsItem.endDate ? DateTime.fromISO(exhibitionsItem.endDate) : null,
          title: exhibitionsItem.title,
          venue: exhibitionsItem.venue,
          city: exhibitionsItem.city,
          country: exhibitionsItem.country,
          curatedBy: exhibitionsItem.curatedBy,
        }))
      : [
          {
            exhibitionType: "",
            partner: "",
            startDate: null,
            endDate: null,
            title: "",
            venue: "",
            city: "",
            country: "",
            curatedBy: "",
          },
        ],
    collections: artist?.collections?.length
      ? artist.collections.map((collectionsItem) => ({
          name: collectionsItem.name,
          city: collectionsItem.city,
          country: collectionsItem.country,
        }))
      : [
          {
            name: "",
            city: "",
            country: "",
          },
        ],
    publications: artist?.publications?.length
      ? artist.publications.map((publicationsItem) => ({
          editor: publicationsItem.editor,
          publisher: publicationsItem.publisher,
          author: publicationsItem.author,
          city: publicationsItem.city,
          year: publicationsItem.year,
          title: publicationsItem.title,
          issue: publicationsItem.issue,
        }))
      : [
          {
            editor: "",
            publisher: "",
            author: "",
            city: "",
            year: "",
            title: "",
            issue: "",
          },
        ],
    awards: artist?.awards?.length
      ? artist.awards.map((awardsItem) => ({
          year: awardsItem.year,
          title: awardsItem.title,
          city: awardsItem.city,
          country: awardsItem.country,
        }))
      : [
          {
            title: "",
            city: "",
            country: "",
          },
        ],
  };

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: { ...defaultValues } });

  const submit = (values, event) => {
    event.preventDefault();
    const tempArtistId = uuid();
    if (!artist) {
      return dispatch(
        addActionToQueue(
          createArtist(
            {
              ...values,
            },
            tempArtistId
          ),
          tempArtistId
        )
      );
    } else {
      return dispatch(addActionToQueue(editArtist(values), tempArtistId));
    }
  };

  useEffect(() => {
    if (user.role !== "artist") {
      history.push("/artworks");
    }
  }, []);

  const renderFloatingActionPillButtons = () => {
    return [
      <Button
        type="submit"
        disabled={isSubmitting}
        buttonStyle="text"
        icon="UploadCloud"
        iconSize="20"
        color="white"
        fontFamily="body"
        size="medium"
        hoverColor="blue.20"
        fontWeight="500"
      >
        {!artist ? ButtonLabels.createProfile : ButtonLabels.updateProfile}
      </Button>,

      <Button
        type="button"
        onClick={() => reset()}
        buttonStyle="text"
        icon="RotateCcw"
        iconSize="20"
        color="white"
        fontFamily="body"
        fontSize="l"
        size="medium"
        hoverColor="blue.20"
        fontWeight="500"
      >
        {ButtonLabels.reset}
      </Button>,
    ];
  };

  return (
    <Box flex="1">
      <Text fontFamily="bodyDmSans" fontSize="heading2" mb="xxl">
        {Headings.artistProfile}
      </Text>
      {Object.keys(errors).length > 0 && (
        <Flex color="red" mb="m">
          <Icon icon="AlertCircle" size="12" mr="xxxs" />
          <Text fontSize="smaller">{InputErrors.invalidFields}</Text>
        </Flex>
      )}
      <ArtistForm
        handleSubmit={handleSubmit}
        watch={watch}
        setValue={setValue}
        control={control}
        errors={errors}
        selectedArtistIds={[]}
        renderFloatingActionPillButtons={renderFloatingActionPillButtons}
        onSubmit={submit}
      />
    </Box>
  );
};

export default ArtistProfileScreen;
