export const selectSelectedCollectionId = (state) => state.selection.collection;

export const selectSelectedCategory = (state) => state.selection.category;

export const selectSelectedArtworkIds = (state) => state.selection.artworks;

export const selectSelectedArtistIds = (state) => state.selection.artists;

export const selectSelectedContactIds = (state) => state.selection.contacts;

export const selectSelectedListIds = (state) => state.selection.lists;

export const selectSelectedViewingRoomIds = (state) => state.selection.viewingRooms;
