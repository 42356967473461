import { Box, Text, Flex, Link, Image } from "../../fundamentals";
import { useIsScreenMobile, useIsScreenMobileSmall } from "../../hooks/useMediaQuery";
import { UiLabels, ButtonLabels, LinkLabels } from "../../../localisation";
import { Button } from "../../elements";
import { useHistory } from "react-router-dom";
import { TikTokIcon } from "../CustomIcons";

export const DiscoveryFooter = () => {
  const isMobile = useIsScreenMobile();
  const isSmallMobile = useIsScreenMobileSmall();
  const history = useHistory();
  return (
    <Box>
      <Box borderTop="1px solid #E8E7EA" p={isMobile ? "16px 20px" : "xxl"}>
        <Box
          height={isMobile ? "327px" : "500px"}
          borderRadius="10px 10px 0px 0px"
          backgroundImage={
            isMobile ? `url("/images/marketing/CTA-image-mob.png")` : `url("/images/marketing/CTA-image.png")`
          }
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
        />
        <Flex
          flexDirection="column"
          alignItems="center"
          borderRadius="0px 0px 10px 10px"
          backgroundColor="blue.60"
          color="white"
          padding="48px 24px"
        >
          <Text fontFamily="bodyDmSans" fontSize="28px" mb="s" textAlign="center" maxWidth={!isMobile && "380px"}>
            {UiLabels.appForManagingArt}
          </Text>
          <Box mb="s" fontSize="smaller">
            <Text textAlign="center">{UiLabels.timeOnAdmin}</Text>
            <Text textAlign="center">{UiLabels.timeOnDiscovery}</Text>
          </Box>
          <Button
            buttonStyle="default"
            inverted={true}
            size="small"
            alignSelf="center"
            fontWeight="700"
            onClick={(e) => {
              e.preventDefault();
              history.push("/register");
            }}
          >
            {ButtonLabels.startForFree}
          </Button>
        </Flex>
      </Box>
      <Box borderTop="1px solid #E8E7EA" p={isMobile ? "16px 20px" : "xxl"}>
        <Flex flexDirection={isMobile ? "column" : "row"} mb="36px">
          <Box fontSize="small" mb={isMobile && "36px"} mr={!isMobile && "72px"}>
            <Text fontWeight="500" mb="xs">
              {UiLabels.aboutUs}
            </Text>
            <Box mb="xs">
              <Link href="https://www.simplify.art/product" target="_blank" color="black">
                {LinkLabels.about}
              </Link>
            </Box>
            <Box mb="xs">
              <Link href="https://www.simplify.art/contact" target="_blank" color="black">
                {LinkLabels.contact}
              </Link>
            </Box>
            <Box>
              <Link href="https://www.simplify.art/sell-your-art" target="_blank" color="black">
                {LinkLabels.buyingWithSimplify}
              </Link>
            </Box>
          </Box>
          <Box fontSize="small" mb={isMobile && "36px"} mr={!isMobile && "72px"}>
            <Text fontWeight="500" mb="xs">
              {UiLabels.support}
            </Text>
            <Box mb="xs">
              <Link href="https://calendly.com/victoria-dejaco/30min?month=2023-09" target="_blank" color="black">
                {LinkLabels.bookDemo}
              </Link>
            </Box>
            <Box>
              <Link href="https://www.simplify.art/help" target="_blank" color="black">
                {LinkLabels.visitHelpCenter}
              </Link>
            </Box>
          </Box>
          <Box fontSize="smaller" color="grey.80">
            <Text mb="xs">{UiLabels.CopyrightSimplify2023}</Text>
            <Box mb="xs">
              <Link href="https://www.simplify.art/imprint#terms-conditions" target="_blank" color="grey.80">
                {LinkLabels.termsAndConditions}
              </Link>
            </Box>
            <Box>
              <Link href="https://www.simplify.art/imprint#privacy-policy" target="_blank" color="grey.80">
                {LinkLabels.privacyPolicy}
              </Link>
            </Box>
          </Box>
        </Flex>
        <Flex flexDirection={isSmallMobile && "column"} borderTop="1px solid #E8E7EA" paddingTop="s" alignItems={!isSmallMobile && "center"} justifyContent={!isSmallMobile && "space-between"}>
          <Link href="https://www.simplify.art" target="_blank" mb={isSmallMobile && "xxs"}>
            <Image
              src={process.env.PUBLIC_URL + `/images/brand-identity/logo_horizontal-purple.svg`}
              alt="logo"
              height="20px"
            />
          </Link>
          <Flex>
            <Link href="https://www.instagram.com/simplify.art/" target="_blank">
              <Button buttonStyle="primary" size="small" icon="Instagram" mr="10px" width="28px" height="28px" iconCursor="pointer" />
            </Link>
            <Link href="https://www.youtube.com/@simplifyart" target="_blank">
              <Button buttonStyle="primary" size="small" icon="Youtube" mr="10px" width="28px" height="28px" iconCursor="pointer" />
            </Link>
            <Link href="https://www.tiktok.com/@simplify.art" target="_blank">
              <Button buttonStyle="primary" size="small"  mr="10px" p="0" width="28px" height="28px">
                <TikTokIcon sx={{paddingTop:"5px"}}/>
              </Button>
            </Link>
            <Link href="https://www.facebook.com/simplifyartarchiving/" target="_blank">
              <Button buttonStyle="primary" size="small" icon="Facebook" mr="10px" width="28px" height="28px" iconCursor="pointer" />
            </Link>
            <Link href="https://www.linkedin.com/company/simplify-art/" target="_blank">
              <Button buttonStyle="primary" size="small" icon="Linkedin" width="28px" height="28px" iconCursor="pointer" />
            </Link>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
