import { useSelector } from "react-redux";
import { Flex, Box, Text } from "../fundamentals";
import { UiLabels, DynamicHeadings, InputLabels, ButtonLabels, InputErrors } from "../../localisation";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Typography } from "@mui/material";
import { selectPaymentInviteById } from "../../store/selectors";
import { updatePaymentInviteRequest } from "../../store/actions";
import { PaymentInviteModalInfo } from "../modules";
import { Button, Input } from "../elements";
import { Field, reduxForm } from "redux-form";

const Form = ({ handleSubmit, submitting, paymentInviteId, isMarketPlace, placeId }) => {
  const isMobile =useIsScreenMobile();
  
  const submit = (values, dispatch) => {
    return dispatch(
      updatePaymentInviteRequest({
        paymentInviteId,
        placeId,
        isMarketPlace,
        isEnquiry: true,
        status:"Enquiry",
        ...values,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box fontSize="small" mb="m">
        <Text color="blue.60" fontWeight="500" mb="xs">
          {UiLabels.contact}
        </Text>
        <Flex flexDirection={isMobile ? "column" : "row"}>
          <Field
            flex="1"
            minHeight="50px"
            name="contactFirstName"
            placeholder={InputLabels.firstName}
            component={Input}
            mr={!isMobile && "xs"}
            required
          />
          <Field
            flex="1"
            minHeight="50px"
            name="contactLastName"
            placeholder={InputLabels.lastName}
            component={Input}
            required
          />
        </Flex>
        <Field name="contactEmail" placeholder={InputLabels.emailText} component={Input} minHeight="50px" required />
        <Field name="contactPhone" placeholder={InputLabels.phone} component={Input} minHeight="50px" required />
      </Box>
      <Box fontSize="small" mb="m">
        <Text color="blue.60" fontWeight="500" mb="xs">
          {InputLabels.message}
        </Text>
        <Field name="message" type="textarea" required minHeight="100px" component={Input} />
      </Box>
      <Button
        type="submit"
        buttonStyle="primary"
        size="medium"
        fontWeight="500"
        disabled={submitting}
        icon={submitting ? "Loader" : null}
        iconSpinning={true}
        iconOffset="xs"
      >
        {ButtonLabels.sendAnEnquiry}
      </Button>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  const { contactFirstName, contactLastName, contactEmail, contactPhone, message } = values;

  if (!contactFirstName) {
    errors.contactFirstName = InputErrors.noFirstName;
  }
  if (!contactLastName) {
    errors.contactLastName = InputErrors.noLastName;
  }

  if (!contactEmail) {
    errors.contactEmail = InputErrors.noEmail;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(contactEmail)) {
    errors.contactEmail = InputErrors.invalidEmail;
  }
  if (!contactPhone) {
    errors.contactPhone = InputErrors.noPhoneNumber;
  } else if (!contactPhone.startsWith("+")) {
    errors.contactPhone = InputErrors.invalidPhoneNumber;
  }
  if (!message) {
    errors.message = InputErrors.noMessage;
  }

  return errors;
};

export const BuyerEnquiryForm = reduxForm({
  form: "payment_invite.update",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "24px",
    lineHeight: mobile ? "26px" : "32px",
    marginBottom: "30px",
    fontWeight: "400",
  };
};

export const PaymentInviteBuyerEnquiryModal = ({ paymentInviteId, isMarketPlace, placeId }) => {
  const isMobile = useIsScreenMobile();
  const paymentInvite = useSelector((state) => selectPaymentInviteById(state, paymentInviteId));

  // const paymentInviteArtworks = useSelector(
  //   (state) => state.payment_invite_artworks?.paymentInviteArtworks.byId[paymentInviteId]
  // );

  //Create a combined artworks array from paymentInvite.artworks and paymentInviteArtworks that will contain the properties of both
  // let combinedArtworksArray = [];
  // if (paymentInviteArtworks) {
  //   combinedArtworksArray = paymentInvite?.artworks?.map(({ _id, price }) => {
  //     const restOfProperties = paymentInviteArtworks?.find((artwork) => artwork._id === _id);
  //     return { _id, price, ...restOfProperties };
  //   });
  // }

  // const calculateSingleArtworkSubtotal = (artworkId) => {
  //   const artwork = paymentInvite.artworks.find((artwork) => artwork._id === artworkId);

  //   if (!artwork) {
  //     return null;
  //   }

  //   const discountTotal = artwork.discounts.reduce((total, discount) => {
  //     return total + Number(artwork.price.value) * (discount.value / 100);
  //   }, 0);

  //   const totalPrice = Number(artwork.price.value) - discountTotal;

  //   return totalPrice;
  // };

  return (
    <Flex justifyContent="space-between" width="992px" height="800px">
      {/* Left Side */}
      <Box p="xxl" marginRight="xxs" overflow="auto" overflowX="hidden" width="55%">
        <Typography sx={headerTextStyle(isMobile)}>
          {DynamicHeadings.sendEnquiryTo(paymentInvite?._user?.organisation?.name || paymentInvite?._user?.name)}
        </Typography>
        {/* Artwork boxes currently hidden */}
        {/* <Flex mt="18px" p="xxxs" mb="m" width="460px" overflowX="auto">
          {combinedArtworksArray.map((artwork, index) => {
            const { images = [] } = artwork;
            const [firstImage = {}] = images;
            console.log("ARTWORK", artwork);
            return (
              <Box
                key={artwork._id}
                boxShadow="small"
                width="212px"
                mr={index === combinedArtworksArray.length - 1 ? "0" : "m"}
                mb="xxs"
                padding="s"
              >
                <Flex justifyContent="space-between" mb="xxs" alignItems="start">
                  <Box minWidth="74px" mr="xs">
                    <ImageWithCache
                      image={firstImage}
                      artworkId={artwork.id}
                      urlSuffix="/preview.jpg"
                      width="100%"
                      userselect="none"
                    />
                  </Box>
                  <Box>
                    <Text fontSize="smaller">{artwork.artist}</Text>
                    <Text
                      fontSize="smaller"
                      color="grey.80"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      maxWidth="14ch"
                    >
                      {artwork.artworkValues.title}
                    </Text>
                  </Box>
                </Flex>
                <Box fontSize="smaller">
                  <Flex justifyContent="space-between">
                    <Text>{UiLabels.artworkPrice}:</Text>
                    <Text>
                      {currencySymbol}
                      {artwork.price.value}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between" mt="xxxs">
                    <Text>{UiLabels.subTotal}:</Text>
                    <Text color="blue.60">
                      {currencySymbol}
                      {calculateSingleArtworkSubtotal(artwork._id)}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            );
          })}
        </Flex> */}
        <Box width="460px">
          <BuyerEnquiryForm paymentInviteId={paymentInviteId} isMarketPlace={isMarketPlace} placeId={placeId} />
        </Box>
      </Box>

      {/* Right Side */}
      <PaymentInviteModalInfo isEnquiry={true} />
    </Flex>
  );
};
