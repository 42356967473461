import {
  QR_SOURCE, SEND_EVENT
} from "../actions/types";

const COMMS_INITIAL = {
};

export default function commsReducer(state = COMMS_INITIAL, action) {
  switch (action.type) {
    case QR_SOURCE:
      return { ...state, ...action.payload };
    case SEND_EVENT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};