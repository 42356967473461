import React from "react";
import { useForm } from "react-hook-form";
import { WithRequireAccess } from "../hocs";
import { ButtonLabels, Headings, UiLabels } from "../../localisation";
import { selectUser } from "../../store/selectors";
import { createArtist, addActionToQueue } from "../../store/actions";

import { Box, Button, Stack, Typography } from "@mui/material";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router-dom";
import { ArrowLeft, UploadCloud, X } from "react-feather";
import { useSelector, useDispatch } from "react-redux";

import { NavigationBar } from "../react-material/NavigationBar/NavigationBar";

import { ArtistForm } from "../react-material/forms/ArtistForm.jsx";

const buttonStyle = {
  color: "white",
  fontFamily: "Inter",
  fontSize: "16px",
  lineHeight: "16px",
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.7,
  },
  "&:active": {
    opacity: 0.5,
  },
};

const defaultValues = {
  education: [
    {
      startDate: null,
      endDate: null,
      institution: "",
      course: "",
      city: "",
      country: "",
    },
  ],
  exhibitions: [
    {
      exhibitionType: "",
      partner: "",
      startDate: null,
      endDate: null,
      title: "",
      venue: "",
      city: "",
      country: "",
      curatedBy: "",
    },
  ],
  collections: [
    {
      name: "",
      city: "",
      country: "",
    },
  ],
  publications: [
    {
      editor: "",
      publisher: "",
      author: "",
      title: "",
      city: "",
      year: "",
      issue: "",
    },
  ],
  awards: [
    {
      year: "",
      title: "",
      city: "",
      country: "",
    },
  ],
};

const AddArtistScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: { ...defaultValues } });

  const submit = (values, event) => {
    event.preventDefault();
    const tempArtistId = uuid();
    return dispatch(
      addActionToQueue(
        createArtist(
          {
            ...values,
          },
          tempArtistId
        ),
        tempArtistId
      )
    );
  };

  const renderFloatingActionPillButtons = () => {
    return [
      <Button
        type="submit"
        variant="text"
        disabled={isSubmitting}
        startIcon={<UploadCloud size="20px" />}
        sx={buttonStyle}
      >
        {ButtonLabels.saveArtist}
      </Button>,

      <Button onClick={() => history.goBack()} variant="text" startIcon={<X size="20px" />} sx={buttonStyle}>
        {ButtonLabels.cancel}
      </Button>,
    ];
  };
  return (
    <Box width="100%">
      <Box maxWidth="98%" margin="0 auto">
        <NavigationBar />
      </Box>
      <Stack direction="row">
        <Stack pl="48px" pt="16px" pb="16px" alignItems="flex-start" flexGrow={1} minHeight="100vh" bgcolor="white">
          <Button
            sx={{ lineHeight: "16px", fontSize: "14px", pl: "0px" }}
            startIcon={<ArrowLeft style={{ position: "relative", right: "-5px", width: "16px", height: "16px" }} />}
            onClick={() => history.push("/artists")}
          >
            {ButtonLabels.backToArtists}
          </Button>
          <Stack direction="row">
            <Typography variant="h2" sx={{ color: "#6A6870" }}>
              {Headings.artists}
            </Typography>
            <Typography variant="h2" sx={{ color: "#6A6870", m: "0 8px" }}>
              /
            </Typography>
            <Typography variant="h2">{Headings.newArtist}</Typography>
          </Stack>
          <Stack mt="16px" mb="48px">
            <Typography sx={{ fontSize: "12px", fontWeight: 500, color: "#95939C" }}>{UiLabels.lastUpdated}</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {moment().format("MMMM Do YYYY, h:mm")} by {user.name}
            </Typography>
          </Stack>
          <Stack sx={{ width: "100%", alignItems: "center", paddingBottom: "100px" }}>
            <ArtistForm
              handleSubmit={handleSubmit}
              watch={watch}
              setValue={setValue}
              control={control}
              errors={errors}
              selectedArtistIds={[]}
              renderFloatingActionPillButtons={renderFloatingActionPillButtons}
              onSubmit={submit}
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default WithRequireAccess(AddArtistScreen, 1);
