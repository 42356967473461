import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import CountryData from "country-region-data";
import {
  Headings,
  ButtonLabels,
  InputPlaceholders,
  UiLabels,
  DynamicLabels,
  Messages,
  InputErrors,
} from "../../localisation";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Flex, Text } from "../fundamentals";
import {
  Button,
  Input,
  InputSelect,
  StripeCardNumberInput,
  StripeCardExpiryInput,
  StripeCardCvcInput,
  RadioSelect,
} from "../elements";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { register } from "../../store/actions";
import { returnSelectedPlan, returnStripePlansBasedOnUserRole } from "../../utils";
import { useIsScreenDesktop, useIsScreenMobile, useIsScreenTablet } from "../hooks/useMediaQuery";
import { Box, Typography } from "@mui/material";
import { RenderPaymentPlan } from "../react-material/RenderPaymentPlan/RenderPaymentPlan";

const headerTextStyle = (mobile) => {
  return {
    textAlign: "center",
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "32px",
    lineHeight: mobile ? "26px" : "40px",
    marginBottom: mobile ? "30px" : "39px",
    fontWeight: "400",
  };
};

const subHeadingStyle = {
  fontSize: "12px",
  lineHeight: "14px",
  fontWeight: "600",
  letterSpacing: "2px",
  textAlign: "left",
  marginBottom: "24px",
};

const Form = ({ handleSubmit, submitting, plan, role, user, prevLocation }) => {
  const isTablet = useIsScreenTablet();
  const isMobile = useIsScreenMobile();
  const isDesktop = useIsScreenDesktop();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  useEffect(() => {
    if (!role && !user) {
      history.push("/register");
    }
  }, [role, history]);

  const submit = (values, dispatch) => {
    if (submitting) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }
    // Inject role property to cater to the case of user having selected role artist on webflow and not through onboarding
    values.role = role;

    return dispatch(
      register.request({
        ...values,
        stripe,
        elements,
        prevLocation,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Flex
        flexDirection="column"
        alignItems="center"
        width={isTablet ? "588px" : isMobile ? "325px" : "1060px"}
        bg="white"
        p={isMobile ? "m" : "60px 60px 35px 60px"}
        boxShadow="mediumLarge"
        borderRadius="15px"
        marginBottom="72px"
      >
        <Typography sx={headerTextStyle(isMobile)}>{Headings.chooseBillingPeriod}</Typography>
        <Flex justifyContent="space-between" marginBottom={isDesktop ? "72px" : "30px"}>
          <Field
            name="plan"
            options={returnStripePlansBasedOnUserRole(role)}
            direction="horizontal"
            customItemComponent={RenderPaymentPlan}
            component={RadioSelect}
          />
        </Flex>

        <Flex
          flexDirection={isTablet || isMobile ? "column-reverse" : "row"}
          justifyContent="space-between"
          alignItems="start"
          mt="xxxs"
          width="100%"
        >
          {/* Left side */}

          <Box width={isTablet ? "80%" : isMobile ? "100%" : "45%"}>
            <Typography sx={{ ...subHeadingStyle }}>{Headings.accountDetails.toUpperCase()}</Typography>

            <Flex justifyContent="space-between" mt="s">
              <Field
                name="address"
                placeholder={InputPlaceholders.address}
                flex="2"
                mr="m"
                minHeight={isDesktop ? "50px" : "32px"}
                component={Input}
              />
              <Field
                name="apt"
                placeholder={InputPlaceholders.apt}
                flex="1"
                minHeight={isDesktop ? "50px" : "32px"}
                component={Input}
              />
            </Flex>

            <Field
              name="city"
              placeholder={InputPlaceholders.city}
              minHeight={isDesktop ? "50px" : "32px"}
              component={Input}
            />

            <Field
              name="country"
              placeholder="Country"
              options={CountryData.map((country) => ({ value: country.countryName, label: country.countryName }))}
              component={InputSelect}
            />

            <Field
              mt="-10px"
              mb="s"
              name="postalCode"
              placeholder={InputPlaceholders.postalCode}
              minHeight={isDesktop ? "50px" : "32px"}
              component={Input}
            />

            <Field
              name="cardNumber"
              height={isDesktop ? "50px" : "32px"}
              component={StripeCardNumberInput}
              options={{ placeholder: InputPlaceholders.creditCardNumber }}
            />

            <Flex justifyContent="space-between" mt="s">
              <Field name="cardExpiryDate" height={isDesktop ? "50px" : "32px"} component={StripeCardExpiryInput} />
              <Field name="cardCvc" height={isDesktop ? "50px" : "32px"} component={StripeCardCvcInput} />
            </Flex>

            {(isTablet || isMobile) && (
              <Box display="flex" flexDirection="column" gap="18px">
                <Button
                  buttonStyle="form"
                  type="submit"
                  size={isMobile ? "small" : "medium"}
                  fontFamily="body"
                  mt="m"
                  disabled={submitting || !plan}
                  icon={submitting ? "Loader" : null}
                  iconSpinning={true}
                  flip={true}
                  iconOffset="xs"
                  // bg="white"
                  p={isMobile ? "5px 12px" : "8px 16px"}
                  alignSelf="center"
                >
                  {ButtonLabels.payAndCreateAccount}
                </Button>
                <Button
                  buttonStyle="formBack"
                  size="small"
                  icon="ArrowLeft"
                  alignSelf="center"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {ButtonLabels.back}
                </Button>
              </Box>
            )}
          </Box>

          {/* Right Side */}

          <Box width={isTablet ? "80%" : isMobile ? "100%" : "45%"} height={isMobile ? "320px" : "410px"}>
            <Typography sx={{ ...subHeadingStyle }}>{Headings.orderSummary.toUpperCase()}</Typography>

            <Flex justifyContent="space-between" fontWeight="500" color="blue.60" mb="xs">
              <Text size="body">{`${returnSelectedPlan(plan).label}`}</Text>
              <Text size="body">{"\u20ac" + returnSelectedPlan(plan).price}</Text>
            </Flex>

            {plan && (
              <Flex mb="m">
                <Text size="body">{`Paid ${returnSelectedPlan(plan).billing_period}`}</Text>
              </Flex>
            )}

            <Box borderBottom="1px solid #C8CEFF"></Box>

            <Flex justifyContent="space-between" mt="m" mb="xxs">
              <Text size="body">{UiLabels.subTotal}</Text>
              <Text size="body">{"\u20ac" + returnSelectedPlan(plan).subtotal}</Text>
            </Flex>

            <Flex justifyContent="space-between" mb="m">
              <Text size="body">{UiLabels.vat.toUpperCase()}</Text>
              <Text size="body">{"\u20ac" + returnSelectedPlan(plan).vat}</Text>
            </Flex>

            <Box borderBottom="1px solid #C8CEFF" my="s"></Box>

            <Flex justifyContent="space-between" fontWeight="500" color="blue.60" mt="m" mb="xxs">
              <Text size="body">{UiLabels.dueNow}</Text>
              <Text size="body">{"\u20ac" + returnSelectedPlan(plan).price}</Text>
            </Flex>

            <Text size="smaller" mb="l">
              {plan &&
                `${Messages.automaticRenewMessage(
                  DynamicLabels.planRenewalDate(moment().add(returnSelectedPlan(plan).daysTillRenewal, "days"))
                )}`}
            </Text>

            {isDesktop && (
              <Box display="flex" flexDirection="column" gap="18px">
                <Button
                  buttonStyle="form"
                  type="submit"
                  size={isMobile ? "small" : "medium"}
                  fontFamily="body"
                  disabled={submitting}
                  icon={submitting ? "Loader" : null}
                  iconSpinning={true}
                  flip={true}
                  iconOffset="xs"
                  // bg="white"
                  p={isMobile ? "5px 12px" : "8px 16px"}
                >
                  {ButtonLabels.payAndCreateAccount}
                </Button>
                <Button
                  buttonStyle="formBack"
                  size="small"
                  icon="ArrowLeft"
                  pl="none"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {ButtonLabels.back}
                </Button>
              </Box>
            )}
          </Box>
        </Flex>
      </Flex>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const { plan, address, city, country, postalCode } = values;

  if (!plan) errors.plan = InputErrors.noPlan;
  if (!address) errors.address = InputErrors.noAddress;
  if (!city) errors.city = InputErrors.noCity;
  if (!country) errors.country = InputErrors.noCountry;
  if (!postalCode) errors.postalCode = InputErrors.noPostalCode;

  // if (!cardNumber) errors.cardNumber = InputErrors.noCardNumber;
  // if (!cardExpiryDate) errors.cardExpiryDate = InputErrors.noCardExpiryDate;
  // if (!cardCvc) errors.cardCvc = InputErrors.noCardCvc;

  return errors;
};

let OnboardingThirdForm = reduxForm({
  form: "register",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

const selector = formValueSelector("register");

function mapStateToProps(state) {
  const role = selector(state, "role");
  const plan = selector(state, "plan");
  // If the user has selected role artist on webflow, they haven't selected a role during onboarding,
  // so the role should be equal to the urlRole saved in global state
  if (typeof role == 'undefined') {
    const role = state.user.urlRole;
    return { role, plan, user: state.user };
 };

  return { role, plan, user: state.user };
}

OnboardingThirdForm = connect(mapStateToProps, null)(OnboardingThirdForm);

export { OnboardingThirdForm };
