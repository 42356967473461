import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/selectors";
import { Box, Text, Link } from "../../fundamentals";

import { FloatingActionPill } from "../FloatingActionPill";

import { ArtistGeneralFormSection } from "./ArtistGeneralFormSection";
import { ArtistEducationFormSection } from "./ArtistEducationFormSection";
import { ArtistExhibitionsFormSection } from "./ArtistExhibitionsFormSection";
import { ArtistCollectionsFormSection } from "./ArtistCollectionsFormSection";
import { ArtistPublicationsFormSection } from "./ArtistPublicationsFormSection";
import { ArtistAwardsFormSection } from "./ArtistAwardsFormSection";
import { ReactHookFormCheckBox } from "../Checkbox";

export const ArtistForm = ({
  handleSubmit,
  onSubmit,
  watch,
  setValue,
  control,
  errors,
  renderFloatingActionPillButtons,
}) => {
  const fixedPillRef = useRef(null);
  const user = useSelector(selectUser);

  const watchPublishToDiscovery = watch("publishToDiscovery");
  const watchYearDeath = watch("yearDeath");
  const watchCityDeath = watch("cityDeath");
  const watchCountryDeath = watch("countryDeath");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Only Artists can select to publish their Profile to Discovery */}
      {user.role === "artist" && (
        <Box p="m" backgroundColor="blue.10" border="1px solid #C3C2C9" borderRadius="8px" width="828px" mb="30px">
          <ReactHookFormCheckBox
            name="publishToDiscovery"
            control={control}
            errors={errors}
            size="16"
            label={"Publish Profile to Discovery"}
          />
          <Text fontSize="small">
            Discovery is the simplify.art marketplace for discovering and selling your art. Powered by Yurica, an AI
            that we've built and trained, we're helping artists, curators and galleries find each other more easily.{" "}
            <span>
              {" "}
              <Link
                href="https://www.simplify.art/sell-your-art"
                target="_blank"
                color="black"
                textDecoration="underline"
                cursor="pointer"
              >
                Learn more here.
              </Link>
            </span>{" "}
          </Text>
        </Box>
      )}
      <ArtistGeneralFormSection
        control={control}
        errors={errors}
        setValue={setValue}
        watchPublishToDiscovery={watchPublishToDiscovery}
        watchYearDeath={watchYearDeath}
        watchCityDeath={watchCityDeath}
        watchCountryDeath={watchCountryDeath}
      />
      <ArtistEducationFormSection
        control={control}
        errors={errors}
        setValue={setValue}
        watchPublishToDiscovery={watchPublishToDiscovery}
      />
      <ArtistExhibitionsFormSection
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        watchPublishToDiscovery={watchPublishToDiscovery}
      />
      <ArtistCollectionsFormSection control={control} errors={errors} setValue={setValue} />
      <ArtistPublicationsFormSection control={control} errors={errors} setValue={setValue} />
      <ArtistAwardsFormSection control={control} errors={errors} setValue={setValue} />
      {renderFloatingActionPillButtons && (
        <FloatingActionPill ref={fixedPillRef}>{renderFloatingActionPillButtons()}</FloatingActionPill>
      )}
    </form>
  );
};
