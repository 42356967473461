import {
    CREATE_LIST_REQUEST,
    CREATE_LIST_LOCALLY,
    CREATE_LIST_SUCCESS,
    CREATE_LIST_FAILURE,
    GET_LISTS_REQUEST,
    GET_LISTS_SUCCESS,
    EDIT_LIST_REQUEST,
    EDIT_LIST_LOCALLY,
    EDIT_LIST_SUCCESS,
    EDIT_LIST_FAILURE,
    DELETE_LISTS,
    DELETE_LIST_REQUEST,
    DELETE_LIST_SUCCESS,
    DELETE_LIST_FAILURE,
    DOWNLOAD_LIST_PDF,
} from "./types";

export const createListRequest = ({  artworks, category, path, ...listValues }, tempListId) => {
    return {
      type: CREATE_LIST_REQUEST,
      payload: {
        tempListId,
        dateCreated: new Date().toISOString(),
        artworks,
        category,
        path,
        ...listValues,
      },
    };
  };
  
  export const createListLocally = (list) => {
    return {
      type: CREATE_LIST_LOCALLY,
      payload: { ...list },
    };
  };

  export const createListSuccess = (tempListId, response) => {
    return { type: CREATE_LIST_SUCCESS, payload: { tempListId, ...response } };
  };
  
  export const createListFailure = () => {
    return { type: CREATE_LIST_FAILURE, payload: {} };
  };

  export const getListsRequest = () => {
    return { type: GET_LISTS_REQUEST };
  };

  export const getListsSuccess = (lists) => {
    return { type: GET_LISTS_SUCCESS, payload: lists };
  };

  export const getListsFailure = () => {
    return { type: GET_LISTS_REQUEST, payload: [] };
  };

  export const editListRequest = ({ id, selectedArtworks, isDownload, isNewList, ...formValues }) => {
    return {
      type: EDIT_LIST_REQUEST,
      payload: {
        id,
        lastModified: new Date().toISOString(),
        isDownload,
        isNewList,
        ...formValues,
        artworks: selectedArtworks,
      },
    };
  };
  
  export const editListLocally = (list) => {
    return { type: EDIT_LIST_LOCALLY, payload: { ...list } };
  };
  
  export const editListSuccess = (listId, response) => {
    return { type: EDIT_LIST_SUCCESS, payload: { listId, response } };
  };
  
  export const editListFailure = () => {
    return { type: EDIT_LIST_FAILURE, payload: {} };
  };

  export const deleteLists = (listIds) => {
    return { type: DELETE_LISTS, payload: { listIds } };
  };
  
  export const deleteList = (listId) => {
    return { type: DELETE_LIST_REQUEST, payload: { listId } };
  };
  
  export const deleteListSuccess = (listId) => {
    return { type: DELETE_LIST_SUCCESS, payload: { listId } };
  };
  
  export const deleteListFailure = (listId) => {
    return { type: DELETE_LIST_FAILURE, payload: { listId } };
  };

  export const downloadListPdf = (list) => {
    return {
      type: DOWNLOAD_LIST_PDF,
      payload: { ...list },
    };
  };