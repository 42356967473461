import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "redux-form";
import {
  selectSelectedArtworkIds,
  selectSelectedArtworks,
  selectUser,
  selectArtworksById,
} from "../../store/selectors";
import { TUTORIAL_NAMES } from "../../config";
import { FieldNotEmpty } from "../../utils";
import { createExport, createCsv, executeJoyride, removeSelectedArtwork } from "../../store/actions";
import { Box, Flex, Text, ImageWithCache } from "../fundamentals";
import { GenericExportForm } from "../forms";
import { PageTransitions, Input, InputSelect, RadioSelect, Checkbox, Button, Section } from "../elements";
import {
  Headings,
  InputLabels,
  Instructions,
  UiLabels,
  Messages,
  ExportStandardConditions,
  InputErrors,
  Warnings,
} from "../../localisation";

const AdvancedExportsModal = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState("template");
  const selectedArtworkIds = useSelector(selectSelectedArtworkIds);
  const selectedArtworks = useSelector((state) => selectArtworksById(state, selectedArtworkIds));

  useEffect(() => {
    dispatch(executeJoyride(TUTORIAL_NAMES.whiteLabeledExports));
  }, []);

  const handleOnBackToTemplate = () => {
    setPage("template");
  };

  const handleRemoveSelectedArtworkClick = (artwork) => {
    dispatch(removeSelectedArtwork(artwork.id));
  };

  return (
    <Box>
      <PageTransitions
        page={page}
        initialWidth={800}
        initialHeight={510}
        transition="width 0.2s ease-out"
        timeout="0"
        childAlignment="center"
      >
        <Flex page="template" width="70vw" maxWidth="800px" minWidth="600px" height="510px" alignItems="stretch">
          <Box overflow="auto">
            {selectedArtworks.map((artwork, index) => {
              const { images = [] } = artwork;
              const [firstImage = {}] = images;
              return (
                <Flex
                  position="relative"
                  flexDirection="column"
                  justifyContent="end"
                  width="175px"
                  minHeight="155px"
                  padding="s"
                  margin="xxxs"
                  marginRight="xs"
                  marginBottom={index !== selectedArtworks.length - 1 && "xxl"}
                  boxShadow="small"
                  borderRadius="2px"
                >
                  {artwork.images.length ? (
                    <ImageWithCache
                      image={firstImage}
                      artworkId={artwork.id}
                      urlSuffix="/preview.jpg"
                      width="100%"
                      userSelect="none"
                      mb="s"
                      imageKey={artwork.id}
                    />
                  ) : (
                    <Box pt="l" height="100px">
                      <Text color="grey.60" fontSize="smaller">
                        No image available
                      </Text>
                    </Box>
                  )}
                  <Text mb="xxxs">{artwork.artist}</Text>
                  <Flex>
                    <Text
                      fontSize="small"
                      color="grey.80"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      maxWidth={artwork.artworkValues.year ? "11ch" : "16ch"}
                    >
                      {artwork.artworkValues.title}
                    </Text>
                    {artwork.artworkValues.year && (
                      <Text fontSize="small" color="grey.80">
                        {`, ${artwork.artworkValues.year}`}
                      </Text>
                    )}
                  </Flex>
                  {selectedArtworks.length > 1 && (
                    <Flex flexDirection="column" position="absolute" top="xxs" right="xxs" pointerEvents="none">
                      <Button
                        buttonStyle={"primary"}
                        size="small"
                        icon="X"
                        iconStrokeWidth="3px"
                        onClick={() => handleRemoveSelectedArtworkClick(artwork)}
                        mb="xxs"
                      ></Button>
                    </Flex>
                  )}
                </Flex>
              );
            })}
          </Box>
          <Box flex="2" ml="xl">
            <Text size="heading2" color="black">
              {Headings.exportArtworks}
            </Text>
            <Text size="body" color="grey.80">
              {Instructions.exportsTypeSelect}
            </Text>
            <Flex mt="xxl">
              <Box flex="1">
                <Section title={Headings.artworkLists} titleSize="small" color="grey.80" titleStyle={{ pb: "xxs" }}>
                  <Button buttonStyle="text" size="medium" color="black" pl="none" onClick={() => setPage("portfolio")}>
                    {InputLabels.portfolio}
                  </Button>
                  <Button buttonStyle="text" size="medium" color="black" pl="none" onClick={() => setPage("workList")}>
                    {InputLabels.workList}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="black"
                    pl="none"
                    onClick={() => setPage("transportList")}
                  >
                    {InputLabels.transportList}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="black"
                    pl="none"
                    onClick={() => setPage("insuranceList")}
                  >
                    {InputLabels.insuranceList}
                  </Button>
                  <Button buttonStyle="text" size="medium" color="black" pl="none" onClick={() => setPage("priceList")}>
                    {InputLabels.priceList}
                  </Button>
                  <Button buttonStyle="text" size="medium" color="black" pl="none" onClick={() => setPage("dataSheet")}>
                    {InputLabels.dataSheet}
                  </Button>
                </Section>

                <Section
                  title={Headings.salesAndAgreements}
                  titleSize="small"
                  color="grey.80"
                  titleStyle={{ pb: "xxs" }}
                >
                  <Button buttonStyle="text" size="medium" color="black" pl="none" onClick={() => setPage("invoice")}>
                    {InputLabels.invoice}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="black"
                    pl="none"
                    onClick={() => setPage("loanAgreement")}
                  >
                    {InputLabels.loanAgreement}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="black"
                    pl="none"
                    onClick={() => setPage("consignmentAgreement")}
                  >
                    {InputLabels.consignmentAgreement}
                  </Button>
                </Section>
              </Box>
              <Box flex="1">
                <Section title={Headings.artworkDocuments} titleSize="small" color="grey.80" titleStyle={{ pb: "xxs" }}>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="black"
                    pl="none"
                    onClick={() => setPage("certificate")}
                  >
                    {InputLabels.certificate}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color={selectedArtworkIds.length > 1 ? "grey.60" : "black"}
                    pl="none"
                    onClick={() => setPage("conditionReport")}
                    disabled={selectedArtworkIds.length > 1}
                  >
                    {InputLabels.conditionReport}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color={selectedArtworkIds.length > 1 ? "grey.60" : "black"}
                    pl="none"
                    onClick={() => setPage("installationManual")}
                    disabled={selectedArtworkIds.length > 1}
                  >
                    {InputLabels.installationManual}
                  </Button>
                  <Button buttonStyle="text" size="medium" color="black" pl="none" onClick={() => setPage("handoff")}>
                    {InputLabels.handoff}
                  </Button>
                </Section>

                <Section title={Headings.labels} titleSize="small" color="grey.80" titleStyle={{ pb: "xxs" }}>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="black"
                    pl="none"
                    onClick={() => setPage("storageLabels")}
                  >
                    {InputLabels.storageLabels}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="black"
                    pl="none"
                    onClick={() => setPage("wallLabels")}
                  >
                    {InputLabels.wallLabels}
                  </Button>
                </Section>
                <Section title={Headings.csv} titleSize="small" color="grey.80" titleStyle={{ pb: "xxs" }}>
                  <Button buttonStyle="text" size="medium" color="black" pl="none" onClick={() => setPage("csv")}>
                    {InputLabels.csv}
                  </Button>
                </Section>
              </Box>
            </Flex>
          </Box>
        </Flex>

        <PortfolioModal page="portfolio" handleOnBackToTemplate={handleOnBackToTemplate} />
        <WorkListModal page="workList" handleOnBackToTemplate={handleOnBackToTemplate} />
        <TransportListModal page="transportList" handleOnBackToTemplate={handleOnBackToTemplate} />
        <InsuranceListModal page="insuranceList" handleOnBackToTemplate={handleOnBackToTemplate} />
        <PriceListModal page="priceList" handleOnBackToTemplate={handleOnBackToTemplate} />
        <InvoiceModal page="invoice" handleOnBackToTemplate={handleOnBackToTemplate} />
        <LoanAgreementModal page="loanAgreement" handleOnBackToTemplate={handleOnBackToTemplate} />
        <ConsignmentAgreementModal
          page="consignmentAgreement"
          handleOnBackToTemplate={handleOnBackToTemplate}
        />
        <HandoffDocumentModal page="handoff" handleOnBackToTemplate={handleOnBackToTemplate} />
        <CertificateModal page="certificate" handleOnBackToTemplate={handleOnBackToTemplate} />
        <ConditionModal page="conditionReport" handleOnBackToTemplate={handleOnBackToTemplate} />
        <InstallationManualModal
          page="installationManual"
          handleOnBackToTemplate={handleOnBackToTemplate}
        />
        <DataSheetModal page="dataSheet" handleOnBackToTemplate={handleOnBackToTemplate} />
        <StorageLabelsModal page="storageLabels" handleOnBackToTemplate={handleOnBackToTemplate} />
        <WallLabelsModal page="wallLabels" handleOnBackToTemplate={handleOnBackToTemplate} />
        <CsvModal page="csv" handleOnBackToTemplate={handleOnBackToTemplate} />
      </PageTransitions>
    </Box>
  );
};

const PortfolioModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "subtitle",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.subtitle,
            },
          ],
        },
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "sortBy",
              field: "InputSelect",
              options: [
                { value: "artist.name", label: InputLabels.artist },
                { value: "archiveArtworkNumber", label: InputLabels.id },
                { value: "artworkValues.year", label: InputLabels.year },
              ],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.sortBy,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "showPrice",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.price,
            },
            {
              fieldName: "vat",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: true,
              isTextarea: false,
              label: InputLabels.vat,
            },
            {
              fieldName: "isVatIncluded",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.vatIncluded,
            },
            {
              fieldName: "showInsuranceValue",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.insuranceValue,
            },
            {
              fieldName: "showCurrentOwner",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.currentOwner,
            },
            {
              fieldName: "showWorkDescription",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.workDescription,
            },
            {
              fieldName: "showSecondaryImages",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.secondaryImages,
            },
            {
              fieldName: "showPageNumber",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pageNumber,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  const formValues = useSelector((state) => state.form?.exports?.values);
  let showPrice = false;
  if (formValues) {
    showPrice = formValues.showPrice;
  }

  return (
    <GenericExportForm
      title={Headings.portfolio}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.portfolio,
        sortBy: "artist.name",
        isVatIncluded: false,
        showId: false,
        showCustomId: false,
        showPrice: false,
        showCurrentOwner: false,
        showInsuranceValue: false,
        showWorkDescription: false,
        showSecondaryImages: false,
        showPageNumber: false,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field name="subtitle" label={InputLabels.subtitle} mb="s" component={Input} />
      <Field
        name="sortBy"
        label={InputLabels.sortBy}
        options={[
          { value: "artist.name", label: InputLabels.artist },
          { value: "archiveArtworkNumber", label: InputLabels.id },
          { value: "artworkValues.year", label: InputLabels.year },
        ]}
        component={InputSelect}
        stateful
      />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />
        <Field name="showPrice" label={InputLabels.price} mb="s" component={Checkbox} />
        {showPrice && (
          <Box mb="s">
            <Field name="vat" label={InputLabels.vat} type="number" suffix="%" component={Input} />
            <Field name="isVatIncluded" label={InputLabels.vatIncluded} component={Checkbox} />
          </Box>
        )}
        <Field name="showInsuranceValue" label={InputLabels.insuranceValue} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} mb="s" component={Checkbox} />
        <Field name="showWorkDescription" label={InputLabels.workDescription} mb="s" component={Checkbox} />
        <Field name="showSecondaryImages" label={InputLabels.secondaryImages} mb="s" component={Checkbox} />
        <Field name="showPageNumber" label={InputLabels.pageNumber} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const WorkListModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "sortBy",
              field: "InputSelect",
              options: [
                { value: "artist.name", label: InputLabels.artist },
                { value: "archiveArtworkNumber", label: InputLabels.id },
                { value: "artworkValues.year", label: InputLabels.year },
              ],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.sortBy,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "showPrice",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.price,
            },
            {
              fieldName: "vat",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: true,
              isTextarea: false,
              label: InputLabels.vat,
            },
            {
              fieldName: "isVatIncluded",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.vatIncluded,
            },
            {
              fieldName: "showInsuranceValue",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.insuranceValue,
            },
            {
              fieldName: "showLocation",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.location,
            },
            {
              fieldName: "showCurrentOwner",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.currentOwner,
            },
            {
              fieldName: "showPhotoCredits",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.photoCredits,
            },
            {
              fieldName: "showPageNumber",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pageNumber,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  const formValues = useSelector((state) => state.form?.exports?.values);
  let showPrice = false;
  if (formValues) {
    showPrice = formValues.showPrice;
  }

  return (
    <GenericExportForm
      title={Headings.workList}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.workList,
        sortBy: "artist.name",
        isVatIncluded: false,
        showId: false,
        showCustomId: false,
        showPrice: false,
        showInsuranceValue: false,
        showLocation: false,
        showCurrentOwner: false,
        showPhotoCredits: false,
        showPageNumber: false,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field
        name="sortBy"
        label={InputLabels.sortBy}
        options={[
          { value: "artist.name", label: InputLabels.artist },
          { value: "archiveArtworkNumber", label: InputLabels.id },
          { value: "artworkValues.year", label: InputLabels.year },
        ]}
        component={InputSelect}
        stateful
      />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="xs">
        <Field name="showId" label={InputLabels.id} mb="xs" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="xs" component={Checkbox} />
        <Field name="showPrice" label={InputLabels.price} mb="xs" component={Checkbox} />
        {showPrice && (
          <Box mb="s">
            <Field name="vat" label={InputLabels.vat} type="number" suffix="%" component={Input} />
            <Field name="isVatIncluded" label={InputLabels.vatIncluded} component={Checkbox} />
          </Box>
        )}
        <Field name="showInsuranceValue" label={InputLabels.insuranceValue} mb="xs" component={Checkbox} />
        <Field name="showLocation" label={InputLabels.location} mb="xs" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} mb="xs" component={Checkbox} />
        <Field name="showPhotoCredits" label={InputLabels.photoCredits} mb="s" component={Checkbox} />
        <Field name="showPageNumber" label={InputLabels.pageNumber} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const TransportListModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "sortBy",
              field: "InputSelect",
              options: [
                { value: "artist.name", label: InputLabels.artist },
                { value: "archiveArtworkNumber", label: InputLabels.id },
                { value: "artworkValues.year", label: InputLabels.year },
              ],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.sortBy,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "hasOriginAddress",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pickupAddress,
            },
            {
              fieldName: "origin.address",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.address,
            },
            {
              fieldName: "origin.postalCode",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.postCode,
            },
            {
              fieldName: "origin.city",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.city,
            },
            {
              fieldName: "origin.country",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.country,
            },
            {
              fieldName: "hasTargetAddress",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.deliveryAddress,
            },
            {
              fieldName: "target.address",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.address,
            },
            {
              fieldName: "target.postalCode",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.postCode,
            },
            {
              fieldName: "target.city",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.city,
            },
            {
              fieldName: "target.country",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.country,
            },
            {
              fieldName: "hasReturnAddress",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.returnAddress,
            },
            {
              fieldName: "return.address",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.address,
            },
            {
              fieldName: "return.postalCode",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.postCode,
            },
            {
              fieldName: "return.city",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.city,
            },
            {
              fieldName: "return.country",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.country,
            },
            {
              fieldName: "showPrice",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.price,
            },
            {
              fieldName: "vat",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: true,
              isTextarea: false,
              label: InputLabels.vat,
            },
            {
              fieldName: "isVatIncluded",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.vatIncluded,
            },
            {
              fieldName: "showInsuranceValue",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.insuranceValue,
            },
            {
              fieldName: "showLocation",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.location,
            },
            {
              fieldName: "showCurrentOwner",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.currentOwner,
            },
            {
              fieldName: "showPageNumber",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pageNumber,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  const formValues = useSelector((state) => state.form?.exports?.values);
  let hasOriginAddress = false;
  let hasTargetAddress = false;
  let hasReturnAddress = false;
  let showPrice = false;
  if (formValues) {
    hasOriginAddress = formValues.hasOriginAddress;
    hasTargetAddress = formValues.hasTargetAddress;
    hasReturnAddress = formValues.hasReturnAddress;
    showPrice = formValues.showPrice;
  }

  return (
    <GenericExportForm
      title={Headings.transportList}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.transportList,
        sortBy: "artist.name",
        isVatIncluded: false,
        showId: false,
        showCustomId: false,
        hasOriginAddress: false,
        hasTargetAddress: false,
        hasReturnAddress: false,
        showPrice: false,
        showInsuranceValue: false,
        showLocation: false,
        showCurrentOwner: false,
        showPageNumber: false,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field
        name="sortBy"
        label={InputLabels.sortBy}
        options={[
          { value: "artist.name", label: InputLabels.artist },
          { value: "archiveArtworkNumber", label: InputLabels.id },
          { value: "artworkValues.year", label: InputLabels.year },
        ]}
        component={InputSelect}
        stateful
      />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />
        <Field name="hasOriginAddress" label={InputLabels.pickupAddress} mb="s" component={Checkbox} />
        {hasOriginAddress && (
          <Box>
            <Flex>
              <Field name="origin.address" label={InputLabels.address} component={Input} mr="xxs" width="70%" />
              <Field name="origin.postalCode" label={InputLabels.postCode} component={Input} width="30%" />
            </Flex>
            <Flex justifyContent="space-between">
              <Field name="origin.city" label={InputLabels.city} component={Input} mr="xxs" />
              <Field name="origin.country" label={InputLabels.country} component={Input} />
            </Flex>
          </Box>
        )}
        <Field name="hasTargetAddress" label={InputLabels.deliveryAddress} mb="s" component={Checkbox} />
        {hasTargetAddress && (
          <Box>
            <Flex>
              <Field name="target.address" label={InputLabels.address} component={Input} mr="xxs" width="70%" />
              <Field name="target.postalCode" label={InputLabels.postCode} component={Input} width="30%" />
            </Flex>
            <Flex justifyContent="space-between">
              <Field name="target.city" label={InputLabels.city} component={Input} mr="xxs" />
              <Field name="target.country" label={InputLabels.country} component={Input} />
            </Flex>
          </Box>
        )}
        <Field name="hasReturnAddress" label={InputLabels.returnAddress} mb="s" component={Checkbox} />
        {hasReturnAddress && (
          <Box>
            <Flex>
              <Field name="return.address" label={InputLabels.address} component={Input} mr="xxs" width="70%" />
              <Field name="return.postalCode" label={InputLabels.postCode} component={Input} width="30%" />
            </Flex>
            <Flex justifyContent="space-between">
              <Field name="return.city" label={InputLabels.city} component={Input} mr="xxs" />
              <Field name="return.country" label={InputLabels.country} component={Input} />
            </Flex>
          </Box>
        )}
        <Field name="showPrice" label={InputLabels.price} mb="s" component={Checkbox} />
        {showPrice && (
          <Box mb="s">
            <Field name="vat" label={InputLabels.vat} type="number" suffix="%" component={Input} />
            <Field name="isVatIncluded" label={InputLabels.vatIncluded} component={Checkbox} />
          </Box>
        )}
        <Field name="showInsuranceValue" label={InputLabels.insuranceValue} mb="s" component={Checkbox} />
        <Field name="showLocation" label={InputLabels.location} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} mb="s" component={Checkbox} />
        <Field name="showPageNumber" label={InputLabels.pageNumber} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const InsuranceListModal = ({ page, handleOnBackToTemplate }) => {
  const artworks = useSelector(selectSelectedArtworks);
  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "sortBy",
              field: "InputSelect",
              options: [
                { value: "artist.name", label: InputLabels.artist },
                { value: "archiveArtworkNumber", label: InputLabels.id },
                { value: "artworkValues.year", label: InputLabels.year },
                { value: "artworkValues.location", label: InputLabels.location },
                // { value: "artworkValues.acquisitionDate", label: InputLabels.acquisitionDate }, // does not exist in the artwork form atm
              ],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.sortBy,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "showLocation",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.location,
            },
            {
              fieldName: "showCurrentOwner",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.currentOwner,
            },
            {
              fieldName: "showPageNumber",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pageNumber,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  return (
    <GenericExportForm
      title={Headings.insuranceList}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.insuranceList,
        sortBy: "artist.name",
        showId: false,
        showCustomId: false,
        showLocation: false,
        showCurrentOwner: false,
        showPageNumber: false,
        showInsuranceValue: true,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
      warn={(values) => {
        const warnings = {};
        if (
          artworks.some(
            (artwork) =>
              !artwork.artworkValues.hasOwnProperty("insuranceValue") || !artwork.artworkValues.insuranceValue
          )
        ) {
          warnings._warning = Warnings.missingSelectedArtworkInsuranceValues;
        }
        return warnings;
      }}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field
        name="sortBy"
        label={InputLabels.sortBy}
        options={[
          { value: "artist.name", label: InputLabels.artist },
          { value: "archiveArtworkNumber", label: InputLabels.id },
          { value: "artworkValues.year", label: InputLabels.year },
          { value: "artworkValues.location", label: InputLabels.location },
          // { value: "artworkValues.acquisitionDate", label: InputLabels.acquisitionDate }, // does not exist in the artwork form atm
        ]}
        component={InputSelect}
        stateful
      />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />

        <Field name="showLocation" label={InputLabels.location} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} mb="s" component={Checkbox} />
        <Field name="showPageNumber" label={InputLabels.pageNumber} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const PriceListModal = ({ page, handleOnBackToTemplate }) => {
  const artworks = useSelector(selectSelectedArtworks);

  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "sortBy",
              field: "InputSelect",
              options: [
                { value: "artist.name", label: InputLabels.artist },
                { value: "archiveArtworkNumber", label: InputLabels.id },
                { value: "artworkValues.year", label: InputLabels.year },
              ],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.sortBy,
            },
          ],
        },
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "vat",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: true,
              isTextarea: false,
              label: InputLabels.vat,
            },
          ],
        },
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "isVatIncluded",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.vatIncluded,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "showInsuranceValue",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.insuranceValue,
            },
            {
              fieldName: "showPageNumber",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pageNumber,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  return (
    <GenericExportForm
      title={Headings.priceList}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.priceList,
        sortBy: "artist.name",
        showPrice: true,
        isVatIncluded: false,
        showId: false,
        showCustomId: false,
        showInsuranceValue: false,
        showPageNumber: false,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
      warn={(values) => {
        const warnings = {};
        if (artworks.some((artwork) => !artwork.artworkValues.hasOwnProperty("price"))) {
          warnings._warning = Warnings.missingSelectedArtworkPrices;
        }
        return warnings;
      }}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field
        name="sortBy"
        label={InputLabels.sortBy}
        options={[
          { value: "artist.name", label: InputLabels.artist },
          { value: "archiveArtworkNumber", label: InputLabels.id },
          { value: "artworkValues.year", label: InputLabels.year },
        ]}
        component={InputSelect}
        stateful
      />
      <Field name="vat" label={InputLabels.vat} type="number" suffix="%" component={Input} />
      <Field name="isVatIncluded" label={InputLabels.vatIncluded} mb="s" component={Checkbox} />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />
        <Field name="showInsuranceValue" label={InputLabels.insuranceValue} mb="s" component={Checkbox} />
        <Field name="showPageNumber" label={InputLabels.pageNumber} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const DataSheetModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "showPrice",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.price,
            },
            {
              fieldName: "vat",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: true,
              isTextarea: false,
              label: InputLabels.vat,
            },
            {
              fieldName: "isVatIncluded",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.vatIncluded,
            },
            {
              fieldName: "showInsuranceValue",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.insuranceValue,
            },
            {
              fieldName: "showLocation",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.location,
            },
            {
              fieldName: "showCurrentOwner",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.currentOwner,
            },
            {
              fieldName: "showWorkDescription",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.workDescription,
            },
            {
              fieldName: "showSecondaryImages",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.secondaryImages,
            },
            {
              fieldName: "showPageNumber",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pageNumber,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  const formValues = useSelector((state) => state.form?.exports?.values);
  let showPrice = false;
  if (formValues) {
    showPrice = formValues.showPrice;
  };

  return (
    <GenericExportForm
      title={Headings.dataSheet}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.dataSheet,
        sortBy: "artist.name",
        isVatIncluded: false,
        showId: false,
        showCustomId: false,
        showPrice: false,
        showInsuranceValue: false,
        showLocation: false,
        showCurrentOwner: false,
        showSecondaryImages: false,
        showPageNumber: false,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />
        <Field name="showPrice" label={InputLabels.price} mb="s" component={Checkbox} />
        {showPrice && (
          <Box mb="s">
            <Field name="vat" label={InputLabels.vat} type="number" suffix="%" component={Input} />
            <Field name="isVatIncluded" label={InputLabels.vatIncluded} component={Checkbox} />
          </Box>
        )}
        <Field name="showInsuranceValue" label={InputLabels.insuranceValue} mb="s" component={Checkbox} />
        <Field name="showLocation" label={InputLabels.location} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} mb="s" component={Checkbox} />
        <Field name="showWorkDescription" label={InputLabels.workDescription} mb="s" component={Checkbox} />
        <Field name="showSecondaryImages" label={InputLabels.secondaryImages} mb="s" component={Checkbox} />
        <Field name="showPageNumber" label={InputLabels.pageNumber} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const InvoiceModal = ({ page, handleOnBackToTemplate }) => {
  const artworks = useSelector(selectSelectedArtworks);

  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "invoiceAddress",
              field: "Input",
              options: [],
              isRequired: true,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.invoiceAddress,
            },
          ],
        },
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "discount",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: true,
              isTextarea: false,
              label: InputLabels.discount,
            },
          ],
        },
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "vat",
              field: "Input",
              options: [],
              isRequired: true,
              isNumber: true,
              isTextarea: false,
              label: InputLabels.vat,
            },
          ],
        },
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "isVatIncluded",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.vatIncluded,
            },
          ],
        },
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "bankDetails",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: true,
              label: InputLabels.bankDetails,
            },
          ],
        },
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "paymentConditions",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: true,
              label: InputLabels.paymentConditions,
            },
          ],
        },
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "remarks",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: true,
              label: InputLabels.remarks,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "showCurrentOwner",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.currentOwner,
            },
            {
              fieldName: "showInsuranceValue",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.insuranceValue,
            },
            {
              fieldName: "showPageNumber",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pageNumber,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  return (
    <GenericExportForm
      title={Headings.invoice}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.invoice,
        isVatIncluded: false,
        sortBy: "artist.name",
        showId: false,
        showCustomId: false,
        showPrice: true,
        showCurrentOwner: false,
        showInsuranceValue: false,
        showPageNumber: false,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
      warn={(values) => {
        const warnings = {};
        if (
          artworks.some((artwork) => !artwork.artworkValues.hasOwnProperty("price") || !artwork.artworkValues.price)
        ) {
          warnings._warning = Warnings.missingSelectedArtworkPrices;
        }
        return warnings;
      }}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} component={Input} required />
      <Field
        name="invoiceAddress"
        label={InputLabels.invoiceAddress}
        validate={FieldNotEmpty}
        component={Input}
        required
      />
      <Field name="discount" label={InputLabels.discount} type="number" suffix="%" component={Input} />
      <Field
        name="vat"
        label={InputLabels.vat}
        validate={FieldNotEmpty}
        type="number"
        suffix="%"
        component={Input}
        required
      />
      <Field name="isVatIncluded" label={InputLabels.vatIncluded} mb="s" component={Checkbox} />
      <Field name="bankDetails" label={InputLabels.bankDetails} type="textarea" minHeight="100px" component={Input} />
      <Field
        name="paymentConditions"
        label={InputLabels.paymentConditions}
        type="textarea"
        minHeight="100px"
        component={Input}
      />
      <Field name="remarks" label={InputLabels.remarks} type="textarea" minHeight="100px" component={Input} />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} mb="s" component={Checkbox} />
        <Field name="showInsuranceValue" label={InputLabels.insuranceValue} mb="s" component={Checkbox} />
        <Field name="showPageNumber" label={InputLabels.pageNumber} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const LoanAgreementModal = ({ page, handleOnBackToTemplate }) => {
  const user = useSelector(selectUser);
  const { email, name, address } = user;
  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "loanDuration",
              field: "Input",
              options: [],
              isRequired: true,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.loanDuration,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: Headings.borrower,
          fields: [
            {
              fieldName: "borrower.name",
              field: "Input",
              options: [],
              isRequired: true,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.borrowerName,
            },
            {
              fieldName: "borrower.address",
              field: "Input",
              options: [],
              isRequired: true,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.address,
            },
            {
              fieldName: "borrower.contactPerson",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.contactPerson,
            },
            {
              fieldName: "borrower.contactPersonEmail",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.email,
            },
            {
              fieldName: "borrower.contactPersonPhone",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.phone,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: Headings.lender,
          fields: [
            {
              fieldName: "lender.name",
              field: "Input",
              options: [],
              isRequired: true,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.lenderName,
            },
            {
              fieldName: "lender.address",
              field: "Input",
              options: [],
              isRequired: true,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.address,
            },
            {
              fieldName: "lender.contactPerson",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.contactPerson,
            },
            {
              fieldName: "lender.contactPersonEmail",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.email,
            },
            {
              fieldName: "lender.contactPersonPhone",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.phone,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: Headings.exhibition,
          fields: [
            {
              fieldName: "exhibitionValues.title",
              field: "Input",
              options: [],
              isRequired: true,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.title,
            },
            {
              fieldName: "exhibitionValues.duration",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.exhibitionDuration,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: Headings.credits,
          fields: [
            {
              fieldName: "loanCreditstextPrint",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.printCredits,
            },
            {
              fieldName: "loanCreditstextPhoto",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.photoCredits,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: Headings.transport,
          fields: [
            {
              fieldName: "pickupAddress",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pickupAddress,
            },
            {
              fieldName: "returnAddress",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.returnAddress,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: Headings.insurance,
          fields: [
            {
              fieldName: "insuranceAgreement",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.insuranceAgreement,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: Headings.consignmentConditions,
          fields: [
            {
              fieldName: "consignmentConditions",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: true,
              label: InputLabels.consignmentConditions,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "showCurrentOwner",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.currentOwner,
            },
            {
              fieldName: "showInsuranceValue",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.insuranceValue,
            },
            {
              fieldName: "showPageNumber",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pageNumber,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  return (
    <GenericExportForm
      title={Headings.loanAgreement}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.loanAgreement,
        lender: {
          name: name,
          address: address ? `${address.addressLine1}, ${address.city} ${address.country} ${address.postalCode}` : "",
          contactPersonEmail: email,
        },
        consignmentConditions: ExportStandardConditions.loanAgreement,
        showId: false,
        showCustomId: false,
        showCurrentOwner: false,
        showInsuranceValue: false,
        showPageNumber: false,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} component={Input} required />
      <Field name="loanDuration" label={InputLabels.loanDuration} validate={FieldNotEmpty} component={Input} required />
      <Section title={Headings.borrower} titleSize="body" pb="xxxs" mb="s">
        <Field
          name="borrower.name"
          label={InputLabels.borrowerName}
          validate={FieldNotEmpty}
          component={Input}
          required
        />
        <Field
          name="borrower.address"
          label={InputLabels.address}
          validate={FieldNotEmpty}
          component={Input}
          required
        />
        <Field name="borrower.contactPerson" label={InputLabels.contactPerson} component={Input} />
        <Field name="borrower.contactPersonEmail" label={InputLabels.email} component={Input} />
        <Field name="borrower.contactPersonPhone" label={InputLabels.phoneNumber} component={Input} />
      </Section>
      <Section title={Headings.lender} titleSize="body" pb="xxxs" mb="s">
        <Field name="lender.name" label={InputLabels.lenderName} validate={FieldNotEmpty} component={Input} required />
        <Field name="lender.address" label={InputLabels.address} validate={FieldNotEmpty} component={Input} required />
        <Field name="lender.contactPerson" label={InputLabels.contactPerson} component={Input} />
        <Field name="lender.contactPersonEmail" label={InputLabels.email} component={Input} />
        <Field name="lender.contactPersonPhone" label={InputLabels.phoneNumber} component={Input} />
      </Section>
      <Section title={Headings.exhibition} titleSize="body" pb="xxxs" mb="s">
        <Field
          name="exhibitionValues.title"
          label={InputLabels.title}
          validate={FieldNotEmpty}
          component={Input}
          required
        />
        <Field name="exhibitionValues.duration" label={InputLabels.exhibitionDuration} component={Input} />
      </Section>
      <Section title={Headings.credits} titleSize="body" pb="xxxs" mb="s">
        <Field name="loanCreditstextPrint" label={InputLabels.printCredits} component={Input} />
        <Field name="loanCreditstextPhoto" label={InputLabels.photoCredits} component={Input} />
      </Section>
      <Section title={Headings.transport} titleSize="body" pb="xxxs" mb="s">
        <Field name="pickupAddress" label={InputLabels.pickupAddress} component={Input} />
        <Field name="returnAddress" label={InputLabels.returnAddress} component={Input} />
      </Section>
      <Section title={Headings.insurance} titleSize="body" pb="xxxs" mb="s">
        <Field name="insuranceAgreement" label={InputLabels.insuranceAgreement} component={Input} />
      </Section>
      <Section title={Headings.consignmentConditions} titleSize="body" pb="xxxs" mb="none">
        <Field name="consignmentConditions" type="textarea" minHeight="100px" component={Input} />
      </Section>
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} mb="s" component={Checkbox} />
        <Field name="showInsuranceValue" label={InputLabels.insuranceValue} mb="s" component={Checkbox} />
        <Field name="showPageNumber" label={InputLabels.pageNumber} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const ConsignmentAgreementModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "artist",
              field: "Input",
              options: [],
              isRequired: true,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.artist,
            },
          ],
        },
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "percentageArtistSplit",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: true,
              isTextarea: false,
              label: InputLabels.percentageArtistSplit,
            },
          ],
        },
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "percentageGallerySplit",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: true,
              isTextarea: false,
              label: InputLabels.percentageGallerySplit,
            },
          ],
        },
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "consignmentConditions",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: true,
              label: InputLabels.consignmentConditions,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "showCurrentOwner",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.currentOwner,
            },
            {
              fieldName: "showInsuranceValue",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.insuranceValue,
            },
            {
              fieldName: "showPageNumber",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pageNumber,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  return (
    <GenericExportForm
      title={Headings.consignmentAgreement}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.consignmentAgreement,
        consignmentConditions: ExportStandardConditions.consignmentAgreement,
        showId: false,
        showPrice: true,
        showCustomId: false,
        showCurrentOwner: false,
        showInsuranceValue: false,
        showPageNumber: false,
      }}
      validate={({ percentageArtistSplit, percentageGallerySplit }) => {
        const errors = {};
        if (
          percentageArtistSplit !== undefined &&
          percentageGallerySplit !== undefined &&
          parseFloat(percentageArtistSplit) + parseFloat(percentageGallerySplit) !== 100
        ) {
          errors.percentageArtistSplit = InputErrors.percetageNot100;
        }
        return errors;
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field name="artist" label={InputLabels.artist} validate={FieldNotEmpty} component={Input} required />
      <Flex justifyContent="space-between">
        <Field
          name="percentageArtistSplit"
          label={InputLabels.percentageArtistSplit}
          type="number"
          suffix="%"
          validate={FieldNotEmpty}
          component={Input}
        />
        <Field
          name="percentageGallerySplit"
          label={InputLabels.percentageGallerySplit}
          type="number"
          suffix="%"
          validate={FieldNotEmpty}
          component={Input}
        />
      </Flex>

      <Field
        name="consignmentConditions"
        label={Headings.consignmentConditions}
        type="textarea"
        minHeight="100px"
        component={Input}
      />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} mb="s" component={Checkbox} />
        <Field name="showInsuranceValue" label={InputLabels.insuranceValue} mb="s" component={Checkbox} />
        <Field name="showPageNumber" label={InputLabels.pageNumber} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const CertificateModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "docText",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: true,
              label: InputLabels.text,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "showPrice",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.price,
            },
            {
              fieldName: "vat",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: true,
              isTextarea: false,
              label: InputLabels.vat,
            },
            {
              fieldName: "isVatIncluded",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.vatIncluded,
            },
            {
              fieldName: "showPageNumber",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pageNumber,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  const formValues = useSelector((state) => state.form?.exports?.values);
  let showPrice = false;
  if (formValues) {
    showPrice = formValues.showPrice;
  };

  return (
    <GenericExportForm
      title={Headings.certificate}
      page={page}
      width="390px"
      initialValues={{
        docText: Messages.certificateText,
        title: Messages.certificateTitle,
        isVatIncluded: false,
        showId: false,
        showCustomId: false,
        showPrice: false,
        showPageNumber: false,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field name="docText" label={InputLabels.text} type="textarea" minHeight="100px" component={Input} />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />
        <Field name="showPrice" label={InputLabels.price} mb="s" component={Checkbox} />
        {showPrice && (
          <Box mb="s">
            <Field name="vat" label={InputLabels.vat} type="number" suffix="%" component={Input} />
            <Field name="isVatIncluded" label={InputLabels.vatIncluded} component={Checkbox} />
          </Box>
        )}
        <Field name="showPageNumber" label={InputLabels.pageNumber} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const ConditionModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "ConditionDetails",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: true,
              label: InputLabels.originAddress,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "showCurrentOwner",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.currentOwner,
            },
            {
              fieldName: "showInsuranceValue",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.insuranceValue,
            },
            {
              fieldName: "showPageNumber",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pageNumber,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  return (
    <GenericExportForm
      title={Headings.condition}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.conditionReport,
        sortBy: "artist.name",
        showId: false,
        showCustomId: false,
        showCurrentOwner: false,
        showInsuranceValue: false,
        showPageNumber: false,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />

      <Field
        name="ConditionDetails"
        label={Headings.conditionDetails}
        type="textarea"
        minHeight="100px"
        component={Input}
      />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} mb="s" component={Checkbox} />
        <Field name="showInsuranceValue" label={InputLabels.insuranceValue} mb="s" component={Checkbox} />
        <Field name="showPageNumber" label={InputLabels.pageNumber} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const InstallationManualModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "showPageNumber",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.pageNumber,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  return (
    <GenericExportForm
      title={Headings.installationManual}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.installationManual,
        showId: false,
        showCustomId: false,
        showPageNumber: false,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} component={Input} required />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />
        <Field name="showPageNumber" label={InputLabels.pageNumber} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const HandoffDocumentModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "originAddress",
              field: "Input",
              options: [],
              isRequired: true,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.originAddress,
            },
          ],
        },
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "targetAddress",
              field: "Input",
              options: [],
              isRequired: true,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.targetAddress,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "showPrice",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.price,
            },
            {
              fieldName: "vat",
              field: "Input",
              options: [],
              isRequired: false,
              isNumber: true,
              isTextarea: false,
              label: InputLabels.vat,
            },
            {
              fieldName: "isVatIncluded",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.vatIncluded,
            },
            {
              fieldName: "showInsuranceValue",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.insuranceValue,
            },
            {
              fieldName: "showCurrentOwner",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.currentOwner,
            },
            {
              fieldName: "showPageNumber",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.currentOwner,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  const formValues = useSelector((state) => state.form?.exports?.values);
  let showPrice = false;
  if (formValues) {
    showPrice = formValues.showPrice;
  };

  return (
    <GenericExportForm
      title={Headings.handoffDocument}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.handoff,
        isVatIncluded: false,
        showId: false,
        showCustomId: false,
        showPrice: false,
        showInsuranceValue: false,
        showCurrentOwner: false,
        showPageNumber: false,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field
        name="originAddress"
        label={InputLabels.originAddress}
        validate={FieldNotEmpty}
        component={Input}
        required
      />
      <Field
        name="targetAddress"
        label={InputLabels.targetAddress}
        validate={FieldNotEmpty}
        component={Input}
        required
      />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />
        <Field name="showPrice" label={InputLabels.price} mb="s" component={Checkbox} />
        {showPrice && (
          <Box mb="s">
            <Field name="vat" label={InputLabels.vat} type="number" suffix="%" component={Input} />
            <Field name="isVatIncluded" label={InputLabels.vatIncluded} component={Checkbox} />
          </Box>
        )}
        <Field name="showInsuranceValue" label={InputLabels.insuranceValue} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} mb="s" component={Checkbox} />
        <Field name="showPageNumber" label={InputLabels.pageNumber} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const StorageLabelsModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "labelSize",
              field: "RadioSelect",
              options: [
                {
                  value: "regular",
                  label: InputLabels.regular,
                  description: "99.06 x 67.73 mm\n8 / A4 sheet\nAvery 6426",
                },
                {
                  value: "thin",
                  label: InputLabels.small,
                  description: "6 / A4 sheet\nAvery 6426",
                },
              ],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.labelFormat,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "printGuidelines",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.guidelines,
            },
            {
              fieldName: "showCurrentOwner",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.currentOwner,
            },
            {
              fieldName: "showInsuranceValue",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.insuranceValue,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  return (
    <GenericExportForm
      title={Headings.storageLabels}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.storageLabels,
        labelSize: "regular",
        showId: false,
        showCustomId: false,
        printGuidelines: false,
        showCurrentOwner: false,
        showInsuranceValue: false,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field
        name="labelSize"
        label={InputLabels.labelFormat}
        options={{
          regular: { label: InputLabels.regular, description: "99.06 x 67.73 mm\n8 / A4 sheet\nAvery 6426" },
          thin: { label: InputLabels.small, description: "6 / A4 sheet\nAvery 6426" },
        }}
        component={RadioSelect}
      />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s" mt="none">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />
        <Field name="printGuidelines" label={InputLabels.guidelines} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} mb="s" component={Checkbox} />
        <Field name="showInsuranceValue" label={InputLabels.insuranceValue} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const WallLabelsModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    const fieldTypeInjectedValues = {
      fieldTypes: [
        {
          isSection: false,
          sectionLabel: "",
          fields: [
            {
              fieldName: "labelSize",
              field: "RadioSelect",
              options: [
                {
                  value: "regular",
                  label: InputLabels.regular,
                  description: "99.06 x 67.73 mm\n8 / A4 sheet",
                },
                {
                  value: "small",
                  label: InputLabels.small,
                  description: "6 / A4 sheet",
                },
              ],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.labelFormat,
            },
          ],
        },
        {
          isSection: true,
          sectionLabel: UiLabels.include,
          fields: [
            {
              fieldName: "showId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.id,
            },
            {
              fieldName: "showCustomId",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.customId,
            },
            {
              fieldName: "printGuidelines",
              field: "Checkbox",
              options: [],
              isRequired: false,
              isNumber: false,
              isTextarea: false,
              label: InputLabels.guidelines,
            },
          ],
        },
      ],
      ...values,
    };
    return dispatch(createExport(page, fieldTypeInjectedValues, true, true));
  };

  return (
    <GenericExportForm
      title={Headings.wallLabels}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.wallLabels,
        labelSize: "regular",
        showId: false,
        showCustomId: false,
        printGuidelines: false,
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field
        name="labelSize"
        label={InputLabels.labelFormat}
        options={{
          regular: { label: InputLabels.regular, description: "105 x 148 mm\n4 / A4 sheet\nAvery 6426" },
          small: { label: InputLabels.small, description: "105 x 74 mm\n8 / A4 sheet\nAvery 6426" },
        }}
        component={RadioSelect}
      />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s" mt="none">
        <Field name="showId" label={InputLabels.id} mb="s" component={Checkbox} />
        <Field name="showCustomId" label={InputLabels.customId} mb="s" component={Checkbox} />
        <Field name="printGuidelines" label={InputLabels.guidelines} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const CsvModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    return dispatch(createCsv(values));
  };

  return (
    <GenericExportForm
      title={Headings.newCsv}
      page={page}
      width="390px"
      initialValues={{ showPrice: false, showLocation: false, showCurrentOwner: false }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} component={Input} />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s" mt="none">
        <Field name="showPrice" label={InputLabels.price} mb="s" component={Checkbox} />
        <Field name="showLocation" label={InputLabels.location} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

export { AdvancedExportsModal };
