import { Box, Text } from "../fundamentals";
import { Headings } from "../../localisation";

export const ActivityArtworkAcquisitionsScreen = ({headerTextStyle}) => {
    return (
        <Box>
            <Text style={{ ...headerTextStyle, fontSize: "26px"}} mb="xxxl">{Headings.artworkAcquisitions}</Text>
            <Text>Coming soon</Text>
        </Box>
    )
};