import { selectViewingRoomById } from "../../store/selectors";
import { Box, Flex, Text, ImageWithCache } from "../fundamentals";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import css from "@styled-system/css";
import { useIsScreenMobile } from "../hooks/useMediaQuery";

const ArtworkOverlay = styled(Box)`
  width: 100%;
  padding: 16px;
  background-color: white;
  transition: opacity 0.2s, background-color 0.3s, border-color 0.3s, color 0.3s, padding 0.4s ease-out;
  opacity: 0;
`;

const StyledArtwork = styled(Flex)`
  flex-direction: column;
  flex-shrink: 0;
  white-space: nowrap;
  position: relative;
  border-radius: 2px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
  &:hover {
    cursor: pointer;
    ${ArtworkOverlay} {
      opacity: 0.9;
    }
  }
  ${css({
    padding: "s",
    bg: "white",
    marginRight: "m",
  })};
`;

const ViewingRoomArtworksGrid = () => {
  const location = useLocation();
  const history = useHistory();
  const isMobile = useIsScreenMobile();
  const params = new URLSearchParams(location.search);
  const viewingRoomId = params.get("viewingRoomId");
  const viewingRoom = useSelector((state) => selectViewingRoomById(state, viewingRoomId));

  const handleArtworkOnClick = (artworkId) => {
    history.push(`/viewingroom/details?viewingRoomId=${viewingRoomId}&artworkId=${artworkId}`, {
      source: history.location.pathname,
    });
  };

  return (
    <Flex flexWrap="wrap">
      {_.map(viewingRoom?.artworks, (artwork, index) => {
        const { images = [] } = artwork;
        const [firstImage = {}] = images;
        return (
          <Flex
            justifyContent="space-between"
            alignItems="start"
            mb={isMobile ? "xs" : "xxl"}
            key={artwork?._id?.id}
            onClick={() => handleArtworkOnClick(artwork?._id?.id)}
          >
            {/* Image */}
            {isMobile ? (
              <Box padding="s" backgroundColor="white" mr="xs" boxShadow="0px 8px 12px 0px rgba(0, 0, 0, 0.2)">
                <ImageWithCache
                  image={firstImage}
                  artworkId={artwork?._id?.id}
                  urlSuffix="/preview.jpg"
                  width="137px"
                  userSelect="none"
                  imageKey={artwork?._id?.id}
                />
                <Box mt="18px">
                  <Text
                    mb="xxxs"
                    fontSize="small"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxWidth="137px"
                  >
                    {artwork?._id?.artist?.name}
                  </Text>
                  <Flex fontSize="smaller" color="grey.80">
                    <Text
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      maxWidth={artwork?._id?.artworkValues?.year ? "102px" : "137px"}
                    >
                      {artwork?._id?.artworkValues?.title}
                    </Text>
                    {artwork?._id?.artworkValues?.year && <Text>{`, ${artwork?._id?.artworkValues?.year}`}</Text>}
                  </Flex>
                </Box>
              </Box>
            ) : (
              <StyledArtwork>
                {artwork?.images?.length ? (
                  <ImageWithCache
                    image={firstImage}
                    artworkId={artwork?._id?.id}
                    urlSuffix="/preview.jpg"
                    maxHeight="176px"
                    userSelect="none"
                    imageKey={artwork?._id?.id}
                  />
                ) : (
                  <Box pt="l" minWidth="130px" height="176px" mb="s">
                    <Text color="grey.60" fontSize="smaller">
                      No image available
                    </Text>
                  </Box>
                )}
                <Flex flexDirection="column" position="absolute" bottom="0" right="0" pointerEvents="none" width="100%">
                  <ArtworkOverlay>
                    <Text mb="xxxs" fontSize="small">
                      {artwork?._id?.artist?.name}
                    </Text>
                    <Flex fontSize="smaller" color="grey.80">
                      <Text
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxWidth={artwork?._id?.artworkValues?.year ? "10ch" : "15ch"}
                      >
                        {artwork?._id?.artworkValues?.title}
                      </Text>
                      {artwork?._id?.artworkValues?.year && <Text>{`, ${artwork?._id?.artworkValues?.year}`}</Text>}
                    </Flex>
                  </ArtworkOverlay>
                </Flex>
              </StyledArtwork>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

export { ViewingRoomArtworksGrid };
