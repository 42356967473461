import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Flex, Text, ImageWithCache, Icon } from "../fundamentals";
import { Button, Modal, Drawer, ImageSwiper } from "../elements";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectDiscoveryLoad } from "../../store/selectors";
import { openModal, createPaymentInviteRequest, updateDiscoveryStatus } from "../../store/actions";
import { ButtonLabels, UiLabels, InputValues, Info, Headings } from "../../localisation";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  ImageViewerModal,
  PaymentInviteBuyerGeneratedGeneralInfoModal,
  PaymentInviteBuyerGeneratedShippingInfoModal,
  PaymentInviteBuyerGeneratedPaymentModal,
  PaymentInviteStatusModal,
  PaymentInviteBuyerOfferModal,
  SuccessfulBuyerActionModal,
  PaymentInviteBuyerEnquiryModal,
  AlertModal,
} from "../modals";
import { DEFAULT_ARTWORK_STATUSES } from "../../config";
import { useIsScreenMobile, useIsScreenMobileSmall } from "../hooks/useMediaQuery";
import { PaymentInviteBuyerDrawer } from "../react-material/drawers";

const StyledWorkDescription = styled(Text)`
  display: -webkit-box;
  max-width: 415px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
  color: #6a6870;
  margin-bottom: 16px;
`;

// Issue: https://github.com/styled-components/styled-components/issues/1198
const StyledArrowContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  padding: 16px;
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  &:hover {
    background-color: lightGrey;
  }
`;

const buyingWithSimplifyBenefits = [
  {
    icon: "Lock",
    description: "Secure Payment",
  },
  {
    icon: "UserCheck",
    description: "Verified Sellers",
  },
  {
    icon: "Star",
    description: "Vetted Artists",
  },
];

const RenderDimensions = ({ artwork }) => {
  const { dimensions } = artwork?.artworkId?.artworkValues;
  return (
    <Box>
      {dimensions?.width && (
        <Flex color="grey.80">
          <Text mr="xxxs">{dimensions.width}</Text>
          <Text mr="xxxs">x</Text>
          <Text mr="xxxs">{dimensions.height}</Text>
          {dimensions?.depth && (
            <Flex>
              <Text mr="xxxs">x</Text>
              <Text mr="xxxs">{dimensions.height}</Text>
            </Flex>
          )}
          <Text>{dimensions.units}</Text>
        </Flex>
      )}
      {dimensions?.diameter && (
        <Flex color="grey.80">
          <Text mr="xxxs">{dimensions.diameter}</Text>
          <Text>{dimensions.units}</Text>
        </Flex>
      )}
      {dimensions?.variable && (
        <Flex color="grey.80">
          <Text>Variable dimensions</Text>
        </Flex>
      )}
    </Box>
  );
};

const DiscoveryArtworkDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useIsScreenMobile();
  const isSmallMobile = useIsScreenMobileSmall();
  const [buyButtonClicked, setBuyButtonClicked] = useState(false);
  const [offerButtonClicked, setOfferButtonClicked] = useState(false);
  const [enquiryButtonClicked, setEnquiryButtonClicked] = useState(false);
  const params = new URLSearchParams(location.search);
  const paymentInviteState = useSelector((state) => state.payment_invite);
  const discoveryState = useSelector((state) => state.discovery);
  const { artworks, sessionId, favourites } = discoveryState;
  const eventLoadTemp = useSelector((state) => selectDiscoveryLoad(state, sessionId));
  const artworkId = params.get("artworkId");
  const isFavouriteArtwork = location.pathname.includes("favourites");
  const favouriteArtwork = favourites?.filter((artwork) => artwork?.artworkId?.id === artworkId)[0];
  const discoveryArtwork = artworks?.filter((artwork) => artwork?._id?.id === artworkId)[0];
  const artwork = isFavouriteArtwork ? favouriteArtwork : discoveryArtwork;
  const eventLoad = { artworks: eventLoadTemp["artworks"] };
  const recommendationId = eventLoadTemp["recommendationId"];
  const images = artwork?.images || [];
  const firstImage = images[0];
  const [isWorkDescriptionOpen, setIsWorkDescriptionOpen] = useState(false);
  const [isShippingAndTaxesOpen, setIsShippingAndTaxesOpen] = useState(false);
  const [isBuyingWithSimplifyOpen, setIsBuyingWithSimplifyOpen] = useState(false);
  const [onImageHover, setOnImageHover] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (!Object.keys(eventLoad).length) {
      history.push(`/discovery/artworks`);
    }
  }, []);

  //Create Payment Invite when the Buy button is clicked
  const handleBuyClick = () => {
    setBuyButtonClicked(true);
    setOfferButtonClicked(false);
    setEnquiryButtonClicked(false);

    dispatch(updateDiscoveryStatus(sessionId, recommendationId, "DetailBuy", artworkId));
    return dispatch(
      createPaymentInviteRequest({
        artworkIds: [artworkId],
        isBuyerGenerated: true,
        isMarketPlace: true,
        isMobile,
      })
    );
  };

  //Create Payment Invite when the Offer button is clicked
  const handleOfferClick = () => {
    setBuyButtonClicked(false);
    setOfferButtonClicked(true);
    setEnquiryButtonClicked(false);

    dispatch(updateDiscoveryStatus(sessionId, recommendationId, "DetailOffer", artworkId));
    return dispatch(
      createPaymentInviteRequest({
        artworkIds: [artworkId],
        isBuyerGenerated: true,
        isMarketPlace: true,
        isOffer: true,
        isMobile,
      })
    );
  };

  //Create Payment Invite when the Enquiry button is clicked
  const handleEnquiryClick = () => {
    setBuyButtonClicked(false);
    setOfferButtonClicked(false);
    setEnquiryButtonClicked(true);

    dispatch(updateDiscoveryStatus(sessionId, recommendationId, "DetailEnquiry", artworkId));
    return dispatch(
      createPaymentInviteRequest({
        artworkIds: [artworkId],
        isBuyerGenerated: true,
        isMarketPlace: true,
        isEnquiry: true,
        isMobile,
      })
    );
  };

  return (
    <Box>
      {!artwork ? (
        <Text fontSize="heading3">Sorry, this artwork is no longer available on Discovery</Text>
      ) : (
        <Box>
          <Flex alignItems="start" flexDirection={isMobile ? "column" : "row"}>
            {/* Artwork Image Container */}
            {isMobile && images.length > 1 && <ImageSwiper images={images} />}
            {isMobile && images.length === 1 && (
              <Flex width="100%" justifyContent="center">
                <ImageWithCache
                  image={firstImage}
                  artworkId={artwork?.artworkId?.id}
                  urlSuffix="/medium.jpg"
                  maxWidth="100%"
                  userSelect="none"
                  imageKey={images[currentImageIndex].key}
                  cursor={onImageHover && "zoom-in"}
                />
              </Flex>
            )}
            {!isMobile && (
              <Flex
                onMouseEnter={() => setOnImageHover(true)}
                onMouseLeave={() => setOnImageHover(false)}
                mr="50px"
                alignItems="center"
                justifyContent="space-between"
                flex="1"
              >
                {/* Left Arrow Container */}
                <Box>
                  {(!onImageHover || currentImageIndex === 0) && <Box p="s" width="20px" height="20px" mr="xl"></Box>}
                  {onImageHover && currentImageIndex !== 0 && (
                    <StyledArrowContainer
                      mr="xl"
                      onClick={() =>
                        setCurrentImageIndex(currentImageIndex === 0 ? images?.length - 1 : currentImageIndex - 1)
                      }
                    >
                      <Icon icon="ChevronLeft" color="grey.80" size="16" cursor="pointer" />
                    </StyledArrowContainer>
                  )}
                </Box>
                {/* Image */}
                <ImageWithCache
                  image={currentImageIndex === 0 ? firstImage : images[currentImageIndex]}
                  artworkId={artwork?.artworkId?.id}
                  urlSuffix="/medium.jpg"
                  maxWidth="560px"
                  userSelect="none"
                  imageKey={images[currentImageIndex].key}
                  cursor={onImageHover && "zoom-in"}
                  onClick={() => dispatch(openModal("image_viewer"))}
                />
                {/* Right Arrow Container */}
                <Box>
                  {(!onImageHover || !(images?.length > currentImageIndex + 1)) && (
                    <Box p="s" width="20px" height="20px" ml="xl"></Box>
                  )}
                  {onImageHover && images?.length > currentImageIndex + 1 && (
                    <StyledArrowContainer
                      ml="xl"
                      onClick={() =>
                        setCurrentImageIndex(currentImageIndex === images?.length - 1 ? 0 : currentImageIndex + 1)
                      }
                    >
                      <Icon icon="ChevronRight" color="grey.80" size="16" cursor="pointer" />
                    </StyledArrowContainer>
                  )}
                </Box>
              </Flex>
            )}
            {/* Artwork Details */}
            <Box mt={isMobile && "20px"} width={isMobile ? "100%" : "415px"} minWidth={!isMobile && "415px"}>
              <Box mb="s">
                <Text fontFamily="bodyDmSans" fontSize="heading2">
                  {artwork?.artworkId?.artist.name}
                </Text>
                <Text fontFamily="bodyDmSans" fontSize="heading2" color="grey.80" fontStyle="italic">
                  {artwork?.artworkId?.artworkValues?.title}
                </Text>
                <Text fontFamily="bodyDmSans" fontSize="heading2" color="grey.80">
                  {artwork?.artworkId?.artworkValues?.year}
                </Text>
              </Box>
              <Box mb="s">
                <Text color="grey.80" mb="xxxs">
                  {artwork?.artworkId?.artworkValues?.technique}
                </Text>
                {artwork?.artworkId?.artworkValues.hasOwnProperty("dimensions") && (
                  <RenderDimensions artwork={artwork} />
                )}
              </Box>
              {!isFavouriteArtwork && (
                <Flex alignItems="baseline" mb="s">
                  <Text fontFamily="bodyDmSans" fontSize="heading2" mr="xxs">
                    {getSymbolFromCurrency(artwork?.artworkId?.artworkValues?.totalPublicPrice?.currency)}
                    {artwork?.artworkId?.artworkValues?.totalPublicPrice?.value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                  <Text>{`incl. VAT  ${artwork?.artworkId?.artworkValues?.vat}%`}</Text>
                </Flex>
              )}
              {!isFavouriteArtwork && (
                <Box>
                  {artwork?.artworkId?.status === "reserved" || artwork?.artworkId?.status === "sold" ? (
                    <Box
                      width="fit-content"
                      p="xxs"
                      borderRadius="4px"
                      backgroundColor={DEFAULT_ARTWORK_STATUSES[artwork?.artworkId?.status].color}
                    >
                      {InputValues[artwork?.artworkId?.status]}
                    </Box>
                  ) : (
                    <Box>
                      <Flex>
                        <Button
                          buttonStyle="primary"
                          size="large"
                          fontWeight="500"
                          fontSize="body"
                          mb="xxs"
                          onClick={handleBuyClick}
                          disabled={paymentInviteState?.isLoading}
                          icon={paymentInviteState?.isLoading && buyButtonClicked ? "Loader" : null}
                          iconSpinning={true}
                          flip={true}
                          iconOffset="xs"
                          flex="1"
                        >
                          {ButtonLabels.buy}
                        </Button>
                      </Flex>
                      <Flex flexDirection={isSmallMobile ? "column" : "row"} alignItems={isSmallMobile && "stretch"}> 
                        <Button
                          buttonStyle="secondary"
                          size="large"
                          fontWeight="500"
                          fontSize="body"
                          mr={!isSmallMobile && "xxs"}
                          mb={isSmallMobile && "xxs"}
                          width={isSmallMobile && "100%"}
                          onClick={handleOfferClick}
                          disabled={paymentInviteState?.isLoading}
                          icon={paymentInviteState?.isLoading && offerButtonClicked ? "Loader" : null}
                          iconSpinning={true}
                          flip={true}
                          iconOffset="xs"
                          flex="1"
                          padding={isMobile && "12px"}
                        >
                          {ButtonLabels.makeAnOffer}
                        </Button>
                        <Button
                          buttonStyle="secondary"
                          size="large"
                          fontWeight="500"
                          fontSize="body"
                          width={isSmallMobile && "100%"}
                          onClick={handleEnquiryClick}
                          disabled={paymentInviteState?.isLoading}
                          icon={paymentInviteState?.isLoading && enquiryButtonClicked ? "Loader" : null}
                          iconSpinning={true}
                          flip={true}
                          iconOffset="xs"
                          flex="1"
                          padding={isMobile && "12px"}
                        >
                          {ButtonLabels.sendAnEnquiry}
                        </Button>
                      </Flex>
                    </Box>
                  )}
                </Box>
              )}
              {/* Work description */}
              {artwork?.artworkId?.artworkValues?.workDescription && (
                <Box mt="36px" pt="s" pb="s" borderTop="1px solid #DBDADF" borderBottom="1px solid #DBDADF">
                  <Text mb="xs">{UiLabels.aboutTheWork}</Text>

                  {isWorkDescriptionOpen ? (
                    <Box width="100%">
                      <Text mb="s" color="grey.80" fontSize="small">
                        {artwork?.artworkId?.artworkValues?.workDescription}
                      </Text>
                      <Button
                        buttonStyle="text"
                        size="small"
                        icon="ChevronUp"
                        p="0"
                        onClick={() => setIsWorkDescriptionOpen(false)}
                      >
                        {ButtonLabels.showLess}
                      </Button>
                    </Box>
                  ) : (
                    <Box width="100%">
                      <StyledWorkDescription>
                        {" "}
                        {artwork?.artworkId?.artworkValues?.workDescription}
                      </StyledWorkDescription>
                      <Button
                        buttonStyle="text"
                        size="small"
                        icon="ChevronDown"
                        p="0"
                        onClick={() => setIsWorkDescriptionOpen(true)}
                      >
                        {ButtonLabels.showMore}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              {/* Shipping and taxes details */}
              {!isFavouriteArtwork && (
                <Box pt="s" pb="s" borderBottom="1px solid #DBDADF" maxWidth="100%">
                  <Flex
                    justifyContent="space-between"
                    cursor="pointer"
                    onClick={() => setIsShippingAndTaxesOpen((prevValue) => !prevValue)}
                  >
                    <Text>{UiLabels.shippingAndTaxes}</Text>
                    <Button
                      buttonStyle="text"
                      size="medium"
                      icon={isShippingAndTaxesOpen ? "ChevronUp" : "ChevronDown"}
                      iconCursor="pointer"
                      color="grey.80"
                      hoverColor="grey.80"
                      p="0"
                    ></Button>
                  </Flex>
                  {isShippingAndTaxesOpen && (
                    <Box mt="s" color="grey.80" fontSize="small">
                      <Text mb="xxs">
                        Price is inclusive of VAT, our processing fee and all other costs such as framing or packaging.
                        Duties may apply on delivery.
                      </Text>
                      <Text>
                        {`Ships from ${artwork?.artworkId?.artworkValues?.publicLocation?.address?.country}. You can choose to use our shipping partner Convelio, organise your own shipping or organise a personal handover. Convelio shipping costs are calculated at checkout.`}
                      </Text>
                    </Box>
                  )}
                </Box>
              )}
              {/* Buying with simplify.art details */}
              {!isFavouriteArtwork && (
                <Box
                  pt="s"
                  pb="s"
                  mb={isMobile && "s"}
                  borderBottom={isBuyingWithSimplifyOpen ? null : "1px solid #DBDADF"}
                >
                  <Flex
                    justifyContent="space-between"
                    cursor="pointer"
                    onClick={() => setIsBuyingWithSimplifyOpen((prevValue) => !prevValue)}
                  >
                    <Text>{UiLabels.buyingWithSimplify}</Text>
                    <Button
                      buttonStyle="text"
                      size="medium"
                      icon={isBuyingWithSimplifyOpen ? "ChevronUp" : "ChevronDown"}
                      iconCursor="pointer"
                      color="grey.80"
                      hoverColor="grey.80"
                      p="0"
                    ></Button>
                  </Flex>
                  {isBuyingWithSimplifyOpen && (
                    <Box mt="xs">
                      {buyingWithSimplifyBenefits.map((benefit) => {
                        return (
                          <Flex color="grey.80" fontSize="small" mb="xxs" alignItems="center">
                            <Icon icon={benefit.icon} size="18" mr="xxxs" />
                            <Text>{benefit.description}</Text>
                          </Flex>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Flex>
        </Box>
      )}
      {/* Modals for desktop screens */}
      <Modal id="image_viewer" closeButtonColor="white" closeButtonHoverColor="white" closeButtonBorderColor="white">
        <ImageViewerModal images={images} initialIndex={currentImageIndex} />
      </Modal>
      <Modal
        id="payment_invite_buyer"
        p="0px"
        closeButtonColor="white"
        closeButtonBorderColor="white"
        closeButtonHoverColor="white"
      >
        <PaymentInviteBuyerGeneratedGeneralInfoModal />
      </Modal>
      <Modal
        id="payment_invite_buyer.shipping"
        p="0px"
        closeButtonColor="white"
        closeButtonBorderColor="white"
        closeButtonHoverColor="white"
      >
        <PaymentInviteBuyerGeneratedShippingInfoModal />
      </Modal>
      <Modal
        id="payment_invite_buyer.pay"
        p="0px"
        closeButtonColor="white"
        closeButtonBorderColor="white"
        closeButtonHoverColor="white"
      >
        <PaymentInviteBuyerGeneratedPaymentModal />
      </Modal>
      <Modal id="payment_invite_buyer.status" p="0px">
        <PaymentInviteStatusModal />
      </Modal>
      <Modal id="payment_invite_buyer.payment.success">
        <AlertModal
          title={Headings.processedPayment}
          message="You will receive an email confirmation with your invoice shortly."
          acceptButtonText={ButtonLabels.backToDiscovery}
          onAccept={()=> history.push("/discovery")}
        />
      </Modal>
      <Modal
        id="payment_invite_buyer.offer"
        p="0px"
        closeButtonColor="white"
        closeButtonBorderColor="white"
        closeButtonHoverColor="white"
      >
        <PaymentInviteBuyerOfferModal />
      </Modal>
      <Modal id="payment_invite_buyer.offer_sent" p={isMobile ? "36px" : "l"}>
        <SuccessfulBuyerActionModal />
      </Modal>
      <Modal
        id="payment_invite_buyer.enquiry"
        p="0px"
        closeButtonColor="white"
        closeButtonBorderColor="white"
        closeButtonHoverColor="white"
      >
        <PaymentInviteBuyerEnquiryModal />
      </Modal>
      <Modal id="payment_invite_buyer.enquiry_sent" p={isMobile ? "36px" : "l"}>
        <SuccessfulBuyerActionModal />
      </Modal>
      {/* Drawers for mobile screens */}
      <Drawer id="payment_invite_buyer" padding="0">
        <PaymentInviteBuyerDrawer />
      </Drawer>
    </Box>
  );
};

export { DiscoveryArtworkDetails };
