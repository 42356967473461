import React, { useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import _ from "lodash";
import { Stack, Typography } from "@mui/material";
import { removeSelectedArtwork, openModal } from "../../../store/actions";
import { selectArtworksById, selectSelectedArtworkIds, selectUser } from "../../../store/selectors";
import { Box, Flex, ImageWithCache, Text, Icon } from "../../fundamentals";
import { Button, DropdownSelect } from "../../elements";
import {
  ButtonLabels,
  LinkLabels,
  InputLabels,
  UiLabels,
  DynamicLabels,
  InputErrors,
  DynamicInputErrors,
  Messages,
  Instructions,
} from "../../../localisation";
import { Input, RenderError, EditableLabelInput } from "../../react-material/Input";
import { Popover } from "../../react-material/Popover";
import { ReduxFormDateInput } from "../../react-material/DateInput/ReduxFormDateInput";
import { SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE, NON_SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE } from "../../../constants";
import getSymbolFromCurrency from "currency-symbol-map";
import { PAYMENT_INVITE_SUPPORTED_CURRENCIES } from "../../../config";

const fieldStyle = {
  flexGrow: 1,
  ".MuiFilledInput-root": {
    backgroundColor: "white",
    height: "50px",
    "&:hover": {
      backgroundColor: "#F3F4FF",
      borderBottom: "2px solid #C8CEFF",
      marginBottom: "-3px",
      height: "50px",
    },
    "&:before": {
      content: "none",
    },
    "&.Mui-focused": {
      borderBottom: "none",
      backgroundColor: "#F3F4FF",
      marginBottom: "-3px",
      height: "50px",
    },
  },
  "& .MuiFilledInput-input": {
    textAlign: "right",
    paddingRight: "0",
  },
  "& .Mui-error": {
    ".MuiFilledInput-input": {
      marginBottom: "3px",
    },
  },
};

const editableFieldStyle = {
  ".MuiFilledInput-root": {
    backgroundColor: "white",
    height: "50px",
    "&:hover": {
      borderBottom: "2px solid #C8CEFF",
      marginBottom: "-6px",
      height: "50px",
    },
    "&.Mui-focused": {
      borderBottom: "none",
      backgroundColor: "#F3F4FF",
      marginBottom: "-6px",
      height: "50px",
    },
  },
};

const dateInputStyle = {
  color: "#5E54FF",
};

// const multiSelectStyle = {
//   "& .MuiInputBase-input": {
//     minWidth: "223px",
//     height: "40px !important",
//   },
// };

const inputLabelStyle = {
  width: "151px",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#6A6870",
  fontFamily: "Inter",
};

const stackStyle = {
  borderBottom: "1px solid #C3C2C9",
  alignItems: "center",
  justifyContent: "space-between",
  height: "52px",
};

const totalAmountStyle = {
  color: "#5E54FF",
  fontWeight: "500",
};

//Calculate total value of all artworks minus their discounts if these exist
function calculateTotalValue(obj) {
  let totalValue = 0;
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const price = obj[key]?.price ? Number(obj[key]?.price?.value) : 0;
      const discounts = obj[key]?.discounts
        ? obj[key].discounts.reduce((total, discount) => {
            if (discount.value) {
              return total + (Number(discount.value) / 100) * price;
            } else {
              return total;
            }
          }, 0)
        : 0;
      totalValue += price - discounts;
    }
  }
  return totalValue;
}

const renderDiscountItems = ({ fields, artwork, artworks, currencySymbol }) => {
  const renderDiscountAmount = (artworkId, discountIndex) => {
    if (artworks) {
      const artworkPrice = Number(artworks[artworkId]?.price?.value);
      const discount = artworks[artworkId]?.discounts[discountIndex].value;
      if (artworkPrice && discount) {
        const discountAmount = Math.round(artworkPrice * (Number(discount) / 100) * 100) / 100;
        return discountAmount;
      } else {
        return 0;
      }
    }
  };

  const renderArtworkTotal = (id) => {
    if (artworks) {
      const artworkPrice = Number(artworks[id]?.price?.value);
      const artworkTotalDiscount = artworks[id]?.discounts?.reduce((total, cur) => {
        return Number(total) + Number(cur.value);
      }, 0);

      if (artworkPrice && artworkTotalDiscount) {
        const total = artworkPrice - (artworkTotalDiscount * artworkPrice) / 100;
        return total;
      } else if (artworkPrice) {
        return artworkPrice;
      } else {
        return 0;
      }
    }
  };

  return (
    <>
      {fields.map((discountItem, index) => (
        <Stack direction="row" spacing={1} key={discountItem[artwork.id]}>
          <Box borderBottom="1px solid #C3C2C9" width="50%">
            <Stack direction="row" alignItems="center" height="55px" justifyContent="space-between">
              <Field
                name={`${discountItem}.name`}
                component={EditableLabelInput}
                placeholder="Discount"
                sx={editableFieldStyle}
              />
              <Field
                name={`${discountItem}.value`}
                component={Input}
                sx={fieldStyle}
                typeNumber
                formName="payment_invite.create"
              />
              <Typography sx={{ textAlign: "right" }}>%</Typography>
            </Stack>
          </Box>

          <Box borderBottom="1px solid #C3C2C9" width="50%">
            <Stack direction="row" alignItems="center" height="55px" justifyContent="end">
              <Text>{`${renderDiscountAmount(artwork.id, index)}`}</Text>
            </Stack>
          </Box>
        </Stack>
      ))}
      <Flex justifyContent="space-between" mt="18px">
        <Button
          type="button"
          buttonStyle="text"
          fontFamily="Inter"
          icon="Plus"
          iconSize="16"
          onClick={() => fields.push({})}
        >
          {ButtonLabels.addDiscount}
        </Button>
        <Text style={totalAmountStyle} fontSize="small">
          {UiLabels.artworkTotal}
          {` ${currencySymbol}${renderArtworkTotal(artwork.id)}`}
        </Text>
      </Flex>
    </>
  );
};

const renderCostItems = ({
  fields,
  artworks,
  transportCosts,
  packagingCosts,
  otherCosts,
  setSubtotal,
  currencySymbol,
}) => {
  const calculateSubtotal = () => {
    if (artworks) {
      const artworkTotals = calculateTotalValue(artworks);
      const transportCostsValue = Number(transportCosts?.value) || 0;
      const packagingCostsValue = Number(packagingCosts?.value) || 0;
      const allOtherCosts = otherCosts
        ? otherCosts.reduce((sum, obj) => {
            return sum + Number(obj.value);
          }, 0)
        : 0;
      const totalCosts = allOtherCosts
        ? transportCostsValue + packagingCostsValue + allOtherCosts
        : transportCostsValue + packagingCostsValue;
      const subtotal = artworkTotals + totalCosts;
      setSubtotal(subtotal);
      return subtotal;
    } else {
      return 0;
    }
  };

  return (
    <>
      {fields.map((costItem, index) => (
        <Stack direction="row" spacing={1} key={costItem}>
          <Box borderBottom="1px solid #C3C2C9" width="100%">
            <Stack direction="row" alignItems="center" height="55px" justifyContent="space-between">
              <Field name={`${costItem}.name`} component={EditableLabelInput} placeholder="Cost" />
              <Field
                name={`${costItem}.value`}
                component={Input}
                sx={fieldStyle}
                typeNumber
                formName="payment_invite.create"
              />
            </Stack>
          </Box>
        </Stack>
      ))}
      <Flex justifyContent="space-between" mt="18px">
        <Button
          type="button"
          buttonStyle="text"
          fontFamily="Inter"
          icon="Plus"
          iconSize="16"
          onClick={() => fields.push({})}
        >
          {ButtonLabels.addCost}
        </Button>
        <Text style={totalAmountStyle} fontSize="small">
          {`${UiLabels.subTotal}: ${currencySymbol}${calculateSubtotal()}`}
        </Text>
      </Flex>
    </>
  );
};

const Form = ({
  handleSubmit,
  goToPage,
  artworks,
  transportCosts,
  packagingCosts,
  otherCosts,
  currency,
  inviteValidity,
}) => {
  // const { id, artist, images, artworkValues = {} } = artwork;
  // const [image = {}] = _.sortBy(images, "sortIndex");
  const user = useSelector(selectUser);
  const selectedArtworkIds = useSelector(selectSelectedArtworkIds);
  const selectedArtworks = useSelector((state) => selectArtworksById(state, selectedArtworkIds));
  const [subtotal, setSubtotal] = useState(0);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const currencySymbol = getSymbolFromCurrency(currency);
  const dispatch = useDispatch();

  const calculateProcessingFee = (percentage) => {
    const amount = (subtotal * percentage) / 100;
    return amount;
  };

  const totalBuyerPays = user.account.accessLevel === 0 ? 
    subtotal + calculateProcessingFee(NON_SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE) :
    subtotal + calculateProcessingFee(SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE);

  const handleFeeInfoClick = (e) => {
    setPopoverAnchorEl(e.currentTarget);
  };

  const handleRemoveSelectedArtworkClick = (artwork) => {
    dispatch(removeSelectedArtwork(artwork.id));
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(() => goToPage("contact_details"))}>
        <Box overflow="auto" pt="30px" height="480px" pr="xxs">
          {_.map(selectedArtworks, (artwork, index) => {
            const { images = [] } = artwork;
            const [firstImage = {}] = images;
            return (
              <Flex justifyContent="space-between" alignItems="start" mb="xxl" key={artwork.id}>
                {/* Image */}
                <Box position="relative" width="196px" minHeight="155px" padding="s" mr="72px" boxShadow="small" borderRadius="2px">
                  {artwork.images.length ? (
                    <ImageWithCache
                      image={firstImage}
                      artworkId={artwork.id}
                      urlSuffix="/preview.jpg"
                      width="100%"
                      userSelect="none"
                      mb="s"
                      imageKey={artwork.id}
                    />
                  ) : (
                    <Box pt="l" height="100px">
                      <Text color="grey.60" fontSize="smaller">
                        No image available
                      </Text>
                    </Box>
                  )}
                  <Text mb="xxxs">{artwork.artist}</Text>
                  <Flex>
                    <Text
                      fontSize="small"
                      color="grey.80"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      maxWidth={artwork.artworkValues.year ? "13ch" : "19ch"}
                    >
                      {artwork.artworkValues.title}
                    </Text>
                    {artwork.artworkValues.year && (
                      <Text fontSize="small" color="grey.80">
                        {`, ${artwork.artworkValues.year}`}
                      </Text>
                    )}
                  </Flex>
                  {selectedArtworks.length > 1 && (
                    <Flex flexDirection="column" position="absolute" top="xxs" right="xxs" pointerEvents="none">
                      <Button
                        buttonStyle="primary"
                        size="small"
                        icon="X"
                        iconStrokeWidth="3px"
                        onClick={() => handleRemoveSelectedArtworkClick(artwork)}
                        mb="xxs"
                      ></Button>
                    </Flex>
                  )}
                </Box>
                {/* Price Details */}
                <Box width="560px">
                  <Box border="1px solid #C3C2C9" borderRadius="7px" padding="l">
                    <Stack direction="column">
                      <Box>
                        <Stack sx={stackStyle} direction="row">
                          <Typography sx={inputLabelStyle}>{InputLabels.price}</Typography>
                          <Field
                            name={`${artwork.id}.price.value`}
                            component={Input}
                            sx={fieldStyle}
                            typeNumber
                            hideError={true}
                            formName="payment_invite.create"
                          />
                        </Stack>
                        <Field name={`${artwork.id}.price.value`} component={RenderError} />
                      </Box>
                      <FieldArray
                        name={`${artwork.id}.discounts`}
                        component={renderDiscountItems}
                        artwork={artwork}
                        artworks={artworks}
                        currencySymbol={currencySymbol}
                      />
                    </Stack>
                  </Box>
                  {/* <Flex alignItems="center" color="grey.80" overflow="hidden" height={24} mb="xxs">
                <Icon icon="Repeat" size="14" mr="xxxs" />
                <Text fontSize="smaller" lineHeight="button">
                  {Info.editingPriceInfo}
                </Text>
              </Flex> */}
                </Box>
              </Flex>
            );
          })}
          <Flex justifyContent="end">
            <Box width="560px">
              {/* Costs */}
              <Box border="1px solid #C3C2C9" borderRadius="7px" padding="32px" mb="15px">
                <Stack sx={stackStyle} direction="row">
                  <Typography sx={inputLabelStyle}>{InputLabels.transportCosts}</Typography>
                  <Field
                    name="transportCosts.value"
                    component={Input}
                    sx={fieldStyle}
                    typeNumber
                    formName="payment_invite.create"
                  />
                </Stack>
                <Stack sx={stackStyle} direction="row">
                  <Typography sx={inputLabelStyle}>{InputLabels.packagingCosts}</Typography>
                  <Field
                    name="packagingCosts.value"
                    component={Input}
                    sx={fieldStyle}
                    typeNumber
                    formName="payment_invite.create"
                  />
                </Stack>
                <FieldArray
                  name="otherCosts"
                  component={renderCostItems}
                  artworks={artworks}
                  transportCosts={transportCosts}
                  packagingCosts={packagingCosts}
                  otherCosts={otherCosts}
                  setSubtotal={setSubtotal}
                  currencySymbol={currencySymbol}
                />
              </Box>
              {/* Totals */}
              <Box border="1px solid #C3C2C9" borderRadius="7px" padding="32px" mb="15px">
                <Flex justifyContent="space-between">
                  <Text fontSize="small">{UiLabels.totalBuyerPays}</Text>
                  <Text style={totalAmountStyle} fontSize="small">
                    {`${currencySymbol}${totalBuyerPays}`}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" mt="xs">
                  <Flex onClick={handleFeeInfoClick} cursor="pointer">
                    <Text fontSize="small">
                      {user.account.accessLevel === 0 ? DynamicLabels.paymentInviteFee(NON_SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE) : DynamicLabels.paymentInviteFee(SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE)}
                    </Text>
                    <Icon icon="HelpCircle" size="14" ml="xxxs" color="blue.60" cursor="pointer"></Icon>
                  </Flex>
                  <Text style={totalAmountStyle} fontSize="small">
                    {user.account.accessLevel === 0 ?
                    `-${currencySymbol}${calculateProcessingFee(NON_SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE)}` :
                    `-${currencySymbol}${calculateProcessingFee(SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE)}`}
                  </Text>
                </Flex>
                {user.account.accessLevel === 0 && (<Flex
                  fontSize="smaller"
                  color="grey.80"
                  mt="xxxs"
                  alignItems="center"
                >
                <Text>Save money again and again by</Text>
                <Button buttonStyle="link" fontFamily="Inter" color="grey.80" fontSize="smaller" onClick={()=> dispatch(openModal("upgrade"))}>upgrading to All-Access to drop the fee to 7%.</Button>

                </Flex>)}
                <Flex justifyContent="space-between" borderTop="1px solid #C3C2C9" pt="xs" mt="xs">
                  <Text fontSize="medium" fontWeight="500">
                    {UiLabels.totalYouReceive}
                  </Text>
                  <Text style={{ ...totalAmountStyle, color: subtotal < 0 ? "red" : "#5E54FF" }} fontSize="medium">
                    {currencySymbol}
                    {subtotal}
                  </Text>
                </Flex>
                {subtotal < 0 && (
                  <Text color="red" fontSize="smaller" mt="xxxs">
                    Total should be greater than 0
                  </Text>
                )}
              </Box>
              <Popover
                popoverAnchorEl={popoverAnchorEl}
                setPopoverAnchorEl={setPopoverAnchorEl}
                text={user.account.accessLevel === 0 ? Messages.paymentInviteMessage(NON_SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE) : Messages.paymentInviteMessage(SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE)}
                linkText={LinkLabels.learnMore}
                link="https://www.simplify.art/payment-invites"
                icon="HelpCircle"
              />
              <Box border="1px solid #C3C2C9" borderRadius="7px" padding="32px" mb="15px">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text fontSize="small">{UiLabels.inviteValidUntil}</Text>
                  <Field
                    name="inviteValidity"
                    component={ReduxFormDateInput}
                    hideError={true}
                    outline={true}
                    disablePast={true}
                    customRootStyles={dateInputStyle}
                    customInputStyles={{ maxWidth: "110px", fontSize: "14px" }}
                  />
                </Flex>
              </Box>
            </Box>
          </Flex>
        </Box>
        {/* Footer */}
        <Flex paddingTop="m" justifyContent="space-between" boxShadow="0px -15px 10px -15px rgba(0, 0, 0, 0.16)">
          <Flex alignItems="start">
            <Button buttonStyle="secondary" size="small" icon="Plus" iconSize="16" disabled={true}>
              {ButtonLabels.addArtworks}
            </Button>
            <Field
              name="currency"
              options={PAYMENT_INVITE_SUPPORTED_CURRENCIES}
              buttonProps={{
                buttonStyle: "secondary",
                size: "small",
                height: "100%",
                borderRadius: "20px",
                hoverColor: "grey.90",
                ml: "xs",
                icon: "DollarSign",
                iconSize: "16",
                flip: false,
              }}
              placeholder={ButtonLabels.changeCurrency}
              immutablePlaceholder
              labelSize="small"
              preventDefault
              dropdownPositionTop="-150px"
              component={DropdownSelect}
            />
          </Flex>
          <Flex flexDirection="column">
            <Button
              type="submit"
              buttonStyle="primary"
              size="small"
              fontWeight="500"
              disabled={!inviteValidity || Object.keys(artworks).length < selectedArtworks.length || subtotal < 0}
              alignSelf="end"
            >
              {ButtonLabels.next}
            </Button>
            {!inviteValidity && (
              <Text fontSize="smaller" color="grey.80" mt="12px">
                {Instructions.setExpiryDate}
              </Text>
            )}
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};

const validate = (values) => {
  const errors = {};
  const { transportCosts, packagingCosts, otherCosts, inviteValidity, currency, contacts, message, ...artworks } =
    values;
  const currencySymbol = getSymbolFromCurrency(currency);

  if (artworks) {
    for (let [artworkKey, artworkValue] of Object.entries(artworks)) {
      if (!artworkValue?.price?.value) {
        errors[artworkKey] = {
          price: {
            value: InputErrors.noPrice,
          },
        };
      } else if (Number(artworkValue.price.value) < 200) {
        errors[artworkKey] = {
          price: {
            value: DynamicInputErrors.minimumArtworkPrice(currencySymbol),
          },
        };
      }
    }
  }

  return errors;
};

let SaleDetailsForm = reduxForm({
  form: "payment_invite.create",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const { transportCosts, packagingCosts, otherCosts, inviteValidity, currency, contacts, message, ...artworks } = state
    .form.payment_invite?.create
    ? state.form.payment_invite.create.values
    : "";

  return { artworks, transportCosts, packagingCosts, otherCosts, inviteValidity, currency };
}

SaleDetailsForm = connect(mapStateToProps, null)(SaleDetailsForm);

export { SaleDetailsForm };
