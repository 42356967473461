import React, { useRef, useEffect } from "react";
import { Box, Flex, Text, Image, Link } from "../fundamentals";
import { Button } from "../elements";
import { ButtonLabels, Headings } from "../../localisation";
import { useDispatch, useSelector } from "react-redux";
import { StickyObservable, WithItemMeasure } from "../hocs";
import { CollectionHeader, ArtworkCategory } from "../modules";
import {
  selectSearch,
  selectSelectedCollection,
  selectSelectedCollectionArtworksCategories,
  selectUser,
} from "../../store/selectors";
import { useVirtual } from "react-virtual";
import { closeModal, updateSortByOption } from "../../store/actions";

const ArtworkCategoryWithItemMeasure = WithItemMeasure(ArtworkCategory);

const RowVirtualizerDynamic = ({ rows, listHeight, listWidth, scrollPosition }) => {
  const parentRef = useRef(null);

  const rowVirtualizer = useVirtual({
    size: rows.length,
    parentRef,
    overscan: 5,
  });

  return (
    <div
      ref={parentRef}
      className="List"
      style={{
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.totalSize}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          return (
            <ArtworkCategoryWithItemMeasure
              key={virtualRow.index}
              measure={virtualRow.measureRef}
              category={rows[virtualRow.index]}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                transform: `translateY(${virtualRow.start}px)`,
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const AdditionalArtworksModal = ({isList}) => {
  const categories = useSelector(selectSelectedCollectionArtworksCategories);
  const search = useSelector(selectSearch);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateSortByOption("*"))
  }, [])

  return (
    <Box width="90vw" minWidth="360px" backgroundColor="white">
      <Box padding="48px 48px 24px 48px">
        <Text fontSize="heading2" font="bodyDmSans" mb="xxs">
          {Headings.selectArtworksToAdd}
        </Text>
        <Text fontSize="smaller" color="grey.80">
          {isList ? "Click on the artworks and these will be included in your List" : "Click on the artworks and these will be included in your Viewing Room"}
        </Text>
      </Box>
      <Box height="60vh" overflow="auto" backgroundColor="grey.10">
        <StickyObservable options={{ threshold: 1 }}>
          <CollectionHeader hideButtons={true} initialValues={{ searchTerm: search.searchTerm, sortBy: "*" }} />
        </StickyObservable>

        {/*Artwork List*/}
        {categories.length !== 0 && <RowVirtualizerDynamic rows={categories} />}
      </Box>
      <Flex padding="24px 48px" justifyContent="end">
        <Button buttonStyle="primary" size="small" fontWeight="500" alignSelf="center" onClick={()=> dispatch(closeModal("additional_artworks"))}>
          {isList ? ButtonLabels.goBackToList : ButtonLabels.goBackToViewingRoom}
        </Button>
      </Flex>
    </Box>
  );
};

export { AdditionalArtworksModal };
