import React, { useState } from "react";
import _ from "lodash";
import { Link } from "react-scroll";

import { Flex, Box, Text } from "../fundamentals";
import { Button } from "../elements";

const ScrollSectionIndicator = ({ sections, ...props }) => {
  const [activeSection, setActiveSection] = useState();
  return (
    <Flex flexDirection="column" pl="xl" {...props}>
      {_.map(sections, (section) => (
        <Link
          key={section.name}
          to={section.name}
          onSetActive={(newActiveSection) => setActiveSection(newActiveSection)}
          offset={-310}
          spy={true}
          smooth={true}
        >
          <Box mb="m" cursor="pointer">
            <Text size="body" color={section.name === activeSection ? "blue.60" : "black"}>
              {section.title}
            </Text>
            {section.scrollSectionSubtitle && (
              <Text size="smaller" color={section.name === activeSection ? "blue.60" : "black"}>
                {section.scrollSectionSubtitle}
              </Text>
            )}
          </Box>
        </Link>
      ))}
    </Flex>
  );
};

export { ScrollSectionIndicator };
