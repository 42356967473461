import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { Icon, Box, Flex, Text } from "../../fundamentals";

const CustomCalendarIcon = () => {
  const [hover, setHover] = useState(false);
  return (
    <Box borderRadius="50%" backgroundColor={hover && "blue.10"} padding="xxs" alignItems="center">
      <Icon
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        icon="Calendar"
        size="16"
        color="blue.60"
        cursor="pointer"
      />
    </Box>
  );
};

const rootInputStyles = {
  "&:before": {
    content: "none",
  },

  "&:after": {
    content: "none",
  },

  "&.Mui-error": {
    background: "#FFDFDA",
  },
};

const inputStyles = {
  fontFamily: "Inter",
};

const popperStyle = {
  "& .MuiPickersYear-yearButton": {
    "&:hover": {
      backgroundColor: "#F3F4FF",
    },
    "&.Mui-selected": {
      backgroundColor: "#5E54FF !important",
    },
  },
  "& .MuiPickersDay-root": {
    "&:hover": {
      backgroundColor: "#F3F4FF",
    },
    "&.Mui-selected": {
      backgroundColor: "#5E54FF !important",
    },
  },
  "& .MuiPickersDay-today": {
    borderColor: "#5E54FF !important",
    backgroundColor: "white !important",
    "&:hover": {
      backgroundColor: "#F3F4FF !important",
    },
  },
  "& .MuiSvgIcon-root": {
    borderRadius: "50%",
    padding: "2px",
    "&:hover": {
      backgroundColor: "#F3F4FF",
    },
  },
};

const DateInput = ({
  input,
  meta: { touched, invalid, error, active },
  warning,
  label,
  disabled,
  outline,
  customRootStyles,
  customInputStyles,
  customPopperStyle,
  disableFuture,
  disablePast,
  clearable,
  hideError = false,
  minDate,
  maxDate,
  props,
}) => {

  const getHelperText = () => {
    if (invalid && error) {
      return (
        <Flex alignItems="center">
          <Icon size="12" icon="Info" color="red" marginRight="xxxs" />
          <Text fontSize="smaller" color="red">
            {error}
          </Text>
        </Flex>
      );
    }

    if (warning) {
      return (
        <Flex alignItems="center">
          <Icon size="12" icon="Info" color="red" marginRight="xxxs" />
          <Text fontSize="smaller">{warning}</Text>
        </Flex>
      );
    }
  };

  return (
    <>
      {outline ? (
        // With outline
        <Box border="1px solid #5E54FF" borderRadius="8px" padding="18px 14px">
          <DatePicker
            value={input.value || null}
            onChange={input.onChange}
            label={label}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            minDate={minDate}
            maxDate={maxDate}
            slotProps={{
              actionBar: {
                actions: clearable && ["clear"],
              },
              textField: {
                variant: "standard",
                error: invalid,
                helperText: !hideError && getHelperText(),
                InputProps: {
                  sx: {
                    ...rootInputStyles,
                    ...customRootStyles,
                  },
                },
                inputProps: {
                  sx: {
                    ...inputStyles,
                    ...customInputStyles,
                  },
                },
              },
              popper: {
                sx: {
                  ...popperStyle,
                  ...customPopperStyle,
                },
              },
            }}
            // components should be replaced by slots, but slots are not currently working (https://mui.com/x/react-date-pickers/custom-field/)
            components={{
              OpenPickerIcon: CustomCalendarIcon,
            }}
            //   slots={{
            //     inputAdornment: CustomCalendarIcon
            //   }}
            {...props}
          />
        </Box>
      ) : (
        // Without outline
        <DatePicker
          value={input.value || null}
          onChange={input.onChange}
          label={label}
          disabled={disabled}
          disableFuture={disableFuture}
          disablePast={disablePast}
          minDate={minDate}
          maxDate={maxDate}
          slotProps={{
            actionBar: {
              actions: clearable && ["clear"],
            },
            textField: {
              variant: "standard",
              error: invalid,
              helperText: !hideError && getHelperText(),
              InputProps: {
                sx: {
                  ...rootInputStyles,
                  ...customRootStyles,
                },
              },
              inputProps: {
                sx: {
                  ...inputStyles,
                  ...customInputStyles,
                },
              },
            },
            popper: {
              sx: {
                ...popperStyle,
                ...customPopperStyle,
              },
            },
          }}
          // components should be replaced by slots, but slots are not currently working (https://mui.com/x/react-date-pickers/custom-field/)
          components={{
            OpenPickerIcon: CustomCalendarIcon,
          }}
          //   slots={{
          //     inputAdornment: CustomCalendarIcon
          //   }}
          {...props}
        />
      )}
    </>
  );
};

export { DateInput as ReduxFormDateInput };
