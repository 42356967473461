import { useState, useEffect } from "react";
import { Flex, Box, Text, Icon } from "../../fundamentals";
import { FeaturesInfo, OverviewLabels } from "../../../localisation";
import { BILLING_PERIOD_MONTHLY, BILLING_PERIOD_YEARLY, ROLE_ARTIST } from "../../../constants";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateBillingPeriod } from "../../../store/actions";
import { selectUser } from "../../../store/selectors";
import { Tooltip } from "../Tooltip";

// Custom Checkbox Component
const CustomCheckBox = ({ selected }) => {
  return (
    <Box
      width="20px"
      height="20px"
      borderRadius="50%"
      border={`2px solid ${selected ? "#5E54FF" : "#C8CEFF"}`}
      backgroundColor={selected ? "blue.60" : "white"}
    >
      <Icon icon="Check" cursor="pointer" color={selected ? "white" : "transparent"} size="16" />
    </Box>
  );
};

//MUI Switch Component
const MuiSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  marginInline: 10,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#F4F4F5" : "#5E54FF",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#F4F4F5",
    borderRadius: 20 / 2,
  },
}));

const RenderPlanCard = ({ input, itemValue, plan, features, price_for_month, ...props }) => {
  const dispatch = useDispatch();
  const [savedAmount, setSavedAmount] = useState(0);
  const role = useSelector((state) => state.user.role);
  const selected = input.value === itemValue;
  const billingPeriod = useSelector((state) => state.user.billingPeriod);
  const user = useSelector(selectUser);
  const hasDiscount = user.account.hasOwnProperty("discount") && user.account?.discount?.value ? true : false;
  const discountPercentage = user.account?.discount?.value;
  const showYearlyOption = false; // Change value to true to enable yearly option
  const disablePaidOption = false; // Change the value to true to disable whole card

  const calculateSavedAmount = () => {
    let amount;
    if (role === ROLE_ARTIST) {
      if (hasDiscount) {
        amount = 43.2;
      } else {
        amount = 24;
      }
    } else if (hasDiscount) {
      amount = 108;
    } else {
      amount = 48;
    }
    setSavedAmount(amount);
  };

  useEffect(() => {
    calculateSavedAmount();
  }, []);

  //Update global state (billingPeriod) and input.value when switch is toggled
  const toggleYearlyPlan = (e) => {
    if (e.target.checked) {
      dispatch(updateBillingPeriod(BILLING_PERIOD_YEARLY));
      input.onChange(`${role}-${BILLING_PERIOD_YEARLY}`);
    } else {
      dispatch(updateBillingPeriod(BILLING_PERIOD_MONTHLY));
      input.onChange(`${role}-${BILLING_PERIOD_MONTHLY}`);
    }
  };

  const handleCardClick = () => {
    if (!plan.includes("free") && disablePaidOption) {
      return;
    } else {
      input.onChange(itemValue);
      // Initiating billingPeriod as monthly when all-access card is clicked and there is no discount
      if (!itemValue.includes("free") && !hasDiscount) {
        dispatch(updateBillingPeriod(BILLING_PERIOD_MONTHLY));
      }
    }
  };

  return (
    <Tooltip
      title={!plan.includes("free") && disablePaidOption ? "Currently not available" : null}
      followCursor={true}
      placement="top"
    >
      <Flex
        onClick={handleCardClick}
        {...props}
        cursor={!plan.includes("free") && disablePaidOption ? "not-allowed" : "pointer"}
        flexDirection="column"
        mr="m"
        p="28px"
        width="280px"
        bg="white"
        boxShadow="small"
        opacity={!plan.includes("free") && disablePaidOption ? "50%" : "100%"}
      >
        <Flex justifyContent="space-between" alignItems="center" mb="18px">
          <Text fontSize="heading3">
            {plan.includes("free") ? OverviewLabels.freePlan : OverviewLabels.allAccessPlan}
          </Text>
          <CustomCheckBox selected={selected} />
        </Flex>
        <Text fontSize="small" mb="36px">
          {plan.includes("free") ? FeaturesInfo.freePlanDesc : FeaturesInfo.allAccessPlanDesc}
        </Text>
        <Flex flexDirection="column" justifyContent="space-between" mb="m" pb="xs" borderBottom="1px solid #9AA1FF">
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              {hasDiscount && billingPeriod === BILLING_PERIOD_YEARLY && !plan.includes("free") ? (
                <Flex flexDirection="column" justifyContent="space-between" position="relative">
                  <Text
                    position="absolute"
                    bottom="36px"
                    left="48px"
                    fontSize="heading2"
                    fontFamily="bodyDmSans"
                    fontWeight="700"
                    color="blue.60"
                  >
                    {"\u20ac" +
                      `${Math.round((price_for_month - (price_for_month * discountPercentage) / 100) * 10) / 10}`}
                  </Text>
                  <Text
                    fontSize="heading1"
                    lineHeight="heading1"
                    fontFamily="bodyDmSans"
                    color="blue.40"
                    background="linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%)"
                  >
                    {"\u20ac" + `${price_for_month}`}
                  </Text>
                </Flex>
              ) : (
                <Text
                  fontSize="heading1"
                  lineHeight="heading1"
                  fontFamily="bodyDmSans"
                  fontWeight="700"
                  color="blue.60"
                >
                  {"\u20ac" + `${price_for_month}`}
                </Text>
              )}
            </Box>
            {!plan.includes("free") && showYearlyOption && (
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="smaller">{OverviewLabels.billingPeriodMonthly}</Text>
                <MuiSwitch onChange={toggleYearlyPlan} checked={billingPeriod === BILLING_PERIOD_YEARLY} />
                <Text fontSize="smaller" color={!billingPeriod === BILLING_PERIOD_YEARLY && "blue.60"}>
                  {OverviewLabels.billingPeriodYearly}
                </Text>
              </Flex>
            )}
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="smaller" color="blue.40">
              per month
            </Text>
            {!plan.includes("free") && showYearlyOption && (
              <Text fontSize="smaller" color="blue.60">
                {billingPeriod === BILLING_PERIOD_YEARLY ? `Saving €${savedAmount}/year` : `Save €${savedAmount}/year`}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Text fontSize="small" fontWeight="500" mb="xs">
            {OverviewLabels.features}
          </Text>
          {!plan.includes("free") && (
            <Text fontSize="smaller" mb="xs">
              All features of the free version plus:
            </Text>
          )}
          {features.map((feature) => {
            return (
              <Flex mb="xxs" alignItems="center">
                <Icon icon="Check" color="blue.60" mr="17px" />
                <Text fontSize="smaller">{feature}</Text>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Tooltip>
  );
};

export { RenderPlanCard };
