import produce from "immer";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import {
  DOWNLOAD_ARCHIVE,
  CREATE_ARTWORK_LOCALLY,
  CREATE_ARTWORK_SUCCESS,
  EDIT_ARTWORK_LOCALLY,
  EDIT_ARTWORK_SUCCESS,
  DELETE_ARTWORKS,
  DELETE_ARTWORK_SUCCESS,
  SAVE_SHARED_ARTWORK_LOCALLY,
  UPLOAD_ARTWORK_IMAGE_SUCCESS,
  DOWNLOAD_ARTISTS_SUCCESS,
  CREATE_ARTIST_LOCALLY,
  CREATE_ARTIST_SUCCESS,
  DELETE_ARTIST_SUCCESS,
  EDIT_ARTIST_LOCALLY,
  EDIT_ARTIST_SUCCESS,
  UPLOAD_ARTIST_IMAGE_SUCCESS,
  DELETE_ARTISTS,
  SYNC_ARCHIVE,
  SIGN_OUT_SUCCESS,
} from "../actions/types";
import { shareArtwork } from "../actions";

const ARCHIVE_INITIAL_STATE = {
  collections: { byId: {} },
  artworks: { byId: {} },
  artists: { byId: {} },
  shareRecords: { byId: {} }
};

const archiveReducer = (state = ARCHIVE_INITIAL_STATE, action) => {
  return produce(state, draftState => {
    switch (action.type) {
      case DOWNLOAD_ARCHIVE:
        return downloadArchive(draftState, action);
      case CREATE_ARTWORK_LOCALLY:
        return createArtworkLocally(draftState, action);
      case CREATE_ARTWORK_SUCCESS:
        return createArtworkSuccess(draftState, action);
      case EDIT_ARTWORK_LOCALLY:
        return editArtworkLocally(draftState, action);
      case EDIT_ARTWORK_SUCCESS:
        return editArtworkSuccess(draftState, action);
      case DELETE_ARTWORKS:
        return deleteArtworks(draftState, action);
      case DELETE_ARTWORK_SUCCESS:
        return deleteArtworkSuccess(draftState, action);
      case shareArtwork.SUCCESS:
        return shareArtworkSuccess(draftState, action);
      case SAVE_SHARED_ARTWORK_LOCALLY:
        return saveSharedArtworkLocally(draftState, action);
      case UPLOAD_ARTWORK_IMAGE_SUCCESS:
        return uploadArtworkImageSuccess(draftState, action);
      case DOWNLOAD_ARTISTS_SUCCESS:
        return downloadArtists(draftState, action);
      case CREATE_ARTIST_LOCALLY:
        return createArtistLocally(draftState, action);
        case CREATE_ARTIST_SUCCESS:
        return createArtistSuccess(draftState, action);
      case UPLOAD_ARTIST_IMAGE_SUCCESS:
        return uploadArtistImageSuccess(draftState, action);
      case EDIT_ARTIST_LOCALLY:
        return editArtistLocally(draftState, action);
      case EDIT_ARTIST_SUCCESS:
        return editArtistSuccess(draftState, action);
      case DELETE_ARTISTS:
        return deleteArtists(draftState, action);
      case DELETE_ARTIST_SUCCESS:
        return deleteArtistSuccess(draftState, action);
      case SYNC_ARCHIVE:
        return syncArchive(state, draftState, action);
      case SIGN_OUT_SUCCESS:
        return ARCHIVE_INITIAL_STATE;
      default:
        return state; 
    }
  });
};

const downloadArchive = (draftState, action) => {
  const { collections, artists, artworks, shareRecords } = action.payload;

  collections.forEach(collection => {
    draftState.collections.byId[collection.id] = collection;
  });

  artists.forEach(artist => {
    if (!artist.isDeleted) {
      draftState.artists.byId[artist.id] = {
        ...artist, 
        isFromServer: true,
        isModified: false,
        isActive: true
      };
    }
  });

  artworks.forEach(({ images, artist, ...artwork }) => {
    if (artwork.isDeleted && !artwork.activeShareSource) {
      return;
    }

    const imageList = images.map(image => {
      return { key: uuid(), ...image, isFromServer: true, isModified: false, isActive: true };
    });

    const documentList = artwork.artworkValues.documents.map(document => {
      const documentFiles = document.files.map(file => {
        return { key: uuid(), ...file, isFromServer: true, isModified: false, isActive: true }
      })
      return { ...document, files: documentFiles}
    })

    draftState.artworks.byId[artwork.id] = {
      ...artwork,
      artist: artist.name,
      images: _.sortBy(imageList, ["sortIndex"]),
      artworkValues: {
        ...artwork.artworkValues,
        documents: documentList,
      },
      isFromServer: true,
      isModified: false,
      isActive: true
    };
  });

  shareRecords.forEach(shareRecord => {
    draftState.shareRecords.byId[shareRecord.id] = shareRecord;
  });
};

const createArtworkLocally = (draftState, action) => {
  const {
    tempArtworkId,
    collection: { id },
    ...restOfArtwork
  } = action.payload;
  draftState.artworks.byId[tempArtworkId] = {
    id: tempArtworkId,
    artworkCollection: id,
    ...restOfArtwork,
    isFromServer: false,
    isModified: false,
    isActive: true
  };
};

const createArtworkSuccess = (draftState, action) => {
  const { artworkId, tempArtworkId, collectionId, artworkFamilyId, archiveArtworkNumber, artistId } = action.payload;

  draftState.artworks.byId[artworkId] = { ...draftState.artworks.byId[tempArtworkId] };
  draftState.artworks.byId[artworkId].id = artworkId;
  draftState.artworks.byId[artworkId].artworkFamily = artworkFamilyId;
  draftState.artworks.byId[artworkId].archiveArtworkNumber = archiveArtworkNumber;
  draftState.artworks.byId[artworkId].artistId = artistId;
  draftState.artworks.byId[artworkId].artworkCollection = collectionId;
  draftState.artworks.byId[artworkId].isFromServer = true;
  delete draftState.artworks.byId[tempArtworkId];
};

const editArtworkLocally = (draftState, action) => {
  const { artworkValues: oldArtworkValues, ...oldArtworkData } = draftState.artworks.byId[action.payload.id];
  const { artworkValues: newArtworkValues, ...newArtworkData } = action.payload;

  draftState.artworks.byId[action.payload.id] = {
    artworkValues: { ...oldArtworkValues, ...newArtworkValues },
    ...oldArtworkData,
    ...newArtworkData,
    isModified: true
  };
};

const editArtworkSuccess = (draftState, action) => {
  const { artworkId } = action.payload;

  draftState.artworks.byId[artworkId].isModified = false;
};

const deleteArtworks = (draftState, action) => {
  const { artworkIds } = action.payload;

  artworkIds.forEach(artworkId => {
    draftState.artworks.byId[artworkId].isActive = false;
  });
};

const deleteArtworkSuccess = (draftState, action) => {
  if (!draftState.artworks.byId[action.payload.artworkId].activeShareSource) {
    delete draftState.artworks.byId[action.payload.artworkId];
  }
};

const shareArtworkSuccess = (draftState, action) => {
  const { shareRecord } = action.payload;

  draftState.shareRecords.byId[shareRecord.id] = shareRecord;
};

const saveSharedArtworkLocally = (draftState, action) => {
  const { collectionId, artworkId } = action.payload;

  draftState.artworks.byId[artworkId].artworkCollection = collectionId;
  draftState.artworks.byId[artworkId].isModified = true;
  draftState.artworks.byId[artworkId].isActive = true;
  draftState.artworks.byId[artworkId].isDeleted = false;
};

const uploadArtworkImageSuccess = (draftState, action) => {
  const { artworkId, connectionId, imageToConnect, type } = action.payload;

  if (type === "image") {

    const index = draftState.artworks.byId[artworkId].images.findIndex(image => image.connectionId === connectionId);
    if (index !== -1) {
      draftState.artworks.byId[artworkId].images[index].key = uuid();
      draftState.artworks.byId[artworkId].images[index].url = imageToConnect.url;
      draftState.artworks.byId[artworkId].images[index].description = imageToConnect.description;
      draftState.artworks.byId[artworkId].images[index].sortIndex = imageToConnect.sortIndex;
      draftState.artworks.byId[artworkId].images[index].isFromServer = true;
    }
  }else{
    if (draftState.artworks.byId[artworkId].artworkValues.documents !== undefined) {
      const documentIndex = draftState.artworks.byId[artworkId].artworkValues.documents.findIndex(document => document.connectionId === connectionId);
      if (documentIndex !== -1) {
        const fileIndex = draftState.artworks.byId[artworkId].artworkValues.documents[documentIndex].files.findIndex(file => file.collectionId === imageToConnect.connectionId)
        draftState.artworks.byId[artworkId].documents[documentIndex].description = imageToConnect.description;
        draftState.artworks.byId[artworkId].documents[documentIndex].documentType = imageToConnect.documentType;
        draftState.artworks.byId[artworkId].documents[documentIndex].files[fileIndex].key = uuid();
        draftState.artworks.byId[artworkId].documents[documentIndex].files[fileIndex].url = imageToConnect.url;
        draftState.artworks.byId[artworkId].documents[documentIndex].files[fileIndex].sortIndex = imageToConnect.sortIndex;
        draftState.artworks.byId[artworkId].documents[documentIndex].files[fileIndex].isFromServer = true;
      }
    }
  };
};

const downloadArtists = (draftState, action) => {
  action.payload.forEach((artist) => {
    draftState.artists.byId[artist.id] = { ...artist, isFromServer: true, isModified: false, isActive: true };
  });
};

const createArtistLocally = (draftState, action) => {
  const { tempArtistId, ...restOfArtist } = action.payload;
  draftState.artists.byId[tempArtistId] = {
    id: tempArtistId,
    ...restOfArtist,
    isFromServer: false,
    isModified: false,
    isActive: true,
  };
};

const createArtistSuccess = (draftState, action) => {
  const { id: artistId, tempArtistId } = action.payload;

  draftState.artists.byId[artistId] = { ...draftState.artists.byId[tempArtistId] };
  draftState.artists.byId[artistId].id = artistId;
  draftState.artists.byId[artistId].isFromServer = true;
  delete draftState.artists.byId[tempArtistId];
};

const uploadArtistImageSuccess = (draftState, action) => {
  const { artistId, connectionId, imageToConnect } = action.payload;

  const index = draftState.artists.byId[artistId].images.findIndex((image) => image.connectionId === connectionId);

  if (index !== -1) {
    draftState.artists.byId[artistId].images[index].key = uuid();
    draftState.artists.byId[artistId].images[index].url = imageToConnect.url;
    draftState.artists.byId[artistId].images[index].description = imageToConnect.description;
    draftState.artists.byId[artistId].images[index].sortIndex = imageToConnect.sortIndex;
    draftState.artists.byId[artistId].images[index].isFromServer = true;
  }
};

const editArtistLocally = (draftState, action) => {
  const { ...oldArtistData } = draftState.artists.byId?.[action.payload.id];
  const { ...newArtistData } = action.payload;
  draftState.artists.byId[action.payload.id] = {
    ...oldArtistData,
    ...newArtistData,
    isModified: true,
  };
};

const editArtistSuccess = (draftState, action) => {
  const { artistId } = action.payload;

  draftState.artists.byId[artistId].isModified = false;
};

const deleteArtists = (draftState, action) => {
  const { artistIds } = action.payload;

  artistIds.forEach((artistId) => {
    draftState.artists.byId[artistId].isActive = false;
  });
};

const deleteArtistSuccess = (draftState, action) => {
  delete draftState.artists.byId[action.payload.artistId];
};

const syncArchive = (state, draftState, action) => {
  const { artworks, artists, recievedRecords, sentRecords } = action.payload;

  artists.forEach(artist => {
    if (!artist.isDeleted) {
      draftState.artists.byId[artist.id] = {
        ...artist, 
        isFromServer: true,
        isModified: false,
        isActive: true
      };
    }

  });

  artworks.forEach(({ images: imageListFromServer, artist, ...artwork }) => {
    if (artwork.isDeleted && !artwork.activeShareSource) {
      delete draftState.artworks.byId[artwork.id];
      return;
    }

    const imageList = _.map(imageListFromServer, serverImage => {
      let tempImage;

      if (state.artworks.byId[artwork.id]) {
        state.artworks.byId[artwork.id].images.forEach(localImage => {
          if (serverImage.connectionId === localImage.connectionId) {
            tempImage = { ...localImage, ...serverImage, isFromServer: true, isModified: false, isActive: true };
          }
        });
      }

      if (!tempImage) {
        tempImage = { key: uuid(), ...serverImage, isFromServer: true, isModified: false, isActive: true };
      }

      return tempImage;
    });

    draftState.artworks.byId[artwork.id] = {
      ...artwork,
      artist: artist.name,
      images: _.sortBy(imageList, ["sortIndex"]),
      isFromServer: true,
      isModified: false,
      isActive: true
    };
  });

  recievedRecords.forEach(recievedRecord => {
    draftState.shareRecords.byId[recievedRecord.id] = recievedRecord;
  });

  sentRecords.forEach(sentRecord => {
    draftState.shareRecords.byId[sentRecord.id] = sentRecord;
  });
};

export default archiveReducer;
