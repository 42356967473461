import { useEffect } from "react";
import { WithRequireAccess } from "../hocs";
import { Button } from "../elements";
import { Box as CustomBox, Flex, Text, Image, Link } from "../fundamentals";
import { NavigationBar } from "../react-material/NavigationBar";
import { Headings, ButtonLabels, Messages, DynamicLabels, Warnings } from "../../localisation";
import { Box, Stack, InputBase, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  openModal,
  getViewingRoomsRequest,
  clearAllSelectedViewingRooms,
  clearAllSelectedArtworks,
  updateSearchTermViewingRoom,
  deleteViewingRooms,
} from "../../store/actions";
import { selectSearch, selectAllViewingRoomsList, selectSelectedViewingRoomIds } from "../../store/selectors";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { ViewingRoomsTable } from "../react-material/ViewingRoomsTable/ViewingRoomsTable";
import { Modal } from "../react-material";
import { DeleteModal } from "../modals";

const inputBaseStyle = {
  bgcolor: "#FFFFFF",
  p: "8px 16px",
  borderRadius: "16px",
  width: "275px",
  height: "32px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
};

const containerStyle = (selected) => {
  return {
    maxWidth: "100%",
    padding: "16px 48px",
    mt: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: selected && "#5E54FF",
  };
};

const viewingRoomHeadingCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title",
  },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: false,
    label: "Created on",
  },
  {
    id: "dateValid",
    numeric: false,
    disablePadding: false,
    label: "Valid Until",
  },
  {
    id: "viewsRecord",
    numeric: false,
    disablePadding: false,
    label: "Number of Views",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

const ViewingRoomsScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const viewingRooms = useSelector(selectAllViewingRoomsList);
  const selectedViewingRooms = useSelector(selectSelectedViewingRoomIds) || [];
  const selected = selectedViewingRooms.length > 0;
  const search = useSelector(selectSearch);
  const isSearchTermEmpty = search.searchTermViewingRoom !== "";

  const handleOnSearch = _.debounce((searchTerm) => dispatch(updateSearchTermViewingRoom(searchTerm)), 50);

  const deleteSelectedViewingRooms = () => {
    dispatch(deleteViewingRooms(selectedViewingRooms));
    dispatch(clearAllSelectedViewingRooms());
  };

  const handleCancelSelectAll = () => {
    dispatch(clearAllSelectedViewingRooms());
  };

  const addViewingRoomButtonClick = () => {
    history.push("viewingrooms/add");
  };

  useEffect(() => {
    dispatch(clearAllSelectedViewingRooms());
    dispatch(clearAllSelectedArtworks());
    dispatch(getViewingRoomsRequest());
  }, []);

  const renderEmptyViewingRoomList = () => {
    return (
      <Flex flexDirection="column" alignItems="center" height="100%" width="100%">
        <Image
          src={process.env.PUBLIC_URL + "/images/icons/brand-icons/empty-state-illustration.svg"}
          alt="brand"
          width="130px"
          height="180px"
          mt="xxxl"
        />

        <Text fontFamily="bodyDmSans" size="heading2" mt="m">
          {isSearchTermEmpty ? Messages.noViewingRoomsFound : Messages.noViewingRooms}
        </Text>
        <Text width="300px" height="64px" overflow="hidden" overflowY="hidden" mt="s" textAlign="center">
          {isSearchTermEmpty ? Messages.emptySearchViewingRoom(search.searchTermViewingRoom) : Messages.addViewingRooms}
        </Text>
        <CustomBox mt="m">
          {isSearchTermEmpty ? (
            <Button
              icon="XCircle"
              buttonStyle="secondary"
              size="medium"
              mr="s"
              onClick={() => dispatch(updateSearchTermViewingRoom(""))}
            >
              {ButtonLabels.clearSearch}
            </Button>
          ) : (
            <Link href="https://www.simplify.art/help" target="_blank" mr="s">
              <Button icon="HelpCircle" buttonStyle="secondary" size="medium">
                {ButtonLabels.help}
              </Button>
            </Link>
          )}
          <Button icon="Plus" buttonStyle="primary" size="medium" onClick={addViewingRoomButtonClick}>
            {ButtonLabels.newViewingRoom}
          </Button>
        </CustomBox>
      </Flex>
    );
  };

  return (
    <Flex flexDirection="column" width="100%" margin="0 auto" position="relative">
      <NavigationBar />
      <Text fontSize="heading2" pl="xxl" mt="m">
        {Headings.viewingRooms}
      </Text>
      {/* search bar */}
      <Box sx={containerStyle(selected)}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <InputBase
            placeholder="Search Viewing Rooms"
            sx={inputBaseStyle}
            value={search.searchTermViewingRoom}
            onChange={(e) => handleOnSearch(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#55535A" }} />
              </InputAdornment>
            }
          />
        </Stack>

        <Stack direction="row" gap="40px" alignItems="center">
          {selected ? (
            <>
              <Button
                onClick={() => dispatch(openModal("delete_viewing_room"))}
                buttonStyle="secondary"
                size="small"
                icon="Trash2"
                fontWeight="500"
                inverted={true}
              >
                {ButtonLabels.delete}
              </Button>

              <Button
                buttonStyle="primary"
                size="small"
                icon="X"
                fontWeight="500"
                iconStrokeWidth="2.5px"
                iconCursor="pointer"
                inverted={true}
                onClick={handleCancelSelectAll}
              >
                {ButtonLabels.cancel}
              </Button>
            </>
          ) : (
            <Box>
              {Object.keys(viewingRooms).length > 0 && (
                <Button
                  buttonStyle="primary"
                  size="medium"
                  fontWeight="500"
                  icon="Plus"
                  iconStrokeWidth="2.5px"
                  iconCursor="pointer"
                  onClick={addViewingRoomButtonClick}
                >
                  {ButtonLabels.newViewingRoom}
                </Button>
              )}
            </Box>
          )}
        </Stack>
      </Box>
      {Object.keys(viewingRooms).length > 0 ? (
        <Box>
          <ViewingRoomsTable headCells={viewingRoomHeadingCells} viewingRooms={viewingRooms} />
        </Box>
      ) : (
        <Box>{renderEmptyViewingRoomList()}</Box>
      )}
      <Modal id="delete_viewing_room">
        <DeleteModal
          headerText={DynamicLabels.popupHeaderForDelete(
            selectedViewingRooms.length > 1 ? "Viewing Rooms" : "Viewing Room",
            selectedViewingRooms.length
          )}
          bodyText={Warnings.popupWarning}
          onDelete={deleteSelectedViewingRooms}
        />
      </Modal>
    </Flex>
  );
};

export default WithRequireAccess(ViewingRoomsScreen, 1);
