import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Button } from "../elements";
import { Flex, Box as StyledBox, Text } from "../fundamentals";
import { closeModal, paymentInviteRejected} from "../../store/actions";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Typography, Box, Stack } from "@mui/material";
import { Headings, ButtonLabels, Info, InputLabels } from "../../localisation";
import { Input } from "../react-material/Input";

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: "20px",
    lineHeight: mobile ? "26px" : "28px",
    marginBottom: "12px",
    fontWeight: "400",
  };
};

const inputLabelStyle = {
  width: "151px",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#6A6870",
  fontFamily: "Inter",
};

const Form = (props) => {
  const isMobile = useIsScreenMobile();
  const dispatch = useDispatch();
  const paymentInviteId = props[0];
  const message = props.message;
  const handleSubmit = props.handleSubmit;
  const submitting = props.submitting;

  const handleBackClick = () => {
    dispatch(closeModal("payment_invite_buyer_reject"));
  };

  const handleRejectClick = () => {
    dispatch(paymentInviteRejected({paymentInviteId: paymentInviteId, eventName:"PaymentInviteRejected",
      message: {PaymentInviteRejected: "rejected", message:message}, userId: 'Guest'}));
  };

  return (
    <StyledBox width="500px">
      <Typography sx={headerTextStyle(isMobile)}>{Headings.rejectPaymentInvite}</Typography>
      <Text fontSize="small" color="grey.80" mb="xl">
        {Info.cancelPaymentInviteInfo}
      </Text>
      <form onSubmit={handleSubmit(handleRejectClick)}>
        <Box sx={{ borderBottom: "1px solid #C3C2C9", marginBottom:"30px" }}>
          <Stack direction="column">
            <Typography sx={inputLabelStyle} marginTop="12px">
              {InputLabels.message}
            </Typography>

            <Field
              name="message"
              component={Input}
              formName="payment_invite.reject"
              sx={{
                flexGrow: 1,
                ".MuiFilledInput-root": {
                  paddingTop: "0px",
                  "&:hover": {
                    backgroundColor: "#F3F4FF",
                    borderBottom: "2px solid #C8CEFF",
                    marginBottom: "-1px",
                  },
                  "&:before": {
                    content: "none",
                  },
                  "&.Mui-focused": {
                    borderBottom: "none",
                    backgroundColor: "#F3F4FF",
                    marginBottom: "-1px",
                  },
                },
              }}
              multiline={true}
            />
          </Stack>
        </Box>
        <Flex>
          <Button 
            type="submit" 
            buttonStyle="warning" 
            size="medium" 
            fontWeight="500" 
            mr="xs" 
            disabled={submitting}
            icon={submitting ? "Loader" : null}
            iconSpinning={true}
            iconOffset="xs"
            >
            {ButtonLabels.reject}
          </Button>
          <Button type="button" buttonStyle="secondary" size="medium" onClick={handleBackClick}>
            {ButtonLabels.back}
          </Button>
        </Flex>
      </form>
    </StyledBox>
  );
};

let PaymentInviteRejectionModal = reduxForm({
  form: "payment_invite.reject",
})(Form);

const selector = formValueSelector("payment_invite.reject");

function mapStateToProps(state) {
  const message = selector(state, "message");
  return { message };
}
PaymentInviteRejectionModal = connect(mapStateToProps, null)(PaymentInviteRejectionModal);

export { PaymentInviteRejectionModal };
