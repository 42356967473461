import produce from "immer";
import {
  ADD_SELECTED_ARTWORK,
  REMOVE_SELECTED_ARTWORK,
  REPLACE_SELECTED_ARTWORK,
  CHANGE_SELECTED_COLLECTION,
  CHANGE_SELECTED_CATEGORY,
  SELECT_ALL_ARTWORKS,
  CLEAR_ALL_SELECTED_ARTWORKS,
  CREATE_ARTWORK_SUCCESS,
  SIGN_OUT_SUCCESS,
  ADD_SELECTED_CONTACT,
  REMOVE_SELECTED_CONTACT,
  REPLACE_SELECTED_CONTACT,
  SELECT_ALL_CONTACTS,
  CLEAR_ALL_SELECTED_CONTACTS,
  ADD_SELECTED_ARTIST,
  REMOVE_SELECTED_ARTIST,
  REPLACE_SELECTED_ARTIST,
  SELECT_ALL_ARTISTS,
  CLEAR_ALL_SELECTED_ARTISTS,
  ADD_SELECTED_PAYMENT_INVITE,
  CLEAR_ALL_SELECTED_PAYMENT_INVITES,
  ADD_SELECTED_LIST,
  REMOVE_SELECTED_LIST,
  REPLACE_SELECTED_LIST,
  SELECT_ALL_LISTS,
  CLEAR_ALL_SELECTED_LISTS,
  ADD_SELECTED_VIEWING_ROOM,
  REMOVE_SELECTED_VIEWING_ROOM,
  REPLACE_SELECTED_VIEWING_ROOM,
  SELECT_ALL_VIEWING_ROOMS,
  CLEAR_ALL_SELECTED_VIEWING_ROOMS,
} from "../actions/types";

const SELECTION_INITIAL_STATE = {
  collection: "",
  category: "",
  artworks: [],
  artists: [],
  contacts: [],
  lists: [],
  paymentInvites: [],
  viewingRooms: [],
};

const selectionReducer = (state = SELECTION_INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case ADD_SELECTED_ARTWORK:
        return addSelectedArtwork(draftState, action);
      case REMOVE_SELECTED_ARTWORK:
        return removeSelectedArtwork(draftState, action);
      case REPLACE_SELECTED_ARTWORK:
        return replaceSelectedArtwork(draftState, action);
      case CHANGE_SELECTED_COLLECTION:
        return changeSelectedCollection(draftState, action);
      case CHANGE_SELECTED_CATEGORY:
        return changeSelectedCategory(draftState, action);
      case SELECT_ALL_ARTWORKS:
        return selectAllArtworks(draftState, action);
      case CLEAR_ALL_SELECTED_ARTWORKS:
        return clearAllSelectedArtworks(draftState, action);
      case CREATE_ARTWORK_SUCCESS:
        return createArtworkSuccess(draftState, action);
      case ADD_SELECTED_CONTACT:
        return addSelectedContact(draftState, action);
      case REMOVE_SELECTED_CONTACT:
        return removeSelectedContact(draftState, action);
      case REPLACE_SELECTED_CONTACT:
        return replaceSelectedContact(draftState, action);
      case SELECT_ALL_CONTACTS:
        return selectAllContacts(draftState, action);
      case CLEAR_ALL_SELECTED_CONTACTS:
        return clearAllSelectedContacts(draftState, action);
      case ADD_SELECTED_ARTIST:
        return addSelectedArtist(draftState, action);
      case REMOVE_SELECTED_ARTIST:
        return removeSelectedArtist(draftState, action);
      case REPLACE_SELECTED_ARTIST:
        return replaceSelectedArtist(draftState, action);
      case SELECT_ALL_ARTISTS:
        return selectAllArtists(draftState, action);
      case CLEAR_ALL_SELECTED_ARTISTS:
        return clearAllSelectedArtists(draftState, action);
      case ADD_SELECTED_PAYMENT_INVITE:
        return addSelectedPaymentInvite(draftState, action);
      case CLEAR_ALL_SELECTED_PAYMENT_INVITES:
        return clearAllSelectedPaymentInvites(draftState, action);
      case ADD_SELECTED_LIST:
        return addSelectedList(draftState, action);
      case REMOVE_SELECTED_LIST:
        return removeSelectedList(draftState, action);
      case REPLACE_SELECTED_LIST:
        return replaceSelectedList(draftState, action);
      case SELECT_ALL_LISTS:
        return selectAllLists(draftState, action);
      case CLEAR_ALL_SELECTED_LISTS:
        return clearAllSelectedLists(draftState, action);
      case ADD_SELECTED_VIEWING_ROOM:
        return addSelectedViewingRoom(draftState, action);
      case REMOVE_SELECTED_VIEWING_ROOM:
        return removeSelectedViewingRoom(draftState, action);
      case REPLACE_SELECTED_VIEWING_ROOM:
        return replaceSelectedViewingRoom(draftState, action);
      case SELECT_ALL_VIEWING_ROOMS:
        return selectAllViewingRooms(draftState, action);
      case CLEAR_ALL_SELECTED_VIEWING_ROOMS:
        return clearAllSelectedViewingRooms(draftState, action);
      case SIGN_OUT_SUCCESS:
        return SELECTION_INITIAL_STATE;
      default:
        return state;
    }
  });
};

const addSelectedArtwork = (draftState, action) => {
  const { artworkId } = action.payload;
  if (!draftState.artworks.includes(artworkId)) draftState.artworks.push(artworkId);
};

const removeSelectedArtwork = (draftState, action) => {
  const index = draftState.artworks.findIndex((artworkId) => artworkId === action.payload.artworkId);
  if (index !== -1) draftState.artworks.splice(index, 1);
};

const replaceSelectedArtwork = (draftState, action) => {
  draftState.artworks = [];
  draftState.artworks.push(action.payload.artworkId);
};

const changeSelectedCollection = (draftState, action) => {
  draftState.collection = action.payload.collectionId;
};

const changeSelectedCategory = (draftState, action) => {
  draftState.category = action.payload.category;
};

const selectAllArtworks = (draftState, action) => {
  draftState.artworks = action.payload.artworkIds;
};

const clearAllSelectedArtworks = (draftState, action) => {
  draftState.artworks = [];
};

const createArtworkSuccess = (draftState, action) => {
  const index = draftState.artworks.findIndex((artworkId) => artworkId === action.payload.tempArtworkId);
  if (index !== -1) draftState.artworks[index] = action.payload.artworkId;
};

const addSelectedContact = (draftState, action) => {
  const { contactId } = action.payload;
  if (!draftState.contacts.includes(contactId)) draftState.contacts.push(contactId);
};

const removeSelectedContact = (draftState, action) => {
  const index = draftState.contacts.findIndex((contactId) => contactId === action.payload.contactId);
  if (index !== -1) draftState.contacts.splice(index, 1);
};

const replaceSelectedContact = (draftState, action) => {
  draftState.contacts = [];
  draftState.contacts.push(action.payload.contactId);
};

const selectAllContacts = (draftState, action) => {
  draftState.contacts = action.payload.contactIds;
};

const clearAllSelectedContacts = (draftState, action) => {
  draftState.contacts = [];
};

const addSelectedArtist = (draftState, action) => {
  const { artistId } = action.payload;
  if (!draftState.artists.includes(artistId)) draftState.artists.push(artistId);
};

const removeSelectedArtist = (draftState, action) => {
  const index = draftState.artists.findIndex((artistId) => artistId === action.payload.artistId);
  if (index !== -1) draftState.artists.splice(index, 1);
};

const replaceSelectedArtist = (draftState, action) => {
  draftState.artists = [];
  draftState.artists.push(action.payload.artistId);
};

const selectAllArtists = (draftState, action) => {
  draftState.artists = action.payload.artistIds;
};

const clearAllSelectedArtists = (draftState, action) => {
    draftState.artists = [];
};

const addSelectedPaymentInvite = (draftState, action) => {
  const { paymentInviteId } = action.payload;
  if (!draftState.paymentInvites.includes(paymentInviteId)) draftState.paymentInvites.push(paymentInviteId);
};

const clearAllSelectedPaymentInvites = (draftState, action) => {
  draftState.paymentInvites = [];
};

const addSelectedList = (draftState, action) => {
  const { listId } = action.payload;
  if (!draftState.lists.includes(listId)) draftState.lists.push(listId);
};

const removeSelectedList = (draftState, action) => {
  const index = draftState.lists.findIndex((listId) => listId === action.payload.listId);
  if (index !== -1) draftState.lists.splice(index, 1);
};

const replaceSelectedList = (draftState, action) => {
  draftState.lists = [];
  draftState.lists.push(action.payload.listId);
};

const selectAllLists = (draftState, action) => {
  draftState.lists = action.payload.listIds;
};

const clearAllSelectedLists = (draftState, action) => {
  draftState.lists = [];
};

const addSelectedViewingRoom = (draftState, action) => {
  const { viewingRoomId } = action.payload;
  if (!draftState.viewingRooms.includes(viewingRoomId)) draftState.viewingRooms.push(viewingRoomId);
};

const removeSelectedViewingRoom = (draftState, action) => {
  const index = draftState.viewingRooms.findIndex((viewingRoomId) => viewingRoomId === action.payload.viewingRoomId);
  if (index !== -1) draftState.viewingRooms.splice(index, 1);
};

const replaceSelectedViewingRoom = (draftState, action) => {
  draftState.viewingRooms = [];
  draftState.viewingRooms.push(action.payload.viewingRoomId);
};

const selectAllViewingRooms = (draftState, action) => {
  draftState.viewingRooms = action.payload.viewingRoomIds;
};

const clearAllSelectedViewingRooms = (draftState, action) => {
    draftState.viewingRooms = [];
};

export default selectionReducer;
