import { SimplifyApi } from "../../utils";
import { all, take, call, select, put, fork, cancel, cancelled, delay, takeLatest } from "redux-saga/effects";
import { increaseSyncCount, syncArchive, syncUser, syncSuccess, syncFailure, unseenShareRecords } from "../actions";
import { INITIALISE_SYNC_SYSTEM, START_BACKGROUND_SYNC, STOP_BACKGROUND_SYNC } from "../actions/types";
import { syncContacts } from "../actions/ContactActions";
// import { syncArtists } from "../actions/ArtistActions";

const syncService = (lastSyncDate) => SimplifyApi.get(`/v1/sync/${lastSyncDate}`);

function* backgroundSyncSaga() {
  try {
    while (true) {
      const syncState = yield select((state) => state.sync);

      if (syncState.isOnline) {
        try {
          const response = yield call(syncService, syncState.nextSync);

          const { nextSync, user, artworks, artists, contacts, recievedRecords, sentRecords } = response.data;

          if (nextSync || user || artworks || artists || recievedRecords || sentRecords) {
            if (recievedRecords && recievedRecords.length > 0) yield put(unseenShareRecords());

            yield all([
              put(syncSuccess(syncState.nextSync, nextSync)),
              put(increaseSyncCount()),
              put(syncArchive(artworks, artists, recievedRecords, sentRecords)),
              put(syncUser(user)),
              put(syncContacts(contacts)),
              // put(syncArtists(artists)),
            ]);
          } else {
            console.log(response.data);
          }
        } catch (error) {
          console.log(error);
          yield all([put(syncFailure(syncState.nextSync)), put(increaseSyncCount())]);
        }
      } else {
        console.log("Client is currently offline can not sync");
      }

      yield delay(20000);
    }
  } catch (error) {
    console.log(error);
    yield put(syncFailure());
  } finally {
    if (yield cancelled()) yield put(syncFailure());
  }
}

function* backgroundSyncWatcherSaga() {
  while (yield take(START_BACKGROUND_SYNC)) {
    // starts the task in the background
    const backgroundSyncTask = yield fork(backgroundSyncSaga);

    // wait for the user to click signout
    yield take(STOP_BACKGROUND_SYNC);

    // user clicked signout. cancel the background task
    // this will cause the forked backgroundSync task to jump into its finally block
    yield cancel(backgroundSyncTask);
  }
}

function* syncSaga() {
  yield takeLatest(INITIALISE_SYNC_SYSTEM, backgroundSyncWatcherSaga);
}

export default syncSaga;
