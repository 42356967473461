import { replace } from "connected-react-router";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { RecordEventAnalytics } from "../../analytics";
import { Events } from "../../analytics/types";
import { SimplifyApi, createToast } from "../../utils";
import { addActionToQueue, addSelectedArtist, cacheImageActive, removeCacheImage, openModal } from "../actions";
import {
  createArtistLocally,
  createArtistSuccess,
  createArtistFailure,
  deleteArtist,
  deleteArtistFailure,
  deleteArtistSuccess,
  editArtistFailure,
  editArtistLocally,
  editArtistSuccess,
  uploadArtistImage,
  uploadArtistImageSuccess,
  uploadArtistImageFailure,
  downloadArtistsFailure,
  downloadArtistsSuccess,
} from "../actions/ArtistActions";
import { DELETE_ARTISTS, DOWNLOAD_ARTISTS, CREATE_ARTIST, EDIT_ARTIST } from "../actions/types";
import { selectUser, selectArtistById } from "../selectors";
import _ from "lodash";
import { Notifications } from "../../localisation";

// SERVICES

const createArtistService = (artistValues) =>
  SimplifyApi.post("/v1/artists", {
    artists: { ...artistValues },
  });

const editArtistService = (artistValues) => SimplifyApi.patch("/v1/artists", { ...artistValues });

const deleteArtistService = (artistId) =>
  SimplifyApi.delete(`/v1/artists?artistId=${artistId}
`);

const downloadArtistService = () => {
  return SimplifyApi.get("/v1/artists");
};

//SAGAS
export function* createArtistSaga(payload) {
  const user = yield select(selectUser);
  try {
    let artist;
    artist = yield select((state) => selectArtistById(state, payload.tempArtistId));

    if (!artist) {
      yield put(createArtistLocally(payload));

      // Make cached images in IndexDB active
      // so the automatic cleanup will not remove them
      // before they are uploaded to the server
      if (payload.images) {
        for (const image of payload.images) {
          yield put(cacheImageActive(image.localId));
        }
      }

      yield put(addSelectedArtist(payload.tempArtistId));

      if (user.role !== "artist") {
        yield put(replace("/artists"));
      }
      artist = yield select((state) => selectArtistById(state, payload.tempArtistId));
    }

    if (!artist) {
      yield put(createArtistFailure());
    }

    const response = yield call(createArtistService, artist);

    if (artist.images) {
      for (const image of artist.images) {
        yield put(
          addActionToQueue(uploadArtistImage(response.data.artistId, image.localId, image), response.data.artistId)
        );
      }
    }

    yield put(createArtistSuccess(payload.tempArtistId, response.data));
    if (user.role === "artist") {
      createToast({ type: "success", ...Notifications.profileCreated });
    }
  } catch (error) {
    yield put(createArtistFailure());
    throw error;
  }
}

export function* editArtistSaga(payload) {
  try {
    yield put(editArtistLocally(payload));

    const artist = yield select((state) => selectArtistById(state, payload.id));

    if (artist.isFromServer) {
      yield call(editArtistService, artist);
    }

    yield put(editArtistSuccess(artist.id));
    createToast({ type: "success", ...Notifications.artistUpdateSuccess });
    RecordEventAnalytics(Events.EDIT_ARTIST);
  } catch (error) {
    yield put(editArtistFailure());
    createToast({ type: "error", ...Notifications.generic });
    throw error;
  }
}

export function* deleteArtistsSaga(action) {
  const { artistIds } = action.payload;

  try {
    for (const artistId of artistIds) {
      yield put(addActionToQueue(deleteArtist(artistId), artistId));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteArtistSaga(payload) {
  const { artistId } = payload;

  try {
    const artistForDelete = yield select((state) => selectArtistById(state, artistId));

    if (artistForDelete.isFromServer) {
      const response = yield call(deleteArtistService, artistId);
      //Do not delete artist if they are connected to artworks
      if (response.data === "Artist has artworks") {
        yield put(openModal("artist-deletion-denied"));
      } else {
        yield put(deleteArtistSuccess(artistId));
        yield put(replace("/artists"));
      }

      if (artistForDelete.images) {
        for (const image of artistForDelete.images) {
          if (!image.isFromServer) yield put(removeCacheImage(image.localId));
        }
      }
    } else {
      if (artistForDelete.images) {
        for (const image of artistForDelete.images) {
          if (!image.isFromServer) yield put(removeCacheImage(image.localId));
        }
      }
    }
  } catch (error) {
    yield put(deleteArtistFailure(artistId));
    createToast({ type: "error", ...Notifications.deleteArtistFailure });
    throw error;
  }
}

export function* downloadArtists() {
  try {
    const { data } = yield call(downloadArtistService);
    yield put(downloadArtistsSuccess(data.artists));
  } catch (error) {
    yield put(downloadArtistsFailure());
  }
}

function* artistSaga() {
  yield takeEvery(DOWNLOAD_ARTISTS, downloadArtists);
  yield takeEvery(DELETE_ARTISTS, deleteArtistsSaga);
  yield takeEvery(CREATE_ARTIST, createArtistSaga);
  yield takeEvery(EDIT_ARTIST, editArtistSaga);
}

export default artistSaga;
