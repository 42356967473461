import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Modal } from "../react-material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ArrowLeft, RotateCcw, Trash2, UploadCloud } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { ButtonLabels, DynamicLabels, Headings, UiLabels, Warnings } from "../../localisation";
import { editContact, addActionToQueue, openModal, deleteContacts } from "../../store/actions";
import { WithRequireAccess } from "../hocs";
import { ContactSidebar } from "../modules";
import { ContactForm } from "../react-material/forms/ContactForm";
import { NavigationBar } from "../react-material/NavigationBar/NavigationBar";
import { selectSelectedContactIds, selectUser, selectContactById } from "../../store/selectors";
import { reset } from "redux-form";
import { CONTACT_TYPES } from "../../config";
import { DeleteModal } from "../modals";
import { v4 as uuid } from "uuid";

const buttonStyle = {
  color: "white",
  fontFamily: "Inter",
  fontSize: "16px",
  lineHeight: "16px",
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.7,
  },
  "&:active": {
    opacity: 0.5,
  },
};

const submit = (values, dispatch) => {
  const { ContactGeneralFormSection, ContactAdditionalFormSection } = values;
  const types = ContactGeneralFormSection.types.map((data) => data.value);
  if (types) {
    ContactGeneralFormSection.types = types;
  }
  const { address1, address2, city, postalCode, state, country, ...ContactAdditionalFormSectionOtherValues } =
    ContactAdditionalFormSection;
  const contact = {
    id: values.id,
    ...ContactGeneralFormSection,
    address: { address1, address2, city, postalCode, state, country },
    ...ContactAdditionalFormSectionOtherValues,
  };
  const tempContactId = uuid();
  return dispatch(addActionToQueue(editContact(contact), tempContactId));
};

const EditContactScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const selectedContactId = useSelector(selectSelectedContactIds)[0];
  const contact = useSelector((state) => selectContactById(state, selectedContactId));
  const types = CONTACT_TYPES.filter((o) => contact?.types?.some((value) => o.value === value));

  const initialFormValues = {
    id: contact?.id,
    ContactGeneralFormSection: {
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      organisation: contact?.organisation,
      email: contact?.email,
      phone: contact?.phone,
      types: types,
    },
    ContactAdditionalFormSection: {
      websiteUrl: contact?.websiteUrl,
      instagramUrl: contact?.instagramUrl,
      position: contact?.position,
      vatNumber: contact?.vatNumber,
      address1: contact?.address?.address1,
      address2: contact?.address?.address2,
      city: contact?.address?.city,
      postalCode: contact?.address?.postalCode,
      state: contact?.address?.state,
      country: contact?.address?.country,
      notes: contact?.notes,
    },
  };

  const renderFloatingActionPillButtons = (dispatch, form, submitting) => {
    return [
      <Button 
        type="submit"
        variant="text"
        startIcon={<UploadCloud size="20px" />} 
        sx={buttonStyle}
        disabled={submitting}
      >
        {ButtonLabels.saveContact}
      </Button>,

      <Button
        onClick={() => dispatch(reset(form))}
        variant="text"
        key="reset"
        startIcon={<RotateCcw size="20px" />}
        sx={buttonStyle}
        disabled={submitting}
      >
        {ButtonLabels.reset}
      </Button>,
      <Button
        variant="text"
        startIcon={<Trash2 size="20px" />}
        sx={buttonStyle}
        onClick={() => dispatch(openModal("delete_contact"))}
      >
        {ButtonLabels.delete}
      </Button>,
    ];
  };

  const deleteSelectedContacts = () => {
    dispatch(deleteContacts([selectedContactId]));
  };

  return (
    <Box width="100%">
      <Box maxWidth="98%" margin="0 auto">
        <NavigationBar />
      </Box>
      <Stack direction="row">
        <Stack pl="48px" pt="16px" pb="16px" alignItems="flex-start" flexGrow={1} minHeight="100vh" bgcolor="white">
          <Button
            onClick={() => history.goBack()}
            sx={{ lineHeight: "16px", fontSize: "14px", pl: "0px" }}
            startIcon={<ArrowLeft style={{ position: "relative", right: "-5px", width: "16px", height: "16px" }} />}
          >
            {ButtonLabels.backToContacts}
          </Button>
          <Stack direction="row">
            <Typography variant="h2" sx={{ color: "#6A6870" }}>
              {Headings.contacts}
            </Typography>
            <Typography variant="h2" sx={{ color: "#6A6870", m: "0 8px" }}>
              /
            </Typography>
            <Typography variant="h2">{`${contact?.firstName || " "} ${contact?.lastName || " "}`}</Typography>
          </Stack>
          <Stack mt="16px" mb="48px">
            <Typography sx={{ fontSize: "12px", fontWeight: 500, color: "#95939C" }}>{UiLabels.lastUpdated}</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {moment().format("MMMM Do YYYY, h:mm")} by {user.name}
            </Typography>
          </Stack>
          <ContactForm
            form="contact.edit"
            selectedContactIds={[selectedContactId]}
            initialValues={initialFormValues}
            renderFloatingActionPillButtons={renderFloatingActionPillButtons}
            onSubmit={submit}
          />
        </Stack>

        <Stack>
          <ContactSidebar data={contact} />
        </Stack>
      </Stack>

      <Modal id="delete_contact">
        <DeleteModal
          headerText={DynamicLabels.popupHeaderForDelete("contact")}
          bodyText={Warnings.popupWarning}
          onDelete={deleteSelectedContacts}
        />
      </Modal>
    </Box>
  );
};

export default WithRequireAccess(EditContactScreen, 1);
