import _ from "lodash";
import { channel } from "redux-saga";
import { retry, linearGrowth } from "redux-saga-retry";
import { all, call, put, take, fork, select, takeLatest, takeEvery } from "redux-saga/effects";
import {
  INITIALISE_QUEUE_SYSTEM,
  ADD_ACTION_TO_QUEUE,
  EXECUTE_ACTION_IN_QUEUE_FINISHED,
  ADD_FAILED_ACTIONS_TO_QUEUE,
  CREATE_ARTWORK,
  CREATE_ARTWORK_AND_COPY,
  CREATE_ARTWORK_AND_NEW,
  DELETE_ARTWORK,
  EDIT_ARTWORK,
  UPDATE_ARTWORK_AFTER_MULTI_EDIT,
  UPLOAD_ARTWORK_IMAGE,
  SAVE_SHARED_ARTWORK,
  CREATE_CONTACT,
  UPLOAD_CONTACT_IMAGE,
  DELETE_CONTACT,
  EDIT_CONTACT,
  CREATE_ARTIST,
  DELETE_ARTIST,
  EDIT_ARTIST,
  CREATE_PAYMENT_INVITE,
  CREATE_LIST_REQUEST,
  EDIT_LIST_REQUEST,
  DELETE_LIST_REQUEST,
  CREATE_VIEWING_ROOM,
  DELETE_VIEWING_ROOM_REQUEST,
  SAVE_VIEWING_ROOM_DRAFT,
} from "../actions/types";
import {
  addActionToQueue,
  addActionToDelayQueue,
  removeActionFromDelayQueue,
  executeActionInQueue,
  executeActionInQueueSuccess,
  executeActionInQueueFailure,
  executeActionInQueueFinished,
  removeFailedActionFromQueue,
} from "../actions";
import { selectExecutingActions, selectDelayActionQueue, selectFailedActionQueue } from "../selectors";
import {
  createArtworkSaga,
  createArtworkAndCopySaga,
  createArtworkAndNewSaga,
  deleteArtworkSaga,
  editArtworkSaga,
  updateArtworkAfterMultiEditSaga,
  saveSharedArtworkSaga,
  uploadArtworkImageSaga,
} from "./archiveSaga";
import { createContactSaga, deleteContactSaga, editContactSaga, uploadContactImageSaga } from "./contactSaga";
import { createArtistSaga, deleteArtistSaga, editArtistSaga } from "./artistSaga";
import { createViewingRoomSaga, deleteViewingRoomSaga, saveViewingRoomDraftSaga } from "./viewingRoomSaga";
import { deleteListSaga } from "./listSaga";

const retryOptions = {
  condition: /_FAIL(ED|URE)?$/,
  backoff: linearGrowth,
  retries: 1,
};

function* watchQueue() {
  console.log("Watcher Of Queue Initialise");
  // create a channel to queue incoming execution requests
  const chan = yield call(channel);

  // create 3 worker 'threads'
  for (var i = 0; i < 3; i++) {
    yield fork(worker, chan);
  }

  //single worker
  // yield fork(worker, chan);

  while (true) {
    const { payload } = yield take(ADD_ACTION_TO_QUEUE);

    const executingActions = yield select(selectExecutingActions);

    const conditionForExecutingJob = _.some(executingActions, (execAction) => execAction.itemId === payload.itemId);

    if (!conditionForExecutingJob) {
      yield put(chan, payload);
    } else {
      yield put(addActionToDelayQueue(payload.id)); // put the action in the delay queue
    }
  }
}

function* worker(chan) {
  console.log("Worker Initialise");

  while (true) {
    const payload = yield take(chan);

    try {
      yield put(executeActionInQueue(payload.id));
      // process the job type and execute instructions
      switch (payload.action.type) {
        case CREATE_ARTWORK:
          yield call(retry(createArtworkSaga, retryOptions), payload.action.payload);
          break;
        case CREATE_ARTWORK_AND_COPY:
          yield call(retry(createArtworkAndCopySaga, retryOptions), payload.action.payload);
          break;
        case CREATE_ARTWORK_AND_NEW:
          yield call(retry(createArtworkAndNewSaga, retryOptions), payload.action.payload);
          break;
        case UPLOAD_ARTWORK_IMAGE:
          yield call(retry(uploadArtworkImageSaga, retryOptions), payload.action.payload);
          break;
        case EDIT_ARTWORK:
          yield call(retry(editArtworkSaga, retryOptions), payload.action.payload);
          break;
        case UPDATE_ARTWORK_AFTER_MULTI_EDIT:
          yield call(retry(updateArtworkAfterMultiEditSaga, retryOptions), payload.action.payload);
          break;
        case DELETE_ARTWORK:
          yield call(retry(deleteArtworkSaga, retryOptions), payload.action.payload);
          break;
        case SAVE_SHARED_ARTWORK:
          yield call(retry(saveSharedArtworkSaga, retryOptions), payload.action.payload);
          break;
        case CREATE_CONTACT:
          yield call(retry(createContactSaga, retryOptions), payload.action.payload);
          break;
        case UPLOAD_CONTACT_IMAGE:
          yield call(retry(uploadContactImageSaga, retryOptions), payload.action.payload);
          break;
        case EDIT_CONTACT:
          yield call(retry(editContactSaga, retryOptions), payload.action.payload);
          break;
        case DELETE_CONTACT:
          yield call(retry(deleteContactSaga, retryOptions), payload.action.payload);
          break;
        case CREATE_ARTIST:
          yield call(retry(createArtistSaga, retryOptions), payload.action.payload);
          break;
        case EDIT_ARTIST:
          yield call(retry(editArtistSaga, retryOptions), payload.action.payload);
          break;
        case DELETE_ARTIST:
          yield call(retry(deleteArtistSaga, retryOptions), payload.action.payload);
          break;
        case DELETE_LIST_REQUEST:
          yield call(retry(deleteListSaga, retryOptions), payload.action.payload);
          break;
        case CREATE_VIEWING_ROOM:
          yield call(retry(createViewingRoomSaga, retryOptions), payload.action.payload);
          break;
        case DELETE_VIEWING_ROOM_REQUEST:
          yield call(retry(deleteViewingRoomSaga, retryOptions), payload.action.payload);
          break;
        case SAVE_VIEWING_ROOM_DRAFT:
          yield call(retry(saveViewingRoomDraftSaga, retryOptions), payload.action.payload);
          break;
        default:
          break;
      }

      yield put(executeActionInQueueSuccess(payload.id));
    } catch (error) {
      console.log(error);
      yield put(executeActionInQueueFailure(payload.id));
    } finally {
      yield put(executeActionInQueueFinished(payload.id));
    }
  }
}

function* addFailedActionsToQueueSaga() {
  const failedActionQueue = yield select(selectFailedActionQueue);

  for (const failedAction of failedActionQueue) {
    yield all([
      put(addActionToQueue(failedAction.action, failedAction.itemId, failedAction.id, failedAction.attempt)),
      put(removeFailedActionFromQueue(failedAction.id)),
    ]);
  }
}

function* addDelayActionsToQueueSaga() {
  const delayedActionQueue = yield select(selectDelayActionQueue);

  for (const delayedAction of delayedActionQueue) {
    yield all([
      put(addActionToQueue(delayedAction.action, delayedAction.itemId, delayedAction.id, delayedAction.attempt)),
      put(removeActionFromDelayQueue(delayedAction.id)),
    ]);
  }
}

function* queueSaga() {
  yield takeLatest(INITIALISE_QUEUE_SYSTEM, watchQueue);
  yield takeEvery(ADD_FAILED_ACTIONS_TO_QUEUE, addFailedActionsToQueueSaga);
  yield takeEvery(EXECUTE_ACTION_IN_QUEUE_FINISHED, addDelayActionsToQueueSaga);
}

export default queueSaga;
