import React from "react";
import { Field, reduxForm } from "redux-form";
import { markPaymentInviteInTransit } from "../../store/actions";
import { Box, Text } from "../fundamentals";
import { Button } from "../elements";
import { Input, RenderError } from "../react-material/Input";
import { InputLabels, InputErrors, ButtonLabels, Headings, Info } from "../../localisation";
import { Stack, Typography } from "@mui/material";

const fieldStyle = {
  flexGrow: 1,
  ".MuiFilledInput-root": {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F3F4FF",
      borderBottom: "2px solid #C8CEFF",
      marginBottom: "-3px",
    },
    "&:before": {
      content: "none",
    },
    "&.Mui-focused": {
      borderBottom: "none",
      backgroundColor: "#F3F4FF",
      marginBottom: "-3px",
    },
  },

  "& .Mui-error": {
    ".MuiFilledInput-input": {
      marginBottom: "3px",
    },
  },
};

const stackStyle = {
  borderBottom: "1px solid #C3C2C9",
  alignItems: "center",
  justifyContent: "space-between",
  height: "58px",
};

const inputLabelStyle = {
  width: "151px",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#6A6870",
  fontFamily: "Inter",
};

const Form = ({ handleSubmit, submitting, paymentInviteId }) => {

    const submit = (values, dispatch) => {
        const { trackingNumber} = values;
        dispatch(markPaymentInviteInTransit(paymentInviteId, trackingNumber))
    }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box width="540px">
        <Text size="heading3" mb="xs" color="black">
          {Headings.markAsInTransit}
        </Text>
        <Text mb="l" color="grey.80" fontSize="small">
            {Info.confirmPaymentInviteInTransit}
        </Text>
        <Box>
          <Stack sx={stackStyle} direction="row">
            <Typography sx={inputLabelStyle}>{InputLabels.trackingNumber}</Typography>
            <Field name="trackingNumber" sx={fieldStyle} component={Input} hideError={true} />
          </Stack>
          <Field name="trackingNumber" component={RenderError} />
        </Box>
        <Button
          type="submit"
          buttonStyle="primary"
          size="small"
          mt="m"
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
          icon={submitting ? "Loader" : null}
          disabled={submitting}
          fontWeight="500"
        >
          {ButtonLabels.confirm}
        </Button>
      </Box>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const { trackingNumber } = values;

  if (!trackingNumber) {
    errors.trackingNumber = InputErrors.noTrackingNumber;
  }

  return errors;
};

const PaymentInviteInTransitModal = reduxForm({
  form: "payment_invite.status.in_transit",
  validate,
})(Form);

export { PaymentInviteInTransitModal };
