import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Flex, Box, Text, Icon, ImageWithCache } from "../fundamentals";
import { Button } from "../elements";
import { Headings, ButtonLabels, UiLabels, PaymentInviteBuyerNextSteps, Info } from "../../localisation";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Typography } from "@mui/material";
import { selectPaymentInviteById } from "../../store/selectors";
import { selectUser } from "../../store/selectors/userSelectors";
import { useHistory } from "react-router-dom";

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "24px",
    lineHeight: mobile ? "26px" : "32px",
    marginBottom: "12px",
    fontWeight: "400",
  };
};

const nextSteps = [
  {
    title: PaymentInviteBuyerNextSteps.stepOneAfterPayment,
    description: PaymentInviteBuyerNextSteps.stepOneDescAfterPayment,
    icon: "CreditCard",
  },
  {
    title: PaymentInviteBuyerNextSteps.stepTwo,
    description: PaymentInviteBuyerNextSteps.stepTwoDescAfterPayment,
    icon: "Bell",
  },
  {
    title: PaymentInviteBuyerNextSteps.stepThree,
    description: PaymentInviteBuyerNextSteps.stepThreeDescAfterPayment,
    icon: "Truck",
  },
  {
    title: PaymentInviteBuyerNextSteps.stepFour,
    description: PaymentInviteBuyerNextSteps.stepFourDesc,
    icon: "Image",
  },
];

const PaymentInviteBuyerConfirmationModal = ({ paymentInviteId }) => {
  const isMobile = useIsScreenMobile();
  const history = useHistory();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const paymentInvite = useSelector((state) => selectPaymentInviteById(state, paymentInviteId));
  const paymentInviteArtworks = useSelector(
    (state) => state.payment_invite_artworks.paymentInviteArtworks.byId[paymentInviteId]
  );
  const currentUrl = window.location.href;
  const destinationUrl = currentUrl.replace("paymentinvite/paid", "paymentinvite/pay");

  const copyUrl = () => {
    navigator.clipboard.writeText(destinationUrl);
    setIsButtonClicked(true);
  };

  //Create a combined artworks array from paymentInvite.artworks and paymentInviteArtworks that will contain the properties of both
  let combinedArtworksArray = [];
  if (paymentInviteArtworks) {
    combinedArtworksArray = paymentInvite.artworks.map(({ _id, price }) => {
      const restOfProperties = paymentInviteArtworks.find((artwork) => artwork._id === _id);
      return { _id, price, ...restOfProperties };
    });
  }

  const user = useSelector(selectUser);
  const isUser = user.account ? true : false;

  const handleButtonClick = () => {
    if (!isUser) {
      history.push("/register");
    } else {
      history.push("/artworks");
    }
  };

  return (
    <Flex justifyContent="space-between" width="992px">
      {/* Left Side */}
      <Box p="xxl">
        <Box width="460px">
          <Typography sx={headerTextStyle(isMobile)}>{Headings.processedPayment}</Typography>
          <Text fontSize="14px" lineHeight="18px" marginBottom="30px" fontWeight="400" color="grey.80">
            {isUser ? Info.paymentInviteEmailConfirmationUser : Info.paymentInviteEmailConfirmationNonUser}
          </Text>
          {!isUser && (
            <Flex
              border="1px solid #C8CEFF"
              borderRadius="8px"
              padding="l"
              justifyContent="space-between"
              marginBottom="30px"
            >
              <Flex alignItems="center">
                <Icon icon="Truck" color="blue.60" size="16" mr="xxs" />
                <Text fontWeight="500" color="blue.60">
                  {UiLabels.statusPage}
                </Text>
              </Flex>
              <Button
                buttonStyle="secondary"
                size="small"
                fontWeight="500"
                disabled={isButtonClicked}
                icon={isButtonClicked ? "Check" : "Copy"}
                flip={true}
                iconOffset="xxs"
                onClick={copyUrl}
              >
                {isButtonClicked ? ButtonLabels.linkCopied : ButtonLabels.copyLink}
              </Button>
            </Flex>
          )}
          <Box mb="30px">
            {/* <Text fontSize="small" color="grey.80" mb="xxs">
              {isUser ? Info.addPurchasedArtworksUser : Info.addPurchasedArtworksNonUser}
            </Text> */}
            <Flex overflowX="auto" paddingBottom="s" alignItems="start">
              {combinedArtworksArray.map((artwork) => {
                const { images = [] } = artwork;
                const [firstImage = {}] = images;
                return (
                  <Flex
                    flexDirection="column"
                    minWidth="196px"
                    maxWidth="196px"
                    padding="s"
                    mr="m"
                    boxShadow="small"
                    borderRadius="2px"
                    border="1px solid #5E54FF"
                    key={artwork?.id}
                  >
                    <ImageWithCache
                      image={firstImage}
                      artworkId={artwork?.id}
                      urlSuffix="/preview.jpg"
                      width="100%"
                      userselect="none"
                    />
                    <Text mb="xxxs">{artwork?.artist}</Text>
                    <Flex>
                      <Text
                        fontSize="small"
                        color="grey.80"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxWidth={artwork?.artworkValues?.year ? "13ch" : "19ch"}
                      >
                        {artwork?.artworkValues?.title}
                      </Text>
                      {artwork?.artworkValues?.year && (
                        <Text fontSize="small" color="grey.80">
                          {`, ${artwork?.artworkValues?.year}`}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </Box>
          <Button buttonStyle="primary" size="medium" fontWeight="500" onClick={handleButtonClick}>
            {isUser ? ButtonLabels.goToArtworks : ButtonLabels.startAccountAndAddArtworks}
          </Button>
        </Box>
      </Box>
      {/* Right Side */}
      <Flex
        flexDirection="column"
        backgroundColor="blue.60"
        color="white"
        padding="xxl"
        justifyContent="end"
        borderRadius="0px 10px 10px 0px"
      >
        <Text fontFamily="bodyDmSans" fontSize="heading3">
          {Headings.nextSteps}
        </Text>
        {nextSteps.map((step) => (
          <Flex key={step.title} mt="m" alignItems="start">
            <Box borderRadius="50%" backgroundColor="white" padding="xxxs" mr="s">
              <Icon icon={step.icon} size="16" color="blue.60" />
            </Box>
            <Box>
              <Text fontWeight="500" mb="xxxs">
                {step.title}
              </Text>
              <Text fontSize="small">{step.description}</Text>
            </Box>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export { PaymentInviteBuyerConfirmationModal };
