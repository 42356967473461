import React from "react";
import { connect, useDispatch } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Button, Input, Checkbox } from "../../elements";
import { Flex, Box, Text } from "../../fundamentals";
import { StripeCardNumberInput, StripeCardExpiryInput, StripeCardCvcInput } from "../../elements";
import { InputPlaceholders, ButtonLabels, InputErrors, InputLabels, UiLabels, LinkLabels } from "../../../localisation";
import { Box as MuiBox } from "@mui/material";
import { initiateInvitePayment, openModal } from "../../../store/actions";
import { useStripe, useElements } from "@stripe/react-stripe-js";

// const paymentMethods = {
//   credit_card: {
//     value: "credit_card",
//     label: "Credit Card",
//     poweredBy: "Stripe",
//   },
//   bank_transfer: {
//     value: "bank_transfer",
//     label: "Bank Transfer",
//     poweredBy: "Klarna",
//   },
// };

// const paymentMethodStyle = {
//   fontSize: "14px",
//   fontWeight: "500",
// };

// Temporarily used with cc details only

const Form = ({
  handleSubmit,
  submitting,
  paymentMethod,
  paymentInviteId,
  amountWithoutFee,
  currency,
  isBillingAddressSame,
  agreeToTerms,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const submit = (values, dispatch) => {
    if (submitting) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    const amount = amountWithoutFee * 100; //Base in cents

    if (!amount) {
      return;
    }

    return dispatch(
      initiateInvitePayment.request({
        ...values,
        stripe,
        elements,
        paymentInviteId,
        amount,
        currency,
      })
    );
  };

  const handleRejectClick = () => {
    dispatch(openModal("payment_invite_buyer_reject", [paymentInviteId]));
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      {/* Shipping details */}
      <Box fontSize="small" mb="m">
        <Text color="blue.60" fontWeight="500" mb="xs">
          {UiLabels.shippingAddress}
        </Text>
        <Field name="shippingFullName" placeholder={InputLabels.fullName} component={Input} minHeight="50px" required />
        <Field
          name="shippingAddress1"
          placeholder={InputLabels.addressLine1}
          component={Input}
          minHeight="50px"
          required
        />
        <Field name="shippingAddress2" placeholder={InputLabels.addressLine2} component={Input} minHeight="50px" />
        <Flex>
          <Field
            name="shippingCity"
            placeholder={InputLabels.city}
            component={Input}
            minHeight="50px"
            width="70%"
            mr="xxs"
            required
          />
          <Field
            name="shippingPostcode"
            placeholder={InputLabels.postCode}
            component={Input}
            minHeight="50px"
            required
          />
        </Flex>
        <Field name="shippingCountry" placeholder={InputLabels.country} component={Input} minHeight="50px" required />
      </Box>
      {/* Billing details */}
      <Box fontSize="small" mb="xxl">
        <Flex justifyContent="space-between" alignItems="center" mb="xs">
          <Text color="blue.60" fontWeight="500">
            {UiLabels.billingAddress}
          </Text>
          <Field
            name="isBillingAddressSame"
            label={InputLabels.sameAsShippingAddress}
            labelSize="small"
            component={Checkbox}
            marginRight="0"
          />
        </Flex>
        {!isBillingAddressSame && (
          <Box>
            <Field
              name="billingFullName"
              placeholder={InputLabels.fullName}
              component={Input}
              minHeight="50px"
              required
            />
            <Field
              name="billingAddress1"
              placeholder={InputLabels.addressLine1}
              component={Input}
              minHeight="50px"
              required
            />
            <Field name="sbillingddress2" placeholder={InputLabels.addressLine2} component={Input} minHeight="50px" />
            <Flex>
              <Field
                name="billingCity"
                placeholder={InputLabels.city}
                component={Input}
                minHeight="50px"
                width="70%"
                mr="xxs"
                required
              />
              <Field
                name="billingPostcode"
                placeholder={InputLabels.postCode}
                component={Input}
                minHeight="50px"
                required
              />
            </Flex>
            <Field
              name="billingCountry"
              placeholder={InputLabels.country}
              component={Input}
              minHeight="50px"
              required
            />
          </Box>
        )}
      </Box>
      {/* Credit card details */}
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="small" color="grey.80">
          {InputLabels.creditCardDetails}
        </Text>
        <MuiBox
          component="img"
          alt="stripe badge"
          src={process.env.PUBLIC_URL + "/images/icons/other-icons/stripe-badge.svg"}
          height="32px"
        ></MuiBox>
      </Flex>
      <Box mt="m">
        <Field
          name="cardNumber"
          height="50px"
          component={StripeCardNumberInput}
          options={{ placeholder: InputPlaceholders.creditCardNumber }}
        />

        <Flex justifyContent="space-between" mt="s" mb="m">
          <Field name="cardExpiryDate" height="50px" component={StripeCardExpiryInput} />
          <Field name="cardCvc" height="50px" component={StripeCardCvcInput} />
        </Flex>
      </Box>
      <Field
        name="agreeToTerms"
        label={InputLabels.agreeTo}
        labelSize="small"
        linkText={LinkLabels.termsAndConditions}
        linkUrl="https://www.simplify.art/imprint#terms-conditions"
        component={Checkbox}
      />

      <Flex mt="xl">
        <Button
          type="submit"
          mr="xs"
          buttonStyle="primary"
          size="medium"
          fontWeight="500"
          disabled={submitting || !agreeToTerms}
          icon={submitting ? "Loader" : null}
          iconSpinning={true}
          iconOffset="xs"
        >
          {ButtonLabels.pay}
        </Button>
        <Button buttonStyle="secondary" type="button" size="medium" onClick={handleRejectClick}>
          {ButtonLabels.reject}
        </Button>
      </Flex>
      {!agreeToTerms && (
        <Text color="grey.80" fontSize="smaller" mt="xs">Please agree to Terms & Conditions to complete payment</Text>
      )}
    </form>
  );
};
// Temporarily not used

// const Form = ({ handleSubmit, submitting, paymentMethod }) => {
//   console.log(paymentMethod);
//   return (
//     <form>
//       <Flex justifyContent="space-between">
//         <Box>
//           <Field
//             name="payment_method"
//             component={RadioSelect}
//             // customItemComponent={RenderPaymentMethod}
//             options={paymentMethods}
//             // sx={paymentMethodStyle}
//           />
//         </Box>
//         <Box>
//           <Text mb="xxl">Stripe</Text>
//           <Text>Klarna</Text>
//         </Box>
//       </Flex>
//       {paymentMethod === "credit_card" && (
//         <Box mt="m">
//           <Field
//             name="cardNumber"
//             height="50px"
//             component={StripeCardNumberInput}
//             options={{ placeholder: InputPlaceholders.creditCardNumber }}
//           />

//           <Flex justifyContent="space-between" mt="s">
//             <Field
//                 name="cardExpiryDate"
//                 height="50px"
//                 component={StripeCardExpiryInput}
//                 />
//             <Field name="cardCvc" height="50px" component={StripeCardCvcInput} />
//           </Flex>
//         </Box>
//       )}

//       <Flex mt="xl">
//         <Button
//             mr="xs"
//             buttonStyle="primary"
//             size="small"
//             fontWeight="500"
//             disabled={submitting || !paymentMethod}
//         >
//           {paymentMethod === "bank_transfer"? ButtonLabels.proceedToBankTransfer : ButtonLabels.pay}
//         </Button>
//         <Button buttonStyle="secondary" size="small">
//           {ButtonLabels.reject}
//         </Button>
//       </Flex>
//     </form>
//   );
// };

const validate = (values) => {
  const errors = {};

  const {
    shippingFullName,
    shippingAddress1,
    shippingCity,
    shippingPostcode,
    shippingCountry,
    billingFullName,
    billingAddress1,
    billingCity,
    billingPostcode,
    billingCountry,
    isBillingAddressSame,
    ...restValues
  } = values;

  if (!shippingFullName) {
    errors.shippingFullName = InputErrors.noName;
  }
  if (!shippingAddress1) {
    errors.shippingAddress1 = InputErrors.noAddress;
  }
  if (!shippingCity) {
    errors.shippingCity = InputErrors.noCity;
  }
  if (!shippingPostcode) {
    errors.shippingPostcode = InputErrors.noPostalCode;
  }
  if (!shippingCountry) {
    errors.shippingCountry = InputErrors.noCountry;
  }
  if (!isBillingAddressSame) {
    if (!billingFullName) {
      errors.billingFullName = InputErrors.noName;
    }
    if (!billingAddress1) {
      errors.billingAddress1 = InputErrors.noAddress;
    }
    if (!billingCity) {
      errors.billingCity = InputErrors.noCity;
    }
    if (!billingPostcode) {
      errors.billingPostcode = InputErrors.noPostalCode;
    }
    if (!billingCountry) {
      errors.billingCountry = InputErrors.noCountry;
    }
  }

  return errors;
};

let PaymentForm = reduxForm({
  form: "payment_invite.pay",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

const selector = formValueSelector("payment_invite.pay");

function mapStateToProps(state) {
  const paymentMethod = selector(state, "payment_method");
  const isBillingAddressSame = selector(state, "isBillingAddressSame");
  const agreeToTerms = selector(state, "agreeToTerms");
  return { paymentMethod, isBillingAddressSame, agreeToTerms };
}

PaymentForm = connect(mapStateToProps, null)(PaymentForm);

export { PaymentForm };
