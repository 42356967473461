import { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { ReactHookFormInput } from "../react-material/Input";
import { ReactHookFormDateInput } from "../react-material/DateInput";
import { ReactHookFormCheckBox } from "../react-material/Checkbox";
import {
  removeSelectedArtwork,
  openModal,
  addActionToQueue,
  createViewingRoom,
  saveViewingRoomDraft,
  editViewingRoomRequest,
} from "../../store/actions";
import { selectSelectedArtworkIds, selectArtworksById, selectUser } from "../../store/selectors";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../elements";
import { ButtonLabels, Headings, InputErrors, InputLabels } from "../../localisation";
import { Box, Flex, Text, ImageWithCache, Icon } from "../fundamentals";
import _ from "lodash";
import { v4 as uuid } from "uuid";

const fieldStyle = {
  flexGrow: 1,
  position: "relative",
  marginBottom: "-1px",
};

const labelStyle = {
  width: "100px",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#6A6870",
  fontFamily: "Inter",
};

const stackStyle = {
  borderBottom: "1px solid #C3C2C9",
  alignItems: "center",
  justifyContent: "space-between",
  height: "50px",
};

const RenderMessages = ({
  artworksWithoutYear,
  artworksWithoutTechnique,
  artworksWithoutPrice,
  artworksWithoutDimensions,
  artworksWithoutPublicLocation,
  artworksWithoutPackaging,
  watchShowPrice,
  watchAllowBuying,
}) => {
  return (
    <Box
      borderRadius="8px"
      p="m"
      border="1px solid red"
      backgroundColor="#FFDFDA"
      mb="m"
      maxWidth="704px"
      minWidth="500px"
    >
      <Flex alignItems="start">
        <Icon icon="AlertCircle" size="18" mr="xxs" />
        <Box>
          <Text fontSize="small" fontWeight="500" mb="xs">
            Required Fields Missing
          </Text>
          <Text fontSize="smaller" color="grey.80" mb="s">
            The marked artworks are missing some details. Go to the relevant Artwork Details page of each artwork and
            update the following fields:
          </Text>
          <Box fontSize="smaller">
            {artworksWithoutYear.length > 0 && (
              <Flex justifyContent="space-between" mb="xxs">
                <Flex mr="s">
                  <Text mr="xxxs" fontWeight="500">
                    Year
                  </Text>
                  <Text color="grey.80">(General Information):</Text>
                </Flex>
                <Text mr="s" textAlign="end">
                  {artworksWithoutYear.map((artwork) => artwork.artworkValues.title).join(", ")}
                </Text>
              </Flex>
            )}
            {artworksWithoutTechnique.length > 0 && (
              <Flex justifyContent="space-between" mb="xxs">
                <Flex mr="s">
                  <Text mr="xxxs" fontWeight="500">
                    Techninque
                  </Text>
                  <Text color="grey.80">(General Information):</Text>
                </Flex>
                <Text mr="s" textAlign="end">
                  {artworksWithoutTechnique.map((artwork) => artwork.artworkValues.title).join(", ")}
                </Text>
              </Flex>
            )}
            {artworksWithoutDimensions.length > 0 && (
              <Flex justifyContent="space-between" mb="xxs">
                <Flex mr="s">
                  <Text mr="xxxs" fontWeight="500">
                    Dimensions
                  </Text>
                  <Text color="grey.80">(General Information):</Text>
                </Flex>
                <Text mr="s" textAlign="end">
                  {artworksWithoutDimensions.map((artwork) => artwork.artworkValues.title).join(", ")}
                </Text>
              </Flex>
            )}
            {watchShowPrice && artworksWithoutPrice.length > 0 && (
              <Flex justifyContent="space-between" mb="xxs">
                <Flex mr="s">
                  <Text mr="xxxs" fontWeight="500">
                    Public Price
                  </Text>
                  <Text color="grey.80">(Public Details):</Text>
                </Flex>
                <Text mr="s" textAlign="end">
                  {artworksWithoutPrice.map((artwork) => artwork.artworkValues.title).join(", ")}
                </Text>
              </Flex>
            )}
            {watchAllowBuying && artworksWithoutPublicLocation.length > 0 && (
              <Flex justifyContent="space-between" mb="xxs">
                <Flex mr="s">
                  <Text mr="xxxs" fontWeight="500">
                    Public Location
                  </Text>
                  <Text color="grey.80">(Public Details):</Text>
                </Flex>
                <Text mr="s" textAlign="end">
                  {artworksWithoutPublicLocation.map((artwork) => artwork.artworkValues.title).join(", ")}
                </Text>
              </Flex>
            )}
            {watchAllowBuying && artworksWithoutPackaging.length > 0 && (
              <Flex justifyContent="space-between" mb="xxs">
                <Flex mr="s">
                  <Text mr="xxxs" fontWeight="500">
                    Packaging
                  </Text>
                  <Text color="grey.80">(Additional Information):</Text>
                </Flex>
                <Text mr="s" textAlign="end">
                  {artworksWithoutPackaging.map((artwork) => artwork.artworkValues.title).join(", ")}
                </Text>
              </Flex>
            )}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export const ViewingRoomForm = ({ handleSubmit, isSubmitting, setValue, watch, control, errors, viewingRoom }) => {
  const dispatch = useDispatch();
  const selectedArtworkIds = useSelector(selectSelectedArtworkIds);
  const selectedArtworks = useSelector((state) => selectArtworksById(state, selectedArtworkIds));
  const user = useSelector(selectUser);

  const watchTitle = watch("title");
  const watchShowPrice = watch("showPrice");
  const watchAllowBuying = watch("allowBuying");
  const watchHasPassword = watch("hasPassword");

  const titleError = _.get(errors, "title");
  const passwordError = _.get(errors, "password");
  const dateError = _.get(errors, "dateValid");

  useEffect(() => {
    if (!watchShowPrice) {
      setValue("allowBuying", false);
    }
    if (!watchAllowBuying) {
      setValue("allowOffer", false);
    }
  }, [watchShowPrice, watchAllowBuying]);

  const artworksWithoutYear = selectedArtworks.filter((artwork) => !artwork.artworkValues.year);
  const artworksWithoutTechnique = selectedArtworks.filter((artwork) => !artwork.artworkValues.technique);
  const artworksWithoutPrice = selectedArtworks.filter((artwork) => !artwork.artworkValues.publicPrice);
  const artworksWithoutDimensions = selectedArtworks.filter(
    (artwork) =>
      !artwork.artworkValues.dimensions ||
      Object.values(artwork.artworkValues.dimensions).some((value) => value === null) ||
      (!artwork.artworkValues.dimensions.width && !artwork.artworkValues.dimensions.diameter && !artwork.artworkValues.dimensions.variable)
  );
  const artworksWithoutPublicLocation = selectedArtworks.filter(
    (artwork) =>
      !artwork.artworkValues.publicLocation ||
      !artwork.artworkValues.publicLocation.organisationName ||
      !artwork.artworkValues.publicLocation.firstName ||
      !artwork.artworkValues.publicLocation.lastName ||
      !artwork.artworkValues.publicLocation.phone ||
      !artwork.artworkValues.publicLocation.address ||
      !artwork.artworkValues.publicLocation.address.addressLine1 ||
      !artwork.artworkValues.publicLocation.address.city ||
      !artwork.artworkValues.publicLocation.address.country ||
      !artwork.artworkValues.publicLocation.address.postalCode
  );

  const artworksWithoutPackaging = selectedArtworks.filter(
    (artwork) =>
      !artwork.artworkValues.packaging ||
      !artwork.artworkValues.packaging.crates ||
      !artwork.artworkValues.packaging.crates.length ||
      artwork.artworkValues.packaging.crates.some(
        (crate) =>
          !crate.dimensions ||
          !crate.dimensions.height ||
          !crate.dimensions.width ||
          !crate.dimensions.depth ||
          !crate.weight.value
      )
  );

  const isMissingFields =
    //Year, Technique and Dimensions are always required for the creation of a Viewing Room
    artworksWithoutYear.length > 0 ||
    artworksWithoutTechnique.length > 0 ||
    artworksWithoutDimensions.length > 0 ||
    //Public Price is required when Show Price is selected
    (watchShowPrice && artworksWithoutPrice.length > 0) ||
    //Public Location and Packaging are required when Allow Buying is selected
    (watchAllowBuying && (artworksWithoutPublicLocation.length > 0 || artworksWithoutPackaging.length > 0));

  const checkArtworkForMissingValues = (artwork) => {
    if (
      !artwork.artworkValues.year ||
      !artwork.artworkValues.technique ||
      !artwork.artworkValues.dimensions ||
      Object.values(artwork.artworkValues.dimensions).some((value) => value === null) ||
      (!artwork.artworkValues.dimensions.width && !artwork.artworkValues.dimensions.diameter && !artwork.artworkValues.dimensions.variable)
    ) {
      return true;
    } else if (watchShowPrice && !watchAllowBuying) {
      if (!artwork.artworkValues.publicPrice) {
        return true;
      }
    } else if (watchShowPrice && watchAllowBuying) {
      if (
        !artwork.artworkValues.publicLocation ||
        !artwork.artworkValues.publicLocation.organisationName ||
        !artwork.artworkValues.publicLocation.firstName ||
        !artwork.artworkValues.publicLocation.lastName ||
        !artwork.artworkValues.publicLocation.phone ||
        !artwork.artworkValues.publicLocation.address ||
        !artwork.artworkValues.publicLocation.address.addressLine1 ||
        !artwork.artworkValues.publicLocation.address.city ||
        !artwork.artworkValues.publicLocation.address.country ||
        !artwork.artworkValues.publicLocation.address.postalCode ||
        !artwork.artworkValues.packaging ||
        !artwork.artworkValues.packaging.crates ||
        !artwork.artworkValues.packaging.crates.length ||
        artwork.artworkValues.packaging.crates.some(
          (crate) =>
            !crate.dimensions ||
            !crate.dimensions.height ||
            !crate.dimensions.width ||
            !crate.dimensions.depth ||
            !crate.weight.value
        )
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleRemoveSelectedArtworkClick = (artwork) => {
    dispatch(removeSelectedArtwork(artwork.id));
  };

  const handleSeeArtworkDetailsClick = (artwork) => {
    dispatch(openModal("artwork_details", artwork));
  };

  const handleCreateViewingRoom = (values, event) => {
    event.preventDefault();
    const tempViewingRoomId = uuid();
    return dispatch(
      addActionToQueue(
        createViewingRoom(
          {
            artworks: selectedArtworks,
            ...values,
          },
          tempViewingRoomId
        ),
        tempViewingRoomId
      )
    );
  };

  const handleSaveViewingRoomDraft = (values, event) => {
    event.preventDefault();
    const tempViewingRoomId = uuid();
    //Create a new Viewing Room draft if the user saves a new Viewing Room as a draft
    if (!viewingRoom) {
      return dispatch(
        addActionToQueue(
          saveViewingRoomDraft(
            {
              artworks: selectedArtworks,
              ...values,
            },
            tempViewingRoomId
          ),
          tempViewingRoomId
        )
      );
      // Edit the existing Viewing Room if the user edits an existing Viewing Room and saves it as a draft again
    } else {
      return dispatch(
        editViewingRoomRequest({
          _id: viewingRoom._id,
          selectedArtworks,
          ...values,
          isActive: false,
          wasViewingRoomDraft: !viewingRoom?.isActive,
        })
      );
    }
  };

  const handleEditViewingRoom = (values, event) => {
    event.preventDefault();
    return dispatch(
      editViewingRoomRequest({
        _id: viewingRoom._id,
        selectedArtworks,
        ...values,
        isActive: true,
        wasViewingRoomDraft: !viewingRoom?.isActive,
      })
    );
  };

  return (
    <form>
      <Box>
        <Box pl="xxl" pr="xxl" pb="m">
          <Flex flexDirection="column" width="100%">
            {/* Submit Buttons */}
            <Box mb="m">
              <Flex alignItems="center" justifyContent="end">
                {/* Save as Draft option only for non-active Viewing Rooms */}
                {!viewingRoom?.isActive && (
                  <Button
                    buttonStyle="secondary"
                    alignSelf="end"
                    fontWeight="500"
                    size="medium"
                    icon="Edit"
                    mr="s"
                    onClick={handleSubmit(handleSaveViewingRoomDraft)}
                  >
                    {ButtonLabels.saveAsDraft}
                  </Button>
                )}
                <Button
                  type="button"
                  onClick={!viewingRoom ? handleSubmit(handleCreateViewingRoom) : handleSubmit(handleEditViewingRoom)}
                  buttonStyle="primary"
                  alignSelf="end"
                  fontWeight="500"
                  size="medium"
                  icon={isSubmitting ? "Loader" : null}
                  disabled={isSubmitting || !watchTitle || selectedArtworkIds.length < 2 || isMissingFields}
                  iconSpinning={true}
                  flip={true}
                  iconOffset="xs"
                >
                  {!viewingRoom || (viewingRoom && !viewingRoom.isActive) ? ButtonLabels.create : ButtonLabels.update}
                </Button>
              </Flex>
              {selectedArtworkIds.length < 2 && (
                <Flex justifyContent="end" mt="xxs">
                  <Text fontSize="smaller" color="grey.80">
                    Please select at least 2 artworks to continue
                  </Text>
                </Flex>
              )}
            </Box>
            {selectedArtworks.length > 0 && isMissingFields && (
              <Box>
                <RenderMessages
                  artworksWithoutYear={artworksWithoutYear}
                  artworksWithoutTechnique={artworksWithoutTechnique}
                  artworksWithoutPrice={artworksWithoutPrice}
                  artworksWithoutDimensions={artworksWithoutDimensions}
                  artworksWithoutPublicLocation={artworksWithoutPublicLocation}
                  artworksWithoutPackaging={artworksWithoutPackaging}
                  watchShowPrice={watchShowPrice}
                  watchAllowBuying={watchAllowBuying}
                />
              </Box>
            )}
            <Flex alignItems="start" overflowY="auto">
              {/* Form Container */}
              <Box width="40%" minWidth="420px" mr="30px">
                <Box borderRadius="7px" p="l" border="1px solid #C3C2C9">
                  <Stack direction="column">
                    <Text size="heading3" mb="s">
                      {Headings.information}
                    </Text>
                    <Box marginBottom={errors.title && "20px"}>
                      <Stack sx={stackStyle} direction="row">
                        <Typography sx={labelStyle}>{InputLabels.title}*</Typography>
                        <ReactHookFormInput
                          name="title"
                          control={control}
                          errors={errors}
                          isRequired
                          errorMessage={InputErrors.noTitle}
                          type="text"
                          customRootStyles={{
                            ...fieldStyle,
                            bottom: titleError && "-12px",
                            "&:hover": {
                              marginBottom: titleError ? "-2px" : "-1px",
                              height: titleError ? null : "49px",
                            },
                          }}
                          customInputStyles={{ textAlign: "right" }}
                          setValue={setValue}
                        />
                      </Stack>
                    </Box>
                    <ReactHookFormCheckBox
                      name="showWorkDescription"
                      control={control}
                      errors={errors}
                      size="14"
                      label={InputLabels.showWorkDescription}
                      description="Viewing Rooms uses the Public Work Description"
                      descriptionSize="12px"
                    />
                    <ReactHookFormCheckBox
                      name="showPrice"
                      control={control}
                      errors={errors}
                      size="14"
                      label={InputLabels.showPrice}
                      description="Viewing Rooms uses the Public Price not the Internal List Price."
                      descriptionSize="12px"
                    />
                    <ReactHookFormCheckBox
                      name="allowBuying"
                      control={control}
                      errors={errors}
                      size="14"
                      label={InputLabels.allowPurchase}
                      disabled={!watchShowPrice || !user.account.idVerified || !user.account.marketplaceOnboarded}
                    />
                    <ReactHookFormCheckBox
                      name="allowOffer"
                      control={control}
                      errors={errors}
                      label={InputLabels.allowOffer}
                      size="14"
                      disabled={!watchAllowBuying}
                    />
                    <ReactHookFormCheckBox
                      name="hasPassword"
                      control={control}
                      errors={errors}
                      label={InputLabels.setAsPrivate}
                      size="14"
                      disabled={viewingRoom}
                    />
                    {watchHasPassword && (
                      <Box marginBottom={errors.password && "20px"}>
                        <Stack sx={{ ...stackStyle, marginBottom: "18px" }} direction="row">
                          <Typography sx={labelStyle}>{InputLabels.password}*</Typography>
                          <ReactHookFormInput
                            name="password"
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            isRequired={watchHasPassword}
                            errorMessage={InputErrors.noPassword}
                            type="password"
                            disabled={viewingRoom}
                            customRootStyles={{
                              ...fieldStyle,
                              bottom: passwordError && "-12px",
                              "&:hover": {
                                marginBottom: passwordError ? "-2px" : "-1px",
                                height: passwordError ? null : "49px",
                              },
                            }}
                            customInputStyles={{ textAlign: "right" }}
                          />
                        </Stack>
                      </Box>
                    )}
                    <Box marginBottom={errors.dateValid && "20px"}>
                      <Stack sx={{ ...stackStyle, borderBottom: "none" }} direction="row">
                        <Typography sx={labelStyle}>{InputLabels.validUntil}*</Typography>
                        <ReactHookFormDateInput
                          name="dateValid"
                          control={control}
                          disablePast={true}
                          isRequired
                          outline
                          errorMessage={dateError?.type === "required" ? InputErrors.noDate : InputErrors.futureDate}
                          customInputStyles={{ maxWidth: "110px", fontSize: "14px" }}
                        />
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              </Box>
              {/* Artworks container */}
              <Flex
                flexDirection="column"
                justifyContent="space-between"
                width="60%"
                minWidth="300px"
                maxHeight="70vh"
                overflow="auto"
                p="l"
                borderRadius="7px"
                border="1px solid #C3C2C9"
              >
                <Text size="heading3" mb="l">
                  {Headings.artworks}
                </Text>
                <Flex flexWrap="wrap">
                  {_.map(selectedArtworks, (artwork, index) => {
                    const { images = [] } = artwork;
                    const [firstImage = {}] = images;
                    return (
                      <Flex justifyContent="space-between" alignItems="start" mb="xxl" key={artwork.id}>
                        {/* Image */}
                        <Box
                          position="relative"
                          height="285px"
                          padding="s"
                          mr={index === selectedArtworks.length - 1 ? null : "s"}
                          boxShadow="small"
                          borderRadius="2px"
                          border={checkArtworkForMissingValues(artwork) && "1px solid red"}
                        >
                          {artwork.images.length ? (
                            <ImageWithCache
                              image={firstImage}
                              artworkId={artwork.id}
                              urlSuffix="/preview.jpg"
                              maxHeight="176px"
                              userSelect="none"
                              mb="s"
                              imageKey={artwork.id}
                            />
                          ) : (
                            <Box pt="l" minWidth="130px" height="176px" mb="s">
                              <Text color="grey.60" fontSize="smaller">
                                No image available
                              </Text>
                            </Box>
                          )}
                          <Box maxWidth="100%">
                            <Text mb="xxxs" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                              {artwork.artist}
                            </Text>
                          </Box>
                          <Flex mb="xxxs">
                            <Box maxWidth={artwork.artworkValues.year ? "60%" : "100%"}>
                              <Text
                                fontSize="small"
                                color="grey.80"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {artwork.artworkValues.title}
                              </Text>
                            </Box>
                            {artwork.artworkValues.year && (
                              <Text fontSize="small" color="grey.80">
                                {`, ${artwork.artworkValues.year}`}
                              </Text>
                            )}
                          </Flex>
                          <Button
                            type="button"
                            buttonStyle="text"
                            icon="Maximize2"
                            iconSize="12"
                            pl="0"
                            onClick={() => handleSeeArtworkDetailsClick(artwork)}
                          >
                            {ButtonLabels.seeDetails}
                          </Button>
                          <Flex flexDirection="column" position="absolute" top="xxs" right="xxs" pointerEvents="none">
                            <Button
                              buttonStyle="primary"
                              size="small"
                              icon="X"
                              iconStrokeWidth="3px"
                              onClick={() => handleRemoveSelectedArtworkClick(artwork)}
                              mb="xxs"
                            ></Button>
                          </Flex>
                        </Box>
                      </Flex>
                    );
                  })}
                </Flex>
                <Button
                  type="button"
                  buttonStyle="secondary"
                  size="small"
                  icon="Plus"
                  iconStrokeWidth="3px"
                  onClick={() => dispatch(openModal("additional_artworks"))}
                  alignSelf="end"
                >
                  {ButtonLabels.addArtworks}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </form>
  );
};
