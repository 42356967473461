import React from "react";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import {
  Headings,
  ButtonLabels,
  InputPlaceholders,
  UiLabels,
  DynamicLabels,
  Messages,
  InputErrors,
} from "../../localisation";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Flex, Text, Box } from "../fundamentals";
import {
  Button,
  StripeCardNumberInput,
  StripeCardExpiryInput,
  StripeCardCvcInput,
  RadioSelect,
} from "../elements";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { createSubscription } from "../../store/actions";
import { returnSelectedPlan, returnStripePlansBasedOnUserRole } from "../../utils";
import { selectUser } from "../../store/selectors";

const renderPaymentPlan = ({ input, label, itemValue, price, discount, billing_period, mr, ...props }) => {
  const selected = input.value === itemValue;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      border="2px solid black"
      borderRadius="15px"
      width="130px"
      height="100px"
      cursor="pointer"
      bg={selected ? "#5E54FF" : undefined}
      color={selected ? "white" : undefined}
      onClick={() => input.onChange(itemValue)}
      mr={mr}
    >
      <Text fontWeight="700" size="30px">
        {"\u20ac" + price}
        <Text size="small" fontWeight="700">
          /mo
        </Text>
      </Text>
      <Box mt="xxxs">
        <Text size="smaller" fontWeight="600">
          {billing_period.toUpperCase()}
        </Text>
        {discount && (
          <Text size="smaller" fontWeight="600" color={selected ? "white" : "blue.60"}>
            {"save " + discount + "%"}
          </Text>
        )}
      </Box>
    </Box>
  );
};

const Form = ({ handleSubmit, submitting, plan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const user = useSelector(selectUser);

  const submit = (values, dispatch) => {
    if (submitting) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    return dispatch(createSubscription.request({ stripe, elements, plan: values.plan }));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Flex
        flexDirection="column"
        alignItems="center"
        width="800px"
        height="620px"
        bg="white"
        pt="m"
        boxShadow="medium"
        borderRadius="20px"
      >
        <Text size="heading2" mb="l" fontWeight="700">
          {Headings.choosePlanFreeTrial}
        </Text>
        <Flex justifyContent="space-between" width="40%">
          <Field
            name="plan"
            options={returnStripePlansBasedOnUserRole(user.role)}
            direction="horizontal"
            customItemComponent={renderPaymentPlan}
            component={RadioSelect}
          />
        </Flex>

        <Flex justifyContent="space-between" width="90%" mt="l">
          {/* Left side */}

          <Box width="45%">
            <Field
              name="cardNumber"
              component={StripeCardNumberInput}
              options={{ placeholder: InputPlaceholders.creditCardNumber }}
            />

            <Flex justifyContent="space-between" mt="s">
              <Field name="cardExpiryDate" component={StripeCardExpiryInput} />
              <Field name="cardCvc" component={StripeCardCvcInput} />
            </Flex>
          </Box>

          {/* Right Side */}

          <Box width="45%">
            <Text fontFamily="body" size="smaller" fontWeight="600" mb="s">
              {Headings.orderSummary.toUpperCase()}
            </Text>

            <Flex justifyContent="space-between" fontWeight="700">
              <Text size="small">{`${returnSelectedPlan(plan).label} ${UiLabels.plan}`}</Text>
              <Text size="small">{"\u20ac" + returnSelectedPlan(plan).price}</Text>
            </Flex>

            <Box border="2px solid black" my="s"></Box>

            <Flex justifyContent="space-between" mb="xxxs">
              <Text size="small">{UiLabels.subTotal}</Text>
              <Text size="small">{"\u20ac" + returnSelectedPlan(plan).subtotal}</Text>
            </Flex>

            <Flex justifyContent="space-between" mb="xxxs">
              <Text size="small">{UiLabels.vat.toUpperCase()}</Text>
              <Text size="small">{"\u20ac" + returnSelectedPlan(plan).vat}</Text>
            </Flex>

            <Flex justifyContent="space-between">
              <Text size="small" color={"blue.80"}>
                {DynamicLabels.numFreeTrial(30)}
              </Text>
              <Text size="small" color={"blue.80"}>
                - {"\u20ac" + returnSelectedPlan(plan).price}
              </Text>
            </Flex>

            <Box border="2px solid black" my="s"></Box>

            <Flex justifyContent="space-between" fontWeight="700" mb="xxxs">
              <Text size="small">{UiLabels.dueNow}</Text>
              <Text size="small">{"\u20ac" + 0}</Text>
            </Flex>

            <Flex justifyContent="space-between" mb="xxxs">
              <Text size="small">{DynamicLabels.dateStartingToPay(moment().add(30, "days"))}</Text>
              <Text size="small">{"\u20ac" + returnSelectedPlan(plan).price}</Text>
            </Flex>

            <Text size="smaller" width="80%">
              {`${Messages.trialMessage(30)}. ${Messages.paymentMessage}.`}
            </Text>

            <Button
              buttonStyle="default"
              size="medium"
              type="submit"
              disabled={submitting}
              icon={submitting ? "Loader" : null}
              iconSpinning={true}
              flip={true}
              iconOffset="xs"
              mt="l"
              alignSelf="center"
              cursor={plan ? "pointer" : "default"}
            >
              {ButtonLabels.startFreeTrial}
            </Button>
          </Box>
        </Flex>
      </Flex>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.plan) errors.plan = InputErrors.noPlan;
  return errors;
};

let PaymentForm = reduxForm({
  form: "pay",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

const selector = formValueSelector("pay");

function mapStateToProps(state) {
  const role = selector(state, "role");
  const plan = selector(state, "plan");

  return { role, plan };
}

PaymentForm = connect(mapStateToProps, null)(PaymentForm);

export { PaymentForm };
