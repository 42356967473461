import React from "react";
import styled from "styled-components";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectFade, Scrollbar } from "swiper";

// import Swiper from "react-id-swiper";
import { Box } from "../fundamentals";

const StyledSwiper = styled(Box)`
  .swiper-wrapper {
    margin-bottom: 20px;
  }
  .swiper-slide {
    max-width: fit-content;
  }
  .swiper-scrollbar {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .swiper-scrollbar-drag {
    background: rgba(0, 0, 0, 0.3);
  }
`;

SwiperCore.use([Navigation, Pagination, EffectFade, Scrollbar]);

const SimplifySwiper = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledSwiper>
      <Swiper
        modules={[Navigation, Pagination, EffectFade, Scrollbar]}
        slidesPerView={"auto"}
        spaceBetween={20}
        effect={props.effect ?? "slide"}
        preventClicks={false}
        touchStartPreventDefault={false}
        scrollbar={{
          hide: false,
        }}
        watchOverflow
      >
        {React.Children.map(children, (child) => (
          <SwiperSlide>
            <Box width="fit-content" maxWidth="100%" mt="xs" mb={children.length > 1 ? "xs" : "none"}>
              {child}
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledSwiper>
  );
});

export { SimplifySwiper as Swiper };
