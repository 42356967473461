import { useEffect } from "react";
import { Box, Text, Flex } from "../fundamentals";
import { UiLabels } from "../../localisation";
import getSymbolFromCurrency from "currency-symbol-map";
import { useSelector } from "react-redux";
import { useIsScreenMobile } from "../hooks/useMediaQuery";

const PaymentInviteArtworkValuesTable = ({ paymentInvite, total, setTotal, hasShippingCosts, isShippingModal }) => {
  const isMobile = useIsScreenMobile();
  const transportMode = useSelector((state) => state.form.payment_invite?.update?.values?.transportMode);
  const isDiscount = paymentInvite?.artworks?.some((artwork) => artwork?.discounts?.length > 0);
  const currency = paymentInvite?.artworks?.reduce((acc, item) => {
    if (!acc && item.price.currency) {
      return item.price.currency;
    }
    return acc;
  }, null);
  const currencySymbol = getSymbolFromCurrency(currency);

  useEffect(() => {
    calculateTotal();
  }, []);

  const calculateAllArtworksSubtotal = () => {
    const totalArtworksValue = paymentInvite?.artworks?.reduce((total, artwork) => {
      const discountTotal = artwork.discounts.reduce((discountTotal, discount) => {
        return discountTotal + discount.value;
      }, 0);

      const artworkTotal = artwork.price.value - discountTotal;

      return total + artworkTotal;
    }, 0);
    if (isDiscount) {
      const originalPriceSubtotal = paymentInvite?.artworks?.reduce((total, artwork) => {
        return total + artwork.totalPublicPrice.value;
      }, 0);
      return Math.round(originalPriceSubtotal * 100) / 100;
    } else {
      return Math.round(totalArtworksValue * 100) / 100;
    }
  };

  const artworkSubtotal = calculateAllArtworksSubtotal();
  const processingFeePercentage = paymentInvite?.processingFee / 100;
  const processingFeeForDiscount = Math.round(artworkSubtotal * (1 - 1 / (1 + processingFeePercentage)) * 100) / 100;

  const calculateOriginalPriceSubtotal = () => {
    return Math.round((artworkSubtotal - processingFeeForDiscount) * 100) / 100;
  };

  const calculateTotalDiscount = () => {
    let totalDiscountAmount = 0;
    paymentInvite?.artworks?.forEach((artwork) => {
      const discountTotal = artwork.discounts.reduce((discountTotal, discount) => {
        return discountTotal + discount.value;
      }, 0);

      totalDiscountAmount += discountTotal;
    }, 0);
    return Math.round(totalDiscountAmount * 100) / 100;
  };

  const renderCosts = () => {
    let costs = [];
    if (paymentInvite?.costs?.length > 0) {
      if (isDiscount) {
        costs = paymentInvite.costs.filter((cost) => !cost.name.includes("Processing Fee"));
      } else {
        costs = paymentInvite.costs;
      }
      costs = costs.map((item, index) => {
        if (item.value) {
          return (
            <Flex justifyContent="space-between" mt="xs" key={index}>
              <Text>{item.name}</Text>
              <Text fontWeight="500">
                {currencySymbol}
                {item.value}
              </Text>
            </Flex>
          );
        }
      });
    }
    return costs;
  };

  const calculateTotal = () => {
    const totalArtworksValue = calculateAllArtworksSubtotal();

    // Calculate costs total
    const costsTotal = paymentInvite?.costs.reduce((total, cost) => {
      if (isDiscount && cost.name.includes("Processing Fee")) {
        return total;
      } else {
        return total + cost.value;
      }
    }, 0);
    if (isDiscount) {
      const totalDiscount = calculateTotalDiscount();
      setTotal(Math.round((totalArtworksValue + costsTotal - totalDiscount) * 100) / 100);
    } else {
      setTotal(Math.round((totalArtworksValue + costsTotal) * 100) / 100);
    }
  };

  return (
    <Box
      width={isMobile ? "100%" : "460px"}
      border="1px solid #C8CEFF"
      borderRadius="8px"
      padding={isMobile? "m" : "l"}
      mb="m"
      fontSize="small"
    >
      {isDiscount && (
        <Box pb="xs" mb="xs" borderBottom="1px solid #C3C2C9">
          <Flex justifyContent="space-between" mb="xs">
            <Text>{UiLabels.artwork}</Text>
            <Text fontWeight="500">
              {currencySymbol}
              {calculateOriginalPriceSubtotal()}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text>{`Processing Fee (${paymentInvite?.processingFee}%)`}</Text>
            <Text fontWeight="500">
              {currencySymbol}
              {processingFeeForDiscount}
            </Text>
          </Flex>
        </Box>
      )}
      <Flex justifyContent="space-between" borderBottom={!isDiscount && "1px solid #C3C2C9"} pb={!isDiscount && "xs"}>
        <Text>{UiLabels.artworkSubtotal}</Text>
        <Text fontWeight="500">
          {currencySymbol}
          {calculateAllArtworksSubtotal()}
        </Text>
      </Flex>
      <Box pb="xs" borderBottom="1px solid #C3C2C9">
        {isDiscount && (
          <Flex justifyContent="space-between" mt="xs">
            <Text>{UiLabels.discount}</Text>
            <Text fontWeight="500">
              -{currencySymbol}
              {calculateTotalDiscount()}
            </Text>
          </Flex>
        )}
        {paymentInvite?.costs?.length > 0 && <Box>{renderCosts()}</Box>}
        {transportMode === "simplifyShipping" && (
          <Flex justifyContent="space-between" mt="xs">
            <Text>{UiLabels.shippingCosts}</Text>
            <Text fontWeight="500">{UiLabels.calculatedNext}</Text>
          </Flex>
        )}
        {isShippingModal && !hasShippingCosts && (
          <Flex justifyContent="space-between" mt="xs">
            <Text mr="m">{UiLabels.shippingCosts}</Text>
            <Text fontWeight="500" color="red" textAlign="end">
              Will be sent via email
            </Text>
          </Flex>
        )}
      </Box>
      <Flex justifyContent="space-between" fontSize="body" fontWeight="500" mt="xs">
        <Text>{UiLabels.total}</Text>
        <Text color="blue.60">
          {currencySymbol}
          {total}
        </Text>
      </Flex>
    </Box>
  );
};

export { PaymentInviteArtworkValuesTable };
