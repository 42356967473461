import { InputValues, Headings, Instructions, InputLabels, UiLabels, ButtonLabels } from "../localisation";

export const DEFAULT_ARTWORK_STATUSES = {
  available: { label: InputValues.available, color: "#AAEE98" },
  reserved: { label: InputValues.reserved, color: "amber.30" },
  sold: { label: InputValues.sold, color: "#F1B8AB" },
  onsite: { label: InputValues.onsite, color: "#A1CEEE" },
  loan: { label: InputValues.onLoan, color: "#EAF39F" },
  transit: { label: InputValues.inTransit, color: "#E3C7A4" },
  production: { label: InputValues.inProduction, color: "#DFDFDF" },
};

export const SUPPORTED_CURRENCIES = ["EUR", "USD", "GBP", "CHF", "CNY", "HKD", "RUB"];

export const PAYMENT_INVITE_SUPPORTED_CURRENCIES = {
  EUR: { label: "EUR" },
  USD: { label: "USD"},
  GBP: { label: "GBP" },
};

export const DEFAULT_PAYMENT_INVITE_STATUSES_SELLER = {
  Created: { 
    label: UiLabels.awaitingPayment, 
    color: "#FFE49A",
    actions: [
      {id: "downloadInvoice", label: ButtonLabels.downloadInvoice},
      {id: "sendReminder", label: ButtonLabels.sendReminder},
    ] 
  },
  Viewed: { 
    label: UiLabels.awaitingPayment, 
    color: "#FFE49A",
    actions: [
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice },
      { id: "sendReminder", label: ButtonLabels.sendReminder },
    ] 
  },
  Rejected: { 
    label: UiLabels.rejected, 
    color: "#F1B8AB",
    actions: [
      { id: "duplicate", label: ButtonLabels.duplicate }
    ]
  },
  Paid: { 
    label: UiLabels.paidByBuyer, 
    color: "#A1CEEE",
    actions: [
      { id: "markAsInTransit", label: ButtonLabels.markAsInTransit },
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice }
    ]
  },
  Transit: { 
    label: UiLabels.transit,
    color: "#E3C7A4",
    actions: [
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice }
    ]
  },
  Delivered: { 
    label: UiLabels.delivered,
    color: "#EAF39F",
    actions: [
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice }
    ]
  },
  Disputed: { 
    label: UiLabels.disputed,
    color: "#F1B8AB",
    actions: [
      { id: "contactUs", label: ButtonLabels.contactUs},
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice }
    ]
  },
  Bounced: { 
    label: UiLabels.bounced,
    color: "#F1B8AB",
    actions: [
      { id: "updateEmail", label: ButtonLabels.updateEmail}
    ]
  },
  PaidOut: { 
    label: UiLabels.paidOut,
    color: "#AAEE98",
    actions: [
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice }
    ]
  },
  Duplicate: {
    label: UiLabels.duplicate,
    color: "#E8E7EA",
    actions : []
  },
  Support: { 
    label: UiLabels.awaitingSupport,
    color: "#eec098",
    actions: [
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice }
    ]
  },
  Offer: { 
    label: UiLabels.offerReceived,
    color: "#eec098",
    actions: [
      { id: "seeOffer", label: ButtonLabels.seeOffer }
    ]
  },
  Enquiry: { 
    label: UiLabels.enquiryReceived,
    color: "#d0b9ed",
    actions: [
      { id: "seeEnquiry", label: ButtonLabels.seeEnquiry }
    ]
  },
  EnquiryAnswered: {
    label: UiLabels.enquiryAnswered,
    color: "#e7daf7",
    actions : []
  },
};

export const DEFAULT_PAYMENT_INVITE_STATUSES_BUYER = {
  Created: { 
    label: UiLabels.awaitingPayment, 
    color: "#FFE49A",
    actions: [
      {id: "downloadInvoice", label: ButtonLabels.downloadInvoice},
      {id: "pay", label: ButtonLabels.pay},
    ] 
  },
  Viewed: { 
    label: UiLabels.awaitingPayment, 
    color: "#FFE49A",
    actions: [
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice },
      { id: "pay", label: ButtonLabels.pay },
    ] 
  },
  Rejected: { 
    label: UiLabels.rejected, 
    color: "#F1B8AB",
    actions: []
  },
  Paid: { 
    label: UiLabels.paid, 
    color: "#A1CEEE",
    actions: [
      { id: "dispute", label: ButtonLabels.dispute },
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice }
    ]
  },
  Transit: { 
    label: UiLabels.transit,
    color: "#E3C7A4",
    actions: [
      { id: "confirmDelivery", label: ButtonLabels.confirmDelivery },
      { id: "dispute", label: ButtonLabels.dispute },
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice }
    ]
  },
  Delivered: { 
    label: UiLabels.delivered,
    color: "#EAF39F",
    actions: [
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice }
    ]
  },
  Disputed: { 
    label: UiLabels.disputed,
    color: "#F1B8AB",
    actions: [
      { id: "contactUs", label: ButtonLabels.contactUs},
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice }
    ]
  },
  PaidOut: { 
    label: UiLabels.delivered,
    color: "#AAEE98",
    actions: [
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice }
    ]
  },
  Duplicate: {
    label: UiLabels.duplicate,
    color: "#E8E7EA",
    actions : []
  },
  Support: { 
    label: UiLabels.awaitingSupport,
    color: "#eec098",
    actions: [
      { id: "downloadInvoice", label: ButtonLabels.downloadInvoice }
    ]
  },
};

export const SORT_OPTIONS = {
  "*": { label: "All", sortOrder: "asc" },
  artist: { label: "Artist Name", sortOrder: "asc" },
  "artworkValues.year": { label: "Year", sortOrder: "desc" },
  "artworkValues.currentOwner": { label: "Current Owner", sortOrder: "desc" },
  "artworkValues.seriesTitle": { label: "Series Title", sortOrder: "desc" },
  status: { label: "Status", sortOrder: "asc" },
  dateCreated: { label: "Date Created", sortOrder: "desc" },
  lastModified: { label: "Last Updated", sortOrder: "desc" },
};

export const ARTWORK_FORM_SECTIONS = {
  artworkImages: { title: Headings.images, name: "images", subtitle: Instructions.addImages, mb: "m" },
  collection: {
    title: Headings.collection,
    name: "collection",
    minWidth: "280px",
    maxWidth: "500px",
    userSelect: "none",
    mb: "l",
  },
  generalInformation: {
    title: Headings.generalInformation,
    name: "generalInformation",
    minWidth: "280px",
    maxWidth: "500px",
    userSelect: "none",
    mb: "l",
  },
  publicDetails: {
    title: Headings.publicDetails,
    scrollSectionSubtitle: Headings.forDiscoveryAndViewingRooms,
    name: "publicDetails",
    minWidth: "280px",
    maxWidth: "500px",
    userSelect: "none",
    mb: "l",
  },
  artworkLocation: {
    title: Headings.location,
    name: "location",
    minWidth: "280px",
    maxWidth: "500px",
    userSelect: "none",
    mb: "l",
  },
  artworkValue: {
    title: Headings.value,
    name: "value",
    minWidth: "280px",
    maxWidth: "500px",
    userSelect: "none",
    mb: "l",
  },
  additionalInformation: {
    title: Headings.additionalInformation,
    name: "additionalInformation",
    minWidth: "280px",
    maxWidth: "500px",
    userSelect: "none",
    mb: "l",
  },
};

export const CONTACT_TYPES = [
  {
    label: InputLabels.artistType,
    value: "Artist",
  },
  {
    label: InputLabels.curator,
    value: "Curator",
  },
  {
    label: InputLabels.gallerist,
    value: "Gallerist",
  },
  
  {
    label: InputLabels.collector,
    value: "Collector",
  },
  {
    label: InputLabels.journalist,
    value: "Journalist",
  },
  {
    label: InputLabels.technician,
    value: "Technician",
  },
  {
    label: InputLabels.museum,
    value: "Museum",
  },
  {
    label: InputLabels.auction,
    value: "Auction house",
  },
  {
    label: InputLabels.writer,
    value: "Writer",
  },
  {
    label: InputLabels.historian,
    value: "Art historian",
  },
  {
    label: InputLabels.transport,
    value: "Transport",
  },
  {
    label: InputLabels.insurance,
    value: "Insurance",
  },

  {
    label: InputLabels.fair,
    value: "Fair",
  },
  {
    label: InputLabels.institution,
    value: "Institution",
  },
  {
    label: InputLabels.vip,
    value: "VIP",
  },
  {
    label: InputLabels.sponsor,
    value: "Sponsor",
  },
  {
    label: InputLabels.hotel,
    value: "Hotel",
  },
  {
    label: InputLabels.catering,
    value: "Catering",
  },
];

export const ART_MOVEMENTS = [
  "Conceptual Art",
  "Pop Art",
  "Modernism",
  "Performance Art",
  "Abstract Expressionism",
  "Deconstructivism",
  "Ecological Art",
  "Queer Art",
  "Minimalism",
  "Expressionism",
  "Photorealism",
  "Viennese Actionism",
  "Kinetic Art",
  "Land Art",
  "Fluxus",
  "Art Brut/Outsider Art",
  "Digital Art",
  "Op-Art",
  "Mexican Muralism",
  "Négritude",
  "Black Consciousness Movement",
  "Gutai Art",
  "Mono-ha",
  "Bombay Progressive Artists Group",
  "Modern Indian Art Movement",
  "Cynical Realism",
  "Political Pop",
  "’85 New Wave",
  "Papua New Guinean Contemporary",
  "Hurufiyya",
  "Arab Feminist Art Movement",
  "Feminist Art",
  "Cyberfeminism",
  "Postmodernism",
  "Ecofeminism",
  "Black Arts Movement",
  "Chicano Art Movement",
  "Harlem Renaissance",
  "Afrofuturism",
  "Abstract Art",
  "Graffiti/Street Art",
  "Aboriginal Art",
  "Cuban Modern Art",
  "Thai Modern Art",
  "Indigenous Art",
  "Happening",
  "Japanese Avant-garde Women Artists",
  "Contemporary Central Asian Art",
  "Polynesian Contemporary Art",
  "Contemporary Pacific Art",
  "Contemporary Southeast Asian Art",
  "Global Feminisms",
  "Black Feminist Art",
  "Participatory Art",
  "Indigenous Mythology",
  "Art in Public Space",
  "Folklore",
  "Mythology",
  "Ecology",
  "Political Criticism",
  "LGBTQIA+",
  "Black LGBTQIA+",
  "Islamic Feminism",
  "Colonialism",
  "Intersectionality",
  "Diaspora",
  "Migration",
  "Climate Change",
  "Architecture",
  "Technology",
  "Memory",
  "Globalization",
  "Identity Politics",
  "War",
  "Materialization",
  "Native American",
];

export const ART_MEDIA = [
  "Painting",
  "Sculpture",
  "Drawing",
  "Installation",
  "Photography",
  "Print",
  "Collage",
  "Video",
  "Film",
  "Performance",
  "Animation",
  "Mixed Media",
  "Wall work",
  "Textile",
  "Sound",
];

export const SUPPORTED_MEASUREMENT_UNITS = ["cm", "in"];

export const SUPPORTED_WEIGHT_UNITS = ["kg", "lbs"];