import produce from "immer";
import { v4 as uuid } from "uuid";
import {
  CREATE_LIST_LOCALLY,
  CREATE_LIST_SUCCESS,
  GET_LISTS_SUCCESS,
  EDIT_LIST_LOCALLY,
  EDIT_LIST_SUCCESS,
  DELETE_LISTS,
  DELETE_LIST_SUCCESS,
  SIGN_OUT_SUCCESS,
} from "../actions/types";

const LIST_INITIAL_STATE = {
  lists: { byId: {} },
};

const listReducer = (state = LIST_INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case CREATE_LIST_LOCALLY:
        return createListLocally(draftState, action);
      case CREATE_LIST_SUCCESS:
        return createListSuccess(draftState, action);
      case GET_LISTS_SUCCESS:
        return getListsSuccess(draftState, action);
      case EDIT_LIST_LOCALLY:
        return editListLocally(draftState, action);
      case EDIT_LIST_SUCCESS:
        return editListSuccess(draftState, action);
      case DELETE_LISTS:
        return deleteLists(draftState, action);
      case DELETE_LIST_SUCCESS:
        return deleteListSuccess(draftState, action);
      case SIGN_OUT_SUCCESS:
        return LIST_INITIAL_STATE;
      default:
        return state;
    }
  });
};

const createListLocally = (draftState, action) => {
  const { tempListId, ...restOfList } = action.payload;

  draftState.lists.byId[tempListId] = {
    _id: tempListId,
    ...restOfList,
    isFromServer: false,
    isModified: false,
    isActive: true,
  };
};

const createListSuccess = (draftState, action) => {
  const { _id: listId, tempListId } = action.payload;

  draftState.lists.byId[listId] = { ...draftState.lists.byId[tempListId] };
  draftState.lists.byId[listId]._id = listId;
  draftState.lists.byId[listId].isFromServer = true;
  delete draftState.lists.byId[tempListId];
};

const getListsSuccess = (draftState, action) => {
  action.payload.forEach((list) => {
    draftState.lists.byId[list._id] = { ...list, isFromServer: true, isModified: false, isActive: true };
  });
};

const editListLocally = (draftState, action) => {
  const { ...oldListData } = draftState.lists.byId?.[action.payload.id];
  const { title, artworks, ...newListData } = action.payload;

  draftState.lists.byId[action.payload.id] = {
    ...oldListData,
    title: title,
    artworks: artworks,
    values: { ...newListData },
    isModified: true,
  };
};

const editListSuccess = (draftState, action) => {
  const { listId, response } = action.payload;

  draftState.lists.byId[listId] = {
    ...draftState.lists.byId[listId],
    isModified: false,
    path: response.path,
  };
};

const deleteLists = (draftState, action) => {
  const { listIds } = action.payload;

  listIds.forEach((listId) => {
    draftState.lists.byId[listId].isActive = false;
  });
};

const deleteListSuccess = (draftState, action) => {
  delete draftState.lists.byId[action.payload.listId];
};

export default listReducer;
