import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import { Icon, Box } from "../../fundamentals";

const CustomCalendarIcon = () => {
  const [hover, setHover] = useState(false);
  return (
    <Box borderRadius="50%" backgroundColor={hover && "blue.10"} padding="xxs" alignItems="center">
      <Icon
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        icon="Calendar"
        size="16"
        color="blue.60"
        cursor="pointer"
      />
    </Box>
  );
};

const rootInputStyles = {
  "&:before": {
    content: "none",
  },

  "&:after": {
    content: "none",
  },

  "&.Mui-error": {
    background: "#FFDFDA",
    borderBottom: "none",
  },
};

const inputStyles = {
  fontFamily: "Inter",
  color:"#5E54FF"
};

const popperStyle = {
  "& .MuiPickersYear-yearButton": {
    "&:hover": {
      backgroundColor: "#F3F4FF",
    },
    "&.Mui-selected": {
      backgroundColor: "#5E54FF !important",
    },
  },
  "& .MuiPickersDay-root": {
    "&:hover": {
      backgroundColor: "#F3F4FF",
    },
    "&.Mui-selected": {
      backgroundColor: "#5E54FF !important",
    },
  },
  "& .MuiPickersDay-today": {
    borderColor: "#5E54FF !important",
    backgroundColor: "white !important",
    "&:hover": {
      backgroundColor: "#F3F4FF !important",
    },
  },
  "& .MuiSvgIcon-root": {
    borderRadius: "50%",
    padding: "2px",
    "&:hover": {
      backgroundColor: "#F3F4FF",
    },
  },
};

const DateInput = ({
  name,
  control,
  label,
  disabled,
  outline,
  customRootStyles,
  customInputStyles,
  customPopperStyle,
  disableFuture,
  disablePast,
  isRequired,
  errorMessage,
  dateContainerStyles,
  props,
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required: { value: isRequired, message: errorMessage },
          min: { value: disablePast && new Date(), message: errorMessage },
          max: { value: disableFuture && new Date(), message: errorMessage },
        }}
        render={({
          fieldState: { invalid, isTouched, isDirty, error },
          field: { onChange, onBlur, value, ...field },
        }) =>
          outline ? (
            // With outline
            <Box border={error ? "1px solid #FF3737" : "1px solid #5E54FF"} borderRadius="4px" padding="4px 8px">
              <DatePicker
                value={value || null}
                // date={new Date(value)}
                onChange={onChange}
                label={label}
                disabled={disabled}
                disableFuture={disableFuture}
                disablePast={disablePast}
                slotProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                  textField: {
                    variant: "standard",
                    helperText: error && errorMessage,
                    error: error,
                    sx:{...dateContainerStyles},
                    InputProps: {
                      sx: {
                        ...rootInputStyles,
                        ...customRootStyles,
                      },
                    },
                    inputProps: {
                      sx: {
                        ...inputStyles,
                        ...customInputStyles,
                      },
                    },
                  },
                  popper: {
                    sx: {
                      ...popperStyle,
                      ...customPopperStyle,
                    },
                  },
                }}
                // components should be replaced by slots, but slots are not currently working (https://mui.com/x/react-date-pickers/custom-field/)
                components={{
                  OpenPickerIcon: CustomCalendarIcon,
                }}
                //   slots={{
                //     inputAdornment: CustomCalendarIcon
                //   }}
                {...props}
              />
            </Box>
          ) : (
            // Without outline
            <DatePicker
              value={value || null}
              // date={new Date(value)}
              onChange={onChange}
              label={label}
              disabled={disabled}
              disableFuture={disableFuture}
              disablePast={disablePast}
              slotProps={{
                actionBar: {
                  actions: ["clear"],
                },
                textField: {
                  variant: "standard",
                  helperText: error && errorMessage,
                  error: error,
                  sx:{...dateContainerStyles},
                  InputProps: {
                    sx: {
                      ...rootInputStyles,
                      ...customRootStyles,
                    },
                  },
                  inputProps: {
                    sx: {
                      ...inputStyles,
                      ...customInputStyles,
                    },
                  },
                },
                popper: {
                  sx: {
                    ...popperStyle,
                    ...customPopperStyle,
                  },
                },
              }}
              // components should be replaced by slots, but slots are not currently working (https://mui.com/x/react-date-pickers/custom-field/)
              components={{
                OpenPickerIcon: CustomCalendarIcon,
              }}
              //   slots={{
              //     inputAdornment: CustomCalendarIcon
              //   }}
              {...props}
            />
          )
        }
      />
    </>
  );
};

export { DateInput };
