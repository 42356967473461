import { fork, all, spawn, call } from "redux-saga/effects";
import formActionSaga from "redux-form-saga";
import appSaga from "./appSaga";
import queueSaga from "./queueSaga";
import queueCleanerSaga from "./queueCleanerSaga";
import authSaga from "./authSaga";
import userSaga from "./userSaga";
import syncSaga from "./syncSaga";
import archiveSaga from "./archiveSaga";
import contactSaga from "./contactSaga";
import sessionSaga from "./sessionSaga";
import tutorialSaga from "./tutorialSaga";
import uiSaga from "./uiSaga";
import artistSaga from "./artistSaga";
import paymentInviteSaga from "./paymentInviteSaga";
import marketplaceSaga from "./marketplaceSaga";
import commsSaga from "./commsSaga";
import listSaga from "./listSaga";
import viewingRoomSaga from "./viewingRoomSaga";
import discoverySaga from "./discoverySaga";

export function* rootSaga() {
  yield fork(formActionSaga);
  yield fork(appSaga);
  yield fork(queueSaga);
  yield fork(queueCleanerSaga);
  yield fork(syncSaga);
  yield fork(authSaga);
  yield fork(userSaga);
  yield fork(archiveSaga);
  yield fork(contactSaga);
  yield fork(sessionSaga);
  yield fork(tutorialSaga);
  yield fork(uiSaga);
  yield fork(artistSaga);
  yield fork(paymentInviteSaga);
  yield fork(marketplaceSaga);
  yield fork(commsSaga);
  yield fork(listSaga);
  yield fork(viewingRoomSaga);
  yield fork(discoverySaga);
}

// export function* rootSaga() {
//   const sagas = [
//     formActionSaga,
//     appSaga,
//     queueSaga,
//     queueCleanerSaga,
//     syncSaga,
//     authSaga,
//     userSaga,
//     archiveSaga,
//     sessionSaga,
//     tutorialSaga,
//     uiSaga
//   ];
//
//   yield all(
//     sagas.map(saga =>
//       spawn(function*() {
//         while (true) {
//           try {
//             yield call(saga);
//             break;
//           } catch (e) {
//             console.log(e);
//           }
//         }
//       })
//     )
//   );
// }
