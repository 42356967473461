import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectFade } from "swiper";
import "swiper/swiper-bundle.css";
import { ImageWithCache, Box } from "../fundamentals";
import styled from "styled-components";

const StyledContainer = styled(Box)`
  width: 100%;
  .swiper-container {
    padding-bottom: 20px;
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
  }
  .swiper-container-horizontal > .swiper-pagination {
    position: absolute;
    bottom: 0;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #c3c2c9;
  }
  .swiper-pagination-bullet {
    background-color: #e8e7ea;
    opacity: 100;
  }
`;

SwiperCore.use([Navigation, Pagination, EffectFade]);

const ImageSwiper = ({ images, ...props }) => {
  return (
    <StyledContainer>
      <Swiper
        modules={[Navigation, Pagination, EffectFade]}
        effect={props.effect ?? "slide"} //other effects 'fade' | 'cube' | 'coverflow' | 'flip' | 'creative' | 'cards'
        slidesPerView={1}
        centeredSlides={true}
        pagination={{ clickable: true }}
        navigation={props.navigation ?? false}
        className="mySwiper"
        breakpoints={{ 768: { slidesPerView: 4 } }}
      >
        {images.map((image) => (
          <SwiperSlide key={image._id}>
            <ImageWithCache
              image={image}
              urlSuffix="/medium.jpg"
              maxWidth="100%"
              userSelect="none"
              imageKey={image.key}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledContainer>
  );
};

export { ImageSwiper };
