import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";

const styles = {
  control: (styles, state) => ({
    ...styles,
    border: "none",
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  }),
  container: (styles, state) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "52px",
    backgroundColor: state.isFocused || state.isSelected ? "#F3F4FF" : "white",
    borderBottom: state.isFocused ? "2px solid #5E54FF" : "none",
    marginBottom: "-6px",
    ":hover": {
      backgroundColor: "#F3F4FF",
      borderBottom: state.isFocused ? "2px solid #5E54FF" : "1px solid #C8CEFF",
    },
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "#5E54FF" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: state.isSelected ? "#5E54FF" : "#F3F4FF",
      color: state.isSelected ? "white" : "#5E54FF",
      cursor: "pointer",
    },
  }),
  menuList: (styles, state) => ({
    ...styles,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#2B2A2E",
  }),
  multiValue: (styles, state) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#DBDADF",
    color: "#2B2A2E",
    borderRadius: "100px",
    padding: "4px 8px",
  }),
  multiValueRemove: (styles, state) => ({
    ...styles,
    color: "#6A6870",
    border: "2px solid #6A6870",
    borderRadius: "50%",
    width: "18px",
    height: "18px",
    padding: "1px",
    marginLeft: "7px",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#6A6870",
      cursor: "pointer",
    },
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
  }),
};

const createOption = (label) => ({
  label,
  value: label.toLowerCase(),
});

const MultiInputCreatableSelect = ({ input, options }) => {
  const [allOptions, setAllOptions] = useState(options);

  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue);
    setAllOptions((prev) => [...prev, newOption]);
    //When the user creates a new option, add this to the input.value array
    const newValues = [...input.value, newOption];
    input.onChange(newValues);
  };

  const handleChange = (value) => {
    input.onChange(value);
  };

  return (
    <CreatableSelect
      value={input.value}
      options={allOptions}
      onChange={handleChange}
      onCreateOption={handleCreate}
      styles={styles}
      isMulti
      isClearable={false}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Placeholder: () => null,
      }}
    />
  );
};

export { MultiInputCreatableSelect };
