import React from "react";
import { InputLabels } from "../../localisation";
import CountryData from "country-region-data";

import { FormSection, Field } from "redux-form";
import { Box, Flex } from "../fundamentals";
import { Input, InputSelect } from "../elements";

export const AddressFields = ({ ...props }) => {
  return (
    <FormSection name="address">
      <Box mb="m" {...props}>
        <Field name="addressLine1" label={InputLabels.addressLine1} component={Input} hideError />
        <Field name="addressLine2" label={InputLabels.addressLine2} component={Input} hideError />
        <Flex justifyContent="space-between">
          <Field name="city" label={InputLabels.city} flex="1" mr="m" component={Input} hideError />
          <Field name="region" label={InputLabels.region} flex="1" component={Input} hideError />
        </Flex>
        <Flex>
          <Field name="postalCode" label={InputLabels.postCode} flex="1" mr="m" component={Input} hideError />
          <Field
            name="country"
            label={InputLabels.country}
            options={CountryData.map(country => ({ value: country.countryName, label: country.countryName }))}
            flex="1"
            component={InputSelect}
            hideError
          />
        </Flex>
      </Box>
    </FormSection>
  );
};
