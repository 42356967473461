import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  clearAllSelectedArtworks,
  addActionToQueue,
  saveSharedArtwork,
  replaceSelectedArtwork,
} from "../../store/actions";
import { selectCollections, selectArtworksById, selectSelectedArtworkIds } from "../../store/selectors";
import { ButtonLabels, UiLabels, InputLabels } from "../../localisation";
import { DEFAULT_ARTWORK_STATUSES } from "../../config";

import { NavigationBar } from "../react-material/NavigationBar";
import { SizeMe } from "react-sizeme";
import { Flex, Box, Text } from "../fundamentals";
import { Button, DropdownSelect } from "../elements";
import { ArtworkForm } from "../forms";
import { ArtworkSidebar, ArtworkDetailsHeader, DropdownContainer, Dropdown, DropdownItem, BackToTop } from "../modules";
import { WithRequireAccess, StickyObservable } from "../hocs";

const StyledHeaderContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }
`;

const ShareArtworkDetailsScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [sidebarWidth, setSidebarWidth] = useState();
  const collections = useSelector(selectCollections);
  const selectedArtworkIds = useSelector(selectSelectedArtworkIds);
  const artworks = useSelector((state) => selectArtworksById(state, selectedArtworkIds));
  const sharedArtworkIds = history.location.state.sharedArtworkIds;
  const artworkNavigationIndex = _.findIndex(sharedArtworkIds, (artworkId) => artworkId === selectedArtworkIds[0]);
  const isModalOpened = useSelector((state) => state.ui.modal);
  const firstArtwork = artworks[0];
  const { artworkCollection, status, lastModified, dateCreated, artworkValues, ...restOfArtwork } = firstArtwork;

  const lastModifiedDate = dateCreated && new Date(lastModified || dateCreated);

  const initialFormValues = {
    ...artworkValues,
    status,
    ...restOfArtwork,
  };

  useEffect(() => {
    document.addEventListener("keyup", handleOnArrowLeftRigthKeysPress);

    return () => {
      document.removeEventListener("keyup", handleOnArrowLeftRigthKeysPress);
    };
  });

  const handleOnNavigateToArtwork = (navigateTo) => {
    if (navigateTo === "next") {
      dispatch(replaceSelectedArtwork(sharedArtworkIds[artworkNavigationIndex + 1]));
    } else if (navigateTo === "prev") {
      dispatch(replaceSelectedArtwork(sharedArtworkIds[artworkNavigationIndex - 1]));
    }
  };

  const handleOnArrowLeftRigthKeysPress = (event) => {
    if (selectedArtworkIds.length === 1 && sharedArtworkIds.length > 1 && !isModalOpened) {
      if (event.keyCode === 39) {
        if (artworkNavigationIndex !== sharedArtworkIds.length - 1) handleOnNavigateToArtwork("next");
      } else if (event.keyCode === 37) {
        if (artworkNavigationIndex !== 0) handleOnNavigateToArtwork("prev");
      }
    }
  };

  const renderFloatingActionPillButtons = (reset, dirty, pristine, submitting) => {
    if (history.location.state.saveable)
      return [
        <DropdownContainer
          component={Button}
          buttonStyle="text"
          size="medium"
          icon="Save"
          label={ButtonLabels.saveArtwork}
          key="save"
          mr="s"
          ml="s"
        >
          <Dropdown right="-60px" bottom="xl">
            {_.filter(collections, (collection) => collection.type !== "production").map((collection) => {
              return (
                <DropdownItem
                  buttonStyle="text"
                  size="medium"
                  label={ collection.name === "Discovery" ? "Favourites" : collection.name === "Artworks" ? "Collection" : collection.name }
                  onClick={() => {
                    dispatch(addActionToQueue(saveSharedArtwork(collection.id, firstArtwork.id), firstArtwork.id));
                    history.replace("/artworks");
                    dispatch(clearAllSelectedArtworks());
                  }}
                />
              );
            })}
          </Dropdown>
        </DropdownContainer>,
      ];
    else return [];
  };

  const renderStatusSelector = () => {
    return (
      <DropdownSelect
        input={{
          value: status,
        }}
        label={InputLabels.status}
        options={DEFAULT_ARTWORK_STATUSES}
        buttonProps={{
          size: "small",
          height: "100%",
          bg: DEFAULT_ARTWORK_STATUSES[status] ? DEFAULT_ARTWORK_STATUSES[status].color : null,
          color: status ? "black" : "grey.80",
          borderRadius: "4px",
          hoverColor: "grey.90",
          ml: "xs",
        }}
        placeholder={InputLabels.status}
        disabled={true}
        labelSize="small"
        clearable
        stateful
      />
    );
  };

  return (
    <Flex flex="1" flexDirection="column" maxWidth="1680px" width="100%">
      <NavigationBar />

      <Flex bg="white" boxShadow="small" mb="xxxl" position="relative" flex="1">
        <StyledHeaderContainer mt="s">
          <Flex pl="xl">
            <Button
              buttonStyle="text"
              size="small"
              icon="ArrowLeft"
              onClick={() => {
                history.replace("/artworks");
                dispatch(clearAllSelectedArtworks());
              }}
              pl="0px"
              pb="none"
            >
              {ButtonLabels.backToArtworks}
            </Button>
          </Flex>

          <StickyObservable options={{ threshold: 1 }}>
            <ArtworkDetailsHeader
              artwork={firstArtwork}
              pl="xl"
              right="0"
              sidebarWidth={sidebarWidth}
              renderStatusSelector={renderStatusSelector}
              showNavigationButtons={selectedArtworkIds.length === 1 && sharedArtworkIds.length > 1 ? true : false}
              handleOnNavigateToArtworkCallback={handleOnNavigateToArtwork}
              isFirstArtwork={artworkNavigationIndex === 0}
              isLastArtwork={artworkNavigationIndex === sharedArtworkIds.length - 1}
              isMultiEditMode={false}
            />
          </StickyObservable>

          <Flex color="grey.80" mt="xxxs" pl="xl" mb="xxl" alignItems="center" whiteSpace="nowrap">
            {firstArtwork.archiveArtworkNumber && (
              <Text size="small" mr="m">
                {`ID \u0023${firstArtwork.archiveArtworkNumber}`}
              </Text>
            )}
            {renderStatusSelector()}

            <Text size="small" ml="m" whiteSpace="nowrap">
              {lastModifiedDate
                ? `${UiLabels.lastUpdated} ${lastModifiedDate.toDateString()}, ${(
                    "0" + lastModifiedDate.getHours()
                  ).slice(-2)}:${("0" + lastModifiedDate.getMinutes()).slice(-2)}`
                : ""}
            </Text>
          </Flex>
        </StyledHeaderContainer>

        <Box flex="1">
          <ArtworkForm
            form="artworks.share"
            singleTab={false}
            readOnly={true}
            initialValues={initialFormValues}
            selectedArtworkIds={selectedArtworkIds}
            isMultiEditMode={false}
            numArtworks={1}
            renderFloatingActionPillButtons={renderFloatingActionPillButtons}
            mt="168px"
          />
        </Box>

        <SizeMe>
          {({ size }) => {
            if (sidebarWidth !== size.width) setSidebarWidth(size.width);
            return <ArtworkSidebar artworks={artworks} artworkPreviewNum={6} isMultiEditMode={false} />;
          }}
        </SizeMe>
      </Flex>

      <BackToTop />
    </Flex>
  );
};

export default WithRequireAccess(ShareArtworkDetailsScreen, 1);
