import _ from "lodash";
import { createSelector } from "reselect";

export const selectPaymentInvites = (state) => state.payment_invite.paymentInvites.byId;
export const selectPaymentInviteId = (state, paymentInviteId) => paymentInviteId;
export const selectPaymentInviteIds = (state, paymentInviteIds) => paymentInviteIds;

export const selectPaymentInviteById = createSelector(selectPaymentInvites, selectPaymentInviteId, (paymentInvites, paymentInviteId) => {
  return paymentInvites?.[paymentInviteId];
});

export const selectPaymentInvitesById = createSelector(selectPaymentInvites, selectPaymentInviteIds, (paymentInvites, paymentInviteIds) => {
  const selectedPaymentInvites = _.map(paymentInviteIds, (paymentInviteId) => paymentInvites[paymentInviteId]);

  return selectedPaymentInvites;
});

export const selectAllPaymentInvitesList = createSelector(selectPaymentInvites, (paymentInvites) => {
  let paymentInvitesList = _.map(paymentInvites, (paymentInvite) => {
    return paymentInvite;
  });

  return paymentInvitesList;
});