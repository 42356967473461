import React, { useState, useRef, useCallback } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import css from "@styled-system/css";
import { useSelector, useDispatch } from "react-redux";
import { addSelectedArtwork, replaceSelectedArtwork } from "../../store/actions";
import { selectShareRecordsByType, selectArtworksByShareArtworkIds } from "../../store/selectors";
import { useVirtual } from "react-virtual";
import AutoSizer from "react-virtualized-auto-sizer";
import { Box, Flex, Text, ImageWithCache, Separator } from "../fundamentals";
import { TabSelect, Swiper } from "../elements";
import { Headings, ButtonLabels, DynamicLabels } from "../../localisation";

const StyledSharingDropdown = styled(Flex)`
  flex-direction: column;
  position: relative;
  width: 500px;
  max-height: 650px;
  overflow-y: auto;
  width: 100%;
  ::-webkit-scrollbar {
    width: 11px;
  }
  ::-webkit-scrollbar-thumb {
    border: 2px solid white;
    border-radius: 10px;
    background: #c7c7c7;
  }
`;

const StyledSharedArtwork = styled(Box)`
  min-height: 201px;
  border-width: 2px;
  border-style: solid;
  ${css({
    bg: "grey.10",
    borderColor: "grey.10",
    padding: "xs",
    "&:hover": {
      borderColor: "blue.50",
      cursor: "pointer"
    }
  })};
`;

const ShareRecord = React.forwardRef(({ tab, rowsLength, shareRecord, index, ...props }, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artworks, dateShared, recipient, sender } = shareRecord;
  const sharedArtworks = useSelector(state => selectArtworksByShareArtworkIds(state, artworks));
  const user = tab === "recipient" ? recipient : sender;
  return (
    <Box ref={ref} {...props}>
      <Flex>
        <Box flex="1">
          <Text size="body" color="black">
            {user.name || user.email}
          </Text>
          <Text size="small" color="grey.80">
            {DynamicLabels.sharedArtworks(sharedArtworks.length)}
          </Text>
        </Box>
        <Text fontSize="12px" lineHeight="button" color="grey.60">
          {new Date(dateShared).toDateString()}
        </Text>
      </Flex>
      <Swiper>
        {sharedArtworks.map(artwork => {
          const { id = "", artworkValues = {}, images = [] } = artwork;
          const [firstImage = {}] = _.sortBy(images, "sortIndex");
          return (
            <StyledSharedArtwork
              key={id}
              onClick={() => {
                if (!history.location.pathname.includes("share/artworks/details")) {
                  dispatch(replaceSelectedArtwork(id));
                  history.push("/share/artworks/details", {
                    source: history.location.pathname,
                    saveable: tab === "recipient",
                    sharedArtworkIds: artworks
                  });
                } else {
                  dispatch(replaceSelectedArtwork(id));
                }
              }}
            >
              <ImageWithCache
                image={firstImage}
                style={{ maxHeight: "148px", maxWidth: "100%" }}
                urlSuffix="/preview.jpg"
                userselect="none"
              />

              <AutoSizer style={{ height: "20px" }}>
                {({ width }) => (
                  <Box width={width}>
                    <Text size="small" mt="xs" color="black" overflow="hidden" maxWidth="width">
                      {artworkValues.title}
                    </Text>
                  </Box>
                )}
              </AutoSizer>
            </StyledSharedArtwork>
          );
        })}
      </Swiper>
      {index !== rowsLength - 1 && <Separator mt="xxxs" mb="m" />}
    </Box>
  );
});

const RowVirtualizerDynamic = ({ tab, rows }) => {
  const parentRef = useRef(null);

  const rowVirtualizer = useVirtual({
    size: rows.length,
    parentRef,
    estimateSize: useCallback(() => 311, []),
    overscan: 3
  });

  return (
    <div
      ref={parentRef}
      className="List"
      style={{
        height: "525px",
        width: "100%",
        overflow: "auto"
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.totalSize}px`,
          width: "100%",
          position: "relative"
        }}
      >
        {rowVirtualizer.virtualItems.map(virtualRow => {
          return (
            <ShareRecord
              key={virtualRow.index}
              ref={virtualRow.measureRef}
              tab={tab}
              shareRecord={rows[virtualRow.index]}
              rowsLength={rows.length}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const SharingDropdown = () => {
  const [tab, setTab] = useState("recipient");
  const shareRecords = useSelector(state => selectShareRecordsByType(state, tab));

  const renderEmptySharingDropdown = () => {
    return (
      <Flex height="300px" alignItems="center" justifyContent="center">
        <Text size="heading3" color="grey.80" textAlign="center" mb="m">
          {tab === "sender"
            ? "Share your first artwork and your record will appear here."
            : "Works shared with you will appear here"}
        </Text>
      </Flex>
    );
  };

  return (
    <StyledSharingDropdown p="m" pt="none">
      <Flex flexDirection="column" position="relative">
        <Box position="sticky" top="0" bg="white" pt="s" zIndex="10" mb="m">
          {/* <Text size="heading3" mb="m">
            {Headings.activity}
          </Text> */}
          <TabSelect
            input={{ value: tab, onChange: setTab }}
            options={{ recipient: ButtonLabels.incoming, sender: ButtonLabels.outgoing }}
          />
        </Box>

        {shareRecords.length === 0 && renderEmptySharingDropdown()}
        {shareRecords.length !== 0 && <RowVirtualizerDynamic tab={tab} rows={shareRecords} />}
      </Flex>
    </StyledSharingDropdown>
  );
};

export { SharingDropdown };
