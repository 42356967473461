import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useIsScreenMobile } from "../../hooks/useMediaQuery";
import { Typography } from "@mui/material";
import { Headings, ButtonLabels } from "../../../localisation";
import { Button } from "../../elements";
import { Text, Box } from "../../fundamentals";
import { markPaymentInviteDelivered } from "../../../store/actions";
import { DateTime } from "luxon";

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "24px",
    lineHeight: mobile ? "26px" : "32px",
    marginBottom: "12px",
    fontWeight: "400",
  };
};

const DeliveryConfirmationForm = ({ paymentInviteId, paymentInvite }) => {
  const isMobile = useIsScreenMobile();
  const dispatch = useDispatch();
  const paymentDate = paymentInvite?.status?.find((status) => status.status_name === "Paid")?.date;
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleConfirmDeliveryClick = () => {
    setIsButtonClicked(true);
    dispatch(markPaymentInviteDelivered(paymentInviteId));
  };

  return (
    <Box>
      <Typography sx={headerTextStyle(isMobile)}>{Headings.confirmDelivery}</Typography>
      <Text mb="38px">
        You’re confirming that the artworks you purchased on
        <span style={{ fontWeight: 500 }}> {DateTime.fromISO(paymentDate).toFormat("dd MMM yyyy")}</span> from
        <span style={{ fontWeight: 500 }}> {paymentInvite?._user?.name} </span>
        have been delivered.
      </Text>
      <Button
        buttonStyle="primary"
        size={isMobile ? "small" : "medium"}
        fontWeight="500"
        onClick={handleConfirmDeliveryClick}
        disabled={isButtonClicked}
        icon={isButtonClicked ? "Loader" : null}
        iconSpinning={true}
        flip={true}
        iconOffset="xs"
      >
        {ButtonLabels.confirm}
      </Button>
    </Box>
  );
};

export { DeliveryConfirmationForm };
