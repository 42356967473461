import { Field, FieldArray, FormSection, change, getFormValues, getFormInitialValues } from "redux-form";
import { Box, Flex, Text, Icon } from "../fundamentals";
import { Button, Input, InputSelect, RadioSelect } from "../elements";
import { ImageUpload } from "./ImageUpload";
import { InputLabels, ButtonLabels } from "../../localisation";
import { v4 as uuid } from "uuid";

const renderDocumentUploadFields = ({
  fields,
  disabled,
  form,
  textTypeOptionsDirection,
  meta: { error },
  ...props
}) => {
  const documentConnectionId = uuid();
  return (
    <Box>
      {error && (
        <Text fontSize="small" color="red">
          {error}
        </Text>
      )}
      {fields.map((document, index) => (
        <Box key={index}>
          <Field
            name={`${document}.documentType`}
            component={RadioSelect}
            form={form}
            direction={textTypeOptionsDirection || "column"}
            labelSize="small"
            spacing="small"
            disabled={disabled}
            options={{
              workDescription: { label: InputLabels.workDescription },
              review: { label: InputLabels.review },
              catalogue: { label: InputLabels.catalogueText },
              other: { label: InputLabels.other },
            }}
          />
          <Field
          name={`${document}.description`}
          form={form}
          component={Input}
          placeholder={InputLabels.additionalTextDescription}
          disabled={disabled}
          />
          <FieldArray
            name={`${document}.files`}
            component={ImageUpload}
            form={form}
            urlSuffix="/document.pdf"
            contained
            withDropbox
            isLogoUpload={false}
            isDocumentUpload={true}
            disabled={disabled}
          />
          {!disabled && (
            <Flex>
              <Button
                buttonStyle="text"
                icon="MinusCircle"
                size="small"
                padding="none"
                fontWeight="500"
                mb="m"
                onClick={() => fields.remove(index)}
              >
                {ButtonLabels.removeText}
              </Button>
            </Flex>
          )}
        </Box>
      ))}
      <Button
        type="button"
        buttonStyle="text"
        icon="Plus"
        size="small"
        padding="none"
        fontWeight="500"
        marginTop="s"
        onClick={() => fields.push({documentConnectionId})}
        disabled={disabled}
      >
        {ButtonLabels.addText}
      </Button>
    </Box>
  );
};

const DocumentUpload = ({ form, disabled, groupName, textTypeOptionsDirection, ...props }) => {
  return (
    <FieldArray
      disabled={disabled}
      //   rerenderOnEveryChange={true}
      name={groupName}
      component={renderDocumentUploadFields}
      form={form}
      textTypeOptionsDirection={textTypeOptionsDirection}
    />
  );
};

export { DocumentUpload };
