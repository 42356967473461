import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import styled, { withTheme } from "styled-components";
import css from "@styled-system/css";

import { addSelectedArtwork, removeSelectedArtwork, changeSelectedCategory } from "../../store/actions";
import { selectSelectedArtworkIds, selectArtworkById, selectIsArtworkSynced } from "../../store/selectors";

import { ButtonLabels } from "../../localisation";

import AutoSizer from "react-virtualized-auto-sizer";
import { Line } from "rc-progress";
import { Box, Flex, ImageWithCache, Text } from "../fundamentals";
import { Button } from "../elements";

const StyledProgressLine = styled(Line)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
`;

const StateButton = styled(Button)`
  pointer-events: all;
  transition: opacity 0.2s, background-color 0.3s, border-color 0.3s, color 0.3s, padding 0.4s ease-out;
  ${props => `opacity: ${props.selected ? 1 : 0};`}
`;

const StyledArtwork = styled(Flex)`
  position: relative;
  border: 2px solid;
  border-radius: 2px;
  transition: border-color 0.2s;
  flex-direction: column;
  flex-shrink: 0;
  white-space: nowrap;
  &:hover {
    ${css({ borderColor: "blue.50" })}
    ${StateButton} {
      opacity: 1;
    }
  }
  ${css({
    padding: ["xs", "s"],
    bg: "white",
    marginRight: "m",
    marginBottom: "m",
    borderColor: "grey.10"
  })};
  ${props =>
    css({
      borderColor: props.selected ? "blue.50" : null
    })}
`;

const ArtworkComponent = ({ id, category, readOnly, theme, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedArtworkIds = useSelector(selectSelectedArtworkIds);
  const isArtworkSelected = _.includes(selectedArtworkIds, id);
  const [pillIcon, setPillIcon] = useState(isArtworkSelected ? "Check" : "Circle");
  const artwork = useSelector(state => selectArtworkById(state, id));
  const isSynced = useSelector(state => selectIsArtworkSynced(state, artwork.id));
  const { images = [] } = artwork;
  const [firstImage = {}] = images;

  const handleArtworkOnClick = e => {
    if (selectedArtworkIds.length === 0) {
      dispatch(addSelectedArtwork(artwork.id));
      dispatch(changeSelectedCategory(category));
      history.push("/artworks/details", { source: history.location.pathname });
    } else {
      handleArtworkSelect(e);
    }
  };

  const handleArtworkSelect = e => {
    e.stopPropagation();

    if (isArtworkSelected) {
      dispatch(removeSelectedArtwork(artwork.id));
    } else {
      setPillIcon("X");
      dispatch(addSelectedArtwork(artwork.id));
    }
  };

  useEffect(() => {
    if (isArtworkSelected && pillIcon === "Circle") setPillIcon("Check");
    if (!isArtworkSelected) setPillIcon("Circle");
  }, [isArtworkSelected, pillIcon]);

  return (
    <StyledArtwork
      selected={isArtworkSelected}
      onMouseEnter={() => isArtworkSelected && setPillIcon("X")}
      onMouseLeave={() => isArtworkSelected && setPillIcon("Check")}
      onClick={e => handleArtworkOnClick(e)}
      minWidth={"150px"}
      minHeight={"160px"}
      userSelect="none"
      {...props}
    >
      <ImageWithCache
        image={firstImage}
        artworkId={id}
        urlSuffix="/preview.jpg"
        height={["160px", "176px"]}
        minHeight={"160px"}
        userselect="none"
        mb="s"
      />

      <AutoSizer style={{ height: "40px" }}>
        {({ width }) => (
          <Box width={width}>
            <Text overflow="hidden" size="body" color="black" maxWidth={width}>
              {artwork.artworkValues.title}
            </Text>
            <Text size="small" color="grey.80">
              {artwork.artworkValues.year}
            </Text>
            {!isSynced && (
              <StyledProgressLine
                percent={100}
                strokeLinecap="square"
                strokeWidth={1.5}
                strokeColor={theme.colors.amber[50]}
                trailWidth={1.5}
                trailColor={theme.colors.blue[40]}
              />
            )}
          </Box>
        )}
      </AutoSizer>
      {!readOnly && (
        <Flex flexDirection="column" position="absolute" top="xxs" left="xxs" pointerEvents="none">
          <StateButton
            buttonStyle={"primary"}
            size="small"
            selected={selectedArtworkIds.length > 0}
            icon={pillIcon}
            iconStrokeWidth="3px"
            inverted={!isArtworkSelected}
            onClick={handleArtworkSelect}
            mb="xxs"
          >
            {isArtworkSelected ? pillIcon === "X" && ButtonLabels.clear : ButtonLabels.select}
          </StateButton>
        </Flex>
      )}
    </StyledArtwork>
  );
};

const Artwork = withTheme(ArtworkComponent);

export { Artwork };
