import React, { useEffect } from "react";
import { WithRequireAccess } from "../hocs";
import { Box as StyleBox, Flex, Text, Image, Link } from "../fundamentals";
import { Button as StyleButton, Modal } from "../elements";
import { ButtonLabels, DynamicLabels, Messages, UiLabels, Warnings, Headings } from "../../localisation";
import { selectAllArtistsList, selectSearch, selectSelectedArtistIds, selectUser } from "../../store/selectors";
import {
  clearAllSelectedArtists,
  deleteArtists,
  openModal,
  selectAllArtists,
  updateSearchTermArtist,
  closeModal,
} from "../../store/actions";

import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Box, Typography, Stack, InputBase, InputAdornment, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Trash2, X } from "react-feather";
import { useHistory } from "react-router-dom";

import { NavigationBar } from "../react-material/NavigationBar";
import { ArtistsTable } from "../react-material/ArtistsTable/ArtistsTable";
// import { Modal } from "../react-material";
import { DeleteModal, AlertModal } from "../modals";

const artistHeadingCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "yearBirth",
    numeric: false,
    disablePadding: false,
    label: "Year Born",
  },
  {
    id: "cityBirth",
    numeric: false,
    disablePadding: false,
    label: "City Born",
  },
  {
    id: "countryBirth",
    numeric: false,
    disablePadding: false,
    label: "Country Born",
  },
  {
    id: "currentCity",
    numeric: false,
    disablePadding: false,
    label: "City Living In",
  },
  {
    id: "currentCountry",
    numeric: false,
    disablePadding: false,
    label: "Country Living In",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

const buttonStyle = {
  borderRadius: "16px",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "16px",
  borderColor: "transparent",
};

const selectAllButton = {
  fontSize: "16px",
  fontFamily: "Inter",
  gap: "4px",
  padding: "8px 16px",
  border: "1px solid #5E54FF",
  ":active": {
    opacity: 0.8,
  },
};

const deleteButtonStyle = {
  backgroundColor: "#7274FF",
  color: "#FFFFFF",
  ":hover": {
    backgroundColor: "#7274FF",
    color: "#FFFFFF",
    borderColor: "transparent",
  },
  ":active": {
    opacity: 0.8,
    borderColor: "transparent",
  },
};

const closeButtonStyle = {
  backgroundColor: "#FFFFFF",
  color: "#7274FF",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
  ":hover": {
    backgroundColor: "#FFFFFF",
    color: "#7274FF",
    borderColor: "transparent",
  },
  ":active": {
    opacity: 0.8,
    borderColor: "transparent",
  },
};

const inputbaseStyle = {
  bgcolor: "#FFFFFF",
  p: "8px 16px",
  borderRadius: "16px",
  width: "275px",
  height: "32px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
};

const textStyle = (selected) => {
  return { fontSize: "16px", lineHeight: "24px", color: selected ? "white" : "#6A6870" };
};

const containerStyle = (selected) => {
  return {
    width: "100%",
    mt: "1.6rem",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: selected && "#5E54FF",
  };
};

const ArtistsScreen = () => {
  const artists = useSelector(selectAllArtistsList);
  const selectedArtists = useSelector(selectSelectedArtistIds) || [];
  const artistIds = _.map(artists, ({ id }) => id) || [];
  const selected = selectedArtists.length > 0;
  const search = useSelector(selectSearch);
  const isSearchTermEmpty = search.searchTermArtist === "";
  const user = useSelector(selectUser);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnSearch = _.debounce((searchTerm) => dispatch(updateSearchTermArtist(searchTerm)), 50);


  useEffect(() => {
    dispatch(clearAllSelectedArtists());
  }, []);

  const handleAddArtistButtonClick = () => {
    history.push("/artists/add");
  };

  const renderEmptyArtistsList = () => {
    return (
      <Flex flexDirection="column" alignItems="center" height="100%" width="100%">
        <Image
          src={process.env.PUBLIC_URL + "/images/icons/brand-icons/empty-state-illustration.svg"}
          alt="brand"
          width="130px"
          height="180px"
          mt="xxxl"
        />

        <Text fontFamily="bodyDmSans" size="heading2" mt="m">
          {isSearchTermEmpty ? Messages.noArtists : Messages.noArtistFound}
        </Text>
        <Text width="300px" height="48px" overflow="hidden" overflowY="hidden" mt="s" textAlign="center">
          {isSearchTermEmpty ? Messages.addArtists : Messages.emptySearchArtist(search.searchTermArtist)}
        </Text>
        <StyleBox mt="m">
          {!isSearchTermEmpty ? (
            <StyleButton
              icon="XCircle"
              buttonStyle="secondary"
              size="medium"
              mr="s"
              onClick={() => () => dispatch(updateSearchTermArtist(""))}
            >
              {ButtonLabels.clearSearch}
            </StyleButton>
          ) : (
            <Link href="https://www.simplify.art/help" target="_blank" mr="s">
              <StyleButton icon="HelpCircle" buttonStyle="secondary" size="medium">
                {ButtonLabels.help}
              </StyleButton>
            </Link>
          )}
          <StyleButton icon="Plus" buttonStyle="primary" size="medium" onClick={handleAddArtistButtonClick}>
            {ButtonLabels.addArtist}
          </StyleButton>
        </StyleBox>
      </Flex>
    );
  };

  const showArtists = (selected) => {
    if (selected) {
      return selectedArtists.length + " " + UiLabels.artistsSelected;
    } else {
      return artistIds?.length + " " + UiLabels.artists;
    }
  };

  const handleSelectAll = () => {
    dispatch(selectAllArtists(artistIds));
  };

  const deleteSelectedArtists = () => {
    dispatch(deleteArtists(selectedArtists));
    dispatch(clearAllSelectedArtists());
  };

  const cancelDeletion = () => {
    dispatch(closeModal())
  };

  const handleCancelSelectAll = () => {
    dispatch(clearAllSelectedArtists());
  };

  useEffect(() => {
    handleCancelSelectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <NavigationBar />
      <Box mt="1rem">
        <Typography variant="h2" pl="48px">
          {UiLabels.artists}
        </Typography>
        {/* search bar */}

        <Box sx={containerStyle(selected)}>
          <Stack pl="48px" height="64px" direction="row" alignItems="center" spacing={2}>
            <Typography sx={textStyle(selected)}>{showArtists(selected)}</Typography>
            <InputBase
              placeholder="Search Artists"
              sx={inputbaseStyle}
              value={search.searchTermArtist}
              onChange={(e) => handleOnSearch(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#55535A" }} />
                </InputAdornment>
              }
            />
          </Stack>

          <Stack direction="row" gap="40px" alignItems="center" mr="48px">
            {selected ? (
              <>
                <Button
                  onClick={() => dispatch(openModal("delete_artist"))}
                  variant="outlined"
                  sx={{
                    ...buttonStyle,
                    ...deleteButtonStyle,
                  }}
                  startIcon={<Trash2 color="white" size="16px" />}
                >
                  {ButtonLabels.delete}
                </Button>

                <StyleButton
                  buttonStyle="text"
                  mt="20px"
                  size="small"
                  icon="X"
                  inverted={true}
                  onClick={handleCancelSelectAll}
                >
                  {ButtonLabels.cancel}
                </StyleButton>
              </>
            ) : (
              <StyleButton buttonStyle="text" size="medium" mt="15px" onClick={handleSelectAll}>
                {ButtonLabels.selectAll}
              </StyleButton>
            )}
          </Stack>
        </Box>
      </Box>

      {Object.keys(artists).length > 0 ? (
        <Box>
          <ArtistsTable headCells={artistHeadingCells} artists={artists} />
        </Box>
      ) : (
        <Box>{renderEmptyArtistsList()}</Box>
      )}

      <Modal id="delete_artist">
        <DeleteModal
          headerText={DynamicLabels.popupHeaderForDelete("artist")}
          bodyText={Warnings.popupWarning}
          onDelete={deleteSelectedArtists}
        />
      </Modal>
      <Modal id="artist-deletion-denied">
        <AlertModal
          title={Headings.artistDeletionDenied}
          message={Warnings.artistDeletionDenied}
          acceptButtonText={ButtonLabels.cancel}
          onAccept={cancelDeletion}
        />
      </Modal>
    </Box>
  );
};

export default WithRequireAccess(ArtistsScreen, 1);
