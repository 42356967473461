import React from "react";
import { useFieldArray } from "react-hook-form";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from "@mui/material";
import { ChevronDown, XCircle, MinusCircle, Plus } from "react-feather";
import { ReactHookFormInput } from "../Input";
import { ReactHookFormInputSelect } from "../InputSelect";
import { ButtonLabels, InputLabels, Headings } from "../../../localisation/labels";
import CountryData from "country-region-data";
import { RenderError } from "../Input";
import { Flex } from "../../fundamentals";

const fieldStyle = {
  flexGrow: 1,
  marginBottom: "-1px",
  "&:hover": {
    height: "49px",
    marginBottom: "-1px",
  },
};

const inputSelectStyle = {
  height: "50px",
  marginBottom: "-1px",
  minWidth: "185px",
};

const accordionStyle = {
  "&.MuiAccordion-root": {
    border: "1px solid #C3C2C9",
    borderRadius: "7px",
    width: "828px",
    marginBottom: "30px",
    boxShadow: "none",
    "&:before": {
      content: "none",
    },
  },
};

const labelStyle = {
  width: "151px",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#6A6870",
  fontFamily: "Inter",
};

const stackStyle = {
  borderBottom: "1px solid #C3C2C9",
  alignItems: "center",
  justifyContent: "space-between",
  height: "50px",
};

const headerTextStyle = {
  fontSize: "20px",
  lineHeight: "28px",
  fontFamily: "Inter",
  color: "#2B2A2E",
};

const ArtistAwardsFormSection = ({ control, errors, setValue }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "awards",
  });

  const clearAllFields = () => {
    fields.map((_, index) =>
      [`awards.${index}.year`, `awards.${index}.title`, `awards.${index}.city`, `awards.${index}.country`].map(
        (fieldName) => setValue(fieldName, "")
      )
    );
  };

  return (
    <Accordion sx={accordionStyle} defaultExpanded={false}>
      <AccordionSummary
        sx={{ padding: "32px", height: "92px", backgroundColor: "white", borderRadius: "7px" }}
        expandIcon={<ChevronDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={headerTextStyle}>{Headings.awards}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0px 32px 32px 32px" }}>
        <>
          {fields.map((item, index) => {
            return (
              <Stack direction="column" key={item.id}>
                <Stack direction="column">
                  <Stack sx={{ ...stackStyle, justifyContent: "start" }} direction="row">
                    <Typography sx={labelStyle}>{InputLabels.year}</Typography>
                    <ReactHookFormInput
                        name={`awards.${index}.year`}
                        control={control}
                        errors={errors}
                        required
                        type="text"
                        customRootStyles={fieldStyle}
                        setValue={setValue}
                      />
                  </Stack>

                  <Stack sx={stackStyle} direction="row">
                    <Typography sx={labelStyle}>{InputLabels.title}</Typography>
                    <ReactHookFormInput
                      name={`awards.${index}.title`}
                      control={control}
                      errors={errors}
                      required
                      type="text"
                      customRootStyles={fieldStyle}
                      setValue={setValue}
                    />
                  </Stack>

                  <Flex>
                    <Stack sx={{ ...stackStyle, width: "50%", marginRight:"16px" }} direction="row">
                      <Typography sx={labelStyle}>{InputLabels.city}</Typography>
                      <ReactHookFormInput
                        name={`awards.${index}.city`}
                        control={control}
                        errors={errors}
                        required
                        type="text"
                        customRootStyles={{...fieldStyle, maxWidth:"223px"}}
                        setValue={setValue}
                      />
                    </Stack>

                    <Stack sx={{ ...stackStyle, width: "50%" }} direction="row">
                      <Typography sx={labelStyle}>{InputLabels.country}</Typography>
                      <ReactHookFormInputSelect
                        name={`awards.${index}.country`}
                        control={control}
                        errors={errors}
                        customInputSelectStyle={inputSelectStyle}
                        options={CountryData.map((country) => ({
                          value: country.countryName,
                          label: country.countryName,
                          displayedLabel: country.countryShortCode,
                        }))}
                      />
                    </Stack>
                  </Flex>
                </Stack>
                {fields.length > 1 && (
                  <Button
                    startIcon={
                      <MinusCircle
                        style={{ width: "16px", height: "16px", position: "relative", right: "-5px", top: "-1px" }}
                      />
                    }
                    onClick={() => remove(index)}
                    sx={{
                      p: 0,
                      mt: "42px",
                      mb: index === fields.length - 1 ? 0 : "50px",
                      alignSelf: "start",
                    }}
                  >
                    {ButtonLabels.removeAward}
                  </Button>
                )}
              </Stack>
            );
          })}
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginTop: "24px" }}>
            <Button
              startIcon={
                <Plus style={{ width: "16px", height: "16px", position: "relative", right: "-5px", top: "-1px" }} />
              }
              onClick={() => {
                append({
                  year: "",
                  title: "",
                  city: "",
                  country: "",
                });
              }}
              sx={{
                p: 0,
              }}
            >
              {ButtonLabels.addAward}
            </Button>
            <Button
              startIcon={
                <XCircle style={{ width: "16px", height: "16px", position: "relative", right: "-5px", top: "-1px" }} />
              }
              sx={{ paddingRight: "0", paddingTop: "0px", fontSize: "14px", fontWeight: 500 }}
              onClick={clearAllFields}
            >
              {ButtonLabels.clear}
            </Button>
          </Stack>
        </>
      </AccordionDetails>
    </Accordion>
  );
};

export { ArtistAwardsFormSection };
