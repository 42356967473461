import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { InputLabels } from "../../localisation";

import { Field, FormSection, change, getFormValues, getFormInitialValues } from "redux-form";
import { Box, Flex, Text } from "../fundamentals";
import { Input, Checkbox, InputSelect } from ".";
import { SUPPORTED_MEASUREMENT_UNITS } from "../../config";

const DimensionsInput = ({
  form,
  name,
  dimensions,
  preventOverlapRef,
  readOnly,
  variable,
  diameter,
  changeValue,
  untouchField,
  warning,
  defaultUnits,
  getInitialUnits,
  ...props
}) => {
  const [diameterOption, setDiameterOption] = useState(dimensions && dimensions.hasOwnProperty("diameter"));
  const clearDimensions = () => changeValue(form, name, undefined);
  const [selectedUnits, setSelectedUnits] = useState(false);
  return (
    <FormSection name={name}>
      <Box mb="xl" {...props}>
        <Box mb="xxs">
          {diameterOption ? (
            <Box>
              <Text fontSize="small" lineHeight="button" color="grey.90" mb="xxxs">
                {InputLabels.diameter}
              </Text>
              <Flex>
                <Field
                  name="units"
                  options={SUPPORTED_MEASUREMENT_UNITS.map((value) => ({ label: value, value }))}
                  component={InputSelect}
                  isSearchable={false}
                  selectIndicatorSize={15}
                  disabled={readOnly || (dimensions && dimensions.variable)}
                  placeholder={selectedUnits || defaultUnits}
                  additionalOnChange={(value, { prevValue, form }) => {
                    setSelectedUnits(value);
                    if (prevValue === "") changeValue(form, name, "");
                  }}
                  minWidth="20%"
                />
                <Field
                  name="diameter"
                  minWidth="80%"
                  disabled={readOnly || (dimensions && dimensions.variable)}
                  preventOverlapRef={preventOverlapRef}
                  scrollingRef={{ current: window }}
                  options={{ type: "number" }}
                  additionalOnChange={(value) => {
                    if (!value || isNaN(value)) clearDimensions();
                    else if (value) {
                      changeValue(form, name, { diameter: value, units: selectedUnits || getInitialUnits(form) || defaultUnits })
                    };
                  }}
                  hideError
                  component={Input}
                />
              </Flex>
            </Box>
          ) : (
            <Flex justifyContent="space-between">
              {/* Height */}
              <Box mr="xxs">
                <Text fontSize="small" lineHeight="button" color="grey.90" mb="xxxs">
                  {InputLabels.height}
                </Text>
                <Flex>
                  <Field
                    name="units"
                    options={SUPPORTED_MEASUREMENT_UNITS.map((value) => ({ label: value, value }))}
                    component={InputSelect}
                    isSearchable={false}
                    selectIndicatorSize={15}
                    disabled={readOnly || (dimensions && dimensions.variable)}
                    placeholder={selectedUnits || defaultUnits}
                    additionalOnChange={(value, { prevValue, form }) => {
                      setSelectedUnits(value);
                      if (prevValue === "") changeValue(form, name, "");
                    }}
                    minWidth="40%"
                  />
                  <Field
                    name="height"
                    disabled={readOnly || (dimensions && dimensions.variable)}
                    preventOverlapRef={preventOverlapRef}
                    scrollingRef={{ current: window }}
                    options={{ type: "number" }}
                    additionalOnChange={(value) => {
                      if (dimensions) {
                        if ((!value || isNaN(value)) && (!dimensions.width || isNaN(dimensions.width)))
                          clearDimensions();
                          else if (dimensions.diameter && value && !isNaN(value)) {
                            changeValue(form, name, { height: value, units: selectedUnits || getInitialUnits(form) || defaultUnits });
                          }
                          
                      }
                    }}
                    normalize={(value) => value && value.toString()}
                    hideError
                    component={Input}
                    minWidth="60%"
                  />
                </Flex>
              </Box>

              {/* Width */}
              <Box mr="xxs">
                <Text fontSize="small" lineHeight="button" color="grey.90" mb="xxxs">
                  {InputLabels.width}
                </Text>
                <Flex>
                  <Field
                    name="units"
                    options={SUPPORTED_MEASUREMENT_UNITS.map((value) => ({ label: value, value }))}
                    component={InputSelect}
                    isSearchable={false}
                    selectIndicatorSize={15}
                    disabled={readOnly || (dimensions && dimensions.variable)}
                    placeholder={selectedUnits || defaultUnits}
                    additionalOnChange={(value, { prevValue, form }) => {
                      setSelectedUnits(value);
                      if (prevValue === "") changeValue(form, name, "");
                    }}
                    minWidth="40%"
                  />
                  <Field
                    name="width"
                    disabled={readOnly || (dimensions && dimensions.variable)}
                    preventOverlapRef={preventOverlapRef}
                    scrollingRef={{ current: window }}
                    options={{ type: "number" }}
                    additionalOnChange={(value) => {
                      if (dimensions) {
                        if ((!value || isNaN(value)) && (!dimensions.height || isNaN(dimensions.height)))
                          clearDimensions();
                          else if (dimensions.diameter) {
                            changeValue(form, name, { width: value, units: selectedUnits || getInitialUnits(form) || defaultUnits });
                          }
                      }
                    }}
                    normalize={(value) => value && value.toString()}
                    hideError
                    component={Input}
                    minWidth="60%"
                  />
                </Flex>
              </Box>

              {/* Depth */}
              <Box>
                <Text fontSize="small" lineHeight="button" color="grey.90" mb="xxxs">
                  {InputLabels.depth}
                </Text>
                <Flex>
                  <Field
                    name="units"
                    options={SUPPORTED_MEASUREMENT_UNITS.map((value) => ({ label: value, value }))}
                    component={InputSelect}
                    isSearchable={false}
                    selectIndicatorSize={15}
                    disabled={readOnly || (dimensions && dimensions.variable)}
                    placeholder={selectedUnits || defaultUnits}
                    additionalOnChange={(value, { prevValue, form }) => {
                      setSelectedUnits(value);
                      if (prevValue === "") changeValue(form, name, "");
                    }}
                    minWidth="40%"
                  />
                  <Field
                    name="depth"
                    disabled={readOnly || (dimensions && dimensions.variable)}
                    preventOverlapRef={preventOverlapRef}
                    scrollingRef={{ current: window }}
                    options={{ type: "number" }}
                    additionalOnChange={(value, {prevValue}) => {
                      if (!value || isNaN(value)) changeValue(form, name, { depth: value });
                      else if (!prevValue) {
                        changeValue(form, `${name}.units`, selectedUnits || getInitialUnits(form) || defaultUnits);
                        dimensions && dimensions.diameter && changeValue(form, name, _.omit(dimensions, diameter));
                      }
                    }}
                    normalize={(value) => value && value.toString()}
                    hideError
                    component={Input}
                    minWidth="60%"
                  />
                </Flex>
              </Box>
            </Flex>
          )}
        </Box>
        <Flex>
          {variable && (
            <Field
              name="variable"
              label={InputLabels.dimensionsVariable}
              labelSize="small"
              disabled={readOnly}
              additionalOnChange={(variable) => {
                if (variable) changeValue(form, name, { variable });
                else clearDimensions();
              }}
              component={Checkbox}
            />
          )}
          {diameter && (
            <Checkbox
              input={{ value: dimensions && dimensions.variable ? false : diameterOption, onChange: setDiameterOption }}
              label="Diameter"
              labelSize="small"
              disabled={(dimensions && dimensions.variable) || readOnly}
              additionalOnChange={(value) => {}}
            />
          )}
        </Flex>
      </Box>
    </FormSection>
  );
};

function mapStateToProps(state, { form, name }) {
  const { user } = state;
  const dimensions = _.get(getFormValues(form)(state), name);
  
  return { 
    dimensions,
    defaultUnits: user.settings && user.settings.defaultDimensionUnits ? user.settings.defaultDimensionUnits : "",
    getInitialUnits: form => {
      const initialValues = getFormInitialValues(form)(state)[name];
      if (initialValues) return initialValues.units;
    }
  
  };
}


function mapDispatchToProps(dispatch, { form, name }) {
  return bindActionCreators(
    {
      changeValue: change,
    },
    dispatch
  );
}

const ConnectedDimensionsInput = connect(mapStateToProps, mapDispatchToProps)(DimensionsInput);

export { ConnectedDimensionsInput as DimensionsInput };
