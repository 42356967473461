import { Box, Flex, Text, Icon } from "../fundamentals";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateDiscoveryFilters, getDiscoveryLoadRequest, openDrawer } from "../../store/actions";
import { selectEventFiltersById } from "../../store/selectors";
import { ButtonLabels, InputLabels } from "../../localisation";
import { Button } from "../elements";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { ReactHookFormInputSelect } from "../react-material/InputSelect";
import { useIsScreenMobile } from "../hooks/useMediaQuery";

const FilterLabels = ({ setValue }) => {
  const discoveryState = useSelector((state) => state.discovery);
  const { filters } = discoveryState;
  const selectedEventFilters = useSelector((state) => selectEventFiltersById(state, filters.events));

  const handleClearAllFiltersClick = () => {
    Object.keys(filters).map((filterType) => {
      setValue(filterType, []);
    });
  };

  const handleRemoveSingleFilterClick = (filterType, filterId) => {
    const removedFilterIndex = filters[filterType].indexOf(filterId);
    const updatedFilters = filters[filterType].toSpliced(removedFilterIndex, 1);
    setValue(filterType, updatedFilters);
  };

  return (
    <Flex flexWrap="wrap">
      {/* Will need o be updated to map over all filter types and not just events */}
      {selectedEventFilters.map((event) => {
        return (
          <Flex>
            <Flex
              color="grey.90"
              border="1px solid #6A6870"
              borderRadius="30px"
              padding="6px 16px"
              mr="xs"
              fontWeight="500"
            >
              <Text mr="xxs" fontSize="small">
                {event.eventName}
              </Text>
              <Icon
                icon="X"
                size="15"
                padding="0px"
                cursor="pointer"
                onClick={() => {
                  handleRemoveSingleFilterClick("events", event._id);
                }}
              />
            </Flex>
          </Flex>
        );
      })}
      <Button
        buttonStyle="secondary"
        size="small"
        padding="6px 16px"
        fontFamily="body"
        fontWeight="500"
        onClick={handleClearAllFiltersClick}
      >
        {ButtonLabels.clearAll}
      </Button>
    </Flex>
  );
};

const DiscoveryFilterBar = ({ setExpandedFilter}) => {
  const dispatch = useDispatch();
  const isMobile = useIsScreenMobile();
  const discoveryState = useSelector((state) => state.discovery);
  const { sessionId, filters, events } = discoveryState;

  const eventDropdownMenuStyle = {
    maxHeight: "556px",
    minWidth: "300px",
  };

  const defaultFilters = {
    events: discoveryState.filters.events,
  };

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { ...defaultFilters } });

  const handleFilterClickOnMobile = (filterName) => {
    dispatch(openDrawer("discovery.filters"));
    setExpandedFilter(filterName)
  }

  const OnSubmit = (values) => {
    dispatch(updateDiscoveryFilters(values));
    dispatch(getDiscoveryLoadRequest(sessionId, values.events, false));
  };

  // Submit the filter values every time a filter changes
  useEffect(() => {
    return watch(handleSubmit(OnSubmit));
  }, [handleSubmit, watch]);

  return (
    <Box>
      <Box padding={isMobile ? "20px 16px" : "12px 48px"} borderBottom="1px solid #E8E7EA">
        <form>
          <Flex>
            {isMobile ? (
              <Button
                type="button"
                buttonStyle="neutral"
                fontWeight="500"
                size="small"
                padding="6px 16px"
                fontFamily="body"
                flip={true}
                icon="ChevronDown"
                iconOffset="xxxs"
                onClick={() => handleFilterClickOnMobile("event")}
              >
                {ButtonLabels.event}
              </Button>
            ) : (
              <ReactHookFormInputSelect
                name="events"
                control={control}
                errors={errors}
                placeholder={InputLabels.event}
                multiple={true}
                buttonPlaceholder={true}
                options={events.map((event) => ({
                  value: event._id,
                  label: event.eventName,
                  suffixLabel: event.count,
                }))}
                suffix={true}
                customMenuStyle={eventDropdownMenuStyle}
              />
            )}
          </Flex>
        </form>
      </Box>
      {!isMobile && (
        <Box padding="12px 48px">{filters?.events?.length > 0 && <FilterLabels setValue={setValue} />}</Box>
      )}
    </Box>
  );
};

export { DiscoveryFilterBar };
