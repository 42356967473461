import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Flex, Box, Text, Icon, ImageWithCache } from "../fundamentals";
import {
  Headings,
  LinkLabels,
  UiLabels,
  Messages,
  DynamicHeadings,
  DynamicLabels,
  ButtonLabels,
} from "../../localisation";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Typography } from "@mui/material";
import { selectPaymentInviteById } from "../../store/selectors";
import { PaymentForm } from "../forms/PaymentInviteForms/PaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../config";
import getSymbolFromCurrency from "currency-symbol-map";
import { Popover } from "../react-material/Popover";
import { DEFAULT_PAYMENT_INVITE_STATUSES_BUYER } from "../../config";
import { Button } from "../elements";
import { useHistory } from "react-router-dom";
import { downloadPaymentInviteInvoice } from "../../store/actions";
import { PaymentInviteModalInfo } from "../modules";
import { PaymentInviteStatusModal } from "./PaymentInviteStatusModal";

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "24px",
    lineHeight: mobile ? "26px" : "32px",
    marginBottom: "12px",
    fontWeight: "400",
  };
};

const PaymentInviteBuyerModal = ({ paymentInviteId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useIsScreenMobile();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const paymentInvite = useSelector((state) => selectPaymentInviteById(state, paymentInviteId));
  const paymentInviteArtworks = useSelector(
    (state) => state.payment_invite_artworks?.paymentInviteArtworks.byId[paymentInviteId]
  );
  const currency = paymentInvite?.artworks.reduce((acc, item) => {
    if (!acc && item.price.currency) {
      return item.price.currency;
    }
    return acc;
  }, null);
  const currencySymbol = getSymbolFromCurrency(currency);
  const processingFeePercentage = paymentInvite?.hasOwnProperty("processingFee") ? paymentInvite.processingFee : 9;
  const processingFee = (subtotal * processingFeePercentage) / 100;

  //Create a combined artworks array from paymentInvite.artworks and paymentInviteArtworks that will contain the properties of both
  let combinedArtworksArray = [];
  if (paymentInviteArtworks) {
    combinedArtworksArray = paymentInvite?.artworks?.map(({ _id, price }) => {
      const restOfProperties = paymentInviteArtworks?.find((artwork) => artwork._id === _id);
      return { _id, price, ...restOfProperties };
    });
  }

  // check status
  const latestStatus = paymentInvite?.status?.find((item) => item.is_latest === true)?.status_name;

  const paymentInviteValidity = new Date(paymentInvite?.inviteValidity).getTime();
  const inviteIsValid = new Date() < paymentInviteValidity;

  useEffect(() => {
    calculateSubtotal();
  }, []);

  const renderArtworkDiscounts = (id) => {
    const artwork = paymentInvite.artworks.filter((artwork) => artwork._id === id);
    let discounts = [];
    if (artwork[0].discounts.length > 0) {
      discounts = artwork[0].discounts.map((discount) => {
        return (
          <Flex key={discount} justifyContent="space-between" mt="xxxs">
            <Text>{discount.name}:</Text>
            <Text>
              -{currencySymbol}
              {(discount.value * artwork[0].price.value) / 100}
            </Text>
          </Flex>
        );
      });
      return discounts;
    }
  };

  const calculateSingleArtworkSubtotal = (artworkId) => {
    const artwork = paymentInvite.artworks.find((artwork) => artwork._id === artworkId);

    if (!artwork) {
      return null;
    }

    const discountTotal = artwork.discounts.reduce((total, discount) => {
      return total + Number(artwork.price.value) * (discount.value / 100);
    }, 0);

    const totalPrice = Number(artwork.price.value) - discountTotal;

    return totalPrice;
  };

  const calculateAllArtworksSubtotal = () => {
    const totalArtworksValue = paymentInvite?.artworks?.reduce((total, artwork) => {
      const discountTotal = artwork.discounts.reduce((discountTotal, discount) => {
        return discountTotal + artwork.price.value * (discount.value / 100);
      }, 0);

      const artworkTotal = artwork.price.value - discountTotal;

      return total + artworkTotal;
    }, 0);
    return totalArtworksValue;
  };

  const renderCosts = () => {
    let costs = [];
    if (paymentInvite?.costs.length > 0) {
      costs = paymentInvite.costs.map((item) => {
        return (
          <Flex justifyContent="space-between" mt="xs" key={item}>
            <Text>{item.name}</Text>
            <Text>
              {currencySymbol}
              {item.value}
            </Text>
          </Flex>
        );
      });
    }
    return costs;
  };

  const calculateSubtotal = () => {
    const totalArtworksValue = calculateAllArtworksSubtotal();

    // Calculate costs total
    const costsTotal = paymentInvite?.costs.reduce((total, cost) => {
      return total + cost.value;
    }, 0);
    setSubtotal(totalArtworksValue + costsTotal);
  };

  const handleFeeInfoClick = (e) => {
    setPopoverAnchorEl(e.currentTarget);
  };

  if (inviteIsValid && (latestStatus == "Created" || latestStatus == "Viewed")) {
    return (
      <Flex justifyContent="space-between" width="992px" height="800px">
        {/* Left Side */}
        <Box p="xxl" marginRight="xxs" overflow="auto" overflowX="hidden" width="55%">
          <Typography sx={headerTextStyle(isMobile)}>
            {DynamicHeadings.artworkForSale(paymentInvite?._user?.name, combinedArtworksArray.length)}
          </Typography>
          <Flex mt="18px" p="xxxs" mb="m" width="460px" overflowX="auto">
            {combinedArtworksArray.map((artwork, index) => {
              const { images = [] } = artwork;
              const [firstImage = {}] = images;
              return (
                <Box
                  key={artwork._id}
                  boxShadow="small"
                  width="212px"
                  mr={index === combinedArtworksArray.length - 1 ? "0" : "m"}
                  mb="xxs"
                  padding="s"
                >
                  <Flex justifyContent="space-between" mb="xxs" alignItems="start">
                    <Box minWidth="74px" mr="xs">
                      <ImageWithCache
                        image={firstImage}
                        artworkId={artwork.id}
                        urlSuffix="/preview.jpg"
                        width="100%"
                        userselect="none"
                      />
                    </Box>
                    <Box>
                      <Text fontSize="smaller">{artwork.artist}</Text>
                      <Text
                        fontSize="smaller"
                        color="grey.80"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxWidth="14ch"
                      >
                        {artwork.artworkValues.title}
                      </Text>
                    </Box>
                  </Flex>
                  <Box fontSize="smaller">
                    <Flex justifyContent="space-between">
                      <Text>{UiLabels.artworkPrice}:</Text>
                      <Text>
                        {currencySymbol}
                        {artwork.price.value}
                      </Text>
                    </Flex>
                    {renderArtworkDiscounts(artwork._id)}
                    <Flex justifyContent="space-between" mt="xxxs">
                      <Text>{UiLabels.subTotal}:</Text>
                      <Text color="blue.60">
                        {currencySymbol}
                        {calculateSingleArtworkSubtotal(artwork._id)}
                      </Text>
                    </Flex>
                  </Box>
                </Box>
              );
            })}
          </Flex>
          <Box width="460px" border="1px solid #C8CEFF" borderRadius="8px" padding="l" mb="m" fontSize="small">
            <Flex justifyContent="space-between" pb="xs" borderBottom="1px solid #C3C2C9">
              <Text>{paymentInviteArtworks?.length === 1 ? UiLabels.artwork : UiLabels.artworks}</Text>
              <Text>
                {currencySymbol}
                {calculateAllArtworksSubtotal()}
              </Text>
            </Flex>
            {paymentInvite?.costs.length > 0 && (
              <Box pb="xs" borderBottom="1px solid #C3C2C9">
                {renderCosts()}
              </Box>
            )}
            <Flex justifyContent="space-between" mt="xs">
              <Text>{UiLabels.subTotal}</Text>
              <Text>
                {currencySymbol}
                {subtotal}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" mt="xs" pb="xs" borderBottom="1px solid #C3C2C9">
              <Flex onClick={handleFeeInfoClick} cursor="pointer">
                <Text fontSize="small">{DynamicLabels.paymentInviteFee(processingFeePercentage)}</Text>
                <Icon icon="HelpCircle" size="14" ml="xxxs" color="blue.60" cursor="pointer"></Icon>
              </Flex>
              <Text>
                {currencySymbol}
                {(subtotal * processingFeePercentage) / 100}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" fontSize="body" fontWeight="500" mt="xs">
              <Text>{UiLabels.total}</Text>
              <Text color="blue.60">
                {currencySymbol}
                {subtotal + processingFee}
              </Text>
            </Flex>
            <Popover
              popoverAnchorEl={popoverAnchorEl}
              setPopoverAnchorEl={setPopoverAnchorEl}
              text={Messages.paymentInviteMessage(processingFeePercentage)}
              linkText={LinkLabels.learnMore}
              link="https://www.simplify.art/payment-invites"
              icon="HelpCircle"
            />
          </Box>

          <Flex
            justifyContent="space-between"
            width="460px"
            fontSize="small"
            border="1px solid #C8CEFF"
            borderRadius="8px"
            padding="m"
            mb="xxl"
          >
            <Text>{UiLabels.validUntil}</Text>
            <Text color="blue.60">{moment(paymentInvite?.inviteValidity).format("DD MMMM Y")}</Text>
          </Flex>
          <Box width="460px">
            <Elements stripe={stripePromise}>
              <PaymentForm paymentInviteId={paymentInviteId} amountWithoutFee={subtotal} currency={currency} />
            </Elements>
          </Box>
        </Box>

        {/* Right Side */}
        <PaymentInviteModalInfo />
      </Flex>
    );
  } else {
    if (!inviteIsValid) {
      return (
        <Flex justifyContent="space-between" p="xxl">
          <Flex justifyContent="space-between" fontSize="body" fontWeight="500" mt="xs">
            <Text>{UiLabels.paymentInviteExpired}</Text>
          </Flex>
        </Flex>
      );
    } else {
      return (
        <PaymentInviteStatusModal paymentInviteId={paymentInviteId} currencySymbol={currencySymbol} subtotal={subtotal} processingFee={processingFee} />
      );
    }
  }
};

export { PaymentInviteBuyerModal };
