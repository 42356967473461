import React from "react";
import { Field, reduxForm } from "redux-form";
import { contactUs } from "../../store/actions";
import { Box, Text } from "../fundamentals";
import { Input, Section, Checkbox, Button } from "../elements";
import { InputLabels, InputErrors, ButtonLabels, Headings, UiLabels } from "../../localisation";

const Form = ({ handleSubmit, submitting }) => {
  return (
    <form onSubmit={handleSubmit(contactUs)}>
      <Box width="320px">
        <Text size="heading3" mb="xxl" color="black">
          {Headings.contactUs}
        </Text>
        <Field name="message" type="textarea" label={InputLabels.message} minHeight="120px" component={Input} />
        
        <Button
          type="submit"
          buttonStyle="primary"
          size="medium"
          mt="m"
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
          icon={submitting ? "Loader" : null}
          disabled={submitting}
        >
          {ButtonLabels.sendMessage}
        </Button>
      </Box>
    </form>
  );
};


const validate = values => {
  const errors = {};

  const { message } = values;

  if (!message) {
    errors.message = InputErrors.noMessage;
  }

  return errors;
};

const ContactUsFormModal = reduxForm({
  form: "contactUs",
  validate
})(Form);

export { ContactUsFormModal };
