// This is the first onboarding screen for any user who has not selected type artist. Artists are redirected to the
// second onboarding screen right after submitting their email and password
import React from "react";
import styled, { withTheme } from "styled-components";
import { useHistory } from "react-router-dom";

import { Box, Flex, Image } from "../fundamentals";
import { OnboardingFirstForm } from "../forms";
// import { ProgressStepBar } from "../modules"; //Not currently used

// import { STEPS_ONBOARDING } from "../../config";
import { useIsScreenMobile } from "../hooks/useMediaQuery";

const StyledOnboardingScreen = styled(Flex)`
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const OnboardingFirstScreen = (props) => {
  const history = useHistory();
  const isMobile = useIsScreenMobile();
  const prevLocation = props.location.state?.from;

  return (
    <StyledOnboardingScreen bg="white">
      <Image
        src={
          process.env.PUBLIC_URL +
          `/images/brand-identity/${isMobile ? "logo-icon-black.svg" : "logo_horizontal-black.svg"}`
        }
        alt="logo"
        width={isMobile ? "25px" : "184px"}
        height={isMobile ? "35px" : ""}
        p={isMobile ? "35px 30px" : "35px 50px"}
      />

      <Box display="flex" justifyContent="center">
        {/* <ProgressStepBar steps={STEPS_ONBOARDING} activeColor="#5E54FF" color="black" activeIndex={0} /> */}
        <OnboardingFirstForm
          onSubmit={() => {
            history.push("/onboarding/profile-details", {from: prevLocation});
          }}
        />
      </Box>
    </StyledOnboardingScreen>
  );
};

export default withTheme(OnboardingFirstScreen);
