import { useSelector } from "react-redux";
import { WithRequireAccess } from "../hocs";
import { Box, Flex, Text } from "../fundamentals";
import { Button, Modal } from "../elements";
import { NavigationBar } from "../react-material/NavigationBar";
import { UiLabels, ButtonLabels, Headings } from "../../localisation";
import { useLocation, useHistory } from "react-router-dom";
import { ActivitySharedArtworksScreen } from "./ActivitySharedArtworksScreen";
import { ActivityArtworkAcquisitionsScreen } from "./ActivityArtworkAcquisitionsScreen";
import { ActivityArtworkSalesScreen } from "./ActivityArtworkSalesScreen";
import {
  ContactUsFormModal,
  PaymentInviteInTransitModal,
  UpgradeModal,
  PaymentInviteOfferDetailsModal,
  PaymentInviteEnquiryDetailsModal,
} from "../modals";

const headerTextStyle = () => {
  return {
    fontFamily: "DM Sans",
    lineHeight: "32px",
    fontWeight: "400",
    marginBottom: "xxxl",
  };
};

const ActivityScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const appVersion = useSelector((state) => state.app.version);

  return (
    <Flex flexDirection="column" width="100%" maxWidth="98%" margin="0 auto" position="relative">
      <NavigationBar />
      <Box mt="xl" pr="m" pl="m">
        <Flex justifyContent="space-between" alignItems="end" mb="m">
          <Text style={{ ...headerTextStyle, fontSize: "24px" }}>{Headings.activity}</Text>
          <Text size="small" color="grey.80">
            {UiLabels.version} {appVersion}
          </Text>
        </Flex>
        <Flex justifyContent="stretch">
          <Flex flexDirection="column" width="175px" mr="xxl">
            <Button
              buttonStyle="text"
              size="medium"
              p="0"
              mb="m"
              color={location.pathname !== "/activity/shared" ? "grey.80" : null}
              onClick={() => history.push("/activity/shared")}
            >
              {ButtonLabels.sharedArtworks}
            </Button>
            <Button
              buttonStyle="text"
              size="medium"
              p="0"
              mb="m"
              color={location.pathname !== "/activity/acquisitions" ? "grey.80" : null}
              onClick={() => history.push("/activity/acquisitions")}
            >
              {ButtonLabels.artworkAcquisitions}
            </Button>
            <Button
              buttonStyle="text"
              size="medium"
              p="0"
              color={location.pathname !== "/activity/sales" ? "grey.80" : null}
              onClick={() => history.push("/activity/sales")}
            >
              {ButtonLabels.artworkSales}
            </Button>
          </Flex>
          <Box background="white" width="100%" minWidth="960px" p="m">
            {location.pathname === "/activity/shared" && (
              <ActivitySharedArtworksScreen headerTextStyle={headerTextStyle} />
            )}
            {location.pathname === "/activity/acquisitions" && (
              <ActivityArtworkAcquisitionsScreen headerTextStyle={headerTextStyle} />
            )}
            {location.pathname === "/activity/sales" && (
              <ActivityArtworkSalesScreen headerTextStyle={headerTextStyle} />
            )}
          </Box>
        </Flex>
        <Modal id="contactUs">
          <ContactUsFormModal />
        </Modal>
        <Modal id="paymentInviteInTransit">
          <PaymentInviteInTransitModal />
        </Modal>
        <Modal id="payment_invite.seller.see_offer">
          <PaymentInviteOfferDetailsModal />
        </Modal>
        <Modal id="payment_invite.seller.see_enquiry">
          <PaymentInviteEnquiryDetailsModal />
        </Modal>
        <Modal id="upgrade" p="none">
          <UpgradeModal />
        </Modal>
      </Box>
    </Flex>
  );
};

export default WithRequireAccess(ActivityScreen, 0);
