import React from "react";
import { Field, reduxForm } from "redux-form";
import { useIsScreenMobile } from "../../hooks/useMediaQuery";
import { Headings, Messages, ButtonLabels } from "../../../localisation";
import { Text } from "../../fundamentals";
import { Button } from "../../elements";
import { Stack, Typography, Box } from "@mui/material";
import { InputLabels, InputErrors } from "../../../localisation";
import { Input, RenderError } from "../../react-material/Input";
import { markPaymentInviteDisputed } from "../../../store/actions";

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "24px",
    lineHeight: mobile ? "26px" : "32px",
    marginBottom: "12px",
    fontWeight: "400",
  };
};

const fieldStyle = {
  flexGrow: 1,
  ".MuiFilledInput-root": {
    backgroundColor: "white",
    marginLeft: "30px",
    alignItems:"center",
    "&:hover": {
      backgroundColor: "#F3F4FF",
      borderBottom: "2px solid #C8CEFF",
      marginBottom: "-3px",
    },
    "&:before": {
      content: "none",
    },
    "&.Mui-focused": {
      borderBottom: "none",
      backgroundColor: "#F3F4FF",
      marginBottom: "-3px",
    },
  },

  "& .Mui-error": {
    ".MuiFilledInput-input": {
      marginBottom: "3px",
    },
  },
};

const stackStyle = {
  borderBottom: "1px solid #C3C2C9",
  alignItems: "center",
  justifyContent: "space-between",
  height: "58px",
};



const Form = ({ handleSubmit, submitting, paymentInviteId }) => {
  const isMobile = useIsScreenMobile();
  
  const submit = (values, dispatch) => {
    const { subject, message } = values;
    return dispatch(markPaymentInviteDisputed(paymentInviteId, subject, message))
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Typography sx={headerTextStyle(isMobile)}>{Headings.startDisputeProcess}</Typography>
      <Text fontSize="small" color="grey.80">
        {Messages.customerSupport}
      </Text>
      <Stack direction="column" marginTop="12px" marginBottom="30px">
        <Box>
          <Stack sx={stackStyle} direction="row">
            <Field 
                name="subject"
                sx={fieldStyle} 
                component={Input} 
                required={true}
                hideError={true} 
                label={InputLabels.subject}
                formName="payment_invite.dispute"
            />
          </Stack>
          <Field name="subject" component={RenderError} ml="100px"/>
        </Box>
        <Box sx={{ borderBottom: "1px solid #C3C2C9", marginTop:"12px" }}>
          <Stack direction="column">

            <Field
              name="message"
              component={Input}
              required={true}
              label={InputLabels.message}
              formName="payment_invite.dispute"
              sx={{
                flexGrow: 1,
                ".MuiFilledInput-root": {
                  paddingTop: "0px",
                  paddingLeft: "0px",
                  "&:hover": {
                    backgroundColor: "#F3F4FF",
                    borderBottom: "2px solid #C8CEFF",
                    marginBottom: "-1px",
                  },
                  "&:before": {
                    content: "none",
                  },
                  "&.Mui-focused": {
                    borderBottom: "none",
                    backgroundColor: "#F3F4FF",
                    marginBottom: "-1px",
                  },
                },
              }}
              multiline={true}
            />
          </Stack>
        </Box>
      </Stack>
      <Button
        type="submit"
        buttonStyle="primary"
        size="medium"
        fontWeight="500"
        icon={submitting ? "Loader" : null}
        disabled={submitting}
      >
        {ButtonLabels.sendMessage}
      </Button>
    </form>
  );
};

const validate = (values) => {
    const errors = {};
    const { subject, message } = values;
    if (!subject) {
      errors.subject = InputErrors.noSubject;
    };
    if (!message) {
      errors.message= InputErrors.noMessage;
    };
    return errors;
  };

let DisputeForm = reduxForm({
  form: "payment_invite.dispute",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(Form);

export { DisputeForm };
