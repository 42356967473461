import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import appReducer from "./appReducer";
import userReducer from "./userReducer";
import uiReducer from "./uiReducer";
import tutorialReducer from "./tutorialReducer";
import queueReducer from "./queueReducer";
import archiveReducer from "./archiveReducer";
import selectionReducer from "./selectionReducer";
import searchReducer from "./searchReducer";
import syncReducer from "./syncReducer";
import sessionReducer from "./sessionReducer";
import contactReducer from "./contactReducer";
import paymentInviteReducer from "./paymentInviteReducer";
import paymentInviteArtworksReducer from "./paymentInviteArtworksReducer";
import marketplaceReducer from "./marketplaceReducer";
import commsReducer from "./commsReducer";
import listReducer from "./listReducer";
import viewingRoomReducer from "./viewingRoomReducer";
import discoveryReducer from "./discoveryReducer";

/*
 * This is the root state of the app
 * It contains every substate of the app
 */
const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    app: appReducer,
    sync: syncReducer,
    queue: queueReducer,
    user: userReducer,
    archive: archiveReducer,
    contact: contactReducer,
    selection: selectionReducer,
    search: searchReducer,
    ui: uiReducer,
    tutorial: tutorialReducer,
    session: sessionReducer,
    payment_invite: paymentInviteReducer,
    payment_invite_artworks: paymentInviteArtworksReducer,
    marketplace: marketplaceReducer,
    comms: commsReducer,
    list: listReducer,
    viewing_room: viewingRoomReducer,
    discovery: discoveryReducer
  });

export default createRootReducer;
