import React, { useState } from "react";
import { Checkbox as MuiCheckbox } from "@mui/material";
import { FormControlLabel, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { CheckBoxOutlineBlankRounded, CheckBoxRounded, RadioButtonUnchecked, CheckCircle } from "@mui/icons-material";
import { Flex, Box } from "../../fundamentals";

const Checkbox = ({
  name,
  control,
  label,
  labelSize,
  disabled,
  size,
  type,
  description,
  descriptionSize,
  required,
  suffix,
  suffixStyling,
  checkboxSize,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const getColor = (type, value) => {
    if (disabled) return "#C3C2C9";

    if (type === "icon" && !isHovered) return "#6A6870";

    if (value || isHovered) return "#5E54FF";

    return "#2B2A2E";
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: props }) => (
        <FormControlLabel
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          disabled={disabled}
          required={required}
          labelPlacement="end"
          label={
            <Flex justifyContent={suffix && "space-between"}>
              <Box>
                <Typography
                  fontFamily={"Inter"}
                  fontSize={labelSize || `${size}px` || "16px"}
                  fontWeight={400}
                  color={getColor(null, props.value)}
                  paddingTop={description ? "20px" : "0px"}
                >
                  {label}
                  {description && (
                    <Typography
                      fontFamily="Inter"
                      fontSize={descriptionSize || "14px"}
                      fontWeight={400}
                      color={disabled ? "#C3C2C9" : getColor(null, props.value)}
                    >
                      {description}
                    </Typography>
                  )}
                </Typography>
              </Box>
                <Typography fontSize={labelSize || `${size}px` || "16px"} color={getColor(null, props.value)} sx={suffixStyling}>{suffix}</Typography>
            </Flex>
          }
          control={
            <MuiCheckbox
              {...props}
              checked={!!props.value}
              disabled={disabled}
              onChange={(e) => props.onChange(e.target.checked)}
              icon={
                type === "round" ? (
                  <RadioButtonUnchecked sx={{ color: getColor("icon", props.value) }} />
                ) : (
                  <CheckBoxOutlineBlankRounded sx={{ color: getColor("icon", props.value) }} />
                )
              }
              checkedIcon={
                type === "round" ? (
                  <CheckCircle sx={{ color: "#5E54FF" }} />
                ) : (
                  <CheckBoxRounded sx={{ color: "#5E54FF" }} />
                )
              }
              size={checkboxSize}
            />
          }
        />
      )}
    />
  );
};

export { Checkbox };
