import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Headings,
  ButtonLabels,
  InputErrors,
  OverviewLabels,
} from "../../localisation";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { reduxForm, formValueSelector } from "redux-form";
import {  useElements } from "@stripe/react-stripe-js";
import { createFreeUser } from "../../store/actions";
import { Flex, Box, Text } from "../fundamentals";
import { Button } from "../elements";
import { Typography } from "@mui/material";

const headerTextStyle = (mobile) => {
  return {
    textAlign: "left",
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "32px",
    lineHeight: mobile ? "26px" : "40px",
    marginBottom: mobile ? "30px" : "50px",
    fontWeight: "400",
  };
};

const Form = ({ handleSubmit, submitting, plan, role, user, name, organisationName, prevLocation }) => {
  const isMobile = useIsScreenMobile();
  const history = useHistory();
  const elements = useElements();

  useEffect(() => {
    if (!role && !plan) {
      history.push("/register");
    }
  }, [role, history]);

  const submit = (values, dispatch) => {
    if (submitting) {
      return;
    }
    // Inject role property to cater to the case of user having selected role artist on webflow and not through onboarding
    values.role = role;
    return dispatch(
      createFreeUser.request({
        ...values,
        elements,
        prevLocation,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Flex
        flexDirection="column"
        width={isMobile ? "325px" : "500px"}
        bg="white"
        p={isMobile ? "m" : "60px 60px 35px 60px"}
        boxShadow="mediumLarge"
        borderRadius="15px"
      >
        <Typography sx={headerTextStyle(isMobile)}>{Headings.confirmAccount}</Typography>
        <Flex border="1px solid #C8CEFF" borderRadius="10px" p="24px" mb={isMobile ? "30px" : "50px"}>
          <Box fontSize="small" mr="12px">
            <Text lineHeight="18px" mb="xxs">{OverviewLabels.name}</Text>
            {organisationName && <Text lineHeight="18px" mb="xxs">{OverviewLabels.organisation}</Text>}
            <Text lineHeight="18px" mb="xxs">{OverviewLabels.accountType}</Text>
            <Text lineHeight="18px" mb="xxs">{OverviewLabels.subscription}</Text>
            {!plan.includes("free") && <Text lineHeight="18px" mb="xxs">{OverviewLabels.cardNumber}</Text>}
          </Box>
          <Box fontSize="small" color="blue.60" lineHeight="18px">
            <Text fontWeight="500" mb="xxs">{name}</Text>
            {organisationName && <Text fontWeight="500" mb="xxs">{organisationName}</Text>}
            <Text fontWeight="500" mb="xxs">{OverviewLabels[role]}</Text>
            <Text fontWeight="500" mb="xxs">{plan.includes("free")? OverviewLabels.freePlan : OverviewLabels.allAccessPlan}</Text>
            {!plan.includes("free") && <Text fontWeight="500" mb="xxs">card</Text>}
          </Box>
        </Flex>
        <Box display="flex" flexDirection="column" alignSelf="center">
        <Button
              buttonStyle="form"
              type="submit"
              size={isMobile ? "small" : "medium"}
              fontFamily="body"
              mb="18px"
              disabled={submitting}
              icon={submitting ? "Loader" : null}
              iconSpinning={true}
              flip={true}
              iconOffset="xs"
              p={isMobile ? "5px 12px" : "8px 16px"}
              alignSelf="center"
            >
              {ButtonLabels.confirmAndCreateAccount}
            </Button>
            <Button
              buttonStyle="formBack"
              type="button"
              size="small"
              icon="ArrowLeft"
              alignSelf="center"
              onClick={() => {
                history.goBack();
              }}
            >
              {ButtonLabels.back}
            </Button>
        </Box>
      </Flex>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  const { role } = values;
  if (!role) errors.role = InputErrors.noRole;

  return errors;
};

let OnboardingFourthForm = reduxForm({
  form: "register",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

const selector = formValueSelector("register");

function mapStateToProps(state) {
  const role = selector(state, "role");
  const plan = selector(state, "plan");
  const name = selector(state, "name");
  const organisationName = selector(state, "organisationName");
  //const cardNumber = selector(state, "cardNumber");

  // If the role and/or plan haven't been selected by the user during sign-up process, 
  // use the role and/or plan that they selected on webflow (saved on global state)
  if (typeof role == 'undefined' && typeof plan == 'undefined') {
    const role = state.user.urlRole;
    const plan = state.user.urlType;
    return { role, plan, user: state.user, name, organisationName };
 } else if (typeof role == 'undefined') {
    const role = state.user.urlRole;
    return { role, plan, user: state.user, name, organisationName };
 } else if (typeof plan == 'undefined') {
    const plan = `${role}-free`;
    return { role, plan, user: state.user, name, organisationName };
 } else {
    return { role, plan, user: state.user, name, organisationName };
 };
};

OnboardingFourthForm = connect(mapStateToProps, null)(OnboardingFourthForm);

export { OnboardingFourthForm };

export default Form;
