import _ from "lodash";
import { createSelector } from "reselect";
import { ListContainsSearchTags } from "../../utils";
import { selectSearch } from "./searchSelectors";

export const selectLists = (state) => state.list.lists.byId;
export const selectListId = (state, listId) => listId;
export const selectListIds = (state, listIds) => listIds;

export const selectListById = createSelector(selectLists, selectListId, (lists, listId) => {
  return lists?.[listId];
});

export const selectListsById = createSelector(selectLists, selectListIds, (lists, listIds) => {
  const selectedLists = _.map(listIds, (listId) => lists[listId]);

  return selectedLists;
});

export const selectAllListsList = createSelector(selectLists, selectSearch, (lists, search) => {
  let listsList = _.map(lists, (list) => {
    return list;
  });

  return _(lists)
    .thru((lists) => {
      if (search.searchTermList !== "") {
        const searchTags = search.searchTermList.split(" ");

        const filter = listsList.filter((list) => ListContainsSearchTags(searchTags, list));
        return filter;
      }
      return lists;
    })
    .value();
});
