import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../store/actions";
import { Box, Text, Flex } from "../fundamentals";
import { Button } from "../elements";
import { ButtonLabels, DynamicLabels } from "../../localisation";
import { selectUser } from "../../store/selectors";
import moment from "moment";
import { useStripe } from "@stripe/react-stripe-js";
import { changeSubscription } from "../../store/actions";

const ChangeBillingPeriodModal = ({ currentPlan, alternativePlan }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const stripe = useStripe();

  const message = DynamicLabels.changeBillingPeriodMessage(
    user.account.subscription && user.account.subscription.currentPeriodEnd,
    alternativePlan && alternativePlan.price,
    alternativePlan && alternativePlan.billing_period,
    user.account.card && user.account.card.lastFourNumbers
  );

  const highlightWords = [
    `${moment(user?.account?.subscription?.currentPeriodEnd * 1000).format("ddd MMM DD YYYY")},`,
    `€${alternativePlan?.price}`,
    alternativePlan?.billing_period === "monthly" ? "each month" : "each year",
  ];

  const handleChangeSubscription = () => {
    dispatch(closeModal());
    dispatch(changeSubscription(alternativePlan.plan, stripe));
  }

  return (
    <Box width="300px">
      <Text size="heading3" mb="s" color="black">
        {DynamicLabels.changeBillingPeriod(currentPlan.billing_period === "monthly" ? "yearly" : "monthly")}
      </Text>
      <Text fontSize="small" mb="s">
        {message.split(" ").map((word, i) =>
          highlightWords.find((highlightWord) => highlightWord.match(word)) ? (
            <Text key={i} fontSize="small" color="black" fontWeight="500">
              {word + " "}
            </Text>
          ) : (
            <Text color="#6A6870" key={i}>
              {word + " "}
            </Text>
          )
        )}
      </Text>
      <Flex>
        <Button
          buttonStyle="primary"
          size="medium"
          onClick={handleChangeSubscription}
          mt="s"
        >
          {ButtonLabels.changeBillingPeriodConfirmation}
        </Button>
      </Flex>
    </Box>
  );
};

export { ChangeBillingPeriodModal };
