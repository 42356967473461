import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { SimplifyApi } from "../../utils";
import { Headings, ButtonLabels, InputErrors, ApiErrors, InputPlaceholders } from "../../localisation";
import { signUpBeta } from "../../store/actions";
import { Flex, Text, Box } from "../fundamentals";
import { Input, Button } from "../elements";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Typography } from "@mui/material";

const headerTextStyle = (mobile) => {
  return {
    textAlign: "center",
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "32px",
    lineHeight: mobile ? "26px" : "40px",
    marginBottom: mobile ? "30px" : "50px",
    fontWeight: "400",
  };
};

const Form = ({ handleSubmit, submitting, prevLocation }) => {
  const history = useHistory();
  const passwordRef = useRef(null);
  const isMobile = useIsScreenMobile();

  const submit = (values, dispatch) => {
    if (submitting) {
      return;
    };

    return dispatch(
      signUpBeta.request({
        ...values,
        prevLocation
      })
    );
  };

  return (
    <Flex flexDirection="column" justifyContent="center">
      <form onSubmit={handleSubmit(submit)}>
      <Typography sx={headerTextStyle(isMobile)}>{Headings.expressionOfInterest}</Typography>
        <Field
          name="email"
          type="text"
          focusColor="blue.60"
          placeholder={InputPlaceholders.emailText}
          minHeight={isMobile ? "32px" : "50px"}
          options={{
            onKeyDown: (event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                passwordRef.current.focus();
              }
            },
          }}
          component={Input}
        />

        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box>
            <Button
              buttonStyle="form"
              type="submit"
              size={isMobile ? "small" : "medium"}
              fontSize={isMobile ? "13px" : "16px"}
              disabled={submitting}
              icon={submitting ? "Loader" : null}
              iconSpinning={true}
              flip={true}
              iconOffset="xs"
              // bg="white"
              p={isMobile ? "5px 12px" : "8px 16px"}
              fontFamily="body"
              mt="s"
              // fontWeight="700"
            >
              {ButtonLabels.expressionOfInterest}
            </Button>
          </Box>
          <Box>
            <Button
              buttonStyle="text"
              fontSize={isMobile ? "8px" : "smaller"}
              color="#000000"
              mt={isMobile ? "xxxs" : "xxs"}
              textDecoration="underline"
              fontFamily="body"
              onClick={(e) => {
                e.preventDefault();
                history.push("/register");
              }}
            >
              {ButtonLabels.createAccount}
            </Button>
          </Box>
        </Box>
      </form>
    </Flex>
  );
};

const validate = (values) => {
  const errors = {};

  const { email, password } = values;

  if (!email) errors.email = InputErrors.noEmail;
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) errors.email = InputErrors.invalidEmail;

  if (!password) errors.password = InputErrors.noPassword;

  return errors;
};

const SignUpBetaForm = reduxForm({
  form: "login",
  validate,
  asyncBlurFields: ["email"],
})(Form);

export { SignUpBetaForm };
