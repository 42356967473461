import { Box, Flex, Icon, Text } from "../fundamentals";
import { Headings, PaymentInviteBuyerNextSteps, PaymentInviteSecurityMeasures, PaymentInviteOfferNextSteps, PaymentInviteEnquiryNextSteps } from "../../localisation";
import { useSelector } from "react-redux";

const securityMeasures = [
  {
    icon: "Shield",
    text: PaymentInviteSecurityMeasures.protectedDetails,
  },
  {
    icon: "Info",
    text: PaymentInviteSecurityMeasures.phishingScams,
  },
  {
    icon: "Lock",
    text: PaymentInviteSecurityMeasures.sensitiveInfo,
  },
];

const NextSteps = ({transportMode, isOffer, isEnquiry}) => {
  let defaultNextSteps = [
    {
      title: PaymentInviteBuyerNextSteps.stepOneBeforePayment,
      description: PaymentInviteBuyerNextSteps.stepOneDescBuyerGenerated,
      icon: "CreditCard",
    },
    {
      title: PaymentInviteBuyerNextSteps.stepTwo,
      description: PaymentInviteBuyerNextSteps.stepTwoDescBeforePayment,
      icon: "Bell",
    },
    {
      title: PaymentInviteBuyerNextSteps.stepThree,
      description: PaymentInviteBuyerNextSteps.stepThreeDesc,
      icon: "Truck",
    },
    {
      title: PaymentInviteBuyerNextSteps.stepFour,
      description: PaymentInviteBuyerNextSteps.stepFourDesc,
      icon: "Image",
    },
  ];
  if (transportMode === "ownShipping") {
    defaultNextSteps = [
      {
        title: PaymentInviteBuyerNextSteps.stepOneBeforePayment,
        description: PaymentInviteBuyerNextSteps.stepOneDescBuyerGenerated,
        icon: "CreditCard",
      },
      {
        title: PaymentInviteBuyerNextSteps.stepTwoOwnShipping,
        description: PaymentInviteBuyerNextSteps.stepTwoDescOwnShipping,
        icon: "Bell",
      },
      {
        title: PaymentInviteBuyerNextSteps.stepThreeOwnShipping,
        description: PaymentInviteBuyerNextSteps.stepThreeDescOwnShipping,
        icon: "Truck",
      },
      {
        title: PaymentInviteBuyerNextSteps.stepFour,
        description: PaymentInviteBuyerNextSteps.stepFourDesc,
        icon: "Image",
      },
    ];
  } else if (transportMode === "handover") {
    defaultNextSteps = [
      {
        title: PaymentInviteBuyerNextSteps.stepOneBeforePayment,
        description: PaymentInviteBuyerNextSteps.stepOneDescBuyerGenerated,
        icon: "CreditCard",
      },
      {
        title: PaymentInviteBuyerNextSteps.stepTwoOwnShipping,
        description: PaymentInviteBuyerNextSteps.stepTwoDescOwnShipping,
        icon: "Bell",
      },
      {
        title: PaymentInviteBuyerNextSteps.stepThreePersonalHandover,
        description: PaymentInviteBuyerNextSteps.stepThreeDescPersonalHandover,
        icon: "Truck",
      },
      {
        title: PaymentInviteBuyerNextSteps.stepFourPersonalHandover,
        description: PaymentInviteBuyerNextSteps.stepFourDescPersonalHandover,
        icon: "Image",
      },
    ];
  };

  const offerNextSteps = [
    {
      title: PaymentInviteOfferNextSteps.stepOne,
      description: PaymentInviteOfferNextSteps.stepOneDesc,
      icon: "Mail",
    },
    {
      title: PaymentInviteOfferNextSteps.stepTwo,
      description: PaymentInviteOfferNextSteps.stepTwoDesc,
      icon: "ThumbsUp",
    },
    {
      title: PaymentInviteOfferNextSteps.stepThree,
      description: PaymentInviteOfferNextSteps.stepThreeDesc,
      icon: "CreditCard",
    },
  ];

  const enquiryNextSteps = [
    {
      title: PaymentInviteEnquiryNextSteps.stepOne,
      description: PaymentInviteEnquiryNextSteps.stepOneDesc,
      icon: "Mail",
    },
    {
      title: PaymentInviteEnquiryNextSteps.stepTwo,
      description: PaymentInviteEnquiryNextSteps.stepTwoDesc,
      icon: "MessageCircle",
    },
    {
      title: PaymentInviteEnquiryNextSteps.stepThree,
      description: PaymentInviteEnquiryNextSteps.stepThreeDesc,
      icon: "CreditCard",
    },
  ];

  const nextSteps = isOffer ? offerNextSteps : isEnquiry ? enquiryNextSteps : defaultNextSteps;
  
  return (
    <Box>
      {nextSteps.map((step) => (
        <Flex key={step.title} mt="m" alignItems="start">
          <Box borderRadius="50%" backgroundColor="white" padding="xxxs" mr="s">
            <Icon icon={step.icon} size="16" color="blue.60" />
          </Box>
          <Box>
            <Text fontWeight="500" mb="xxxs">
              {step.title}
            </Text>
            <Text fontSize="small">{step.description}</Text>
          </Box>
        </Flex>
      ))}
    </Box>
  );
};

const PaymentInviteModalInfo = ({isOffer, isEnquiry}) => {
  const transportMode = useSelector((state) => state.form.payment_invite?.update?.values?.transportMode);
  return (
    <Flex
      width="45%"
      flexDirection="column"
      justifyContent={isOffer || isEnquiry ? "end" : "space-between"}
      backgroundColor="blue.60"
      p="xxl"
      color="white"
      borderRadius="0px 10px 10px 0px"
    >
      {(!isOffer && !isEnquiry) && <Box mb="xxl">
        <Text fontFamily="bodyDmSans" fontSize="heading3" mb="s">
          {Headings.securePayment}
        </Text>
        {securityMeasures.map((measure, index) => (
          <Flex key={measure.text} alignItems="start" mb={index === securityMeasures.length - 1 ? "0" : "xs"}>
            <Box>
              <Icon icon={measure.icon} size="16" />
            </Box>
            <Text fontSize="smaller" ml="s">
              {measure.text}
            </Text>
          </Flex>
        ))}
      </Box>}
      <Box>
        <Text fontFamily="bodyDmSans" fontSize="heading3">
          {Headings.nextSteps}
        </Text>
        <NextSteps transportMode={transportMode} isOffer={isOffer} isEnquiry={isEnquiry} />
      </Box>
    </Flex>
  );
};

export { PaymentInviteModalInfo };
