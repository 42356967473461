import { Box, Flex, Text, Icon } from "../fundamentals";
import { Button } from "../elements";
import { UiLabels, ButtonLabels, Headings, InputErrors } from "../../localisation";
import { openModal, closeModal, importArchiveRequest, downloadArchiveTemplate, } from "../../store/actions";
import { useDispatch } from "react-redux";
import { FieldArray, reduxForm } from "redux-form";
import { ImageUpload } from "../modules";

const fileSetupCheckList = [
  {
    text: "Make sure the data you copy matches the column headings provided in the template.",
  },
  {
    text: "Do not change the column headings provided in the template. These need to be unchanged for the import to work.",
  },
  {
    text: "Dates are assumed to be in English (UK) format. For example, 25/12/2023 or 25 Dec 2023.",
  },
  {
    text: "Please include currency symbol or ISO code (e.g. EUR) in the pricing fields.",
  },
  {
    text: "Insert your images into the Image column fields.",
  },
  {
    text: "Save your file as a CSV file (not a native Excel file). You should see “.csv” at the end of the filename.",
  },
];

const ProgressBar = ({ page }) => {
  return (
    <Flex fontSize="small" alignItems="center">
      <Flex color={page === "templateDownload" ? "blue.60" : "blue.40"} mr="xxs">
        {page !== "templateDownload" && <Icon icon="Check" size="14" mr="xxxs" />}
        <Text>{UiLabels.downloadTemplate}</Text>
      </Flex>
      <Icon icon="ChevronRight" color="grey.40" size="16" mr="xxs" />
      <Flex color={page === "fileSetUp" ? "blue.60" : page === "upload" ? "blue.40" : "grey.40"} mr="xxs">
        {page === "upload" && <Icon icon="Check" size="14" mr="xxxs" />}
        <Text>{UiLabels.setUpFile}</Text>
      </Flex>
      <Icon icon="ChevronRight" color="grey.40" size="16" mr="xxs" />
      <Text color={page === "upload" ? "blue.60" : "grey.40"}>{UiLabels.upload}</Text>
    </Flex>
  );
};

const Form = ({ handleSubmit, submitting }) => {
  const dispatch = useDispatch();

  const submit = (values, dispatch) => {
    const { csvFiles } = values;
    return dispatch(importArchiveRequest(csvFiles[0]));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <FieldArray
        name={"csvFiles"}
        form="csv.upload"
        component={ImageUpload}
        urlSuffix="/document.csv"
        contained
        withDropbox
        isLogoUpload={false}
        isDocumentUpload={true}
        isCsvUpload={true}
        maxImages={1}
      />
      <Flex justifyContent="space-between">
        <Button
          type="button"
          buttonStyle="secondary"
          size="medium"
          fontWeight="500"
          onClick={() => dispatch(openModal("csv_import", { page: "fileSetUp" }))}
        >
          {ButtonLabels.back}
        </Button>
        <Button
          type="submit"
          buttonStyle="primary"
          size="medium"
          fontWeight="500"
          icon={submitting ? "Loader" : null}
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
        >
          {ButtonLabels.upload}
        </Button>
      </Flex>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const { csvFiles } = values;
  if (!csvFiles || !csvFiles?.length) {
    errors.csvFiles = { _error: InputErrors.noCsvFile };
  }
  return errors;
};

const CsvUploadForm = reduxForm({
  form: "csv.upload",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

const CsvImportModal = ({ page }) => {
  const dispatch = useDispatch();

  const handleEmailClick = () => {
    window.open("mailto:contact@simplify.art?subject=Archive Setup Service");
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb="30px">
        <Text fontFamily="bodyDmSans" fontSize="heading2">
          {Headings.importYourArchive}
        </Text>
        <ProgressBar page={page} />
      </Flex>
      {/* Download Template Page */}
      {page === "templateDownload" && (
        <Box>
          <Box
            fontSize="small"
            backgroundColor="blue.10"
            border="1px solid #C8CEFF"
            borderRadius="8px"
            padding="m"
            mb="30px"
          >
            <Flex alignItems="center" mb="xs">
              <Icon
                icon="Layers"
                size="14"
                color="blue.60"
                backgroundColor="blue.30"
                borderRadius="50%"
                padding="xxxs"
                mr="6px"
              />
              <Text fontWeight="500">Archive setup service</Text>
            </Flex>
            <Text mb="s">
              We know transferring your archive can sometimes be a little overwhelming. We can help you get set up in
              simplify.art and provide ongoing support and consultation if you need it. Email us for more details.
            </Text>
            <Button buttonStyle="primary" size="small" fontWeight="500" onClick={handleEmailClick}>
              {ButtonLabels.emailUs}
            </Button>
          </Box>
          <Text mb="30px">
            Start the import process by downloading our CSV archive template spreadsheet. You can use this CSV file in
            software like Excel, Numbers or GoogleSheets.
          </Text>
          <Button
            buttonStyle="primary"
            size="medium"
            fontWeight="500"
            mb="30px"
            icon="Download"
            onClick={() => dispatch(downloadArchiveTemplate())}
          >
            {ButtonLabels.downloadTemplateSpreadsheet}
          </Button>
          <Text mb="30px">
            Once you have successfully downloaded the spreadsheet, click Continue for instructions on how to set up your
            file properly.
          </Text>
          <Flex justifyContent="space-between">
            <Button buttonStyle="secondary" size="medium" fontWeight="500" onClick={() => dispatch(closeModal())}>
              {ButtonLabels.close}
            </Button>
            <Button
              buttonStyle="primary"
              size="medium"
              fontWeight="500"
              onClick={() => dispatch(openModal("csv_import", { page: "fileSetUp" }))}
            >
              {ButtonLabels.continue}
            </Button>
          </Flex>
        </Box>
      )}
      {/* File Setup Page */}
      {page === "fileSetUp" && (
        <Box>
          <Text mb="30px">
            Whether you are updating an existing CSV file to match our template or copying existing data into our
            template, go through the following checklist to make sure the file is set up properly:
          </Text>
          <Box mb="30px" fontSize="small">
            {fileSetupCheckList.map((item, index) => (
              <Flex key={item.text} alignItems="center" mb={index !== fileSetupCheckList.length - 1 && "13px"}>
                <Icon icon="AlertCircle" size="16" color="blue.60" mr="10px" />
                <Box maxWidth="642px">
                  <Text>{item.text}</Text>
                </Box>
              </Flex>
            ))}
          </Box>
          <Text mb="30px">
            Once you’ve checked your file is setup and exported properly, click Continue to upload your CSV file.
          </Text>
          <Flex justifyContent="space-between">
            <Button
              buttonStyle="secondary"
              size="medium"
              fontWeight="500"
              onClick={() => dispatch(openModal("csv_import", { page: "templateDownload" }))}
            >
              {ButtonLabels.back}
            </Button>
            <Button
              buttonStyle="primary"
              size="medium"
              fontWeight="500"
              onClick={() => dispatch(openModal("csv_import", { page: "upload" }))}
            >
              {ButtonLabels.continue}
            </Button>
          </Flex>
        </Box>
      )}
      {/* Upload Page */}
      {page === "upload" && (
        <Box>
          <CsvUploadForm />
        </Box>
      )}
    </Box>
  );
};

export { CsvImportModal };
