import {
  CREATE_SESSION_DISCOVERY,
  GET_DISCOVERY_LOAD_REQUEST,
  GET_DISCOVERY_EVENTS_REQUEST,
  UPDATE_INTERACTION_DISCOVERY,
  GET_DISCOVERY_FAVOURITES_REQUEST,
} from "../actions/types";
import {
createSessionDiscoverySuccess,
getDiscoveryLoadSuccess,
getDiscoveryLoadFailure,
getDiscoveryEventsRequest,
getDiscoveryEventsSuccess,
getDiscoveryEventsFailure,
getDiscoveryFavouritesSuccess,
getDiscoveryFavouritesFailure,
} from "../actions";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { SimplifyApi, createToast } from "../../utils";
import { Notifications } from "../../localisation";
import { replace } from "connected-react-router";
import { selectDiscoveryLoad } from "../selectors";

//SERVICES
const createSessionService = (userId, isUser) => {
  return SimplifyApi.post("/v1/marketplace/session/create", { userId, isUser});
}

const recommendationsService = (sessionId, eventIds, numberOfArtworks, artworkId) => {
  return SimplifyApi.post("/v1/marketplace/recommendations/get", {sessionId, eventIds,numberOfArtworks, artworkId});
}

const updateInteractionService = (sessionId, recommendationId, actionType, artWorkId) => {
  return SimplifyApi.post("/v1/marketplace/interactions/create", {sessionId, recommendationId, actionType, artWorkId})
}

const getDiscoveryEventsService = () => {
  return SimplifyApi.get("/v1/marketplace/events")
}

const getDiscoveryFavouritesService = (userId) => {
  return SimplifyApi.post("/v1/marketplace/favourites/get", {userId})
} 


//SAGAS
function* createSessionSaga(action){
  const {userId, isUser} = action.payload;
  try{
    const response = yield call(createSessionService, userId, isUser);
    yield put(createSessionDiscoverySuccess(response.data));
  }catch (error){
    console.log('session ID:', error);
    throw error;
  };
}

function* getDiscoveryLoadSaga(action){
  const shownDiscoveryArtworks = (yield select(selectDiscoveryLoad)).artworks;
  const numberOfArtworks = shownDiscoveryArtworks.length;
  const {sessionId, eventIds, isLoadMore, artworkId} = action.payload;
  try{
    // Get the recommended Discovery artworks
    const response = yield call(recommendationsService, sessionId, eventIds, numberOfArtworks, artworkId);
    const {artworks, recommendationId, isFinal} = response.data;
    // Get the Discovery events
    yield put(getDiscoveryEventsRequest());
    // If all successful, update discovery state
    yield put(getDiscoveryLoadSuccess(artworks, recommendationId, isLoadMore, isFinal));
  }catch (error){
    yield put(getDiscoveryLoadFailure());
    createToast({ type: "error", ...Notifications.discoveryLoadingError });
    throw error;
  };
}

function* getDiscoveryFavouritesSaga(action){
  const {userId} = action.payload;
  try{
    // Get the favourite  artworks
    const response = yield call(getDiscoveryFavouritesService, userId);
    const {artworks} = response.data;
    // If all successful, update discovery state
    yield put(getDiscoveryFavouritesSuccess(artworks));
  }catch (error){
    yield put(getDiscoveryFavouritesFailure());
    createToast({ type: "error", ...Notifications.discoveryFavouritesError });
    throw error;
  };
}



function* getDiscoveryEventsSaga(action) {
  try {
    const response = yield call(getDiscoveryEventsService);
    yield put(getDiscoveryEventsSuccess({events: response.data}))
  } catch (error) {
    yield put(getDiscoveryEventsFailure())
    createToast({ type: "error", ...Notifications.generic });
    throw error;
  }
}

function* updateInteraction(action){

  const {sessionId, recommendationId, actionType, artWorkId} = action.payload;
  try{
    const response = yield call(updateInteractionService, sessionId, recommendationId, actionType, artWorkId);
  }catch (error){
    throw error;
  };
}

function* discoverySaga() {
  yield takeEvery(CREATE_SESSION_DISCOVERY, createSessionSaga);
  yield takeEvery(GET_DISCOVERY_LOAD_REQUEST, getDiscoveryLoadSaga);
  yield takeEvery(GET_DISCOVERY_EVENTS_REQUEST, getDiscoveryEventsSaga);
  yield takeEvery(UPDATE_INTERACTION_DISCOVERY, updateInteraction);
  yield takeEvery(GET_DISCOVERY_FAVOURITES_REQUEST, getDiscoveryFavouritesSaga);
}

export default discoverySaga;
