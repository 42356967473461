import React from "react";
import { useFieldArray } from "react-hook-form";
import { Accordion, AccordionDetails, AccordionSummary, Button, Stack, Typography } from "@mui/material";
import { ChevronDown, XCircle, MinusCircle, Plus } from "react-feather";
import { ReactHookFormInput } from "../Input";
import { ReactHookFormInputSelect } from "../InputSelect";
import { ReactHookFormDateInput } from "../DateInput";
import { ButtonLabels, InputLabels, Headings } from "../../../localisation/labels";
import CountryData from "country-region-data";
import { Flex } from "../../fundamentals";
import { InputErrors } from "../../../localisation";

const fieldStyle = {
  flexGrow: 1,
  marginBottom: "-1px",
  "&:hover": {
    height: "49px",
    marginBottom: "-1px",
  },
};

const inputSelectStyle = {
  height: "50px",
  marginBottom: "-1px",
  minWidth: "218px",
};

const dateInputStyle = {
  paddingLeft: "8px",
  height: "50px",
  "&:after": {
    borderBottom: "2px solid #5E54FF",
  },

  "&:hover": {
    borderBottom: "2px solid #C8CEFF",
    marginBottom: "-2px",
    "&.Mui-error": {
      borderBottom: "none",
      marginBottom: "0",
    },
  },
};

const accordionStyle = {
  border: "1px solid #C3C2C9",
  borderRadius: "7px",
  width: "828px",
  marginBottom: "30px",
  boxShadow: "none",
  "&:before": {
    content: "none",
  },
};

const labelStyle = {
  width: "151px",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#6A6870",
  fontFamily: "Inter",
};

const stackStyle = {
  borderBottom: "1px solid #C3C2C9",
  alignItems: "center",
  justifyContent: "space-between",
  height: "50px",
};

const headerTextStyle = {
  fontSize: "20px",
  lineHeight: "28px",
  fontFamily: "Inter",
  color: "#2B2A2E",
};

const ArtistEducationFormSection = ({ control, errors, setValue, watchPublishToDiscovery }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "education",
  });

  const clearAllFields = () => {
    fields.map((_, index) =>
      [
        `education.${index}.startDate`,
        `education.${index}.endDate`,
        `education.${index}.institution`,
        `education.${index}.course`,
        `education.${index}.city`,
        `education.${index}.country`,
      ].map((fieldName) => {
        if (fieldName.includes("Date")) {
          setValue(fieldName, null);
        } else {
          setValue(fieldName, "");
        }
      })
    );
  };

  return (
    <Accordion
      sx={{
        "&.MuiAccordion-root": {
          ...accordionStyle,
          border: errors.education && "1px solid red",
        },
      }}
      defaultExpanded={false}
    >
      <AccordionSummary
        sx={{ padding: "32px", height: "92px", backgroundColor: "white", borderRadius: "7px" }}
        expandIcon={<ChevronDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={headerTextStyle}>{Headings.education}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0px 32px 32px 32px" }}>
        <>
          {fields.map((item, index) => {
            return (
              <Stack direction="column" key={item.id}>
                <Stack direction="column">
                  <Flex mb={errors?.education?.[index]?.startDate || errors?.education?.[index]?.endDate ? "24px" : ""}>
                    <Stack sx={{ ...stackStyle, marginRight: "16px", flex: "1" }} direction="row">
                      <Typography sx={labelStyle}>{InputLabels.start}</Typography>
                      <ReactHookFormDateInput
                        name={`education.${index}.startDate`}
                        control={control}
                        customRootStyles={dateInputStyle}
                        dateContainerStyles={{ bottom: errors?.education?.[index]?.startDate && "-12px" }}
                        isRequired={watchPublishToDiscovery}
                        errorMessage={InputErrors.noDate}
                      />
                    </Stack>

                    <Stack sx={{ ...stackStyle, flex: "1" }} direction="row">
                      <Typography sx={labelStyle}>{InputLabels.end}</Typography>
                      <ReactHookFormDateInput
                        name={`education.${index}.endDate`}
                        control={control}
                        customRootStyles={dateInputStyle}
                        dateContainerStyles={{ bottom: errors?.education?.[index]?.endDate && "-12px" }}
                        isRequired={watchPublishToDiscovery}
                        errorMessage={InputErrors.noDate}
                      />
                    </Stack>
                  </Flex>
                  <Stack
                    sx={{ ...stackStyle, marginBottom: errors?.education?.[index]?.institution && "24px" }}
                    direction="row"
                  >
                    <Typography sx={labelStyle}>{InputLabels.institution}</Typography>
                    <ReactHookFormInput
                      name={`education.${index}.institution`}
                      control={control}
                      errors={errors}
                      required
                      type="text"
                      customRootStyles={{
                        ...fieldStyle,
                        bottom: errors?.education?.[index]?.institution && "-12px",
                        "&:hover": {
                          marginBottom: errors?.education?.[index]?.institution ? "" : "-2px",
                        },
                      }}
                      setValue={setValue}
                      isRequired={watchPublishToDiscovery}
                      errorMessage={"Institution required"}
                    />
                  </Stack>

                  <Stack
                    sx={{ ...stackStyle, marginBottom: errors?.education?.[index]?.course && "24px" }}
                    direction="row"
                  >
                    <Typography sx={labelStyle}>{InputLabels.course}</Typography>
                    <ReactHookFormInput
                      name={`education.${index}.course`}
                      control={control}
                      errors={errors}
                      required
                      type="text"
                      customRootStyles={{
                        ...fieldStyle,
                        bottom: errors?.education?.[index]?.course && "-12px",
                        "&:hover": {
                          marginBottom: errors?.education?.[index]?.course ? "" : "-2px",
                        },
                      }}
                      setValue={setValue}
                      isRequired={watchPublishToDiscovery}
                      errorMessage={"Course required"}
                    />
                  </Stack>

                  <Flex>
                    <Stack
                      sx={{
                        ...stackStyle,
                        marginRight: "16px",
                        flex: "1",
                      }}
                      direction="row"
                    >
                      <Typography sx={labelStyle}>{InputLabels.city}</Typography>
                      <ReactHookFormInput
                        name={`education.${index}.city`}
                        control={control}
                        errors={errors}
                        required
                        type="text"
                        customRootStyles={{
                          ...fieldStyle,
                          bottom: errors?.education?.[index]?.city && "-12px",
                          maxWidth: "223px",
                          "&:hover": {
                            marginBottom: errors?.education?.[index]?.city ? "" : "-2px",
                          },
                        }}
                        setValue={setValue}
                        isRequired={watchPublishToDiscovery}
                        errorMessage={"City required"}
                      />
                    </Stack>

                    <Stack sx={{ ...stackStyle, flex: "1" }} direction="row">
                      <Typography sx={labelStyle}>{InputLabels.country}</Typography>
                      <ReactHookFormInputSelect
                        name={`education.${index}.country`}
                        control={control}
                        errors={errors}
                        customInputSelectStyle={{
                          ...inputSelectStyle,
                          bottom: errors?.education?.[index]?.country && "-10px",
                        }}
                        options={CountryData.map((country) => ({
                          value: country.countryName,
                          label: country.countryName,
                          displayedLabel: country.countryShortCode,
                        }))}
                        isRequired={watchPublishToDiscovery}
                        errorMessage={"Country required"}
                      />
                    </Stack>
                  </Flex>
                </Stack>
                {fields.length > 1 && (
                  <Button
                    startIcon={
                      <MinusCircle
                        style={{ width: "16px", height: "16px", position: "relative", right: "-5px", top: "-1px" }}
                      />
                    }
                    onClick={() => remove(index)}
                    sx={{
                      p: 0,
                      mt: "42px",
                      mb: index === fields.length - 1 ? 0 : "50px",
                      alignSelf: "start",
                    }}
                  >
                    {ButtonLabels.removeEducation}
                  </Button>
                )}
              </Stack>
            );
          })}
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginTop: "24px" }}>
            <Button
              startIcon={
                <Plus style={{ width: "16px", height: "16px", position: "relative", right: "-5px", top: "-1px" }} />
              }
              onClick={() => {
                append({
                  startDate: null,
                  endDate: null,
                  institution: "",
                  course: "",
                  city: "",
                  country: "",
                });
              }}
              sx={{
                p: 0,
              }}
            >
              {ButtonLabels.addEducation}
            </Button>
            <Button
              startIcon={
                <XCircle style={{ width: "16px", height: "16px", position: "relative", right: "-5px", top: "-1px" }} />
              }
              sx={{ paddingRight: "0", paddingTop: "0px", fontSize: "14px", fontWeight: 500 }}
              onClick={clearAllFields}
            >
              {ButtonLabels.clear}
            </Button>
          </Stack>
        </>
      </AccordionDetails>
    </Accordion>
  );
};

export { ArtistEducationFormSection };
