import React from "react";
import { reduxForm } from "redux-form";
import { Box, Flex, Text, Icon } from "../fundamentals";
import { Button } from "../elements";
import { ButtonLabels, InputLabels, FormErrors } from "../../localisation";

const Form = ({
  title,
  handleSubmit,
  submitFailed,
  submitting,
  children,
  warning,
  handleOnBackToTemplate,
  ...props
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box {...props}>
        <Flex mb="s">
          <Button type="button" buttonStyle="text" size="small" icon="ArrowLeft" onClick={handleOnBackToTemplate} pl="0px">
            {ButtonLabels.back}
          </Button>
        </Flex>
        <Text size="heading3" mb="xl">
          {title}
        </Text>
        <Box display="flex" flexDirection="column" justifyContent="space-between">
          <Box maxHeight="560px" overflow="auto" pr="xs">{children}</Box>
          {warning && (
            <Flex alignItems="center" mb="s">
              <Icon icon="AlertTriangle" size="19" color="amber.60" mr="xxs" />
              <Text color="amber.60">{warning}</Text>
            </Flex>
          )}
          <Box position="relative" mt="s">
            <Button
              type="submit"
              buttonStyle="primary"
              size="medium"
              icon={submitting ? "Loader" : null}
              disabled={submitting}
              iconSpinning={true}
              mr="s"
            >
              {`Create ${InputLabels[props.page]}`}
            </Button>
            {submitFailed && (
              <Text position="absolute" size="small" mb="xxxs" color="red" bottom="-32px">
                {FormErrors.checkFormErrors}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
    </form>
  );
};

const GenericExportForm = reduxForm({
  form: "exports"
})(Form);

export { GenericExportForm };
