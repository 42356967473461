import { SimplifyApi } from "../../utils";
import {call, takeEvery } from "redux-saga/effects";
import { QR_SOURCE, SEND_EVENT} from "../actions/types";

const sendQrInfoService = (qrSource, whereTo, eventName) => SimplifyApi.post(`/v1/custom_event`,
    {eventName: eventName, message: {source: qrSource, whereTo: whereTo}, userValue: 'Guest'} );

const sendEventService =  (eventName, message, userId) => SimplifyApi.post(`/v1/custom_event`,
    {eventName: eventName, message: message, userValue: userId});

function* sendQrSource(action) {
  try {

    const sourceRecorded = action.payload.source;
    const whereTo = action.payload.whereTo;

    let eventName;
    if (whereTo=='mobile'){
      eventName = 'QrCodeScanned';
    }else{
      eventName = 'MarketingRedirect';
    };

    const response = yield call(sendQrInfoService, sourceRecorded, whereTo, eventName);


  }catch (error){
    console.log(error);
  };
};

function* sendEvent(action){
  try {
    const eventName = action.payload.eventName;
    const message = action.payload.message;
    const userId = action.payload.userId;

    const response = yield call(sendEventService, eventName, message, userId);
  }catch (error){
    console.log(error);
  };
};

function* commsSaga() {

  yield takeEvery(QR_SOURCE, sendQrSource);
  yield takeEvery(SEND_EVENT, sendEvent);

};

export default commsSaga;
