import React, { useState } from "react";
import { updatePaymentInviteRequest } from "../../store/actions";
import { Box, Text, Flex } from "../fundamentals";
import { Button } from "../elements";
import { ButtonLabels, Headings, Info, DynamicLabels } from "../../localisation";
import { selectPaymentInviteById, selectArtworkById } from "../../store/selectors";
import { useSelector, useDispatch } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";


const PaymentInviteOfferDetailsModal = ({ paymentInviteId }) => {
  const dispatch = useDispatch();
  const [acceptClicked, setAcceptClicked] = useState(false);
  const [rejectClicked, setRejectClicked] = useState(false);
  const paymentInvite = useSelector((state) => selectPaymentInviteById(state, paymentInviteId));
  const artworkId = paymentInvite?.artworks[0]?._id?.id;
  const artwork = useSelector((state) => selectArtworkById(state, artworkId));

  const offerDetails = paymentInvite?.actions.find((item) => item.group === "offer");
  const currency = offerDetails?.values.find((item) => item.key === "currency").value;
  const offeredAmount = offerDetails?.values.find((item) => item.key === "offeredAmount").value;

  const handleAcceptButtonClick = () => {
    if (!acceptClicked) {
      setAcceptClicked(true);
    } else {
      dispatch(updatePaymentInviteRequest({ paymentInviteId, acceptOffer: true, status:"Viewed", actionId: offerDetails._id, }));
    }
  };

  const handleRejectButtonClick = () => {
    if (!rejectClicked) {
      setRejectClicked(true);
    } else {
      dispatch(updatePaymentInviteRequest({ paymentInviteId, rejectOffer: true, status:"Rejected" }));
    }
  };

  const handleBackButtonClick = () => {
    setAcceptClicked(false);
    setRejectClicked(false);
  };

  return (
    <Box width="540px">
      {/* Confirm accept or reject */}
      {acceptClicked || rejectClicked ? (
        <Box>
          <Text size="heading3" mb="xs" color="black">
            {Headings.sureToProceed}
          </Text>
          <Text mb="l">
            {acceptClicked
              ? Info.acceptOffer(
                  paymentInvite?.contacts[0]?._id?.firstName,
                  paymentInvite?.contacts[0]?._id?.lastName,
                  artwork?.artworkValues?.title
                )
              : Info.rejectOffer(
                  paymentInvite?.contacts[0]?._id?.firstName,
                  paymentInvite?.contacts[0]?._id?.lastName,
                  artwork?.artworkValues?.title
                )}
          </Text>
          <Flex>
            <Button
              buttonStyle={acceptClicked ? "primary" : "warning"}
              size="medium"
              mr="s"
              fontWeight="500"
              onClick={acceptClicked ? handleAcceptButtonClick : handleRejectButtonClick}
            >
              {acceptClicked ? ButtonLabels.acceptOffer : ButtonLabels.rejectOffer}
            </Button>
            <Button buttonStyle="secondary" size="medium" fontWeight="500" onClick={handleBackButtonClick}>
              {ButtonLabels.backToOffer}
            </Button>
          </Flex>
        </Box>
      ) : (
        // Offer Details
        <Box>
          <Text size="heading3" mb="xs" color="black">
            {Headings.offerReceived}
          </Text>
          <Text mb="l" color="grey.80" fontSize="small">
            {Info.paymentInviteOfferDetails}
          </Text>
          <Box border="1px solid #C8CEFF" borderRadius="8px" padding="l" mb="m" fontSize="small">
            <Flex justifyContent="space-between" mb="xs">
              <Text>Offered Amount</Text>
              <Text>
                {getSymbolFromCurrency(currency)}
                {offeredAmount}
              </Text>
            </Flex>
            {paymentInvite?.costs.map((cost) => (
              <Flex key={cost?._id} justifyContent="space-between">
                <Text>{cost?.name}</Text>
                <Text>
                  {getSymbolFromCurrency(currency)}
                  {cost?.value}
                </Text>
              </Flex>
            ))}
            <Flex
              mt="xs"
              pt="xs"
              borderTop="1px solid #C3C2C9"
              justifyContent="space-between"
              color="blue.60"
              fontWeight="500"
            >
              <Text>{DynamicLabels.totalYouReceiveInclVat(artwork?.artworkValues.vat)}</Text>
              <Text>
                {getSymbolFromCurrency(currency)}
                {Math.round((offeredAmount - paymentInvite?.costs[0]?.value) * 100) / 100}
              </Text>
            </Flex>
          </Box>
          <Flex>
            <Button buttonStyle="primary" size="medium" mr="s" fontWeight="500" onClick={handleAcceptButtonClick}>
              {ButtonLabels.accept}
            </Button>
            <Button buttonStyle="warning" size="medium" fontWeight="500" onClick={handleRejectButtonClick}>
              {ButtonLabels.reject}
            </Button>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export { PaymentInviteOfferDetailsModal };
