import React, { useRef, useEffect, useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Element } from "react-scroll";
import { connect, useDispatch, useSelector } from "react-redux";
import { deleteArtworks, clearAllSelectedArtworks, openModal } from "../../store/actions";
import { selectUser } from "../../store/selectors";
import { Field, FieldArray, FormSection, reduxForm, submit, formValueSelector, change } from "redux-form";
import { Box, Flex, Text, Icon } from "../fundamentals";
import {
  Section,
  TabSelect,
  Input,
  Modal,
  PriceInput,
  DimensionsInput,
  InputSelect,
  DropdownSelect,
  CrateFields,
  FloatingActionPill,
  Checkbox,
} from "../elements";
import { MultiInputSelect } from "../react-material/MultiInputSelect";
import { ImageViewerModal, AlertModal } from "../modals";
import { ScrollSectionIndicator, ImageUpload, DocumentUpload } from "../modules";
import { DynamicLabels, InputLabels, InputErrors, Info, Notifications, LinkLabels } from "../../localisation";
import { DEFAULT_ARTWORK_STATUSES, ARTWORK_FORM_SECTIONS, ART_MOVEMENTS, ART_MEDIA } from "../../config";
import { Popover } from "../react-material/Popover";
import { AddressFields } from "../elements";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE,
  NON_SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE,
  ROLE_COLLECTOR,
} from "../../constants";
import { calculateArtworkTotalPublicPrice, createToast } from "../../utils";
import { ReduxFormDateInput } from "../react-material/DateInput/ReduxFormDateInput";

const multiSelectStyle = {
  "& .MuiInputBase-input": {
    height: "38px !important",
    padding: "0 !important",
    margin: "0 !important",
    backgroundColor: "#F3F4FF",
    borderBottom: "2px solid black",
    minWidth: "500px",
  },
};

const Form = ({
  form,
  handleSubmit,
  renderFloatingActionPillButtons,
  reset,
  pristine,
  submitting,
  dirty,
  initialValues,
  singleTab,
  readOnly,
  selectedArtworkIds,
  status,
  modalImages,
  hasFrameData,
  collections,
  isMultiEditMode,
  numArtworks,
  publicPrice,
  vat,
  isVatIncluded,
  framingCostsIncluded,
  packagingCostsIncluded,
  hasRoundedPublicPrice,
  framingCosts,
  packagingCosts,
  submitFailed,
  publishToDiscovery,
  images,
  ...props
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const fixedPillRef = useRef(null);
  const tabRef = useRef(null);
  const [tab, setTab] = useState("values");

  const handleOnEnterSubmitForm = (event) => {
    if (event.keyCode === 13) {
      if (!submitting && !pristine) dispatch(submit(form));
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", handleOnEnterSubmitForm);

    return () => {
      document.removeEventListener("keyup", handleOnEnterSubmitForm);
    };
  });

  useEffect(() => {
    if (submitFailed) {
      createToast({ type: "error", ...Notifications.missingFormValues });
    }
  }, [submitFailed]);

  return (
    <form onSubmit={handleSubmit}>
      <Flex flex="1" flexDirection="column" pl="xl" pr="xl" {...props}>
        {!singleTab && (
          <Box ref={tabRef} mb="xl">
            <TabSelect
              input={{ value: tab, onChange: setTab }}
              options={{ values: "Artwork Details", images: "Images", documents: "Documents" }}
              imagesError={publishToDiscovery && !images.length}
            />
          </Box>
        )}

        <Flex display={tab !== "values" ? "none" : null} justifyContent="center">
          <ScrollSectionIndicator
            sections={singleTab ? ARTWORK_FORM_SECTIONS : _.omit(ARTWORK_FORM_SECTIONS, ["artworkImages"])}
            minWidth="fit-content"
            height="100%"
            position="sticky"
            top={singleTab ? "m" : "mega"}
            mr="xxxl"
          />

          <Box flex="1" maxWidth="710px">
            {singleTab && !isMultiEditMode && <ArtworkImages form={form} readOnly={readOnly} />}
            <ArtworkCollection
              collections={collections}
              readOnly={readOnly}
              isMultiEditMode={isMultiEditMode}
              numArtworks={numArtworks}
            />
            <ArtworkGeneralInfo
              form={form}
              fixedPillRef={fixedPillRef}
              status={status}
              readOnly={readOnly}
              isMultiEditMode={isMultiEditMode}
              numArtworks={numArtworks}
              hasFrameData={hasFrameData}
              singleTab={singleTab}
            />
            <ArtworkPublicDetails
              form={form}
              fixedPillRef={fixedPillRef}
              status={status}
              readOnly={readOnly}
              isMultiEditMode={isMultiEditMode}
              numArtworks={numArtworks}
              publicPrice={publicPrice}
              vat={vat}
              isVatIncluded={isVatIncluded}
              framingCostsIncluded={framingCostsIncluded}
              packagingCostsIncluded={packagingCostsIncluded}
              hasRoundedPublicPrice={hasRoundedPublicPrice}
              framingCosts={framingCosts}
              packagingCosts={packagingCosts}
            />
            <ArtworkLocation
              fixedPillRef={fixedPillRef}
              readOnly={readOnly}
              isMultiEditMode={isMultiEditMode}
              numArtworks={numArtworks}
            />
            <ArtworkValue
              form={form}
              fixedPillRef={fixedPillRef}
              readOnly={readOnly}
              isMultiEditMode={isMultiEditMode}
              numArtworks={numArtworks}
            />
            <ArtworkAdditionalInfo
              fixedPillRef={fixedPillRef}
              readOnly={readOnly}
              isMultiEditMode={isMultiEditMode}
              numArtworks={numArtworks}
              form={form}
            />
          </Box>
        </Flex>

        {!isMultiEditMode && (
          <Flex display={tab !== "images" ? "none" : null}>
            <ArtworkImages form={form} readOnly={readOnly} />
          </Flex>
        )}

        {!isMultiEditMode && (
          <Flex display={tab !== "documents" ? "none" : null}>
            <Box flex="1">
              <DocumentUpload
                form={form}
                groupName="documents"
                disabled={readOnly}
                textTypeOptionsDirection="horizontal"
              />
            </Box>
          </Flex>
        )}

        {isMultiEditMode && (
          <Flex display={tab !== "images" ? "none" : null}>
            <Text fontSize="body" lineHeight="body" color="grey.100" mt="s" mb="xs">
              {"Sorry you can't add/remove images in multi edit mode"}
            </Text>
          </Flex>
        )}

        {isMultiEditMode && (
          <Flex display={tab !== "documents" ? "none" : null}>
            <Text fontSize="body" lineHeight="body" color="grey.100" mt="s" mb="xs">
              {"Sorry you can't add/remove documents in multi edit mode"}
            </Text>
          </Flex>
        )}

        <FloatingActionPill ref={fixedPillRef} inverted={dirty || selectedArtworkIds.length === 0}>
          {renderFloatingActionPillButtons(reset, dirty, pristine, submitting, handleSubmit)}
        </FloatingActionPill>

        <Modal id="image_viewer">
          <ImageViewerModal images={modalImages} />
        </Modal>
        <Modal id="delete_artwork">
          <AlertModal
            title={DynamicLabels.confirmDeleteArtworks(selectedArtworkIds.length)}
            onAccept={() => {
              history.replace("/artworks");
              dispatch(deleteArtworks(selectedArtworkIds));
              dispatch(clearAllSelectedArtworks());
            }}
          />
        </Modal>
      </Flex>
    </form>
  );
};

const ArtworkImages = ({ form, readOnly, disabled }) => {
  const currentUser = useSelector(selectUser);
  const artworksFormAdd = useSelector((state) => state.form.artworks.add);
  const artworksFormAddImagesLength = artworksFormAdd ? artworksFormAdd.values?.images?.length : 0;
  const artworksFormEdit = useSelector((state) => state.form.artworks.edit);
  const artworksFormEditImagesLength = artworksFormEdit ? artworksFormEdit.values?.images?.length : 0;

  return (
    <Element name={ARTWORK_FORM_SECTIONS["artworkImages"].title} style={{ width: "100%" }}>
      <Section {...ARTWORK_FORM_SECTIONS["artworkImages"]} whiteSpace="nowrap">
        <FieldArray
          name="images"
          disabled={
            currentUser.account.accessLevel === 0 &&
            (artworksFormAddImagesLength === 1 || artworksFormEditImagesLength === 1)
          }
          component={ImageUpload}
          form={form}
          urlSuffix="/preview.jpg"
          contained
          withDropbox
          isLogoUpload={false}
          maxImages={currentUser.account.accessLevel === 0 && "1"}
        />
      </Section>
    </Element>
  );
};

const ArtworkCollection = ({ collections, readOnly, isMultiEditMode, numArtworks }) => {
  const renamedCollections = _.map(collections, ({ id, name }) => ({
    id: id,
    name: name === "Discovery" ? "Favourites" : name === "Artworks" ? "Collection" : name,
  }));
  return (
    <Element name={ARTWORK_FORM_SECTIONS["collection"].name}>
      <Section {...ARTWORK_FORM_SECTIONS["collection"]}>
        <Field
          name="collection"
          disabled={readOnly}
          label={InputLabels.collection}
          options={_.map(renamedCollections, ({ id, name }) => ({ label: name, value: { id } }))}
          warning={isMultiEditMode && DynamicLabels.multiCollectionEditWarning(numArtworks)}
          component={InputSelect}
          stateful
        />
      </Section>
    </Element>
  );
};

const ArtworkGeneralInfo = ({
  fixedPillRef,
  form,
  status,
  readOnly,
  isMultiEditMode,
  numArtworks,
  hasFrameData,
  singleTab,
}) => {
  const dispatch = useDispatch();
  const [hasFrame, setHasFrame] = useState(hasFrameData);
  const alphabeticallySortedMovements = ART_MOVEMENTS.sort((a, b) => (a > b ? 1 : -1));
  const alphabeticallySortedMedia = ART_MEDIA.sort((a, b) => (a > b ? 1 : -1));
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  useEffect(() => {
    setHasFrame(hasFrameData);
  }, [hasFrameData]);

  return (
    <Element name={ARTWORK_FORM_SECTIONS["generalInformation"].name}>
      <Section {...ARTWORK_FORM_SECTIONS["generalInformation"]}>
        <Flex justifyContent="space-between" alignItems="end">
          <Field
            name="customId"
            disabled={readOnly || isMultiEditMode}
            label={InputLabels.customId}
            flex="1"
            mr="m"
            preventOverlapRef={fixedPillRef}
            scrollingRef={{ current: window }}
            warning={isMultiEditMode && DynamicLabels.multiArtistEditWarning(numArtworks)}
            component={Input}
            placeholder={isMultiEditMode && "Multiple IDs"}
          />
          <Field
            name="status"
            disabled={readOnly}
            label={InputLabels.status}
            options={DEFAULT_ARTWORK_STATUSES}
            buttonProps={{
              size: "small",
              height: "100%",
              bg: DEFAULT_ARTWORK_STATUSES[status] ? DEFAULT_ARTWORK_STATUSES[status].color : null,
              color: status ? "black" : "grey.80",
              borderRadius: "4px",
              hoverColor: "grey.90",
              ml: "xs",
            }}
            placeholder={InputLabels.status}
            labelSize="small"
            color={"grey.90"}
            mb="m"
            flex="1"
            clearable
            preventDefault
            warning={isMultiEditMode && DynamicLabels.multiStatusEditWarning(numArtworks)}
            component={DropdownSelect}
          />
        </Flex>
        <Field
          name="artist"
          disabled={readOnly}
          label={InputLabels.artist}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiArtistEditWarning(numArtworks)}
          component={Input}
        />

        <Field
          name="title"
          disabled={readOnly}
          label={InputLabels.title}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiTitleEditWarning(numArtworks)}
          component={Input}
        />

        <Flex justifyContent="space-between">
          <Field
            name="year"
            disabled={readOnly}
            label={InputLabels.year}
            preventOverlapRef={fixedPillRef}
            scrollingRef={{ current: window }}
            warning={isMultiEditMode && DynamicLabels.multiYearEditWarning(numArtworks)}
            flex="1"
            mr="m"
            component={Input}
          />

          <Field
            name="edition"
            disabled={readOnly}
            label={InputLabels.edition}
            preventOverlapRef={fixedPillRef}
            scrollingRef={{ current: window }}
            warning={isMultiEditMode && DynamicLabels.multiEditionEditWarning(numArtworks)}
            flex="1"
            component={Input}
          />
        </Flex>

        <Field
          name="technique"
          disabled={readOnly}
          label={InputLabels.technique}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiTechniqueEditWarning(numArtworks)}
          component={Input}
        />

        <Field
          name="seriesTitle"
          disabled={readOnly}
          label={InputLabels.seriesTitle}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiSeriesTitleEditWarning(numArtworks)}
          component={Input}
        />

        {isMultiEditMode && (
          <Flex alignItems="center" color={"amber.90"} overflow="hidden" height={24} mb="xxs">
            <Icon icon="Info" size="14" mr="xxxs" />
            <Text fontSize="smaller" lineHeight="button">
              {DynamicLabels.multiDimensionsEditWarning(numArtworks)}
            </Text>
          </Flex>
        )}

        <DimensionsInput
          name="dimensions"
          form={form}
          readOnly={readOnly}
          preventOverlapRef={fixedPillRef}
          variable
          diameter
        />

        <Checkbox
          input={{
            value: hasFrame,
            onChange: (hasFrame) => {
              setHasFrame(hasFrame);
              if (!hasFrame) dispatch(change(form, "frame", null));
            },
          }}
          label={InputLabels.isFramed}
          labelSize="small"
          mb="l"
          disabled={readOnly}
        />

        {hasFrame && (
          <FormSection name="frame">
            <Field
              name="typeOfFrame"
              label={InputLabels.typeOfFrame}
              disabled={readOnly}
              scrollingRef={{ current: window }}
              preventOverlapRef={fixedPillRef}
              component={Input}
            />
            <DimensionsInput name="dimensions" readOnly={readOnly} preventOverlapRef={fixedPillRef} />
          </FormSection>
        )}

        <Box marginBottom="16px">
          <Text fontSize="small" lineHeight="button" color="grey.90" mb="xxxs">
            {InputLabels.movement}
          </Text>
          <Field
            name="movement"
            disabled={readOnly}
            options={_.map(alphabeticallySortedMovements, (item) => ({ label: item, value: item, key: item }))}
            warning={isMultiEditMode && DynamicLabels.multiMovementEditWarning(numArtworks)}
            component={MultiInputSelect}
            stateful
            preventOverlapRef={fixedPillRef}
            scrollingRef={{ current: window }}
            sx={multiSelectStyle}
          />
        </Box>
        <Field
          name="medium"
          disabled={readOnly}
          label={InputLabels.medium}
          options={_.map(alphabeticallySortedMedia, (item) => ({ label: item, value: item, key: item }))}
          warning={isMultiEditMode && DynamicLabels.multiMediumEditWarning(numArtworks)}
          component={InputSelect}
          stateful
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          flex="1"
        />
        {singleTab && (
          <Box flex="1">
            <Flex justifyContent="space-between" mt="35px" mb="s">
              <Text color="grey.90" size="small">
                {InputLabels.additionalText}
              </Text>
              <Icon
                icon="HelpCircle"
                size="16"
                color="grey.90"
                cursor="pointer"
                onClick={(e) => setPopoverAnchorEl(e.currentTarget)}
              />
              <Popover
                popoverAnchorEl={popoverAnchorEl}
                setPopoverAnchorEl={setPopoverAnchorEl}
                text={Info.additionalTextExplanation}
                icon="Info"
              />
            </Flex>
            <DocumentUpload form={form} groupName="documents" disabled={readOnly} />
          </Box>
        )}
      </Section>
    </Element>
  );
};

const ArtworkPublicDetails = ({
  fixedPillRef,
  form,
  status,
  readOnly,
  isMultiEditMode,
  numArtworks,
  publicPrice,
  vat,
  isVatIncluded,
  framingCostsIncluded,
  packagingCostsIncluded,
  hasRoundedPublicPrice,
  framingCosts,
  packagingCosts,
}) => {
  const [locationPopoverAnchorEl, setLocationPopoverAnchorEl] = useState(null);
  const [totalPublicPricePopoverAnchorEl, setTotalPublicPricePopoverAnchorEl] = useState(null);
  const [totalPublicPrice, setTotalPublicPrice] = useState(0);
  const user = useSelector(selectUser);
  const processingFee =
    user.account.accessLevel === 0
      ? NON_SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE
      : SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE;

  function numberWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    setTotalPublicPrice(
      calculateArtworkTotalPublicPrice(
        user.account.accessLevel,
        publicPrice,
        vat,
        isVatIncluded,
        packagingCosts,
        framingCosts,
        packagingCostsIncluded,
        framingCostsIncluded,
        hasRoundedPublicPrice
      )
    );
  }, [
    publicPrice,
    vat,
    isVatIncluded,
    framingCosts,
    packagingCosts,
    framingCostsIncluded,
    packagingCostsIncluded,
    hasRoundedPublicPrice,
  ]);

  return (
    <Element name={ARTWORK_FORM_SECTIONS["publicDetails"].name}>
      <Section {...ARTWORK_FORM_SECTIONS["publicDetails"]}>
        <Field
          name="workDescription"
          disabled={readOnly}
          label={InputLabels.publicWorkDescription}
          type="textarea"
          minHeight="100px"
          mb="l"
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiWorkDescriptionEditWarning(numArtworks)}
          component={Input}
          hasExplanation={true}
          explanationText={Info.publicWorkDescriptionExplanation}
        />
        <Flex justifyContent="space-between">
          <PriceInput
            name="publicPrice"
            form={form}
            disabled={readOnly}
            label={InputLabels.publicPrice}
            preventOverlapRef={fixedPillRef}
            scrollingRef={{ current: window }}
            warning={isMultiEditMode && DynamicLabels.multiPublicPriceEditWarning(numArtworks)}
            hasExplanation={true}
            explanationText={Info.publicPriceExplanation(processingFee)}
            explanationLinkText={processingFee === 9 && `${LinkLabels.checkOutPaidSubscription})`}
            explanationAction={openModal("upgrade")}
            minHeight="38px"
          />
          <Box flex="1" ml="m">
            <Flex justifyContent="space-between" mb="xxxs">
              <Text color="grey.90" size="small">
                {InputLabels.totalPublicPrice}
              </Text>
              <Icon
                icon="HelpCircle"
                size="16"
                color="grey.90"
                cursor="pointer"
                onClick={(e) => setTotalPublicPricePopoverAnchorEl(e.currentTarget)}
              />
              <Popover
                popoverAnchorEl={totalPublicPricePopoverAnchorEl}
                setPopoverAnchorEl={setTotalPublicPricePopoverAnchorEl}
                text={Info.totalPublicPriceExplanation(processingFee)}
                linkText={processingFee === 9 && `${LinkLabels.checkOutPaidSubscription})`}
                action={openModal("upgrade")}
                icon="Info"
              />
            </Flex>
            <Flex
              alignItems="center"
              backgroundColor="grey.10"
              height="39px"
              fontSize="small"
              borderBottom="2px solid black"
              p="2px 8px"
            >
              <Text mr="m">{getSymbolFromCurrency(publicPrice?.currency || getSymbolFromCurrency("EUR"))}</Text>
              <Text>{publicPrice ? numberWithCommas(totalPublicPrice) : ""}</Text>
            </Flex>
          </Box>
        </Flex>
        <Flex alignItems="center">
          <Field
            flex="1"
            mr="m"
            name="vat"
            label={InputLabels.vat}
            disabled={readOnly || !publicPrice}
            component={Input}
            suffix="%"
            options={{ type: "number" }}
          />
          <Field
            flex="3"
            name="isVatIncluded"
            label={InputLabels.vatIncluded}
            labelSize="small"
            disabled={readOnly || !publicPrice}
            component={Checkbox}
          />
        </Flex>
        <Field
          name="hasRoundedPublicPrice"
          label={InputLabels.roundPriceUp}
          labelSize="small"
          disabled={readOnly || (!isMultiEditMode && !publicPrice)}
          component={Checkbox}
        />
        <Flex>
          <Field
            name="framingCostsIncluded"
            label={InputLabels.includeFramingCosts}
            labelSize="small"
            disabled={readOnly || (!isMultiEditMode && !publicPrice)}
            component={Checkbox}
          />
          <Field
            name="packagingCostsIncluded"
            label={InputLabels.includePackagingCosts}
            labelSize="small"
            disabled={readOnly || (!isMultiEditMode && !publicPrice)}
            component={Checkbox}
          />
        </Flex>
        <FormSection name="publicLocation">
          <Flex justifyContent="space-between" mt="35px" mb="s">
            <Text fontSize="small" color="grey.90">
              {InputLabels.publicLocation}
            </Text>
            <Icon
              icon="HelpCircle"
              size="16"
              color="grey.90"
              cursor="pointer"
              onClick={(e) => setLocationPopoverAnchorEl(e.currentTarget)}
            />
            <Popover
              popoverAnchorEl={locationPopoverAnchorEl}
              setPopoverAnchorEl={setLocationPopoverAnchorEl}
              text={Info.publicLocationExplanation}
              icon="Info"
            />
          </Flex>
          <Field
            name="sameAsOrganisation"
            label={InputLabels.sameAsOrganisationSettings}
            labelSize="small"
            disabled={readOnly}
            component={Checkbox}
            mb="s"
          />
          <Field name="organisationName" label={InputLabels.organisationName} disabled={readOnly} component={Input} />
          <Flex justifyContent="space-between">
            <Field
              flex="1"
              name="firstName"
              label={InputLabels.firstName}
              disabled={readOnly}
              component={Input}
              mr="m"
            />
            <Field flex="1" name="lastName" label={InputLabels.lastName} disabled={readOnly} component={Input} />
          </Flex>
          <Field name="phone" label={InputLabels.phone} disabled={readOnly} component={Input} />
          <AddressFields mb="0" />
        </FormSection>
        <Field
          name="isWoodCrated"
          label={InputLabels.convelioPackaging}
          labelSize="small"
          disabled={readOnly}
          component={Checkbox}
          mb="s"
          hasExplanation={true}
          explanationText={Info.convelioGuidelines}
          explanationLinkText={"here."}
          explanationLink={"https://help.convelio.com/en/articles/5150876-what-are-your-packing-guidelines"}
        />
        <Field
          name="publishToDiscovery"
          label={InputLabels.publishToDiscovery}
          labelSize="small"
          disabled={readOnly || user.role === ROLE_COLLECTOR || status !== "available"}
          component={Checkbox}
          hasExplanation={true}
          explanationText={
            user.role === ROLE_COLLECTOR
              ? Info.discoveryForCollectors
              : status !== "available"
              ? Info.discoveryForAvailableArtworks
              : Info.publishToDiscoveryExplanation
          }
          description={Info.discoveryDesc}
          flex="1"
        />
      </Section>
    </Element>
  );
};

const ArtworkLocation = ({ fixedPillRef, readOnly, isMultiEditMode, numArtworks }) => {
  return (
    <Element name={ARTWORK_FORM_SECTIONS["artworkLocation"].name}>
      <Section {...ARTWORK_FORM_SECTIONS["artworkLocation"]}>
        <Field
          name="location"
          disabled={readOnly}
          label={InputLabels.location}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiLocationEditWarning(numArtworks)}
          component={Input}
        />

        <Field
          name="position"
          disabled={readOnly}
          label={InputLabels.position}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiPositionEditWarning(numArtworks)}
          component={Input}
        />

        <Field
          name="currentOwner"
          disabled={readOnly}
          label={InputLabels.currentOwner}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiCurrentOwnerEditWarning(numArtworks)}
          component={Input}
        />

        <Field
          name="source"
          disabled={readOnly}
          label={InputLabels.source}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiSourceEditWarning(numArtworks)}
          component={Input}
        />
      </Section>
    </Element>
  );
};

const ArtworkValue = ({ fixedPillRef, readOnly, isMultiEditMode, numArtworks, form }) => {
  return (
    <Element name={ARTWORK_FORM_SECTIONS["artworkValue"].name}>
      <Section {...ARTWORK_FORM_SECTIONS["artworkValue"]}>
        <PriceInput
          name="price"
          form={form}
          disabled={readOnly}
          label={InputLabels.price}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiPriceEditWarning(numArtworks)}
          minHeight="38px"
        />

        <PriceInput
          name="insuranceValue"
          form={form}
          disabled={readOnly}
          label={InputLabels.insuranceValue}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiInsuranceValueEditWarning(numArtworks)}
          minHeight="38px"
        />

        <PriceInput
          name="productionCosts"
          form={form}
          disabled={readOnly}
          label={InputLabels.productionCosts}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiProductionCostsEditWarning(numArtworks)}
          minHeight="38px"
        />

        <PriceInput
          name="framingCosts"
          form={form}
          disabled={readOnly}
          label={InputLabels.framingCosts}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiFramingCostsEditWarning(numArtworks)}
          minHeight="38px"
        />

        <PriceInput
          name="packagingCosts"
          form={form}
          disabled={readOnly}
          label={InputLabels.packagingCosts}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiPackagingCostsEditWarning(numArtworks)}
          minHeight="38px"
        />
      </Section>
    </Element>
  );
};

const ArtworkAdditionalInfo = ({
  fixedPillRef,
  readOnly,
  isMultiEditMode,
  numArtworks,
  form,
}) => {

  const dateInputStyling = {
    borderBottom: "1px solid black",
    padding: "8px 8px 4px 8px",
    backgroundColor: "#F3F4FF",
    "&.Mui-error": {
      background: "#FFDFDA",
      borderBottom: "1px solid red"
    },
  }
  return (
    <Element name={ARTWORK_FORM_SECTIONS["additionalInformation"].name}>
      <Section {...ARTWORK_FORM_SECTIONS["additionalInformation"]}>
        <Field
          name="photoCopyrightOwner"
          disabled={readOnly}
          label={InputLabels.photoCopyrightOwner}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiPhotoCopyrightOwnerEditWarning(numArtworks)}
          component={Input}
        />
        <Box mb="s">
        <Text fontSize="small" color="grey.90" mb="xxxs">{InputLabels.incomingDate}</Text>
          <Field
            name="incomingDate"
            disabled={readOnly}
            preventOverlapRef={fixedPillRef}
            scrollingRef={{ current: window }}
            warning={isMultiEditMode && DynamicLabels.multiIncomingDateEditWarning(numArtworks)}
            component={ReduxFormDateInput}
            customInputStyles={{ maxWidth: "90px", fontSize: "14px" }}
            customRootStyles={dateInputStyling}
            clearable
          />
        </Box>

        <CrateFields
          groupName="packaging.crates"
          disabled={readOnly}
          sortBy="openingOrder"
          isMultiEditMode={isMultiEditMode}
          numArtworks={numArtworks}
          form={form}
        />

        <Field
          name="packaging.instructions"
          disabled={readOnly}
          label={InputLabels.installationInstructions}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiPackagingInstructionsEditWarning(numArtworks)}
          type="textarea"
          minHeight="100px"
          component={Input}
        />

        <Field
          name="notes"
          disabled={readOnly}
          label={InputLabels.notes}
          type="textarea"
          minHeight="100px"
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiNotesEditWarning(numArtworks)}
          component={Input}
        />
      </Section>
    </Element>
  );
};

const validate = (values, ownProps) => {
  const errors = {};
  const {
    collection,
    artist,
    title,
    year,
    technique,
    dimensions: { height, width, depth, diameter, variable: dimensionsVariable } = {},
    workDescription,
    publicPrice,
    vat,
    framingCostsIncluded,
    packagingCostsIncluded,
    publicLocation: { organisationName, firstName, lastName, phone, address } = {},
    publishToDiscovery,
    framingCosts,
    packagingCosts,
    documents,
    packaging,
    images,
    incomingDate,
  } = values;

  if (!collection || collection.id === "") {
    errors.collection = InputErrors.noCollection;
  }

  if ((!artist && !ownProps.isMultiEditMode) || (_.trim(title) === "" && !ownProps.isMultiEditMode)) {
    errors.artist = InputErrors.noArtist;
  } else if (collection && ownProps.collections[collection.id].type === "production" && artist !== ownProps.user.name) {
    errors.artist = InputErrors.notUserArtist;
  }

  if ((!title && !ownProps.isMultiEditMode) || (_.trim(title) === "" && !ownProps.isMultiEditMode)) {
    errors.title = InputErrors.noTitle;
  }

  if (!dimensionsVariable && (height || depth) && !width) {
    errors.dimensions = { width: InputErrors.noWidthError };
  }
  if (!dimensionsVariable && !height && (width || depth)) {
    errors.dimensions = { height: InputErrors.noHeightError };
  }

  if (publicPrice && framingCosts && framingCostsIncluded) {
    if (publicPrice.currency !== framingCosts.currency) {
      errors.framingCostsIncluded = InputErrors.multipleCurrencies;
    }
  }

  if (publicPrice && packagingCosts && packagingCostsIncluded) {
    if (publicPrice.currency !== packagingCosts.currency) {
      errors.framingCostsIncluded = InputErrors.multipleCurrencies;
    }
  }

  if (publicPrice) {
    if (!vat) {
      errors.vat = InputErrors.noVat;
    } else if (vat > 100) {
      errors.vat = InputErrors.maxVat100;
    }
  }

  if (documents) {
    const documentsWithoutType = documents.filter((document) => document.files && !document.documentType);
    if (documentsWithoutType.length) {
      errors.documents = { _error: InputErrors.typeForEachDocument };
    }
  }

  if (incomingDate?.invalid?.reason) {
    errors.incomingDate = InputErrors.invalidDate
  }
  // Validation for artworks published to Discovery
  if (publishToDiscovery) {
    if (!year) {
      errors.year = InputErrors.noYear;
    }
    if (!technique) {
      errors.technique = InputErrors.noTechnique;
    }
    if ((!height || !width) && !diameter && !dimensionsVariable) {
      errors.dimensions = { variable: InputErrors.noDimensions };
    }
    if (!workDescription) {
      errors.workDescription = InputErrors.noWorkDescription;
    }
    if (!publicPrice) {
      errors.publicPrice = { value: InputErrors.noPublicPrice };
    }
    if (!organisationName) {
      errors.publicLocation = { organisationName: InputErrors.noOrganisation };
    }
    if (!firstName) {
      errors.publicLocation = { firstName: InputErrors.noFirstName };
    }
    if (!lastName) {
      errors.publicLocation = { lastName: InputErrors.noLastName };
    }
    if (!phone) {
      errors.publicLocation = { phone: InputErrors.noPhoneNumber };
    }
    if (!address?.addressLine1) {
      errors.publicLocation = { address: { addressLine1: InputErrors.noAddress } };
    }
    if (!address?.city) {
      errors.publicLocation = { address: { city: InputErrors.noCity } };
    }
    if (!address?.country) {
      errors.publicLocation = { address: { country: InputErrors.noCountry } };
    }
    if (!address?.postalCode) {
      errors.publicLocation = { address: { postalCode: InputErrors.noPostalCode } };
    }
    if (!images.length) {
      errors.images = { _error: InputErrors.noImages };
    }

    if (packaging?.crates.length) {
      packaging.crates.forEach((crate, index) => {
        if (!crate.dimensions?.height) {
          errors.packaging = { crates: { [index]: { dimensions: { height: InputErrors.noHeight } } } };
        }
        if (!crate.dimensions?.width) {
          errors.packaging = { crates: { [index]: { dimensions: { width: InputErrors.noWidth } } } };
        }
        if (!crate.dimensions?.depth) {
          errors.packaging = { crates: { [index]: { dimensions: { depth: InputErrors.noDepth } } } };
        }
        if (!crate.weight) {
          errors.packaging = { crates: { [index]: { weight: { value: InputErrors.noWeight } } } };
        }
      });
    }
  }

  return errors;
};

let ArtworkForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(Form);

const selector = (form, ...other) => formValueSelector(form)(...other);

const mapStateToProps = (state, initialProps) => {
  const { user, archive } = state;
  const status = selector(initialProps.form, state, "status");
  const modalImages = selector(initialProps.form, state, "images");
  const frame = selector(initialProps.form, state, "frame");
  const hasFrameData = !_.isNil(frame);
  const publicPrice = selector(initialProps.form, state, "publicPrice");
  const vat = selector(initialProps.form, state, "vat");
  const isVatIncluded = selector(initialProps.form, state, "isVatIncluded");
  const framingCostsIncluded = selector(initialProps.form, state, "framingCostsIncluded");
  const packagingCostsIncluded = selector(initialProps.form, state, "packagingCostsIncluded");
  const hasRoundedPublicPrice = selector(initialProps.form, state, "hasRoundedPublicPrice");
  const framingCosts = selector(initialProps.form, state, "framingCosts");
  const packagingCosts = selector(initialProps.form, state, "packagingCosts");
  const publishToDiscovery = selector(initialProps.form, state, "publishToDiscovery");
  const images = selector(initialProps.form, state, "images");

  return {
    user,
    collections: archive.collections.byId,
    status,
    modalImages,
    hasFrameData,
    publicPrice,
    vat,
    isVatIncluded,
    framingCostsIncluded,
    packagingCostsIncluded,
    hasRoundedPublicPrice,
    framingCosts,
    packagingCosts,
    publishToDiscovery,
    images,
  };
};

ArtworkForm = connect(mapStateToProps, null)(ArtworkForm);

export { ArtworkForm };
