import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Flex, Text,  } from "../fundamentals";
import { useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../store/selectors";
import { ButtonLabels, Headings, Info } from "../../localisation";
import { DiscoveryArtworksGrid, DiscoveryArtworkDetails, DiscoveryFilterBar, FavouriteArtworksGrid } from "../modules";
import { Button, Drawer } from "../elements";
import { getDiscoveryLoadRequest, createSessionDiscovery, updateDiscoveryFilters } from "../../store/actions";
import { NavigationBar, NonUserNavigationBar } from "../react-material/NavigationBar";
import { Button as MuiButton } from "@mui/material";
import { Tooltip } from "../react-material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { DiscoveryFooter } from "../react-material/Footer";
import { DiscoveryFiltersDrawer } from "../react-material/drawers";

const StyledPublicDiscoveryScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: white;
`;

const StyledButton = styled(MuiButton)((props) => ({
  "&.MuiButton-root": {
    fontSize: "14px",
    padding: props.padding ?? "6px 8px",
    marginRight: props.marginRight ?? "24px",
    lineHeight: "16px",
    fontWeight: 500,
    color: props.textColor ?? "black",
    borderRadius: props.borderRadius ?? "8px",
    backgroundColor: props.bgColor ?? "transparent",
    width: props.width,
    height: props.height,
    maxHeight: "24px",
    "&:hover": {
      background: props.hoverColor ?? "#F4F4F5",
    },
  },
}));

const PublicDiscoveryScreen = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsScreenMobile();
  const [expandedFilter, setExpandedFilter] = useState(null);
  const params = new URLSearchParams(location.search);
  const discoveryEventId = params.get("eventId");
  const discoveryArtworkId = params.get("artworkId");
  const discoveryState = useSelector((state) => state.discovery);
  const sessionId = discoveryState.sessionId;
  const user = useSelector(selectUser);
  const isLoggedInUser = Object.keys(user).length > 0;

  useEffect(() => {
    if (!sessionId) {
      const isUser = user.hasOwnProperty("id");
      const genRanHex = (size) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
      const tempSessionId = genRanHex(24);
      dispatch(createSessionDiscovery(tempSessionId, isUser));
      if (discoveryArtworkId) {
        // Clear any previously selected events
        dispatch(updateDiscoveryFilters({ events: [] }));
        dispatch(getDiscoveryLoadRequest(tempSessionId, [], false, discoveryArtworkId));
      } else if (discoveryEventId) {
        dispatch(updateDiscoveryFilters({ events: [discoveryEventId] }));
        dispatch(getDiscoveryLoadRequest(tempSessionId, [discoveryEventId], false));
      } else {
        dispatch(getDiscoveryLoadRequest(tempSessionId, [], false));
      }
    } else {
      if (discoveryArtworkId) {
        // Clear any previously selected events
        dispatch(updateDiscoveryFilters({ events: [] }));
        dispatch(getDiscoveryLoadRequest(sessionId, [], false, discoveryArtworkId));
      } else if (discoveryEventId) {
        dispatch(updateDiscoveryFilters({ events: [discoveryEventId] }));
        dispatch(getDiscoveryLoadRequest(sessionId, [discoveryEventId], false));
      }
    }
  }, []);

  const handleBackToAllClick = () => {
    if (location.pathname === "/discovery/artworks/details") {
      history.push("/discovery");
    } else {
      history.push("/discovery/artworks/favourites");
    }
  };

  const handleGetFavouritesClick = () => {
    history.push("/discovery/artworks/favourites");
  };

  return (
    <StyledPublicDiscoveryScreen>
      <Helmet>
        <title>Discovery | simplify.art</title>
        <meta name="description" content="Sign in to your simplify.art account to access your collection" />
      </Helmet>
      <Box minHeight="100vh">
        {/* Navbar */}
        {isLoggedInUser ? <NavigationBar /> : <NonUserNavigationBar />}
        {/* Main */}
        <Box>
          {/* Header */}
          <Box padding={isMobile ? "22px 20px" : "24px 48px"} borderBottom="1px solid #E8E7EA">
            <Text fontFamily="bodyDmSans" fontSize="heading2" mb="xs">
              {Headings.discovery}
            </Text>
            <Flex justifyContent="space-between" alignItems="center">
              <Flex>
                <StyledButton
                  bgColor={
                    ["/discovery", "/discovery/artworks/details"].includes(location.pathname)
                      ? "#DBDADF"
                      : "transparent"
                  }
                  onClick={() => history.push("/discovery")}
                >
                  {ButtonLabels.artworks}
                </StyledButton>
                {/* Rest of buttons currently hidden */}
                {/* <StyledButton
                  bgColor={["/discovery/artists", "/discovery/artists/details"].includes(location.pathname) ? "#DBDADF" : "transparent"}
                  onClick={() => history.push("/discovery/artists")}
                >
                  {ButtonLabels.artists}
                </StyledButton>
                <StyledButton
                  bgColor={["/discovery/events", "/discovery/events/details"].includes(location.pathname) ? "#DBDADF" : "transparent"}
                  onClick={() => history.push("/discovery/events")}
                >
                  {ButtonLabels.events}
                </StyledButton> */}
              </Flex>
              <Flex alignItems="center">
                {location.pathname.includes("details") && (
                  <Tooltip title="Back to All">
                    <Button
                      buttonStyle={isMobile ? "primary" : "text"}
                      padding={!isMobile && "0px"}
                      icon="ArrowLeft"
                      size="medium"
                      fontWeight="500"
                      iconCursor="pointer"
                      alignSelf="center"
                      onClick={handleBackToAllClick}
                    >
                      {isMobile ? null : ButtonLabels.back}
                    </Button>
                  </Tooltip>
                )}
                {isLoggedInUser && (
                  <Tooltip
                    title={Info.discoveryFavourites}
                    fontSize="16px"
                    color="white"
                    bgColor="#5E54FF"
                    padding="24px"
                    lineHeight="24px"
                    placement="bottom-end"
                  >
                    <Button
                      buttonStyle="primary"
                      // color="grey.70"
                      icon="Heart"
                      size="medium"
                      iconCursor="pointer"
                      alignSelf="center"
                      ml="s"
                      onClick={handleGetFavouritesClick}
                    />
                  </Tooltip>
                )}
              </Flex>
            </Flex>
          </Box>
          {/* Filters */}
          {location.pathname === "/discovery" && discoveryState.loaded && (
            <DiscoveryFilterBar setExpandedFilter={setExpandedFilter} />
          )}
          {/* Grid or artwork details */}
          {discoveryState.isLoading ? (
            <Flex justifyContent="center" alignItems="center" pb="mega" minHeight="50vh">
              <CircularProgress sx={{ color: "#5E54FF" }} />
            </Flex>
          ) : (
            <Box p={isMobile ? "16px 20px" : "xxl"}>
              {location.pathname === "/discovery" && <DiscoveryArtworksGrid />}
              {location.pathname === "/discovery/artworks/details" && <DiscoveryArtworkDetails />}
              {location.pathname === "/discovery/artworks/favourites" && <FavouriteArtworksGrid />}
              {location.pathname === "/discovery/artworks/favourites/details" && <DiscoveryArtworkDetails />}
            </Box>
          )}
        </Box>
        {!isLoggedInUser && <DiscoveryFooter />}
      </Box>
      <Drawer id="discovery.filters" padding="0">
        <DiscoveryFiltersDrawer expandedFilter={expandedFilter} setExpandedFilter={setExpandedFilter} />
      </Drawer>
    </StyledPublicDiscoveryScreen>
  );
};

export default PublicDiscoveryScreen;
