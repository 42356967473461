import {
    CREATE_VIEWING_ROOM,
    CREATE_VIEWING_ROOM_LOCALLY,
    CREATE_VIEWING_ROOM_SUCCESS,
    CREATE_VIEWING_ROOM_FAILURE,
    GET_VIEWING_ROOMS_REQUEST,
    GET_VIEWING_ROOMS_SUCCESS,
    CHECK_VIEWING_ROOM_FOR_PASSWORD,
    CHECK_VIEWING_ROOM_FOR_PASSWORD_SUCCESS,
    GET_VIEWING_ROOM_REQUEST,
    GET_VIEWING_ROOM_SUCCESS,
    GET_VIEWING_ROOM_FAILURE,
    EDIT_VIEWING_ROOM_REQUEST,
    EDIT_VIEWING_ROOM_LOCALLY,
    EDIT_VIEWING_ROOM_SUCCESS,
    EDIT_VIEWING_ROOM_FAILURE,
    DELETE_VIEWING_ROOMS,
    DELETE_VIEWING_ROOM_REQUEST,
    DELETE_VIEWING_ROOM_SUCCESS,
    DELETE_VIEWING_ROOM_FAILURE,
    SAVE_VIEWING_ROOM_DRAFT,
    SAVE_VIEWING_ROOM_DRAFT_LOCALLY,
    SAVE_VIEWING_ROOM_DRAFT_SUCCESS,
    SAVE_VIEWING_ROOM_DRAFT_FAILURE,
  } from "./types";

  export const createViewingRoom = ({ artworks, ...formValues }, tempViewingRoomId) => {
    return {
      type: CREATE_VIEWING_ROOM,
      payload: {
        tempViewingRoomId,
        dateCreated: new Date().toISOString(),
        artworks,
        ...formValues,
      },
    };
  };
  
  export const createViewingRoomLocally = (viewingRoom) => {
    return {
      type: CREATE_VIEWING_ROOM_LOCALLY,
      payload: { ...viewingRoom },
    };
  };

  export const createViewingRoomSuccess = (tempViewingRoomId, response) => {
    return { type: CREATE_VIEWING_ROOM_SUCCESS, payload: { tempViewingRoomId, ...response } };
  };
  
  export const createViewingRoomFailure = () => {
    return { type: CREATE_VIEWING_ROOM_FAILURE, payload: {} };
  };

  export const checkViewingRoomForPassword = (viewingRoomId) => {
    return { type: CHECK_VIEWING_ROOM_FOR_PASSWORD, payload: viewingRoomId };
  };
  
  export const checkViewingRoomForPasswordSuccess = () => {
    return { type: CHECK_VIEWING_ROOM_FOR_PASSWORD_SUCCESS, payload: [] };
  };

  export const getViewingRoomsRequest = () => {
    return { type: GET_VIEWING_ROOMS_REQUEST };
  };

  export const getViewingRoomsSuccess = (viewingRooms) => {
    return { type: GET_VIEWING_ROOMS_SUCCESS, payload: viewingRooms };
  };

  export const getViewingRoomsFailure = () => {
    return { type: GET_VIEWING_ROOMS_REQUEST, payload: [] };
  };

  export const getViewingRoomRequest = (viewingRoomId, submittedPassword) => {
    return { type: GET_VIEWING_ROOM_REQUEST, payload: {viewingRoomId, submittedPassword} }
  };
  
  export const getViewingRoomSuccess = (viewingRoom) => {
    return { type: GET_VIEWING_ROOM_SUCCESS, payload: {...viewingRoom} };
  };
  
  export const getViewingRoomFailure = () => {
    return { type: GET_VIEWING_ROOM_FAILURE, payload: [] };
  };

  export const editViewingRoomRequest = ({ _id, selectedArtworks, isActive, wasViewingRoomDraft, ...formValues }) => {
    return {
      type: EDIT_VIEWING_ROOM_REQUEST,
      payload: {
        _id,
        lastModified: new Date().toISOString(),
        wasViewingRoomDraft,
        ...formValues,
        isActive,
        artworks: selectedArtworks,
      },
    };
  };
  
  export const editViewingRoomLocally = (viewingRoom) => {
    return { type: EDIT_VIEWING_ROOM_LOCALLY, payload: { ...viewingRoom } };
  };
  
  export const editViewingRoomSuccess = (viewingRoomId, response) => {
    return { type: EDIT_VIEWING_ROOM_SUCCESS, payload: { viewingRoomId, response } };
  };
  
  export const editViewingRoomFailure = () => {
    return { type: EDIT_VIEWING_ROOM_FAILURE, payload: {} };
  };

  export const deleteViewingRooms = (viewingRoomIds) => {
    return { type: DELETE_VIEWING_ROOMS, payload: { viewingRoomIds } };
  };
  
  export const deleteViewingRoom = (viewingRoomId) => {
    return { type: DELETE_VIEWING_ROOM_REQUEST, payload: { viewingRoomId } };
  };
  
  export const deleteViewingRoomSuccess = (viewingRoomId) => {
    return { type: DELETE_VIEWING_ROOM_SUCCESS, payload: { viewingRoomId } };
  };
  
  export const deleteViewingRoomFailure = (viewingRoomId) => {
    return { type: DELETE_VIEWING_ROOM_FAILURE, payload: { viewingRoomId } };
  };

  export const saveViewingRoomDraft = ({ artworks, ...formValues }, tempViewingRoomId) => {
    return {
      type: SAVE_VIEWING_ROOM_DRAFT,
      payload: {
        tempViewingRoomId,
        dateCreated: new Date().toISOString(),
        artworks,
        ...formValues,
      },
    };
  };
  
  export const saveViewingRoomDraftLocally = (viewingRoom) => {
    return {
      type: SAVE_VIEWING_ROOM_DRAFT_LOCALLY,
      payload: { ...viewingRoom },
    };
  };

  export const saveViewingRoomDraftSuccess = (tempViewingRoomId, response) => {
    return { type: SAVE_VIEWING_ROOM_DRAFT_SUCCESS, payload: { tempViewingRoomId, ...response } };
  };
  
  export const saveViewingRoomDraftFailure = () => {
    return { type: SAVE_VIEWING_ROOM_DRAFT_FAILURE, payload: {} };
  }
