import React from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import _ from "lodash";

import { Flex, Box } from "../fundamentals";
import { Button } from ".";

const StyledTabItem = styled(Box)`
  border-bottom: 2px solid;
  ${variant({
    prop: "active",
    variants: {
      true: { borderColor: "blue.60" },
      false: {
        borderColor: "transparent",
        "&:hover": {
          borderBottom: "2px solid",
          borderColor: "grey.80"
        }
      }
    }
  })}
`;

export const TabSelect = ({ input, meta, options, imagesError, ...props }) => {
  return (
    <Flex borderBottom="1px solid" borderColor="grey.20" {...props}>
      {_.map(options, (label, value) => {
        const active = input.value === value;
        return (
          <StyledTabItem key={value} active={active}>
            <Button
              preventDefault
              buttonStyle="text"
              size="medium"
              onClick={() => input.onChange(value)}
              color={imagesError && label === "Images" ? "red" : !active ? "grey.80" : null}
              hoverColor={!active ? "grey.80" : "blue.60"}
            >
              {label}
            </Button>
          </StyledTabItem>
        );
      })}
    </Flex>
  );
};
