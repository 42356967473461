import React, { useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, TableRow, Stack, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Edit2, Trash2, Eye, Link as LinkIcon } from "react-feather";
import {
  replaceSelectedViewingRoom,
  openModal,
  addSelectedViewingRoom,
  removeSelectedViewingRoom,
  addSelectedArtwork,
  clearAllSelectedArtworks,
} from "../../../store/actions";
import { selectSelectedViewingRoomIds } from "../../../store/selectors";
import DoneIcon from "@mui/icons-material/Done";
import { DateTime } from "luxon";
import { Tooltip } from "../Tooltip";
import { ButtonLabels, Notifications } from "../../../localisation";
import { createToast } from "../../../utils";
import { Link, Text, Box as CustomBox, Flex } from "../../fundamentals";

const DraftLabel = ({ title }) => {
  return (
    <Flex fontSize="small" alignItems="center">
      <CustomBox padding="4px 12px" backgroundColor="amber.30" mr="xxxs" borderRadius="4px">
        <Text>Draft</Text>
      </CustomBox>
      <Text>{title}</Text>
    </Flex>
  );
};

const ViewingRoomItem = ({ viewingRoom, index, deleteViewingRoomRow }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [hoverViewingRoom, handleOnHover] = useState({});
  const [hoverPreviewIcon, handleOnHoverPreviewIcon] = useState({});
  const [hoverEditIcon, handleOnHoverEditIcon] = useState({});
  const [hoverLinkIcon, handleOnHoverLinkIcon] = useState({});
  const [hoverDeleteIcon, handleOnHoverDeleteIcon] = useState({});
  const selectedViewingRoomIds = useSelector(selectSelectedViewingRoomIds);
  const isViewingRoomSelected = _.includes(selectedViewingRoomIds, viewingRoom._id);
  const labelId = `enhanced-table-checkbox-${index}`;
  const viewingRoomUrl = `${process.env.REACT_APP_CLIENT_URL}/viewingroom?viewingRoomId=${viewingRoom._id}`;
  const uniqueViewsArray = viewingRoom?.viewsRecord?.reduce((acc, view) => {
    const existingIp = acc.find((item) => item.ipAddress === view.ipAddress);
    if (existingIp) {
      return acc;
    }
    acc.push(view);
    return acc;
  }, []);

  const handleOnViewingRoomClick = () => {
    dispatch(replaceSelectedViewingRoom(viewingRoom._id));
  };

  const handleOnEditClick = () => {
    dispatch(replaceSelectedViewingRoom(viewingRoom._id));
    dispatch(clearAllSelectedArtworks());
    viewingRoom.artworks.map((artwork) => {
      dispatch(addSelectedArtwork(artwork._id));
    });
    history.push("/viewingrooms/edit");
  };

  const handleOnCopyLinkClick = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_CLIENT_URL}/viewingroom?viewingRoomId=${viewingRoom._id}`);
    createToast({ type: "success", ...Notifications.linkCopied });
  };

  const handleOnDeleteClick = () => {
    deleteViewingRoomRow([viewingRoom._id]);
    dispatch(openModal("delete_viewing_room_row"));
  };

  const handleOnViewingRoomSelect = (event) => {
    event.stopPropagation();

    if (isViewingRoomSelected) {
      dispatch(removeSelectedViewingRoom(viewingRoom._id));
    } else {
      dispatch(addSelectedViewingRoom(viewingRoom._id));
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isViewingRoomSelected}
      tabIndex={-1}
      key={viewingRoom._id}
      selected={isViewingRoomSelected}
      sx={{
        cursor: "pointer",
        "&.MuiTableRow-root": {
          backgroundColor: isViewingRoomSelected ? "#EFEFF0" : "#F4F4F5",
          "&:hover": { backgroundColor: "#EFEFF0" },
        },
      }}
      onMouseEnter={() => handleOnHover(viewingRoom)}
      onMouseLeave={() => handleOnHover({})}
    >
      <TableCell padding="checkbox">
        {(hoverViewingRoom._id === viewingRoom._id || isViewingRoomSelected) && (
          <Box
            sx={{
              borderRadius: "4px",
              width: "16px",
              height: "16px",
              backgroundColor: isViewingRoomSelected && "#5E54FF",
              border: !isViewingRoomSelected && "2px solid #95939C",
              p: isViewingRoomSelected && ".1rem",
              ml: ".7rem",
            }}
            checked={isViewingRoomSelected}
            onClick={handleOnViewingRoomSelect}
          >
            {isViewingRoomSelected && (
              <DoneIcon
                style={{
                  position: "relative",
                  top: "-1px",
                  left: "0px",
                  color: "white",
                  borderRadius: "4px",
                  width: "16px",
                  height: "16px",
                }}
              />
            )}
          </Box>
        )}
      </TableCell>
      <TableCell
        onClick={handleOnViewingRoomClick}
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        sx={{ color: isViewingRoomSelected && "#5E54FF" }}
      >
        {!viewingRoom?.isActive ? <DraftLabel title={viewingRoom?.title} /> : viewingRoom?.title}
      </TableCell>
      <TableCell onClick={handleOnViewingRoomClick} sx={{ color: isViewingRoomSelected && "#5E54FF" }}>
        {DateTime.fromISO(viewingRoom?.dateCreated).toFormat("dd MMM yyyy")}
      </TableCell>
      <TableCell onClick={handleOnViewingRoomClick} sx={{ color: isViewingRoomSelected && "#5E54FF" }}>
        {DateTime.fromISO(viewingRoom?.dateValid).toFormat("dd MMM yyyy")}
      </TableCell>
      <TableCell onClick={handleOnViewingRoomClick} sx={{ color: isViewingRoomSelected && "#5E54FF" }}>
        {uniqueViewsArray?.length}
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} justifyContent="center">
          <Link href={viewingRoomUrl} target="_blank" style={{pointerEvents: !viewingRoom?.isActive && "none"}}>
            <Tooltip title={ButtonLabels.preview}>
              <IconButton
                onMouseEnter={() => handleOnHoverPreviewIcon(viewingRoom)}
                onMouseLeave={() => handleOnHoverPreviewIcon({})}
                disabled={!viewingRoom?.isActive}
              >
                <Eye
                  style={{
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                    color: viewingRoom._id === hoverPreviewIcon._id && "#5E54FF",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Link>
          <Tooltip title={ButtonLabels.edit}>
            <IconButton
              onClick={handleOnEditClick}
              onMouseEnter={() => handleOnHoverEditIcon(viewingRoom)}
              onMouseLeave={() => handleOnHoverEditIcon({})}
            >
              <Edit2
                style={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                  color: viewingRoom._id === hoverEditIcon._id && "#5E54FF",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title={ButtonLabels.copyLink}>
            <IconButton
              onClick={handleOnCopyLinkClick}
              onMouseEnter={() => handleOnHoverLinkIcon(viewingRoom)}
              onMouseLeave={() => handleOnHoverLinkIcon({})}
              disabled={!viewingRoom?.isActive}
            >
              <LinkIcon
                style={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                  color: viewingRoom._id === hoverLinkIcon._id && "#5E54FF",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title={ButtonLabels.delete}>
            <IconButton
              onClick={handleOnDeleteClick}
              onMouseEnter={() => handleOnHoverDeleteIcon(viewingRoom)}
              onMouseLeave={() => handleOnHoverDeleteIcon({})}
            >
              <Trash2
                style={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                  color: viewingRoom._id === hoverDeleteIcon._id && "#5E54FF",
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export { ViewingRoomItem };
