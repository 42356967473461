import { Icon } from "@mui/material";

export const TikTokIcon = ({size, ...props}) => {
    return (
        <Icon  {...props}>
            <img 
                alt="tik-tok-logo" 
                src="/images/icons/other-icons/icon-tiktok.svg" 
                width={size}
            />
        </Icon>
    )
};