import React from "react";
import styled from "styled-components";
import { space, color, flexbox } from "styled-system";
import _ from "lodash";

import { Box, Text } from "../fundamentals";
import { Checkbox } from "../elements";
import { Stack } from "@mui/material";

const StyledRadioSelect = styled(Box)`
  ${space}
  ${color}
  ${flexbox}
`;

const RadioSelect = ({
  input,
  meta,
  label,
  labelSize,
  descriptionSize,
  direction,
  required,
  disabled,
  options,
  customItemComponent,
  disabledOption,
  ...props
}) => {
  const Item = customItemComponent || Checkbox;
  return (
    <StyledRadioSelect {...props}>
      <Text fontSize="small" lineHeight="button" color={disabled ? "grey.40" : "grey.90"}>
        {label} {required ? "*" : ""}
      </Text>

      <Stack direction={direction === "horizontal" ? "row" : "column"} spacing={props.spacing ? props.spacing : "28px"}>
        {_.map(options, (innerProps, value) => (
          <Item
            key={value}
            labelSize={labelSize || "body"}
            disabled={disabledOption === value || disabled}
            itemValue={value}
            input={input}
            descriptionSize={descriptionSize}
            {...innerProps}
            grouped
          />
        ))}
      </Stack>

      {meta.error && meta.touched && (
        <Text fontSize="smaller" lineHeight="button" color="red" mt="xxxs">
          {meta.error}
        </Text>
      )}
    </StyledRadioSelect>
  );
};

export { RadioSelect };
