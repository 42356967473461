import React from "react";
import styled from "styled-components";

import { Box } from ".";

const StyledText = styled(Box)`
  text-overflow: ellipsis;
  -webkit-font-smoothing: never;
  user-select: inherit;
  * {
    display: inline;
  }
  ${props =>
    props.textDecoration &&
    `
    text-decoration: ${props.textDecoration};
  `}
  ${props => props.whiteSpace && `white-space: ${props.whiteSpace};`}
`;

const Text = ({ children, size, lineHeight, label, maxWidth, whiteSpace, overflow, textDecoration, listItem, ...props }) => {
  const Text = (
    <Box {...props}>
      {label && (
        <Box color="grey.60" fontSize="12px" lineHeight="12px" mb="xxs">
          {label}
        </Box>
      )}
      <StyledText
        fontSize={size}
        lineHeight={lineHeight}
        textDecoration={textDecoration}
        maxWidth={maxWidth}
        whiteSpace={whiteSpace}
        overflow={overflow}
      >
        {children}
      </StyledText>
    </Box>
  );
  return listItem ? <li>{Text}</li> : Text;
};

export { Text };
