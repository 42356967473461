import React, { useState } from "react";
import _ from "lodash";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import CheckIcon from "@mui/icons-material/Check";
import { Controller } from "react-hook-form";
import { Icon, Flex, Text } from "../../fundamentals";
import { Button } from "../../elements";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

const selectedLabelStyle = {
  fontFamily: "Inter",
  color: "#2B2A2E",
  fontSize: "16px",
};

const placeholderStyle = {
  color: "#C3C2C9",
  paddingLeft: "4px",
};

const InputSelect = ({
  name,
  errors,
  label,
  options,
  placeholder,
  required,
  control,
  textIcon,
  disabled,
  customInputSelectStyle,
  customPlaceholderStyle,
  multiple,
  buttonPlaceholder,
  customMenuStyle,
  suffix,
  isRequired,
  errorMessage,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const inputSelectStyle = {
    disableUnderline: "true",
    paddingLeft: "4px",
    backgroundColor: "transparent",
    "&:hover": {
      background: buttonPlaceholder ? "transparent" : "#F3F4FF",
      borderBottom: buttonPlaceholder ? "none" : "2px solid #C8CEFF",
      marginBottom: buttonPlaceholder ? "0px" : "-2px",
    },
    "&:focus": {
      background: buttonPlaceholder ? "transparent" : "#F3F4FF",
      color: buttonPlaceholder ? "white" : "#2B2A2E",
    },
    "&:before": {
      content: "none",
    },
    "&:after": {
      borderBottom: buttonPlaceholder ? "none" : "2px solid #5E54FF",
    },
    "&.Mui-focused": {
      borderBottom: "none",
      backgroundColor: buttonPlaceholder ? "transparent" : "#F3F4FF",
    },
    "&.Mui-error": {
      backgroundColor: "#FFDFDA",
      borderBottom: "1px solid red",
    },
  };

  const optionContainerStyle = {
    display: "flex",
    padding: "16px 14px",
    justifyContent: "space-between",
    background: "#FFFFFF",
    "&:hover": {
      background: "#F3F4FF",
      color: "#5E54FF",
      "&.MuiCheckbox-root": {
        color: "#5E54FF",
      },
    },
    "&.Mui-selected": {
      backgroundColor: multiple ? "#5E54FF" : "#F3F4FF",
      color: multiple ? "white" : "#5E54FF",
      "&:hover": {
        backgroundColor: multiple ? "#5E54FF" : "#F3F4FF",
        color: multiple ? "white" : "#5E54FF",
      },
    },
  };

  const optionTextStyle = {
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "24px",
  };

  const checkboxStyle = {
    padding: 0,
    paddingRight: "8px",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-checked": {
      color: "white",
    },
  };

  const getOptionLabel = (selectedValue) => {
    const selectedOption = _.find(options, (option) => option.value === selectedValue);
    if (selectedOption?.hasOwnProperty("displayedLabel")) {
      return selectedOption?.displayedLabel;
    } else return selectedOption?.label;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const helperTextStyle = {
    color: "red",
    fontSize: "12px",
    margin: "0",
    top: "14px",
    position: "relative",
  };

  return (
    <FormControl required={required}>
      <Controller
        name={name}
        control={control}
        rules={{
          required: { value: isRequired, message: errorMessage },
        }}
        render={({ fieldState: { invalid, isTouched, isDirty, error }, field: { onChange, value } }) => (
          <Select
            onChange={onChange}
            value={value ? value : multiple ? [] : ""}
            variant="standard"
            displayEmpty
            multiple={multiple}
            disabled={disabled}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            error={!!error}
            inputProps={{
              sx: {
                backgroundColor: buttonPlaceholder && "transparent",
                padding: buttonPlaceholder && "0px",
                paddingRight: "0px !important",
                "&:focus": {
                  background: buttonPlaceholder ? "transparent" : "#F3F4FF",
                },
                "&.Mui-disabled": {
                  cursor: "not-allowed",
                  "-webkit-text-fill-color": "#D6D6D6",
                },
              },
            }}
            MenuProps={{
              sx: {
                "& .MuiMenu-paper": {
                  marginTop: "8px",
                  ...customMenuStyle,
                },
                "& .MuiList-root": {
                  padding: 0,
                },
              },
            }}
            renderValue={(selected) => {
              if (buttonPlaceholder) {
                return (
                  <Button
                    buttonStyle={open ? "primary" : "neutral"}
                    fontWeight="500"
                    size="small"
                    padding="6px 16px"
                    fontFamily="body"
                    flip={true}
                    icon={open ? "ChevronUp" : "ChevronDown"}
                    iconOffset="xxxs"
                    iconCursor="pointer"
                  >
                    {placeholder}
                  </Button>
                );
              }
              if (placeholder && !selected) {
                return <Typography sx={{ ...placeholderStyle, ...customPlaceholderStyle }}>{placeholder}</Typography>;
              } else if (!placeholder && !selected) {
                return <Typography>{""}</Typography>;
              }
              return (
                <MenuItem sx={{ paddingLeft: "4px" }}>
                  <Typography sx={selectedLabelStyle}>{getOptionLabel(selected)}</Typography>
                </MenuItem>
              );
            }}
            IconComponent={(props) => {
              if (!buttonPlaceholder) {
                return <Icon icon="ChevronDown" size="18" color={disabled ? "grey.40" : "grey.80"} {...props} />;
              } else {
                return null;
              }
            }}
            sx={{
              ...inputSelectStyle,
              ...customInputSelectStyle,
            }}
            {...props}
          >
            {placeholder && !buttonPlaceholder && (
              <MenuItem disabled value="">
                {placeholder}
              </MenuItem>
            )}
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value} disableGutters sx={optionContainerStyle}>
                {multiple ? (
                  <Flex alignItems="center">
                    <Checkbox checked={value.indexOf(option.value) > -1} sx={checkboxStyle} />
                    <Typography sx={optionTextStyle}>{option?.label}</Typography>
                  </Flex>
                ) : (
                  <Typography sx={optionTextStyle}>{option?.label}</Typography>
                )}
                {!multiple && option.value === value && <CheckIcon sx={{ color: "#5E54FF" }} />}
                {suffix && <Typography paddingLeft="30px">{option.suffixLabel}</Typography>}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {_.get(errors, name) && <FormHelperText sx={helperTextStyle}>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export { InputSelect };
