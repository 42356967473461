import React, { useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, TableRow, Typography, Stack, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Edit2, Trash2 } from "react-feather";
import { replaceSelectedArtist, openModal, addSelectedArtist, removeSelectedArtist } from "../../../store/actions";
import { selectSelectedArtistIds } from "../../../store/selectors";
import DoneIcon from "@mui/icons-material/Done";

const ArtistItem = ({ artist, index, deleteArtistRow }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [hoverArtist, handleOnHover] = useState({});
  const selectedArtistIds = useSelector(selectSelectedArtistIds);
  const isArtistSelected = _.includes(selectedArtistIds, artist.id);
  const labelId = `enhanced-table-checkbox-${index}`;

  const handleOnArtistClick = () => {
    dispatch(replaceSelectedArtist(artist.id));
    dispatch(openModal("preview_artist"));
  };

  const handleOnEditArtistClick = () => {
    dispatch(replaceSelectedArtist(artist.id));
    history.push("/artists/edit");
  };

  const handleOnDeleteArtistClick = () => {
    deleteArtistRow([artist.id]);
    dispatch(openModal("delete_artist_row"));
  };

  const handleOnArtistSelect = (event) => {
    event.stopPropagation();

    if (isArtistSelected) {
      dispatch(removeSelectedArtist(artist.id));
    } else {
      dispatch(addSelectedArtist(artist.id));
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isArtistSelected}
      tabIndex={-1}
      key={artist.id}
      selected={isArtistSelected}
      sx={{
        cursor: "pointer",
        "&.MuiTableRow-root": {
          backgroundColor: isArtistSelected ? "#EFEFF0" : "#F4F4F5",
          "&:hover": { backgroundColor: "#EFEFF0" },
        },
      }}
      onMouseEnter={() => handleOnHover(artist)}
      onMouseLeave={() => handleOnHover({})}
    >
      <TableCell padding="checkbox">
        {(hoverArtist.id === artist.id || isArtistSelected) && (
          <Box
            sx={{
              borderRadius: "4px",
              width: "16px",
              height: "16px",
              backgroundColor: isArtistSelected && "#5E54FF",
              border: !isArtistSelected && "2px solid #95939C",
              p: isArtistSelected && ".1rem",
              ml: ".7rem",
            }}
            checked={isArtistSelected}
            onClick={handleOnArtistSelect}
          >
            {isArtistSelected && (
              <DoneIcon
                style={{
                  position: "relative",
                  top: "-1px",
                  left: "0px",
                  color: "white",
                  borderRadius: "4px",
                  width: "16px",
                  height: "16px",
                }}
              />
            )}
          </Box>
        )}
      </TableCell>
      <TableCell
        onClick={handleOnArtistClick}
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        sx={{ color: isArtistSelected && "#5E54FF" }}
      >
        {artist?.name}
      </TableCell>
      <TableCell onClick={handleOnArtistClick} sx={{ color: isArtistSelected && "#5E54FF" }}>
        {artist?.yearBirth}
      </TableCell>
      <TableCell onClick={handleOnArtistClick} sx={{ color: isArtistSelected && "#5E54FF" }}>
        {artist?.cityBirth}
      </TableCell>
      <TableCell onClick={handleOnArtistClick} sx={{ color: isArtistSelected && "#5E54FF" }}>
        {artist?.countryBirth}
      </TableCell>
      <TableCell onClick={handleOnArtistClick} sx={{ color: isArtistSelected && "#5E54FF" }}>
        {artist?.currentCity}
      </TableCell>
      <TableCell onClick={handleOnArtistClick} sx={{ color: isArtistSelected && "#5E54FF" }}>
        {artist?.currentCountry}
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} justifyContent="center">
          <IconButton onClick={handleOnEditArtistClick}>
            <Edit2 style={{ width: "20px", height: "20px", color: artist.id === hoverArtist.id && "#5E54FF" }} />
          </IconButton>
          <IconButton onClick={handleOnDeleteArtistClick}>
            <Trash2 style={{ width: "20px", height: "20px", color: artist.id === hoverArtist.id && "#5E54FF" }} />
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export { ArtistItem };
