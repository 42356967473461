import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Input, RenderError } from "../../react-material/Input";
import { downloadContacts, createPaymentInviteRequest } from "../../../store/actions";
import { selectAllContactsList, selectSelectedArtworkIds } from "../../../store/selectors";
import { Box as StyledBox } from "../../fundamentals";
import { Stack, Typography, Box } from "@mui/material";
import { InputLabels, InputErrors } from "../../../localisation";
import { MultiInputCreatableSelect } from "../../elements";
import { useLocation } from "react-router-dom";

const fieldStyle = {
  flexGrow: 1,
  ".MuiFilledInput-root": {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F3F4FF",
      borderBottom: "2px solid #C8CEFF",
      marginBottom: "-3px",
    },
    "&:before": {
      content: "none",
    },
    "&.Mui-focused": {
      borderBottom: "none",
      backgroundColor: "#F3F4FF",
      marginBottom: "-3px",
    },
  },

  "& .Mui-error": {
    ".MuiFilledInput-input": {
      marginBottom: "3px",
    },
  },
};

const stackStyle = {
  borderBottom: "1px solid #C3C2C9",
  alignItems: "center",
  justifyContent: "space-between",
  height: "58px",
};

const inputLabelStyle = {
  width: "151px",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#6A6870",
  fontFamily: "Inter",
};

const Form = ({ handleSubmit, submitRef, ...props }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedArtworkIds = useSelector(selectSelectedArtworkIds);
  const allContacts = useSelector(selectAllContactsList);
  const contactsWithEmail = [];
  const checkIsPresent = [];
  for (const id in allContacts) {
    const contact = allContacts[id];
    // Check if the current contact object has an email
    if (contact.hasOwnProperty("email") && !checkIsPresent.includes(contact.email)) {
      let label = "";
      if (contact.organisation && !contact.firstName && !contact.lastName) {
        label = contact.organisation;
      } else if (contact.firstName && !contact.lastName) {
        label = contact.firstName;
      } else if (contact.firstName && contact.lastName) {
        label = `${contact.firstName} ${contact.lastName}`;
      } else if (!contact.firstName && contact.lastName) {
        label = contact.lastName;
      } else {
        label = contact.email;
      }
      // Add the current contact object to the contactsWithEmail array with the appropriate label and value properties
      contactsWithEmail.push({ id, label, value: contact.email });
      checkIsPresent.push(contact.email);
    }
  }

  const submit = (values, dispatch) => {
    return dispatch(
      createPaymentInviteRequest({
        artworkIds: selectedArtworkIds,
        isBuyerGenerated: false,
        isMarketPlace: false,
        isOffer: false,
        isEnquiry: false,
        ...values,
      })
    );
  };

  useEffect(() => {
    dispatch(downloadContacts());
  }, []);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <StyledBox border="1px solid #C3C2C9" borderRadius="7px" padding="l">
        <Stack direction="column">
          <StyledBox>
            <Stack sx={stackStyle} direction="row">
              <Typography sx={inputLabelStyle}>{InputLabels.emailOrContact}</Typography>
              <Field
                name="contacts"
                sx={fieldStyle}
                component={MultiInputCreatableSelect}
                options={contactsWithEmail}
                hideError={true}
              />
            </Stack>
            <Field name="contacts" component={RenderError} />
          </StyledBox>
          <Box sx={{ borderBottom: "1px solid #C3C2C9" }}>
            <Stack direction="column">
              <Typography sx={inputLabelStyle} marginTop="12px">
                {InputLabels.message}
              </Typography>

              <Field
                name="message"
                component={Input}
                formName="payment_invite.create"
                sx={{
                  flexGrow: 1,
                  ".MuiFilledInput-root": {
                    paddingTop: "0px",
                    "&:hover": {
                      backgroundColor: "#F3F4FF",
                      borderBottom: "2px solid #C8CEFF",
                      marginBottom: "-1px",
                    },
                    "&:before": {
                      content: "none",
                    },
                    "&.Mui-focused": {
                      borderBottom: "none",
                      backgroundColor: "#F3F4FF",
                      marginBottom: "-1px",
                    },
                  },
                }}
                multiline={true}
              />
            </Stack>
          </Box>
        </Stack>
      </StyledBox>
      {/* Invisible button to trigger submission from parent component using ref https://stackoverflow.com/a/72165641/18711161*/}
      <button type="submit" ref={submitRef} style={{ display: "none" }} />
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const { contacts } = values;

  if (!contacts) {
    errors.contacts = InputErrors.noContact;
  }
  if (contacts) {
    contacts.forEach((contact) => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(contact.value)) {
        errors.contacts = InputErrors.invalidEmail;
      }
    });
  }
  return errors;
};

let ContactDetailsForm = reduxForm({
  form: "payment_invite.create",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

export { ContactDetailsForm };
