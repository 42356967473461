import LocalizedStrings from "react-localization";

export const Instructions = new LocalizedStrings({
  en: {
    addImages: "Drag images to reorder them. The top image is used as the artwork’s cover.",
    addFirstImage: "Add your first image",
    forgotPassword:
      "Enter the email address you used to signup with and we will send you a link to reset your password.",
    forgotPasswordText: "Enter your email address and we will send you a reset code",
    changePassword: "Enter the code from the email we just sent and create your new password",
    listType: "Select type of document you want to create",
    viewingRoomPassword: "Enter viewing room password",
    noAccount: "Don't have an account?",
    accountExists: "Already simplifying things?",
    needHelp: "Need help?",
    noCode: "Didn’t receive a code?",
    acceptTerms: "By creating an account you agree to",
    acceptTermsText: "By clicking the button above, I agree to simplify.art's ",
    unsubscribeReason: "We are sad to see you go! What is your reason for leaving simplify.art?",
    downloadMobileApps: "For the best possible experience, download our companion mobile app from your app store",
    exportsTypeSelect: "Select what type of document you want to create",
    installationManual: "Create a PDF document with instructions and packaging information",
    upgrade: "Upgrade to use this feature",
    upgradeToAllAccess: "Upgrade to simplify.art All-Access",
    upgradePrompt: "Improve your workflow and simplify your art management with these additional features:",
    setExpiryDate: "Set the expiry date of the Invite to continue",
  },
});

export const Prompts = new LocalizedStrings({
  en: {
    unsyncedLogout: (numUnsyncedActions) =>
      `You have ${numUnsyncedActions} unsynced items in your archive. All unsynced data will be lost`,
  },
});

export const Messages = new LocalizedStrings({
  en: {
    welcomeRedirectText: "You are now being redirected to the simplify.art web app.",
    welcomeNotRedirectedText: "If you are not redirected automaticaly, please click ",
    welcome: "Welcome to simplify.art. Your new account is all set up and ready.",
    trialMessage: (trialLength) =>
      `You will not be charged for the first ${trialLength} day${trialLength > 1 ? "s" : ""} of your subscription`,
    paymentMessage: "You can cancel anytime",
    automaticRenewMessage: (date) => `Your plan wil automatically renew on ${date}.`,
    certificateText: "The undersigned ensures herewith the authenticity of the artwork shown below.",
    certificateTitle: "Certificate of Authenticity",
    noArtWorks: "You have no artworks",
    noContacts: "You have no contacts",
    noArtists: "You have no artists",
    noLists: "You have no lists",
    noViewingRooms: "You have no Viewing Rooms",
    addArtWorks: "No need to worry! Simply click the add artwork button below to get started.",
    addContacts: "No need to worry! Simply click the add contact button below to get started.",
    addArtists: "No need to worry! Simply click the add artist button below to get started.",
    createLists:
      "No need to worry! Once you create a PDF export, the exported list will be saved here for future editing. Simply go to artworks and select the ones you want to create an export for.",
    addViewingRooms: "No need to worry! Simply click the new Viewing Room button below to get started.",
    noArtWorksFound: "No artworks found",
    noContactFound: "No Contacts found",
    noArtistFound: "No Artists found",
    noListsFound: "No Lists found",
    noViewingRoomsFound: "No Viewing Rooms found",
    upComingListFeature:
      "With Lists you can save a group of artworks to edit and export later. If you have any input, suggestions or requests, feel free to get in touch!",
    comingSoon: "Coming soon!",
    upComingExhibitionFeature:
      "With Exhibitions, you can archive images and information about exhibitions and link them to artworks and artists. If you have any input, suggestions or requests, feel free to get in touch!",
    upComingEventFeature:
      "With Events, you can keep track of invites and RSVPs and add them to your calendar. If you have any input, suggestions or requests, feel free to get in touch!",
    upComingArtistFeature:
      "With Artists, you can record their CVs and publications. If you have any input, suggestions or requests, feel free to get in touch!",
    emptySearch: (query) => `Your search “${query}” did not match any artworks. Please try again.`,
    emptySearchContact: (query) => `Your search “${query}” did not match any contacts. Please try again.`,
    emptySearchArtist: (query) => `Your search “${query}” did not match any artists. Please try again.`,
    emptySearchList: (query) => `Your search “${query}” did not match any lists. Please try again.`,
    emptySearchViewingRoom: (query) => `Your search “${query}” did not match any Viewing Rooms. Please try again.`,
    failedPayment: "Unfortunately, the payment wasn’t successful and the sign-up process hasn’t been completed.",
    retrySignup: "Please use the button below to re-try the sign-up process.",
    upgradeMessage: (feature, description) => `${feature} is a paid feature ${description}.`,
    retryUpgrade: "Please use the button below to re-try the upgrade process.",
    paymentInviteMessage: (percentage) =>
      `We charge a ${percentage}% processing fee to cover the cost of keeping your money and information safe.`,
    betaFeature: "This feature is currently in Beta. If you would like access,",
    customerSupport: "Our customer support team will be in touch within 1-2 business days.",
    viewingRoomPreviewAndShare: "Preview and share your Viewing Room",
  },
});

export const FeatureDescriptions = new LocalizedStrings({
  en: {
    waltersCubeExhibitions:
      "Creating beautiful 3D online exhibitions in Walters Cube is in BETA!\n\nIt will be free to use until the summer. We encourage you to try it out and let us know what you think.",
    whiteLabeledExports:
      "You can upload your custom logo in Organisation Settings and it will appear in all your exports!",
  },
});

export const FeaturesInfo = new LocalizedStrings({
  en: {
    allAccessPlanDesc: "Be even more efficient with tools for optimised workflows.",
    exporting: "Exporting",
    exportingFeatDesc: "that gives you professional reports and lists in a few clicks",
    dropboxImages: "Adding images from dropbox",
    dropboxIntegration: "Dropbox integration",
    dropboxIntegrationDescLong: "Add images of artworks directly from your Dropbox account.",
    dropboxIntegrationDescShort: "Add images from Dropbox",
    addingArtists: "Adding artists",
    artistsFeatDesc: "that allows you to record important personal and career details of the artists in your archive",
    addingContacts: "Adding contacts",
    contacts: " Contacts",
    addContacts: "Add contacts",
    contactsDescLong: "Build and manage your database of contacts in the same place as where you manage your art.",
    contactsDescShort: "Build and manage your contacts. They are key for your success.",
    contactsFeatDesc: "that makes managing your contacts easier",
    multiEdit: "Multi-edit",
    multiEditDescShort: "Edit multiple artworks at once",
    multiEditFeatDesc: "that improves your workflow and saves you time",
    addingMultiImages: "Adding multiple images to an artwork",
    multiImages: "Add multiple images to each artwork",
    multiImagesFeatDesc: "that improves your documentation",
    freePlanDesc: "Get the basics to manage your art effortlessly.",
    freeBuildDatabase: "Build and manage your database of artworks",
    freeShareArtworks: "Share artworks with contacts",
    freeSaveArtworks: "Save and manage shared artworks",
    freeUseApp: "Use app on phone, tablet or desktop",
    lists: "Lists",
    listsFeatDesc: "that allows you to save and edit selections for pdf exports",
    reducedFees: "Reduced processing fees",
    reducedFeesTo7: "Processing fees reduced to 7%",
    reducedFeesDesc:
      "Save money on every transaction made through simplify.art Discovery, Viewing Rooms or Payment Invites. By upgrading the fee drops to 7%.",
    viewingRooms: "Viewing rooms",
    viewingRoomsFeatDesc: "that allows you to share a selection of works nicely presented and ready to be purchased",
    viewingRoomsDesc:
      "Share beautiful custom websites with a selection of works with interested curators or buyers with optional purchase possibility.",
    unlimitedViewingRooms: "Generate unlimited custom Viewing Rooms",
    exportDocuments: "Export documents",
    exportDocumentsDesc:
      "Use our flexible templates to generate documents for any occasion: transport, loan, insurance...",
    biographicalInfo: "Add biographical info",
    artistBiographicalInfo: "Add biographical info to artists",
    biographicalInfoDesc:
      "Add biographical information for artists including their exhibition history, publications or awards",
    flexibleDocumentTemplates: "Flexible templates for documents",
    editWorklists: "Edit selected worklists",
    archiveImport: "Importing an Archive",
    archiveImportFeatDesc: "where you can upload a .csv file to automatically add your existing data to simplify.art"
  },
});

export const PaymentInviteSellerNextSteps = new LocalizedStrings({
  en: {
    stepOne: "Buyer makes the payment",
    stepOneDesc: "You will receive confirmation once the payment has been made.",
    stepTwo: "Organise delivery",
    stepTwoDesc: "Confirm delivery details with the buyer and inform your designated transport company.",
    stepThree: "Artwork is delivered",
    stepThreeDesc: "The buyer confirms the artwork has arrived safely.",
    stepFour: "Get paid",
    stepFourDesc: "Request your payout from the Artwork Sales area (located in Activity).",
  },
});

export const PaymentInviteBuyerNextSteps = new LocalizedStrings({
  en: {
    stepOneBeforePayment: "Make the payment",
    stepOneDescBeforePayment:
      "Enter your credit card details and pay for your artwork securely with our in-platform payment system powered by Stripe.",
    stepOneAfterPayment: "Payment has been made",
    stepOneDescAfterPayment:
    "You’ve paid for your artwork securely and confirmed where you would like the artwork delivered.",
    stepOneDescBuyerGenerated: "Pay for your artwork securely by credit card and confirm where you would like the artwork delivered.",
    stepTwo: "Get payment confirmation",
    stepTwoOwnShipping: "Get payment confirmation and seller's details",
    stepTwoDescBeforePayment: "An email will be sent after payment is made with your invoice.",
    stepTwoDescAfterPayment: "An email will be sent shortly with your invoice.",
    stepTwoDescOwnShipping: "An email will be sent after payment is made with your invoice and the seller's contact details and artwork location.",
    stepThree: "Artwork is on its way",
    stepThreeOwnShipping: "Organise your own shipping",
    stepThreePersonalHandover: "Organise your personal handover",
    stepThreeDesc: "We will organise delivery to the address you provide now when paying.",
    stepThreeDescBeforePayment: "The seller will organise delivery to the address you provide now when paying.",
    stepThreeDescAfterPayment: "The seller will organise delivery to the address you provided when paying.",
    stepThreeDescOwnShipping: "With the information from the seller, organise transport of the artwork from its location.",
    stepThreeDescPersonalHandover: "Organise with the seller when you will collect the artwork.",
    stepFour: "Artwork is delivered",
    stepFourPersonalHandover: "Collect your artwork",
    stepFourDesc: "Confirm the artwork has been safely delivered and enjoy!",
    stepFourDescPersonalHandover: "Confirm the artwork has been safely collected and enjoy!",
  },
});

export const PaymentInviteSecurityMeasures = new LocalizedStrings({
  en: {
    protectedDetails:
      "By using our in-platform payment system powered by Stripe, your details are protected by stringent security measures.",
    phishingScams: "To avoid phishing scams, check that this URL starts with https://app.simplify.art",
    sensitiveInfo: "simplify.art will never ask you to send sensitive information or personal details via email.",
  },
});

export const PaymentInviteFeatures = new LocalizedStrings({
  en: {
    poweredByStripe: "Powered by Stripe",
    poweredByStripeDesc:
      "Our in-platform payment system is powered by Stripe meaning your details are protected by stringent security measures.",
    bankTransfer: "Bank transfers for larger sums",
    bankTransferDesc: "Your buyer can choose to pay securely via bank transfer.",
    statusTracking: "Track the status of your sales",
    statusTrackingDesc: "Get an overview of your sales (and acquisitions) in the Activity area.",
    idVerification: "ID verification",
    idVerificationDesc: "By confirming the identity of both buyer and seller, all parties are protected against fraud.",
  },
});

export const PaymentInviteOfferNextSteps = new LocalizedStrings({
  en: {
    stepOne: "Seller receives offer",
    stepOneDesc: "Enter the amount you'd like to pay for the artwork.",
    stepTwo: "Seller responds to offer",
    stepTwoDesc: "Seller will either accept or reject your offer.",
    stepThree: "Purchase artwork",
    stepThreeDesc: "Once the offer has been accepted by both parties, you can purchase the artwork securely with our in-platform payment system.",
  },
});

export const PaymentInviteEnquiryNextSteps = new LocalizedStrings({
  en: {
    stepOne: "Seller receives enquiry",
    stepOneDesc: "Enter your details and your message for the seller.",
    stepTwo: "Seller responds to enquiry",
    stepTwoDesc: "You'll receive an email with the seller's response once they reply.",
    stepThree: "Make an offer or purchase artwork",
    stepThreeDesc: "You can then choose to make an offer or purchase the artwork.",
  },
});

export const MarketplaceOnboardingSteps = new LocalizedStrings({
  en: {
    stepOne: "Accept Terms",
    stepOneDesc: "Read the fine print and agree to our terms.",
    stepTwo: "Basic Details",
    stepTwoDesc: "Enter some basic details to get started.",
    stepThree: "ID Verification with Stripe",
    stepThreeDesc: "Get your phone and passport ready.",
    stepFour: "Payout Details with Stripe",
    stepFourDesc: "Provide the card or account details to receive payments.",
    stepFive: "Your sales account is activated!",
    stepFiveDesc: "In most cases, you’ll be approved instantly and can start selling right away!",
  },
});

export const Testimonials = new LocalizedStrings({
  en: {
    collector: {
      quote:
        "Using the simplify.art app is so easy. It completely erases the effort that building an archive used to be.",
      name: "Mathias Lehner",
      role: "Collector",
      city: "Vienna",
    },
    gallerist: {
      quote:
        "Multiple staff members work collaboratively with simplify.art to stay on top of the weekly flow of artworks coming and going. We all love it.",
      name: "Miryam Charim",
      role: "Gallerist",
      city: "Vienna",
    },
    artist: {
      quote: "A great working tool for collaborating with collectors and galleries and preparing for exhibitions.",
      name: "Eva Beresin",
      role: "Artist",
      city: "Vienna",
    },
    simplify_ceo: {
      quote_one:
        "I used to waste so much time handling sales via emails. simplify.art Payment Invites make the whole process smooth and easy for both sides. And we’ve eliminated the fraud risks.",
      quote_two:
        "I have witnessed so much trouble when it comes to handing over artworks to buyers. simplify.art Payment Invites make the whole process smooth and easy for both sides.",
      name: "Victoria Dejaco",
      role: "CEO of simplify.art",
      city: "Vienna",
    },
  },
});
