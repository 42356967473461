import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { WithRequireAccess } from "../hocs";
import AccountSettingsScreen from "../screens/AccountSettingsScreen";
import OrganisationSettingsScreen from "../screens/OrganisationSettingsScreen";
import BillingSettingsScreen from "../screens/BillingSettingsScreen";
import { Flex, Text } from "../fundamentals";
import { Button, Modal } from "../elements";
import {
  ChangePasswordModal,
  UpgradeModal,
  SuccessfulUpgradeModal,
  UnsuccessfulUpgradeModal,
  ContactUsFormModal,
} from "../modals";
import { ButtonLabels, UiLabels } from "../../localisation";
import { NavigationBar } from "../react-material/NavigationBar";
import ArtistProfileScreen from "./ArtistProfileScreen";
import { selectUser } from "../../store/selectors";

const SettingsScreen = () => {
  const history = useHistory();
  const [page, setPage] = useState("account");
  const appVersion = useSelector((state) => state.app.version);
  const user = useSelector(selectUser);

  const renderSettingsPage = () => {
    if (page === "account") return <AccountSettingsScreen />;
    if (page === "organisation") return <OrganisationSettingsScreen />;
    if (page === "billing") return <BillingSettingsScreen />;
    if (page === "profile") return <ArtistProfileScreen />;
  };

  const handleOnPageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <Flex flex="1" flexDirection="column" maxWidth="1680px">
      <NavigationBar marginBottom="80px" />

      <Flex flex="1" ml="xxl" mr="xxl" mb="mega">
        <Flex flexDirection="column" mr="xl">
          <Button
            buttonStyle="text"
            size="medium"
            color={page !== "account" ? "grey.80" : null}
            onClick={() => handleOnPageChange("account")}
          >
            {ButtonLabels.accountAndSettings}
          </Button>
          <Button
            buttonStyle="text"
            size="medium"
            color={page !== "organisation" ? "grey.80" : null}
            mt="s"
            onClick={() => handleOnPageChange("organisation")}
          >
            {ButtonLabels.organisationSettings}
          </Button>
          {user.role === "artist" && (
            <Button
              buttonStyle="text"
              size="medium"
              color={page !== "profile" ? "grey.80" : null}
              mt="s"
              onClick={() => handleOnPageChange("profile")}
            >
              {ButtonLabels.artistProfile}
            </Button>
          )}
          <Button
            buttonStyle="text"
            size="medium"
            color={page !== "billing" ? "grey.80" : null}
            mt="s"
            onClick={() => handleOnPageChange("billing")}
          >
            {ButtonLabels.subscriptionAndBilling}
          </Button>
        </Flex>
        <Flex flexDirection="column" flex="1" bg="white" p="xxl" position="relative">
          <Flex
            position="absolute"
            left="0"
            right="xxxs"
            top="-32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button buttonStyle="text" size="small" icon="ArrowLeft" onClick={history.goBack} pl="0px">
              {ButtonLabels.back}
            </Button>
            <Text size="small" color="grey.80">
              {UiLabels.version} {appVersion}
            </Text>
          </Flex>
          {renderSettingsPage()}
        </Flex>
      </Flex>
      <Modal id="change_password">
        <ChangePasswordModal />
      </Modal>
      <Modal id="upgrade" p="none">
        <UpgradeModal />
      </Modal>
      <Modal id="upgradeSuccess">
        <SuccessfulUpgradeModal />
      </Modal>
      <Modal id="upgradeFailure">
        <UnsuccessfulUpgradeModal />
      </Modal>
      <Modal id="contactUs">
        <ContactUsFormModal />
      </Modal>
    </Flex>
  );
};

export default WithRequireAccess(SettingsScreen, 0);
