export const UserAttributes = {
  USER_ID: "user",
  ROLE: "role",
  SUBSCRIPTION: "subscription"
};

export const Events = {
  LOGIN: "login",
  REGISTER: "register",
  LOGOUT: "logout",
  FORCE_LOGOUT: "force_logout",

  SELECT_COLLECTION: "select_collection",
  OPEN_MODAL: "open_modal",

  NEW_ARTWORK: "artwork_added",
  EDIT_ARTWORK: "artwork_edited",
  EDIT_ARTIST: "artist_edited",
  EDIT_CONTACT: "contact_edited",
  CHANGE_ARTWORK_COLLECTIONS: "artworks_moved",
  SAVE_SHARED_ARTWORKS: "shared_artworks_saved",
  SHARE_ARTWORKS: "artworks_shared",
  DELETE_COLLECTION_ARTWORKS: "collection_artworks_deleted",
  DELETE_COLLECTION_CONTACTS: "collection_contacts_deleted",
  PRICE_ADDED: "price_added",

  ARTWORK_SEARCH: "artwork_searched",

  NEW_PDF_EXPORT: "pdf_export_generated",
  FAILED_PDF_EXPORT: "pdf_export_failed",
  NEW_CSV_EXPORT: "csv_exported",
  NEW_VIEWING_ROOM: "viewing_room_created",
  VIEWING_ROOM_ACCESSED: "viewing_room_opened",

  SYNC: "device_sync",
  SHOW_REMOTE_ALERT: "show_remote_alert",

  UPLOAD_IMAGE_FAILED: "upload_image_failed",

  PAYMENT_INCOMPLETE: "payment_incomplete",
  PAYMENT_SUCCESSFUL: "payment_successful",
  PAYMENT_FAILED: "payment_failed",
  UNSUBSCRIBE: "unsubscribe",

  LOGO_UPLOADED: "logo_uploaded",
  USER_UPDATED: "user_updated",
  ORGANISATION_UPDATED: "organisation_updated",
  PAYMENT_DETAILS_UPDATED: "payment_details_updated",
  ACCOUNT_CLOSED: "account_closed",

  ACTION_EXCEPTION: "action_exception",

  NEW_OFFLINE_ACTION: "offline_action_added",
  EXECUTE_OFFLINE_ACTION: "execute_offline_action",
  MAX_OFFLINE_RETRY_ATTEMPTS: "max_retry_attemps",
  CONTACT_US: "contact_us_message_sent",
  SIGN_UP_BETA: "sign_up_beta_message_sent"
};

export const Properties = {
  MODAL: "modal",

  ARTIST: "artist",
  COLLECTION_TYPE: "collectionType",
  NUM_ADDED_LIBRARY_IMAGES: "numLibraryImages",
  NUM_ADDED_DROPBOX_IMAGES: "numDropboxImages",
  NUM_FILLED_FIELDS: "numAddedFields",
  NUM_CHANGED_FIELDS: "numChangedFields",
  NUM_ARTWORKS: "numArtworks",
  NUM_CONTACTS: "numContacts",
  NUM_ARTISTS: "numArtists",

  TYPE: "type",
  AMOUNT: "amount",
  CURRENCY: "currency",

  PREVIEW: "preview",
  PASSWORD_PROTECTED: "passwordProtected",

  HAS_MESSAGE: "hasMessage",

  SYNCED_ITEMS: "numSyncedItems",
  EXCLUDED_ITEMS: "numExludedItems",

  ACTION: "action",
  ATTEMPT: "attempt",

  SEARCH_STRING: "searchString",
  SORT_BY: "sortBy",

  LENGTH: "length"
};

export const ActionExceptions = {
  REGISTRATION: "registration",
  CHANGE_PASSWORD: "change_password",
  EMAIL_FORGOTTEN_PASSWORD: "email_forgotten_password",
  RESET_PASSWORD_W_CODE: "reset_password_with_code",
  UPDATE_USER_DETAILS: "update_user_details",
  UPDATE_ORGANISATION_DETAILS: "update_organisation_details",
  UPLOAD_ORGANISATION_LOGO: "upload_organisation_logo",
  CLOSE_ACCOUNT: "close_account",
  CREATE_VIEWING_ROOM: "create_viewing_room",
  OPEN_VIEWING_ROOM: "open_viewing_room",
  CANCEL_SUBSCRIPTION: "cancel_subscription",
  UPDATE_CARD_DETAILS: "update_card_details"
};
