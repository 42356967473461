import _ from "lodash";
import { createSelector } from "reselect";
import { ArtistContainsSearchTags } from "../../utils";
import { selectSearch } from "./searchSelectors";

export const selectArtists = (state) => state.archive.artists.byId;
export const selectArtistId = (state, artistId) => artistId;

export const selectArtistById = createSelector(selectArtists, selectArtistId, (artists, artistId) => {
  return artists?.[artistId];
});

export const selectAllArtistsList = createSelector(selectArtists, selectSearch, (artists, search) => {
  let artistsList = _.map(artists, (artist) => {
    return artist;
  });
  return _(artists)
    .thru((artists) => {
      if (search.searchTermArtist !== "") {
        const searchTags = search.searchTermArtist.split(" ");

        const filter = artistsList.filter((artist) => ArtistContainsSearchTags(searchTags, artist));
        return filter;
      }
      return artists;
    })
    .value();
});
