import {
  SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE,
  NON_SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE,
} from "../constants";

export function calculateArtworkTotalPublicPrice(
  userAccessLevel,
  publicPrice,
  vat = 0,
  isVatIncluded,
  packagingCosts,
  framingCosts,
  packagingCostsIncluded,
  framingCostsIncluded,
  hasRoundedPublicPrice
) {
  const processingFee =
    userAccessLevel === 0
      ? NON_SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE / 100
      : SUBSCRIBED_USER_PAYMENT_INVITE_FEE_PERCENTAGE / 100;

  let totalPrice = publicPrice?.value;
  if (packagingCostsIncluded && packagingCosts) {
    totalPrice += packagingCosts?.value;
  }
  if (framingCostsIncluded && framingCosts) {
    totalPrice += framingCosts?.value;
  }
  if (isVatIncluded) {
    totalPrice = totalPrice * (1 + processingFee);
  } else {
    totalPrice = totalPrice * (1 + processingFee) * (1 + vat / 100);
  }

  const totalPriceWithTwoDecimals = (Math.round(totalPrice * 100) / 100).toFixed(2);
  const totalPriceRounded = Math.ceil((totalPrice * 100) / 100);
  if (hasRoundedPublicPrice) {
    return totalPriceRounded;
  } else {
    return totalPriceWithTwoDecimals;
  }
}
