import { SimplifyApi, createToast } from "../../utils";
import { call, put, all, takeEvery, select } from "redux-saga/effects";
import { replace } from "connected-react-router";
import _ from "lodash";
import { SIGN_OUT, FORCE_SIGN_OUT, SEND_PASSWORD_RESET_CODE, RESET_PASSWORD } from "../actions/types";
import { SubmissionError, change, startSubmit, stopSubmit, updateSyncErrors } from "redux-form";
import {
  signIn,
  register,
  createOnboardingSubscription,
  changePassword,
  sendPasswordResetCodeSuccess,
  sendPasswordResetCodeFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  signOutSuccess,
  signOutFailure,
  downloadArchive,
  downloadArtists,
  downloadContacts,
  downloadPaymentInvites,
  changeSelectedCollection,
  closeModal,
  syncStarted,
  startBackgroundSync,
  stopBackgroundSync,
  startQueueSystemCleaner,
  stopQueueSystemCleaner,
  createSessionDiscovery,
  getDiscoveryLoadRequest,
  getDiscoveryFavouritesRequest,
} from "../actions";
import { selectFailedActionQueue } from "../selectors";
import { ActionExceptions, Properties, Events } from "../../analytics/types";

import { AddUserAnalytics, RecordEventAnalytics, RecordExceptionAnalytics, ClearAnalyticsUser } from "../../analytics";

import { ApiErrors, Notifications } from "../../localisation";

// AUTH SERVICES
const signInService = (credentials) => SimplifyApi.post("/v1/signin", credentials);

const registerService = (newUser) => SimplifyApi.post("/v1/register", newUser);

const changePasswordService = (currentPassword, newPassword) =>
  SimplifyApi.post(`/v1/changePassword`, {
    currentPassword,
    newPassword,
  });

const sendPasswordResetCodeService = (email) => SimplifyApi.get(`/v1/resetPassword/${email}`);

const resetPasswordService = (email, resetCode, newPassword) =>
  SimplifyApi.post(`/v1/resetPassword/${email}`, { resetCode, newPassword });

const forceSignOutService = () => SimplifyApi.post("/v1/forceSignout", {});

// AUTH SAGAS
function* signInSaga(action) {
  yield put(startSubmit("login"));
  const { email, password, prevLocation } = action.payload;

  try {
    const response = yield call(signInService, { email, password, isWeb: true });

    const { lastSyncDate, user, collections, artists, artworks, shareRecords } = response.data;

    yield all([
      put(downloadArchive(collections, artists, artworks, shareRecords)),
      
    ]);

    let initialCollection;
    if (user.role === "artist") {
      initialCollection = _.find(collections, (collection) => collection.type === "production");
    } else {
      initialCollection = _.find(collections, (collection) => collection.type === "collection");
    }

    AddUserAnalytics(user);

    let redirectRoute = "/artworks";
    if (prevLocation) {
      if (prevLocation.pathname === "/upgrade" && prevLocation.search) {
        redirectRoute = `/upgrade${prevLocation.search}`;
      } else if (prevLocation.pathname === "/upgrade") {
        redirectRoute = "/upgrade";
      } else if (prevLocation.pathname === "/marketplace/onboarding") {
        redirectRoute = "/marketplace/onboarding";
      } else if (prevLocation.pathname === "/activity/sales") {
        redirectRoute = "/activity/sales";
      }
    }

    // Create Discovery session id on sign in
    const genRanHex = (size) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
    const tempUser = genRanHex(24);

    yield all([
      put(changeSelectedCollection(initialCollection.id)),
      put(signIn.success(user)),
      put(replace(redirectRoute)),
      put(syncStarted(lastSyncDate)),
      put(startBackgroundSync()),
      put(startQueueSystemCleaner()),
      put(downloadContacts()),
      put(downloadArtists()),
      put(downloadPaymentInvites()),
      put(createSessionDiscovery(tempUser, true)),
      put(getDiscoveryLoadRequest(tempUser, [], false)),
      put(getDiscoveryFavouritesRequest(user.id)),
    ]);

  } catch (error) {
    let errorString;

    if (error.response && error.response.status === 401) {
      errorString = ApiErrors.noPasswordMatch;
      createToast({ type: "error", ...Notifications.noPasswordMatch });
    } else {
      errorString = ApiErrors.generic;
      createToast({ type: "error", ...Notifications.generic });
    }

    const formError = new SubmissionError({
      password: errorString,
    });

    yield put(signIn.failure(formError));
  } finally {
    yield put(stopSubmit("login"));
  }
}

function* registerSaga(action) {
  yield put(startSubmit("register"));

  const {
    email,
    password,
    role,
    name,
    organisationName,
    address,
    city,
    country,
    postalCode,
    region,
    stripe,
    elements,
    plan,
    prevLocation,
  } = action.payload;

  try {
    const newUser = { email, password, role, name };

    newUser.pathWay = "subscription_flow";
    const response = yield call(registerService, newUser);

    const { user, lastSyncDate, collections, artists, artworks, shareRecords } = response.data;

    let initialCollection;

    if (user.role === "artist") {
      initialCollection = _.find(collections, (collection) => collection.type === "production");
    } else {
      initialCollection = _.find(collections, (collection) => collection.type === "collection");
    }

    AddUserAnalytics(user);

    // Create Discovery session id on sign up
    const genRanHex = (size) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
    const tempUser = genRanHex(24);

    yield all([
      put(register.success(user)),
      put(downloadArchive(collections, artists, artworks, shareRecords)),
      put(downloadContacts()),
      put(downloadArtists()),
      put(downloadPaymentInvites()),
      put(changeSelectedCollection(initialCollection.id)),
      put(syncStarted(lastSyncDate)),
      put(startBackgroundSync()),
      put(startQueueSystemCleaner()),
      put(createSessionDiscovery(tempUser, true)),
      put(getDiscoveryLoadRequest(tempUser, [], false)),
    ]);

    createToast({ type: "success", ...Notifications.accountCreatedSuccessfully });

    const organisation = {
      name: organisationName,
      address: {
        addressLine1: address,
        city: city,
        country: country,
        postalCode: postalCode,
        region: region,
      },
    };

    yield put(createOnboardingSubscription.request({ stripe, elements, plan, organisation }));
  } catch (error) {
    yield put(stopSubmit("register"));

    yield all([put(register.failure()), put(replace("/register"))]);
  }
}

function* signOutSaga() {
  try {
    ClearAnalyticsUser();

    const failedActions = yield select(selectFailedActionQueue);
    RecordEventAnalytics(Events.LOGOUT, {
      [Properties.NUM_UNSYNCED_RECORDS]: failedActions.length,
    });

    yield all([
      put(stopBackgroundSync()),
      put(stopQueueSystemCleaner()),
      put(signOutSuccess()),
      put(replace("/login")),
    ]);
  } catch (error) {
    yield put(signOutFailure());
  }
}

function* forceSignOutSaga() {
  try {
    yield all([put(stopBackgroundSync()), put(stopQueueSystemCleaner())]);

    // yield call(forceSignOutService);

    const failedActions = yield select(selectFailedActionQueue);
    RecordEventAnalytics(Events.FORCE_LOGOUT, {
      [Properties.NUM_UNSYNCED_RECORDS]: failedActions.length,
    });

    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure());
  }
}

function* changePasswordSaga(action) {
  try {
    const { currentPassword, newPassword } = action.payload;

    const response = yield call(changePasswordService, currentPassword, newPassword);

    yield all([put(changePassword.success()), put(closeModal())]);
    createToast({ type: "success", ...Notifications.passwordChangedSuccessfully });
  } catch (error) {
    if (error.response && error.response.status === 403) {
      const formError = new SubmissionError({
        currentPassword: ApiErrors.wrongCurrentPassword,
      });

      yield put(changePassword.failure(formError));
    } else {
      RecordExceptionAnalytics(ActionExceptions.CHANGE_PASSWORD);
      yield put(closeModal());
      createToast({ type: "error", ...Notifications.passwordResetFailed });
    }
  }
}

function* sendPasswordResetCodeSaga(action) {
  yield put(startSubmit("forgottenPassword"));
  const { email } = action.payload;
  try {
    yield call(sendPasswordResetCodeService, email);

    createToast({ type: "success", ...Notifications.passwordResetCodeSent });

    yield all([put(sendPasswordResetCodeSuccess()), put(change("forgottenPassword", "emailVerified", true))]);
  } catch (error) {
    let formError;
    if (error.response && error.response.status === 404) {
      formError = new SubmissionError({
        email: ApiErrors.emailNotExists,
      });

      yield put(sendPasswordResetCodeFailure());
    } else {
      RecordExceptionAnalytics(ActionExceptions.EMAIL_FORGOTTEN_PASSWORD);
    }

    throw formError;
  } finally {
    yield put(stopSubmit("forgottenPassword"));
  }
}

function* resetPasswordSaga(action) {
  try {
    yield put(startSubmit("forgottenPassword"));

    const { email, resetCode, newPassword } = action.payload;

    yield call(resetPasswordService, email, resetCode, newPassword);

    createToast({ type: "success", ...Notifications.passwordChangedSuccessfully });

    yield all([put(resetPasswordSuccess()), put(signIn.request({ email, password: newPassword }))]);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      yield put(
        updateSyncErrors("forgottenPassword", {
          resetCode: ApiErrors.invalidPasswordResetCode,
        })
      );
    } else {
      RecordExceptionAnalytics(ActionExceptions.RESET_PASSWORD_W_CODE);

      createToast({ type: "error", ...Notifications.generic });
    }

    yield put(resetPasswordFailure());
  } finally {
    yield put(stopSubmit("forgottenPassword"));
  }
}

function* authSaga() {
  yield takeEvery(signIn.REQUEST, signInSaga);
  yield takeEvery(register.REQUEST, registerSaga);
  yield takeEvery(SIGN_OUT, signOutSaga);
  yield takeEvery(FORCE_SIGN_OUT, forceSignOutSaga);
  yield takeEvery(changePassword.REQUEST, changePasswordSaga);
  yield takeEvery(SEND_PASSWORD_RESET_CODE, sendPasswordResetCodeSaga);
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
}

export default authSaga;
