import { useRef } from "react";
import QRCode from "react-qr-code";
import { Flex } from "../fundamentals";
import { Button } from "./Button";

const GeneratedQrCode = ({ value, imageTitle, isDownloadable, ...props }) => {
  const qrRef = useRef();

  const downloadQrImage = (href, name) => {
    const link = document.createElement("a");
    link.download = name;
    link.style.opacity = "0";
    document.body.append(link);
    link.href = href;
    link.click();
    link.remove();
  };

  const handleDownloadQrCode = (e) => {
    e.preventDefault();
    const svgElement = qrRef.current;

    // Find the width and height of the SVG
    let { width, height } = svgElement.getBBox();
    // Clone node
    let clonedSvgElement = svgElement.cloneNode(true);
    // Create a blob object from the cloned node
    let outerHTML = clonedSvgElement.outerHTML,
      blob = new Blob([outerHTML], { type: "image/svg+xml;charset=utf-8" });
    // Create a URL from the blob object
    let URL = window.URL || window.webkitURL || window;
    let blobURL = URL.createObjectURL(blob);
    // Load the blobURL to the image element
    let image = new Image();
    image.src = blobURL;

    // Once the image is loaded we can draw the image onto the canvas
    image.onload = () => {
      let canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      let context = canvas.getContext("2d");
      // Draw image onto the canvas starting from left-0 , top-0
      context.drawImage(image, 0, 0, width, height);

      let pngUrl = canvas.toDataURL(); // default png
      let jpegUrl = canvas.toDataURL("image/jpg");

      // Download image as png
      downloadQrImage(pngUrl, `${imageTitle}.png`);
    };
  };

  return (
    <Flex flexDirection="column" alignItems="center">
      <QRCode
        ref={qrRef}
        value={value}
        size={256}
        style={{ height: props.height ?? "180px" }}
        viewBox={`0 0 256 256`}
      />
      {isDownloadable && (
        <Button buttonStyle="secondary" alignSelf="center" size="small" mt="m" onClick={handleDownloadQrCode}>
          Download QR Code
        </Button>
      )}
    </Flex>
  );
};

export { GeneratedQrCode as QRCode };
