import { Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { Text } from "../../fundamentals";
import { useIsScreenDesktop, useIsScreenMobile, useIsScreenTablet } from "../../hooks/useMediaQuery";
import { useSelector } from "react-redux";

const discountStyle = {
  "&:hover": {
    color: "white",
  },
};

const billingStyle = {
  fontSize: "12px",
  lineHeight: "14px",
  fontWeight: "600",
  textAlign: "center",
  letterSpacing: "2px",
};

const boxStyle = (selected) => {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `2px solid ${selected ? "#5E54FF" : "black"}`,
    borderRadius: "15px",
    width: "130px",
    height: "100px",
    cursor: "pointer",
    backgroundColor: selected ? "#5E54FF" : undefined,
    color: selected ? "white" : undefined,
    // marginRight: "24px",
    "&:hover": {
      color: "white",
      border: `2px solid #5E54FF`,
      backgroundColor: "#5E54FF",
    },
  };
};

export const RenderPaymentPlan = ({ input, label, itemValue, price,price_for_month, discount, billing_period, mr, ...props }) => {
  const planType = useSelector(state => state.user.urlType);
  // If the user has manually selected a plan and input.value is not "", then selected should be true when input.value is equal to itemValue
  // otherwise selected should be true for the plan that corresponds to the users selection on webflow (planType)
  const selected = input.value ? input.value === itemValue : planType === 'annually' ? itemValue.includes('yearly') : itemValue.includes(planType);
  const [hover, setHover] = useState(false);
  const isDesktop = useIsScreenDesktop();

  useEffect(() => {
    if (planType) {
      input.onBlur(itemValue);
    } 
  }, []);

  return (
    <Box
      sx={boxStyle(selected)}
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      onClick={() => input.onChange(itemValue)}
      {...props}
    >
      <Text fontWeight="700" size={isDesktop?"36px": "28px"} lineHeight="110%" fontFamily="bodyDmSans">
        {"\u20ac" + price_for_month}
        <Text size="small" fontWeight="700" fontFamily="bodyDmSans">
          /mo
        </Text>
      </Text>
      <Box mt="xxxs">
        <Typography sx={billingStyle}>{billing_period.toUpperCase()}</Typography>
        {discount && (
          <Typography sx={{ ...billingStyle, ...discountStyle, color: `${selected || hover ? "white" : "#5E54FF"}` }}>
            {"SAVE " + discount + "%"}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
