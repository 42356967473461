import { useState } from "react";
import { Box, Text, Flex, Icon } from "../../fundamentals";
import {
  ButtonLabels,
  Headings,
  DynamicHeadings,
  UiLabels,
  PaymentInviteBuyerNextSteps,
  PaymentInviteSecurityMeasures,
  PaymentInviteEnquiryNextSteps,
  PaymentInviteOfferNextSteps,
} from "../../../localisation";
import { Button } from "../../elements";
import { ChevronDown } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { openDrawer, closeDrawer, openModal } from "../../../store/actions";
import { selectPaymentInviteById } from "../../../store/selectors";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { PaymentInviteArtworkValuesTable } from "../../modules";
import { BuyerGeneralInfoForm } from "../../forms/PaymentInviteForms/BuyerGeneralInfoForm";
import { BuyerEnquiryForm, PaymentInvitePaymentForm } from "../../modals";
import { stripePromise } from "../../../config";
import { Elements } from "@stripe/react-stripe-js";
import { BuyerOfferForm } from "../../forms/PaymentInviteForms/BuyerOfferForm";

const accordionStyle = {
  border: "none",
  borderTop: "1px solid #E8E7EA",
  paddingTop: "16px",
  maxWidth: "100%",
};

const accordionSummaryStyle = {
  padding: "0px",
  alignItems: "start",
  height: "fit-content",
  minHeight: "0",
  paddingBottom: "16px",
  "& .MuiAccordionSummary-content": {
    margin: "0",
  },
  "&:hover": {
    backgroundColor: "white",
  },
};

const accordionDetailsStyle = {
  padding: "0",
  paddingBottom: "16px",
};

const ProgressBar = ({ page }) => {
  return (
    <Flex fontSize="smaller">
      <Text color="blue.60" mr="xxs">
        {UiLabels.information}
      </Text>
      <Icon icon="ChevronRight" color="grey.40" size="16" mr="xxs" />
      <Text mr="xxs" color={page === "shipping" || page === "payment" ? "blue.60" : "black"}>
        {UiLabels.shipping}
      </Text>
      <Icon icon="ChevronRight" color="grey.40" size="16" mr="xxs" />
      <Text color={page === "payment" ? "blue.60" : "black"}>{UiLabels.payment}</Text>
    </Flex>
  );
};

const securityMeasures = [
  {
    icon: "Shield",
    text: PaymentInviteSecurityMeasures.protectedDetails,
  },
  {
    icon: "Info",
    text: PaymentInviteSecurityMeasures.phishingScams,
  },
  {
    icon: "Lock",
    text: PaymentInviteSecurityMeasures.sensitiveInfo,
  },
];

const NextSteps = ({ transportMode, isOffer, isEnquiry }) => {
  let defaultNextSteps = [
    {
      title: PaymentInviteBuyerNextSteps.stepOneBeforePayment,
      description: PaymentInviteBuyerNextSteps.stepOneDescBuyerGenerated,
      icon: "CreditCard",
    },
    {
      title: PaymentInviteBuyerNextSteps.stepTwo,
      description: PaymentInviteBuyerNextSteps.stepTwoDescBeforePayment,
      icon: "Bell",
    },
    {
      title: PaymentInviteBuyerNextSteps.stepThree,
      description: PaymentInviteBuyerNextSteps.stepThreeDesc,
      icon: "Truck",
    },
    {
      title: PaymentInviteBuyerNextSteps.stepFour,
      description: PaymentInviteBuyerNextSteps.stepFourDesc,
      icon: "Image",
    },
  ];
  if (transportMode === "ownShipping") {
    defaultNextSteps = [
      {
        title: PaymentInviteBuyerNextSteps.stepOneBeforePayment,
        description: PaymentInviteBuyerNextSteps.stepOneDescBuyerGenerated,
        icon: "CreditCard",
      },
      {
        title: PaymentInviteBuyerNextSteps.stepTwoOwnShipping,
        description: PaymentInviteBuyerNextSteps.stepTwoDescOwnShipping,
        icon: "Bell",
      },
      {
        title: PaymentInviteBuyerNextSteps.stepThreeOwnShipping,
        description: PaymentInviteBuyerNextSteps.stepThreeDescOwnShipping,
        icon: "Truck",
      },
      {
        title: PaymentInviteBuyerNextSteps.stepFour,
        description: PaymentInviteBuyerNextSteps.stepFourDesc,
        icon: "Image",
      },
    ];
  } else if (transportMode === "handover") {
    defaultNextSteps = [
      {
        title: PaymentInviteBuyerNextSteps.stepOneBeforePayment,
        description: PaymentInviteBuyerNextSteps.stepOneDescBuyerGenerated,
        icon: "CreditCard",
      },
      {
        title: PaymentInviteBuyerNextSteps.stepTwoOwnShipping,
        description: PaymentInviteBuyerNextSteps.stepTwoDescOwnShipping,
        icon: "Bell",
      },
      {
        title: PaymentInviteBuyerNextSteps.stepThreePersonalHandover,
        description: PaymentInviteBuyerNextSteps.stepThreeDescPersonalHandover,
        icon: "Truck",
      },
      {
        title: PaymentInviteBuyerNextSteps.stepFourPersonalHandover,
        description: PaymentInviteBuyerNextSteps.stepFourDescPersonalHandover,
        icon: "Image",
      },
    ];
  }

  const offerNextSteps = [
    {
      title: PaymentInviteOfferNextSteps.stepOne,
      description: PaymentInviteOfferNextSteps.stepOneDesc,
      icon: "Mail",
    },
    {
      title: PaymentInviteOfferNextSteps.stepTwo,
      description: PaymentInviteOfferNextSteps.stepTwoDesc,
      icon: "ThumbsUp",
    },
    {
      title: PaymentInviteOfferNextSteps.stepThree,
      description: PaymentInviteOfferNextSteps.stepThreeDesc,
      icon: "CreditCard",
    },
  ];

  const enquiryNextSteps = [
    {
      title: PaymentInviteEnquiryNextSteps.stepOne,
      description: PaymentInviteEnquiryNextSteps.stepOneDesc,
      icon: "Mail",
    },
    {
      title: PaymentInviteEnquiryNextSteps.stepTwo,
      description: PaymentInviteEnquiryNextSteps.stepTwoDesc,
      icon: "MessageCircle",
    },
    {
      title: PaymentInviteEnquiryNextSteps.stepThree,
      description: PaymentInviteEnquiryNextSteps.stepThreeDesc,
      icon: "CreditCard",
    },
  ];

  const nextSteps = isOffer ? offerNextSteps : isEnquiry ? enquiryNextSteps : defaultNextSteps;

  return (
    <Box>
      {nextSteps.map((step, index) => (
        <Flex key={step.title} mb={index !== nextSteps.length - 1 && "m"} alignItems="start">
          <Box borderRadius="50%" backgroundColor="blue.20" padding="xxxs" mr="s">
            <Icon icon={step.icon} size="14" color="blue.60" />
          </Box>
          <Box>
            <Text fontWeight="500" mb="xxxs">
              {step.title}
            </Text>
            <Text fontSize="small">{step.description}</Text>
          </Box>
        </Flex>
      ))}
    </Box>
  );
};

export const PaymentInviteBuyerDrawer = ({ paymentInviteId, isMarketPlace, placeId, page, isOffer, isEnquiry }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [total, setTotal] = useState(0);
  const discoveryState = useSelector((state) => state.discovery);
  const { sessionId } = discoveryState;
  const paymentInvite = useSelector((state) => selectPaymentInviteById(state, paymentInviteId));
  const transportMode = useSelector((state) => state.form.payment_invite?.update?.values?.transportMode);
  const hasShippingCosts = paymentInvite?.costs.some((cost) => cost.name === "Shipping Costs");
  const artworkCurrency = paymentInvite?.artworks.reduce((acc, item) => {
    if (!acc && item.price.currency) {
      return item.price.currency;
    }
    return acc;
  }, null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleButtonClickFromShipping = () => {
    if (hasShippingCosts) {
      dispatch(
        openDrawer("payment_invite_buyer", {
          paymentInviteId: paymentInvite._id,
          placeId,
          isMarketPlace,
          page: "payment",
        })
      );
    } else {
      dispatch(closeDrawer());
      dispatch(
        openModal("payment_invite_buyer.status", { paymentInviteId: paymentInvite._id, placeId, isMarketPlace })
      );
    }
  };

  const initialValues = {
    contactEmail: paymentInvite?.contacts.length === 1 ? paymentInvite?.contacts[0]._id.email : "",
    contactPhone: paymentInvite?.contacts.length === 1 ? paymentInvite?.contacts[0]._id.phone : "",
  };

  const initialOfferValues = {
    offeredAmount: { currency: artworkCurrency },
  };

  return (
    <Box pb="20px">
      {/* Heading */}
      <Box padding="20px" paddingBottom="28px">
        <Text fontFamily="bodyDmSans" fontSize="20px" mb="xxxs">
          {isOffer
            ? DynamicHeadings.makeOfferTo(paymentInvite?._user?.organisation?.name || paymentInvite?._user?.name)
            : isEnquiry
            ? DynamicHeadings.sendEnquiryTo(paymentInvite?._user?.organisation?.name || paymentInvite?._user?.name)
            : DynamicHeadings.purchaseFrom(paymentInvite?._user?.organisation?.name || paymentInvite?._user?.name)}
        </Text>
        {!isOffer && !isEnquiry && <ProgressBar page={page} />}
      </Box>
      {/* Main */}
      <Box paddingLeft="20px" paddingRight="20px" maxHeight="80vh" overflowY="auto">
        {/* Secure Payment Info */}
        {!isOffer && !isEnquiry && (
          <MuiAccordion
            sx={accordionStyle}
            disableGutters
            elevation={0}
            expanded={expanded === "securePayment"}
            onChange={handleChange("securePayment")}
          >
            <MuiAccordionSummary
              expandIcon={<ChevronDown size="16" color="black" />}
              aria-controls="secure-payment-details"
              id="secure-payment-header"
              sx={accordionSummaryStyle}
            >
              <Text>{Headings.securePayment}</Text>
            </MuiAccordionSummary>
            <MuiAccordionDetails sx={accordionDetailsStyle}>
              {securityMeasures.map((measure, index) => (
                <Flex key={measure.text} alignItems="start" mb={index === securityMeasures.length - 1 ? "0" : "xs"}>
                  <Box>
                    <Icon icon={measure.icon} size="18" color="blue.60" />
                  </Box>
                  <Text fontSize="smaller" ml="10px">
                    {measure.text}
                  </Text>
                </Flex>
              ))}
            </MuiAccordionDetails>
          </MuiAccordion>
        )}
        {/* Next Steps */}
        <MuiAccordion
          sx={{ ...accordionStyle, borderBottom: "1px solid #E8E7EA", marginBottom: "28px" }}
          disableGutters
          elevation={0}
          expanded={expanded === "nextSteps"}
          onChange={handleChange("nextSteps")}
        >
          <MuiAccordionSummary
            expandIcon={<ChevronDown size="16" color="black" />}
            aria-controls="secure-payment-details"
            id="secure-payment-header"
            sx={accordionSummaryStyle}
          >
            <Text>{Headings.nextSteps}</Text>
          </MuiAccordionSummary>
          <MuiAccordionDetails sx={accordionDetailsStyle}>
            <NextSteps isOffer={isOffer} isEnquiry={isEnquiry} transportMode={transportMode} />
          </MuiAccordionDetails>
        </MuiAccordion>
        {!isOffer && !isEnquiry && (
          <PaymentInviteArtworkValuesTable
            paymentInvite={paymentInvite}
            total={total}
            setTotal={setTotal}
            hasShippingCosts={hasShippingCosts}
            isShippingModal={page === "shipping"}
          />
        )}
        {page === "information" && (
          <BuyerGeneralInfoForm
            initialValues={initialValues}
            paymentInviteId={paymentInviteId}
            placeId={isMarketPlace ? sessionId : placeId}
            isMarketPlace={isMarketPlace}
            publicLocationExists={paymentInvite?.publicLocationExists}
          />
        )}

        {page === "shipping" && (
          <Box>
            <Box width="100%" border="1px solid #C8CEFF" borderRadius="8px" padding="m" mb="m" fontSize="small">
              <Flex justifyContent="space-between" pb="xs" borderBottom="1px solid #C3C2C9">
                <Text>{UiLabels.contact}</Text>
                <Text fontWeight="500">{paymentInvite?.contacts[paymentInvite?.contacts?.length - 1]?.email}</Text>
              </Flex>
              <Flex justifyContent="space-between" mt="xs">
                <Text>{UiLabels.shipTo}</Text>
                <Text fontWeight="500">
                  {`${paymentInvite?.shippingDetails?.address1}, ${paymentInvite?.shippingDetails?.postcode}, ${paymentInvite?.shippingDetails?.city}, ${paymentInvite?.shippingDetails?.country} `}
                </Text>
              </Flex>
            </Box>
            <Button buttonStyle="primary" size="medium" fontWeight="500" mt="s" onClick={handleButtonClickFromShipping}>
              {hasShippingCosts ? ButtonLabels.continueToPayment : ButtonLabels.status}
            </Button>
          </Box>
        )}
        {page === "payment" && (
          <Elements stripe={stripePromise}>
            <PaymentInvitePaymentForm
              paymentInviteId={paymentInviteId}
              paymentInvite={paymentInvite}
              placeId={placeId}
              isMarketPlace={isMarketPlace}
              total={total}
              currency={artworkCurrency}
            />
          </Elements>
        )}
        {isOffer && (
          <BuyerOfferForm
            paymentInviteId={paymentInviteId}
            isMarketPlace={isMarketPlace}
            placeId={placeId}
            initialValues={initialOfferValues}
          />
        )}
        {isEnquiry && (
          <BuyerEnquiryForm paymentInviteId={paymentInviteId} isMarketPlace={isMarketPlace} placeId={placeId} />
        )}
      </Box>
    </Box>
  );
};
