import React, { useEffect } from "react";
import styled from "styled-components";
import { Box, Flex, Text } from "../fundamentals";
import { Modal } from "../elements";
import { useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { OnboardingForm } from "../forms/PaymentInviteForms/Onboarding/OnboardingForm";
import { WithRequireAccess, StickyObservable } from "../hocs";
import { selectUser } from "../../store/selectors";
import { useHistory } from "react-router-dom";
import { NavigationBar } from "../react-material/NavigationBar";
import { AlertModal } from "../modals";
import { Headings, Info, ButtonLabels } from "../../localisation";
import {openModal, sendQrSource} from "../../store/actions";
import { sendEvent } from "../../store/actions";

const StyledMarketplaceOnboardingScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const MarketplaceOnboardingScreen = () => {
  const user = useSelector(selectUser);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    //Currently user always get successfully onboarded after being redirected here from Stripe
    // if (!user.account.marketplaceOnboarded && !user.account.idVerified) {
    //   dispatch(openModal("id_verification_failed"));
    //   history.push("/artworks");
    // } else {
        dispatch(sendEvent({eventName:"PaymentInviteOnboardingCompleted",
        message: {paymentInviteOnboarded: "completed"}, userId: user._id}));
        // setTimeout(() => history.replace("/artworks"), 5000);
        dispatch(openModal("marketplace_onboarding_completed"));
    // }
    
}, []);

  

  return (
    <StyledMarketplaceOnboardingScreen bg="white">
      <NavigationBar />
      <Modal id="marketplace_onboarding_completed">
        <AlertModal 
          title={Headings.activationComplete}
          message={Info.startSelling}
          acceptButtonText={ButtonLabels.goToArtworks}
          onAccept={()=> history.replace("/artworks")}
          width="500px"
        />
      </Modal>
    </StyledMarketplaceOnboardingScreen>
  );
};

export default WithRequireAccess(MarketplaceOnboardingScreen, 0);
