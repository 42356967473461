//APP
export const CHANGE_CONNECTION_STATUS = "@@app/CHANGE_CONNECTION_STATUS";
export const UPDATE_APP_VERSION = "@@app/UPDATE_APP_VERSION";
export const RESET_APP = "@@app/RESET_APP";
export const RESET_APP_SUCCESS = "@@app/RESET_APP_SUCCESS";
export const RESET_APP_FAILURE = "@@app/RESET_APP_FAILURE";

//AUTH
export const SIGN_IN = "@@auth/SIGN_IN"; //Created actions with redux-form-saga
export const SIGN_OUT = "@@auth/SIGN_OUT";
export const FORCE_SIGN_OUT = "@@auth/FORCE_SIGN_OUT";
export const SIGN_OUT_SUCCESS = "@@auth/SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILURE = "@@auth/SIGN_OUT_FAILURE";
export const REGISTER = "@@auth/REGISTER"; //Created actions with redux-form-saga
export const SEND_PASSWORD_RESET_CODE = "@@auth/SEND_PASSWORD_RESET_CODE";
export const SEND_PASSWORD_RESET_CODE_SUCCESS = "@@auth/SEND_PASSWORD_RESET_CODE_SUCCESS";
export const SEND_PASSWORD_RESET_CODE_FAILURE = "@@auth/SEND_PASSWORD_RESET_CODE_FAILURE";
export const RESET_PASSWORD = "@@auth/RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "@@auth/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "@@auth/RESET_PASSWORD_FAILURE";
export const CHANGE_PASSWORD = "@@auth/CHANGE_PASSWORD"; //Created actions with redux-form-saga

//USER
export const SYNC_USER = "@@user/SYNC_USER";
export const CREATE_SUBSCRIPTION = "@@user/CREATE_SUBSCRIPTION"; //Created actions with redux-form-saga
export const CREATE_ONBOARDING_SUBSCRIPTION = "@@user/CREATE_ONBOARDING_SUBSCRIPTION"; //Created actions with redux-form-saga
export const CREATE_FREE_USER = "@@yser/CREATE_FREE_USER";
export const EXPIRED_PAYMENT_METHOD = "@@user/EXPIRED_PAYMENT_METHOD"; // Created actions with redux-from-saga
export const GET_SUBSCRIPTION = "@@user/GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS = "@@user/GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAILURE = "@@user/GET_SUBSCRIPTION_FAILURE";
export const CANCEL_SUBSCRIPTION = "@@user/CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "@@user/CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "@@user/CANCEL_SUBSCRIPTION_FAILURE";
export const RESTART_SUBSCRIPTION = "@@user/RESTART_SUBSCRIPTION";
export const RESTART_SUBSCRIPTION_SUCCESS = "@@user/RESTART_SUBSCRIPTION_SUCCESS";
export const RESTART_SUBSCRIPTION_FAILURE = "@@user/RESTART_SUBSCRIPTION_FAILURE";
export const CHANGE_SUBSCRIPTION = "@@user/CHANGE_SUBSCRIPTION";
export const CHANGE_SUBSCRIPTION_SUCCESS = "@@user/CHANGE_SUBSCRIPTION_SUCCESS";
export const CHANGE_SUBSCRIPTION_FAILURE = "@@user/CHANGE_SUBSCRIPTION_FAILURE";
export const GET_PAYMENT_METHOD = "@@user/GET_PAYMENT_METHOD";
export const GET_PAYMENT_METHOD_SUCCESS = "@@user/GET_PAYMENT_METHOD_SUCCESS";
export const GET_PAYMENT_METHOD_FAILURE = "@@user/GET_PAYMENT_METHOD_FAILURE";
export const CHANGE_PAYMENT_METHOD = "@@user/CHANGE_PAYMENT_METHOD"; //Created actions with redux-form-saga
export const GET_USER = "@@user/GET_USER";
export const GET_USER_SUCCESS = "@@user/GET_USER_SUCCESS";
export const GET_USER_FAILURE = "@@user/GET_USER_FAILURE";
export const UPDATE_USER_DETAILS = "@@user/UPDATE_USER_DETAILS"; //Created actions with redux-form-saga
export const UPDATE_ORGANISATION = "@@user/UPDATE_ORGANISATION"; //Created actions with redux-form-saga
export const DELETE_USER_ACCOUNT = "@@user/DELETE_USER_ACCOUNT";
export const DELETE_USER_ACCOUNT_SUCCESS = "@@user/DELETE_USER_ACCOUNT_SUCCESS";
export const DELETE_USER_ACCOUNT_FAILURE = "@@user/DELETE_USER_ACCOUNT_FAILURE";
export const UPDATE_BILLING_PERIOD = "@@user/BILLING_PERIOD";
export const UPDATE_URL_ROLE = "@@user/UPDATE_URL_ROLE";
export const UPDATE_URL_TYPE = "@@user/UPDATE_URL_TYPE";
export const CONTACT_US = "@@user/CONTACT_US";
export const CONTACT_US_SUCCESS = "@@user/CONTACT_US_SUCCESS";
export const CONTACT_US_FAILURE = "@@user/CONTACT_US_FAILURE";
export const PAYMENT_INVITE_BETA_REQUEST = "@@user/PAYMENT_INVITE_BETA_REQUEST";
export const PAYMENT_INVITE_BETA_REQUEST_SUCCESS = "@@user/PAYMENT_INVITE_BETA_REQUEST_SUCCESS";
export const PAYMENT_INVITE_BETA_REQUEST_FAILURE = "@@user/PAYMENT_INVITE_BETA_REQUEST_FAILURE";
export const SIGN_UP_BETA = "@@user/SIGN_UP_BETA";
export const GET_DISCOUNT_REQUEST = "@@user/GET_DISCOUNT_REQUEST";
export const GET_DISCOUNT_SUCCESS = "@@user/GET_DISCOUNT_SUCCESS";
export const GET_DISCOUNT_FAILURE = "@@user/GET_DISCOUNT_FAILURE";

//ARCHIVE
export const DOWNLOAD_ARCHIVE = "@@archive/DOWNLOAD_ARCHIVE";
export const SYNC_ARCHIVE = "@@archive/SYNC_ARCHIVE";
export const CREATE_ARTWORK = "@@archive/CREATE_ARTWORK";
export const CREATE_ARTWORK_AND_COPY = "@@archive/CREATE_ARTWORK_AND_COPY";
export const CREATE_ARTWORK_AND_NEW = "@@archive/CREATE_ARTWORK_AND_NEW";
export const CREATE_ARTWORK_LOCALLY = "@@archive/CREATE_ARTWORK_LOCALLY";
export const CREATE_ARTWORK_SUCCESS = "@@archive/CREATE_ARTWORK_SUCCESS";
export const CREATE_ARTWORK_FAILURE = "@@archive/CREATE_ARTWORK_FAILURE";
export const EDIT_MULTIPLE_ARTWORKS = "@@archive/EDIT_MULTIPLE_ARTWORKS";
export const UPDATE_ARTWORK_AFTER_MULTI_EDIT = "@@archive/UPDATE_ARTWORK_AFTER_MULTI_EDIT";
export const EDIT_ARTWORK = "@@archive/EDIT_ARTWORK";
export const EDIT_ARTWORK_LOCALLY = "@@archive/EDIT_ARTWORK_LOCALLY";
export const EDIT_ARTWORK_SUCCESS = "@@archive/EDIT_ARTWORK_SUCCESS";
export const EDIT_ARTWORK_FAILURE = "@@archive/EDIT_ARTWORK_FAILURE";
export const UPDATE_ARTWORK_STATUS = "@@archive/UPDATE_ARTWORK_STATUS";
export const DELETE_ARTWORKS = "@@archive/DELETE_ARTWORKS";
export const DELETE_ARTWORK = "@@archive/DELETE_ARTWORK";
export const DELETE_ARTWORK_SUCCESS = "@@archive/DELETE_ARTWORK_SUCCESS";
export const DELETE_ARTWORK_FAILURE = "@@archive/DELETE_ARTWORK_FAILURE";
export const UPLOAD_ARTWORK = "@@archive/UPLOAD_ARTWORK";
export const UPLOAD_ARTWORK_SUCCESS = "@@archive/UPLOAD_ARTWORK_SUCCESS";
export const UPLOAD_ARTWORK_FAILURE = "@@archive/UPLOAD_ARTWORK_FAILURE";
export const UPLOAD_ARTWORK_IMAGE = "@@archive/UPLOAD_ARTWORK_IMAGE";
export const UPLOAD_ARTWORK_IMAGE_SUCCESS = "@@archive/UPLOAD_ARTWORK_IMAGE_SUCCESS";
export const UPLOAD_ARTWORK_IMAGE_FAILURE = "@@archive/UPLOAD_ARTWORK_IMAGE_FAILURE";
export const UPDATE_ARTWORK_IMAGE_UPLOAD_PROGRESS = "@@archive/UPDATE_ARTWORK_IMAGE_UPLOAD_PROGRESS";
export const LOCAL_UPLOAD_ARTWORK_IMAGE = "@@archive/LOCAL_UPLOAD_ARTWORK_IMAGE";
export const LOCAL_UPLOAD_ARTWORK_IMAGE_SUCCESS = "@@archive/LOCAL_UPLOAD_ARTWORK_IMAGE_SUCCESS";
export const LOCAL_UPLOAD_ARTWORK_IMAGE_FAILURE = "@@archive/LOCAL_UPLOAD_ARTWORK_IMAGE_FAILURE";
export const SAVE_SHARED_ARTWORK = "@@archive/SAVE_SHARE_ARTWORK";
export const SAVE_SHARED_ARTWORK_LOCALLY = "@@archive/SAVE_SHARE_ARTWORK_LOCALLY";
export const SAVE_SHARED_ARTWORK_SUCCESS = "@@archive/SAVE_SHARE_ARTWORK_SUCCESS";
export const SAVE_SHARED_ARTWORK_FAILURE = "@@archive/SAVE_SHARE_ARTWORK_FAILURE";
export const SHARE_ARTWORK = "@@archive/SHARE_ARTWORK";
export const SHARE_ARTWORK_SUCCESS = "@@archive/SHARE_ARTWORK_SUCCESS";
export const SHARE_ARTWORK_FAILURE = "@@archive/SHARE_ARTWORK_FAILURE";
export const CREATE_EXPORT = "@@archive/CREATE_EXPORT";
export const CREATE_EXPORT_SUCCESS = "@@archive/CREATE_EXPORT_SUCCESS";
export const CREATE_EXPORT_FAILURE = "@@archive/CREATE_EXPORT_FAILURE";
export const CREATE_CSV = "@@archive/CREATE_CSV";
export const CREATE_CSV_SUCCESS = "@@archive/CREATE_CSV_SUCCESS";
export const CREATE_CSV_FAILURE = "@@archive/CREATE_CSV_FAILURE";
export const IMPORT_ARCHIVE_REQUEST = "@@/IMPORT_ARCHIVE_REQUEST";
export const IMPORT_ARCHIVE_SUCCESS = "@@/IMPORT_ARCHIVE_SUCCESS";
export const IMPORT_ARCHIVE_FAILURE = "@@/IMPORT_ARCHIVE_FAILURE";
export const DOWNLOAD_ARCHIVE_TEMPLATE = "@@/DOWNLOAD_ARCHIVE_TEMPLATE";
export const EXPORT_ARCHIVE_REQUEST = "@@/EXPORT_ARCHIVE_REQUEST";
export const EXPORT_ARCHIVE_SUCCESS = "@@/EXPORT_ARCHIVE_SUCCESS";
export const EXPORT_ARCHIVE_FAILURE = "@@/EXPORT_ARCHIVE_FAILURE";

export const CREATE_ARTIST = "@@archive/CREATE_ARTIST";
export const CREATE_ARTIST_LOCALLY = "@@archive/CREATE_ARTIST_LOCALLY";
export const CREATE_ARTIST_SUCCESS = "@@archive/CREATE_ARTIST_SUCCESS";
export const CREATE_ARTIST_FAILURE = "@@archive/CREATE_ARTIST_FAILURE";
export const DOWNLOAD_ARTISTS = "@@archive/DOWNLOAD_ARTISTS";
export const DOWNLOAD_ARTISTS_SUCCESS = "@@archive/DOWNLOAD_ARTISTS_SUCCESS";
export const DOWNLOAD_ARTISTS_FAILURE = "@@archive/DOWNLOAD_ARTISTS_FAILURE";
export const UPDATE_ARTIST = "@@archive/UPDATE_ARTIST";
export const DELETE_ARTIST = "@@archive/DELETE_ARTIST";
export const DELETE_ARTISTS = "@@archive/DELETE_ARTISTS";
export const UPLOAD_ARTIST_IMAGE = "@@archive/UPLOAD_ARTIST_IMAGE";
export const UPLOAD_ARTIST_IMAGE_SUCCESS = "@@archive/UPLOAD_ARTIST_IMAGE_SUCCESS";
export const UPLOAD_ARTIST_IMAGE_FAILURE = "@@archive/UPLOAD_ARTIST_IMAGE_FAILURE";
export const EDIT_ARTIST = "@@archive/EDIT_ARTIST";
export const EDIT_ARTIST_LOCALLY = "@@archive/EDIT_ARTIST_LOCALLY";
export const EDIT_ARTIST_SUCCESS = "@@archive/EDIT_ARTIST_SUCCESS";
export const EDIT_ARTIST_FAILURE = "@@archive/EDIT_ARTIST_FAILURE";
export const DELETE_ARTIST_SUCCESS = "@@archive/DELETE_ARTIST_SUCCESS";
export const DELETE_ARTIST_FAILURE = "@@archive/DELETE_ARTIST_FAILURE";

//CONTACT MANAGEMENT
export const DOWNLOAD_CONTACTS = "@@contact/DOWNLOAD_CONTACTS";
export const DOWNLOAD_CONTACTS_SUCCESS = "@@contact/DOWNLOAD_CONTACTS_SUCCESS";
export const DOWNLOAD_CONTACTS_FAILURE = "@@contact/DOWNLOAD_CONTACTS_FAILURE";
export const SYNC_CONTACTS = "@@contact/SYNC_CONTACTS";
export const CREATE_CONTACT = "@@contact/CREATE_CONTACT";
export const CREATE_CONTACT_LOCALLY = "@@contact/CREATE_CONTACT_LOCALLY";
export const CREATE_CONTACT_SUCCESS = "@@contact/CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_FAILURE = "@@contact/CREATE_CONTACT_FAILURE";
export const UPDATE_CONTACT = "@@contact/UPDATE_CONTACT";
export const DELETE_CONTACT = "@@contact/DELETE_CONTACT";
export const DELETE_CONTACTS = "@@contact/DELETE_CONTACTS";
export const UPLOAD_CONTACT_IMAGE = "@@contact/UPLOAD_CONTACT_IMAGE";
export const UPLOAD_CONTACT_IMAGE_SUCCESS = "@@contact/UPLOAD_CONTACT_IMAGE_SUCCESS";
export const UPLOAD_CONTACT_IMAGE_FAILURE = "@@contact/UPLOAD_CONTACT_IMAGE_FAILURE";
export const EDIT_CONTACT = "@@contact/EDIT_CONTACT";
export const EDIT_CONTACT_LOCALLY = "@@contact/EDIT_CONTACT_LOCALLY";
export const EDIT_CONTACT_SUCCESS = "@@contact/EDIT_CONTACT_SUCCESS";
export const EDIT_CONTACT_FAILURE = "@@contact/EDIT_CONTACT_FAILURE";
export const DELETE_CONTACT_SUCCESS = "@@contact/DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAILURE = "@@contact/DELETE_CONTACT_FAILURE";

//PAYMENT INVITE
export const CREATE_PAYMENT_INVITE_REQUEST = "@@payment_invite/CREATE_PAYMENT_INVITE_REQUEST";
export const CREATE_PAYMENT_INVITE_SUCCESS = "@@payment_invite/CREATE_PAYMENT_INVITE_SUCCESS";
export const CREATE_PAYMENT_INVITE_FAILURE = "@@payment_invite/CREATE_PAYMENT_INVITE_FAILURE";
export const INITIATE_INVITE_PAYMENT = "@@payment_invite/INITIATE_INVITE_PAYMENT";
export const EXECUTE_INVITE_PAYMENT = "@@payment_invite/EXECUTE_INVITE_PAYMENT";
export const DOWNLOAD_PAYMENT_INVITES = "@@payment_invite/DOWNLOAD_PAYMENT_INVITES";
export const DOWNLOAD_PAYMENT_INVITES_SUCCESS = "@@payment_invite/DOWNLOAD_PAYMENT_INVITES_SUCCESS";
export const DOWNLOAD_PAYMENT_INVITES_FAILURE = "@@payment_invite/DOWNLOAD_PAYMENT_INVITES_FAILURE";
export const GET_PAYMENT_INVITE_REQUEST = "@@payment_invite/GET_PAYMENT_INVITE_REQUEST";
export const GET_PAYMENT_INVITE_SUCCESS = "@@payment_invite/GET_PAYMENT_INVITE_SUCCESS";
export const GET_PAYMENT_INVITE_FAILURE = "@@payment_invite/GET_PAYMENT_INVITE_FAILURE";
export const PAYMENT_INVITE_REJECTED = "@@payment_invite/PAYMENT_INVITE_REJECTED";
export const DOWNLOAD_PAYMENT_INVITE_INVOICE = "@@payment_invite/DOWNLOAD_PAYMENT_INVITE_INVOICE";
export const SEND_PAYMENT_INVITE_REMINDER = "@@payment_invite/SEND_PAYMENT_INVITE_REMINDER";
export const MARK_PAYMENT_INVITE_IN_TRANSIT = "@@payment_invite/MARK_PAYMENT_INVITE_IN_TRANSIT";
export const UPDATE_PAYMENT_INVITE_EMAIL = "@@payment_invite/UPDATE_PAYMENT_INVITE_EMAIL";
export const MARK_PAYMENT_INVITE_DISPUTED = "@@payment_invite/MARK_PAYMENT_INVITE_DISPUTED";
// export const MARK_PAYMENT_INVITE_DISPUTED_SUCCESS = "@@payment_invite/MARK_PAYMENT_INVITE_DISPUTED_SUCCESS";
export const MARK_PAYMENT_INVITE_DELIVERED = "@@payment_invite/MARK_PAYMENT_INVITE_DELIVERED";
// export const MARK_PAYMENT_INVITE_DELIVERED_SUCCESS = "@@payment_invite/MARK_PAYMENT_INVITE_DELIVERED_SUCCESS";
export const UPDATE_PAYMENT_INVITE_REQUEST = "@@payment_invite/UPDATE_PAYMENT_INVITE_REQUEST";
export const UPDATE_PAYMENT_INVITE_SUCCESS = "@@payment_invite/UPDATE_PAYMENT_INVITE_SUCCESS";
export const UPDATE_PAYMENT_INVITE_FAILURE = "@@payment_invite/UPDATE_PAYMENT_INVITE_FAILURE";

//PAYMENT INVITE ARTWORKS
export const GET_PAYMENT_INVITE_ARTWORKS = "@@payment_invite_artworks/GET_PAYMENT_INVITE_ARTWORKS";
export const GET_PAYMENT_INVITE_ARTWORKS_SUCCESS = "@@payment_invite_artworks/GET_PAYMENT_INVITE_ARTWORKS_SUCCESS";
export const GET_PAYMENT_INVITE_ARTWORKS_FAILURE = "@@payment_invite_artworks/GET_PAYMENT_INVITE_ARTWORKS_FAILURE";

//MARKETPLACE
export const CREATE_ONBOARDING_LAYOUT_REQUEST = "@@marketplace/CREATE_ONBOARDING_LAYOUT_REQUEST";
export const CREATE_ONBOARDING_LAYOUT_SUCCESS = "@@marketplace/CREATE_ONBOARDING_LAYOUT_SUCCESS";
export const CREATE_ONBOARDING_LAYOUT_FAILURE = "@@marketplace/CREATE_ONBOARDING_LAYOUT_FAILURE";
export const CREATE_MARKETPLACE_ACCOUNT_REQUEST = "@@marketplace/CREATE_MARKETPLACE_ACCOUNT_REQUEST";
export const CREATE_MARKETPLACE_ACCOUNT_SUCCESS = "@@marketplace/CREATE_MARKETPLACE_ACCOUNT_SUCCESS";
export const CREATE_MARKETPLACE_ACCOUNT_FAILURE = "@@marketplace/CREATE_MARKETPLACE_ACCOUNT_FAILURE";
export const UPDATE_CARD_ACCOUNT_REQUEST = "@@marketplace/UPDATE_CARD_ACCOUNT_REQUEST";
export const UPDATE_CARD_ACCOUNT_SUCCESS = "@@marketplace/UPDATE_CARD_ACCOUNT_SUCCESS";
export const UPDATE_CARD_ACCOUNT_FAILURE = "@@marketplace/UPDATE_CARD_ACCOUNT_FAILURE";

//LISTS
export const CREATE_LIST_REQUEST = "@@list/CREATE_LIST_REQUEST";
export const CREATE_LIST_LOCALLY = "@@list/CREATE_LIST_LOCALLY";
export const CREATE_LIST_SUCCESS = "@@list/CREATE_LIST_SUCCESS";
export const CREATE_LIST_FAILURE = "@@list/CREATE_LIST_FAILURE";
export const GET_LISTS_REQUEST = "@@list/GET_LISTS_REQUEST";
export const GET_LISTS_SUCCESS = "@@list/GET_LISTS_SUCCESS";
export const EDIT_LIST_REQUEST = "@@list/EDIT_LIST_REQUEST";
export const EDIT_LIST_LOCALLY = "@@list/EDIT_LIST_LOCALLY";
export const EDIT_LIST_SUCCESS = "@@list/EDIT_LIST_SUCCESS";
export const EDIT_LIST_FAILURE = "@@list/EDIT_LIST_FAILURE";
export const DELETE_LISTS = "@@list/DELETE_LISTS";
export const DELETE_LIST_REQUEST = "@@list/DELETE_LIST_REQUEST";
export const DELETE_LIST_SUCCESS = "@@list/DELETE_LIST_SUCCESS";
export const DELETE_LIST_FAILURE = "@@list/DELETE_LIST_FAILURE";
export const DOWNLOAD_LIST_PDF = "@@list/DOWNLOAD_LIST_PDF";

//VIEWING ROOMS
export const CREATE_VIEWING_ROOM = "@@viewing_room/CREATE_VIEWING_ROOM";
export const CREATE_VIEWING_ROOM_LOCALLY = "@@viewing_room/CREATE_VIEWING_ROOM_LOCALLY";
export const CREATE_VIEWING_ROOM_SUCCESS = "@@viewing_room/CREATE_VIEWING_ROOM_SUCCESS";
export const CREATE_VIEWING_ROOM_FAILURE = "@@viewing_room/CREATE_VIEWING_ROOM_FAILURE";
export const GET_VIEWING_ROOMS_REQUEST = "@@viewing_room/GET_VIEWING_ROOMS_REQUEST";
export const GET_VIEWING_ROOMS_SUCCESS = "@@viewing_room/GET_VIEWING_ROOMS_SUCCESS";
export const CHECK_VIEWING_ROOM_FOR_PASSWORD = "@@viewing_room/CHECK_VIEWING_ROOM_FOR_PASSWORD";
export const CHECK_VIEWING_ROOM_FOR_PASSWORD_SUCCESS = "@@viewing_room/CHECK_VIEWING_ROOM_FOR_PASSWORD_SUCCESS";
export const GET_VIEWING_ROOM_REQUEST = "@@viewing_room/GET_VIEWING_ROOM_REQUEST";
export const GET_VIEWING_ROOM_SUCCESS = "@@viewing_room/GET_VIEWING_ROOM_SUCCESS";
export const GET_VIEWING_ROOM_FAILURE = "@@viewing_room/GET_VIEWING_ROOM_FAILURE";
export const EDIT_VIEWING_ROOM_REQUEST = "@@viewing_room/EDIT_VIEWING_ROOM_REQUEST";
export const EDIT_VIEWING_ROOM_LOCALLY = "@@viewing_room/EDIT_VIEWING_ROOM_LOCALLY";
export const EDIT_VIEWING_ROOM_SUCCESS = "@@viewing_room/EDIT_VIEWING_ROOM_SUCCESS";
export const EDIT_VIEWING_ROOM_FAILURE = "@@viewing_room/EDIT_VIEWING_ROOM_FAILURE";
export const DELETE_VIEWING_ROOMS = "@@viewing_room/DELETE_VIEWING_ROOMS";
export const DELETE_VIEWING_ROOM_REQUEST = "@@viewing_room/DELETE_VIEWING_ROOM_REQUEST";
export const DELETE_VIEWING_ROOM_SUCCESS = "@@viewing_room/DELETE_VIEWING_ROOM_SUCCESS";
export const DELETE_VIEWING_ROOM_FAILURE = "@@viewing_room/DELETE_VIEWING_ROOM_FAILURE";
export const SAVE_VIEWING_ROOM_DRAFT = "@@viewing_room/SAVE_VIEWING_ROOM_DRAFT";
export const SAVE_VIEWING_ROOM_DRAFT_LOCALLY = "@@viewing_room/SAVE_VIEWING_ROOM_DRAFT_LOCALLY";
export const SAVE_VIEWING_ROOM_DRAFT_SUCCESS = "@@viewing_room/SAVE_VIEWING_ROOM_DRAFT_SUCCESS";
export const SAVE_VIEWING_ROOM_DRAFT_FAILURE = "@@viewing_room/SAVE_VIEWING_ROOM_DRAFT_FAILURE";

// DISCOVERY
export const CREATE_SESSION_DISCOVERY = "@@discovery/CREATE_SESSION_DISCOVERY";
export const CREATE_SESSION_DISCOVERY_SUCCESS = "@@discovery/CREATE_SESSION_DISCOVERY_SUCCESS";
export const GET_DISCOVERY_LOAD_REQUEST = "@@discovery/GET_DISCOVERY_LOAD_REQUEST";
export const GET_DISCOVERY_LOAD_SUCCESS = "@@discovery/GET_DISCOVERY_LOAD_SUCCESS";
export const GET_DISCOVERY_LOAD_FAILURE = "@@discovery/GET_DISCOVERY_LOAD_FAILURE";
export const GET_DISCOVERY_EVENTS_REQUEST = "@@discovery/GET_DISCOVERY_EVENTS_REQUEST";
export const GET_DISCOVERY_EVENTS_SUCCESS = "@@discovery/GET_DISCOVERY_EVENTS_SUCCESS";
export const GET_DISCOVERY_EVENTS_FAILURE = "@@discovery/GET_DISCOVERY_EVENTS_FAILURE";
export const UPDATE_DISCOVERY_FILTERS = "@@discovery/UPDATE_DISCOVERY_FILTERS";
export const GET_DISCOVERY_FAVOURITES_REQUEST = "@@discovery/GET_DISCOVERY_FAVOURITES_REQUEST";
export const GET_DISCOVERY_FAVOURITES_SUCCESS = "@@discovery/GET_DISCOVERY_FAVOURITES_SUCCESS";
export const GET_DISCOVERY_FAVOURITES_FAILURE = "@@discovery/GET_DISCOVERY_FAVOURITES_FAILURE";

export const UPDATE_INTERACTION_DISCOVERY = "@@discovery/UPDATE_INTERACTION_DISCOVERY";

//SELECTION
export const ADD_SELECTED_ARTWORK = "@@selection/ADD_SELECTED_ARTWORK";
export const REMOVE_SELECTED_ARTWORK = "@@selection/REMOVE_SELECTED_ARTWORK";
export const REPLACE_SELECTED_ARTWORK = "@@selection/REPLACE_SELECTED_ARTWORK";
export const CHANGE_SELECTED_COLLECTION = "@@selection/CHANGE_SELECTED_COLLECTION";
export const CHANGE_SELECTED_CATEGORY = "@@selection/CHANGE_SELECTED_CATEGORY";
export const SELECT_ALL_ARTWORKS = "@@selection/SELECT_ALL_ARTWORKS";
export const CLEAR_ALL_SELECTED_ARTWORKS = "@@selection/CLEAR_ALL_SELECTED_ARTWORKS";
export const ADD_SELECTED_CONTACT = "@@selection/ADD_SELECTED_CONTACT";
export const REMOVE_SELECTED_CONTACT = "@@selection/REMOVE_SELECTED_CONTACT";
export const REPLACE_SELECTED_CONTACT = "@@selection/REPLACE_SELECTED_CONTACT";
export const SELECT_ALL_CONTACTS = "@@selection/SELECT_ALL_CONTACTS";
export const CLEAR_ALL_SELECTED_CONTACTS = "@@selection/CLEAR_ALL_SELECTED_CONTACTS";
export const ADD_SELECTED_ARTIST = "@@selection/ADD_SELECTED_ARTIST";
export const REMOVE_SELECTED_ARTIST = "@@selection/REMOVE_SELECTED_ARTIST";
export const REPLACE_SELECTED_ARTIST = "@@selection/REPLACE_SELECTED_ARTIST";
export const SELECT_ALL_ARTISTS = "@@selection/SELECT_ALL_ARTISTS";
export const CLEAR_ALL_SELECTED_ARTISTS = "@@selection/CLEAR_ALL_SELECTED_ARTISTS";
export const ADD_SELECTED_PAYMENT_INVITE = "@@selection/ADD_SELECTED_PAYMENT_INVITE";
export const CLEAR_ALL_SELECTED_PAYMENT_INVITES = "@@selection/CLEAR_ALL_SELECTED_PAYMENT_INVITES";
export const ADD_SELECTED_LIST = "@@selection/ADD_SELECTED_LIST";
export const REMOVE_SELECTED_LIST = "@@selection/REMOVE_SELECTED_LIST";
export const REPLACE_SELECTED_LIST = "@@selection/REPLACE_SELECTED_LIST";
export const SELECT_ALL_LISTS = "@@selection/SELECT_ALL_LISTS";
export const CLEAR_ALL_SELECTED_LISTS = "@@selection/CLEAR_ALL_SELECTED_LISTS";
export const ADD_SELECTED_VIEWING_ROOM = "@@selection/ADD_SELECTED_VIEWING_ROOM";
export const REMOVE_SELECTED_VIEWING_ROOM = "@@selection/REMOVE_SELECTED_VIEWING_ROOM";
export const REPLACE_SELECTED_VIEWING_ROOM = "@@selection/REPLACE_SELECTED_VIEWING_ROOM";
export const SELECT_ALL_VIEWING_ROOMS = "@@selection/SELECT_ALL_VIEWING_ROOMS";
export const CLEAR_ALL_SELECTED_VIEWING_ROOMS = "@@selection/CLEAR_ALL_SELECTED_VIEWING_ROOMS";

//SEARCH
export const UPDATE_SEARCH_TERM = "@@search/UPDATE_SEARCH_TERM";
export const UPDATE_SEARCH_TERM_CONTACT = "@@search/UPDATE_SEARCH_TERM_CONTACT";
export const UPDATE_SORT_BY_OPTION = "@@search/UPDATE_SORT_BY_OPTION";
export const UPDATE_SEARCH_TERM_ARTIST = "@@search/UPDATE_SEARCH_TERM_ARTIST";
export const UPDATE_SEARCH_TERM_LIST = "@@search/UPDATE_SEARCH_TERM_LIST";
export const UPDATE_SEARCH_TERM_VIEWING_ROOM = "@@search/UPDATE_SEARCH_TERM_VIEWING_ROOM";

//QUEUE
export const INITIALISE_QUEUE_SYSTEM = "@@queue/INITIALISE_QUEUE_SYSTEM";
export const ADD_ACTION_TO_QUEUE = "@@queue/ADD_ACTION_TO_QUEUE";
export const REMOVE_ACTION_FROM_QUEUE = "@@queue/REMOVE_ACTION_FROM_QUEUE";
export const ADD_ACTION_TO_DELAY_QUEUE = "@@queue/ADD_ACTION_TO_DELAY_QUEUE";
export const REMOVE_ACTION_FROM_DELAY_QUEUE = "@@queue/REMOVE_ACTION_FROM_DELAY_QUEUE";
export const EXECUTE_ACTION_IN_QUEUE = "@@queue/EXECUTE_ACTION_IN_QUEUE";
export const EXECUTE_ACTION_IN_QUEUE_SUCCESS = "@@queue/EXECUTE_ACTION_IN_QUEUE_SUCCESS";
export const EXECUTE_ACTION_IN_QUEUE_FAILURE = "@@queue/EXECUTE_ACTION_IN_QUEUE_FAILURE";
export const EXECUTE_ACTION_IN_QUEUE_FINISHED = "@@queue/EXECUTE_ACTION_IN_QUEUE_FINISHED";
export const ADD_FAILED_ACTIONS_TO_QUEUE = "@@queue/ADD_FAILED_ACTIONS_TO_QUEUE";
export const REMOVE_FAILED_ACTION_FROM_QUEUE = "@@queue/REMOVE_FAILED_ACTION_FROM_QUEUE";
export const INITIALISE_QUEUE_SYSTEM_CLEANER = "@@queue/INITIALISE_QUEUE_SYSTEM_CLEANER";
export const START_QUEUE_SYSTEM_CLEANER = "@@queue/START_QUEUE_SYSTEM_CLEANER";
export const STOP_QUEUE_SYSTEM_CLEANER = "@@queue/STOP_QUEUE_SYSTEM_CLEANER";
export const QUEUE_CLEANED_SUCCESS = "@@queue/QUEUE_CLEANED_SUCCESS";
export const QUEUE_CLEANED_FAILURE = "@@queue/QUEUE_CLEANED_FAILURE";

//SYNC
export const INITIALISE_SYNC_SYSTEM = "@@sync/INITIALISE_SYNC_SYSTEM";
export const SYNC_STARTED = "@@sync/SYNC_STARTED";
export const SYNC_SUCCESS = "@@sync/SYNC_SUCCESS";
export const SYNC_FAILURE = "@@sync/SYNC_FAILURE";
export const START_BACKGROUND_SYNC = "@@sync/START_BACKGROUND_SYNC";
export const STOP_BACKGROUND_SYNC = "@@sync/STOP_BACKGROUND_SYNC";
export const RESET_SYNC_TIMER = "@@sync/RESET_SYNC_TIMER";
export const INCREASE_SYNC_COUNT = "@@sync/INCREASE_SYNC_COUNT";

//UI
export const OPEN_MODAL = "@@ui/OPEN_MODAL";
export const CLOSE_MODAL = "@@ui/CLOSE_MODAL";
export const EXPORT_ARTWORKS = "@@ui/EXPORT_ARTWORKS";
export const EXPORT_COMPLETE = "@@ui/EXPORT_COMPLETE";
export const ACCOUNT_DETAILS_LOADING = "@@ui/ACCOUNT_DETAILS_LOADING";
export const ACCOUNT_DETAILS_LOADED = "@@ui/ACCOUNT_DETAILS_LOADED";
export const UNSEEN_SHARE_RECORDS = "@@ui/UNSEEN_SHARE_RECORDS";
export const SEEN_ALL_SHARE_RECORDS = "@@ui/SEEN_ALL_SHARE_RECORDS";
export const CHECK_FOR_REMOTE_ALERTS = "@@ui/CHECK_FOR_REMOTE_ALERTS";
export const SHOW_REMOTE_ALERT = "@@ui/SHOW_REMOTE_ALERT";
export const OPEN_DRAWER = "@@ui/OPEN_DRAWER";
export const CLOSE_DRAWER = "@@ui/CLOSE_DRAWER";

//SESSION
export const CACHE_IMAGE = "@@session/CACHE_IMAGE";
export const RECACHE_IMAGE = "@@session/RECACHE_IMAGE";
export const REMOVE_CACHE_IMAGE = "@@session/REMOVE_CACHE_IMAGE";
export const CACHE_IMAGE_ACTIVE = "@@session/CACHE_IMAGE_ACTIVE";
export const CLEAR_UNACTIVE_CACHE_IMAGES = "@@session/CLEAR_UNACTIVE_CACHE_IMAGES";

//TUTORIAL
export const EXECUTE_JOYRIDE = "@@tutorial/EXECUTE_JOYRIDE";
export const EXECUTE_TUTORIAL = "@@tutorial/EXECUTE_TUTORIAL";
export const NEW_TUTORIAL_STEP = "@@tutorial/NEW_TUTORIAL_STEP";
export const TUTORIAL_COMPLETED = "@@tutorial/TUTORIAL_COMPLETED";

//COMMS
export const QR_SOURCE = "@@comms/QR_SOURCE";
export const SEND_EVENT = "@@comms/SEND_EVENT";
