import {
  GET_VIEWING_ROOMS_REQUEST,
  CHECK_VIEWING_ROOM_FOR_PASSWORD,
  GET_VIEWING_ROOM_REQUEST,
  EDIT_VIEWING_ROOM_REQUEST,
  DELETE_VIEWING_ROOMS,
} from "../actions/types";
import {
  createViewingRoomLocally,
  createViewingRoomSuccess,
  createViewingRoomFailure,
  addSelectedViewingRoom,
  getViewingRoomsSuccess,
  getViewingRoomsFailure,
  getViewingRoomRequest,
  getViewingRoomSuccess,
  getViewingRoomFailure,
  checkViewingRoomForPasswordSuccess,
  editViewingRoomLocally,
  editViewingRoomSuccess,
  editViewingRoomFailure,
  deleteViewingRoom,
  deleteViewingRoomFailure,
  deleteViewingRoomSuccess,
  addActionToQueue,
  saveViewingRoomDraftLocally,
  saveViewingRoomDraftSuccess,
  saveViewingRoomDraftFailure,
  openModal,
  closeModal,
} from "../actions";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { SimplifyApi, createToast } from "../../utils";
import { selectViewingRoomById } from "../selectors";
import { Notifications } from "../../localisation";
import { replace } from "connected-react-router";

//SERVICES
const createViewingRoomService = (artworks, viewingRoom) => {
  return SimplifyApi.post("/v1/viewing_rooms", { ...viewingRoom });
};

const getViewingRoomsService = () => {
  return SimplifyApi.get("/v1/viewing_rooms");
};

const checkViewingRoomPasswordService = (viewingRoomId) => SimplifyApi.get(`/v1/viewing_rooms_check/${viewingRoomId}`);

const incrementViewCountService = (viewingRoomId) => SimplifyApi.get(`/v1/viewing_rooms_visit/${viewingRoomId}`);

const getViewingRoomService = (viewingRoomId, password) =>
  SimplifyApi.post(`/v1/viewing_rooms/${viewingRoomId}`, password);

const editViewingRoomService = (viewingRoom) => SimplifyApi.patch("/v1/viewing_rooms", { ...viewingRoom });

const deleteViewingRoomService = (viewingRoomId) => {
  return SimplifyApi.delete(`/v1/viewing_rooms/${viewingRoomId}`);
};

//SAGAS
export function* createViewingRoomSaga(payload) {
  try {
    let viewingRoom;
    viewingRoom = yield select((state) => selectViewingRoomById(state, payload.tempViewingRoomId));

    if (!viewingRoom) {
      yield put(createViewingRoomLocally(payload));

      yield put(addSelectedViewingRoom(payload.tempViewingRoomId));
      viewingRoom = yield select((state) => selectViewingRoomById(state, payload.tempViewingRoomId));
    }

    if (!viewingRoom) {
      yield put(createViewingRoomFailure());
    }

    const response = yield call(createViewingRoomService, payload.artworks, viewingRoom);
    yield put(createViewingRoomSuccess(payload.tempViewingRoomId, response.data));
    yield put(replace("/viewingrooms/link", { viewingRoomId: response.data._id }));
  } catch (error) {
    yield put(createViewingRoomFailure());
    throw error;
  }
}

function* getViewingRoomsSaga() {
  try {
    const response = yield call(getViewingRoomsService);
    yield put(getViewingRoomsSuccess(response.data.viewingRooms));
  } catch (error) {
    yield put(getViewingRoomsFailure());
    throw error;
  }
}

export function* getViewingRoomSaga(action) {
  const { viewingRoomId, submittedPassword } = action.payload;
  try {
    const response = yield call(getViewingRoomService, viewingRoomId, { password: submittedPassword });
    yield put(getViewingRoomSuccess(response.data));
    yield put(closeModal("viewing_room_password"));
    yield call(incrementViewCountService, viewingRoomId);
  } catch (error) {
    if (error.response.data.includes("incorrect")) {
      yield put(getViewingRoomFailure());
      yield put(openModal("viewing_room_resubmit_password"));
    } else {
      yield put(getViewingRoomFailure());
      yield put(closeModal("viewing_room_password"));
      createToast({ type: "error", ...Notifications.generic });
      throw error;
    }
  }
}

export function* checkViewingRoomForPasswordSaga(action) {
  const viewingRoomId = action.payload;
  try {
    const passwordResponse = yield call(checkViewingRoomPasswordService, viewingRoomId);
    const isPasswordProtectedViewingRoom = passwordResponse.data;
    yield put (checkViewingRoomForPasswordSuccess());
    if (!isPasswordProtectedViewingRoom) {
      yield put(getViewingRoomRequest(viewingRoomId, null))
    } else {
      yield put(openModal("viewing_room_password"));
    }
  } catch (error) {
    createToast({ type: "error", ...Notifications.generic });
    yield put (checkViewingRoomForPasswordSuccess());
    throw error;
  }
}

export function* editViewingRoomSaga(action) {
  try {
    yield put(editViewingRoomLocally(action.payload));

    const viewingRoom = yield select((state) => selectViewingRoomById(state, action.payload._id));

    if (viewingRoom.isFromServer) {
      yield call(editViewingRoomService, viewingRoom);
    }
    yield put(editViewingRoomSuccess(viewingRoom._id));
    if (!viewingRoom.isActive) {
      yield put(replace("/viewingrooms"));
    } else {
      yield put(
        replace("/viewingrooms/link", {
          viewingRoomId: viewingRoom._id,
          actionType: action.type,
          wasViewingRoomDraft: action.payload.wasViewingRoomDraft,
        })
      );
    }
  } catch (error) {
    createToast({ type: "error", ...Notifications.generic });
    yield put(editViewingRoomFailure());
    throw error;
  }
}

function* deleteViewingRoomsSaga(action) {
  const { viewingRoomIds } = action.payload;

  try {
    for (const viewingRoomId of viewingRoomIds) {
      yield put(addActionToQueue(deleteViewingRoom(viewingRoomId), viewingRoomId));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteViewingRoomSaga(payload) {
  const { viewingRoomId } = payload;
  try {
    const viewingRoomForDelete = yield select((state) => selectViewingRoomById(state, viewingRoomId));

    if (viewingRoomForDelete.isFromServer) {
      yield call(deleteViewingRoomService, viewingRoomId);
    }

    yield put(deleteViewingRoomSuccess(viewingRoomId));
  } catch (error) {
    yield put(deleteViewingRoomFailure(viewingRoomId));
    throw error;
  }
}

export function* saveViewingRoomDraftSaga(payload) {
  try {
    let viewingRoom;
    viewingRoom = yield select((state) => selectViewingRoomById(state, payload.tempViewingRoomId));

    if (!viewingRoom) {
      yield put(saveViewingRoomDraftLocally(payload));

      yield put(addSelectedViewingRoom(payload.tempViewingRoomId));
      viewingRoom = yield select((state) => selectViewingRoomById(state, payload.tempViewingRoomId));
    }

    if (!viewingRoom) {
      yield put(saveViewingRoomDraftFailure());
    }

    const response = yield call(createViewingRoomService, payload.artworks, viewingRoom);
    yield put(saveViewingRoomDraftSuccess(payload.tempViewingRoomId, response.data));
    yield put(replace("/viewingrooms"));
  } catch (error) {
    yield put(saveViewingRoomDraftFailure());
    throw error;
  }
}

function* viewingRoomSaga() {
  yield takeEvery(GET_VIEWING_ROOMS_REQUEST, getViewingRoomsSaga);
  yield takeEvery(CHECK_VIEWING_ROOM_FOR_PASSWORD, checkViewingRoomForPasswordSaga);
  yield takeEvery(GET_VIEWING_ROOM_REQUEST, getViewingRoomSaga);
  yield takeEvery(EDIT_VIEWING_ROOM_REQUEST, editViewingRoomSaga);
  yield takeEvery(DELETE_VIEWING_ROOMS, deleteViewingRoomsSaga);
}

export default viewingRoomSaga;
