import produce from "immer";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { GET_PAYMENT_INVITE_ARTWORKS_SUCCESS, SIGN_OUT_SUCCESS } from "../actions/types";

const PAYMENT_INVITE_ARTWORKS_INITIAL_STATE = {
  paymentInviteArtworks: { byId: {} },
};

const paymentInviteArtworksReducer = (state = PAYMENT_INVITE_ARTWORKS_INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case GET_PAYMENT_INVITE_ARTWORKS_SUCCESS:
        return getPaymentInviteArtworks(draftState, action);
      case SIGN_OUT_SUCCESS:
        return PAYMENT_INVITE_ARTWORKS_INITIAL_STATE;
      default:
        return state;
    }
  });
};

const getPaymentInviteArtworks = (draftState, action) => {
  const { paymentInviteId, artworks } = action.payload;
  draftState.paymentInviteArtworks.byId[paymentInviteId] = [];

  artworks.forEach(({ _id, images, artist, ...artwork }) => {
    const imageList = images.map((image) => {
      return { key: uuid(), ...image, isFromServer: true, isModified: false, isActive: true };
    });

    draftState.paymentInviteArtworks.byId[paymentInviteId].push({
        ...artwork,
        _id,
        artist: artist.name,
        images: _.sortBy(imageList, ["sortIndex"]),
        isFromServer: true,
        isModified: false,
        isActive: true,
      })
  });
};

export default paymentInviteArtworksReducer;
