import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ArrowLeft, RotateCcw, Trash2, UploadCloud, Download } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { ButtonLabels, DynamicLabels, Headings, UiLabels, Warnings } from "../../localisation";
import {
  editListRequest,
  openModal,
  deleteLists,
  removeSelectedArtwork,
  downloadListPdf,
  clearAllSelectedArtworks,
  addSelectedArtwork,
} from "../../store/actions";
import { WithRequireAccess } from "../hocs";
import { NavigationBar } from "../react-material/NavigationBar/NavigationBar";
import {
  selectSelectedListIds,
  selectListById,
  selectUser,
  selectSelectedArtworkIds,
  selectArtworksById,
} from "../../store/selectors";
import { DeleteModal, TwoActionModal, AdditionalArtworksModal } from "../modals";
import { ImageWithCache, Box as StyledBox, Flex, Text, Icon } from "../fundamentals";
import { Button as StyledButton, Modal } from "../elements";
import { ListExportForm } from "../react-material/forms/ListExportForm";
import _ from "lodash";
import { FloatingActionPill } from "../elements";

const buttonStyle = {
  color: "white",
  fontFamily: "Inter",
  fontSize: "16px",
  lineHeight: "16px",
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.7,
  },
  "&:active": {
    opacity: 0.5,
  },
};
//   const isArtworkSelectedToRemove = _.includes(artworkIdsToRemove, artwork.id);
//   const [pillIcon, setPillIcon] = useState(isArtworkSelectedToRemove ? "Check" : "Circle");
//   const { images = [] } = artwork;
//   const [firstImage = {}] = images;

//   const handleArtworkOnClick = () => {
//     if (artworkIdsToRemove.length === 0) {
//       setPillIcon("X");
//       setArtworkIdsToRemove((prevArray) => [...prevArray, artwork.id]);
//     }
//   };
//   const handleArtworkSelect = (e) => {
//     e.stopPropagation();

//     if (isArtworkSelectedToRemove) {
//       setArtworkIdsToRemove((prevArray) => prevArray.filter((artworkId) => artworkId !== artwork.id));
//     } else {
//       setPillIcon("X");
//       setArtworkIdsToRemove((prevArray) => [...prevArray, artwork.id]);
//     }
//   };

//   useEffect(() => {
//     if (isArtworkSelectedToRemove && pillIcon === "Circle") setPillIcon("Check");
//     if (!isArtworkSelectedToRemove) setPillIcon("Circle");
//   }, [isArtworkSelectedToRemove, pillIcon]);

//   return (
//     <Flex
//       selected={isArtworkSelectedToRemove}
//       onMouseEnter={() => isArtworkSelectedToRemove && setPillIcon("X")}
//       onMouseLeave={() => isArtworkSelectedToRemove && setPillIcon("Check")}
//       position="relative"
//       flexDirection="column"
//       justifyContent="end"
//       width="175px"
//       minHeight="155px"
//       padding="s"
//       margin="xxxs"
//       marginRight="xs"
//       marginBottom={index !== selectedArtworks.length - 1 && "xxl"}
//       boxShadow="small"
//       borderRadius="2px"
//       onClick={handleArtworkOnClick}
//     >
//       {artwork.images.length ? (
//         <ImageWithCache
//           image={firstImage}
//           artworkId={artwork.id}
//           urlSuffix="/preview.jpg"
//           width="100%"
//           userSelect="none"
//           mb="s"
//           imageKey={artwork.id}
//         />
//       ) : (
//         <Box height="100px">
//           <Text color="grey.60" fontSize="smaller" mt="xl">
//             No image available
//           </Text>
//         </Box>
//       )}
//       <Text mb="xxxs">{artwork.artist}</Text>
//       <Flex>
//         <Text
//           fontSize="small"
//           color="grey.80"
//           whiteSpace="nowrap"
//           overflow="hidden"
//           textOverflow="ellipsis"
//           maxWidth={artwork.artworkValues.year ? "11ch" : "16ch"}
//         >
//           {artwork.artworkValues.title}
//         </Text>
//         {artwork.artworkValues.year && (
//           <Text fontSize="small" color="grey.80">
//             {`, ${artwork.artworkValues.year}`}
//           </Text>
//         )}
//       </Flex>
//       {selectedArtworks.length > 1 && (
//         <Flex flexDirection="column" position="absolute" top="xxs" right="xxs" pointerEvents="none">
//           <StateButton
//             buttonStyle={"primary"}
//             size="small"
//             selected={artworkIdsToRemove.length > 0}
//             icon={pillIcon}
//             iconStrokeWidth="3px"
//             inverted={!isArtworkSelectedToRemove}
//             onClick={handleArtworkSelect}
//             mb="xxs"
//           >
//             {isArtworkSelectedToRemove ? pillIcon === "X" && ButtonLabels.clear : ButtonLabels.select}
//           </StateButton>
//         </Flex>
//       )}
//     </Flex>
//   );
// };

const EditListScreen = () => {
  const [artworksChanged, setArtworksChanged] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const submitRef = useRef(null);
  const user = useSelector(selectUser);
  const state = useSelector((state) => state);
  const selectedListId = useSelector(selectSelectedListIds)[0];
  const list = useSelector((state) => selectListById(state, selectedListId));
  const selectedArtworkIds = useSelector(selectSelectedArtworkIds);
  const selectedArtworks = useSelector((state) => selectArtworksById(state, selectedArtworkIds));

  const submit = (values, event) => {
    event.preventDefault();
    if (state.ui.modal === "save_list") {
      handleSaveAndExport(values);
      setArtworksChanged(false);
    } else {
      setArtworksChanged(false);
      return dispatch(
        editListRequest({ id: list._id, selectedArtworks, isDownload: false, isNewList: false, ...values })
      );
    }
  };

  const defaultValues = {
    title: list?.title,
    ...list?.values,
  };

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors, isSubmitting, isDirty, isSubmitted, isValid },
  } = useForm({ defaultValues: { ...defaultValues } });

  const handleExportPdfClick = () => {
    if (isDirty || artworksChanged) {
      dispatch(openModal("save_list"));
    } else {
      dispatch(downloadListPdf(list));
    }
  };

  const handleSaveAndExport = (values) => {
    return dispatch(editListRequest({ id: list._id, selectedArtworks, isDownload: true, isNewList: false, ...values }));
  };

  const resetChanges = () => {
    const initialListArtworksIds = list?.artworks.map((artwork) => artwork.id);
    reset();
    dispatch(clearAllSelectedArtworks());
    initialListArtworksIds.map((id) => {
      dispatch(addSelectedArtwork(id));
    });
  };

  const renderFloatingActionPillButtons = (dispatch) => {
    return [
      <Button
        type="submit"
        disabled={isSubmitting || !selectedArtworkIds.length || (!isDirty && !artworksChanged)}
        variant="text"
        startIcon={<UploadCloud size="20px" />}
        sx={{ ...buttonStyle, mr: "18px" }}
        onClick={() => submitRef.current.click()}
      >
        {ButtonLabels.saveList}
      </Button>,

      <Button
        variant="text"
        startIcon={<Download size="20px" />}
        sx={{ ...buttonStyle, mr: "18px" }}
        onClick={handleExportPdfClick}
        disabled={!selectedArtworkIds.length}
      >
        {ButtonLabels.exportPdf}
      </Button>,

      <Button
        onClick={resetChanges}
        variant="text"
        key="reset"
        startIcon={<RotateCcw size="20px" />}
        sx={{ ...buttonStyle, mr: "18px" }}
      >
        {ButtonLabels.reset}
      </Button>,
      <Button
        variant="text"
        startIcon={<Trash2 size="20px" />}
        sx={buttonStyle}
        onClick={() => dispatch(openModal("delete_list"))}
      >
        {ButtonLabels.delete}
      </Button>,
    ];
  };

  const deleteSelectedLists = () => {
    dispatch(deleteLists([selectedListId]));
  };

  const handleRemoveSelectedArtworkClick = (artwork) => {
    dispatch(removeSelectedArtwork(artwork.id));
  };

  useEffect(() => {
    if (isSubmitted) {
      reset(getValues());
    }
  }, [isSubmitted, getValues, reset]);

  useEffect(() => {
    const initialListArtworksIds = list?.artworks.map((artwork) => artwork.id);
    if (
      selectedArtworkIds.every((id) => initialListArtworksIds.includes(id)) &&
      initialListArtworksIds.every((id) => selectedArtworkIds.includes(id))
    ) {
      setArtworksChanged(false);
    } else {
      setArtworksChanged(true);
    }
  }, [selectedArtworkIds]);

  return (
    <Box width="100%">
      <Box maxWidth="98%" margin="0 auto">
        <NavigationBar />
      </Box>
      <Stack direction="row">
        <Stack p="16px 48px" flexGrow={1} minHeight="100vh" bgcolor="white">
          <Button
            onClick={() => history.goBack()}
            sx={{ lineHeight: "16px", fontSize: "14px", pl: "0px", alignSelf: "start" }}
            startIcon={<ArrowLeft style={{ position: "relative", right: "-5px", width: "16px", height: "16px" }} />}
          >
            {ButtonLabels.backToLists}
          </Button>
          <Stack direction="row">
            <Typography variant="h2" sx={{ color: "#6A6870" }}>
              {Headings.lists}
            </Typography>
            <Typography variant="h2" sx={{ color: "#6A6870", m: "0 8px" }}>
              /
            </Typography>
            <Typography variant="h2">{list?.title}</Typography>
          </Stack>
          <Stack mt="16px" mb="48px">
            <Typography sx={{ fontSize: "12px", fontWeight: 500, color: "#95939C" }}>{UiLabels.lastUpdated}</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {moment().format("MMMM Do YYYY, h:mm")} by {user.name}
            </Typography>
            <Flex mt="l" width="100%" justifyContent="center" position="relative">
              <StyledBox
                border="1px solid #C3C2C9"
                minWidth="440px"
                flex="1"
                borderRadius="7px"
                p="m"
                overflowY="auto"
                maxHeight="560px"
                mr="xl"
              >
                <ListExportForm
                  handleSubmit={handleSubmit}
                  watch={watch}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  renderFloatingActionPillButtons={renderFloatingActionPillButtons}
                  onSubmit={submit}
                  submitRef={submitRef} //In order to submit form from modal
                />
              </StyledBox>
              {/* Artworks container */}
              <Flex
                flexDirection="column"
                maxHeight="560px"
                overflow="auto"
                p="l"
                borderRadius="7px"
                border="1px solid #C3C2C9"
                flex="2"
                minWidth="400px"
              >
                <Text size="heading3" mb="l">
                  {Headings.artworks}
                </Text>
                {selectedArtworkIds.length === 0 && (
                  <Text color="red" fontSize="smaller" mb="l">
                    Add Artworks to continue
                  </Text>
                )}
                <Flex flexWrap="wrap">
                  {_.map(selectedArtworks, (artwork, index) => {
                    const { images = [] } = artwork;
                    const [firstImage = {}] = images;
                    return (
                      <Flex
                        justifyContent="space-between"
                        alignItems="start"
                        mb="xxl"
                        key={artwork.id}
                        boxShadow="0px 8px 12px 0px rgba(0, 0, 0, 0.20)"
                        mr={index === selectedArtworks.length - 1 ? null : "s"}
                        borderRadius="2px"
                        position="relative"
                        padding="s"
                      >
                        {/* Image */}
                        <StyledBox>
                          {artwork.images.length ? (
                            <ImageWithCache
                              image={firstImage}
                              artworkId={artwork.id}
                              urlSuffix="/preview.jpg"
                              maxHeight="176px"
                              userSelect="none"
                              mb="s"
                              imageKey={artwork.id}
                            />
                          ) : (
                            <StyledBox pt="l" minWidth="130px" height="176px" mb="s">
                              <Text color="grey.60" fontSize="smaller">
                                No image available
                              </Text>
                            </StyledBox>
                          )}
                          <Text mb="xxxs" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth="13ch">
                            {artwork.artist}
                          </Text>
                          <Flex mb="xxxs">
                            <Text
                              fontSize="small"
                              color="grey.80"
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              maxWidth={artwork.artworkValues.year ? "11ch" : "16ch"}
                            >
                              {artwork.artworkValues.title}
                            </Text>
                            {artwork.artworkValues.year && (
                              <Text fontSize="small" color="grey.80">
                                {`, ${artwork.artworkValues.year}`}
                              </Text>
                            )}
                          </Flex>
                          {selectedArtworks.length > 1 && (
                            <Icon
                              icon="XCircle"
                              backgroundColor="white"
                              color="grey.60"
                              size="14"
                              borderRadius="50%"
                              padding="xxxs"
                              position="absolute"
                              top="10px"
                              right="10px"
                              cursor="pointer"
                              strokeWidth="2.5px"
                              boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.16)"
                              onClick={() => handleRemoveSelectedArtworkClick(artwork)}
                            />
                          )}
                        </StyledBox>
                      </Flex>
                    );
                  })}
                </Flex>
                <StyledButton
                  type="button"
                  buttonStyle="secondary"
                  size="small"
                  icon="Plus"
                  iconStrokeWidth="3px"
                  onClick={() => dispatch(openModal("additional_artworks"))}
                  alignSelf="end"
                >
                  {ButtonLabels.addArtworks}
                </StyledButton>
              </Flex>
              <FloatingActionPill inverted={true} p="s">
                {renderFloatingActionPillButtons(dispatch)}
              </FloatingActionPill>
            </Flex>
          </Stack>
        </Stack>
      </Stack>

      <Modal id="save_list">
        <TwoActionModal
          width="460px"
          title="Would you like to save your changes first?"
          message="You have unsaved changes. If you want to export the latest version, click Save & Export. Otherwise, you can export the old version of this List."
          primaryButtonText="Save & Export"
          primaryAction={() => submitRef.current.click()} //In order to submit form from modal
          secondaryButtonText="Export old version"
          secondaryAction={() => dispatch(downloadListPdf(list))}
        />
      </Modal>

      <Modal id="delete_list" maxWidth="460px">
        <DeleteModal
          headerText={DynamicLabels.popupHeaderForDelete("list")}
          bodyText={Warnings.popupWarning}
          onDelete={deleteSelectedLists}
        />
      </Modal>
      <Modal id="additional_artworks" p="0">
        <AdditionalArtworksModal isList={true} />
      </Modal>
    </Box>
  );
};

export default WithRequireAccess(EditListScreen, 1);
