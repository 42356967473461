import { Text, Box, Link } from "../fundamentals";

export const TermsAndConditions = () => {
  return (
    <Box fontSize="small">
      <Text fontWeight="600" mb="xs">
        1. Preamble
      </Text>
      <Text mb="m">
        Simplify.art has developed a secure online payment and shipment service for the purchase of artworks. Such
        service is based on the process of selling an artwork in person (face-to-face purchase and personal handover of
        an artwork against payment of money). It is intended to give both parties security when purchasing an artwork
        with or without a personal handover (mail order purchase). On the one hand, the seller should be secured that
        the purchaser will pay the purchase price before the artwork is shipped. On the other hand, the purchaser should
        be secured that the seller will ship the artwork after payment of the purchase price or that the purchase price
        is returned to purchaser otherwise.
      </Text>
      <Text fontWeight="600" mb="xs">
        1.1 Definitions
      </Text>
      <Text mb="xs">
        "Account Authorization" means the authorization given by the Seller to Simplify.art to transfer the Purchase
        Price and the Additional Costs, if any, pursuant to section 4.4.1 of these Special Terms and Conditions.
      </Text>
      <Text mb="xs">
        "Additional Costs" means the shipping costs, packaging costs, framing costs, insurance costs or any other costs,
        if applicable, displayed in the Simplify.art Payment Invite to be borne by the Purchaser.
      </Text>
      <Text mb="xs">
        "Bank Account" means an account at a bank that enables day-to-day, cashless payment processing.
      </Text>
      <Text mb="xs">
        "Delivery of Package" means the point in time when (i) the shipment service provider marks the shipment of the
        artwork as "Delivered" and the Purchaser is notified about such delivery or (ii) in case of a Personal Handover
        31 calendar days after the Purchaser has paid the Total Costs and the Purchaser and the Seller are notified
        about the passing of the 31 calendar days on the Simplify.art Platform.
      </Text>
      <Text mb="xs">
        "Payment Invite" means a payment invite in the selling features of the Simplify.art Platform (currently payment
        invite links, viewing rooms and marketplace called "Discovery").
      </Text>
      <Text mb="xs">
        "Personal Handover" means the personal hand over of the artwork offered by the Seller for sale from the Seller
        to the Purchaser.
      </Text>
      <Text mb="xs">
        "Purchase Offer" means the purchase offer with respect to the artwork offered by the Seller for sale.
      </Text>
      <Text mb="xs">
        "Purchase Price" means the price agreed between the Seller and the Purchaser for the artwork purchased.
      </Text>
      <Text mb="xs">
        "Purchase Request" means a non-binding request sent by the Purchaser to the Seller within the Simplify.art
        Payment Invite or outside the Simplify.art Payment Invite by any other means to purchase a specific artwork
        offered by the Seller at a specific price.
      </Text>
      <Text mb="xs">
        "Purchaser" means a User who occurs within the Simplify.art Payment Invite as a possible purchaser of the
        artwork offered by the Seller.
      </Text>
      <Text mb="xs">
        "Seller" means a User who non-bindingly offers an artwork for sale within the Simplify.art Payment Invite and
        who is entitled to create a Stripe Account as defined in the STRIPE Agreements (for instance in Austria only
        businesses (including sole proprietors) and non-profit organisations located in Austria are eligible to apply
        for a Stripe Account and use the Services as defined in the Stripe Services Agreement – Austria).
      </Text>
      <Text mb="xs">
        "Seller Payment Account" means a financial account created by Seller and issued by STRIPE that enables STRIPE to
        collect and remit payment on the Seller's behalf relating to the sale of the Seller's artwork on the
        Simplify.art Platform via the Simplify.art Payment Invite.
      </Text>
      <Text mb="xs">
        "Simplify.art" means simplify.art GmbH, registered with the commercial register of the Commercial Court in
        Vienna under FN 540196 g, as operator of the Simplify.art Platform with the Simplify.art Payment Invite.
      </Text>
      <Text mb="xs">
        "Simplify.art Payment Account" means a financial account created by Simplify.art and issued by STRIPE that
        enables STRIPE to collect and remit payment on Simplify.art's behalf relating to the services provided by
        Simplify.art under the Simplify.art Payment Invite.
      </Text>
      <Text mb="xs">
        "Simplify.art Payment Invite" means the services set forth in these Special Terms and Conditions, which includes
        the services provided by Simplify.art including the payment with Purchaser and Seller protection as well as the
        shipment at the Purchaser's free discretion (in particular see sections 5 to 7 of these Special Terms and
        Conditions).
      </Text>
      <Text mb="xs">
        "Simplify.art Payment Invite Fees" means the cost contribution by the Purchaser in relation to the Purchase
        Price calculated and displayed prior to the conclusion of the contract for the purchase of an artwork.
      </Text>
      <Text mb="xs">
        "Simplify.art Platform" means the platform available under{" "}
        <Link href="https://www.simplify.art/" target="_blank">
          https://www.simplify.art/
        </Link>
        .
      </Text>
      <Text mb="xs">
        "Simplify.art Shipment Partner" means a shipment service provider or a digital shipper which can be selected
        under the Simplify.art Payment Invite. Currently, Convelio SAS based in Paris, France is a digital shipper for
        valuable goods (including, but not limited to, fine-art, antiques, medical equipment) offering instant and
        competitive quotes to all main destinations in Europe and the rest of the world with various options.
      </Text>
      <Text mb="xs">
        "Simplify.art Shipment Partner Ageements" means agreements with a Simplify.art Shipment Partner in connection
        with the Simplify.art Payment Invite, in particular the general terms and conditions of such Simplify.art
        Shipment Partner. With respect to the current digital shipper, Convelio SAS, in particular the general terms and
        conditions{" "}
        <Link href="https://www.simplify.art/" target="_blank">
          https://www.convelio.com/en/terms/
        </Link>{" "}
        shall apply.
      </Text>
      <Text mb="xs">
        "STRIPE" means an online payment service based in Dublin, Ireland. All payments under this Simplify.art Payment
        Invite will be processed by STRIPE.
      </Text>
      <Text mb="xs">
        "STRIPE Agreements" has the meaning set forth in section 1.2.2 of these Special Terms and Conditions below.
      </Text>
      <Text mb="xs">
        "Total Costs" means the Purchase Price, the Simplify.art Payment Invite Fees and the Additional Costs together.
      </Text>
      <Text mb="xs">
        "User" means a user of the Simplify.art Platform who also uses the services of the Simplify.art Payment Invite
        and the services of STRIPE.
      </Text>
      <Text mb="m">"User Account" means an account on the Simplify.art Platform created by a User.</Text>
      <Text fontWeight="600" mb="xs">
        1.2 General Information on the Requirements for the Use of the Simplify.art Payment Invite
      </Text>
      <Text mb="xs">
        <Text fontWeight="500" mr="xxxs">General.</Text>
        These Special Terms and Conditions govern the contractual relationship between Simplify.art and the Users
        (Purchaser and Seller). The contractual relationship includes all content, functions and other services in
        connection with the Simplify.art Payment Invite that Simplify.art makes available on the Simplify.art Platform.
        Individual services, such as in particular the execution of payment by STRIPE and shipping by shipping service
        providers selected by the Purchaser, including a Simplify.art Shipment Partner, may be subject to other and
        supplementary terms and conditions, which must be accepted prior to the use thereof.
      </Text>
      <Text mb="xs">
        <Text fontWeight="500" mr="xxxs">
          Acceptance of Special Terms and Conditions and Creation of Seller Payment Account by Seller.
        </Text>
        In order to use the Simplify.art Payment Invite, the Seller must (i) accept these Special Terms and Conditions
        (for the Simplify.art Payment Invite) and (ii) create and maintain a valid Seller Payment Account with STRIPE.
        Each such Seller Payment Account will be subject to terms and conditions specified in a separate agreement
        between STRIPE and the Seller. The payment processing services are provided by STRIPE and are in particular
        subject to the Stripe Connected Account Agreement (
        <Link href="https://stripe.com/at/legal/connect-account" target="_blank">
          https://stripe.com/at/legal/connect-account
        </Link>
        ), which includes the Stripe Services Agreement (
        <Link href="https://stripe.com/at/legal/ssa" target="_blank">
          https://stripe.com/at/legal/ssa
        </Link>
        ) and the list of Prohibited and Restricted Businesses (
        <Link href="https://stripe.com/at/legal/restricted-businesses" target="_blank">
          https://stripe.com/at/legal/restricted-businesses
        </Link>
        ) (the "STRIPE Agreements").
      </Text>
      <Text mb="xs">
        <Text fontWeight="500" mr="xxxs">Acceptance of STRIPE Agreements.</Text>
        By agreeing to these Special Terms and Conditions (for the Simplify.art Payment Invite) or continuing to offer
        the Seller's artwork for sale via the Simplify.art Payment Invite, the Seller agrees to be bound by the STRIPE
        Agreements, which may be modified by STRIPE from time to time. Simplify.art is not a party to the STRIPE
        Agreements but the Seller and STRIPE as well as any other parties listed in the STRIPE Agreements are parties to
        the STRIPE Agreements. Simplify.art has no obligations, responsibility or liability to the Seller or any other
        party under the STRIPE Agreements. However, if Simplify.art receives notice that the Seller has violated the
        STRIPE Agreements, Simplify.art may suspend, disable or terminate the Seller's ability to offer any artwork for
        sale on the Simplify.art Platform. As a requirement of Simplify.art Platform enabling payment processing
        services via STRIPE, the Seller agrees to provide Simplify.art accurate and complete information about itself
        and its business. The Seller further authorizes Simplify.art to share such information and transaction
        information related to the Seller's use of the payment processing services with STRIPE, tax authorities, and as
        otherwise necessary to provide the Simplify.art Payment Invite. The Seller also authorizes Simplify.art to use
        such information for related purposes, including trust and safety (to prevent fraud, abuse, and other bad actor
        behavior), tax and accounting reporting, and billing. Simplify.art will be responsible for billing the Purchaser
        for the Seller's artwork offered for sale. STRIPE will be responsible for the regulatory requirements and
        operational aspects relating to the collection of fees from the Purchaser and remittance of amounts payable to
        the Seller in accordance with these Special Terms and Conditions. If the Seller does not accept these Special
        Terms and Conditions or the STRIPE Agreements, the Simplify.art Payment Invite cannot be used.
      </Text>
      <Text mb="xs">
        <Text fontWeight="500" mr="xxxs">Acceptance of Special Terms and Conditions by Purchaser.</Text>
        In order to use the Simplify.art Payment Invite, the Purchaser must (i) accept these Special Terms and
        Conditions (for the Simplify.art Payment Invite).
      </Text>
      <Text mb="xs">
        <Text fontWeight="500" mr="xxxs">Shipment via a Simplify.art Shipment Partner.</Text>
        If the Purchaser wishes to use the shipment service via a Simplify.art Shipment Partner, the Purchaser has to
        click the button "Use shipping organised by simplify.art", fill out the information requested by such
        Simplify.art Shipment Partner, in particular dimensions of a package and its weight and agree to the
        Simplify.art Shipment Partner Agreements, which may be modified by the Simplify.art Shipment Partner from time
        to time. Simplify.art is not a party to the Simplify.art Shipment Partner Agreements but the Purchaser and the
        Simplify.art Shipment Partner as well as any other parties listed in the Simplify.art Shipment Partner
        Agreements are parties to the Simplify.art Shipment Partner Agreements. Simplify.art has no obligations,
        responsibility or liability to the Purchaser or any other party under the Simplify.art Shipment Partner
        Agreements. However, if Simplify.art receives notice that the Purchaser has violated the Simplify.art Shipment
        Partner Agreements, Simplify.art may suspend, disable or terminate the Purchaser's ability to receive any
        shipments of any purchased artwork via the Simplify.art Shipment Partner on the Simplify.art Platform. The
        Simplify.art Shipment Partner will be responsible for any regulatory requirements and operational aspects
        relating to the shipment of the purchased artwork in accordance with these Special Terms and Conditions. If the
        Purchaser does not accept the Simplify.art Shipment Partner Agreements, the shipment service via the
        Simplify.art Payment Invite cannot be used.
      </Text>
      <Text mb="xs">
        <Text fontWeight="500" mr="xxxs">Personal Handover.</Text>
        If the Purchaser wishes a Personal Handover, the Purchaser has to click the button "Organise a personal
        handover". In this case it is assumed that the Seller hands over the artwork to the Purchaser within 31 calendar
        days after the Purchaser has paid the Total Costs.
      </Text>
      <Text mb="xs">
        <Text fontWeight="500" mr="xxxs">Other Shipping Service Provider.</Text>
        If the Purchaser wishes to use another suitable shipping service provider, the Purchaser has to click the button
        "Organise your own shipping" and is solely responsible for the selection of such shipping service provider and
        the organisation of the hand over of the purchased artwork by the Seller.
      </Text>
      <Text mb="xs">
        <Text fontWeight="500" mr="xxxs">Purchase Contract of Artwork.</Text>
        The contracting parties of the sale and the purchase of artwork via the Simplify.art Payment Invite are
        exclusively the Seller as provider of the artwork and the Purchaser as interested purchasing party.
      </Text>
      <Text mb="xs">
        <Text fontWeight="500" mr="xxxs">Conclusion of the Purchase Contract of Artwork.</Text>
        If the Purchaser makes a Purchase Request for the Seller's artwork and the Seller accepts such Purchase Request,
        no purchase contract is concluded yet. Upon acceptance of the Purchase Request by the Seller, (i) the Seller has
        to send to the Purchaser via electronic means a Payment Invite or (ii) the Purchaser clicks the button "Buy" on
        the Simplify.art Platform and the status for the artwork displayed on the Simplify.art Platform will be marked
        as "Awaiting Payment". The purchase contract of the Seller's artwork is concluded between Seller and Purchaser
        once the Total Costs are paid by the Purchaser in accordance with the Payment Invite or the button "Buy" on the
        Simplify.art Platform (see explanation of secure payment process in section 4 of these Special Terms and
        Conditions). Thus, as long as the Purchaser has not paid the Total Costs, the Purchaser may withdraw the
        Purchase Request at any time (eg also by non-payment of the Total Costs), even if the Purchase Request has been
        accepted by the Seller and the Seller sent a Payment Invite to the Purchaser. Upon receipt of the Total Costs in
        accordance with the Payment Invite or the button "Buy" on the Simplify.art Platform, the status for the artwork
        displayed in the Simplify.art Payment Invite will be marked as "Paid".
      </Text>
      <Text mb="xs">
        <Text fontWeight="500" mr="xxxs">Payment of the Total Costs.</Text>
        The Seller cannot freely dispose of the Purchase Price and the Additional Costs, if any, immediately, but only
        after the release of the payment of the Purchase Price and (parts of) the Additional Costs from the Seller
        Payment Account (see explanation of secure payment process in section 4 of these Special Terms and Conditions)
        to the Bank Account provided by the Seller to Simplify.art via the Simplify.art Payment Invite. Prerequisite for
        the payout from the Seller Payment Account is the receipt and inspection of the artwork by the Purchaser in
        accordance with the Purchaser and Seller protection provisions (in particular see sections 5 to 7 of these
        Special Terms and Conditions). The Simplify.art Payment Invite Fees will be paid directly to the Simplify.art
        Payment Account and Simplify.art can freely dispose thereof immediately.
      </Text>
      <Text mb="xs">
        <Text fontWeight="500" mr="xxxs">Shipping.</Text>
        After receiving the Purchase Price and the Additional Costs, if any, on the Seller Payment Account, the
        Purchaser shall select and organise a hand over of the purchased artwork by the Seller to either (i) a
        Simplify.art Shipment Partner and the tracking number will be added automatically via the Simplify.art Payment
        Invite or (ii) a suitable shipping service provider and enter a correct tracking number via the Simplify.art
        Payment Invite. Upon entering a tracking number via the Simplify.art Payment Invite, the status for the artwork
        displayed in the Simplify.art Payment Invite will be marked as "In Transit". If the Seller does not hand over
        the purchased artwork to the Simplify.art Shipment Partner or another shipping service provider and no tracking
        number is added via the Simplify.art Payment Invite within 31 calendar days after receipt of the Purchase Price
        and the Additional Costs, if any, on the Seller Payment Account, the Purchase Price and (parts of) the
        Additional Costs, if any, will be transferred back to the Purchaser via the originally used means of payment in
        the Simplify.art Payment Invite but the Simplify.art Payment Invite Fees and (parts of) the Additional Costs, if
        any, will not be refunded to the Purchaser and the contract is cancelled. Upon expiry of the deadline for
        entering a tracking number via the Simplify.art Payment Invite, the status for the artwork displayed in the
        Simplify.art Payment Invite will be marked as "Cancelled". Any further claims between Purchaser and Seller do
        not exist.
      </Text>
      <Text mb="xs">
        <Text fontWeight="500" mr="xxxs">Delivery.</Text>
        After the Delivery of the Package, the status for the artwork displayed in the Simplify.art Payment Invite will
        be marked as "Delivered".
      </Text>
      <Text mb="xs">
        <Text fontWeight="500" mr="xxxs">Dispute.</Text>
        After the Delivery of Package, the Purchaser has 72 hours to file a complaint in the Simplify.art messaging
        system pursuant to section 6 of these Special Terms and Conditions. Upon a filing of such complaint, the status
        for the artwork displayed in the Simplify.art Payment Invite will be marked as "Disputed".
      </Text>
      <Text>
        <Text fontWeight="500" mr="xxxs">Pay-Out.</Text>
        If no complaint is filed in the Simplify.art messaging system pursuant to section 6 of these Special Terms and
        Conditions, the Total Costs shall be paid out pursuant to section 4.3.3 of these Special Terms and Conditions.
        After the pay-out of the Purchase Price and the Additional Costs, if any, the status for the artwork displayed
        in the Simplify.art Payment Invite will be marked as "Paid Out".
      </Text>
    </Box>
  );
};
