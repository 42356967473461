import React, { useState } from "react";
import _ from "lodash";
import { ButtonLabels, InputLabels, Headings, DynamicLabels, InputErrors } from "../../localisation";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, FieldArray, FormSection, change, getFormValues, getFormInitialValues } from "redux-form";
import { Box, Flex, Text, Icon } from "../fundamentals";
import { Button, Input, InputSelect } from "../elements";
import { SUPPORTED_MEASUREMENT_UNITS, SUPPORTED_WEIGHT_UNITS } from "../../config";

const DimensionsInput = ({ meta: { form }, input: { name }, changeValue, defaultUnits }) => {
  const clearDimensions = () => changeValue(form, name, undefined);
  const [selectedUnits, setSelectedUnits] = useState(false);
  return (
    <FormSection name={name}>
      <Flex justifyContent="space-between" mr="xxxs">
        <Box mr="xxxs">
          <Flex>
            <Field
              name="units"
              options={SUPPORTED_MEASUREMENT_UNITS.map((value) => ({ label: value, value }))}
              component={InputSelect}
              isSearchable={false}
              selectIndicatorSize={15}
              placeholder={selectedUnits || defaultUnits}
              additionalOnChange={(value, { prevValue, form }) => {
                setSelectedUnits(value);
                if (prevValue === "") changeValue(form, name, "");
              }}
              minWidth="40%"
            />
            <Field
              name="height"
              minWidth="60%"
              placeholder="Height"
              scrollingRef={{ current: window }}
              options={{ type: "number" }}
              additionalOnChange={(value) => {
                if (!value || isNaN(value)) clearDimensions();
              }}
              hideError
              component={Input}
            />
          </Flex>
        </Box>
        <Box mr="xxxs">
          <Flex>
            <Field
              name="units"
              options={SUPPORTED_MEASUREMENT_UNITS.map((value) => ({ label: value, value }))}
              component={InputSelect}
              isSearchable={false}
              selectIndicatorSize={15}
              placeholder={selectedUnits || defaultUnits}
              additionalOnChange={(value, { prevValue, form }) => {
                setSelectedUnits(value);
                if (prevValue === "") changeValue(form, name, "");
              }}
              minWidth="40%"
            />
            <Field
              name="width"
              minWidth="60%"
              placeholder="Width"
              scrollingRef={{ current: window }}
              options={{ type: "number" }}
              additionalOnChange={(value) => {
                if (!value || isNaN(value)) clearDimensions();
              }}
              hideError
              component={Input}
            />
          </Flex>
        </Box>
        <Box>
          <Flex>
            <Field
              name="units"
              options={SUPPORTED_MEASUREMENT_UNITS.map((value) => ({ label: value, value }))}
              component={InputSelect}
              isSearchable={false}
              selectIndicatorSize={15}
              placeholder={selectedUnits || defaultUnits}
              additionalOnChange={(value, { prevValue, form }) => {
                setSelectedUnits(value);
                if (prevValue === "") changeValue(form, name, "");
              }}
              minWidth="40%"
            />
            <Field
              name="depth"
              minWidth="60%"
              placeholder="Depth"
              scrollingRef={{ current: window }}
              options={{ type: "number" }}
              additionalOnChange={(value) => {
                if (!value || isNaN(value)) clearDimensions();
              }}
              hideError
              component={Input}
            />
          </Flex>
        </Box>
      </Flex>
    </FormSection>
  );
};

const WeightInput = ({ meta: { form }, input: { name }, changeValue, defaultUnits, getInitialUnits, placeholder }) => {
  const clearDimensions = () => changeValue(form, name, undefined);
  const [selectedUnits, setSelectedUnits] = useState(false);

  return (
    <FormSection name={name}>
      <Flex mr="xxxs" width="150px">
        <Field
          name="units"
          options={SUPPORTED_WEIGHT_UNITS.map((value) => ({ label: value, value }))}
          component={InputSelect}
          isSearchable={false}
          selectIndicatorSize={15}
          placeholder={selectedUnits || defaultUnits}
          additionalOnChange={(value, { prevValue, form }) => {
            setSelectedUnits(value);
            if (prevValue === "") changeValue(form, name, "");
          }}
          minWidth="40%"
        />
        <Field
          name="value"
          minWidth="60%"
          placeholder={placeholder}
          scrollingRef={{ current: window }}
          options={{ type: "number" }}
          additionalOnChange={(value) => {
            if (!value || isNaN(value)) clearDimensions();
            else if (value) {
              changeValue(form, name, {
                value: value,
                units: selectedUnits || getInitialUnits(form) || defaultUnits,
              });
            }
          }}
          hideError
          component={Input}
        />
      </Flex>
    </FormSection>
  );
};

function mapDimensionsStateToProps(state, { meta: { form }, input: { name } }) {
  const { user } = state;
  const dimensions = _.get(getFormValues(form)(state), name);

  return {
    dimensions,
    defaultUnits: user.settings && user.settings.defaultDimensionUnits ? user.settings.defaultDimensionUnits : "",
  };
}

function mapWeightStateToProps(state, { meta: { form }, input: { name } }) {
  const { user } = state;
  const weightUnits = _.get(getFormValues(form)(state), name);

  return {
    weightUnits,
    defaultUnits: user.settings && user.settings.defaultWeightUnits ? user.settings.defaultWeightUnits : "",
    getInitialUnits: (form) => {
      const initialValues = getFormInitialValues(form)(state)[name];
      if (initialValues) return initialValues.units;
    },
  };
}

function mapDispatchToProps(dispatch, { form, name }) {
  return bindActionCreators(
    {
      changeValue: change,
    },
    dispatch
  );
}

const ConnectedDimensionsInput = connect(mapDimensionsStateToProps, mapDispatchToProps)(DimensionsInput);
const ConnectedWeightInput = connect(mapWeightStateToProps, mapDispatchToProps)(WeightInput);

const firstRowColumnOptions = [
  {
    name: "dimensions",
    component: ConnectedDimensionsInput,
    label: "Dimensions",
    withPlaceholder: true,
    hideError: true,
    mb: "0",
  },
];

const secondRowColumnOptions = [
  { name: "weight", component: ConnectedWeightInput, label: InputLabels.weight, hideError: true },
  {
    name: "packagingType",
    component: Input,
    label: InputLabels.packagingType,
    hideError: true,
    mr: "xxxs",
    width: "250px",
  },
  {
    name: "openingOrder",
    label: InputLabels.openingOrder,
    pb: "none",
    placeholder: "",
    width: "70px",
    component: InputSelect,
  },
];

const CrateColumn = ({ form, crate, crates, column: { name, label, ...props }, disabled }) => {
  return (
    <Box paddingTop="24px" position="relative">
      <Text
        top="0"
        position="absolute"
        minHeight={label ? "16px" : undefined}
        fontSize="smaller"
        lineHeight="button"
        fontWeight="500"
        color="black"
        mb="xxs"
        whiteSpace="nowrap"
      >
        {label}
      </Text>

      {name === "openingOrder" ? (
        <Field
          name={`${crate}.${name}`}
          disabled={disabled}
          options={crates.map((_, index) => ({ value: index + 1, label: index + 1 }))}
          onChange={(event, newValue, previousValue) => {
            if (previousValue !== newValue) {
              crates.forEach((crate, crateIndex) => {
                const fieldValues = Object.assign({}, crates.get(crateIndex));
                if (fieldValues.openingOrder < previousValue && fieldValues.openingOrder >= newValue) {
                  fieldValues.openingOrder = fieldValues.openingOrder + 1;
                  crates.splice(crateIndex, 1, fieldValues);
                } else if (fieldValues.openingOrder > previousValue && fieldValues.openingOrder <= newValue) {
                  fieldValues.openingOrder = fieldValues.openingOrder - 1;
                  crates.splice(crateIndex, 1, fieldValues);
                }
              });
            }
          }}
          form={form}
          {...props}
        />
      ) : (
        <Field
          name={`${crate}.${name}`}
          disabled={disabled}
          form={form}
          {...props}
        />
      )}
    </Box>
  );
};

const onRemoveRow = (index, fields) => {
  const removedField = fields.get(index);
  fields.forEach((field, fieldIndex) => {
    const fieldValues = Object.assign({}, fields.get(fieldIndex));
    if (removedField.openingOrder < fieldValues.openingOrder) {
      fieldValues.openingOrder = fieldValues.openingOrder - 1;
      fields.splice(fieldIndex, 1, fieldValues);
    }
  });
};

const renderCrates = ({ fields, meta: { error, submitFailed }, sortBy, disabled, form, preventOverlapRef }) => {
  const orderedFields = _.orderBy(
    fields.map((crate, crateIndex) => {
      return { crate, crateIndex, rowValues: fields.get(crateIndex) };
    }),
    `rowValues.${sortBy}`
  );

  return (
    <Box>
      {orderedFields.map(({ crate, crateIndex }) => (
        <Box key={crateIndex} mb="s">
          <Flex justifyContent="space-between">
            {firstRowColumnOptions.map((column, columnIndex) => (
              <CrateColumn
                key={columnIndex}
                crate={crate}
                crates={fields}
                column={column}
                disabled={disabled}
                form={form}
              />
            ))}

            <Button
              buttonStyle="text"
              size="medium"
              color="black"
              icon="X"
              alignSelf="center"
              mt="s"
              pr="none"
              pl="none"
              disabled={disabled}
              onClick={() => {
                onRemoveRow(crateIndex, fields);
                fields.remove(crateIndex);
              }}
            />
          </Flex>
          <Flex>
            {secondRowColumnOptions.map((column, columnIndex) => (
              <CrateColumn
                key={columnIndex}
                crate={crate}
                crates={fields}
                column={column}
                disabled={disabled}
                form={form}
              />
            ))}
          </Flex>
        </Box>
      ))}

      {!disabled && (
        <Button
          buttonStyle="text"
          size="small"
          pl="none"
          icon="Plus"
          preventDefault
          onClick={() => fields.push({ openingOrder: fields.length + 1 })}
        >
          {ButtonLabels.addItem}
        </Button>
      )}
    </Box>
  );
};

const CrateFields = ({ groupName, sortBy, disabled, isMultiEditMode, numArtworks, form }) => {
  return (
    <Box mb="m">
      <Text size="small" color= "grey.80" mb="s">
        {Headings.packaging}
      </Text>
      {isMultiEditMode && (
        <Flex alignItems="center" color={"amber.90"} overflow="hidden" height={24} mb="xxs">
          <Icon icon="Info" size="14" mr="xxxs" />
          <Text fontSize="smaller" lineHeight="button">
            {DynamicLabels.multiPackagingEditWarning(numArtworks)}
          </Text>
        </Flex>
      )}

      <FieldArray
        disabled={disabled}
        rerenderOnEveryChange={true}
        name={groupName}
        sortBy={sortBy}
        component={renderCrates}
        form={form}
      />
    </Box>
  );
};

export { CrateFields };
