import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSearch,
  selectSelectedCollection,
  selectSelectedCollectionArtworksCategories,
  selectUser,
} from "../../store/selectors";
import { useVirtual } from "react-virtual";
import { Box, Flex, Text, Image, Link } from "../fundamentals";
import { Button, Modal } from "../elements";
import {
  AdvancedExportsModal,
  ShareModal,
  UpgradeModal,
  SuccessfulUpgradeModal,
  UnsuccessfulUpgradeModal,
  ContactUsFormModal,
  PaymentInviteModal,
  PaymentInviteConfirmationModal,
  PaymentInviteBuyerModal,
  PaymentInviteBuyerConfirmationModal,
  PaymentInviteRejectionModal,
  AlertModal,
  CsvImportModal,
  TwoActionModal,
} from "../modals";
import { CollectionHeader, ArtworkCategory } from "../modules";
import { WithRequireAccess, StickyObservable, WithItemMeasure } from "../hocs";
import { ButtonLabels, Headings, Messages, Info } from "../../localisation";
import { useHistory, useLocation } from "react-router-dom";
import {
  clearAllSelectedArtworks,
  updateSearchTerm,
  openModal,
  closeModal,
  exportArchiveRequest,
} from "../../store/actions";
import { NavigationBar } from "../react-material/NavigationBar";
import { Helmet } from "react-helmet";
import { reset } from "redux-form";

const ArtworkCategoryWithItemMeasure = WithItemMeasure(ArtworkCategory);

const ArchiveScreen = () => {
  const selectedCollection = useSelector(selectSelectedCollection);
  const categories = useSelector(selectSelectedCollectionArtworksCategories);
  const search = useSelector(selectSearch);
  const dispatch = useDispatch();
  const history = useHistory();
  const isSearchTermEmpty = search.searchTerm !== "";
  const location = useLocation();
  const user = useSelector(selectUser);

  // const state = useSelector(state => state);
  // console.log("STATE", state)

  useEffect(() => {
    dispatch(clearAllSelectedArtworks());
    dispatch(updateSearchTerm(""));
    if (location.pathname === "/upgrade" && user.account.accessLevel === 0) {
      dispatch(openModal("upgrade"));
    }
  }, []);

  const handleAddArtworkButtonClick = () => {
    dispatch(clearAllSelectedArtworks());
    history.push("/artworks/add");
  };

  const renderEmptyArtworkList = () => {
    return (
      <Flex flexDirection="column" alignItems="center" height="100%" width="100%">
        <Image
          src={process.env.PUBLIC_URL + "/images/icons/brand-icons/empty-state-illustration.svg"}
          alt="brand"
          width="130px"
          height="180px"
          mt="xxxl"
        />

        <Text fontFamily="bodyDmSans" size="heading2" mt="m">
          {isSearchTermEmpty ? Messages.noArtWorksFound : Messages.noArtWorks}
        </Text>
        <Text width="300px" height="48px" overflow="hidden" overflowY="hidden" mt="s" textAlign="center">
          {isSearchTermEmpty ? Messages.emptySearch(search.searchTerm) : Messages.addArtWorks}
        </Text>
        <Box mt="m">
          {isSearchTermEmpty ? (
            <Button
              icon="XCircle"
              buttonStyle="secondary"
              size="medium"
              mr="s"
              onClick={() => dispatch(updateSearchTerm(""))}
            >
              {ButtonLabels.clearSearch}
            </Button>
          ) : (
            <Link href="https://www.simplify.art/help" target="_blank" mr="s">
              <Button icon="HelpCircle" buttonStyle="secondary" size="medium">
                {ButtonLabels.help}
              </Button>
            </Link>
          )}
          <Button icon="Plus" buttonStyle="primary" size="medium" onClick={handleAddArtworkButtonClick}>
            {ButtonLabels.addArtwork}
          </Button>
        </Box>
      </Flex>
    );
  };

  return (
    <Flex position="fixed" right="0" left="0" top="0" bottom="0" justifyContent="center" bg="grey.10" zIndex="1">
      {/*App Bar*/}
      <Helmet>
        <title>simplify.art | App for Collectors, Gallerists & Artists</title>
        <meta
          name="description"
          content="The app for managing your collection, gallery or portfolio. We make managing art as effortless as enjoying it"
        />
      </Helmet>
      <Flex flexDirection="column" width="100%" margin="0 auto" position="relative">
        <NavigationBar />

        {selectedCollection && (
          <Text size="heading2" color="black" pb={["xxs", "xs"]} pl="l">
            {selectedCollection.name === "Discovery" ? "Favourites" : selectedCollection.name === "Artworks" ? "Collection" : selectedCollection.name}
          </Text>
        )}
        <StickyObservable options={{ threshold: 1 }}>
          <CollectionHeader initialValues={{ searchTerm: search.searchTerm, sortBy: search.sortBy }} />
        </StickyObservable>

        {/*Artwork List*/}
        {categories.length === 0 && renderEmptyArtworkList()}
        {categories.length !== 0 && <RowVirtualizerDynamic rows={categories} />}
      </Flex>
      <Modal id="advanced_export">
        <AdvancedExportsModal />
      </Modal>
      <Modal id="share">
        <ShareModal />
      </Modal>
      <Modal id="upgrade" p="none">
        <UpgradeModal />
      </Modal>
      <Modal id="upgradeSuccess">
        <SuccessfulUpgradeModal />
      </Modal>
      <Modal id="upgradeFailure">
        <UnsuccessfulUpgradeModal />
      </Modal>
      <Modal id="contactUs">
        <ContactUsFormModal />
      </Modal>
      <Modal id="payment_invite" additionalCloseAction={() => dispatch(reset("payment_invite.create"))}>
        <PaymentInviteModal />
      </Modal>
      <Modal id="payment_invite_confirmation">
        <PaymentInviteConfirmationModal />
      </Modal>
      <Modal id="payment_invite_buyer">
        <PaymentInviteBuyerModal />
      </Modal>
      <Modal id="payment_invite_buyer_confirmation">
        <PaymentInviteBuyerConfirmationModal />
      </Modal>
      <Modal id="payment_invite_buyer_reject">
        <PaymentInviteRejectionModal />
      </Modal>
      <Modal id="id_verification_failed">
        <AlertModal
          title={Headings.somethingWentWork}
          message={Info.idVerificationFailed}
          acceptButtonText={ButtonLabels.close}
          width="500px"
        />
      </Modal>
      <Modal id="invalid_discount_code">
        <AlertModal
          title={Headings.invalidDiscountCode}
          message={Info.discountCodeInvalid}
          acceptButtonText={ButtonLabels.close}
          width="500px"
        />
      </Modal>
      <Modal id="csv_import" width="768px">
        <CsvImportModal />
      </Modal>
      <Modal id="csv_import.success">
        <AlertModal
          title="Your file has been uploaded!"
          message="Your data is being processed and added to your archive. You will receive an email within the next hour once the import is complete with details of any artworks that were not successfully imported."
          acceptButtonText={ButtonLabels.close}
          width="500px"
        />
      </Modal>
      <Modal id="archive_export" width="540px">
        <TwoActionModal
          title="Export your archive"
          message="Export your simplify.art archive as a zip file by clicking the button below. You will receive an email with a link to download your archive once the export is complete."
          primaryButtonText={ButtonLabels.exportArchive}
          primaryAction={() => dispatch(exportArchiveRequest())}
          secondaryButtonText="Cancel"
          secondaryAction={() => dispatch(closeModal())}
        />
      </Modal>
    </Flex>
  );
};

const RowVirtualizerDynamic = ({ rows, listHeight, listWidth, scrollPosition }) => {
  const parentRef = useRef(null);

  const rowVirtualizer = useVirtual({
    size: rows.length,
    parentRef,
    overscan: 5,
  });

  return (
    <div
      ref={parentRef}
      className="List"
      style={{
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.totalSize}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          return (
            <ArtworkCategoryWithItemMeasure
              key={virtualRow.index}
              measure={virtualRow.measureRef}
              category={rows[virtualRow.index]}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                transform: `translateY(${virtualRow.start}px)`,
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WithRequireAccess(ArchiveScreen, 0);
