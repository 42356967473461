import React from "react";
import styled, { withTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Box, Flex, Image } from "../fundamentals";
import { OnboardingSecondForm } from "../forms";
// import { ProgressStepBar } from "../modules";
// import { STEPS_ONBOARDING } from "../../config";
import { useIsScreenMobile } from "../hooks/useMediaQuery";

const StyledOnboardingScreen = styled(Flex)`
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const OnboardingSecondScreen = (props) => {
  const history = useHistory();
  const isMobile = useIsScreenMobile();
  const url_type = useSelector(state => state.user.urlType);
  const prevLocation = props.location.state?.from;
  
// If user has selected All-Access on webflow, 
// take them through the card-details form first (third onboarding form), 
// otherwise take them directly to confirmation form (fourth onboarding form)
  if(url_type == 'monthly' || url_type == 'annually'){
      return (
          <StyledOnboardingScreen bg="white">
              <Image
                  src={
                      process.env.PUBLIC_URL +
                      `/images/brand-identity/${isMobile ? "logo-icon-black.svg" : "logo_horizontal-black.svg"}`
                  }
                  alt="logo"
                  width={isMobile ? "25px" : "184px"}
                  height={isMobile ? "35px" : ""}
                  p={isMobile ? "35px 30px" : "35px 50px"}
              />

              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" pt="l">
                  {/* <ProgressStepBar steps={STEPS_ONBOARDING} activeColor="#5E54FF" color="black" activeIndex={1}/> */}
                  <OnboardingSecondForm
                      onSubmit={() => {
                          history.push("/onboarding/card-details", {from: prevLocation});
                      }}
                  />
              </Box>
          </StyledOnboardingScreen>
      );

  }else {

      return (
          <StyledOnboardingScreen bg="white">
              <Image
                  src={
                      process.env.PUBLIC_URL +
                      `/images/brand-identity/${isMobile ? "logo-icon-black.svg" : "logo_horizontal-black.svg"}`
                  }
                  alt="logo"
                  width={isMobile ? "25px" : "184px"}
                  height={isMobile ? "35px" : ""}
                  p={isMobile ? "35px 30px" : "35px 50px"}
              />

              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" pt="l">
                  {/* <ProgressStepBar steps={STEPS_ONBOARDING} activeColor="#5E54FF" color="black" activeIndex={1}/> */}
                  <OnboardingSecondForm
                      onSubmit={() => {
                          history.push("/onboarding/confirm", {from: prevLocation});
                      }}
                  />
              </Box>
          </StyledOnboardingScreen>
      );
  }
};

export default OnboardingSecondScreen;
