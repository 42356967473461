import _ from "lodash";
import { createSelector } from "reselect";
import { ContactContainsSearchTags } from "../../utils";
import { selectSearch } from "./searchSelectors";

export const selectContacts = (state) => state.contact.contacts.byId;
export const selectContactId = (state, contactId) => contactId;
export const selectContactIds = (state, contactIds) => contactIds;

export const selectContactById = createSelector(selectContacts, selectContactId, (contacts, contactId) => {
  return contacts?.[contactId];
});

export const selectContactsById = createSelector(selectContacts, selectContactIds, (contacts, contactIds) => {
  const selectedContacts = _.map(contactIds, (contactId) => contacts[contactId]);

  return selectedContacts;
});

export const selectAllContactsList = createSelector(selectContacts, selectSearch, (contacts, search) => {
  let contactsList = _.map(contacts, (contact) => {
    return contact;
  });

  return _(contacts)
    .thru((contacts) => {
      if (search.searchTermContact !== "") {
        const searchTags = search.searchTermContact.split(" ");

        const filter = contactsList.filter((contact) => ContactContainsSearchTags(searchTags, contact));
        return filter;
      }
      return contacts;
    })
    .value();
});

export const selectDisplayedContactsList = createSelector(selectContacts, selectSearch, (contacts, search) => {
  // The displayed contacts should be either the ones that have their isDisplayed property set to true (contacts that were created after the paymentInvite release)
  // OR the ones that don't have an isDisplayed property at all (contacts that were created before the paymentInvite release)
  const displayedContactsList = _.filter(contacts, (contact) => (contact.isDisplayed || !contact.hasOwnProperty('isDisplayed')));
  let contactsList = _.map(displayedContactsList, (contact) => {
    return contact;
  });

  return _(displayedContactsList)
    .thru((displayedContactsList) => {
      if (search.searchTermContact !== "") {
        const searchTags = search.searchTermContact.split(" ");

        const filter = contactsList.filter((contact) => ContactContainsSearchTags(searchTags, contact));
        return filter;
      }
      return displayedContactsList;
    })
    .value();
});

