import React, { useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { space, variant } from "styled-system";
import css from "@styled-system/css";

import {
  selectAllArtworks,
  clearAllSelectedArtworks,
  updateSearchTerm,
  updateSortByOption,
  openModal,
  paymentInviteBetaRequest,
} from "../../store/actions";
import {
  ButtonLabels,
  DynamicLabels,
  InputPlaceholders,
  InputLabels,
  Messages,
  LinkLabels,
  FeaturesInfo,
  UiLabels,
} from "../../localisation";
import { SORT_OPTIONS } from "../../config";
import {
  selectSearch,
  selectSelectedArtworkIds,
  selectSelectedCollectionArtworks,
  selectUser,
} from "../../store/selectors";

import { Field, reduxForm } from "redux-form";
import { Flex, Text } from "../fundamentals";
import { Input, DropdownSelect, Button } from "../elements";
import { Popover } from "../react-material/Popover";
// import { Tooltip } from "@mui/material";
import { Tooltip } from "../react-material/Tooltip";

const tooltipStyle = {
  backgroundColor: "#F3F4FF",
  color: "black",
};

const StyledCollectionHeader = styled(Flex)`
  white-space: nowrap;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  border-bottom-width: 1px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;

  ${variant({
    prop: "selection",
    variants: {
      false: {
        bg: "grey.10",
        color: "grey.90",
      },
      true: {
        bg: "blue.60",
        color: "white",
      },
    },
  })}
  ${variant({
    prop: "sticky",
    variants: {
      true: {
        "::after": {
          content: '""',
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "5px",
          zIndex: "-1",
          boxShadow: "0px 4px 6px rgba(0,0,0,0.08)",
        },
      },
      false: { borderBottomStyle: "solid" },
    },
  })}
  ${(props) =>
    css({
      borderBottomColor: "grey.20",
      paddingLeft: "l",
      paddingRight: "m",
      paddingTop: ["xs", "s"],
      paddingBottom: ["xs", "s"],
      borderColor: "grey.30",
    })}
  ${space}
`;

const CollectionHeader = ({ customRef, hideButtons, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedArtworkIds = useSelector(selectSelectedArtworkIds);
  const artworks = useSelector(selectSelectedCollectionArtworks);
  const artworkCountInCollection = artworks.length;
  const isAnyArtworkSelected = selectedArtworkIds.length > 0;
  const search = useSelector(selectSearch);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [upgradeMultiEditAnchorEl, setUpgradeMultiEditAnchorEl] = useState(null);
  const [upgradeViewingRoomsAnchorEl, setUpgradeViewingRoomsAnchorEl] = useState(null);

  const currentUser = useSelector(selectUser);

  const handleOnSearch = _.debounce((searchTerm) => dispatch(updateSearchTerm(searchTerm)), 50);

  const handleOnSortBy = _.debounce((sortBy) => dispatch(updateSortByOption(sortBy)), 50);

  const handleOnArtworkDetailsClick = (e) => {
    if (selectedArtworkIds.length === 1) {
      history.push("/artworks/details", { source: history.location.pathname });
    } else if (currentUser.account.accessLevel === 0) {
      setUpgradeMultiEditAnchorEl(e.currentTarget);
    } else {
      history.push("/artworks/multi-edit", { source: history.location.pathname });
    }
  };

  const handleSelectAllArtworks = () => {
    const artworkIds = _.map(artworks, ({ id }) => id);
    dispatch(selectAllArtworks(artworkIds));
  };

  const handleClearAllSelectedArtworks = () => {
    dispatch(clearAllSelectedArtworks());
  };

  const handleExportsClick = () => {
    dispatch(openModal("advanced_export"));
  };

  const handleViewingRoomsButtonClick = () => {
    history.push("/viewingrooms/add");
  };

  const handleOnSellClick = (e) => {
    if (!currentUser.account.idVerified || !currentUser.account.marketplaceOnboarded) {
      history.push("/marketplace/onboarding");
    } else {
      dispatch(openModal("payment_invite"));
    }
  };

  // For users with a free account
  const handleExportsClickFree = (e) => {
    setPopoverAnchorEl(e.currentTarget);
  };

  const handleViewingRoomsButtonClickFree = (e) => {
    setUpgradeViewingRoomsAnchorEl(e.currentTarget);
  };

  const renderHeaderStyleButtons = () => {
    if (isAnyArtworkSelected)
      return (
        <Flex>
          <Tooltip title={UiLabels.exportDoc}>
            <Button
              buttonStyle="secondary"
              size="small"
              icon="File"
              inverted={true}
              onClick={currentUser.account.accessLevel === 0 ? handleExportsClickFree : handleExportsClick}
              mr="m"
              fontWeight="500"
              iconCursor="pointer"
              iconStrokeWidth="2.5px"
            />
          </Tooltip>
          <Popover
            popoverAnchorEl={popoverAnchorEl}
            setPopoverAnchorEl={setPopoverAnchorEl}
            text={Messages.upgradeMessage(FeaturesInfo.exporting, FeaturesInfo.exportingFeatDesc)}
            linkText={LinkLabels.checkOutPaidSubscription}
            upgrade
          />
          <Tooltip title={UiLabels.shareArtwork}>
            <Button
              buttonStyle="secondary"
              size="small"
              icon="Share"
              inverted={true}
              onClick={() => dispatch(openModal("share"))}
              mr="m"
              fontWeight="500"
              iconCursor="pointer"
              iconStrokeWidth="2.5px"
            />
          </Tooltip>
          <Tooltip title={UiLabels.editArtwork}>
            <Button
              buttonStyle="secondary"
              size="small"
              icon="Edit2"
              inverted={true}
              onClick={handleOnArtworkDetailsClick}
              mr="m"
              fontWeight="500"
              iconCursor="pointer"
              iconStrokeWidth="2.5px"
            />
          </Tooltip>
          <Tooltip title={UiLabels.sellArtwork}>
            <Button
              buttonStyle="secondary"
              size="small"
              icon="DollarSign"
              inverted={true}
              mr="s"
              onClick={handleOnSellClick}
              fontWeight="500"
              iconCursor="pointer"
              iconStrokeWidth="2.5px"
            />
          </Tooltip>
          <Popover
            popoverAnchorEl={upgradeMultiEditAnchorEl}
            setPopoverAnchorEl={setUpgradeMultiEditAnchorEl}
            text={Messages.upgradeMessage(FeaturesInfo.multiEdit, FeaturesInfo.multiEditFeatDesc)}
            linkText={LinkLabels.checkOutPaidSubscription}
            upgrade
          />
          {selectedArtworkIds.length > 1 ? (
            <Tooltip title={UiLabels.createViewingRoom}>
              <Button
                buttonStyle="secondary"
                size="small"
                icon="Globe"
                inverted={true}
                mr="s"
                onClick={
                  currentUser.account.accessLevel === 0
                    ? handleViewingRoomsButtonClickFree
                    : handleViewingRoomsButtonClick
                }
                fontWeight="500"
                iconCursor="pointer"
                iconStrokeWidth="2.5px"
              />
            </Tooltip>
          ) : (
            <Tooltip title={UiLabels.selectMoreArtworksForViewingRoom}>
              <span>
                <Button
                  buttonStyle="secondary"
                  size="small"
                  icon="Globe"
                  inverted={true}
                  mr="s"
                  fontWeight="500"
                  iconCursor="pointer"
                  iconStrokeWidth="2.5px"
                  disabled={true}
                />
              </span>
            </Tooltip>
          )}
          <Popover
            popoverAnchorEl={upgradeViewingRoomsAnchorEl}
            setPopoverAnchorEl={setUpgradeViewingRoomsAnchorEl}
            text={Messages.upgradeMessage(FeaturesInfo.viewingRooms, FeaturesInfo.viewingRoomsFeatDesc)}
            linkText={LinkLabels.checkOutPaidSubscription}
            upgrade
          />
          <Tooltip title={UiLabels.cancel}>
            <Button
              buttonStyle="secondary"
              size="small"
              icon="X"
              inverted={true}
              backgroundColor="white"
              color="blue.60"
              fontWeight="500"
              iconCursor="pointer"
              iconStrokeWidth="3px"
              onClick={handleClearAllSelectedArtworks}
            />
          </Tooltip>
        </Flex>
      );
    else
      return (
        <Flex>
          <Button buttonStyle="text" size="medium" onClick={handleSelectAllArtworks}>
            {ButtonLabels.selectAll}
          </Button>
        </Flex>
      );
  };

  return (
    <StyledCollectionHeader ref={customRef} selection={isAnyArtworkSelected} {...props}>
      <Flex alignItems="center" flexShrink={0} flexGrow={1}>
        <Text size="body" mr="m" flexShrink={0} userSelect="none">
          {DynamicLabels.numArtworks(isAnyArtworkSelected ? selectedArtworkIds.length : artworkCountInCollection)}
        </Text>
        <Field
          name="searchTerm"
          icon="Search"
          iconSize={19}
          inputStyle="pill"
          options={{ placeholder: InputPlaceholders.searchArtworks, value: search.searchTerm }}
          onChange={handleOnSearch}
          hideError={true}
          flex={1}
          maxWidth="275px"
          mr="m"
          component={Input}
        />
        <Field
          name="sortBy"
          component={DropdownSelect}
          inverted={isAnyArtworkSelected}
          label={InputLabels.sortBy}
          options={SORT_OPTIONS}
          onChange={handleOnSortBy}
          stateful
        />
      </Flex>
      {!hideButtons && renderHeaderStyleButtons()}
    </StyledCollectionHeader>
  );
};

const CollectionHeaderWithSearchForm = reduxForm({
  form: "artworks.filter",
})(CollectionHeader);

export { CollectionHeaderWithSearchForm as CollectionHeader };
