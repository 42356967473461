import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Flex, Box, Text, Icon } from "../fundamentals";
import { UiLabels, DynamicHeadings, ButtonLabels } from "../../localisation";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Typography } from "@mui/material";
import { selectPaymentInviteById } from "../../store/selectors";
import { openModal, updateArtworkStatus } from "../../store/actions";
import { Button } from "../elements";
import { PaymentInviteModalInfo, PaymentInviteArtworkValuesTable } from "../modules";

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "24px",
    lineHeight: mobile ? "26px" : "32px",
    marginBottom: "6px",
    fontWeight: "400",
  };
};

const ProgressBar = () => {
  return (
    <Flex fontSize="smaller" mb="l">
      <Text color="blue.60" mr="xxs">
        {UiLabels.information}
      </Text>
      <Icon icon="ChevronRight" color="grey.80" size="14" mr="xxs" />
      <Text color="blue.60" mr="xxs">
        {UiLabels.shipping}
      </Text>
      <Icon icon="ChevronRight" color="grey.80" size="14" mr="xxs" />
      <Text>{UiLabels.payment}</Text>
    </Flex>
  );
};

const PaymentInviteBuyerGeneratedShippingInfoModal = ({ paymentInviteId, placeId, isMarketPlace }) => {
  const dispatch = useDispatch();
  const isMobile = useIsScreenMobile();
  const [total, setTotal] = useState(0);
  const paymentInvite = useSelector((state) => selectPaymentInviteById(state, paymentInviteId));
  //   const paymentInviteArtworks = useSelector(
  //     (state) => state.payment_invite_artworks?.paymentInviteArtworks.byId[paymentInviteId]
  //   );
  const hasShippingCosts = paymentInvite?.costs.some((cost) => cost.name === "Shipping Costs");

  //Create a combined artworks array from paymentInvite.artworks and paymentInviteArtworks that will contain the properties of both
  //   let combinedArtworksArray = [];
  //   if (paymentInviteArtworks) {
  //     combinedArtworksArray = paymentInvite?.artworks?.map(({ _id, price }) => {
  //       const restOfProperties = paymentInviteArtworks?.find((artwork) => artwork._id === _id);
  //       return { _id, price, ...restOfProperties };
  //     });
  //   }
  useEffect(() => {
    // If shipping costs can't be calculated, the status of the artwork should be updated to reserved
    if (!hasShippingCosts) {
      dispatch(
        updateArtworkStatus(paymentInviteId, paymentInvite?.artworks[0]?._id, placeId, isMarketPlace, "reserved")
      );
    }
  }, []);

  // const calculateSingleArtworkSubtotal = (artworkId) => {
  //   const artwork = paymentInvite.artworks.find((artwork) => artwork._id === artworkId);

  //   if (!artwork) {
  //     return null;
  //   }

  //   const discountTotal = artwork.discounts.reduce((total, discount) => {
  //     return total + Number(artwork.price.value) * (discount.value / 100);
  //   }, 0);

  //   const totalPrice = Number(artwork.price.value) - discountTotal;

  //   return totalPrice;
  // };

  const handleButtonClick = () => {
    if (hasShippingCosts) {
      dispatch(openModal("payment_invite_buyer.pay", { paymentInviteId: paymentInvite._id, placeId, isMarketPlace }));
    } else {
      dispatch(
        openModal("payment_invite_buyer.status", { paymentInviteId: paymentInvite._id, placeId, isMarketPlace })
      );
    }
  };

  return (
    <Flex justifyContent="space-between" width="992px" height="800px">
      {/* Left Side */}
      <Box p="xxl" marginRight="xxs" overflow="auto" overflowX="hidden" width="55%">
        <Typography sx={headerTextStyle(isMobile)}>
          {DynamicHeadings.purchaseFrom(paymentInvite?._user?.organisation?.name || paymentInvite?._user?.name)}
        </Typography>
        <ProgressBar />
        {/* Artwork boxes currently hidden */}
        {/* <Flex mt="18px" p="xxxs" mb="m" width="460px" overflowX="auto">
          {combinedArtworksArray.map((artwork, index) => {
            const { images = [] } = artwork;
            const [firstImage = {}] = images;
            console.log("ARTWORK", artwork);
            return (
              <Box
                key={artwork._id}
                boxShadow="small"
                width="212px"
                mr={index === combinedArtworksArray.length - 1 ? "0" : "m"}
                mb="xxs"
                padding="s"
              >
                <Flex justifyContent="space-between" mb="xxs" alignItems="start">
                  <Box minWidth="74px" mr="xs">
                    <ImageWithCache
                      image={firstImage}
                      artworkId={artwork.id}
                      urlSuffix="/preview.jpg"
                      width="100%"
                      userselect="none"
                    />
                  </Box>
                  <Box>
                    <Text fontSize="smaller">{artwork.artist}</Text>
                    <Text
                      fontSize="smaller"
                      color="grey.80"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      maxWidth="14ch"
                    >
                      {artwork.artworkValues.title}
                    </Text>
                  </Box>
                </Flex>
                <Box fontSize="smaller">
                  <Flex justifyContent="space-between">
                    <Text>{UiLabels.artworkPrice}:</Text>
                    <Text>
                      {currencySymbol}
                      {artwork.price.value}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between" mt="xxxs">
                    <Text>{UiLabels.subTotal}:</Text>
                    <Text color="blue.60">
                      {currencySymbol}
                      {calculateSingleArtworkSubtotal(artwork._id)}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            );
          })}
        </Flex> */}
        <PaymentInviteArtworkValuesTable
          paymentInvite={paymentInvite}
          total={total}
          setTotal={setTotal}
          hasShippingCosts={hasShippingCosts}
          isShippingModal={true}
        />
        <Box width="460px" border="1px solid #C8CEFF" borderRadius="8px" padding="l" mb="m" fontSize="small">
          <Flex justifyContent="space-between" pb="xs" borderBottom="1px solid #C3C2C9">
            <Text>{UiLabels.contact}</Text>
            <Text fontWeight="500">{paymentInvite?.contacts[paymentInvite?.contacts?.length - 1]?.email}</Text>
          </Flex>
          <Flex justifyContent="space-between" mt="xs">
            <Text>{UiLabels.shipTo}</Text>
            <Text fontWeight="500">
              {`${paymentInvite?.shippingDetails?.address1}, ${paymentInvite?.shippingDetails?.postcode}, ${paymentInvite?.shippingDetails?.city}, ${paymentInvite?.shippingDetails?.country} `}
            </Text>
          </Flex>
        </Box>
        <Button buttonStyle="primary" size="medium" fontWeight="500" mt="l" onClick={handleButtonClick}>
          {hasShippingCosts ? ButtonLabels.continueToPayment : ButtonLabels.status}
        </Button>
      </Box>

      {/* Right Side */}
      <PaymentInviteModalInfo />
    </Flex>
  );
};

export { PaymentInviteBuyerGeneratedShippingInfoModal };
