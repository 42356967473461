import { call, select, put, all, takeEvery } from "redux-saga/effects";
import { replace } from "connected-react-router";
import axios from "axios";
import moment from "moment";
import { SimplifyApi, cachedDataUrlToFile, createToast } from "../../utils";
import { CardElement, CardNumberElement } from "@stripe/react-stripe-js";
import { startSubmit, stopSubmit, destroy } from "redux-form";

import {
  createOnboardingSubscription,
  createFreeUser,
  createSubscription,
  getSubscription,
  getSubscriptionSuccess,
  getSubscriptionFailure,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  changeSubscriptionSuccess,
  changeSubscriptionFailure,
  updateUserDetails,
  updateOrganisation,
  removeCacheImage,
  deleteUserAccountSuccess,
  deleteUserAccountFailure,
  getPaymentMethod,
  getPaymentMethodSuccess,
  getPaymentMethodFailure,
  changePaymentMethod,
  signOut,
  closeModal,
  expiredPaymentMethod,
  restartSubscriptionSuccess,
  restartSubscriptionFailure,
  register,
  downloadArchive,
  changeSelectedCollection,
  syncStarted,
  startBackgroundSync,
  startQueueSystemCleaner,
  openModal,
  contactUs,
  paymentInviteBetaRequest,
  paymentInviteBetaRequestSuccess,
  paymentInviteBetaRequestFailure,
  signUpBeta,
  getDiscountSuccess,
  getDiscountFailure,
} from "../actions";
import {
  GET_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  CHANGE_SUBSCRIPTION,
  DELETE_USER_ACCOUNT,
  GET_PAYMENT_METHOD,
  RESTART_SUBSCRIPTION,
  PAYMENT_INVITE_BETA_REQUEST,
  GET_DISCOUNT_REQUEST,
} from "../actions/types";
import { RecordEventAnalytics, RecordExceptionAnalytics } from "../../analytics";
import { Events, Properties, ActionExceptions } from "../../analytics/types";
import { Notifications } from "../../localisation";
import { selectUser } from "../selectors";
import _ from "lodash";

// SERVICES
const createSubscriptionService = (plan, payment_method, trial_period_days, onboarding_type, coupon) =>
  SimplifyApi.post("/v1/subscription", { plan, payment_method, trial_period_days, onboarding_type, coupon });

const registerService = (newUser) => SimplifyApi.post("/v1/register", newUser);

const getSubscriptionService = () => SimplifyApi.get("/v1/subscription");

const cancelSubscriptionService = (subscriptionId) => SimplifyApi.patch("/v1/subscription", { subscriptionId });

const restartSubscriptionService = (subscriptionId) =>
  SimplifyApi.patch("/v1/subscription/restart", { subscriptionId });

const changeSubscriptionService = (subscriptionId, newPlan) =>
  SimplifyApi.post("/v1/subscription/change", { subscriptionId, newPlan });

const updateUserDetailsService = (name, email) => SimplifyApi.patch("/v1/user", { name, email });

const updateOrganisationService = (organisation) => SimplifyApi.patch("/v1/organisation", organisation);

const getUploadOrganisationLogoUrlService = (logoImage) =>
  SimplifyApi.get(`/v1/organisation/uploadLogo/${logoImage.type.split("/").pop()}`);

const uploadOrganisationLogoService = (uploadUrl, imageFile, imageType) =>
  axios.put(uploadUrl, imageFile, {
    headers: { "Cache-Control": "max-age: 31536000, immutable", "Content-Type": imageType },
  });

const deleteUserAccountService = () => SimplifyApi.delete(`/v1/account`);

const getPaymentMethodService = () => SimplifyApi.get("/v1/paymentMethod");

const changePaymentMethodService = (payment_method) => SimplifyApi.post("/v1/paymentMethod", { payment_method });

const expiredPaymentMethodService = (payment_method) => SimplifyApi.post("/v1/paymentMethod", { payment_method });

const contactUsService = (user, message) => SimplifyApi.post("/v1/contact_us", { user, message });

const signUpBetaService = (email) => SimplifyApi.post("/v1/signUpBeta", { email });

const paymentInviteBetaRequestService = (user, event_name) =>
  SimplifyApi.post("/v1/custom_event", { userValue: user.id, eventName: event_name });

const getDiscountService = () => SimplifyApi.get("/v1/discount_codes");

// SAGAS
function* createOnboardingSubscriptionSaga(action) {
  const { stripe, elements, plan, organisation } = action.payload;
  try {
    const cardElement = elements.getElement(CardNumberElement);

    // Create Payment Method
    const { paymentMethod, error } = yield stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log(error);
      createToast({ type: "error", ...Notifications.subscriptionFailed });
      return;
    }

    const response = yield call(createSubscriptionService, plan, paymentMethod.id, 0, "onboarding");
    //const { accessLevel, activePlan } = response.data;

    const { subscription, accessLevel, activePlan } = response.data;

    console.log('SUBSCRIPTION ', subscription);

    if (subscription.latest_invoice.payment_intent) {
      const { client_secret, status } = subscription.latest_invoice.payment_intent;
      if (status === "requires_action") {
        const { error: confirmationError } = yield stripe.confirmCardPayment(client_secret);
        if (confirmationError) {
          console.error(confirmationError);
          createToast({ type: "error", ...Notifications.subscriptionFailed });
          return;
        }
      }

      const responseOrganisation = yield call(updateOrganisationService, organisation);
      yield put(updateOrganisation.success(responseOrganisation.data));

      createToast({ type: "success", ...Notifications.subscriptionSuccessful });
      window.dataLayer.push({ event: "registrationProcessPurchaseSuccessful" });
      yield all([put(createOnboardingSubscription.success({ accessLevel, activePlan })), put(replace("/welcome"))]);
    }
  } catch (error) {
    console.log(error);
    RecordEventAnalytics(Events.PAYMENT_FAILED);
    window.dataLayer.push({ event: "registrationProcessPurchaseUnSuccessful" });
    yield put(createOnboardingSubscription.failure());
  } finally {
    yield put(stopSubmit("register"));
    yield put(destroy("register"));
  }
}

function* freeRegisterSaga(action) {
  const { email, password, role, name, organisationName, prevLocation } = action.payload;
  try {
    const newUser = { email, password, role, name };

    newUser.pathWay = "free_flow";
    const response = yield call(registerService, newUser);

    const { user, lastSyncDate, organisation, collections, artists, artworks, shareRecords } = response.data;

    let initialCollection;

    if (user.role === "artist")
      initialCollection = _.find(collections, (collection) => collection.type === "production");
    else initialCollection = _.find(collections, (collection) => collection.type === "collection");

    let redirectRoute = "/welcome";
    if (prevLocation) {
      if (prevLocation.pathname === "/upgrade" && prevLocation.search) {
        redirectRoute = `/upgrade${prevLocation.search}`;
      } else if (prevLocation.pathname === "/upgrade") {
        redirectRoute = "/upgrade";
      }
    };

    yield all([
      put(register.success(user)),
      put(downloadArchive(collections, artists, artworks, shareRecords)),
      put(changeSelectedCollection(initialCollection.id)),
      put(syncStarted(lastSyncDate)),
      put(startBackgroundSync()),
      put(startQueueSystemCleaner()),
    ]);

    RecordEventAnalytics(Events.REGISTER);

    //UpdateAnalyticsUser(user);;
    yield put(replace(redirectRoute));
  } catch (error) {
    createToast({ type: "error", ...Notifications.generic });
    yield put(register.failure());
  }
}

function* createSubscriptionSaga(action) {
  const { stripe, elements, plan, isUpgradeFromPaymentInvite, coupon } = action.payload;
  yield put(startSubmit("pay"));
  yield put(startSubmit("upgrade"));
  try {
    const cardElement = elements.getElement(CardNumberElement);

    // Create Payment Method
    const { paymentMethod, error } = yield stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log('GOES IN HERE ',error);
      yield put(openModal("upgradeFailure"));
      createToast({ type: "error", ...Notifications.subscriptionFailed });
      return;
    }

    const response = yield call(createSubscriptionService, plan, paymentMethod.id, 0, "upgrade", coupon);
    let { subscription, accessLevel, activePlan } = response.data;
    console.log('SUBSCRIPTION ', subscription);
    if (subscription.latest_invoice.payment_intent) {
      const { client_secret, status } = subscription.latest_invoice.payment_intent;
      if (status === "requires_action") {
        const { error: confirmationError } = yield stripe.confirmCardPayment(client_secret);
        if (confirmationError) {
          console.error(confirmationError);
          createToast({ type: "error", ...Notifications.subscriptionFailed });
          yield put(createSubscription.failure());
          yield put(closeModal("upgrade"));
          return;
        } else {
          accessLevel = 1;
        }
      }

      createToast({ type: "success", ...Notifications.subscriptionSuccessful });
      yield all([
        put(createSubscription.success({ accessLevel, activePlan })),
        put(openModal("upgradeSuccess", { isUpgradeFromPaymentInvite })),
      ]);
    }
    //}
  } catch (error) {
    console.log({ otherErr: error });
    RecordEventAnalytics(Events.PAYMENT_FAILED);
    createToast({ type: "error", ...Notifications.subscriptionFailed });
    yield put(createSubscription.failure());
    yield put(closeModal("upgrade"));
  } finally {
    yield put(stopSubmit("pay"));
    yield put(stopSubmit("upgrade"));
    yield put(destroy("upgrade"));
  }
}

function* restartSubscriptionSaga() {
  try {
    const user = yield select(selectUser);

    const subscriptionId = user.account.subscription.subscriptionId;
    yield call(restartSubscriptionService, subscriptionId);

    createToast({ type: "success", ...Notifications.subscriptionRestartSuccess });
    yield all([put(restartSubscriptionSuccess()), put(getSubscription()), put(getPaymentMethod())]);
  } catch (error) {
    createToast({ type: "error", ...Notifications.subscriptionRestartFailed });
    yield put(restartSubscriptionFailure());
  }
}

function* getSubscriptionSaga(action) {
  try {
    const response = yield call(getSubscriptionService);

    yield put(getSubscriptionSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getSubscriptionFailure());
  }
}

function* cancelSubscriptionSaga(action) {
  const user = yield select(selectUser);

  try {
    const subscriptionId = user.account.subscription.subscriptionId;
    yield call(cancelSubscriptionService, subscriptionId);

    const parsedDateCreated = new Date(user.account.subscription.created);
    const date = new Date();
    const monthsOfSubscription =
      date.getMonth() - parsedDateCreated.getMonth() + 12 * (date.getFullYear() - parsedDateCreated.getFullYear());

    RecordEventAnalytics(Events.UNSUBSCRIBE, { [Properties.LENGTH]: monthsOfSubscription });
    createToast(
      {
        type: "warning",
        ...Notifications.subscriptionCancelSuccess,
      },
      { autoDismissTimeout: 5000, textAlign: "left" }
    );

    yield all([put(cancelSubscriptionSuccess()), put(getSubscription()), put(getPaymentMethod())]);
  } catch (error) {
    RecordExceptionAnalytics(ActionExceptions.CANCEL_SUBSCRIPTION);
    createToast({
      type: "error",
      ...Notifications.subscriptionCancelFailed,
    });
    yield put(cancelSubscriptionFailure());
  }
}

function* changeSubscriptionSaga(action) {
  try {
    const user = yield select(selectUser);
    const { newPlan, stripe } = action.payload;
    const subscriptionId = user.account.subscription.subscriptionId;

    const response = yield call(changeSubscriptionService, subscriptionId, newPlan);

    if (response.data.latest_invoice.payment_intent) {
      const { client_secret, status } = response.data.latest_invoice.payment_intent;
      if (status === "requires_action") {
        const { error: confirmationError } = yield stripe.confirmCardPayment(client_secret);
        if (confirmationError) {
          createToast({ type: "error", ...Notifications.subscriptionChangeFailedDueToCard });
          yield put(changeSubscriptionFailure());
          return;
        }
      }
    }

    createToast(
      {
        type: "success",
        ...Notifications.subscriptionChangeSuccess,
      },
      { autoDismissTimeout: 5000 }
    );

    yield all([put(changeSubscriptionSuccess()), put(getSubscription()), put(getPaymentMethod())]);
  } catch (error) {
    RecordExceptionAnalytics(ActionExceptions.CANCEL_SUBSCRIPTION);
    createToast({
      type: "error",
      ...Notifications.subscriptionChangeFailed,
    });
    yield put(changeSubscriptionFailure());
  }
}

function* getPaymentMethodSaga(action) {
  try {
    const response = yield call(getPaymentMethodService);
    yield put(getPaymentMethodSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getPaymentMethodFailure());
  }
}

function* changePaymentMethodSaga(action) {
  const { stripe, elements } = action.payload;
  yield put(startSubmit("changePaymentMethod"));

  try {
    const cardElement = elements.getElement(CardElement);

    // Create Payment Method
    const { paymentMethod, error } = yield stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    const response = yield call(changePaymentMethodService, paymentMethod.id);

    const newPaymentMethod = response.data.newPaymentMethod;
    const subscription = response.data.subscription.data[0];

    if (subscription.latest_invoice.payment_intent) {
      const { client_secret, status } = subscription.latest_invoice.payment_intent;
      if (status === "requires_action") {
        const { error: confirmationError } = yield stripe.confirmCardPayment(client_secret);
        if (confirmationError) {
          console.error(confirmationError);
          createToast({ type: "error", ...Notifications.subscriptionFailed });
          return;
        }
      }
    }

    RecordEventAnalytics(Events.PAYMENT_DETAILS_UPDATED);

    yield all([put(changePaymentMethod.success(newPaymentMethod)), put(closeModal())]);
    createToast({ type: "success", ...Notifications.changeBillingCardSuccess }, { autoDismissTimeout: 5000 });
  } catch (error) {
    RecordExceptionAnalytics(ActionExceptions.UPDATE_CARD_DETAILS);
    yield all([put(changePaymentMethod.failure()), put(closeModal())]);
    createToast({ type: "error", ...Notifications.changeBillingCardFailed });
  } finally {
    yield put(stopSubmit("changePaymentMethod"));
  }
}

function* updateUserDetailsSaga(action) {
  const { name, email } = action.payload;

  try {
    const response = yield call(updateUserDetailsService, name, email);

    createToast({ type: "success", ...Notifications.accountDetailsUpdateSuccess });

    RecordEventAnalytics(Events.USER_UPDATED);

    yield put(updateUserDetails.success({ name, email }));
  } catch (error) {
    RecordExceptionAnalytics(ActionExceptions.UPDATE_USER_DETAILS);
    createToast({ type: "error", ...Notifications.accountDetailsUpdateFailed });
    yield put(updateUserDetails.failure());
  }
}

function* updateOrganisationSaga(action) {
  const { logo, ...organisation } = action.payload;

  try {
    if (logo && logo.length !== 0) {
      try {
        const response = yield call(getUploadOrganisationLogoUrlService, logo[0]);
        const { uploadUrl, key } = response.data;
        const imageFile = yield call(cachedDataUrlToFile, logo[0].localId);
        yield call(uploadOrganisationLogoService, uploadUrl, imageFile, logo[0].type);

        yield put(removeCacheImage(logo.localId));
        organisation.logoUrl = key;
        RecordEventAnalytics(Events.LOGO_UPLOADED);
      } catch (error) {
        RecordExceptionAnalytics(ActionExceptions.UPLOAD_ORGANISATION_LOGO);
        createToast({ type: "error", ...Notifications.organisationLogoUploadFailed });
      }
    } else {
      organisation.logoUrl = null;
    }

    const response = yield call(updateOrganisationService, organisation);

    RecordEventAnalytics(Events.ORGANISATION_UPDATED);
    createToast({ type: "success", ...Notifications.organisationDetailsUpdateSuccess });
    yield put(updateOrganisation.success(response.data));
  } catch (error) {
    RecordExceptionAnalytics(ActionExceptions.UPDATE_ORGANISATION_DETAILS);
    createToast({ type: "error", ...Notifications.organisationDetailsUpdateFailed });
    yield put(updateOrganisation.failure());
  }
}

function* deleteUserAccountSaga() {
  try {
    if (window.confirm("Are you sure you want to close this account. All your data will be lost forever")) {
      yield call(deleteUserAccountService);

      yield all([put(deleteUserAccountSuccess()), put(signOut())]);
    }
  } catch (error) {
    RecordExceptionAnalytics(ActionExceptions.CLOSE_ACCOUNT);
    yield put(deleteUserAccountFailure());
  }
}

function* expiredPaymentMethodSaga(action) {
  const { stripe, elements } = action.payload;
  yield put(startSubmit("expired-payment-method"));

  try {
    const cardNumberElement = elements.getElement(CardNumberElement);

    const { paymentMethod } = yield stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });

    const newPaymentMethod = yield call(expiredPaymentMethodService, paymentMethod.id);
    RecordEventAnalytics(Events.PAYMENT_DETAILS_UPDATED);
    createToast({ type: "success", ...Notifications.creditCardUpdateSuccessful }, { autoDismissTimeout: 5000 });
    yield all([
      put(expiredPaymentMethod.success(newPaymentMethod.data)),
      put(replace("/expired-payment-method/success")),
    ]);
  } catch (error) {
    RecordExceptionAnalytics(ActionExceptions.UPDATE_CARD_DETAILS);
    yield put(expiredPaymentMethod.failure());
    createToast({ type: "error", ...Notifications.creditCardUpdateFailed });
  } finally {
    yield put(stopSubmit("expired-payment-method"));
  }
}

function* contactUsSaga(action) {
  const { message } = action.payload;

  try {
    let user = yield select(selectUser);
    const response = yield call(contactUsService, user, message);

    createToast({ type: "success", ...Notifications.messageSentSuccess });
    yield all([put(closeModal()), put(contactUs.success(response.data))]);

    RecordEventAnalytics(Events.CONTACT_US, {
      [Properties.HAS_MESSAGE]: (message !== null).toString(),
    });
  } catch (error) {
    console.log(error);
    createToast({ type: "error", ...Notifications.messageSentFailure });
    yield all([put(closeModal()), put(contactUs.failure())]);
  }
}

function* signUpBetaSaga(action) {
  const { email } = action.payload;

  try {
    const response = yield call(signUpBetaService, email);

    createToast({ type: "success", ...Notifications.betaRequestPublicSuccess });
    yield all([put(signUpBeta.success(response.data)), put(replace("/register"))]);

    RecordEventAnalytics(Events.SIGN_UP_BETA, {
      [Properties.HAS_MESSAGE]: (email !== null).toString(),
    });
  } catch (error) {
    console.log(error);
    createToast({ type: "error", ...Notifications.messageSentFailure });
    yield all([put(closeModal()), put(contactUs.failure())]);
  }
}

function* paymentInviteBetaRequestSaga(action) {
  const event_name = "PaymentInviteBetaTest";
  try {
    let user = yield select(selectUser);
    const response = yield call(paymentInviteBetaRequestService, user, event_name);
    createToast({ type: "success", ...Notifications.betaRequestSuccess });
    yield put(paymentInviteBetaRequestSuccess(response.data));
  } catch (error) {
    createToast({ type: "error", ...Notifications.generic });
    yield put(paymentInviteBetaRequestFailure());
  }
}

function* getDiscountSaga(action) {
  const submittedCode = action.payload;
  try {
    const response = yield call(getDiscountService);
    const isCodeValid = response.data.hasOwnProperty(submittedCode);
    if (isCodeValid) {
      yield put(getDiscountSuccess({ code: submittedCode, percentage: response.data[submittedCode] }));
    } else {
      yield put(getDiscountFailure());
      yield put(openModal("invalid_discount_code"));
    }
  } catch (error) {
    yield put(getDiscountFailure());
    throw error;
  }
}

function* userSaga() {
  yield takeEvery(createOnboardingSubscription.REQUEST, createOnboardingSubscriptionSaga);
  yield takeEvery(createFreeUser.REQUEST, freeRegisterSaga);
  yield takeEvery(RESTART_SUBSCRIPTION, restartSubscriptionSaga);
  yield takeEvery(createSubscription.REQUEST, createSubscriptionSaga);
  yield takeEvery(GET_SUBSCRIPTION, getSubscriptionSaga);
  yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscriptionSaga);
  yield takeEvery(CHANGE_SUBSCRIPTION, changeSubscriptionSaga);
  yield takeEvery(updateUserDetails.REQUEST, updateUserDetailsSaga);
  yield takeEvery(updateOrganisation.REQUEST, updateOrganisationSaga);
  yield takeEvery(DELETE_USER_ACCOUNT, deleteUserAccountSaga);
  yield takeEvery(GET_PAYMENT_METHOD, getPaymentMethodSaga);
  yield takeEvery(changePaymentMethod.REQUEST, changePaymentMethodSaga);
  yield takeEvery(expiredPaymentMethod.REQUEST, expiredPaymentMethodSaga);
  yield takeEvery(contactUs.REQUEST, contactUsSaga);
  yield takeEvery(PAYMENT_INVITE_BETA_REQUEST, paymentInviteBetaRequestSaga);
  yield takeEvery(signUpBeta.REQUEST, signUpBetaSaga);
  yield takeEvery(GET_DISCOUNT_REQUEST, getDiscountSaga);
}

export default userSaga;
