import {
  CREATE_SESSION_DISCOVERY,
  CREATE_SESSION_DISCOVERY_SUCCESS,
  GET_DISCOVERY_LOAD_REQUEST,
  GET_DISCOVERY_LOAD_SUCCESS,
  GET_DISCOVERY_LOAD_FAILURE,
  UPDATE_INTERACTION_DISCOVERY,
  GET_DISCOVERY_EVENTS_REQUEST,
  GET_DISCOVERY_EVENTS_SUCCESS,
  GET_DISCOVERY_EVENTS_FAILURE,
  UPDATE_DISCOVERY_FILTERS,
  GET_DISCOVERY_FAVOURITES_REQUEST,
  GET_DISCOVERY_FAVOURITES_SUCCESS,
  GET_DISCOVERY_FAVOURITES_FAILURE,
} from "./types";

export const createSessionDiscovery = (userId, isUser) => {
  return {
    type: CREATE_SESSION_DISCOVERY,
    payload: {
      userId,
      isUser,
    },
  };
};

export const createSessionDiscoverySuccess = (discoverySession) => {
  return { type: CREATE_SESSION_DISCOVERY_SUCCESS, payload: { ...discoverySession } };
};

export const getDiscoveryLoadRequest = (sessionId, eventIds, isLoadMore, artworkId) => {
  return {
    type: GET_DISCOVERY_LOAD_REQUEST,
    payload: {
      sessionId,
      eventIds,
      isLoadMore,
      artworkId
    },
  };
};

export const getDiscoveryLoadSuccess = (artworks, recommendationId, isLoadMore, isFinal) => {
  return { type: GET_DISCOVERY_LOAD_SUCCESS, payload: { artworks, recommendationId, isLoadMore, isFinal } };
};

export const getDiscoveryLoadFailure = () => {
  return { type: GET_DISCOVERY_LOAD_FAILURE, payload: [] };
};

export const getDiscoveryEventsRequest = () => {
  return { type: GET_DISCOVERY_EVENTS_REQUEST };
};

export const getDiscoveryEventsSuccess = (events) => {
  return { type: GET_DISCOVERY_EVENTS_SUCCESS, payload: events };
};

export const getDiscoveryEventsFailure = () => {
  return { type: GET_DISCOVERY_EVENTS_FAILURE, payload: [] };
};

export const updateDiscoveryFilters = (filters) => {
  return { type: UPDATE_DISCOVERY_FILTERS, payload: filters };
};

export const updateDiscoveryStatus = (sessionId, recommendationId, actionType, artWorkId) => {
  return {
    type: UPDATE_INTERACTION_DISCOVERY,
    payload: {
      sessionId,
      recommendationId,
      actionType,
      artWorkId,
    },
  };
};

export const getDiscoveryFavouritesRequest = (userId) => {
  return {
    type: GET_DISCOVERY_FAVOURITES_REQUEST,
    payload: {
      userId,
    },
  };
};

export const getDiscoveryFavouritesSuccess = (artworks) => {
  return { type: GET_DISCOVERY_FAVOURITES_SUCCESS, payload: { artworks } };
};

export const getDiscoveryFavouritesFailure = () => {
  return { type: GET_DISCOVERY_FAVOURITES_FAILURE, payload: [] };
};
