import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
  Paper,
} from "@mui/material";
import { CheckBoxRounded, IndeterminateCheckBox } from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { selectAllViewingRooms, clearAllSelectedViewingRooms, deleteViewingRooms } from "../../../store/actions";
import { selectSelectedViewingRoomIds } from "../../../store/selectors";
import { getComparator, stableSort } from "../../../utils";
import { ViewingRoomItem } from "../ViewingRoomItem/ViewingRoomItem";
import _ from "lodash";
import { Modal } from "../Modal";
import { DeleteModal } from "../../modals";
import { DynamicLabels, Warnings } from "../../../localisation";

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ bgcolor: "#EFEFF0" }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            checkedIcon={<CheckBoxRounded sx={{ color: "#5E54FF" }} />}
            indeterminateIcon={<IndeterminateCheckBox sx={{ color: "#5E54FF" }} />}
            inputProps={{
              "aria-label": "select all viewing rooms",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export function ViewingRoomsTable({ headCells, viewingRooms }) {
  const dispatch = useDispatch();
  const selectedViewingRooms = useSelector(selectSelectedViewingRoomIds) || [];
  const viewingRoomIds = _.map(viewingRooms, ({ _id }) => _id);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("dateCreated");
  const [selectRowForDelete, setSelectRowForDelete] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      dispatch(selectAllViewingRooms(viewingRoomIds));
    } else {
      dispatch(clearAllSelectedViewingRooms());
    }
  };

  const deleteSelectedViewingRooms = () => {
    dispatch(deleteViewingRooms(selectRowForDelete));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={"medium"}>
            <EnhancedTableHead
              numSelected={selectedViewingRooms.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAll}
              onRequestSort={handleRequestSort}
              rowCount={viewingRoomIds.length}
              headCells={headCells}
            />
            <TableBody sx={{ bgcolor: "#F4F4F5" }}>
              {stableSort(viewingRooms, getComparator(order, orderBy)).map((viewingRoom, index) => {
                if (viewingRoom?.isDisplayed !== false) {
                  return <ViewingRoomItem key={viewingRoom._id} viewingRoom={viewingRoom} index={index} deleteViewingRoomRow={setSelectRowForDelete} />;
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Modal id="delete_viewing_room_row">
        <DeleteModal
          headerText={DynamicLabels.popupHeaderForDelete("Viewing Room")}
          bodyText={Warnings.popupWarning}
          onDelete={deleteSelectedViewingRooms}
        />
      </Modal>
    </Box>
  );
}
