import React, { useState } from "react";
import styled from "styled-components";
import css from "@styled-system/css";

import { Box, Flex, Text, Icon, Link } from "../fundamentals";
import { Popover } from "../react-material/Popover";

const StyledCheckbox = styled(Box)`
  border: 3px solid;
  transform: scale(0.76);
  border-radius: ${(props) => (props.rounded ? "50%" : "7px")};
  svg {
    stroke-width: 3px;
  }
  ${(props) => props.rounded && `padding: 2px;`}
  ${(props) =>
    !props.disabled &&
    css({
      bg: props.checked ? "blue.60" : "transparent",
      borderColor: props.checked ? "blue.60" : props.hover ? "blue.60" : "grey.80",
      marginTop: "5px",
      "&:hover": {
        borderColor: "blue.60",
      },
    })}
  ${(props) =>
    props.disabled &&
    css({
      bg: "transparent",
      borderColor: "grey.40",
    })}
`;

export const Checkbox = ({
  input,
  meta,
  label,
  labelSize,
  description,
  descriptionSize,
  grouped,
  itemValue,
  disabled,
  rounded,
  additionalOnChange,
  color,
  marginRight,
  linkText,
  linkUrl,
  hasExplanation,
  explanationText,
  explanationLinkText,
  explanationLink,
  linkDecoration,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  function onChange() {
    if (!disabled) {
      const value = grouped || (itemValue && input.value !== itemValue) ? itemValue : !input.value;
      input.onChange(value);
      additionalOnChange && additionalOnChange(value);
    }
  }
  const checked = input.value && (!itemValue || input.value === itemValue);
  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      cursor={disabled ? "inherit" : "pointer"}
      onClick={onChange}
      {...props}
    >
      <Flex alignItems="center">
        <StyledCheckbox
          checked={checked}
          hover={hover}
          disabled={disabled}
          borderColor="red"
          rounded={rounded}
          mt="xxxs"
          mb="xxxs"
        >
          <Icon icon="Check" color={checked && !disabled ? "white" : "transparent"} size="18" />
        </StyledCheckbox>

        <Box>
          <Text
            color={disabled ? "grey.60" : checked || hover ? "blue.60" : color ? color : "#2B2A2E"}
            size={labelSize || "body"}
            ml="xxs"
            mr={marginRight || "s"}
            userSelect="none"
            fontWeight="400"
            lineHeight="24px"
            fontFamily="body"
          >
            {label}
            {linkText && (
              <Flex alignItems="center">
                <Text> </Text>
                <Link href={linkUrl} target="_blank" textDecoration={linkDecoration}>
                  {linkText}
                </Link>
              </Flex>
            )}
          </Text>
        </Box>
        {hasExplanation && (
          <Icon
            icon="HelpCircle"
            size="14"
            color="grey.90"
            right="0"
            cursor="pointer"
            onClick={(e) => setPopoverAnchorEl(e.currentTarget)}
          />
        )}
        <Popover
          popoverAnchorEl={popoverAnchorEl}
          setPopoverAnchorEl={setPopoverAnchorEl}
          text={explanationText}
          linkText={explanationLinkText}
          link={explanationLink}
          icon="Info"
        />
      </Flex>
      {description && (
        <Text
          color={hover && !checked && !disabled ? "grey.80" : checked ? "blue.60" : color ? color : "grey.60"}
          size={descriptionSize || "small"}
          mb="xxs"
          ml="35px"
          userSelect="none"
          whiteSpace="pre-wrap"
          fontWeight="400"
          lineHeight="18px"
        >
          {description}
        </Text>
      )}
      {meta && meta.submitFailed && meta.error && (
        <Text size="small" color="red">
          {meta.error}
        </Text>
      )}
    </Box>
  );
};
