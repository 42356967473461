import React from "react";
import styled, { css, keyframes } from "styled-components";
import { space, color, layout, position, border } from "styled-system";
import * as Icons from "react-feather";

import { Flex } from "reflexbox/styled-components";

const SpinnerAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledIcon = styled(Flex)`
  & > svg {
    overflow: visible;
    ${({ spin }) =>
      spin &&
      css`
        animation: ${SpinnerAnimation} 2s linear infinite;
      `}
    ${({ dark }) => dark && "fill: gray; color: white;"}
    ${({ strokeWidth }) => strokeWidth && `stroke-width: ${strokeWidth};`}
    
  }
  ${color}
  ${space}
  ${layout}
  ${position}
  ${border}
  ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow};`}
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
`;

const Icon = ({ icon, size, onClick, ...props }) => {
  if (icon) {
    const FeatherIcon = Icons[icon];
    return (
      <StyledIcon justifyContent="center" alignItems="center" onClick={onClick} {...props}>
        <FeatherIcon size={size} />
      </StyledIcon>
    );
  }
  return null;
};

export { Icon };
