import localForage from "localforage";
import { createMigrate } from "redux-persist";
import { WEBAPP_VERSION, SORT_OPTIONS } from "../config";

import _ from "lodash";

export const reduxPersistMigrations = {
  10: state => {
    return {
      app: {
        isOnline: false,
        version: WEBAPP_VERSION
      },
      sync: { nextSync: null, lastSyncSuccess: null, lastSyncFailure: null, syncCount: 0, isOnline: false },
      queue: {
        actionQueue: [],
        delayQueue: [],
        executingActions: {
          byId: {}
        },
        failedActionQueue: []
      },
      user: {},
      archive: { collections: { byId: {} }, artworks: { byId: {} }, artists: { byId: {} }, shareRecords: { byId: {} } },
      selection: { collection: "", category: "", artworks: [], artists: [], contacts:[], lists: [], paymentInvites: [], viewingRooms: [] },
      search: { searchTerm: "", sortBy: "artist", sortOrder: SORT_OPTIONS["artist"].sortOrder },
      ui: { modal: false, modalProps: {}, remoteAlert: {} },
      tutorial: { executing: false, joyrides: {} },
      session: { cachedImageUrls: {}, uploads: {} }
    };
  }
};

export const PERSIST_CONFIG = {
  key: "root",
  version: 10,
  storage: localForage,
  blacklist: ["form", "search", "ui"],
  migrate: createMigrate(reduxPersistMigrations, { debug: false }),
  writeFailHandler: () => console.error("Failed to write state to storage engine")
};
