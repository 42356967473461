import React from "react";
import _ from "lodash";
import { updateOrganisation } from "../../store/actions";
import { InputLabels, Headings, ButtonLabels, InputErrors } from "../../localisation";

import { Field, FieldArray, reduxForm } from "redux-form";
import { Text, Flex } from "../fundamentals";
import { Section, Button, Input, AddressFields } from "../elements";
import { ImageUpload } from "../modules";

const Form = ({ handleSubmit, submitting, pristine, ...props }) => {
  return (
    <form onSubmit={handleSubmit(updateOrganisation)}>
      <Text size="heading2" color="grey.80" mb="xxl">
        {Headings.organisationSettings}
      </Text>
      <Section title={Headings.organisationDetails} color="grey.80" width="460px" mb="xl">
        <Field name="name" label={InputLabels.organisationName} component={Input} />
        <Flex justifyContent="space-between">
          <Field name="firstName" flex="1" mr="m" label={InputLabels.representativeFirstName} component={Input} />
          <Field name="lastName" flex="1" label={InputLabels.representativeLastName} component={Input} />
        </Flex>
        <Field name="phone" label={InputLabels.phone} component={Input} />
        <AddressFields />

        <FieldArray
          name="logo"
          label={InputLabels.uploadGalleryLogo}
          component={ImageUpload}
          form={"settings.organisation"}
          urlSuffix={""}
          contained
          withDropbox
          maxImages={1}
          isLogoUpload={true}
        />

        <Field name="vatNumber" label={InputLabels.vatNumber} component={Input} />
        <Field name="websiteUrl" label={InputLabels.website} component={Input} />
        <Button
          type="submit"
          icon={submitting ? "Loader" : null}
          iconSpinning={true}
          buttonStyle="primary"
          size="medium"
          mt="s"
          disabled={pristine || submitting}
        >
          {ButtonLabels.updateOrganisation}
        </Button>
      </Section>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  const { name, firstName, lastName, phone, address } = values;

  if (!name) errors.name = "Enter an Organisation Name";
  if (!firstName) errors.firstName = "Enter a representative's first name";
  if (!lastName) errors.lastName = "Enter a representative's last name";
  if (!phone) {
    errors.phone = InputErrors.noPhoneNumber;
  } else if (!phone.startsWith("+")) {
    errors.phone = InputErrors.invalidPhoneNumber;
  }

  const cleanedAddress = address && _.pickBy(address, _.identity);

  if (cleanedAddress) {
    errors.address = {};
    const { addressLine1, city, postalCode, country } = cleanedAddress;
    if (!addressLine1) errors.address.addressLine1 = "Enter address line 1";
    if (!city) errors.address.city = "Enter your city";
    if (!postalCode) errors.address.postalCode = "Enter your postal code";
    if (!country) errors.address.country = "Select your country";
  }

  return errors;
};

const OrganisationSettingsForm = reduxForm({
  form: "settings.organisation",
  enableReinitialize: true,
  validate,
})(Form);

export { OrganisationSettingsForm };
