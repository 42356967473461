import {
  CREATE_PAYMENT_INVITE_REQUEST,
  CREATE_PAYMENT_INVITE_SUCCESS,
  CREATE_PAYMENT_INVITE_FAILURE,
  INITIATE_INVITE_PAYMENT,
  EXECUTE_INVITE_PAYMENT,
  GET_PAYMENT_INVITE_REQUEST,
  GET_PAYMENT_INVITE_SUCCESS,
  GET_PAYMENT_INVITE_FAILURE,
  GET_PAYMENT_INVITE_ARTWORKS,
  GET_PAYMENT_INVITE_ARTWORKS_SUCCESS,
  PAYMENT_INVITE_REJECTED,
  DOWNLOAD_PAYMENT_INVITES,
  DOWNLOAD_PAYMENT_INVITES_SUCCESS,
  DOWNLOAD_PAYMENT_INVITES_FAILURE,
  DOWNLOAD_PAYMENT_INVITE_INVOICE,
  SEND_PAYMENT_INVITE_REMINDER,
  MARK_PAYMENT_INVITE_IN_TRANSIT,
  UPDATE_PAYMENT_INVITE_EMAIL,
  MARK_PAYMENT_INVITE_DISPUTED,
  MARK_PAYMENT_INVITE_DELIVERED,
  UPDATE_PAYMENT_INVITE_REQUEST,
  UPDATE_PAYMENT_INVITE_SUCCESS,
  UPDATE_PAYMENT_INVITE_FAILURE,
} from "./types";
import { createFormAction } from "redux-form-saga";

export const createPaymentInviteRequest = ({
  artworkIds,
  isBuyerGenerated,
  isMarketPlace,
  isOffer,
  isEnquiry,
  isMobile,
  ...formValues
}) => {
  return {
    type: CREATE_PAYMENT_INVITE_REQUEST,
    payload: {
      artworkIds,
      isBuyerGenerated,
      isMarketPlace,
      isOffer,
      isEnquiry,
      isMobile,
      ...formValues,
    },
  };
};

export const createPaymentInviteSuccess = (response) => {
  return {
    type: CREATE_PAYMENT_INVITE_SUCCESS,
    payload: { ...response },
  };
};
export const createPaymentInviteFailure = () => {
  return { type: CREATE_PAYMENT_INVITE_FAILURE, payload: {} };
};

export const initiateInvitePayment = createFormAction(INITIATE_INVITE_PAYMENT);

export const executeInvitePayment = createFormAction(EXECUTE_INVITE_PAYMENT);

export const getPaymentInviteRequest = ({paymentInviteId, isOffer, isEnquiry, isMarketPlace, isMobile}) => {
  return { type: GET_PAYMENT_INVITE_REQUEST, payload: { paymentInviteId, isOffer, isEnquiry, isMarketPlace, isMobile } };
};

export const getPaymentInviteSuccess = ({paymentInvite, publicLocationExists}) => {
  return { type: GET_PAYMENT_INVITE_SUCCESS, payload: { paymentInvite, publicLocationExists } };
};

export const getPaymentInviteFailure = () => {
  return { type: GET_PAYMENT_INVITE_FAILURE, payload: [] };
};

export const getPaymentInviteArtworks = (paymentInviteId, artworkIds) => {
  return { type: GET_PAYMENT_INVITE_ARTWORKS, payload: { paymentInviteId, artworkIds } };
};

export const getPaymentInviteArtworksSuccess = (paymentInviteId, artworks) => {
  return { type: GET_PAYMENT_INVITE_ARTWORKS_SUCCESS, payload: { paymentInviteId, artworks } };
};

export const getPaymentInviteArtworksFailure = () => {
  return { type: GET_PAYMENT_INVITE_ARTWORKS, payload: [] };
};

export const paymentInviteRejected = ({ paymentInviteId, eventName, message, userId }) => {
  return { type: PAYMENT_INVITE_REJECTED, payload: { paymentInviteId, eventName, message, userId } };
};
export const downloadPaymentInvites = () => {
  return { type: DOWNLOAD_PAYMENT_INVITES };
};
export const downloadPaymentInvitesSuccess = (paymentInvites) => {
  return { type: DOWNLOAD_PAYMENT_INVITES_SUCCESS, payload: paymentInvites };
};
export const downloadPaymentInvitesFailure = () => {
  return { type: DOWNLOAD_PAYMENT_INVITES_FAILURE, payload: [] };
};

export const downloadPaymentInviteInvoice = (paymentInvite) => {
  return { type: DOWNLOAD_PAYMENT_INVITE_INVOICE, payload: paymentInvite };
};

export const sendPaymentInviteReminder = (paymentInviteId) => {
  return { type: SEND_PAYMENT_INVITE_REMINDER, payload: paymentInviteId };
};

export const markPaymentInviteInTransit = (paymentInviteId, trackingNumber) => {
  return { type: MARK_PAYMENT_INVITE_IN_TRANSIT, payload: { paymentInviteId, trackingNumber } };
};

export const updatePaymentInviteEmail = (paymentInviteId, newEmail) => {
  return { type: UPDATE_PAYMENT_INVITE_EMAIL, payload: { paymentInviteId, newEmail } };
};

export const markPaymentInviteDisputed = (paymentInviteId, subject, message) => {
  return { type: MARK_PAYMENT_INVITE_DISPUTED, payload: { paymentInviteId, subject, message } };
};

export const markPaymentInviteDelivered = (paymentInviteId) => {
  return { type: MARK_PAYMENT_INVITE_DELIVERED, payload: paymentInviteId };
};

export const updatePaymentInviteRequest = ({
  paymentInviteId,
  placeId,
  isMarketPlace,
  isOffer,
  isEnquiry,
  status,
  isMobile,
  ...formValues
}) => {
  return {
    type: UPDATE_PAYMENT_INVITE_REQUEST,
    payload: {
      paymentInviteId,
      placeId,
      isMarketPlace,
      isOffer,
      isEnquiry,
      status,
      isMobile,
      lastModified: new Date().toISOString(),
      ...formValues,
    },
  };
};

export const updatePaymentInviteSuccess = (id, response) => {
  return { type: UPDATE_PAYMENT_INVITE_SUCCESS, payload: { id, response } };
};

export const updatePaymentInviteFailure = () => {
  return { type: UPDATE_PAYMENT_INVITE_FAILURE, payload: {} };
};
