import { Flex, Image, Link } from "../../fundamentals";
import { Button } from "../../elements";
import { LinkLabels, ButtonLabels } from "../../../localisation";
import { useHistory } from "react-router-dom";
import { useIsScreenMobile } from "../../hooks/useMediaQuery";

export const NonUserNavigationBar = () => {
    const history = useHistory();
    const isMobile = useIsScreenMobile();

    return (
        <Flex
            padding={isMobile ? "12px 20px" : "24px 48px"}
            boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.16)"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link href="https://www.simplify.art" target="_blank">
              <Image
                src={isMobile ? process.env.PUBLIC_URL + `/images/brand-identity/logo-icon-black.svg` : process.env.PUBLIC_URL + `/images/brand-identity/logo_horizontal-black.svg`}
                alt="logo"
                width={isMobile ? "22px" : "100px"}
              />
            </Link>
            <Flex fontFamily="bodyDmSans" fontSize="small" alignItems="center">
              {!isMobile && <Link
                href="https://www.simplify.art/product"
                target="_blank"
                color="black"
                hoverColor="black"
                fontWeight="700"
                mr="m"
              >
                {LinkLabels.product}
              </Link>}
              {!isMobile && <Link
                href="https://www.simplify.art/pricing"
                target="_blank"
                color="black"
                hoverColor="black"
                fontWeight="700"
                mr="m"
              >
                {LinkLabels.pricing}
              </Link>}
              <Button
                buttonStyle="default"
                type="button"
                size="small"
                mr={isMobile ? "xs" : "m"}
                bg="white"
                fontWeight="700"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/login");
                }}
              >
                {ButtonLabels.logIn}
              </Button>
              <Button
                buttonStyle="primary"
                type="button"
                size="small"
                border="3px solid #5E54FF"
                fontWeight="700"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/register");
                }}
              >
                {ButtonLabels.getStarted}
              </Button>
            </Flex>
          </Flex>
    )
}