import React from "react";
import { Flex, Box, Link } from "../../../fundamentals";
import { Button } from "../../../elements";
import { ButtonLabels, LinkLabels, InputLabels } from "../../../../localisation";
import { Typography, Stack } from "@mui/material";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { InputSelect } from "../../../react-material/InputSelect/InputSelect";
import CountryData from "country-region-data";
import { connect } from "react-redux";
// import { createOnboardingLayoutRequest } from "../../../../store/actions";

const types = [
  {
    label: "Individual",
    value: "individual",
  },
  {
    label: "Company",
    value: "company",
  },
];

const Form = ({
  handleSubmit,
  submitting,
  form,
  fieldStyle,
  stackStyle,
  labelStyle,
  country,
  businessType,
  setPage,
  nextPage,
}) => {
  const submit = (values, dispatch) => {
    setPage(nextPage);
    // const { country, businessType } = values;
    // return dispatch(createOnboardingLayoutRequest({country, businessType}))
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box mt="30px" border="1px solid #C3C2C9" borderRadius="7px" padding="l">
        <Stack direction="column">
          <Stack sx={stackStyle} direction="row">
            <Typography sx={labelStyle}>{InputLabels.countryOfResidence}</Typography>
            <Field
              name="country"
              component={InputSelect}
              sx={{
                width: "250px",
                minHeight: "55px",
                "&.MuiFilledInput-root": { backgroundColor: "white" },
                "& .MuiSelect-filled": { backgroundColor: "white" },
              }}
              options={CountryData.map((country) => ({ value: country.countryShortCode, label: country.countryName }))}
              hideError={true}
            />
          </Stack>

          <Stack sx={stackStyle} direction="row">
            <Typography sx={labelStyle}>{InputLabels.type}</Typography>
            <Field
              name="businessType"
              component={InputSelect}
              sx={{
                width: "250px",
                minHeight: "55px",
                "&.MuiFilledInput-root": { backgroundColor: "white" },
                "& .MuiSelect-filled": { backgroundColor: "white" },
              }}
              options={types.map((type) => ({ value: type.value, label: type.label }))}
              hideError={true}
            />
          </Stack>
        </Stack>
      </Box>
      <Flex justifyContent="space-between" mt="30px">
        <Link href="https://www.simplify.art/sell-your-art" target="_blank">
          <Button type="button" buttonStyle="text" fontFamily="Inter" icon="HelpCircle" iconSize="16" padding="0">
            {LinkLabels.learnMoreSimplifySelling}
          </Button>
        </Link>
        <Button
          type="submit"
          buttonStyle="primary"
          size="small"
          disabled={!country || !businessType || submitting}
          icon={submitting ? "Loader" : null}
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
        >
          {ButtonLabels.next}
        </Button>
      </Flex>
    </form>
  );
};

let BasicInfoForm = reduxForm({
  form: "payment_invite.onboard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Form);

const selector = formValueSelector("payment_invite.onboard");

function mapStateToProps(state) {
  const country = selector(state, "country");
  const businessType = selector(state, "businessType");
  return { country, businessType };
}
BasicInfoForm = connect(mapStateToProps, null)(BasicInfoForm);

export { BasicInfoForm };
