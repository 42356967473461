import React from "react";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm, formValueSelector, FormSection } from "redux-form";
import { Button, Input, Checkbox, RadioSelect } from "../../elements";
import { Flex, Box, Text } from "../../fundamentals";
import { ButtonLabels, InputErrors, InputLabels, UiLabels, LinkLabels } from "../../../localisation";
import { updatePaymentInviteRequest } from "../../../store/actions";
import { useIsScreenMobile } from "../../hooks/useMediaQuery";

const Form = ({
  handleSubmit,
  submitting,
  paymentInviteId,
  isBillingAddressSame,
  agreeToTerms,
  transportMode,
  submitFailed,
  placeId,
  isMarketPlace,
  publicLocationExists,
}) => {
  const isMobile = useIsScreenMobile();
  const transportModeError = useSelector((state) => state.form.payment_invite?.update?.syncErrors?.transportMode);

  const submit = (values, dispatch) => {
    if (submitting) {
      return;
    }

    return dispatch(
      updatePaymentInviteRequest({
        paymentInviteId,
        placeId,
        isMarketPlace,
        isMobile,
        ...values,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box fontSize="small" mb="m">
        <Text color="blue.60" fontWeight="500" mb="xs">
          {UiLabels.contact}
        </Text>
        <Field name="contactEmail" placeholder={InputLabels.emailText} component={Input} minHeight="50px" required />
        <Field name="contactPhone" placeholder={InputLabels.phone} component={Input} minHeight="50px" required />
      </Box>
      <Box
        fontSize="small"
        mb="m"
        p={transportModeError && submitFailed ? "xxxs" : null}
        border={transportModeError && submitFailed ? "1px solid red" : null}
      >
        <Text color="blue.60" fontWeight="500" mb="xs">
          {UiLabels.transport}
        </Text>
        <Field
          name="transportMode"
          component={RadioSelect}
          direction="column"
          labelSize="small"
          descriptionSize="12px"
          spacing="small"
          disabledOption={!publicLocationExists && "simplifyShipping"}
          options={{
            simplifyShipping: {
              label: !publicLocationExists ? InputLabels.simplifyShippingNotAvailable : InputLabels.simplifyShipping,
              description: InputLabels.simplifyShippingDesc,
            },
            ownShipping: { label: InputLabels.ownShipping, description: InputLabels.confirmationEmail },
            handover: { label: InputLabels.handover, description: InputLabels.confirmationEmail },
          }}
        />
      </Box>
      {/* Shipping details */}
      {transportMode === "simplifyShipping" && (
        <FormSection name="shippingDetails">
          <Box fontSize="small" mb="m">
            <Text color="blue.60" fontWeight="500" mb="xs">
              {UiLabels.shippingAddress}
            </Text>
            <Flex flexDirection={isMobile ? "column" : "row"} justifyContent={!isMobile && "space-between"}>
              <Field
                name="firstName"
                placeholder={InputLabels.firstName}
                component={Input}
                minHeight="50px"
                required
                flex="1"
                mr={!isMobile && "10px"}
              />
              <Field
                name="lastName"
                placeholder={InputLabels.lastName}
                component={Input}
                minHeight="50px"
                required
                flex="1"
              />
            </Flex>
            <Field name="address1" placeholder={InputLabels.addressLine1} component={Input} minHeight="50px" required />
            <Field name="address2" placeholder={InputLabels.addressLine2} component={Input} minHeight="50px" />
            <Flex>
              <Field
                name="city"
                placeholder={InputLabels.city}
                component={Input}
                minHeight="50px"
                width="70%"
                mr="xxs"
                required
              />
              <Field name="postcode" placeholder={InputLabels.postCode} component={Input} minHeight="50px" required />
            </Flex>
            <Field name="country" placeholder={InputLabels.country} component={Input} minHeight="50px" required />
          </Box>
        </FormSection>
      )}
      {/* Billing details */}
      <Box fontSize="small" mb="xxl">
        <Flex justifyContent="space-between" alignItems="center" mb="xs">
          <Text color="blue.60" fontWeight="500">
            {UiLabels.billingAddress}
          </Text>
          {transportMode === "simplifyShipping" && (
            <Field
              name="isBillingAddressSame"
              label={InputLabels.sameAsShippingAddress}
              labelSize="small"
              component={Checkbox}
              marginRight="0"
            />
          )}
        </Flex>
        {(!isBillingAddressSame || transportMode !== "simplifyShipping") && (
          <FormSection name="billingDetails">
            <Box>
              <Flex flexDirection={isMobile ? "column" : "row"} justifyContent={!isMobile && "space-between"}>
                <Field
                  name="firstName"
                  placeholder={InputLabels.firstName}
                  component={Input}
                  minHeight="50px"
                  required
                  flex="1"
                  mr={!isMobile && "10px"}
                />
                <Field
                  name="lastName"
                  placeholder={InputLabels.lastName}
                  component={Input}
                  minHeight="50px"
                  required
                  flex="1"
                />
              </Flex>
              <Field
                name="address1"
                placeholder={InputLabels.addressLine1}
                component={Input}
                minHeight="50px"
                required
              />
              <Field name="address2" placeholder={InputLabels.addressLine2} component={Input} minHeight="50px" />
              <Flex>
                <Field
                  name="city"
                  placeholder={InputLabels.city}
                  component={Input}
                  minHeight="50px"
                  width="70%"
                  mr="10px"
                  required
                />
                <Field name="postcode" placeholder={InputLabels.postCode} component={Input} minHeight="50px" required />
              </Flex>
              <Field name="country" placeholder={InputLabels.country} component={Input} minHeight="50px" required />
            </Box>
          </FormSection>
        )}
        <Field
          name="agreeToTerms"
          label={InputLabels.agreeTo}
          labelSize="small"
          linkText={LinkLabels.termsAndConditions}
          linkUrl="https://www.simplify.art/imprint#terms-conditions"
          linkDecoration="underline"
          component={Checkbox}
        />
      </Box>
      <Button
        type="submit"
        buttonStyle="primary"
        size="medium"
        fontWeight="500"
        disabled={submitting || !agreeToTerms}
        icon={submitting ? "Loader" : null}
        iconSpinning={true}
        iconOffset="xs"
      >
        {transportMode === "simplifyShipping" ? ButtonLabels.continueToShipping : ButtonLabels.continueToPayment}
      </Button>
      {!agreeToTerms && (
        <Text color="grey.80" fontSize="smaller" mt="xs">
          Please agree to Terms & Conditions to continue
        </Text>
      )}
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  const {
    contactEmail,
    contactPhone,
    transportMode,
    shippingFirstName,
    shippingLastName,
    shippingAddress1,
    shippingCity,
    shippingPostcode,
    shippingCountry,
    billingFirstName,
    billingLastName,
    billingAddress1,
    billingCity,
    billingPostcode,
    billingCountry,
    isBillingAddressSame,
  } = values;

  if (!contactEmail) {
    errors.contactEmail = InputErrors.noEmail;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(contactEmail)) {
    errors.contactEmail = InputErrors.invalidEmail;
  }
  if (!contactPhone) {
    errors.contactPhone = InputErrors.noPhoneNumber;
  } else if (!contactPhone.startsWith("+")) {
    errors.contactPhone = InputErrors.invalidPhoneNumber;
  }
  if (!transportMode) {
    errors.transportMode = InputErrors.noTransportMode;
  }
  if (transportMode === "simplifyShipping") {
    if (!shippingFirstName) {
      errors.shippingFirstName = InputErrors.noFirstName;
    }
    if (!shippingLastName) {
      errors.shippingLastName = InputErrors.noLastName;
    }
    if (!shippingAddress1) {
      errors.shippingAddress1 = InputErrors.noAddress;
    }
    if (!shippingCity) {
      errors.shippingCity = InputErrors.noCity;
    }
    if (!shippingPostcode) {
      errors.shippingPostcode = InputErrors.noPostalCode;
    }
    if (!shippingCountry) {
      errors.shippingCountry = InputErrors.noCountry;
    }
  }
  if (!isBillingAddressSame) {
    if (!billingFirstName) {
      errors.billingFirstName = InputErrors.noFirstName;
    }
    if (!billingLastName) {
      errors.billingLastName = InputErrors.noLastName;
    }
    if (!billingAddress1) {
      errors.billingAddress1 = InputErrors.noAddress;
    }
    if (!billingCity) {
      errors.billingCity = InputErrors.noCity;
    }
    if (!billingPostcode) {
      errors.billingPostcode = InputErrors.noPostalCode;
    }
    if (!billingCountry) {
      errors.billingCountry = InputErrors.noCountry;
    }
  }

  return errors;
};

let BuyerGeneralInfoForm = reduxForm({
  form: "payment_invite.update",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

const selector = formValueSelector("payment_invite.update");

function mapStateToProps(state) {
  const paymentMethod = selector(state, "payment_method");
  const isBillingAddressSame = selector(state, "isBillingAddressSame");
  const agreeToTerms = selector(state, "agreeToTerms");
  const transportMode = selector(state, "transportMode");
  return { paymentMethod, isBillingAddressSame, agreeToTerms, transportMode };
}

BuyerGeneralInfoForm = connect(mapStateToProps, null)(BuyerGeneralInfoForm);

export { BuyerGeneralInfoForm };
