import React from "react";
import { useDispatch } from "react-redux";
import { getViewingRoomRequest } from "../../store/actions";
import { Box, Text, Flex } from "../fundamentals";
import { Button } from "../elements";
import { ButtonLabels, InputErrors } from "../../localisation";
import { useForm } from "react-hook-form";
import { ReactHookFormInput } from "../react-material/Input";
import _ from "lodash";

const ViewingRoomPasswordModal = ({
  width,
  viewingRoomId,
}) => {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const submit = (values, event) => {
    event.preventDefault();
    return dispatch(getViewingRoomRequest(viewingRoomId, values.password ));
  };

  const passwordError = _.get(errors, "password");

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box width={width}>
        <Text size="heading3" mb="s" color="black">
          This is a private Viewing Room
        </Text>
        <Text fontSize="small" mb="s">
          Please type the password to access the Viewing Room
        </Text>
        <Box>
          <ReactHookFormInput
            name="password"
            isRequired={true}
            control={control}
            errors={errors}
            errorMessage={InputErrors.noPassword}
            type="password"
            customRootStyles={{
              width: "100%",
              position: "relative",
              marginBottom: "-1px",
              borderBottom: "1px solid #C3C2C9",
              bottom: passwordError && "-12px",
              "&:hover": {
                marginBottom: passwordError ? "-2px" : "-1px",
                height: passwordError ? null : "49px",
              },
            }}
          />
        </Box>
        <Button
          type="submit"
          buttonStyle="primary"
          size="medium"
          mt="s"
          disabled={isSubmitting}
          icon={isSubmitting ? "Loader" : null}
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
        >
          {ButtonLabels.submit}
        </Button>
      </Box>
    </form>
  );
};

export { ViewingRoomPasswordModal };
