import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { change, clearFields } from "redux-form";
import { Box, TextField, Typography, IconButton, InputAdornment, FormLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Info, Eye, EyeOff, XCircle } from "react-feather";

const StyledTextField = styled(TextField)((props) => ({
  ".MuiFilledInput-root": {
    "&:hover": {
      background: "#DEE1FF",
    },
    "&:focus": {
      background: "#F3F4FF",
      color: "#2B2A2E",
    },
    "&.Mui-error": {
      background: "#FFDFDA",
    },
  },

  "& .MuiFormLabel-asterisk": {
    color: props?.active || props?.value ? "transparent" : "#5E54FF", // remove label asterisk while field is focused or it contains data
  },

  "& .MuiFilledInput-input": {
    fontFamily: "Inter",
    fontSize: "16px",
    fontHeight: "24px",
    fontWeight: 400,
    color: "#000000",
    paddingTop: "16px",
    paddingBottom: "16px",
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  "& .MuiFilledInput-underline:before": {
    borderBottom: "2px solid #000000",
  },

  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "#5E54FF",
  },

  "& .Mui-error": {
    marginLeft: "0px",
    "&:focus-within::after": {
      borderBottomColor: "#5E54FF !important",
    },
    ".MuiFilledInput-root": {
      color: "#FF3737",
      background: "#FFDFDA",
    },
  },
}));

const labelStyle = {
  fontSize: "14px",
  lineHeight: "18px",
  fontFamily: "Inter",
  color: "#55535A",
  paddingBottom: "3px",
  "& .MuiFormLabel-asterisk": {
    color: "##5E54FF",
  },
};

const Input = ({
  formName,
  label,
  input,
  placeholder,
  type = "text",
  meta: { touched, invalid, error, active },
  password,
  disabled,
  warning,
  textIcon,
  required,
  hideError = false,
  typeNumber,
  ...props
}) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [hover, setHover] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClearField = () => {
    dispatch(change(formName, input.name, ""));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getHelperText = () => {
    if (touched && error) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row", alingItems: "center", marginBottom: "5px" }}>
          <Info size={"12px"} style={{ alignSelf: "center", paddingRight: "5px" }} />

          <Typography sx={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 400, color: "#FF3737" }}>
            {touched && error}
          </Typography>
        </Box>
      );
    }

    if (warning) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row", alingItems: "center", marginBottom: "5px" }}>
          <Info
            size={"12px"}
            style={{ alignSelf: "center", marginLeft: "-15px", paddingRight: "5px", color: "black" }}
          />

          <Typography sx={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 400, color: "#000000" }}>
            {warning}
          </Typography>
        </Box>
      );
    }
  };

  const getEndAdornment = () => {
    if (type === "text" && textIcon) {
      return (
        <InputAdornment position="end" disablePointerEvents={disabled}>
          <IconButton aria-label="unit" edge="end" sx={{ padding: "16px" }}>
            {textIcon}
          </IconButton>
        </InputAdornment>
      );
    }

    if (type === "text" && (active || hover)) {
      return (
        <InputAdornment position="end" disablePointerEvents={disabled}>
          <IconButton
            aria-label="clear field"
            onClick={handleClearField}
            onMouseDown={handleMouseDownPassword}
            edge="end"
            sx={{ padding: "16px" }}
          >
            <XCircle size={"16px"} color="#5E54FF" />
          </IconButton>
        </InputAdornment>
      );
    }

    if (type === "password") {
      return (
        <InputAdornment position="end" disablePointerEvents={disabled}>
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
            sx={{ padding: "16px" }}
          >
            {showPassword ? <Eye size={"16px"} color="#5E54FF" /> : <EyeOff size={"16px"} color="#5E54FF" />}
          </IconButton>
        </InputAdornment>
      );
    }
  };

  return (
    <>
      {label && ( //without this logic, an asterisk appears even if there is no label
        <FormLabel sx={labelStyle} required={required}>
          {label}
        </FormLabel>
      )}

      <StyledTextField
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        label={!input.value && !active && placeholder} //When the field is not focused label will be shown as a placeholder.
        placeholder={!input.value && active && placeholder && `${placeholder}${required && "*"}`} //When the field is focused label will disappear.
        type={password && !showPassword ? "password" : typeNumber ? "number" : "text"}
        error={touched && invalid}
        variant="filled"
        disabled={disabled}
        helperText={!hideError && getHelperText()}
        active={active}
        InputProps={{
          endAdornment: getEndAdornment(),
        }}
        InputLabelProps={{
          style: {color: placeholder && "#C3C2C9"} // Placeholder styling
        }}
        required={required}
        {...input}
        {...props}
      />
    </>
  );
};

export { Input };
