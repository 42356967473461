import React from "react";
import { Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Typography } from "@mui/material";
import { CheckBoxOutlineBlankRounded, CheckBoxRounded, RadioButtonUnchecked, CheckCircle } from "@mui/icons-material";

import { Controller } from "react-hook-form";

const StyledRadio = ({ type, size, ...props }) => {
  return (
    <Radio
      disableRipple
      icon={
        type === "round" ? (
          <RadioButtonUnchecked sx={{ color: "#6A6870" }} />
        ) : (
          <CheckBoxOutlineBlankRounded sx={{ color: "##A6870" }} />
        )
      }
      checkedIcon={
        type === "round" ? <CheckCircle sx={{ color: "#5E54FF" }} /> : <CheckBoxRounded sx={{ color: "#5E54FF" }} />
      }
      size={size}
      {...props}
    />
  );
};

const RadioSelect = ({ name, label, options, type, size, control, disabled, required }) => {

  return (
    <FormControl>
      <FormLabel 
        sx={{ 
            fontSize: "14px",
            color: "#6a6870",
            "&.Mui-focused": {
                color: "#6a6870"
            }
            }}
        >{label}</FormLabel>
      <Controller
        rules={{ required: required }}
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup {...field}>
            {options.map((option) => (
              <FormControlLabel
                disabled={disabled}
                value={option.value}
                label={
                  <Typography
                    fontFamily={"Inter"}
                    fontWeight={400}
                    color={field.value === option.value && "#5E54FF"}
                    paddingTop={option.description ? "20px" : "0px"}
                  >
                    {option.label}
                    {option.description && (
                      <Typography
                        fontFamily="Inter"
                        fontSize={"14px"}
                        fontWeight={400}
                        color={field.value === option.value ? "#5E54FF" : "#6A6870"}
                      >
                        {option.description}
                      </Typography>
                    )}
                  </Typography>
                }
                sx={{
                  "&:hover": {
                    color: "#5E54FF",
                  },
                }}
                control={<StyledRadio type={type} size={size} />}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

export { RadioSelect as ReactHookFormRadioSelect };
