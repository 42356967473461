import { useMediaQuery } from "react-responsive";

export const useIsScreenMobileSmall = () => {
  return useMediaQuery({ maxWidth: 320 });
};

export const useIsScreenMobile = () => {
  return useMediaQuery({ maxWidth: 767 });
};

export const useIsScreenTablet = () => {
  return useMediaQuery({ minWidth: 768, maxWidth: 991 });
};

export const useIsScreenTabletOrDesktop = () => {
  return useMediaQuery({ minWidth: 760 });
};

export const useIsScreenDesktop = () => {
  return useMediaQuery({ minWidth: 992 });
};
