import { Box, Flex, Text, ImageWithCache } from "../fundamentals";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import css from "@styled-system/css";

const ArtworkOverlay = styled(Box)`
  width: 100%;
  padding: 16px;
  background-color: white;
  transition: opacity 0.2s, background-color 0.3s, border-color 0.3s, color 0.3s, padding 0.4s ease-out;
  opacity: 0;
`;

const StyledArtwork = styled(Flex)`
  flex-direction: column;
  flex-shrink: 0;
  white-space: nowrap;
  position: relative;
  border-radius: 2px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
  &:hover {
    cursor: pointer;
    ${ArtworkOverlay} {
      opacity: 0.9;
    }
  }
  ${css({
    padding: "s",
    bg: "white",
    marginRight: "m",
  })};
`;

const FavouriteArtworksGrid = () => {
  const history = useHistory();
  const discoveryState = useSelector((state) => state.discovery);
  const { favourites } = discoveryState;

  const handleArtworkOnClick = (artworkId) => {
    history.push(`/discovery/artworks/favourites/details?artworkId=${artworkId}`, {
      source: history.location.pathname,
    });
  };


  return (
    <Flex flexDirection="column">
      <Flex flexWrap="wrap">
        {_.map(favourites, (artwork, index) => {
          const { images = [] } = artwork;
          const [firstImage = {}] = images;
          return (
            <Flex
              justifyContent="space-between"
              alignItems="start"
              mb="xxl"
              key={artwork?.artworkId?.id}
              onClick={() => handleArtworkOnClick(artwork?.artworkId?.id)}
            >
              {/* Image */}
              <StyledArtwork>
                {artwork?.images?.length ? (
                  <ImageWithCache
                    image={firstImage}
                    artworkId={artwork?.artworkId?.id}
                    urlSuffix="/preview.jpg"
                    maxHeight="176px"
                    userSelect="none"
                    imageKey={artwork?.artworkId?.id}
                  />
                ) : (
                  <Box pt="l" minWidth="130px" height="176px" mb="s">
                    <Text color="grey.60" fontSize="smaller">
                      No image available
                    </Text>
                  </Box>
                )}
                <Flex flexDirection="column" position="absolute" bottom="0" right="0" pointerEvents="none" width="100%">
                  <ArtworkOverlay>
                    <Text mb="xxxs" fontSize="small">
                      {artwork?.artworkId?.artist?.name}
                    </Text>
                    <Flex fontSize="smaller" color="grey.80">
                      <Text
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxWidth={artwork?.artworkId?.artworkValues?.year ? "10ch" : "15ch"}
                      >
                        {artwork?.artworkId?.artworkValues?.title}
                      </Text>
                      {artwork?.artworkId?.artworkValues?.year && (
                        <Text>{`, ${artwork?.artworkId?.artworkValues?.year}`}</Text>
                      )}
                    </Flex>
                  </ArtworkOverlay>
                </Flex>
              </StyledArtwork>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export { FavouriteArtworksGrid };
