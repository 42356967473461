import { Popover as MuiPopover, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { RiVipCrownFill } from "react-icons/ri";
import { Flex, Text, Icon } from "../../fundamentals";
import { openModal } from "../../../store/actions";
import { useDispatch } from "react-redux";

const StyledPopover = styled(({ backgroundColor, borderRadius, props, ...rest }) => (
  <MuiPopover
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...rest}
  />
))((props) => ({
  "& .MuiPaper-root": {
    display: "flex",
    alignItems: "start",
    fontFamily: props.fontFamily ? props.fontFamily : "Inter",
    fontSize: props.fontSize ? props.fontSize : "12px",
    lineHeight: props.lineHeight ? props.lineHeight : "16px",
    borderRadius: props.borderRadius ?? "8px",
    backgroundColor: props.bg ? props.bg : "#FFFBF2",
    color: props.color ? props.color : "black",
    padding: "16px",
    marginTop: "10px",
    width: props.width ? props.width : "250px",
    boxShadow: "0px 12px 24px 0px rgba(0, 0, 0, 0.28)"
  },
}));


const Popover = ({
  popoverAnchorEl,
  setPopoverAnchorEl,
  anchorPosition,
  anchorReference,
  text,
  linkText,
  link,     // Not needed when upgrade property is used, see comment below
  icon,     // Not needed when upgrade property is used, see comment below
  upgrade, // Include this property for the crown icon to be rendered and upgrade modal to open,
            // otherwise use icon property and set it equal to the name of the feather icon you want to use
            // and link property and set it equal to the url where the user should be redirected
  action,
  ...props
}) => {
  const dispatch = useDispatch();

  return (
    <StyledPopover
      anchorEl={popoverAnchorEl}
      anchorReference={anchorReference ? "anchorPosition" : "anchorEl"}
      anchorPosition={anchorPosition && anchorPosition}
      open={Boolean(popoverAnchorEl)}
      onClose={() => setPopoverAnchorEl(null)}
      pl="22px"
    >
      <Flex borderRadius="50%" bg="amber.50" justifyContent="center" alignItems="center" p="xxxs" mr="xs">
        {upgrade ?
        <RiVipCrownFill size="14" color="#FFEFC6" />
        :
        <Icon icon={icon} size="14" color="#FFEFC6"/>  
        }
      </Flex>
      <Text>
        {text}{" "}
        {linkText && 
        <Link
          sx={{
            cursor: "pointer",
            color: "#000000",
          }}
          href={link}
          target="_blank"
          onClick={() => {
            if(action) {
              dispatch(action);
            }
            setPopoverAnchorEl(null);
            if (upgrade) {
              dispatch(openModal("upgrade"));
            }
          }}
        >
          {linkText}
        </Link>}
      </Text>
    </StyledPopover>
  );
};

export { Popover };
