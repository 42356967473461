import React, { useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../store/selectors";

import {
  getPaymentMethod,
  cancelSubscription,
  openModal,
  getSubscription,
  restartSubscription,
} from "../../store/actions";
import { STRIPE_PLANS, stripePromise } from "../../config";

import { Box, Flex, Separator, Text } from "../fundamentals";
import { Section, Button, Modal } from "../elements";
import { ChangePaymentMethodModal, AlertModal, ChangeBillingPeriodModal } from "../modals";
import { Headings, ButtonLabels, Warnings, UiLabels, ApiErrors } from "../../localisation";
import { Elements } from "@stripe/react-stripe-js";

const BillingSettingsScreen = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const accessLevel = user.account.accessLevel;
  const userRole = user.role.charAt(0).toUpperCase() + user.role.slice(1);
  const paymentMethodLoading = useSelector((state) => state.ui.paymentMethodLoading);
  const subscriptionLoading = useSelector((state) => state.ui.subscriptionLoading);

  const currentPlan = _.find(STRIPE_PLANS, (stripePlan) => stripePlan.plan === user.account.activePlan);
  const alternativePlan =
    accessLevel > 0 && (user.account.activePlan.includes("monthly") || user.account.activePlan.includes("yearly"))
      ? _.find(STRIPE_PLANS, (stripePlan) => {
          return stripePlan.plan === currentPlan.alternative_plan;
        })
      : "";
  const subscriptionType = accessLevel > 0 ? currentPlan.label.split(" ").pop() : "Free";
  const billingPeriod =
    accessLevel > 0 ? currentPlan.billing_period.charAt(0).toUpperCase() + currentPlan.billing_period.slice(1) : "";

  const handleUpgradeSubscription = () => {
    dispatch(openModal("upgrade"));
  };

  const handleOnCancelSubscription = () => {
    dispatch(openModal("cancel_subscription_modal"));
  };
  const handleOnChangeBillingPeriod = () => {
    dispatch(openModal("change_billing_period_modal"));
  };
  const handleOnRestartSubscription = () => {
    dispatch(openModal("restart_subscription_modal"));
  };

  useEffect(() => {
    if (accessLevel > 0) {
      dispatch(getPaymentMethod());
      dispatch(getSubscription());
    }
  }, []);

  return (
    <Box flex="1">
      <Text size="heading2" mb="xxl">
        {Headings.subscriptionAndBilling}
      </Text>
      {/* Free Users */}
      {accessLevel == 0 ? (
        <Section
          title={Headings.subscription}
          border="1px solid #C3C2C9"
          borderRadius="10px"
          width="615px"
          p="l"
          mb="m"
        >
          <Flex mb="m" flexDirection="column">
            <Box display="flex">
              <Text size="body" color="grey.90" width="200px">
                {UiLabels.accountType}
              </Text>

              <Text size="body" color="black">
                {userRole}
              </Text>
            </Box>
            <Separator mb="xs" />
            <Box display="flex">
              <Text size="body" color="grey.90" width="200px">
                {UiLabels.subscriptionType}
              </Text>

              <Text size="body" color="black">
                {subscriptionType}
              </Text>
            </Box>
          </Flex>

          <Flex justifyContent="space-between" width="404px">
            <Button
              buttonStyle="primary"
              size="medium"
              fontFamily="Inter"
              fontWeight={500}
              px="s"
              py="xxs"
              flip={true}
              iconOffset="xs"
              onClick={handleUpgradeSubscription}
            >
              {ButtonLabels.upgradeSubscription}
            </Button>
          </Flex>
        </Section>
      ) : (
        // All-Access Users
        <React.Fragment>
          <Section
            title={Headings.cardDetails}
            border="1px solid #C3C2C9"
            borderRadius="10px"
            width="615px"
            mb="m"
            p="l"
          >
            {paymentMethodLoading && <Text>Loading...</Text>}

            {!paymentMethodLoading && !user.account.card && (
              <Flex mb="m">
                <Flex>
                  <Text size="body" color="grey.90">
                    {ApiErrors.failedToGetCardDetails}
                  </Text>
                </Flex>
              </Flex>
            )}

            {!paymentMethodLoading && user.account.card && (
              <Box>
                <Flex mb="m" flexDirection="column">
                  <Box display="flex">
                    <Text size="body" color="grey.90" width="200px">
                      {UiLabels.cardNumber}
                    </Text>
                    <Text size="body" color="black">
                      **** **** **** {user.account.card.lastFourNumbers}
                    </Text>
                  </Box>

                  <Separator />
                  <Box display="flex">
                    <Text size="body" color="grey.90" width="200px">
                      {UiLabels.expirationDate}
                    </Text>
                    <Text size="body" color="black">
                      {("0" + user.account.card.expMonth).slice(-2)} / {user.account.card.expYear.toString().slice(-2)}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            )}
          </Section>

          {!paymentMethodLoading && (
            <Button
              buttonStyle="primary"
              size="medium"
              fontFamily="Inter"
              mb="m"
              px="s"
              py="xxs"
              fontWeight={500}
              iconSpinning={true}
              flip={true}
              iconOffset="xs"
              onClick={() => dispatch(openModal("change_payment_card"))}
            >
              {ButtonLabels.updateCard}
            </Button>
          )}
          <Separator mb="xl" />
          <Section
            title={Headings.subscription}
            border="1px solid #C3C2C9"
            borderRadius="10px"
            width="615px"
            p="l"
            mb="m"
          >
            {subscriptionLoading && <Text>Loading...</Text>}

            {!subscriptionLoading && (
              <Flex mb="m" flexDirection="column">
                <Box display="flex">
                  <Text size="body" color="grey.90" width="200px">
                    {UiLabels.accountType}
                  </Text>

                  <Text size="body" color="black">
                    {userRole}
                  </Text>
                </Box>
                <Separator mb="xs" />
                <Box display="flex">
                  <Text size="body" color="grey.90" width="200px">
                    {UiLabels.subscriptionType}
                  </Text>

                  <Text size="body" color="black">
                    {subscriptionType}
                  </Text>
                </Box>
                <Separator mb="xs" />
                <Box display="flex">
                  <Text size="body" color="grey.90" width="200px">
                    {UiLabels.billingPeriod}
                  </Text>

                  <Text size="body" color="black">
                    {billingPeriod}
                  </Text>
                </Box>
                <Separator mb="xs" />
                <Box display="flex">
                  <Text size="body" color="grey.90" width="200px">
                    {UiLabels.price}
                  </Text>

                  <Text size="body" color="black">
                    {`\u20AC${currentPlan.price} / ${currentPlan.billing_period.replace("ly", "")}`}
                  </Text>
                </Box>
                <Separator mb="xs" />
                {user.account.subscription && (
                  <Box display="flex">
                    <Text size="body" color="grey.90" width="200px">
                      {UiLabels.currentBillingPeriod}
                    </Text>
                    {user.account.subscription && (
                      <Text size="body" color="black">
                        {`${moment(user.account.subscription.create).format("ddd DD MMM YYYY")} 
                        - 
                        ${moment(user.account.subscription.currentPeriodEnd * 1000).format("ddd DD MMM YYYY")}`}
                      </Text>
                    )}
                  </Box>
                )}
              </Flex>
            )}
          </Section>
          {!subscriptionLoading && user.account.subscription && (
            <Flex justifyContent="space-between" width="404px">
              <Button
                buttonStyle="primary"
                size="medium"
                fontFamily="Inter"
                fontWeight={500}
                px="s"
                py="xxs"
                flip={true}
                iconOffset="xs"
                onClick={handleOnChangeBillingPeriod}
              >
                {ButtonLabels.changeBillingPeriod}
              </Button>
              {user.account.activePlan !== "none" && !user.account.subscription.cancelAtPeriodEnd ? (
                <Button
                  buttonStyle="primary"
                  size="medium"
                  fontFamily="Inter"
                  fontWeight={500}
                  px="s"
                  py="xxs"
                  flip={true}
                  iconOffset="xs"
                  onClick={handleOnCancelSubscription}
                >
                  {ButtonLabels.cancelSubscription}
                </Button>
              ) : (
                <Button
                  buttonStyle="primary"
                  size="medium"
                  fontFamily="Inter"
                  fontWeight={500}
                  px="s"
                  py="xxs"
                  flip={true}
                  iconOffset="xs"
                  onClick={handleOnRestartSubscription}
                >
                  {ButtonLabels.restartSubscription}
                </Button>
              )}
            </Flex>
          )}

          {/* Modals for Subscription and Billing Screen */}
          <Modal id="change_payment_card">
            <Elements stripe={stripePromise}>
              <ChangePaymentMethodModal />
            </Elements>
          </Modal>

          <Modal id="cancel_subscription_modal" modalRadius="10px" width="440px">
            <AlertModal
              title={Warnings.cancelSubscription}
              message={Warnings.cancelSubscriptionDesc}
              acceptButtonText={ButtonLabels.cancelSubscriptionConfirmation}
              onAccept={() => {
                dispatch(cancelSubscription());
              }}
            />
          </Modal>

          <Modal id="change_billing_period_modal" modalRadius="10px" width="440px">
            <Elements stripe={stripePromise}>
              <ChangeBillingPeriodModal currentPlan={currentPlan} alternativePlan={alternativePlan} />
            </Elements>
          </Modal>

          <Modal id="restart_subscription_modal">
            <AlertModal
              title={"Restarting your subscription"}
              // message={Warnings.logoutAlertDescription}
              onAccept={() => {
                dispatch(restartSubscription());
              }}
            />
          </Modal>
        </React.Fragment>
      )}
    </Box>
  );
};

export default BillingSettingsScreen;
