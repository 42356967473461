import {
  ADD_SELECTED_ARTWORK,
  REMOVE_SELECTED_ARTWORK,
  REPLACE_SELECTED_ARTWORK,
  CHANGE_SELECTED_COLLECTION,
  CHANGE_SELECTED_CATEGORY,
  SELECT_ALL_ARTWORKS,
  CLEAR_ALL_SELECTED_ARTWORKS,
  ADD_SELECTED_CONTACT,
  REMOVE_SELECTED_CONTACT,
  REPLACE_SELECTED_CONTACT,
  SELECT_ALL_CONTACTS,
  CLEAR_ALL_SELECTED_CONTACTS,
  ADD_SELECTED_ARTIST,
  REMOVE_SELECTED_ARTIST,
  REPLACE_SELECTED_ARTIST,
  SELECT_ALL_ARTISTS,
  CLEAR_ALL_SELECTED_ARTISTS,
  ADD_SELECTED_PAYMENT_INVITE,
  CLEAR_ALL_SELECTED_PAYMENT_INVITES,
  ADD_SELECTED_LIST,
  REMOVE_SELECTED_LIST,
  REPLACE_SELECTED_LIST,
  SELECT_ALL_LISTS,
  CLEAR_ALL_SELECTED_LISTS,
  ADD_SELECTED_VIEWING_ROOM,
  REMOVE_SELECTED_VIEWING_ROOM,
  REPLACE_SELECTED_VIEWING_ROOM,
  SELECT_ALL_VIEWING_ROOMS,
  CLEAR_ALL_SELECTED_VIEWING_ROOMS,
} from "./types";

export const addSelectedArtwork = (artworkId) => {
  return { type: ADD_SELECTED_ARTWORK, payload: { artworkId } };
};

export const removeSelectedArtwork = (artworkId) => {
  return { type: REMOVE_SELECTED_ARTWORK, payload: { artworkId } };
};

export const replaceSelectedArtwork = (artworkId) => {
  return { type: REPLACE_SELECTED_ARTWORK, payload: { artworkId } };
};

export const changeSelectedCollection = (collectionId) => {
  return { type: CHANGE_SELECTED_COLLECTION, payload: { collectionId } };
};

export const changeSelectedCategory = (category) => {
  return { type: CHANGE_SELECTED_CATEGORY, payload: { category } };
};

export const selectAllArtworks = (artworkIds) => {
  return { type: SELECT_ALL_ARTWORKS, payload: { artworkIds } };
};

export const clearAllSelectedArtworks = () => {
  return { type: CLEAR_ALL_SELECTED_ARTWORKS, payload: {} };
};

export const addSelectedContact = (contactId) => {
  return { type: ADD_SELECTED_CONTACT, payload: { contactId } };
};

export const removeSelectedContact = (contactId) => {
  return { type: REMOVE_SELECTED_CONTACT, payload: { contactId } };
};

export const replaceSelectedContact = (contactId) => {
  return { type: REPLACE_SELECTED_CONTACT, payload: { contactId } };
};

export const selectAllContacts = (contactIds) => {
  return { type: SELECT_ALL_CONTACTS, payload: { contactIds } };
};

export const clearAllSelectedContacts = () => {
  return { type: CLEAR_ALL_SELECTED_CONTACTS, payload: {} };
};

export const addSelectedArtist = (artistId) => {
  return { type: ADD_SELECTED_ARTIST, payload: { artistId } };
};

export const removeSelectedArtist = (artistId) => {
  return { type: REMOVE_SELECTED_ARTIST, payload: { artistId } };
};

export const replaceSelectedArtist = (artistId) => {
  return { type: REPLACE_SELECTED_ARTIST, payload: { artistId } };
};

export const selectAllArtists = (artistIds) => {
  return { type: SELECT_ALL_ARTISTS, payload: { artistIds } };
};

export const clearAllSelectedArtists = () => {
  return { type: CLEAR_ALL_SELECTED_ARTISTS, payload: {} };
};

export const addSelectedPaymentInvite = (paymentInviteId) => {
  return { type: ADD_SELECTED_PAYMENT_INVITE, payload: { paymentInviteId } };
};

export const clearAllSelectedPaymentInvites = () => {
  return { type: CLEAR_ALL_SELECTED_PAYMENT_INVITES, payload: {} };
};

export const addSelectedList = (listId) => {
  return { type: ADD_SELECTED_LIST, payload: { listId } };
};

export const removeSelectedList = (listId) => {
  return { type: REMOVE_SELECTED_LIST, payload: { listId } };
};

export const replaceSelectedList = (listId) => {
  return { type: REPLACE_SELECTED_LIST, payload: { listId } };
};

export const selectAllLists = (listIds) => {
  return { type: SELECT_ALL_LISTS, payload: { listIds } };
};

export const clearAllSelectedLists = () => {
  return { type: CLEAR_ALL_SELECTED_LISTS, payload: {} };
};

export const addSelectedViewingRoom = (viewingRoomId) => {
  return { type: ADD_SELECTED_VIEWING_ROOM, payload: { viewingRoomId } };
};

export const removeSelectedViewingRoom = (viewingRoomId) => {
  return { type: REMOVE_SELECTED_VIEWING_ROOM, payload: { viewingRoomId } };
};

export const replaceSelectedViewingRoom = (viewingRoomId) => {
  return { type: REPLACE_SELECTED_VIEWING_ROOM, payload: { viewingRoomId } };
};

export const selectAllViewingRooms = (viewingRoomIds) => {
  return { type: SELECT_ALL_VIEWING_ROOMS, payload: { viewingRoomIds } };
};

export const clearAllSelectedViewingRooms = () => {
  return { type: CLEAR_ALL_SELECTED_VIEWING_ROOMS, payload: {} };
};