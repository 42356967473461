import { BILLING_PERIOD_MONTHLY, BILLING_PERIOD_YEARLY } from "../../../constants";
import {
  Headings,
  Instructions,
  ButtonLabels,
  InputLabels,
  OverviewLabels,
  InputPlaceholders,
  Messages,
  UiLabels,
  DynamicLabels,
} from "../../../localisation";
import { Button, StripeCardNumberInput, StripeCardExpiryInput, StripeCardCvcInput } from "../../elements";
import { Box, Flex, Text } from "../../fundamentals";
import { returnSelectedPlan } from "../../../utils";
import { useIsScreenMobile, useIsScreenDesktop } from "../../hooks/useMediaQuery";
import { selectUser } from "../../../store/selectors";
import { createSubscription } from "../../../store/actions";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm, formValueSelector, startSubmit } from "redux-form";
import { Typography } from "@mui/material";
import moment from "moment";
import { useElements, useStripe } from "@stripe/react-stripe-js";

const Form = ({
  handleSubmit,
  submitting,
  headerTextStyle,
  instructionTextStyle,
  upgradeIcon,
  goToPage,
  plan,
  isUpgradeFromPaymentInvite,
}) => {
  const user = useSelector(selectUser);
  const billingPeriod = useSelector((state) => state.user.billingPeriod);
  const hasDiscount = user.account.hasOwnProperty("discount") && user.account?.discount?.value ? true : false;
  const discountPercentage = user.account?.discount?.value;

  const selectedPlan =
    billingPeriod === BILLING_PERIOD_MONTHLY ? plan : plan.replace(BILLING_PERIOD_MONTHLY, BILLING_PERIOD_YEARLY);
  const isMobile = useIsScreenMobile();
  const isDesktop = useIsScreenDesktop();

  const stripe = useStripe();
  const elements = useElements();

  const submit = (values, dispatch) => {
    if (submitting) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }
    if (hasDiscount && billingPeriod === BILLING_PERIOD_YEARLY) {
      return dispatch(createSubscription.request({ stripe, elements, plan: selectedPlan, isUpgradeFromPaymentInvite, coupon: user.account.discount.code}));
    } else {
      return dispatch(createSubscription.request({ stripe, elements, plan: selectedPlan, isUpgradeFromPaymentInvite}));
    }

  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Flex alignItems="center" marginBottom="16px">
        {upgradeIcon}
        <Text style={instructionTextStyle(isMobile)}>{Instructions.upgradeToAllAccess}</Text>
      </Flex>

      {/* Plan Overview */}
      <Typography sx={headerTextStyle(isMobile)}>{Headings.confirmUpgrade}</Typography>
      <Flex p="m" border="1px solid #C8CEFF" borderRadius="10px" mb="xxs">
        <Box fontSize="small" mr="12px">
          <Text>{OverviewLabels.accountType}</Text>
          <Text>{OverviewLabels.updatedPlan}</Text>
          <Text>{OverviewLabels.billingPeriod}</Text>
        </Box>
        <Box fontSize="small" fontWeight="500" color="blue.60">
          <Text>{OverviewLabels[user.role]}</Text>
          <Text>{!plan?.includes("free") && OverviewLabels.allAccessPlan}</Text>
          <Text>{billingPeriod && billingPeriod.charAt(0).toUpperCase() + billingPeriod.slice(1)}</Text>
        </Box>
      </Flex>
      <Button
        type="button"
        buttonStyle="formBack"
        size="small"
        icon="ArrowLeft"
        pl="none"
        mb="38px"
        fontFamily="body"
        onClick={() => goToPage("billingPeriod")}
      >
        {ButtonLabels.backToChangePlan}
      </Button>

      {/* Credit Card Details */}
      <Text fontSize="small" fontWeight="500" mb="m">
        {InputLabels.creditCardDetails}
      </Text>
      <Field
        name="cardNumber"
        height={isDesktop ? "50px" : "32px"}
        component={StripeCardNumberInput}
        options={{ placeholder: InputPlaceholders.creditCardNumber }}
      />
      <Flex justifyContent="space-between" mt="xs">
        <Field name="cardExpiryDate" height={isDesktop ? "50px" : "32px"} component={StripeCardExpiryInput} />
        <Field name="cardCvc" height={isDesktop ? "50px" : "32px"} component={StripeCardCvcInput} />
      </Flex>
      <Flex justifyContent="space-between" mt="l" mb="xxs" fontSize="small" fontWeight="500">
        <Text>{UiLabels.dueNow}</Text>
        <Text>
          {hasDiscount && billingPeriod === BILLING_PERIOD_YEARLY
            ? "\u20ac" +
              Math.round((returnSelectedPlan(selectedPlan).price - returnSelectedPlan(selectedPlan).price * (discountPercentage / 100)) * 10) / 10
            : "\u20ac" + returnSelectedPlan(selectedPlan).price}
        </Text>
      </Flex>
      <Text fontSize="small" color="grey.60" mb="l">
        {plan &&
          `${Messages.automaticRenewMessage(
            DynamicLabels.planRenewalDate(moment().add(returnSelectedPlan(selectedPlan).daysTillRenewal, "days"))
          )}`}
      </Text>
      <Button
        type="submit"
        buttonStyle="form"
        size={isMobile ? "small" : "medium"}
        fontSize={isMobile ? "13px" : "16px"}
        icon={submitting ? "Loader" : null}
        disabled={submitting}
        mt="xs"
      >
        {ButtonLabels.payAndUpgrade}
      </Button>
    </form>
  );
};

let PaymentForm = reduxForm({
  form: "upgrade",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Form);

const selector = formValueSelector("upgrade");

function mapStateToProps(state) {
  const plan = selector(state, "plan");
  return { plan, user: state.user };
}

PaymentForm = connect(mapStateToProps, null)(PaymentForm);

export { PaymentForm };
