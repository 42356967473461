import React, { useEffect } from "react";
import styled from "styled-components";
import { Box, Flex, Image, Text, Link } from "../fundamentals";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { selectViewingRoomById } from "../../store/selectors";
import { checkViewingRoomForPassword, openModal } from "../../store/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { UiLabels } from "../../localisation";
import { ViewingRoomArtworksGrid } from "../modules";
import { Modal } from "../elements";
import { ViewingRoomPasswordModal, AlertModal } from "../modals";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { DateTime } from "luxon";

const StyledPublicViewingRoomScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const PublicViewingRoomScreen = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useIsScreenMobile();
  const params = new URLSearchParams(location.search);
  const viewingRoomState = useSelector((state) => state.viewing_room);
  const viewingRoomId = params.get("viewingRoomId");
  const viewingRoom = useSelector((state) => selectViewingRoomById(state, viewingRoomId));
  const nameDisplayed = viewingRoom?._user?.hasOwnProperty("organisation")
    ? viewingRoom?._user?.organisation?.name
    : viewingRoom?._user?.name;
  const expiryDate = DateTime.fromISO(viewingRoom?.dateValid);

  useEffect(() => {
    if (!viewingRoom || !viewingRoom?.password || !viewingRoom?.artworks[0]?._id?.artworkValues) {
      dispatch(checkViewingRoomForPassword(viewingRoomId));
    }
  }, []);

  return (
    <StyledPublicViewingRoomScreen>
      <Helmet>
        <title>Viewing Room | simplify.art</title>
        <meta name="description" content="Sign in to your simplify.art account to access your collection" />
      </Helmet>
      <Box>
        {/* Header */}
        <Flex
          padding={isMobile ? "12px 20px" : "24px 48px"}
          borderBottom={!isMobile && "2px solid #E0DFE3"}
          boxShadow={isMobile && "0px 2px 8px 0px rgba(0, 0, 0, 0.16)"}
        >
          <Text fontFamily="bodyDmSans" fontWeight="700" fontSize="small" mr="xs">
            {UiLabels.poweredBy}
          </Text>
          <Link href="https://www.simplify.art" target="_blank">
            <Image
              src={process.env.PUBLIC_URL + `/images/brand-identity/logo_horizontal-black.svg`}
              alt="logo"
              width="100px"
            />
          </Link>
        </Flex>
        {/* Main */}
        {viewingRoomState.isLoading ? (
          <Flex justifyContent="center" alignItems="center" pb="mega" height="100vh">
            <CircularProgress sx={{ color: "#5E54FF" }} />
          </Flex>
        ) : (
          <Box>
            {/* Deleted Viewing Room */}
            {(viewingRoom?.isDeleted || DateTime.now() > expiryDate) && (
              <Flex justifyContent="center" alignItems="center" pb="mega" height="100vh">
                <Text>This Viewing Room is no longer available</Text>
              </Flex>
            )}
            {(!viewingRoom || !viewingRoom?.artworks[0]?._id?.artworkValues) && (
              <Flex justifyContent="center" alignItems="center" pb="mega" height="100vh">
                <Text>Access Denied</Text>
              </Flex>
            )}
            {/* Active Viewing Room */}
            {!viewingRoom?.isDeleted && viewingRoom?.isActive && (
              <Box padding={isMobile ? "24px 20px" : "24px 48px"} borderBottom={isMobile && "1px solid #E8E7EA"}>
                <Text fontFamily="Inter" mb="xxxs">
                  {nameDisplayed}
                </Text>
                <Text fontFamily="bodyDmSans" fontSize={isMobile ? "heading2" : "heading1"}>
                  {viewingRoom?.title}
                </Text>
              </Box>
            )}
            {/* Non-active Viewing Room */}
            {!viewingRoom?.isDeleted && !viewingRoom?.isActive && (
              <Flex justifyContent="center" alignItems="center" pb="mega" height="100vh">
                <Text>This Viewing Room is not active</Text>
              </Flex>
            )}

            <Box pr={!isMobile && "xxl"} pl={!isMobile && "xxl"} padding={isMobile && "s"}>
              <ViewingRoomArtworksGrid />
            </Box>
          </Box>
        )}
        <Modal id="viewing_room_password">
          <ViewingRoomPasswordModal viewingRoomId={viewingRoomId} />
        </Modal>
        <Modal id="viewing_room_resubmit_password">
          <AlertModal
            title="Incorrect Password"
            message="Please get the correct password and try again"
            acceptButtonText="Try again"
            onAccept={() => dispatch(openModal("viewing_room_password"))}
          />
        </Modal>
      </Box>
    </StyledPublicViewingRoomScreen>
  );
};

export default PublicViewingRoomScreen;
