import {
  SYNC_USER,
  CREATE_ONBOARDING_SUBSCRIPTION,
  CREATE_FREE_USER,
  CREATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  CHANGE_SUBSCRIPTION,
  CHANGE_SUBSCRIPTION_SUCCESS,
  CHANGE_SUBSCRIPTION_FAILURE,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_FAILURE,
  CHANGE_PAYMENT_METHOD,
  UPDATE_USER_DETAILS,
  UPDATE_ORGANISATION,
  DELETE_USER_ACCOUNT,
  DELETE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_ACCOUNT_FAILURE,
  UNSEEN_SHARE_RECORDS,
  SEEN_ALL_SHARE_RECORDS,
  EXPIRED_PAYMENT_METHOD,
  RESTART_SUBSCRIPTION,
  RESTART_SUBSCRIPTION_SUCCESS,
  RESTART_SUBSCRIPTION_FAILURE,
  UPDATE_BILLING_PERIOD,
  UPDATE_URL_ROLE,
  UPDATE_URL_TYPE,
  CONTACT_US,
  PAYMENT_INVITE_BETA_REQUEST,
  PAYMENT_INVITE_BETA_REQUEST_SUCCESS,
  PAYMENT_INVITE_BETA_REQUEST_FAILURE,
  SIGN_UP_BETA,
  GET_DISCOUNT_REQUEST,
  GET_DISCOUNT_SUCCESS,
  GET_DISCOUNT_FAILURE,
} from "./types";
import { createFormAction } from "redux-form-saga";

export const createSubscription = createFormAction(CREATE_SUBSCRIPTION);

export const signUpBeta = createFormAction(SIGN_UP_BETA);

export const createOnboardingSubscription = createFormAction(CREATE_ONBOARDING_SUBSCRIPTION);

export const createFreeUser = createFormAction(CREATE_FREE_USER);

export const expiredPaymentMethod = createFormAction(EXPIRED_PAYMENT_METHOD);

export const contactUs = createFormAction(CONTACT_US);

export const getSubscription = () => {
  return { type: GET_SUBSCRIPTION };
};

export const getSubscriptionSuccess = (subscription) => {
  return { type: GET_SUBSCRIPTION_SUCCESS, payload: { ...subscription } };
};

export const getSubscriptionFailure = () => {
  return { type: GET_SUBSCRIPTION_FAILURE };
};

export const cancelSubscription = () => {
  return { type: CANCEL_SUBSCRIPTION };
};

export const cancelSubscriptionSuccess = () => {
  return { type: CANCEL_SUBSCRIPTION_SUCCESS };
};

export const cancelSubscriptionFailure = () => {
  return { type: CANCEL_SUBSCRIPTION_FAILURE };
};

export const restartSubscription = () => {
  return { type: RESTART_SUBSCRIPTION };
};

export const restartSubscriptionSuccess = () => {
  return { type: RESTART_SUBSCRIPTION_SUCCESS };
};

export const restartSubscriptionFailure = () => {
  return { type: RESTART_SUBSCRIPTION_FAILURE };
};

export const changeSubscription = (newPlan, stripe) => {
  return { type: CHANGE_SUBSCRIPTION, payload: { newPlan, stripe } };
};

export const changeSubscriptionSuccess = () => {
  return { type: CHANGE_SUBSCRIPTION_SUCCESS };
};

export const changeSubscriptionFailure = () => {
  return { type: CHANGE_SUBSCRIPTION_FAILURE };
};

export const getPaymentMethod = () => {
  return { type: GET_PAYMENT_METHOD };
};

export const getPaymentMethodSuccess = (paymentMethod) => {
  return { type: GET_PAYMENT_METHOD_SUCCESS, payload: { ...paymentMethod } };
};

export const getPaymentMethodFailure = () => {
  return { type: GET_PAYMENT_METHOD_FAILURE };
};

export const changePaymentMethod = createFormAction(CHANGE_PAYMENT_METHOD);

export const updateUserDetails = createFormAction(UPDATE_USER_DETAILS);

export const updateOrganisation = createFormAction(UPDATE_ORGANISATION);

export const deleteUserAccount = () => {
  return { type: DELETE_USER_ACCOUNT };
};

export const deleteUserAccountSuccess = () => {
  return { type: DELETE_USER_ACCOUNT_SUCCESS };
};

export const deleteUserAccountFailure = () => {
  return { type: DELETE_USER_ACCOUNT_FAILURE };
};

export const syncUser = (user) => {
  return { type: SYNC_USER, payload: { user } };
};

export const unseenShareRecords = () => {
  return { type: UNSEEN_SHARE_RECORDS };
};

export const seenAllShareRecords = () => {
  return { type: SEEN_ALL_SHARE_RECORDS };
};

export const updateBillingPeriod = (billingPeriod) => {
  return { type: UPDATE_BILLING_PERIOD, payload: { billingPeriod }};
};

export const updateUrlRole = (urlRole) => {
  return { type: UPDATE_URL_ROLE, payload: { urlRole }};
};

export const updateUrlType = (urlType) => {
  return { type: UPDATE_URL_TYPE, payload: { urlType }};
};

export const paymentInviteBetaRequest = () => {
  return { type: PAYMENT_INVITE_BETA_REQUEST }
};

export const paymentInviteBetaRequestSuccess = () => {
  return { type: PAYMENT_INVITE_BETA_REQUEST_SUCCESS }
};

export const paymentInviteBetaRequestFailure = () => {
  return { type: PAYMENT_INVITE_BETA_REQUEST_FAILURE }
};

export const getDiscountRequest = (discountCode) => {
  return { type: GET_DISCOUNT_REQUEST, payload: discountCode };
};

export const getDiscountSuccess = ({code, percentage}) => {
  return { type: GET_DISCOUNT_SUCCESS, payload: {code, percentage} };
};

export const getDiscountFailure = () => {
  return { type: GET_DISCOUNT_FAILURE, payload: [] };
};

