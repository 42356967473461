import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { Field, change } from "redux-form";
import { useDropzone } from "react-dropzone";
import DropboxChooser from "react-dropbox-chooser";
import { openModal, cacheImage, removeCacheImage } from "../../store/actions";
import { selectUser } from "../../store/selectors";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { Box, Flex, Text, ImageWithCache, Icon } from "../fundamentals";
import { Button, Input } from "../elements";
import {
  ButtonLabels,
  UiLabels,
  InputLabels,
  Messages,
  LinkLabels,
  FeaturesInfo,
  InputErrors,
} from "../../localisation";
import { dataURLtoBlob } from "../../utils/image";
import { Popover } from "../react-material/Popover";
import { Tooltip } from "@mui/material";

const Handle = SortableHandle(() => (
  <Box pr="m" minWidth="24px">
    <Flex cursor="move">
      <Icon icon="MoreVertical" size="16" />
      <Icon icon="MoreVertical" size="16" ml="-10px" />
    </Flex>
  </Box>
));

const SortableImageItem = SortableElement(
  ({
    field,
    fieldIndex,
    image,
    urlSuffix,
    handleOnRemoveImage,
    handleOnChangeImageDescription,
    handleOnSetAsCover,
    disable, //SortedElement does not allow the "disabled" prop to be passed into it
    isLogoUpload,
    isDocumentUpload,
  }) => {
    return (
      <Flex
        justifyContent="space-between"
        key={image.key}
        alignItems="center"
        bg="grey.10"
        mb="xxxs"
        p="xxs"
        pl="m"
        pr="m"
      >
        {!disable && !isDocumentUpload && <Handle />}

        {isDocumentUpload ? (
          <Text fontSize="smaller" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth="55ch">
            {image.name}
          </Text>
        ) : (
          <ImageWithCache image={image} urlSuffix={urlSuffix} width="100px" isLogo={isLogoUpload} />
        )}

        {!isLogoUpload && !isDocumentUpload && (
          <Flex flexDirection="column" flex="1" ml="l" mt="xxs">
            <Input
              label={InputLabels.caption}
              focusBackground="grey.0"
              input={{
                value: image.description,
                onChange: (event) => {
                  handleOnChangeImageDescription(event, field);
                },
              }}
              disabled={disable}
              hideError
            />
          </Flex>
        )}

        {!disable && fieldIndex === 0 && !isDocumentUpload && (
          <Flex ml="xxxl" mr="xl" pl="none" pr="none" width="85px" justifyContent="flex-end">
            <Icon size="18" color="black" icon="Check" pr="xxxs" />
            <Text size="small" color="black">
              {UiLabels.cover}
            </Text>
          </Flex>
        )}

        {!disable && fieldIndex !== 0 && !isDocumentUpload && (
          <Button
            buttonStyle="text"
            size="small"
            pl="xxxl"
            pr="xl"
            alignSelf="stretch"
            onClick={() => handleOnSetAsCover(image)}
            preventDefault
          >
            {ButtonLabels.setAsCover}
          </Button>
        )}

        {!disable && (
          <Flex alignItems="center">
            <Button
              buttonStyle="text"
              size="medium"
              icon="X"
              color="grey.80"
              alignSelf="center"
              onClick={() => handleOnRemoveImage(image)}
              preventDefault
            />
          </Flex>
        )}
      </Flex>
    );
  }
);

const SortableImageList = SortableContainer(
  ({
    fields,
    urlSuffix,
    handleOnRemoveImage,
    handleOnSetAsCover,
    handleOnChangeImageDescription,
    disabled,
    isLogoUpload,
    isDocumentUpload,
  }) => {
    return (
      <Box>
        {fields.map((field, index) => {
          const image = fields.get(index);
          return (
            <Field
              key={image.key}
              index={index}
              sortIndex={index}
              name={field}
              field={field}
              fieldIndex={index}
              image={image}
              disable={disabled}
              urlSuffix={urlSuffix}
              handleOnRemoveImage={handleOnRemoveImage}
              handleOnChangeImageDescription={handleOnChangeImageDescription}
              handleOnSetAsCover={handleOnSetAsCover}
              isLogoUpload={isLogoUpload}
              component={SortableImageItem}
              isDocumentUpload={isDocumentUpload}
            />
          );
        })}
      </Box>
    );
  }
);

const CsvFile = ({ fields, handleOnRemoveImage }) => {
  const csvFile = fields.get(0);
  const [hover, setHover] = useState(false);

  const formatFileSize = (bytes, decimalPoint = 2) => {
    if (bytes == 0) return "0 Bytes";
    const k = 1000;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint)) + " " + sizes[i];
  };

  return (
    <Flex
      border="1px dashed #5E54FF"
      justifyContent="center"
      alignItems="center"
      padding="xxl"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Flex backgroundColor="blue.10" borderRadius="8px" padding="xs" position="relative">
        <Icon icon="FileText" color="blue.40" size="46" strokeWidth="1px" mr="xxs" />
        <Box>
          <Flex mb="xxxs" justifyContent="space-between">
            <Text fontSize="small">{csvFile.name}</Text>
            <Icon icon="CheckCircle" size="16" color="blue.60" />
          </Flex>
          <Text mb="xxxs" fontSize="smaller" color="grey.80">
            {formatFileSize(csvFile.size)}
          </Text>
          <Box backgroundColor="blue.60" width="314px" height="4px" borderRadius="100px" />
        </Box>
        {hover && (
          <Icon
            icon="XCircle"
            backgroundColor="blue.20"
            color="blue.50"
            size="14"
            borderRadius="50%"
            padding="xxxs"
            position="absolute"
            top="-12px"
            right="-10px"
            cursor={hover ? "pointer" : undefined}
            strokeWidth="2.5px"
            onClick={() => handleOnRemoveImage(csvFile)}
          />
        )}
      </Flex>
    </Flex>
  );
};

const ImageUpload = ({
  form,
  fields,
  urlSuffix,
  maxImages,
  width,
  emptyHeight,
  label,
  minimal,
  contained,
  disabled,
  sortingDisabled,
  withDropbox,
  isLogoUpload,
  isDocumentUpload,
  isCsvUpload,
  meta: { error, submitFailed },
}) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [upgradeDropboxAnchorEl, setUpgradeDropboxAnchorEl] = useState(null);
  const [hoverPdfContainer, setHoverPdfContainer] = useState(false);
  const currentUser = useSelector(selectUser);
  const dispatch = useDispatch();
  const [csvError, setCsvError] = useState(null);

  const onDropForLogo = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        const key = uuid();
        const localId = uuid();
        const connectionId = uuid();
        const sortIndex = 0;

        const image = {
          key,
          localId,
          connectionId,
          name: file.name,
          path: file.path,
          type: file.type,
          url: "",
          description: "",
          sortIndex: sortIndex,
          dateCreated: new Date().toISOString(),
          isFromServer: false,
          isModified: false,
          isActive: true,
        };

        fields.remove(sortIndex);
        fields.insert(sortIndex, image);

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          dispatch(cacheImage(localId, reader.result, URL.createObjectURL(file)));
        };
      });
    },
    [fields]
  );
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        const key = uuid();
        const localId = uuid();
        const connectionId = uuid();
        const sortIndex = acceptedFiles.length > 1 ? fields.length + index : fields.length;

        const image = {
          key,
          localId,
          connectionId,
          name: file.name,
          path: file.path,
          type: file.type,
          url: "",
          description: "",
          sortIndex: sortIndex,
          dateCreated: new Date().toISOString(),
          isFromServer: false,
          isModified: false,
          isActive: true,
          size: file.size,
        };

        fields.insert(sortIndex, image);

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          dispatch(cacheImage(localId, reader.result, URL.createObjectURL(file)));
        };
      });
    },
    [fields]
  );

  const { fileRejections, getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: !isLogoUpload ? onDrop : onDropForLogo,
    maxFiles: maxImages ? maxImages : 0,
    accept: isCsvUpload ? ".csv" : isDocumentUpload ? "application/pdf" : "image/jpeg, image/png",
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (isCsvUpload && fileRejections.length > 0) {
      setCsvError(fileRejections[0].errors[0].code);
    }
  }, [fileRejections]);

  // For users with a free account
  const handleAddImagesFree = (e) => {
    setPopoverAnchorEl(e.currentTarget);
  };

  // Not currently working
  // const handleAddImagesHoverFree = (e) => {
  //   setPopoverAnchorEl(e.currentTarget);
  //   setMouseX(e.clientX);
  //   setMouseY(e.clientY);
  // }

  const handleOnAddImagesFromDropboxFree = (e) => {
    setUpgradeDropboxAnchorEl(e.currentTarget);
  };

  const handleOnAddImagesFromDropbox = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file, index) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "arraybuffer";
        xhr.open("GET", file.link, true);

        const key = uuid();
        const localId = uuid();
        const connectionId = uuid();
        const sortIndex = acceptedFiles.length > 1 ? fields.length + index : fields.length;

        const image = {
          key,
          localId,
          connectionId,
          name: file.name,
          path: file.path,
          type: file.type,
          url: "",
          description: "",
          sortIndex: sortIndex,
          dateCreated: new Date().toISOString(),
          isFromServer: false,
          isModified: false,
          isActive: true,
        };

        fields.insert(sortIndex, image);

        xhr.onabort = () => console.log("file reading was aborted");
        xhr.onerror = () => console.log("file reading has failed");
        xhr.onload = function (e) {
          // Create an array of 8-bit unsigned integers
          var arr = new Uint8Array(this.response);

          // String.fromCharCode returns a 'string' from the specified sequence of Unicode values
          let raw = "";
          let i;
          let j;
          let subArray;
          let chunk = 5000;
          for (i = 0, j = arr.length; i < j; i += chunk) {
            subArray = arr.subarray(i, i + chunk);
            raw += String.fromCharCode.apply(null, subArray);
          }

          //btoa() creates a base-64 encoded ASCII string from a String object
          var b64 = btoa(raw);

          var dataType = "yourImageDataType";
          //ta-da your image data url!
          var dataURL = "data:image/" + dataType + ";base64," + b64;

          const imageBlob = dataURLtoBlob(dataURL);
          dispatch(cacheImage(localId, dataURL, URL.createObjectURL(imageBlob)));
        };

        xhr.send();
      });
    },
    [fields]
  );

  // useEffect(() => () => {
  //   // Make sure to revoke the data uris to avoid memory leaks
  //   fields.getAll().forEach(image => URL.revokeObjectURL(image.url));
  // });

  const handleOnPreviewImage = () => {
    dispatch(openModal("image_viewer"));
  };

  const handleOnSetAsCover = (image) => {
    fields.forEach((field, index) => {
      dispatch(
        change(form, field, ({ sortIndex, ...fieldValue }) => {
          if (image.sortIndex === index) return { ...fieldValue, sortIndex: 0 };
          else if (image.sortIndex < index) return { ...fieldValue, sortIndex: sortIndex };
          else return { ...fieldValue, sortIndex: sortIndex + 1 };
        })
      );
    });
    fields.move(image.sortIndex, 0);
  };

  const handleOnRemoveImage = async (image) => {
    dispatch(removeCacheImage(image.localId));

    if (image.sortIndex + 1 !== fields.length) {
      fields.forEach((field, index) => {
        if (image.sortIndex === index) {
          return;
        }

        dispatch(
          change(form, field, ({ sortIndex, ...fieldValue }) => {
            if (sortIndex === 0) return { ...fieldValue, sortIndex: 0 };
            else if (image.sortIndex > index) return { ...fieldValue, sortIndex: sortIndex };
            else return { ...fieldValue, sortIndex: sortIndex - 1 };
          })
        );
      });
    }

    fields.remove(image.sortIndex);
  };

  const handleOnChangeImageDescription = (event, field) => {
    dispatch(
      change(form, field, ({ description, ...fieldValue }) => {
        return { ...fieldValue, description: event };
      })
    );
  };

  const handleOnSortEnd = ({ oldIndex, newIndex }) => {
    fields.forEach((field, index) => {
      dispatch(
        change(form, field, ({ sortIndex, ...fieldValue }) => {
          if (sortIndex === oldIndex) return { ...fieldValue, sortIndex: newIndex };
          else if (sortIndex <= oldIndex && sortIndex >= newIndex) return { ...fieldValue, sortIndex: sortIndex + 1 };
          else if (sortIndex >= oldIndex && sortIndex <= newIndex) return { ...fieldValue, sortIndex: sortIndex - 1 };
          else return { ...fieldValue, sortIndex: sortIndex };
        })
      );
    });
    fields.move(oldIndex, newIndex);
  };
  return (
    <Flex flexDirection="column" width={width} mb="m">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {(isDocumentUpload && !isCsvUpload) || (isCsvUpload && fields.length === 0) ? (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p="48px 0px"
            bg={hoverPdfContainer && !isDragActive ? "grey.10" : isDragActive ? "blue.10" : "white"}
            border="1px dashed #5E54FF"
            onMouseEnter={() => setHoverPdfContainer(true)}
            onMouseLeave={() => setHoverPdfContainer(false)}
            cursor={isDragActive && "copy"}
          >
            <Icon
              icon="Upload"
              size="30"
              strokeWidth="1.5"
              color={hoverPdfContainer || isDragActive ? "white" : "grey.40"}
              bg={hoverPdfContainer && !isDragActive ? "blue.40" : isDragActive ? "blue.60" : "grey.10"}
              borderRadius="50%"
              p="l"
              mb="m"
            />
            <Text size="small" color="black" mt="xxs">
              {isCsvUpload ? "Drag & drop your CSV file here." : "Drag & drop PDF files here or click below to select."}
            </Text>
            {csvError && (
              <Flex color="red" mt="s">
                <Icon icon="AlertCircle" size="12" mr="xxxs" color="red"/>
                <Text fontSize="smaller">
                  {csvError === "too-many-files"
                    ? InputErrors.maxOneFile
                    : csvError === "file-invalid-type"
                    ? InputErrors.wrongFileTypeCsv
                    : csvError}
                </Text>
              </Flex>
            )}
            <Flex mt="m">
              <Button
                buttonStyle="secondary"
                size="small"
                icon="Plus"
                fontWeight="500"
                alignSelf="center"
                onClick={open}
                preventDefault
              >
                {ButtonLabels.selectFromComputer}
              </Button>
              <Button
                buttonStyle="primary"
                size="small"
                icon="Box"
                ml="xs"
                preventDefault
                fontWeight="500"
                onClick={handleOnAddImagesFromDropboxFree}
              >
                {ButtonLabels.addDropbox}
              </Button>
            </Flex>
          </Flex>
        ) : isCsvUpload && fields.length > 0 ? (
          <CsvFile fields={fields} handleOnRemoveImage={handleOnRemoveImage} />
        ) : (
          <Tooltip
            title={currentUser.account.accessLevel === 0 ? "Free access allows you to only upload one image" : ""}
            followCursor={true}
            placement="left"
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              // height={emptyHeight || "200px"}
              p="48px 0px"
              bg={currentUser.account.accessLevel === 0 ? "white" : error ? "#FFEBED" : "blue.10"}
              border={error ? "1px dashed red" : "1px dashed #5E54FF"}
              cursor={disabled ? "no-drop" : "copy"}
            >
              {!minimal && (
                <Icon
                  icon="Upload"
                  size="30"
                  strokeWidth="1.5"
                  color={currentUser.account.accessLevel === 0 ? "grey.40" : "white"}
                  bg={currentUser.account.accessLevel === 0 ? "grey.10" : "blue.60"}
                  borderRadius="50%"
                  p="l"
                  mb="m"
                />
              )}
              <Text size="small" color="black" mb="m" mt="xxs">
                {"Drag & drop some files here, or click to select files"}
              </Text>
              {/* Users with a free account */}
              {contained && !disabled && currentUser.account.accessLevel === 0 && (
                <Flex>
                  <Button
                    buttonStyle="secondary"
                    size="small"
                    icon="Plus"
                    alignSelf="center"
                    onClick={open}
                    preventDefault
                  >
                    {form.includes("payment_invite") ? ButtonLabels.addFile : ButtonLabels.addImages}
                  </Button>
                  <Button
                    buttonStyle="secondary"
                    size={"small"}
                    icon="Box"
                    ml="xs"
                    preventDefault
                    onClick={handleOnAddImagesFromDropboxFree}
                  >
                    {ButtonLabels.addDropbox}
                  </Button>
                  <Popover
                    popoverAnchorEl={upgradeDropboxAnchorEl}
                    setPopoverAnchorEl={setUpgradeDropboxAnchorEl}
                    text={Messages.upgradeMessage(FeaturesInfo.dropboxImages, "")}
                    linkText={LinkLabels.checkOutPaidSubscription}
                    upgrade
                  />
                </Flex>
              )}
              {/* Users with an all-access account */}
              {contained && !disabled && currentUser.account.accessLevel > 0 && (
                <Flex>
                  <Button
                    buttonStyle="secondary"
                    size="small"
                    icon="Plus"
                    alignSelf="center"
                    onClick={open}
                    preventDefault
                  >
                    {form.includes("payment_invite") ? ButtonLabels.addFile : ButtonLabels.addImages}
                  </Button>
                  {withDropbox && (
                    <DropboxChooser
                      appKey={process.env.REACT_APP_DROPBOX_APP_KEY}
                      success={handleOnAddImagesFromDropbox}
                      cancel={() => {}}
                      extensions={[".jpg", ".jpeg", ".png"]}
                      linkType="direct"
                      multiselect={true}
                    >
                      <Button buttonStyle="secondary" size={"small"} icon="Box" ml="xs" preventDefault>
                        {ButtonLabels.addDropbox}
                      </Button>
                    </DropboxChooser>
                  )}
                </Flex>
              )}
              {/* Users with a free account after ImageUpload has been disabled */}
              {contained && disabled && (
                <Flex>
                  <Button
                    buttonStyle="secondary"
                    size="small"
                    icon="Plus"
                    alignSelf="center"
                    onClick={handleAddImagesFree}
                    preventDefault
                  >
                    {form.includes("payment_invite") ? ButtonLabels.addFile : ButtonLabels.addImages}
                  </Button>
                  <Popover
                    popoverAnchorEl={popoverAnchorEl}
                    setPopoverAnchorEl={setPopoverAnchorEl}
                    text={Messages.upgradeMessage(FeaturesInfo.addingMultiImages, FeaturesInfo.multiImagesFeatDesc)}
                    linkText={LinkLabels.checkOutPaidSubscription}
                    upgrade
                  />
                  <Button
                    buttonStyle="secondary"
                    size={"small"}
                    icon="Box"
                    ml="xs"
                    preventDefault
                    onClick={handleOnAddImagesFromDropboxFree}
                  >
                    {ButtonLabels.addDropbox}
                  </Button>
                  <Popover
                    popoverAnchorEl={upgradeDropboxAnchorEl}
                    setPopoverAnchorEl={setUpgradeDropboxAnchorEl}
                    text={Messages.upgradeMessage(FeaturesInfo.dropboxImages, "")}
                    linkText={LinkLabels.checkOutPaidSubscription}
                    upgrade
                  />
                </Flex>
              )}
            </Flex>
          </Tooltip>
        )}
      </div>

      {!isCsvUpload && (
        <SortableImageList
          fields={fields}
          handleOnRemoveImage={handleOnRemoveImage}
          handleOnSetAsCover={handleOnSetAsCover}
          onSortEnd={handleOnSortEnd}
          handleOnChangeImageDescription={handleOnChangeImageDescription}
          urlSuffix={urlSuffix}
          lockAxis="y"
          lockToContainerEdges
          lockOffset={["0%", "100%"]}
          disabled={sortingDisabled}
          useDragHandle
          isLogoUpload={isLogoUpload}
          isDocumentUpload={isDocumentUpload}
        />
      )}
      {error && submitFailed && (
        <Text color="red" fontSize="smaller" mt="xxs">
          {error}
        </Text>
      )}
    </Flex>
  );
};

export { ImageUpload };
