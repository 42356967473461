import {QR_SOURCE, SEND_EVENT} from "./types";

export const sendQrSource = ({source, whereTo}) => {
  return {
    type: QR_SOURCE,
    payload: {
      source,
      whereTo
    }
  };
};

export const sendEvent = ({eventName, message, userId}) => {
  return {
    type: SEND_EVENT,
    payload: {
      eventName,
      message,
      userId
    }
  };
};