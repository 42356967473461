import produce from "immer";
import {
  ADD_ACTION_TO_QUEUE,
  REMOVE_ACTION_FROM_QUEUE,
  ADD_ACTION_TO_DELAY_QUEUE,
  REMOVE_ACTION_FROM_DELAY_QUEUE,
  EXECUTE_ACTION_IN_QUEUE,
  EXECUTE_ACTION_IN_QUEUE_SUCCESS,
  EXECUTE_ACTION_IN_QUEUE_FAILURE,
  REMOVE_FAILED_ACTION_FROM_QUEUE,
  DELETE_ARTWORKS,
  DELETE_ARTWORK_SUCCESS,
  SIGN_OUT_SUCCESS,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACTS,
  DELETE_ARTIST_SUCCESS,
  DELETE_ARTISTS,
  DELETE_LISTS,
  DELETE_LIST_SUCCESS
} from "../actions/types";

const QUEUE_INITIAL_STATE = {
  actionQueue: [],
  delayQueue: [],
  executingActions: {
    byId: {},
  },
  failedActionQueue: [],
};

const queueReducer = (state = QUEUE_INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case ADD_ACTION_TO_QUEUE:
        return addActionToQueue(draftState, action);
      case REMOVE_ACTION_FROM_QUEUE:
        return removeActionFromQueue(draftState, action);
      case ADD_ACTION_TO_DELAY_QUEUE:
        return addActionToDelayQueue(draftState, action);
      case REMOVE_ACTION_FROM_DELAY_QUEUE:
        return removeActionFromDelayQueue(draftState, action);
      case EXECUTE_ACTION_IN_QUEUE:
        return executeActionInQueue(draftState, action);
      case EXECUTE_ACTION_IN_QUEUE_SUCCESS:
        return executeActionInQueueSuccess(draftState, action);
      case EXECUTE_ACTION_IN_QUEUE_FAILURE:
        return executeActionInQueueFailure(draftState, action);
      case REMOVE_FAILED_ACTION_FROM_QUEUE:
        return removeFailedActionFromQueue(draftState, action);
      case DELETE_ARTWORKS:
        return removeFailedAndDelayedActionsForDeletedArtworks(draftState, action);
      case DELETE_ARTWORK_SUCCESS:
        return removeFailedAndDelayedActionsForDeletedArtwork(draftState, action);
      case DELETE_CONTACTS:
        return removeFailedAndDelayedActionsForDeletedContacts(draftState, action);
      case DELETE_CONTACT_SUCCESS:
        return removeFailedAndDelayedActionsForDeletedContact(draftState, action);
      case DELETE_ARTISTS:
        return removeFailedAndDelayedActionsForDeletedArtists(draftState, action);
      case DELETE_ARTIST_SUCCESS:
        return removeFailedAndDelayedActionsForDeletedArtist(draftState, action);
      case DELETE_LISTS:
        return removeFailedAndDelayedActionsForDeletedLists(draftState, action);
      case DELETE_LIST_SUCCESS:
        return removeFailedAndDelayedActionsForDeletedList(draftState, action);
      case SIGN_OUT_SUCCESS:
        return QUEUE_INITIAL_STATE;
      default:
        return state;
    }
  });
};

const addActionToQueue = (draftState, action) => {
  draftState.actionQueue.push({
    id: action.payload.id,
    itemId: action.payload.itemId,
    attempt: action.payload.attempt,
    action: action.payload.action,
  });
};

const removeActionFromQueue = (draftState, action) => {
  const index = draftState.actionQueue.findIndex((actionInQueue) => actionInQueue.id === action.payload.actionId);
  if (index !== -1) draftState.actionQueue.splice(index, 1);
};

const addActionToDelayQueue = (draftState, action) => {
  const index = draftState.actionQueue.findIndex((actionInQueue) => actionInQueue.id === action.payload.actionId);
  draftState.delayQueue.push(draftState.actionQueue[index]);
  if (index !== -1) draftState.actionQueue.splice(index, 1);
};

const removeActionFromDelayQueue = (draftState, action) => {
  const index = draftState.delayQueue.findIndex((actionInQueue) => actionInQueue.id === action.payload.actionId);
  if (index !== -1) draftState.delayQueue.splice(index, 1);
};

const executeActionInQueue = (draftState, action) => {
  const index = draftState.actionQueue.findIndex((actionInQueue) => actionInQueue.id === action.payload.actionId);
  draftState.executingActions.byId[action.payload.actionId] = { ...draftState.actionQueue[index] };
  if (index !== -1) draftState.actionQueue.splice(index, 1);
};

const executeActionInQueueSuccess = (draftState, action) => {
  delete draftState.executingActions.byId[action.payload.actionId];
};

const executeActionInQueueFailure = (draftState, action) => {
  const failedExecutedAction = draftState.executingActions.byId[action.payload.actionId];
  draftState.failedActionQueue.push({
    id: failedExecutedAction.id,
    itemId: failedExecutedAction.itemId,
    attempt: failedExecutedAction.attempt + 1,
    action: failedExecutedAction.action,
  });
  delete draftState.executingActions.byId[action.payload.actionId];
};

const removeFailedActionFromQueue = (draftState, action) => {
  const index = draftState.failedActionQueue.findIndex((actionInQueue) => actionInQueue.id === action.payload.actionId);
  if (index !== -1) draftState.failedActionQueue.splice(index, 1);
};

const removeFailedAndDelayedActionsForDeletedArtworks = (draftState, action) => {
  const { artworkIds } = action.payload;

  artworkIds.forEach((artworkId) => {
    draftState.failedActionQueue.forEach((failedAction, index) => {
      if (failedAction.itemId === artworkId) {
        draftState.failedActionQueue.splice(index, 1);
      }
    });

    draftState.delayQueue.forEach((delayedAction, index) => {
      if (delayedAction.itemId === artworkId) {
        draftState.delayQueue.splice(index, 1);
      }
    });
  });
};

const removeFailedAndDelayedActionsForDeletedArtwork = (draftState, action) => {
  const { artworkId } = action.payload;

  draftState.failedActionQueue.forEach((failedAction, index) => {
    if (failedAction.itemId === artworkId) {
      draftState.failedActionQueue.splice(index, 1);
    }
  });

  draftState.delayQueue.forEach((delayedAction, index) => {
    if (delayedAction.itemId === artworkId) {
      draftState.delayQueue.splice(index, 1);
    }
  });
};

const removeFailedAndDelayedActionsForDeletedContacts = (draftState, action) => {
  const { contactIds } = action.payload;

  contactIds.forEach((contactId) => {
    draftState.failedActionQueue.forEach((failedAction, index) => {
      if (failedAction.itemId === contactId) {
        draftState.failedActionQueue.splice(index, 1);
      }
    });

    draftState.delayQueue.forEach((delayedAction, index) => {
      if (delayedAction.itemId === contactId) {
        draftState.delayQueue.splice(index, 1);
      }
    });
  });
};

const removeFailedAndDelayedActionsForDeletedContact = (draftState, action) => {
  const { contactId } = action.payload;

  draftState.failedActionQueue.forEach((failedAction, index) => {
    if (failedAction.itemId === contactId) {
      draftState.failedActionQueue.splice(index, 1);
    }
  });

  draftState.delayQueue.forEach((delayedAction, index) => {
    if (delayedAction.itemId === contactId) {
      draftState.delayQueue.splice(index, 1);
    }
  });
};

const removeFailedAndDelayedActionsForDeletedArtists = (draftState, action) => {
  const { artistIds } = action.payload;

  artistIds.forEach((artistId) => {
    draftState.failedActionQueue.forEach((failedAction, index) => {
      if (failedAction.itemId === artistId) {
        draftState.failedActionQueue.splice(index, 1);
      }
    });

    draftState.delayQueue.forEach((delayedAction, index) => {
      if (delayedAction.itemId === artistId) {
        draftState.delayQueue.splice(index, 1);
      }
    });
  });
};

const removeFailedAndDelayedActionsForDeletedArtist = (draftState, action) => {
  const { artistId } = action.payload;

  draftState.failedActionQueue.forEach((failedAction, index) => {
    if (failedAction.itemId === artistId) {
      draftState.failedActionQueue.splice(index, 1);
    }
  });

  draftState.delayQueue.forEach((delayedAction, index) => {
    if (delayedAction.itemId === artistId) {
      draftState.delayQueue.splice(index, 1);
    }
  });
};

const removeFailedAndDelayedActionsForDeletedLists = (draftState, action) => {
  const { listIds } = action.payload;

  listIds.forEach((listId) => {
    draftState.failedActionQueue.forEach((failedAction, index) => {
      if (failedAction.itemId === listId) {
        draftState.failedActionQueue.splice(index, 1);
      }
    });

    draftState.delayQueue.forEach((delayedAction, index) => {
      if (delayedAction.itemId === listId) {
        draftState.delayQueue.splice(index, 1);
      }
    });
  });
};

const removeFailedAndDelayedActionsForDeletedList = (draftState, action) => {
  const { listId } = action.payload;

  draftState.failedActionQueue.forEach((failedAction, index) => {
    if (failedAction.itemId === listId) {
      draftState.failedActionQueue.splice(index, 1);
    }
  });

  draftState.delayQueue.forEach((delayedAction, index) => {
    if (delayedAction.itemId === listId) {
      draftState.delayQueue.splice(index, 1);
    }
  });
};

export default queueReducer;
