import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../store/actions";
import { Box, Text } from "../fundamentals";
import { Button } from "../elements";
import { ButtonLabels, Headings, Info } from "../../localisation";
import { useHistory } from "react-router-dom";
import { useIsScreenMobile } from "../hooks/useMediaQuery";

const SuccessfulBuyerActionModal = ({ placeId, isMarketPlace, isOffer, isEnquiry, title, info }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsScreenMobile();

  const handleButtonClick = () => {
    dispatch(closeModal());
    if (isMarketPlace) {
      history.push("/discovery");
    } else {
      history.push(`/viewingroom?viewingRoomId=${placeId}`);
    }
  };

  return (
    <Box width={isMobile ? "65vw" : "440px"}>
      <Text size="heading3" mb="s" color="black">
        {isOffer ? Headings.offerSent : isEnquiry ? Headings.enquirySent : title}
      </Text>
      <Text fontSize="small" mb={isMobile ? "s" : "l"} color="grey.80">
        {isOffer ? Info.copyOfOfferEmail : isEnquiry ? Info.copyOfOEnquiryEmail : info}
      </Text>
      <Button buttonStyle="primary" size="medium" onClick={handleButtonClick} mt="s">
        {isMarketPlace ? ButtonLabels.backToDiscovery : ButtonLabels.backToViewingRoom}
      </Button>
    </Box>
  );
};

export { SuccessfulBuyerActionModal };
