import React from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Modal } from "../react-material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ArrowLeft, RotateCcw, Trash2, UploadCloud } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { ButtonLabels, DynamicLabels, Headings, UiLabels, Warnings } from "../../localisation";
import { editArtist, addActionToQueue, openModal, deleteArtists, closeModal } from "../../store/actions";
import { WithRequireAccess } from "../hocs";
import { NavigationBar } from "../react-material/NavigationBar/NavigationBar";
import { selectSelectedArtistIds, selectUser, selectArtistById } from "../../store/selectors";
import { DeleteModal, AlertModal } from "../modals";
import { v4 as uuid } from "uuid";
import { ArtistForm } from "../react-material/forms/ArtistForm.jsx";
import { DateTime } from "luxon";

const buttonStyle = {
  color: "white",
  fontFamily: "Inter",
  fontSize: "16px",
  lineHeight: "16px",
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.7,
  },
  "&:active": {
    opacity: 0.5,
  },
};

const EditArtistScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const selectedArtistId = useSelector(selectSelectedArtistIds)[0];
  const artist = useSelector((state) => selectArtistById(state, selectedArtistId));

  const submit = (values, event) => {
    event.preventDefault();
    const tempArtistId = uuid();
    return dispatch(addActionToQueue(editArtist(values), tempArtistId));
  };

  const defaultValues = {
    id: artist?.id,
    publishToDiscovery: artist?.publishToDiscovery,
    name: artist?.name,
    yearBirth: artist?.yearBirth,
    cityBirth: artist?.cityBirth,
    countryBirth: artist?.countryBirth,
    yearDeath: artist?.yearDeath,
    cityDeath: artist?.cityDeath,
    countryDeath: artist?.countryDeath,
    currentCity: artist?.currentCity,
    currentCountry: artist?.currentCountry,
    instagramUrl: artist?.instagramUrl,
    websiteUrl: artist?.websiteUrl,
    bio: artist?.bio,
    education: artist?.education?.map((educationItem) => ({
      startDate: educationItem.startDate ? DateTime.fromISO(educationItem.startDate) : null,
      endDate: educationItem.endDate ? DateTime.fromISO(educationItem.endDate) : null,
      institution: educationItem.institution,
      course: educationItem.course,
      city: educationItem.city,
      country: educationItem.country,
    })),
    exhibitions: artist?.exhibitions?.map((exhibitionsItem) => ({
      exhibitionType: exhibitionsItem.exhibitionType,
      partner: exhibitionsItem.partner,
      startDate: exhibitionsItem.startDate ? DateTime.fromISO(exhibitionsItem.startDate) : null,
      endDate: exhibitionsItem.endDate ? DateTime.fromISO(exhibitionsItem.endDate) : null,
      title: exhibitionsItem.title,
      venue: exhibitionsItem.venue,
      city: exhibitionsItem.city,
      country: exhibitionsItem.country,
      curatedBy: exhibitionsItem.curatedBy,
    })),
    collections: artist?.collections?.map((collectionsItem) => ({
      name: collectionsItem.name,
      city: collectionsItem.city,
      country: collectionsItem.country,
    })),
    publications: artist?.publications?.map((publicationsItem) => ({
      editor: publicationsItem.editor,
      publisher: publicationsItem.publisher,
      author: publicationsItem.author,
      city: publicationsItem.city,
      year: publicationsItem.year,
      title: publicationsItem.title,
      issue: publicationsItem.issue,
    })),
    awards: artist?.awards?.map((awardsItem) => ({
      year: awardsItem.year,
      title: awardsItem.title,
      city: awardsItem.city,
      country: awardsItem.country,
    })),
  };

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: { ...defaultValues } });

  const renderFloatingActionPillButtons = (dispatch, form) => {
    return [
      <Button
        type="submit"
        disabled={isSubmitting}
        variant="text"
        startIcon={<UploadCloud size="20px" />}
        sx={buttonStyle}
      >
        {ButtonLabels.saveArtist}
      </Button>,

      <Button onClick={() => reset()} variant="text" key="reset" startIcon={<RotateCcw size="20px" />} sx={buttonStyle}>
        {ButtonLabels.reset}
      </Button>,
      <Button
        variant="text"
        startIcon={<Trash2 size="20px" />}
        sx={buttonStyle}
        onClick={() => dispatch(openModal("delete_artist"))}
      >
        {ButtonLabels.delete}
      </Button>,
    ];
  };

  const deleteSelectedArtists = () => {
    dispatch(deleteArtists([selectedArtistId]));
  };

  const cancelDeletion = () => {
    dispatch(closeModal());
  };

  return (
    <Box width="100%">
      <Box maxWidth="98%" margin="0 auto">
        <NavigationBar />
      </Box>
      <Stack direction="row">
        <Stack pl="48px" pt="16px" pb="16px" alignItems="flex-start" flexGrow={1} minHeight="100vh" bgcolor="white">
          <Button
            onClick={() => history.goBack()}
            sx={{ lineHeight: "16px", fontSize: "14px", pl: "0px" }}
            startIcon={<ArrowLeft style={{ position: "relative", right: "-5px", width: "16px", height: "16px" }} />}
          >
            {ButtonLabels.backToArtists}
          </Button>
          <Stack direction="row">
            <Typography variant="h2" sx={{ color: "#6A6870" }}>
              {Headings.artists}
            </Typography>
            <Typography variant="h2" sx={{ color: "#6A6870", m: "0 8px" }}>
              /
            </Typography>
            <Typography variant="h2">{`${artist?.name ? artist.name : " "}`}</Typography>
          </Stack>
          <Stack mt="16px" mb="48px">
            <Typography sx={{ fontSize: "12px", fontWeight: 500, color: "#95939C" }}>{UiLabels.lastUpdated}</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {moment().format("MMMM Do YYYY, h:mm")} by {user.name}
            </Typography>
          </Stack>
          <Stack sx={{ width: "100%", alignItems: "center", paddingBottom: "100px" }}>
            <ArtistForm
              handleSubmit={handleSubmit}
              watch={watch}
              setValue={setValue}
              control={control}
              errors={errors}
              selectedArtistIds={[]}
              renderFloatingActionPillButtons={renderFloatingActionPillButtons}
              onSubmit={submit}
            />
          </Stack>
        </Stack>
      </Stack>

      <Modal id="delete_artist">
        <DeleteModal
          headerText={DynamicLabels.popupHeaderForDelete("artist")}
          bodyText={Warnings.popupWarning}
          onDelete={deleteSelectedArtists}
        />
      </Modal>
      <Modal id="artist-deletion-denied">
        <AlertModal
          title={Headings.artistDeletionDenied}
          message={Warnings.artistDeletionDenied}
          acceptButtonText={ButtonLabels.cancel}
          onAccept={cancelDeletion}
        />
      </Modal>
    </Box>
  );
};

export default WithRequireAccess(EditArtistScreen, 1);
