import { createOnboardingLayout } from "../actions/MarketplaceActions";
import { 
  createOnboardingLayoutSuccess, 
  createOnboardingLayoutFailure, 
  createMarketPlaceAccountSuccess,
  createMarketPlaceAccountFailure,
  updateCardAccount,
  openModal 
} from "../actions";
import { SimplifyApi, createToast } from "../../utils";
import { replace } from "connected-react-router";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { startSubmit, stopSubmit, destroy } from "redux-form";
import { Notifications } from "../../localisation";
import {
  CREATE_ONBOARDING_LAYOUT_REQUEST,
  CREATE_MARKETPLACE_ACCOUNT_REQUEST,
} from "../actions/types";
import _ from "lodash";
import {CardElement, CardNumberElement, IbanElement} from "@stripe/react-stripe-js";
import {selectUser} from "../selectors";

// SERVICES
const createOnboardingLayoutService = (country, businessType) => {
  return SimplifyApi.post("/v1/connect/account/layout", { country, businessType });
};

const createMarketPlaceAccountService = (...values) => {
  return SimplifyApi.post("/v1/connect/account/create", {...values})
};

const updateMethodAccountService = (...values) => {
  return SimplifyApi.post("/v1/connect/account/method", {...values})
};

const sendEventService =  (eventName, message, userId) => SimplifyApi.post(`/v1/custom_event`,
    {eventName: eventName, message: message, userValue: userId});

// File upload functionality temporarily disabled
// const uploadFilesToStripeService = (files) => {
//   return SimplifyApi.post("upload_file", {files})
// }


// SAGAS
function* createOnboardingLayoutSaga(action) {
    const { country, businessType } = action.payload;
  yield put(startSubmit("payment_invite.onboard"));

  try {
    const response = yield call(createOnboardingLayoutService, country, businessType);
    yield put(createOnboardingLayoutSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(createOnboardingLayoutFailure());
    yield put(replace("/artworks"));
    yield put(openModal("id_verification_failed"));
  } finally {
    yield put(stopSubmit("payment_invite.onboard"));
  }
}

function* createMarketplaceAccountSaga(action) {
  // const {files, ...formValues} = action.payload;
  const formValues = action.payload;

    let individual = {};
    let businessProfile = {};
    let meta = {};
    let company = {};
    const country = formValues.country;
    const user = yield select(selectUser);
    if (formValues.businessType == 'individual'){

      individual.first_name = formValues.first_name;
      individual.last_name = formValues.last_name;
      individual.phone = formValues.phone;
      individual.dob = formValues.dob;
      individual.address = formValues.address;

      businessProfile.url = formValues.url;

      meta.tax_id = formValues.tax_id;
      meta.vat = formValues.vat;

    }else{

      company.name = formValues.name;
      company.phone = formValues.phone;
      company.address = formValues.address;
      company.tax_id = formValues.tax_id;

      businessProfile.url = formValues.url;

      meta.reg_number = formValues.reg_number;
      meta.vat = formValues.vat;

    };

    yield put(startSubmit("payment_invite.onboard"));

    try {
      // File upload functionality temporarily disabled
      // if (files) {
      //   yield call(uploadFilesToStripeService, files)
      // }

      let response;
      if (formValues.businessType == 'individual') {

          response = yield call(createMarketPlaceAccountService, {
            individual: individual,
            business_profile: businessProfile, metadata: meta,
            businessType: formValues.businessType,
            country: country
          });
      }else{
          response = yield call(createMarketPlaceAccountService, {
            company: company,
            business_profile: businessProfile, metadata: meta,
            businessType: formValues.businessType,
            country: country
          });
      };

      // update DB
      yield put(createMarketPlaceAccountSuccess(response.data));
      window.open(response.data.url, "_self");
    } catch (error) {
      yield call(sendEventService, 'PaymentInviteOnboardingFailed',
          {PaymentInviteOnboardingFailed: 'failed'}, user.id);
      yield put(createMarketPlaceAccountFailure());
      yield put(replace("/artworks"));
      yield put(openModal("id_verification_failed"));
    } finally {
      yield put(stopSubmit("payment_invite.onboard"));
    }
  }

function* updateCardAccountSaga(action){
  const { stripe, elements } = action.payload;
  yield put(startSubmit("payment_invite.onboard.payout_details"));
  
  try {
    const iban = elements.getElement(IbanElement);
    // currently limited to EUROPE/EURO need some adjustments!
    const {token, error_ct} = yield stripe.createToken(iban, { 'currency': 'eur' });
    const response = yield call(updateMethodAccountService, { 'cardToken' :token.id});

    yield put(replace("/artworks"));
    createToast({ type: "success", ...Notifications.activationsCompleted });

 } catch (error) {
    console.log(error);
    yield put(createOnboardingLayoutFailure());
    yield put(replace("/artworks"));
    yield put(openModal("id_verification_failed"));
  } finally {
    yield put(stopSubmit("payment_invite.onboard.payout_details"));
  }
}

function* marketplaceSaga() {
  yield takeEvery(CREATE_ONBOARDING_LAYOUT_REQUEST, createOnboardingLayoutSaga);
  yield takeEvery(CREATE_MARKETPLACE_ACCOUNT_REQUEST, createMarketplaceAccountSaga);
  yield takeEvery(updateCardAccount.REQUEST, updateCardAccountSaga);
};

export default marketplaceSaga;
