import { Room } from "@mui/icons-material";
import moment from "moment";
import LocalizedStrings from "react-localization";

export const ButtonLabels = new LocalizedStrings({
  en: {
    exhibitions: "Exhibitions",
    contacts: "Contacts",
    contact: "Contact",
    listsLabel: "Lists",
    artworks: "Artworks",
    artwork: "Artwork",
    artists: "Artists",
    artist: "Artist",
    events: "Events",
    event: "Event",
    help: "Help",
    select: "Select",
    selectAll: "Select All",
    clear: "Clear",
    clearSearch: "Clear search",
    upload: "Upload",
    addImages: "Add Images",
    addFile: "Add File",
    saveContact: "Save Contact",
    addArtwork: "Add Artwork",
    addArtworks: "Add Artworks",
    addContact: "Add Contact",
    addArtist: "Add Artist",
    saveArtwork: "Save Artwork",
    saveArtist: "Save Artist",
    saveAndCopy: "Save and Copy",
    saveAndNew: "Save and New",
    reset: "Reset Changes",
    export: "Export",
    share: "Share",
    details: "Details",
    delete: "Delete",
    cancel: "Cancel",
    close: "Close",
    back: "Back",
    goBack: "Go Back",
    emailUs: "Email Us",
    prev: "Prev",
    create: "Create",
    cancelSubscriptionConfirmation: "Yes, cancel my subscription",
    changeBillingPeriodConfirmation: "Yes, change my billing period",
    createViewingRoom: "Create Viewing Room",
    more: "More",
    backToArtworks: "Back to artworks",
    backToArtists: "Back to artists",
    backToContacts: "Back to contacts",
    backToLists: "Back to lists",
    backToViewingRooms: "Back to Viewing Rooms",
    settings: "Settings",
    contactUs: "Contact Us",
    signout: "Sign Out",
    forgotPassword: "Forgot Password?",
    accountAndSettings: "Account & Settings",
    organisationSettings: "Organisation Settings",
    subscriptionAndBilling: "Subscription & Billing",
    updateProfile: "Update Profile",
    changePassword: "Change password",
    deleteAccount: "Fully delete my account",
    updateOrganisation: "Update Organisation",
    incoming: "Incoming",
    outgoing: "Outgoing",
    download: "Download",
    preview: "Preview",
    login: "Log in",
    expressionOfInterest: "Join",
    register: "Register",
    next: "Next",
    createAccount: "Create account",
    subscribe: "Subscribe",
    cancelSubscription: "Cancel subscription",
    changeBillingPeriod: "Change billing period",
    restartSubscription: "Restart subscription",
    sendResetCode: "Send Reset Code",
    sendMeTheResetCode: "Send me the reset code",
    requestAnotherOne: "Request another one",
    enterArchive: "Enter Archive",
    addDropbox: "Add from Dropbox",
    discovery: "Discovery",
    addItem: "Add Item",
    add: "Add",
    gotIt: "Got It",
    setAsCover: "Set as Cover",
    updateCard: "Update card",
    finish: "Finish",
    ok: "OK",
    continue: "Continue",
    skip: "Skip",
    startFreeTrial: "Start free trial",
    restartMySubscription: "Restart my subscription",
    update: "Update",
    continueToPayment: "Continue to Payment",
    confirmAndPay: "Confirm and pay",
    confirmAndCreateAccount: "Confirm and create account",
    payAndCreateAccount: "Pay and create account",
    upgrade: "Upgrade",
    proceedToPayment: "Proceed to payment",
    cancelUpgrade: "I won't upgrade today",
    payAndUpgrade: "Pay & upgrade",
    backToChangePlan: "Back to change plan or billing period",
    retrySignup: "Re-try sign-up",
    edit: "Edit",
    upgradeSubscription: "Upgrade",
    backToRetryUpgrade: "Back to re-try upgrade",
    goToArtworks: "Go to Artworks",
    addEducation: "Add Education",
    removeEducation: "Remove Education",
    addExhibition: "Add Exhibition",
    removeExhibition: "Remove Exhibition",
    addCollection: "Add Collection",
    removeCollection: "Remove Collection",
    addPublication: "Add Publication",
    removePublication: "Remove Publication",
    addAward: "Add Award",
    removeAward: "Remove Award",
    sendMessage: "Send Message",
    sell: "Sell",
    hide: "Hide",
    show: "Show",
    addDiscount: "Add Discount",
    removeDiscount: "Remove Discount",
    addCost: "Add Cost",
    changeCurrency: "Change Currency",
    goToArtworkSales: "Go to Artwork Sales",
    pay: "Pay",
    proceedToBankTransfer: "Proceed to Bank Transfer",
    accept: "Accept",
    reject: "Reject",
    copyLink: "Copy Link",
    linkCopied: "Link Copied",
    startAccountAndAddArtworks: "Start free account and add artworks",
    submit: "Submit",
    sharedArtworks: "Shared Artworks",
    artworkAcquisitions: "Artwork Acquistitions",
    artworkSales: "Artwork Sales",
    downloadInvoice: "Download Invoice",
    sendReminder: "Send Reminder",
    markAsInTransit: "Mark as In Transit",
    duplicate: "Duplicate",
    updateEmail: "Update Email",
    confirm: "Confirm",
    startActivation: "Start activation",
    agree: "Agree",
    startFreeAccount: "Start free account",
    confirmDelivery: "Confirm Delivery",
    dispute: "Dispute",
    saveList: "Save List",
    remove: "Remove",
    continueWithPaymentInvite: "Continue with Payment Invite",
    viewingRooms: "Viewing Rooms",
    viewingRoom: "Viewing Room",
    seeDetails: "See details",
    saveAsDraft: "Save as Draft",
    goBackToViewingRoom: "Go back to Viewing Room",
    buy: "Buy",
    makeOffer: "Make Offer",
    makeAnOffer: "Make an Offer",
    sendAnEnquiry: "Send an Enquiry",
    showMore: "Show more",
    showLess: "Show less",
    exportPdf: "Export PDF",
    newViewingRoom: "New Viewing Room",
    view: "View",
    done: "Done",
    continueToShipping: "Continue to Shipping",
    status: "Status",
    removeText: "Remove Text",
    addText: "Add Text",
    selectFromComputer: "Select from computer",
    logIn: "Log In",
    getStarted: "Get started",
    loadMore: "Load More",
    clearAll: "Clear All",
    backToDiscovery: "Back to Discovery",
    sendOffer: "Send Offer",
    backToViewingRoom: "Back to Viewing Room",
    seeOffer: "See offer",
    seeEnquiry: "See enquiry",
    acceptOffer: "Yes, accept offer",
    rejectOffer: "Yes, reject offer",
    backToOffer: "Back to offer",
    sendResponse: "Send response",
    startForFree: "Start for free",
    backToAll: "Back to All",
    importArchive: "Import Archive",
    downloadTemplateSpreadsheet: "Download template spreadsheet",
    goBackToList: "Go back to List",
    artistProfile: "Artist Profile",
    createProfile: "Create Profile",
    exportArchive: "Export Archive",
  },
});

export const LinkLabels = new LocalizedStrings({
  en: {
    signup: "Sign up",
    expressInterest: "Express interest",
    signin: "Sign in here",
    signinText: "Sign in",
    emailUs: "Email us",
    terms: "Terms of Service",
    privacyPolicy: "Privacy Policy",
    here: "here",
    checkOutPaidSubscription: "Check out our paid subscription.",
    learnMorePaymentInvite: "Learn more about the Payment Invite process",
    learnMoreSimplifySelling: "Learn more about selling with simplify.art",
    learnMore: "Learn more.",
    clickHere: "click here.",
    letUsKnow: "let us know.",
    learnHowPaymentInvitesWork: "Learn more about how Payment Invites work",
    readFullTerms: "Read the full Terms & Conditions",
    termsAndConditions: "Terms & Conditions",
    product: "Product",
    pricing: "Pricing",
    about: "About",
    buyingWithSimplify: "Buying with simplify.art",
    bookDemo: "Book a demo",
    visitHelpCenter: "Visit our Help Center",
    contact: "Contact",
  },
});

export const Headings = new LocalizedStrings({
  en: {
    welcomeBack: "Welcome back!",
    expressionOfInterest: "Join Payment Invite Beta",
    trySimplify: "Start simplifying!",
    creditCardExpired: "Your credit card has expired",
    login: "Login",
    registration: "Create your Account",
    onboarding: "Account Details",
    artworks: "Artworks",
    artists: "Artists",
    viewingRoom: "Viewing Room",
    viewingRooms: "Viewing Rooms",
    exportArtworks: "Export Artworks",
    generalInformation: "General Information",
    location: "Location",
    value: "Value",
    additionalInformation: "Additional Information",
    images: "Images",
    accountDetails: "Account Details",
    organisationSettings: "Organisation Settings",
    organisationDetails: "Organisation Details",
    subscription: "Subscription",
    subscriptionAndBilling: "Subscription & Billing",
    shareArtworks: "Share Artworks",
    portfolio: "New Portfolio",
    workList: "New Work List",
    transportList: "New Transport List",
    insuranceList: "New Insurance List",
    priceList: "New Price List",
    invoice: "New Invoice",
    consignmentAgreement: "New Consignment Agreement",
    loanAgreement: "New Loan Agreement",
    handoffDocument: "New Handoff Document",
    certificate: "New Certificate",
    installationManual: "New Installation Manual",
    dataSheet: "New Data Sheet",
    storageLabels: "New Storage Labels",
    wallLabels: "New Wall Labels",
    createViewingRoom: "Create Viewing Room",
    choosePlan: "Choose a plan",
    choosePlanFreeTrial: "Choose a plan to start your free trial",
    chooseBillingPeriod: "Choose a billing period and enter your credit card details",
    confirmAccount: "Confirm your account",
    forgotPassword: "Forgot password?",
    newArtwork: "Add New Artwork",
    changePassword: "Change Password",
    welcome: "Congratulations!",
    collection: "Collection",
    artworkLists: "Artwork Lists",
    salesAndAgreements: "Sales & Agreements",
    artworkDocuments: "Artwork Documents",
    labels: "Labels",
    csv: "CSV",
    newCsv: "New CSV",
    borrower: "Borrower",
    lender: "Lender",
    exhibition: "Exhibition",
    transport: "Transport",
    consignmentConditions: "Consignment Conditions",
    credits: "Credits",
    insurance: "Insurance",
    addCosts: "Add Costs",
    packaging: "Packaging",
    featureChangelog: "Whats New - Update ",
    cardDetails: "Card Details",
    updateCardDetails: "Update Card Details",
    waltersCubeExhibitions: "Walters Cube 3D Exhibitions",
    whiteLabeledExports: "White Label Your Exports",
    userType: "User Type",
    profileDetails: "Profile Details",
    selectWhichFitsBest: "Select which fits best",
    tellUsAboutYourSelf: "Tell us about yourself",
    annually: "Annually",
    monthly: "Monthly",
    orderSummary: "Order Summary",
    trialEnds: "Trial Ends",
    createdAt: "Created At",
    accountCreated: "Your account has been created!",
    creditCardUpdated: "Your credit card has been updated!",
    letsGetYouSimplifying: "Let’s get you back simplifying!",
    contacts: "Contacts",
    newContact: "New Contact",
    upgradeHeading: "Unlock the power of simplify.art",
    selectSubscription: "Select your subscription",
    confirmUpgrade: "Confirm your upgrade",
    paymentIncomplete: "Payment incomplete",
    paymentSuccessful: "Payment successful!",
    success: "Success!",
    upgradeIncomplete: "Upgrade incomplete",
    newArtist: "New Artist",
    general: "General",
    education: "Education",
    exhibitions: "Exhibitions",
    collections: "Collections",
    publications: "Publications",
    awards: "Awards",
    artistDeletionDenied: "Artist can't be deleted",
    contactUs: "Contact Us",
    createPaymentInvite: "Create a Payment Invite to sell your artworks",
    sharePaymentInvite: "Share your Payment Invite",
    confirmPaymentInviteShare: "Your Payment Invite has been shared!",
    nextSteps: "Next steps",
    securePayment: "Will this payment be secure?",
    processedPayment: "Your Payment has been processed!",
    rejectPaymentInvite: "Are you sure you want to reject this Payment Invite?",
    activateSales: "Activate Sales",
    verifyIdentity: "Verify your identity",
    providePayoutDetails: "Provide your payout details",
    confirmSetup: "Confirm setup",
    somethingWentWork: "Something went wrong",
    enterDetails: "Enter your details",
    completeProfile: "Complete your profile",
    onboardingSuccessful: "Onboarding completed successfully!",
    activationComplete: "Activation complete!",
    activity: "Activity",
    sharedArtworks: "Shared Artworks",
    artworkAcquisitions: "Artwork Acquisitions",
    artworkSales: "Artwork Sales",
    markAsInTransit: "Mark as In Transit",
    sellArtworksWithSimplify: "Sell your artwork securely with simplify.art",
    getStarted: "Get started in 10 minutes",
    startDisputeProcess: "Start Dispute Proccess",
    confirmDelivery: "Confirm Artwork Delivery",
    acceptTerms: "Accept Terms",
    summaryOfTemrs: "Summary of simplify.art Terms & Conditions",
    purchaseStatus: "Purchase Status",
    condition: "Condition",
    conditionDetails: "Condition Details",
    lists: "Lists",
    information: "Information",
    selectArtworksToAdd: "Select artworks to add",
    viewingRoomLink: "Viewing Room Link",
    viewingRoomCreated: "Your Viewing Room has been created!",
    editViewingRoom: "Edit Viewing Room",
    viewingRoomUpdated: "Your Viewing Room has been updated!",
    publicDetails: "Public Details",
    forDiscoveryAndViewingRooms: "For Discovery & Viewing Rooms",
    invalidDiscountCode: "Invalid Discount Code",
    discovery: "Discovery",
    offerSent: "Your offer has been sent",
    offerReceived: "You have received the following offer",
    enquirySent: "Your enquiry has been sent",
    enquiryReceived: "You have received the following enquiry",
    sureToProceed: "Are you sure you want to proceed?",
    filterAndSort: "Filter and sort",
    importYourArchive: "Import your archive",
    artistProfile: "Artist Profile",
  },
});

export const UiLabels = new LocalizedStrings({
  en: {
    loading: "Loading",
    lastUpdated: "Last Updated",
    include: "Include",
    multiple: "Multiple",
    comingSoon: "Coming Soon",
    perMonth: "per month",
    plan: "Plan",
    status: "Status",
    renewDate: "Renew Date",
    unsynced: "Unsynced",
    selected: "selected",
    cm: "cm",
    all: "All",
    version: "Version",
    cover: "Cover",
    unspecified: "Unspecified",
    cardNumber: "Card Number",
    expirationDate: "Expiration Date",
    subTotal: "Subtotal",
    vat: "Vat",
    dueNow: "Due Now",
    billingPeriod: "Billing Period",
    billingPeriodStart: "Billing Period Start",
    billingPeriodEnd: "Billing Period End",
    currentBillingPeriod: "Current Billing Period",
    additional: "Additional",
    contacts: "Contacts",
    contactSelected: "Contact selected",
    contactsSelected: "Contacts selected",
    artists: "Artists",
    artistSelected: "Artist selected",
    accountType: "Account Type",
    subscriptionType: "Subscription Type",
    price: "Price",
    artworkTotal: "Artwork Total:",
    totalSalePrice: "Total sale price",
    totalYouReceive: "Total you receive",
    inviteValidUntil: "Invite valid until",
    editSaleDetails: "Edit sale details",
    shareInvite: "Share Invite",
    validUntil: "Valid until",
    totalBuyerPays: "Total buyer pays",
    ourFee: "Our fee",
    artworkPrice: "Artwork Price",
    total: "Total",
    artworkSubtotal: "Artwork Subtotal",
    statusPage: "Status Page",
    artwork: "Artwork",
    artworks: "Artworks",
    paymentInviteExpired: "Payment Invite has expired! Please contact simplify.art",
    paymentInvitePaid: "No action required at the moment! The current status is:",
    dateGenerated: "Date Generated",
    artistNames: "Artist Name(s)",
    artworkTitles: "Artwork Title(s)",
    recipients: "Recipient(s)",
    awaitingPayment: "Awaiting Payment",
    rejected: "Rejected",
    paidByBuyer: "Paid by Buyer",
    transit: "In Transit",
    delivered: "Delivered",
    disputed: "Disputed",
    bounced: "Bounced",
    paidOut: "Paid Out",
    duplicate: "Duplicate",
    awaitingSupport: "Awaiting Support",
    shippingAddress: "Shipping Address",
    billingAddress: "Billing Address",
    seller: "Seller",
    salePrice: "Sale Price",
    artist_s: "Artist(s)",
    paid: "Paid",
    acceptTerms: "Accept Terms",
    basicDetails: "Basic Details",
    idVerificationAndPayoutDetails: "ID Verification & Payout details",
    confirm: "Confirm",
    lists: "Lists",
    listSelected: "List selected",
    listsSelected: "Lists selected",
    poweredBy: "Powered by",
    aboutTheWork: "About the work",
    exportDoc: "Export document",
    shareArtwork: "Share artwork",
    editArtwork: "Edit artwork",
    sellArtwork: "Sell artwork",
    createViewingRoom: "Create viewing room",
    selectMoreArtworksForViewingRoom: "Select more artworks to create a viewing room",
    cancel: "Cancel",
    new: "New",
    shippingCosts: "Shipping Costs",
    calculatedNext: "calculated at next step",
    information: "Information",
    shipping: "Shipping",
    payment: "Payment",
    contact: "Contact",
    shipTo: "Ship to",
    transport: "Transport",
    ownShipping: "Own Shipping",
    personalHandover: "Personal Handover",
    shippingAndTaxes: "Shipping and Taxes",
    buyingWithSimplify: "Buying with simplify.art is safe and hassle-free",
    offer: "Offer",
    offerReceived: "Offer received",
    offeredAmount: "Offered amount",
    enquiryReceived: "Enquiry received",
    enquiryAnswered: "Enquiry answered",
    discount: "Discount",
    appForManagingArt: "The app for managing your art collection effortlessly.",
    timeOnAdmin: "Less time on admin",
    timeOnDiscovery: "More time on discovery",
    aboutUs: "About us",
    support: "Support",
    CopyrightSimplify2023: "© 2023 simplify.art",
    artworkBeforeDiscount: "Artwork before discount",
    activity:"Activity",
    downloadTemplate: "Download template",
    setUpFile: "Set up your file",
    upload: "Upload",
  },
});

export const OverviewLabels = new LocalizedStrings({
  en: {
    name: "Name:",
    organisation: "Organisation:",
    accountType: "Your account type:",
    subscription: "Your subscription:",
    updatedPlan: "Your updated plan:",
    billingPeriod: "Your billing period:",
    cardNumber: "Your card number:",
    artist: "Artist",
    collector: "Collector",
    gallerist: "Gallerist",
    ["art-professional"]: "Art Professional",
    ["art-institution"]: "Art Institution",
    allAccessPlan: "All-Access",
    freePlan: "Free-Access",
    billingPeriodMonthly: "Monthly",
    billingPeriodYearly: "Yearly",
    features: "Features",
  },
});

export const DynamicLabels = new LocalizedStrings({
  en: {
    numArtworks: (number) => `${number} Artwork${number > 1 ? "s" : ""}`,
    numArtists: (number) => `${number} Artist${number > 1 ? "s" : ""}`,
    updateArtworks: (number) => `Update ${number} Artwork${number > 1 ? "s" : ""}`,
    deleteArtworks: (number) => `Delete ${number} Artwork${number > 1 ? "s" : ""}`,
    sharedArtworks: (number) => `shared ${number} artwork${number > 1 ? "s" : ""}`,
    multiArtistEditWarning: (number) => `You are changing the Artist for ${number} selected Artworks`,
    multiTitleEditWarning: (number) => `You are changing the Title for ${number} selected Artworks`,
    multiDimensionsEditWarning: (number) => `You are changing the Dimensions for ${number} selected Artworks`,
    multiCollectionEditWarning: (number) => `You are changing the Collection for ${number} selected Artworks`,
    multiStatusEditWarning: (number) => `You are changing the Status for ${number} selected Artworks`,
    multiYearEditWarning: (number) => `You are changing the Year for ${number} selected Artworks`,
    multiEditionEditWarning: (number) => `You are changing the Edition for ${number} selected Artworks`,
    multiSeriesTitleEditWarning: (number) => `You are changing the Series for ${number} selected Artworks`,
    multiTechniqueEditWarning: (number) => `You are changing the Technique for ${number} selected Artworks`,
    multiMovementEditWarning: (number) => `You are changing the Movement for ${number} selected Artworks`,
    multiMediumEditWarning: (number) => `You are changing the Medium for ${number} selected Artworks`,
    multiWorkDescriptionEditWarning: (number) =>
      `You are changing the Work Description for ${number} selected Artworks`,
    multiLocationEditWarning: (number) => `You are changing the Location for ${number} selected Artworks`,
    multiPositionEditWarning: (number) => `You are changing the Position for ${number} selected Artworks`,
    multiCurrentOwnerEditWarning: (number) => `You are changing the Current Owner for ${number} selected Artworks`,
    multiSourceEditWarning: (number) => `You are changing the Source for ${number} selected Artworks`,
    multiPriceEditWarning: (number) => `You are changing the Price for ${number} selected Artworks`,
    multiPublicPriceEditWarning: (number) => `You are changing the Public Price for ${number} selected Artworks`,
    multiInsuranceValueEditWarning: (number) => `You are changing Insurance Value for ${number} selected Artworks`,
    multiProductionCostsEditWarning: (number) =>
      `You are changing the Production Costs for ${number} selected Artworks`,
    multiFramingCostsEditWarning: (number) => `You are changing the Framing Costs for ${number} selected Artworks`,
    multiPackagingCostsEditWarning: (number) => `You are changing the Packaging Costs for ${number} selected Artworks`,
    multiPhotoCopyrightOwnerEditWarning: (number) =>
      `You are changing the Photo Copyright Owner for ${number} selected Artworks`,
    multiIncomingDateEditWarning: (number) => `You are changing the Incoming Date for ${number} selected Artworks`,
    multiPackagingEditWarning: (number) => `You are changing the Packaging for ${number} selected Artworks`,
    multiPackagingInstructionsEditWarning: (number) =>
      `You are changing the Packaging Instructions for ${number} selected Artworks`,
    multiNotesEditWarning: (number) => `You are changing the Notes for ${number} selected Artworks`,
    collapseToArtworks: (number) => `Collapse to ${number} artworks`,
    showAllArtworks: (number) => `Show all ${number} artworks`,
    confirmDeleteArtworks: (number) => `Are you sure you want to delete ${number} Artwork${number > 1 ? "s" : ""} ?`,
    numFreeTrial: (number) => `${number}-day free trial`,
    dateStartingToPay: (date) => `Starting ${date.date()} ${date.format("MMMM")}`,
    planRenewalDate: (date) => `${date.date()} ${date.format("MMMM YYYY")}`,
    expiredCreditCard: (lastFourDigit) =>
      `The credit card ending in ${lastFourDigit} has expired. Please fill in your new credit card details to continue using your account.`,
    changeBillingPeriod: (period) => `You’re switching to ${period} billing.`,
    changeBillingPeriodMessage: (currentPeriodEnd = "", price = "", billing_period = "", lastFourNumbers = "") =>
      `From ${moment(currentPeriodEnd * 1000).format("ddd MMM DD YYYY")}, we’ll charge €${price} ${
        billing_period === "monthly" ? "each month" : "each year"
      } to the credit card ending in ${lastFourNumbers}.`,
    popupHeaderForDelete: (name, number = 1) =>
      `Are you sure you want to delete ${number > 1 ? "these" : "this"} ${name}?`,
    subscriptionCharge: (amount, billing_period) =>
      `From ${moment().format("dd MMM YYYY")}, we'll charge ${billing_period} €${amount}`,
    paymentInviteFee: (percentage) => `${percentage}% processing fee`,
    totalYouReceiveInclVat: (vat) => `Total you receive (incl. VAT ${vat}%)`,
  },
});

export const DynamicHeadings = new LocalizedStrings({
  en: {
    artworkForSale: (name, artworksNumber) =>
      `${name} is selling you ${artworksNumber > 1 ? "artworks" : "an artwork"}`,
    purchaseFrom: (name) => `Purchase from ${name}`,
    makeOfferTo: (name) => `Make offer to ${name}`,
    sendEnquiryTo: (name) => `Send enquiry to ${name}`,
  },
});

export const InputLabels = new LocalizedStrings({
  en: {
    artist: "Artist Name",
    artist_text: "Artist",
    title: "Title",
    subtitle: "Subtitle",
    year: "Year",
    technique: "Technique",
    dimensions: "Dimensions",
    dimensionsVariable: "Variable dimensions",
    height: "Height",
    width: "Width",
    depth: "Depth",
    diameter: "Diameter",
    location: "Location",
    position: "Position",
    price: "Price",
    source: "Source",
    seriesTitle: "Series Title",
    isFramed: "Framed",
    typeOfFrame: "Type of Frame",
    type: "Type",
    edition: "Edition",
    currentOwner: "Current Owner",
    insuranceValue: "Insurance Value",
    productionCosts: "Production Costs",
    framingCosts: "Framing Costs",
    photoCopyrightOwner: "Photo Copyright",
    incomingDate: "Incoming Date",
    acquisitionDate: "Acquisition Date",
    installationInstructions: "Installation Instructions",
    workDescription: "Work Description",
    description: "Description",
    additionalTextDescription: "Additional Text Description",
    notes: "Notes",
    sortBy: "Sort by",
    organisationName: "Organisation Name",
    recipientEmail: "Recipient Email",
    message: "Message",
    sharePrice: "Share Price",
    pickupAddress: "Pickup Address",
    deliveryAddress: "Delivery Address",
    id: "ID",
    email: "E-mail Address",
    emailText: "Email",
    password: "Password",
    fullName: "Full Name",
    amArtist: "I am an artist",
    organisation: "Name of Organisation (ie. Gallery)",
    organisationType: "Type of Organisation",
    gallery: "Gallery",
    gallerist: "Gallerist",
    collector: "Collector",
    curator: "Curator",
    technician: "Technician",
    artistType: "Artist",
    journalist: "Journalist",
    museum: "Museum",
    auction: "Auction house",
    writer: "Writer",
    historian: "Art historian",
    transport: "Transport",
    insurance: "Insurance",
    fair: "Fair",
    institution: "Institution",
    vip: "VIP",
    sponsor: "Sponsor",
    hotel: "Hotel",
    catering: "Catering",
    artProfessional: "Art Professional",
    artInstitution: "Art Institution",
    other: "Other",
    creditCardDetails: "Credit Card Details",
    resetCode: "Reset Code",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    collection: "Collection",
    portfolio: "Portfolio",
    workList: "Work List",
    transportList: "Transport List",
    insuranceList: "Insurance List",
    priceList: "Price List",
    dataSheet: "Data Sheet",
    invoice: "Invoice",
    consignmentAgreement: "Consignment Agreement",
    certificate: "Certificate",
    conditionReport: "Condition Report",
    installationManual: "Installation Manual",
    handoff: "Handoff Document",
    originAddress: "Coming From",
    targetAddress: "Deliver To",
    storageLabels: "Storage Label",
    wallLabels: "Wall Label",
    csv: "CSV Document",
    discount: "Discount",
    vat: "VAT",
    bankDetails: "Bank Details",
    paymentConditions: "Payment Conditions",
    remarks: "Remarks",
    additionalNotes: "Additional Notes",
    labelFormat: "Label Format",
    regular: "Regular",
    slim: "Slim",
    small: "Small",
    offset: "Offset",
    guidelines: "Print Guidelines",
    loanDuration: "Loan Duration",
    borrowerName: "Borrower Name",
    lenderName: "Lender Name",
    address: "Address",
    contactPerson: "Contact Person",
    phoneNumber: "Phone Number",
    phone: "Phone",
    exhibitionDuration: "Exhibition Duration",
    printCredits: "Print Credits",
    photoCredits: "Photo Credits",
    returnAddress: "Return Address",
    insuranceAgreement: "Insurance Agreement",
    consignmentConditions: "Consignment Conditions",
    loanAgreement: "Loan Agreement",
    cost: "Cost",
    invoiceAddress: "Invoice Address",
    packagingType: "Type of Packaging",
    openingOrder: "Open in Order",
    status: "Status",
    vatNumber: "VAT Number",
    uploadGalleryLogo: "Upload Gallery Logo",
    website: "Website",
    firstName: "First Name",
    lastName: "Last Name",
    organisationContact: "Organisation",
    addressLine1: "Address Line",
    addressLine2: "Address Line 2",
    city: "City",
    region: "Region",
    postCode: "Postal Code",
    country: "Country",
    currency: "Currency",
    text: "Text",
    thin: "Thin",
    percentageArtistSplit: "Artist Sale Split",
    percentageGallerySplit: "Gallery Sale Split",
    instagramUrl: "Instagram URL",
    state: "State",
    born: "Born",
    died: "Died",
    livesAndWorks: "Lives and works in",
    start: "Start",
    end: "End",
    course: "Course",
    curatedBy: "Curated by",
    issue: "Issue",
    bio: "Bio",
    with: "With",
    venue: "Venue/Event",
    editor: "Editor",
    publisher: "Publisher",
    author: "Author",
    pageNumber: "Page Number",
    name: "Name",
    movement: "Movement",
    medium: "Medium",
    transportCosts: "Transport Costs",
    packagingCosts: "Packaging Costs",
    emailOrContact: "Email or Contact",
    iBan: "IBAN",
    dateOfBirth: "Date of Birth",
    businessWebsite: "Business Website",
    taxId: "Tax ID",
    companyName: "Company Name",
    registrationNumber: "Registration Number",
    trackingNumber: "Tracking Number",
    countryOfResidence: "Country of Residence",
    sameAsShippingAddress: "Same as Shipping Address",
    subject: "Subject",
    agreeTo: "I agree to the",
    secondaryImages: "Secondary Images",
    vatIncluded: "VAT inclusive",
    customId: "Custom ID",
    validUntil: "Valid Until",
    publicWorkDescription: "Public Work Description",
    showWorkDescription: "Show work description",
    showPrice: "Show price",
    allowPurchase: "Allow viewers to purchase artwork",
    allowOffer: "Allow viewers to make an offer",
    setAsPrivate: "Set as private",
    publicWorkDescription: "Public Work Description",
    publicPrice: "Public Price",
    totalPublicPrice: "Total Public Price",
    roundPriceUp: "Round price up",
    includeFramingCosts: "Include framing costs",
    includePackagingCosts: "Include packaging costs",
    publicLocation: "Public Location (Ships From)",
    sameAsOrganisationSettings: "Same as organisation settings",
    representativeFirstName: "Representative's First Name",
    representativeLastName: "Representative's Last Name",
    convelioPackaging: "Packaged according to Convelio guidelines prior to shipping",
    publishToDiscovery: "Publish artwork to Discovery",
    weight: "Weight",
    additionalText: "Additional Text",
    review: "Review",
    catalogueText: "Catalogue/Exhibition Text",
    simplifyShipping: "Use shipping organised by simplify.art",
    simplifyShippingNotAvailable: "Use shipping organised by simplify.art (not available for this purchase)",
    simplifyShippingDesc: "Transport conveniently with Convelio",
    ownShipping: "Organise your own shipping",
    handover: "Organise a personal handover",
    confirmationEmail: "A confirmation email will be sent with the seller details",
    event: "Event",
    amount: "Amount",
    yourResponse: "Your Response",
    caption: "Caption",
  },
});

export const InputValues = new LocalizedStrings({
  en: {
    available: "Available",
    onsite: "On Site",
    reserved: "Reserved",
    inProduction: "In Production",
    inTransit: "In Transit",
    sold: "Sold",
    onLoan: "On Loan",
  },
});

export const InputPlaceholders = new LocalizedStrings({
  en: {
    searchArtworks: "Search Artworks",
    optional: "Optional",
    email: "E-mail address",
    emailText: "Email",
    resetCode: "Reset Code",
    password: "Password",
    newPassword: "New password",
    confirmPassword: "Confirm New Password",
    organisation: "Name Of Organisation",
    name: "Name",
    artistName: "Artist Name",
    address: "Address",
    apt: "Apt [Opt]",
    city: "City",
    country: "Country",
    postalCode: "Postal Code",
    creditCardNumber: "Credit Card Number",
  },
});
