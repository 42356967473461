import {
  createPaymentInviteSuccess,
  createPaymentInviteFailure,
  initiateInvitePayment,
  executeInvitePayment,
  getPaymentInviteRequest,
  getPaymentInviteSuccess,
  getPaymentInviteArtworks,
  getPaymentInviteArtworksSuccess,
  downloadPaymentInvitesSuccess,
  downloadPaymentInvitesFailure,
  clearAllSelectedArtworks,
  updatePaymentInviteSuccess,
  updatePaymentInviteFailure,
  updateArtworkStatus,
  openDrawer,
  closeDrawer,
} from "../actions";
import {
  CREATE_PAYMENT_INVITE_REQUEST,
  GET_PAYMENT_INVITE_REQUEST,
  GET_PAYMENT_INVITE_ARTWORKS,
  PAYMENT_INVITE_REJECTED,
  DOWNLOAD_PAYMENT_INVITES,
  SEND_PAYMENT_INVITE_REMINDER,
  MARK_PAYMENT_INVITE_IN_TRANSIT,
  DOWNLOAD_PAYMENT_INVITE_INVOICE,
  MARK_PAYMENT_INVITE_DISPUTED,
  MARK_PAYMENT_INVITE_DELIVERED,
  UPDATE_PAYMENT_INVITE_REQUEST,
} from "../actions/types";
import { closeModal, openModal } from "../actions";
import { SimplifyApi, createToast } from "../../utils";
import { replace } from "connected-react-router";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { selectPaymentInviteById } from "../selectors";
import { startSubmit, stopSubmit, destroy } from "redux-form";
import { Notifications } from "../../localisation";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { saveAs } from "file-saver";
import moment from "moment";

// SERVICES
const createPaymentInviteService = (paymentInvite) => {
  return SimplifyApi.post("/v1/payment_invites", { ...paymentInvite });
};

const createPaymentIntentService = (amount, currency, paymentInviteId) =>
  SimplifyApi.post("/v1/connect/transfer/intent", { amount, currency, paymentInviteId });

const getPaymentInviteService = (paymentInviteId) => SimplifyApi.get(`/v1/payment_invites/${paymentInviteId}`);

const getPaymentInviteArtworksService = (artworkIds) => SimplifyApi.get(`/v1/p-artworks?ids=${artworkIds}`);

const commsService = (eventName, message, userValue) =>
  SimplifyApi.post(`/v1/custom_event`, { eventName, message, userValue });

const statusService = (paymentInviteId, statusName, userStatus) =>
  SimplifyApi.post(`/v1/connect/payment_invite/status`, { paymentInviteId, statusName, userStatus });

const getRejectedPaymentInviteService = (paymentInviteId) =>
  SimplifyApi.get(`/v1/payment_invites/rejected/${paymentInviteId}`);

const downloadPaymentInvitesService = () => {
  return SimplifyApi.get("/v1/payment_invites");
};

const markPaymentInviteInTransitService = (paymentInviteId, trackingNumber) =>
  SimplifyApi.post("/v1/payment_invites/inTransit", { paymentInviteId, trackingNumber });

const sendPaymentInviteReminderService = (paymentInviteId) =>
  SimplifyApi.get(`/v1/payment_invites/sendReminder/${paymentInviteId}`);

const updateWithDeliveryDetailsService = (paymentInviteId, deliveryValues) =>
  SimplifyApi.patch("/v1/payment_invites/delivery_details", { paymentInviteId, ...deliveryValues });

const markPaymentInviteDisputedService = (paymentInviteId, subject, message) =>
  SimplifyApi.post("/v1/payment_invites/disputed", { paymentInviteId, subject, message });

const markPaymentInviteDeliveredService = (paymentInviteId) =>
  SimplifyApi.post("/v1/payment_invites/delivered", { paymentInviteId });

const updatePaymentInviteService = (paymentInviteId, formValues) =>
  SimplifyApi.patch("/v1/payment_invites", { paymentInviteId, ...formValues });

const generatePaymentInviteInvoiceService = (paymentInviteId) =>
  SimplifyApi.patch("/v1/payment_invites/invoice", { paymentInviteId });

const calculateShippingCostsService = (integrationConnector, type, paymentInviteId) =>
  SimplifyApi.post("/v1/integration", { integrationConnector, data: { type, paymentInviteId } });

// SAGAS
function* createPaymentInviteSaga(action) {
  yield put(startSubmit("payment_invite.create"));
  const { payload } = action;
  try {
    const response = yield call(createPaymentInviteService, payload);
    const paymentInviteId = response.data._id;
    yield put(createPaymentInviteSuccess(response.data));
    // Normal Payment Invite Flow - PI generated by seller
    if (!payload.isBuyerGenerated) {
      yield all([
        put(replace("/artworks")),
        put(openModal("payment_invite_confirmation", { paymentInviteId })),
        put(clearAllSelectedArtworks()),
      ]);
    } else {
      // If PI is generated by buyer, make a call to get the PI directly after creating it
      yield put(
        getPaymentInviteRequest({
          paymentInviteId,
          isOffer: payload.isOffer,
          isEnquiry: payload.isEnquiry,
          isMarketPlace: payload.isMarketPlace,
          isMobile: payload.isMobile,
        })
      );
    }
  } catch (error) {
    yield put(createPaymentInviteFailure());
    if (!payload.isBuyerGenerated) {
      createToast({ type: "error", ...Notifications.paymentInviteCreationFailure });
    }
    throw error;
  } finally {
    yield put(stopSubmit("payment_invite.create"));
    yield put(destroy("payment_invite.create"));
  }
}

function* initiateInvitePaymentSaga(action) {
  yield put(startSubmit("payment_invite.pay"));
  let {
    stripe,
    elements,
    country,
    amount,
    currency,
    paymentInviteId,
    artworkIds,
    placeId,
    isMarketPlace,
    isMobile,
    ...values
  } = action.payload;

  try {
    const response = yield call(createPaymentIntentService, amount, currency, paymentInviteId);
    const client_secret = response.data;
    yield put(
      executeInvitePayment.request({
        stripe,
        elements,
        client_secret,
        paymentInviteId,
        artworkIds,
        placeId,
        isMarketPlace,
        isMobile,
        ...values,
      })
    );
  } catch (error) {
    console.log("Payment could not get initiated", error);
    yield put(stopSubmit("payment_invite.pay"));
    yield put(initiateInvitePayment.failure());
    createToast({ type: "error", ...Notifications.invitePaymentFailure });
  }
}

function* executeInvitePaymentSaga(action) {
  const { stripe, elements, client_secret, paymentInviteId, artworkIds, placeId, isMarketPlace, isMobile } =
    action.payload;

  const paymentInvite = yield select((state) => selectPaymentInviteById(state, paymentInviteId));

  try {
    const cardElement = elements.getElement(CardNumberElement);

    // Create Payment Method
    const { paymentMethod, error } = yield stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log(error);
      createToast({ type: "error", ...Notifications.invitePaymentFailure });
      return;
    }

    const result = yield stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (result.error) {
      createToast({ type: "error", ...Notifications.TwoFAFailure });
      return;
    }

    // Trigger Email
    yield call(
      commsService,
      "PaymentInvitePaid",
      { paymentInviteId: paymentInviteId, transportMode: paymentInvite.transportMode },
      "Guest"
    );
    // Update Payment Invite status
    yield call(statusService, paymentInviteId, "Paid");
    // Generate invoice
    yield call(generatePaymentInviteInvoiceService, paymentInviteId);

    yield put(executeInvitePayment.success());

    if (isMobile) {
      yield all([
        put(closeDrawer()),
        put(openModal("payment_invite_buyer.payment.success", { paymentInviteId, isMarketPlace, placeId })),
      ]);
    } else {
      yield all([put(replace(`/paymentinvite/paid?paymentInviteId=${paymentInviteId}`)), put(closeModal())]);
    }

    // Update artwork(s) status
    for (const artworkId of artworkIds) {
      yield put(updateArtworkStatus(paymentInviteId, artworkId, placeId, isMarketPlace, "sold"));
    }
    createToast({ type: "success", ...Notifications.invitePaymentSuccess });
  } catch (error) {
    console.log("Payment failed", error);
    yield put(executeInvitePayment.failure());
    createToast({ type: "error", ...Notifications.invitePaymentFailure });
  } finally {
    yield put(stopSubmit("payment_invite.pay"));
    yield put(destroy("payment_invite.pay"));
  }
}

export function* getPaymentInviteSaga(action) {
  const { paymentInviteId, isOffer, isEnquiry, isMarketPlace, isMobile } = action.payload;
  try {
    const response = yield call(getPaymentInviteService, paymentInviteId);
    const isPaid = response.data.paymentInvite.status.find((item) => item.status_name === "Paid");
    const artworkIds = response.data.paymentInvite.artworks.map((artwork) => artwork._id);
    yield put(getPaymentInviteSuccess(response.data));
    yield put(getPaymentInviteArtworks(paymentInviteId, artworkIds));
    if (isPaid) {
      yield put(
        openModal("payment_invite_buyer.status", {
          paymentInviteId,
        })
      );
    } else if (isOffer) {
      if (isMobile) {
        yield put(openDrawer("payment_invite_buyer", { paymentInviteId, isMarketPlace, isOffer }));
      } else {
        yield put(
          openModal("payment_invite_buyer.offer", {
            paymentInviteId,
            isMarketPlace,
          })
        );
      }
    } else if (isEnquiry) {
      if (isMobile) {
        yield put(openDrawer("payment_invite_buyer", { paymentInviteId, isMarketPlace, isEnquiry }));
      } else {
        yield put(
          openModal("payment_invite_buyer.enquiry", {
            paymentInviteId,
            isMarketPlace,
          })
        );
      }
    } else if (isMobile) {
      yield put(openDrawer("payment_invite_buyer", { paymentInviteId, isMarketPlace, page: "information" }));
    } else {
      yield put(openModal("payment_invite_buyer", { paymentInviteId, isMarketPlace }));
    }
  } catch (error) {
    console.log("Could not get Payment Invite", error);
    createToast({ type: "error", ...Notifications.generic });
  }
}

function* getPaymentInviteArtworksSaga(action) {
  const { paymentInviteId, artworkIds } = action.payload;
  try {
    const response = yield call(getPaymentInviteArtworksService, artworkIds);
    yield put(getPaymentInviteArtworksSuccess(paymentInviteId, response.data));
  } catch (error) {
    console.log("Could not get payment invite artworks", error);
    createToast({ type: "error", ...Notifications.generic });
  }
}

function* getRejectedPaymentInviteSaga(action) {
  yield put(startSubmit("payment_invite.reject"));
  const { paymentInviteId } = action.payload;
  try {
    const response = yield call(getRejectedPaymentInviteService, paymentInviteId);
    yield put(window.location.reload());
  } catch (error) {
    console.log(error);
    createToast({ type: "error", ...Notifications.generic });
  } finally {
    yield put(stopSubmit("payment_invite.reject"));
    yield put(destroy("payment_invite.reject"));
  }
}

export function* downloadPaymentInvitesSaga() {
  try {
    const { data } = yield call(downloadPaymentInvitesService);
    yield put(downloadPaymentInvitesSuccess(data.paymentInvites));
  } catch (error) {
    yield put(downloadPaymentInvitesFailure());
    createToast({ type: "error", ...Notifications.paymentInvitesDownloadError });
  }
}

function* sendPaymentInviteReminderSaga(action) {
  const paymentInviteId = action.payload;
  try {
    yield call(sendPaymentInviteReminderService, paymentInviteId);
    createToast({ type: "success", ...Notifications.reminderSentSuccessfully });
  } catch (error) {
    console.log("Could not send reminder", error);
    createToast({ type: "error", ...Notifications.generic });
  }
}

function* markPaymentInviteInTransitSaga(action) {
  yield put(startSubmit("payment_invite.status.in_transit"));
  const { paymentInviteId, trackingNumber } = action.payload;
  try {
    const response = yield call(markPaymentInviteInTransitService, paymentInviteId, trackingNumber);
    yield put(updatePaymentInviteSuccess(paymentInviteId, response.data));
    createToast({ type: "success", ...Notifications.updateSuccessful });
  } catch (error) {
    console.log("Could not mark as transit", error);
    createToast({ type: "error", ...Notifications.generic });
  } finally {
    yield put(stopSubmit("payment_invite.status.in_transit"));
    yield put(destroy("payment_invite.status.in_transit"));
    yield put(closeModal("paymentInviteInTransit"));
  }
}

function* downloadPaymentInviteInvoiceSaga(action) {
  const paymentInvite = action.payload;

  try {
    const path = paymentInvite.invoiceLink;

    yield call(saveAs, `${process.env.REACT_APP_S3_URL}${path}`, `invoice-${moment(new Date()).format("DD/MM/YYYY")}`);

    createToast({ type: "success", ...Notifications.invoiceDownloadSuccess });
  } catch (error) {
    console.log("Could not download invoice", error);
    createToast({ type: "error", ...Notifications.generic });
  }
}

function* markPaymentInviteDisputedSaga(action) {
  yield put(startSubmit("payment_invite.dispute"));
  const { paymentInviteId, subject, message } = action.payload;
  try {
    yield call(markPaymentInviteDisputedService, paymentInviteId, subject, message);

    createToast({ type: "success", ...Notifications.disputeSuccessful });
    yield put(replace(`/paymentinvite/pay?paymentInviteId=${paymentInviteId}`));
  } catch (error) {
    console.log("Could not mark as disputed", error);
    createToast({ type: "error", ...Notifications.generic });
  } finally {
    yield put(stopSubmit("payment_invite.dispute"));
    yield put(destroy("payment_invite.dispute"));
  }
}

function* markPaymentInviteDeliveredSaga(action) {
  const paymentInviteId = action.payload;
  try {
    yield call(markPaymentInviteDeliveredService, paymentInviteId);

    createToast({ type: "success", ...Notifications.updateSuccessful });
    yield put(replace(`/paymentinvite/pay?paymentInviteId=${paymentInviteId}`));
  } catch (error) {
    console.log("Could not mark as delivered", error);
    createToast({ type: "error", ...Notifications.generic });
  }
}

function* updatePaymentInviteSaga(action) {
  yield put(startSubmit("payment_invite.update"));
  const { paymentInviteId, placeId, isMarketPlace, isOffer, isEnquiry, isMobile, ...formValues } = action.payload;
  try {
    const response = yield call(updatePaymentInviteService, paymentInviteId, { isOffer, isEnquiry, ...formValues });

    if (isOffer) {
      yield put(updatePaymentInviteSuccess(paymentInviteId, response.data));
      yield put(closeDrawer());
      yield put(openModal("payment_invite_buyer.offer_sent", { placeId, isMarketPlace, isOffer, isEnquiry }));
      // Trigger email to
      yield call(commsService, "PaymentInviteOffer", { paymentInviteId: paymentInviteId }, "Guest");
    } else if (isEnquiry) {
      yield put(updatePaymentInviteSuccess(paymentInviteId, response.data));
      yield put(closeDrawer());
      yield put(openModal("payment_invite_buyer.enquiry_sent", { placeId, isMarketPlace, isOffer, isEnquiry }));
      // Trigger email to
      yield call(commsService, "PaymentInviteEnquiry", { paymentInviteId: paymentInviteId }, "Guest");
    } else {
      // If simplify shipping is selected, make a call to the convelio API to get the calculated shipping cost
      if (formValues.transportMode === "simplifyShipping") {
        const integrationResponse = yield call(
          calculateShippingCostsService,
          "convelio_integration",
          "estimation",
          paymentInviteId
        );
        if (integrationResponse.data[0].status === "error") {
          yield put(updatePaymentInviteFailure());
          createToast({ type: "error", ...Notifications.generic });
          yield put(closeModal("payment_invite_buyer"));
          return;
        }

        yield put(updatePaymentInviteSuccess(paymentInviteId, integrationResponse.data[1]));
        if (isMobile) {
          yield put(
            openDrawer("payment_invite_buyer", {
              paymentInviteId: response.data._id,
              placeId,
              isMarketPlace,
              page: "shipping",
            })
          );
        } else {
          yield put(
            openModal("payment_invite_buyer.shipping", { paymentInviteId: response.data._id, placeId, isMarketPlace })
          );
        }
        // If any other mode of transport is selected, take buyer directly to payment screen
      } else {
        yield put(updatePaymentInviteSuccess(paymentInviteId, response.data));
        yield put(closeModal());
        if (isMobile) {
          yield put(
            openDrawer("payment_invite_buyer", {
              paymentInviteId: response.data._id,
              placeId,
              isMarketPlace,
              page: "payment",
            })
          );
        } else {
          yield put(
            openModal("payment_invite_buyer.pay", { paymentInviteId: response.data._id, placeId, isMarketPlace })
          );
        }
      }
    }
  } catch (error) {
    console.log("Could not update payment invite", error);
    yield put(updatePaymentInviteFailure());
    createToast({ type: "error", ...Notifications.generic });
    throw error;
  } finally {
    yield put(stopSubmit("payment_invite.update"));
    yield put(destroy("payment_invite.update"));
  }
}

function* paymentInviteSaga() {
  yield takeEvery(initiateInvitePayment.REQUEST, initiateInvitePaymentSaga);
  yield takeEvery(executeInvitePayment.REQUEST, executeInvitePaymentSaga);
  yield takeEvery(CREATE_PAYMENT_INVITE_REQUEST, createPaymentInviteSaga);
  yield takeEvery(GET_PAYMENT_INVITE_REQUEST, getPaymentInviteSaga);
  yield takeEvery(GET_PAYMENT_INVITE_ARTWORKS, getPaymentInviteArtworksSaga);
  yield takeEvery(PAYMENT_INVITE_REJECTED, getRejectedPaymentInviteSaga);
  yield takeEvery(DOWNLOAD_PAYMENT_INVITES, downloadPaymentInvitesSaga);
  yield takeEvery(SEND_PAYMENT_INVITE_REMINDER, sendPaymentInviteReminderSaga);
  yield takeEvery(MARK_PAYMENT_INVITE_IN_TRANSIT, markPaymentInviteInTransitSaga);
  yield takeEvery(MARK_PAYMENT_INVITE_DISPUTED, markPaymentInviteDisputedSaga);
  yield takeEvery(MARK_PAYMENT_INVITE_DELIVERED, markPaymentInviteDeliveredSaga);
  yield takeEvery(DOWNLOAD_PAYMENT_INVITE_INVOICE, downloadPaymentInviteInvoiceSaga);
  yield takeEvery(UPDATE_PAYMENT_INVITE_REQUEST, updatePaymentInviteSaga);
}

export default paymentInviteSaga;
