import React from "react";
import Drawer from "@mui/material/Drawer";
import { Box } from "../fundamentals";
import { closeDrawer } from "../../store/actions";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { withTheme } from "styled-components";
import { Button } from "./Button";


const CustomDrawer = ({ children, anchor, id, drawerId, drawerProps, ...props }) => {
  const dispatch = useDispatch();

  return (
    <Box>
      <Drawer
        anchor={anchor ?? "bottom"}
        open={id === drawerId}
        onClose={() => dispatch(closeDrawer())}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: props.borderRadius ?? "10px 10px 0px 0px",
            padding: props.padding ?? "20px",
            ...props.customPaperStyling,
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(221, 222, 243, 0.7)",
          },
        }}
      >
        <Button
          type="button"
          buttonStyle="text"
          icon="XCircle"
          position="absolute"
          right="20px"
          top="20px"
          iconSize="20"
          zIndex="100"
          onClick={() => dispatch(closeDrawer())}
        />
        <Box position="relative">{React.cloneElement(children, drawerProps)}</Box>
      </Drawer>
    </Box>
  );
};

function mapStateToProps(state) {
  const {
    ui: { drawerId, drawerProps },
  } = state;
  return { drawerId, drawerProps };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeDrawer: closeDrawer }, dispatch);
}

const ConnectedDrawer = connect(mapStateToProps, mapDispatchToProps)(withTheme(CustomDrawer));

export { ConnectedDrawer as Drawer };
