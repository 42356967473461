import React, { useState } from "react";
import { Toolbar, AppBar, Stack, Button, Menu, MenuItem, Link, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { User } from "react-feather";
import { ButtonLabels, LinkLabels, Messages, FeaturesInfo, UiLabels } from "../../../localisation";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedCollection,
  seenAllShareRecords,
  signOut,
  openModal,
  clearAllSelectedArtworks,
} from "../../../store/actions";
import { SharingDropdown } from "../../modules/SharingDropdown";
import { selectCollections, selectUser } from "../../../store/selectors";
import _ from "lodash";
import { Popover } from "../Popover";
import { ActivityIcon } from "../CustomIcons";
import { Button as CustomButton } from "../../elements";
import { Tooltip } from "../Tooltip";
import { ChevronDown } from "react-feather";

const StyledMenu = styled(({ hoverBgColor, hoverColor, borderRadius, ...rest }) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...rest}
  />
))((props) => ({
  "& .MuiPaper-root": {
    borderRadius: props.borderRadius ?? "8px",
    backgroundColor: props.bg ? props.bg : "#5E54FF",
    color: props.bg ? "black" : "white",
    marginTop: "10px",
    width: props.width ? props.width : "fit-content",
    "& .MuiMenu-list": {
      padding: "0 0",
    },
    "& .MuiMenuItem-root": {
      padding: props.p ? props.p : "16px 24px",
      height: "48px",
      fontWeight: "500",
      "&:hover": {
        backgroundColor: props.hoverBgColor ?? "#7274FF",
        color: props.hoverColor ?? "white",
      },
    },
  },
}));

const StyledButton = styled(Button)((props) => ({
  "&.MuiButton-root": {
    fontSize: "16px",
    padding: props.padding ?? "8px",
    lineHeight: "16px",
    fontWeight: 500,
    color: props.textColor ?? "#5E54FF",
    borderRadius: props.borderRadius ?? "8px",
    backgroundColor: props.bgcolor ?? "transparent",
    width: props.width,
    height: props.height,
    "&:hover": {
      background: props.hoverColor ?? "#FFFFFF",
    },
    "&:disabled": {
      color: "#9AA1FF",
    },
  },
}));

export const NavigationBar = (props) => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [artworksAnchorEl, setArtworksAnchorEl] = useState(null);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [upgradeArtistsAnchorEl, setUpgradeArtistsAnchorEl] = useState(null);
  const [upgradeViewingRoomsAnchorEl, setUpgradeViewingRoomsAnchorEl] = useState(null);
  const [upgradeListsAnchorEl, setUpgradeListsAnchorEl] = useState(null);
  const [upgradeCsvImportAnchorEl, setUpgradeCsvImportAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const collections = _.sortBy(useSelector(selectCollections), ["type", "name"]);
  const currentUser = useSelector(selectUser);

  const handleSignOutButtonClick = () => {
    dispatch(signOut());
  };

  const handleSettingButtonClick = () => {
    history.push("/settings");
  };

  const handleContactUsButtonClick = () => {
    setUserAnchorEl(null);
    dispatch(openModal("contactUs"));
  };

  const handleAddArtworkButtonClick = () => {
    history.push("/artworks/add");
  };

  const handleAddArtistButtonClick = () => {
    history.push("/artists/add");
  };

  const handleAddViewingRoomButtonClick = () => {
    dispatch(clearAllSelectedArtworks());
    history.push("/viewingrooms/add");
  };

  const handleAddContactButtonClick = () => {
    history.push("/contacts/add");
  };

  const handleImportArchiveClick = () => {
    setAnchorEl(null);
    history.push("/artworks");
    dispatch(openModal("csv_import", { page: "templateDownload" }));
  };

  const handleExportArchiveClick = () => {
    setAnchorEl(null);
    history.push("/artworks");
    dispatch(openModal("archive_export", { page: "templateDownload" }));
  };

  const handleActivityClick = () => {
    history.push("/activity/shared");
  };

  const handleShareDropdownClick = (e) => {
    setShareAnchorEl(e.currentTarget);
    dispatch(seenAllShareRecords());
  };

  const handleCollectionClick = (id) => {
    dispatch(changeSelectedCollection(id));
    history.push("/artworks");
    setArtworksAnchorEl(null);
  };

  const handleArtistsButtonClick = () => {
    history.push("/artists");
  };

  const handleViewingRoomsButtonClick = () => {
    history.push("/viewingrooms");
  };

  const handleContactsButtonClick = () => {
    history.push("/contacts");
  };

  const handleListsButtonClick = () => {
    history.push("/lists");
  };

  const handleDiscoveryButtonClick = () => {
    dispatch(changeSelectedCollection(collections[1].id));
    history.push("/discovery");
  };

  // For users with a free account
  const handleArtistsButtonClickFree = (e) => {
    setUpgradeArtistsAnchorEl(e.currentTarget);
  };

  const handleViewingRoomsButtonClickFree = (e) => {
    setUpgradeViewingRoomsAnchorEl(e.currentTarget);
  };

  const handleContactsButtonClickFree = (e) => {
    setPopoverAnchorEl(e.currentTarget);
  };

  const handleListsButtonClickFree = (e) => {
    setUpgradeListsAnchorEl(e.currentTarget);
  };

  const handleImportArchiveClickFree = (e) => {
    setUpgradeCsvImportAnchorEl(e.currentTarget);
  };

  return (
    <AppBar position="static" sx={{ padding: "20px 48px", bgcolor: "#F4F4F5", boxShadow: "none", ...props }}>
      <Toolbar disableGutters variant="dense" sx={{ minHeight: "40px" }}>
        <RouterLink to={process.env.REACT_APP_ENV === "production" ? "https://www.simplify.art?redirect=no" : "/"}>
          <img
            src={`${process.env.PUBLIC_URL}/images/brand-identity/logo-no-text.svg`}
            alt="logo"
            style={{
              height: "35px",
              width: "25px",
            }}
          />
        </RouterLink>
        <Stack spacing={2} direction="row" sx={{ margin: "0px 20px", flexGrow: 1, padding: "2.5px 0px" }}>
          <StyledButton
            onClick={(e) => setArtworksAnchorEl(e.currentTarget)}
            bgcolor={location.pathname === "/artworks" ? "#DEE1FF" : "transparent"}
            endIcon={<ChevronDown />}
          >
            {ButtonLabels.artworks}
          </StyledButton>
          <StyledMenu
            anchorEl={artworksAnchorEl}
            open={Boolean(artworksAnchorEl)}
            onClose={() => setArtworksAnchorEl(null)}
          >
            {collections.map((collection) => {
              return (
                <MenuItem key={collection.id} disableRipple onClick={() => handleCollectionClick(collection.id)}>
                  {collection.name === "Discovery" ? "Favourites" : collection.name === "Artworks" ? "Collection" : collection.name}
                </MenuItem>
              );
            })}
          </StyledMenu>

          {currentUser.role !== "artist" && (
            <StyledButton
              onClick={
                currentUser?.account?.accessLevel === 0 ? handleArtistsButtonClickFree : handleArtistsButtonClick
              }
              bgcolor={
                ["/artists", "/artists/add", "/artists/edit"].includes(location.pathname) ? "#DEE1FF" : "transparent"
              }
            >
              {ButtonLabels.artists}
            </StyledButton>
          )}
          <Popover
            popoverAnchorEl={upgradeArtistsAnchorEl}
            setPopoverAnchorEl={setUpgradeArtistsAnchorEl}
            text={Messages.upgradeMessage(FeaturesInfo.addingArtists, FeaturesInfo.artistsFeatDesc)}
            linkText={LinkLabels.checkOutPaidSubscription}
            upgrade
          />
          <StyledButton
            onClick={
              currentUser?.account?.accessLevel === 0
                ? handleViewingRoomsButtonClickFree
                : handleViewingRoomsButtonClick
            }
            bgcolor={["/viewingrooms"].includes(location.pathname) ? "#DEE1FF" : "transparent"}
          >
            {ButtonLabels.viewingRooms}
          </StyledButton>

          <Popover
            popoverAnchorEl={upgradeViewingRoomsAnchorEl}
            setPopoverAnchorEl={setUpgradeViewingRoomsAnchorEl}
            text={Messages.upgradeMessage(FeaturesInfo.viewingRooms, FeaturesInfo.viewingRoomsFeatDesc)}
            linkText={LinkLabels.checkOutPaidSubscription}
            upgrade
          />

          <StyledButton
            onClick={
              currentUser?.account?.accessLevel === 0 ? handleContactsButtonClickFree : handleContactsButtonClick
            }
            bgcolor={
              ["/contacts", "/contacts/add", "/contacts/edit"].includes(location.pathname) ? "#DEE1FF" : "transparent"
            }
          >
            {ButtonLabels.contacts}
          </StyledButton>

          <Popover
            popoverAnchorEl={popoverAnchorEl}
            setPopoverAnchorEl={setPopoverAnchorEl}
            text={Messages.upgradeMessage(FeaturesInfo.addingContacts, FeaturesInfo.contactsFeatDesc)}
            linkText={LinkLabels.checkOutPaidSubscription}
            upgrade
          />

          <StyledButton
            onClick={currentUser?.account?.accessLevel === 0 ? handleListsButtonClickFree : handleListsButtonClick}
            bgcolor={location.pathname === "/lists" ? "#DEE1FF" : "transparent"}
          >
            {ButtonLabels.listsLabel}
          </StyledButton>

          <Popover
            popoverAnchorEl={upgradeListsAnchorEl}
            setPopoverAnchorEl={setUpgradeListsAnchorEl}
            text={Messages.upgradeMessage(FeaturesInfo.lists, FeaturesInfo.listsFeatDesc)}
            linkText={LinkLabels.checkOutPaidSubscription}
            upgrade
          />

          <StyledButton
            onClick={handleDiscoveryButtonClick}
            bgcolor={location.pathname === "/discovery" ? "#DEE1FF" : "transparent"}
          >
            {ButtonLabels.discovery}
          </StyledButton>
        </Stack>

        <Stack spacing={3.5} direction="row" alignItems="center">
          <Tooltip title={UiLabels.new}>
            <CustomButton
              buttonStyle="primary"
              icon="Plus"
              size="medium"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              alignSelf="center"
              iconCursor="pointer"
              color="white"
              iconStrokeWidth="2.5px"
            />
          </Tooltip>
          <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            <MenuItem disableRipple onClick={handleAddArtworkButtonClick}>
              {ButtonLabels.artwork}
            </MenuItem>
            {currentUser.role !== "artist" && (
              <MenuItem
                disableRipple
                onClick={
                  currentUser?.account?.accessLevel === 0 ? handleArtistsButtonClickFree : handleAddArtistButtonClick
                }
              >
                {ButtonLabels.artist}
              </MenuItem>
            )}
            <MenuItem
              disableRipple
              onClick={
                currentUser?.account?.accessLevel === 0
                  ? handleViewingRoomsButtonClickFree
                  : handleAddViewingRoomButtonClick
              }
            >
              {ButtonLabels.viewingRoom}
            </MenuItem>

            <MenuItem
              disableRipple
              onClick={
                currentUser?.account?.accessLevel === 0 ? handleContactsButtonClickFree : handleAddContactButtonClick
              }
            >
              {ButtonLabels.contact}
            </MenuItem>
            {/* Tooltip and span to be removed once Import Archive is not disabled */}
            <Tooltip title="Coming soon" placement="top">
              <span>
                <MenuItem
                  disableRipple
                  disabled={true}
                  onClick={
                    currentUser?.account?.accessLevel === 0 ? handleImportArchiveClickFree : handleImportArchiveClick
                  }
                >
                  {ButtonLabels.importArchive}
                </MenuItem>
              </span>
            </Tooltip>
            <Popover
              popoverAnchorEl={upgradeCsvImportAnchorEl}
              setPopoverAnchorEl={setUpgradeCsvImportAnchorEl}
              text={Messages.upgradeMessage(FeaturesInfo.archiveImport, FeaturesInfo.archiveImportFeatDesc)}
              linkText={LinkLabels.checkOutPaidSubscription}
              upgrade
            />
            {/* Tooltip and span to be removed once Export Archive is not disabled */}
            <MenuItem disableRipple onClick={handleExportArchiveClick}>
              {ButtonLabels.exportArchive}
            </MenuItem>
          </StyledMenu>
          <Link
            href="https://www.simplify.art/help"
            target="_blank"
            underline="none"
            sx={{ color: "black", fontWeight: 500, padding: "8px 0px" }}
          >
            Help
          </Link>
          <Tooltip title={UiLabels.activity}>
            <IconButton
              sx={{ padding: "8px 0px", "&:hover": { bgcolor: "transparent" } }}
              disableRipple
              onClick={handleActivityClick}
            >
              <ActivityIcon size="18" />
            </IconButton>
          </Tooltip>

          <IconButton
            sx={{ padding: "8px 0px", "&:hover": { bgcolor: "transparent" } }}
            disableRipple
            onClick={(e) => setUserAnchorEl(e.currentTarget)}
          >
            <User style={{ width: "18px", height: "18px", color: "black" }} />
          </IconButton>
          <StyledMenu
            anchorEl={userAnchorEl}
            open={Boolean(userAnchorEl)}
            onClose={() => setUserAnchorEl(null)}
            pl="22px"
            width="220px"
            bg="#FFFFFF"
            borderRadius="0"
            hoverBgColor="#E8E7EA"
            hoverColor="#7274FF"
          >
            <MenuItem disableRipple onClick={handleSettingButtonClick}>
              {ButtonLabels.settings}
            </MenuItem>
            <MenuItem disableRipple onClick={handleContactUsButtonClick}>
              {ButtonLabels.contactUs}
            </MenuItem>
            <MenuItem disableRipple sx={{ borderTop: "1px solid #DBDADF" }} onClick={handleSignOutButtonClick}>
              {ButtonLabels.signout}
            </MenuItem>
          </StyledMenu>
          <StyledMenu
            anchorEl={shareAnchorEl}
            open={Boolean(shareAnchorEl)}
            onClose={() => setShareAnchorEl(null)}
            bg="#FFFFFF"
            borderRadius="0"
            hoverBgColor="#E8E7EA"
            hoverColor="#7274FF"
            width="500px"
          >
            <SharingDropdown />
          </StyledMenu>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
