import React from "react";
import styled from "styled-components";
import { Switch, Redirect } from "react-router-dom";
import { ProtectedRoute, UnProtectedRoute } from "../../components/hocs";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import LoginScreen from "../../components/screens/LoginScreen";
import RegisterScreen from "../../components/screens/RegisterScreen";

import PaymentScreen from "../../components/screens/PaymentScreen";
import WelcomeScreen from "../../components/screens/WelcomeScreen";
import ForgottenPasswordScreen from "../../components/screens/ForgottenPasswordScreen";
import { Flex } from "../../components/fundamentals";

import OnboardingFirstScreen from "../../components/screens/OnboardingFirstScreen";
import OnboardingSecondScreen from "../../components/screens/OnboardingSecondScreen";
import OnboardingThirdScreen from "../../components/screens/OnboardingThirdScreen";
import OnboardingFourthScreen from "../../components/screens/OnboardingFourthScreen";
import UnsuccessfulAccountCreationScreen from "../../components/screens/UnsuccessfulAccountCreationScreen";
import QRCodeRedirectScreen from "../../components/screens/QRCodeRedirectScreen";
import PublicPaymentInviteScreen from "../../components/screens/PublicPaymentInviteScreen";
import PublicPaymentInviteConfirmationScreen from "../../components/screens/PublicPaymentInviteConfirmationScreen";
import SignUpBetaScreen from "../../components/screens/SignUpBeta";
import PaymentInviteOnboardingScreen from "../../components/screens/MarketplaceOnboardingScreen";
import PublicViewingRoomScreen from "../../components/screens/PublicViewingRoomScreen";
import PublicViewingRoomDetailsScreen from "../../components/screens/PublicViewingRoomDetailsScreen";
import PublicDiscoveryScreen from "../../components/screens/PublicDiscoveryScreen";

const StyledTransitionGroup = styled(TransitionGroup)`
  position: relative;
  width: 100%;
  .slide-enter {
    transform: translate3d(100%, 0, 0);
    transition: all 0s;
  }
  .slide-enter.slide-enter-active {
    transform: translate3d(0, 0, 0);
    transition: transform 0.75s;
  }
  .slide-exit {
    transition: all 0s;
    transform: translate3d(0, 0, 0);
  }
  .slide-exit.slide-exit-active {
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.75s;
  }
`;

const AuthRouter = ({ location }) => (
  <Flex width="100vw" height="100vh" position="absolute">
    <StyledTransitionGroup>
      {/* <CSSTransition key={location.key} classNames="slide" timeout={{ enter: 1000, exit: 1000 }}>
        
      </CSSTransition> */}
      <Switch location={location}>
        <UnProtectedRoute exact path="/login" component={LoginScreen} />
        <UnProtectedRoute exact path="/signUpBeta" component={SignUpBetaScreen} />
        <UnProtectedRoute exact path="/register" component={RegisterScreen} />
        <UnProtectedRoute exact path="/forgotPassword" component={ForgottenPasswordScreen} />

        <UnProtectedRoute exact path="/onboarding/user-role" component={OnboardingFirstScreen} />
        <UnProtectedRoute exact path="/onboarding/profile-details" component={OnboardingSecondScreen} />
        <UnProtectedRoute exact path="/onboarding/card-details" component={OnboardingThirdScreen} />
        <UnProtectedRoute exact path="/onboarding/confirm" component={OnboardingFourthScreen} />
        <UnProtectedRoute exact path="/onboarding/signup-error" component={UnsuccessfulAccountCreationScreen} />
        <UnProtectedRoute exact path="/qr_redirect" component={QRCodeRedirectScreen} />
        <UnProtectedRoute exact path="/paymentinvite/pay" component={PublicPaymentInviteScreen} />
        <UnProtectedRoute exact path="/paymentinvite/paid" component={PublicPaymentInviteConfirmationScreen} />
        <UnProtectedRoute exact path="/paymentinvite/status_update" component={PublicPaymentInviteScreen} />
        
        <UnProtectedRoute exact path="/viewingroom" component={PublicViewingRoomScreen} />
        <UnProtectedRoute exact path="/viewingroom/details" component={PublicViewingRoomDetailsScreen} />

        <UnProtectedRoute exact path="/discovery" component={PublicDiscoveryScreen} />
        <UnProtectedRoute exact path="/discovery/artworks/details" component={PublicDiscoveryScreen} />
        <UnProtectedRoute exact path="/discovery/artworks/favourites" component={PublicDiscoveryScreen} />
        <UnProtectedRoute exact path="/discovery/artworks/favourites/details" component={PublicDiscoveryScreen} />

        <ProtectedRoute exact path="/pay" component={PaymentScreen} />
        <ProtectedRoute exact path="/welcome" component={WelcomeScreen} />

        <Redirect from="*" to="/artworks" />
      </Switch>
    </StyledTransitionGroup>
  </Flex>
);

export default AuthRouter;
