import { Box, Text } from "../fundamentals";
import { Button } from "../elements";
import { Headings, ButtonLabels, Notifications } from "../../localisation";
import { Typography } from "@mui/material";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import {useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {closeModal, openModal} from "../../store/actions";

const headerStyle = (isMobile) => {
    return {
      fontFamily: "DM Sans",
      fontSize: isMobile ? "20px" : "32px",
      lineHeight: isMobile ? "26px" : "40px",
      fontWeight: "400",
      marginBottom: isMobile ? "12px" : "24px"
    };
  };

const SuccessfulUpgradeModal = ({isUpgradeFromPaymentInvite}) => {
    const isMobile = useIsScreenMobile();
    const history = useHistory();
    const dispatch = useDispatch();

    const handleClick = () => {
        window.dataLayer.push({'event': 'upgradeSuccessful'})
        dispatch(closeModal());
        if (isUpgradeFromPaymentInvite) {
          dispatch(openModal("payment_invite"));
        } else {
          history.push("/artworks");
        };
      };

    return (
        <Box>
            <Typography sx={headerStyle(isMobile)}>{Headings.success}</Typography>
            <Text mb={isMobile? "s" : "m"}>{Notifications.upgradeSuccessful.text}</Text>
            <Button
                type="button"
                buttonStyle="form"
                size="medium"
                onClick={handleClick}
            >
                {isUpgradeFromPaymentInvite ? ButtonLabels.continueWithPaymentInvite : ButtonLabels.goToArtworks}
            </Button>
        </Box>
    )
};

export { SuccessfulUpgradeModal };