import produce from "immer";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import {
  CREATE_VIEWING_ROOM_LOCALLY,
  CREATE_VIEWING_ROOM_SUCCESS,
  GET_VIEWING_ROOMS_SUCCESS,
  GET_VIEWING_ROOM_REQUEST,
  GET_VIEWING_ROOM_SUCCESS,
  GET_VIEWING_ROOM_FAILURE,
  CHECK_VIEWING_ROOM_FOR_PASSWORD,
  CHECK_VIEWING_ROOM_FOR_PASSWORD_SUCCESS,
  EDIT_VIEWING_ROOM_LOCALLY,
  EDIT_VIEWING_ROOM_SUCCESS,
  DELETE_VIEWING_ROOMS,
  DELETE_VIEWING_ROOM_SUCCESS,
  SAVE_VIEWING_ROOM_DRAFT_LOCALLY,
  SAVE_VIEWING_ROOM_DRAFT_SUCCESS,
  SIGN_OUT_SUCCESS,
} from "../actions/types";

const VIEWING_ROOM_INITIAL_STATE = {
  viewingRooms: { byId: {} },
  isLoading: false,
  loaded: false,
};

const viewingRoomReducer = (state = VIEWING_ROOM_INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case CREATE_VIEWING_ROOM_LOCALLY:
        return createViewingRoomLocally(draftState, action);
      case CREATE_VIEWING_ROOM_SUCCESS:
        return createViewingRoomSuccess(draftState, action);
      case GET_VIEWING_ROOMS_SUCCESS:
        return getViewingRoomsSuccess(draftState, action);
      case GET_VIEWING_ROOM_REQUEST:
        return getViewingRoomRequest(draftState, action);
      case GET_VIEWING_ROOM_SUCCESS:
        return getViewingRoomSuccess(draftState, action);
      case GET_VIEWING_ROOM_FAILURE:
        return getViewingRoomFailure(draftState, action);
      case CHECK_VIEWING_ROOM_FOR_PASSWORD:
        return checkViewingRoomForPassword(draftState, action);
      case CHECK_VIEWING_ROOM_FOR_PASSWORD_SUCCESS:
        return checkViewingRoomForPasswordSuccess(draftState, action);
        case EDIT_VIEWING_ROOM_LOCALLY:
          return editViewingRoomLocally(draftState, action);
        case EDIT_VIEWING_ROOM_SUCCESS:
          return editViewingRoomSuccess(draftState, action);
        case DELETE_VIEWING_ROOMS:
        return deleteViewingRooms(draftState, action);
      case DELETE_VIEWING_ROOM_SUCCESS:
        return deleteViewingRoomSuccess(draftState, action);
        case SAVE_VIEWING_ROOM_DRAFT_LOCALLY:
        return saveViewingRoomDraftLocally(draftState, action);
      case SAVE_VIEWING_ROOM_DRAFT_SUCCESS:
        return saveViewingRoomDraftSuccess(draftState, action);
      case SIGN_OUT_SUCCESS:
        return VIEWING_ROOM_INITIAL_STATE;
      default:
        return state;
    }
  });
};

const createViewingRoomLocally = (draftState, action) => {
  const { tempViewingRoomId, ...restOfViewingRoom } = action.payload;
  draftState.viewingRooms.byId[tempViewingRoomId] = {
    id: tempViewingRoomId,
    ...restOfViewingRoom,
    isFromServer: false,
    isModified: false,
    isActive: true,
  };
};

const createViewingRoomSuccess = (draftState, action) => {
  const { _id: viewingRoomId, tempViewingRoomId, _user } = action.payload;

  draftState.viewingRooms.byId[viewingRoomId] = { ...draftState.viewingRooms.byId[tempViewingRoomId] };
  draftState.viewingRooms.byId[viewingRoomId]._id = viewingRoomId;
  draftState.viewingRooms.byId[viewingRoomId].isFromServer = true;
  draftState.viewingRooms.byId[viewingRoomId]._user = _user;
  delete draftState.viewingRooms.byId[tempViewingRoomId];
};

const getViewingRoomsSuccess = (draftState, action) => {
  action.payload.forEach((viewingRoom) => {
    draftState.viewingRooms.byId[viewingRoom._id] = {
      ...viewingRoom,
      isFromServer: true,
      isModified: false,
    };
  });
};

const getViewingRoomRequest = (draftState, action) => {
  return {
    ...draftState,
    isLoading: true,
    loaded: false,
  };
};

const getViewingRoomSuccess = (draftState, action) => {
  const { artworks, ...restViewingRoom } = action.payload;

  const artworksList = artworks.map(({ images, ...artwork }) => {
    const imageList = images.map((image) => {
      return { key: uuid(), ...image, isFromServer: true, isModified: false, isActive: true };
    });
    return {
      ...artwork,
      images: _.sortBy(imageList, ["sortIndex"]),
    };
  });

  draftState = {
    viewingRooms: {
      byId: {
        [restViewingRoom._id]: {
          sFromServer: true,
          isModified: false,
          isActive: true,
          artworks: artworksList,
          ...restViewingRoom,
        },
      },
    },
    isLoading: false,
    loaded: true,
  };
  return draftState;
};

const getViewingRoomFailure = (draftState, action) => {
  return {
    ...draftState,
    isLoading: false,
    loaded: true,
  };
};

const checkViewingRoomForPassword = (draftState, action) => {
  return {
    ...draftState,
    isLoading: true,
    loaded: false,
  };
};
const checkViewingRoomForPasswordSuccess = (draftState, action) => {
  return {
    ...draftState,
    isLoading: false,
    loaded: true,
  };
};

const editViewingRoomLocally = (draftState, action) => {
  const { ...oldViewingRoomData } = draftState.viewingRooms.byId?.[action.payload._id];
  const { artworks, ...newViewingRoomData } = action.payload;

  draftState.viewingRooms.byId[action.payload._id] = {
    ...oldViewingRoomData,
    artworks: artworks,
    ...newViewingRoomData,
    isModified: true,
  };
};

const editViewingRoomSuccess = (draftState, action) => {
  const { viewingRoomId } = action.payload;

  draftState.viewingRooms.byId[viewingRoomId].isModified = false;
};

const deleteViewingRooms = (draftState, action) => {
  const { viewingRoomIds } = action.payload;

  viewingRoomIds.forEach((viewingRoomId) => {
    draftState.viewingRooms.byId[viewingRoomId].isDisplayed = false;
  });
};

const deleteViewingRoomSuccess = (draftState, action) => {
  delete draftState.viewingRooms.byId[action.payload.viewingRoomId];
};

const saveViewingRoomDraftLocally = (draftState, action) => {
  const { tempViewingRoomId, ...restOfViewingRoom } = action.payload;
  draftState.viewingRooms.byId[tempViewingRoomId] = {
    id: tempViewingRoomId,
    ...restOfViewingRoom,
    isFromServer: false,
    isModified: false,
    isActive: false,
  };
};

const saveViewingRoomDraftSuccess = (draftState, action) => {
  const { _id: viewingRoomId, tempViewingRoomId, _user } = action.payload;

  draftState.viewingRooms.byId[viewingRoomId] = { ...draftState.viewingRooms.byId[tempViewingRoomId] };
  draftState.viewingRooms.byId[viewingRoomId]._id = viewingRoomId;
  draftState.viewingRooms.byId[viewingRoomId].isFromServer = true;
  draftState.viewingRooms.byId[viewingRoomId]._user = _user;
  delete draftState.viewingRooms.byId[tempViewingRoomId];
};

export default viewingRoomReducer;
