import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../store/selectors";
import { Flex, Box, Text, Icon } from "../fundamentals";
import { Button, PageTransitions } from "../elements";
import { Headings, Instructions, FeaturesInfo, ButtonLabels, Testimonials } from "../../localisation";
import {
  ROLE_ARTIST,
  ROLE_COLLECTOR,
  ROLE_GALLERIST,
  ROLE_ART_PROFESSIONAL,
  ROLE_ART_INSTITUTION,
} from "../../constants";
import { useIsScreenMobile, useIsScreenDesktop } from "../hooks/useMediaQuery";
import { Typography } from "@mui/material";
import { RiVipCrownFill } from "react-icons/ri";
import { stripePromise } from "../../config";
import { Elements } from "@stripe/react-stripe-js";
import { BillingPeriodForm } from "../forms/UpgradeForm/BillingPeriodForm";
import { PaymentForm } from "../forms/UpgradeForm/PaymentForm";
import { useLocation } from "react-router-dom";
import { getDiscountRequest } from "../../store/actions";
import CircularProgress from "@mui/material/CircularProgress";

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "24px",
    lineHeight: mobile ? "26px" : "32px",
    marginBottom: mobile ? "24px" : "30px",
    fontWeight: "400",
  };
};

const instructionTextStyle = (mobile) => {
  return {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px",
  };
};

const UpgradeIcon = () => {
  return (
    <Flex borderRadius="50%" bg="amber.50" justifyContent="center" alignItems="center" p="xxxs" mr="xxxs">
      <RiVipCrownFill size="16" color="#FFEFC6" />
    </Flex>
  );
};

const Testimonial = ({ role }) => {
  // Displayed testimonial dependent on user's role
  const roleType =
    role === ROLE_COLLECTOR || role === ROLE_ART_PROFESSIONAL
      ? ROLE_COLLECTOR
      : role === ROLE_GALLERIST || ROLE_ART_INSTITUTION
      ? ROLE_GALLERIST
      : ROLE_ARTIST;
  return (
    <Flex flexDirection="column" bg="blue.60" width="240px" p="36px 44px" borderRadius="12px" color="white">
      <Text fontSize="smaller" lineHeight="button" mb="xs">
        {Testimonials[roleType].quote}
      </Text>
      <Text fontFamily="bodyDmSans" fontSize="10px" fontWeight="700">
        {Testimonials[roleType].name}
      </Text>
      <Text fontFamily="bodyDmSans" fontSize="10px">
        {Testimonials[roleType].role}, {Testimonials[roleType].city}
      </Text>
    </Flex>
  );
};

const benefits = [
  {
    title: FeaturesInfo.exportDocuments,
    description: FeaturesInfo.exportDocumentsDesc,
    icon: "Download",
  },
  {
    title: FeaturesInfo.viewingRooms,
    description: FeaturesInfo.viewingRoomsDesc,
    icon: "Globe",
  },
  {
    title: FeaturesInfo.biographicalInfo,
    description: FeaturesInfo.biographicalInfoDesc,
    icon: "Edit",
  },
  {
    title: FeaturesInfo.contacts,
    description: FeaturesInfo.contactsDescShort,
    icon: "Users",
  },
  {
    title: FeaturesInfo.reducedFees,
    description: FeaturesInfo.reducedFeesDesc,
    icon: "DollarSign",
  },
];

const UpgradeModal = () => {
  const isMobile = useIsScreenMobile();
  const isDesktop = useIsScreenDesktop();
  const location = useLocation();
  const dispatch = useDispatch();
  const [page, setPage] = useState("benefits");
  const user = useSelector(selectUser);
  const formState = useSelector((state) => state.form);
  // The values property has a currency property by default. Any other property indicates that the user started creating a payment invite before coming to the upgrade modal
  const isUpgradeFromPaymentInvite =
    formState.payment_invite && Object.keys(formState.payment_invite?.create?.values).length > 1 ? true : false;
  const params = new URLSearchParams(location.search);
  const discountCode = params.get("code");

  const goToPage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (discountCode) {
      dispatch(getDiscountRequest(discountCode));
    }
  }, []);

  return (
    <Box>
      <PageTransitions
        page={page}
        initialWidth={isDesktop ? "880" : isMobile ? "325" : "440"}
        transition="width 0.2s ease-out"
        timeout="0"
        childAlignment="center"
      >
        {/* Screen 1 */}
        <Flex
          page="benefits"
          width={isDesktop ? "880px" : isMobile ? "325px" : "440px"}
          borderRadius="10px"
          boxShadow="medium"
          justifyContent={user?.account?.discount?.isLoading && "center"}
        >
          {user?.account?.discount?.isLoading ? (
            <Flex justifyContent="center" alignItems="center" pb="mega" minHeight="300px">
            <CircularProgress sx={{ color: "#5E54FF" }} />
          </Flex>
          ) : (
            <Flex>
              <Flex flexDirection="column" width={isDesktop && "50%"} bg="white" p="xxl">
                <Flex alignItems="center" marginBottom="16px">
                  <UpgradeIcon />
                  <Text style={instructionTextStyle(isMobile)}>{Instructions.upgrade}</Text>
                </Flex>
                <Typography sx={headerTextStyle(isMobile)}>{Headings.upgradeHeading}</Typography>
                <Flex flexDirection="column" fontSize="small">
                  <Text mb="18px">{Instructions.upgradePrompt}</Text>
                  {benefits.map((benefit) => {
                    return (
                      <Flex mb="18px" key={benefit.title} alignItems="start">
                        <Icon icon={benefit.icon} size="18" color="black" mr="17px" />
                        <Box>
                          <Text mb="xxxs" fontWeight="500">
                            {benefit.title}
                          </Text>
                          <Text>{benefit.description}</Text>
                        </Box>
                      </Flex>
                    );
                  })}
                  <Text mb="18px">And many more like multi edit and Dropbox integration.</Text>
                </Flex>
                <Button
                  buttonStyle="form"
                  size={isMobile ? "small" : "medium"}
                  fontSize={isMobile ? "13px" : "16px"}
                  mt="xs"
                  onClick={() => setPage("billingPeriod")}
                >
                  {ButtonLabels.upgrade}
                </Button>
              </Flex>
              {isDesktop && (
                <Flex width="50%" bg="blue.20" alignItems="center" justifyContent="center">
                  <Testimonial role={user.role} />
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
        <BillingPeriodModal page="billingPeriod" goToPage={goToPage} />
        <PaymentModal page="payment" goToPage={goToPage} isUpgradeFromPaymentInvite={isUpgradeFromPaymentInvite} />
      </PageTransitions>
    </Box>
  );
};

// Screen 2
const BillingPeriodModal = ({ page, goToPage }) => {
  return (
    <Flex
      page={page}
      flexDirection="column"
      width="680px"
      bg="white"
      borderRadius="10px"
      boxShadow="medium"
      p="50px 48px"
    >
      <BillingPeriodForm
        headerTextStyle={headerTextStyle}
        instructionTextStyle={instructionTextStyle}
        upgradeIcon={UpgradeIcon()}
        goToPage={goToPage}
      />
    </Flex>
  );
};

// Screen 3
const PaymentModal = ({ page, plan, goToPage, isUpgradeFromPaymentInvite }) => {
  // Global State
  const user = useSelector(selectUser);
  const isDesktop = useIsScreenDesktop();

  return (
    <Flex page={page} width="900px" borderRadius="10px" boxShadow="medium">
      <Flex flexDirection="column" width={isDesktop && "50%"} bg="white" p="xxl">
        <Elements stripe={stripePromise}>
          <PaymentForm
            headerTextStyle={headerTextStyle}
            instructionTextStyle={instructionTextStyle}
            upgradeIcon={UpgradeIcon()}
            goToPage={goToPage}
            plan={plan}
            isUpgradeFromPaymentInvite={isUpgradeFromPaymentInvite}
          />
        </Elements>
      </Flex>

      {isDesktop && (
        <Flex width="50%" bg="blue.20" alignItems="center" justifyContent="center">
          <Testimonial role={user.role} />
        </Flex>
      )}
    </Flex>
  );
};

export { UpgradeModal };
