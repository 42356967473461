import React, { useState, useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from "@mui/material";
import { ChevronDown, XCircle } from "react-feather";
import { ReactHookFormInput } from "../Input";
import { ReactHookFormInputSelect } from "../InputSelect";
import { ButtonLabels, InputLabels, Headings, InputErrors } from "../../../localisation";
import CountryData from "country-region-data";
import { useSelector } from "react-redux";
import { selectUser, selectUserArtist } from "../../../store/selectors";
import { Flex, Icon } from "../../fundamentals";
import { Popover } from "../Popover";

const fieldStyle = {
  flexGrow: 1,
  marginBottom: "-1px",
  "&:hover": {
    height: "49px",
    marginBottom: "-1px",
  },
};

const inputSelectStyle = {
  height: "50px",
  marginBottom: "-1px",
  minWidth: "220px",
};

const accordionStyle = {
  border: "1px solid #C3C2C9",
  borderRadius: "7px",
  width: "828px",
  marginBottom: "30px",
  boxShadow: "none",
  "&:before": {
    content: "none",
  },
};

const labelStyle = {
  width: "151px",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#6A6870",
  fontFamily: "Inter",
};

const stackStyle = {
  borderBottom: "1px solid #C3C2C9",
  alignItems: "center",
  justifyContent: "space-between",
  height: "50px",
};

const headerTextStyle = {
  fontSize: "20px",
  lineHeight: "28px",
  fontFamily: "Inter",
  color: "#2B2A2E",
};

const ArtistGeneralFormSection = ({ control, errors, setValue, watchPublishToDiscovery, watchYearDeath, watchCityDeath, watchCountryDeath }) => {
  const user = useSelector(selectUser);
  const artist = useSelector(selectUserArtist);
  const [namePopoverAnchorEl, setNamePopoverAnchorEl] = useState(null);
  const [sectionErrors, setSectionErrors] = useState(false);

  const formSectionFields = [
    "name",
    "yearBirth",
    "cityBirth",
    "countryBirth",
    "yearDeath",
    "cityDeath",
    "countryDeath",
    "currentCity",
    "currentCountry",
    "instagramUrl",
    "websiteUrl",
    "bio",
  ];

  const clearAllFields = () => {
    let clearableFields = [];
    if (user.role === "artist") {
      clearableFields = formSectionFields.filter((fieldName) => fieldName !== "name");
    } else {
      clearableFields = formSectionFields;
    }
    clearableFields.map((name) => setValue(name, ""));
  };

  useEffect(() => {
    const sectionErrorExists = formSectionFields.some((field) => errors.hasOwnProperty(field));
    if (sectionErrorExists) {
      setSectionErrors(true);
    } else {
      setSectionErrors(false);
    }
  }, [Object.keys(errors)]);

  return (
    <Accordion
      sx={{
        "&.MuiAccordion-root": {
          ...accordionStyle,
          border: sectionErrors && "1px solid red",
        },
      }}
      defaultExpanded={sectionErrors || true}
    >
      <AccordionSummary
        sx={{
          padding: "32px",
          height: "92px",
          backgroundColor: "white",
          borderRadius: "7px",
        }}
        expandIcon={<ChevronDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={headerTextStyle}>{Headings.general}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0px 32px 32px 32px" }}>
        <Stack direction="column">
          <Box marginBottom={errors.name && "20px"}>
            <Stack sx={stackStyle} direction="row">
              <Flex width="151px" alignItems="center">
                <Typography sx={{ ...labelStyle, width: "fit-content" }}>{InputLabels.name}</Typography>
                {user.role === "artist" && (
                  <Icon
                    icon="HelpCircle"
                    size="12"
                    color="blue.60"
                    marginLeft="xxxs"
                    strokeWidth="2px"
                    cursor="pointer"
                    onClick={(e) => setNamePopoverAnchorEl(e.currentTarget)}
                  />
                )}
                <Popover
                  popoverAnchorEl={namePopoverAnchorEl}
                  setPopoverAnchorEl={setNamePopoverAnchorEl}
                  text="If you wish to change your name, please go to Account & Settings"
                  icon="Info"
                />
              </Flex>
              <ReactHookFormInput
                name="name"
                control={control}
                errors={errors}
                disabled={user.role === "artist"}
                isRequired
                errorMessage={InputErrors.noArtistName}
                type="text"
                customRootStyles={{
                  flexGrow: 1,
                  marginBottom: "-1px",
                  position: "relative",
                  bottom: errors.name && "-12px",
                  "&:hover": {
                    marginBottom: errors.name ? "" : "-2px",
                  },
                }}
                setValue={setValue}
              />
            </Stack>
          </Box>

          <Box>
            <Stack
              sx={{
                ...stackStyle,
                marginBottom: errors.yearBirth || errors.cityBirth || errors.countryBirth ? "24px" : "",
              }}
              direction="row"
            >
              <Typography sx={labelStyle}>{InputLabels.born}</Typography>
              <ReactHookFormInput
                name="yearBirth"
                control={control}
                errors={errors}
                placeholder={InputLabels.year}
                type="number"
                customRootStyles={{
                  ...fieldStyle,
                  maxWidth: "120px",
                  bottom: errors.yearBirth && "-12px",

                  "&:hover": {
                    marginBottom: errors.yearBirth ? "" : "-2px",
                  },
                }}
                setValue={setValue}
                isRequired={watchPublishToDiscovery}
                errorMessage="Year required"
              />
              <ReactHookFormInput
                name="cityBirth"
                control={control}
                errors={errors}
                placeholder={InputLabels.city}
                type="text"
                customRootStyles={fieldStyle}
                setValue={setValue}
              />
              <ReactHookFormInputSelect
                name="countryBirth"
                control={control}
                errors={errors}
                customInputSelectStyle={{ ...inputSelectStyle, bottom: errors.countryBirth && "-10px" }}
                placeholder={InputLabels.country}
                options={CountryData.map((country) => ({
                  value: country.countryName,
                  label: country.countryName,
                  displayedLabel: country.countryShortCode,
                }))}
                isRequired={watchPublishToDiscovery}
                errorMessage="Country required"
              />
            </Stack>
          </Box>

          <Box>
            <Stack
              sx={{
                ...stackStyle,
                marginBottom: errors.yearDeath || errors.cityDeath || errors.countryDeath ? "24px" : "",
              }}
              direction="row"
            >
              <Typography sx={labelStyle}>{InputLabels.died}</Typography>
              <ReactHookFormInput
                name="yearDeath"
                control={control}
                errors={errors}
                placeholder={InputLabels.year}
                type="number"
                customRootStyles={{
                  ...fieldStyle,
                  maxWidth: "120px",
                  bottom: errors.yearDeath && "-12px",

                  "&:hover": {
                    marginBottom: errors.yearDeath ? "" : "-2px",
                  },
                }}
                setValue={setValue}
                isRequired={watchPublishToDiscovery && (watchCityDeath || watchCountryDeath)}
                errorMessage="Year required"
              />
              <ReactHookFormInput
                name="cityDeath"
                control={control}
                errors={errors}
                placeholder={InputLabels.city}
                type="text"
                customRootStyles={fieldStyle}
                setValue={setValue}
              />
              <ReactHookFormInputSelect
                name="countryDeath"
                control={control}
                errors={errors}
                customInputSelectStyle={inputSelectStyle}
                placeholder={InputLabels.country}
                options={CountryData.map((country) => ({
                  value: country.countryName,
                  label: country.countryName,
                  displayedLabel: country.countryShortCode,
                }))}
              />
            </Stack>
          </Box>
          <Stack sx={{
                ...stackStyle,
                marginBottom: errors.currentCity || errors.currentCountry ? "24px" : "",
              }} direction="row">
            <Typography sx={{ ...labelStyle, marginRight: "120px" }}>{InputLabels.livesAndWorks}</Typography>
            <ReactHookFormInput
              name="currentCity"
              control={control}
              errors={errors}
              placeholder={InputLabels.city}
              type="text"
              customRootStyles={fieldStyle}
              setValue={setValue}
              disabled={watchYearDeath || watchCityDeath || watchCountryDeath}
            />
            <ReactHookFormInputSelect
              name="currentCountry"
              control={control}
              errors={errors}
              customInputSelectStyle={{ ...inputSelectStyle, bottom: errors.currentCountry && "-10px" }}
              placeholder={InputLabels.country}
              isRequired={watchPublishToDiscovery && (!watchYearDeath && !watchCityDeath && !watchCountryDeath)}
              errorMessage="Country required"
              options={CountryData.map((country) => ({
                value: country.countryName,
                label: country.countryName,
                displayedLabel: country.countryShortCode,
              }))}
              disabled={watchYearDeath || watchCityDeath || watchCountryDeath}
            />
          </Stack>
          <Box>
            <Stack sx={stackStyle} direction="row">
              <Typography sx={labelStyle}>{InputLabels.instagramUrl}</Typography>
              <ReactHookFormInput
                name="instagramUrl"
                control={control}
                errors={errors}
                type="text"
                customRootStyles={fieldStyle}
                setValue={setValue}
              />
            </Stack>
          </Box>
          <Box>
            <Stack sx={stackStyle} direction="row">
              <Typography sx={labelStyle}>{InputLabels.website}</Typography>
              <ReactHookFormInput
                name="websiteUrl"
                control={control}
                errors={errors}
                type="text"
                customRootStyles={fieldStyle}
                setValue={setValue}
              />
            </Stack>
          </Box>
          <Box borderBottom="1px solid #C3C2C9" marginTop="15px">
            <Stack direction="column">
              <Typography sx={labelStyle}>{InputLabels.bio}</Typography>
              <ReactHookFormInput
                name="bio"
                control={control}
                errors={errors}
                type="text"
                isRequired={watchPublishToDiscovery}
                errorMessage="Bio required"
                customRootStyles={{
                  bottom: errors.bio && "-12px",
                  "&:hover": {
                    marginBottom: errors.bio ? "" : "-2px",
                  },
                }}
                customRootInputStyles={{
                  paddingTop: "0px",
                  "&:hover": {
                    borderBottom: "2px solid #C8CEFF",
                  },
                  "&:before": {
                    content: "none",
                  },
                  "&:after": {
                    borderBottom: "2px solid #5E54FF",
                  },
                  "&.Mui-focused": {
                    borderBottom: "none",
                    marginBottom: "-2px",
                  },
                }}
                customInputStyles={{
                  paddingLeft: "0px",
                }}
                setValue={setValue}
                multiline={true}
              />
            </Stack>
          </Box>
        </Stack>
        <Stack direction="row-reverse" alignItems="center" sx={{ marginTop: "24px" }}>
          <Button
            startIcon={
              <XCircle style={{ width: "16px", height: "16px", position: "relative", right: "-5px", top: "-1px" }} />
            }
            sx={{ paddingRight: "0", paddingTop: "0px", fontSize: "14px", fontWeight: 500 }}
            onClick={clearAllFields}
          >
            {ButtonLabels.clear}
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export { ArtistGeneralFormSection };
