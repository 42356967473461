import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Flex, Image, Text, Link, ImageWithCache, Icon } from "../fundamentals";
import { Button, Modal, ImageSwiper, Drawer } from "../elements";
import { useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { selectViewingRoomById } from "../../store/selectors";
import { openModal, createPaymentInviteRequest } from "../../store/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { ButtonLabels, UiLabels, InputValues, Headings } from "../../localisation";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  ImageViewerModal,
  PaymentInviteBuyerGeneratedGeneralInfoModal,
  PaymentInviteBuyerGeneratedShippingInfoModal,
  PaymentInviteBuyerGeneratedPaymentModal,
  PaymentInviteStatusModal,
  PaymentInviteBuyerOfferModal,
  SuccessfulBuyerActionModal,
  PaymentInviteBuyerEnquiryModal,
  AlertModal,
} from "../modals";
import { Tooltip } from "../react-material/Tooltip";
import { DEFAULT_ARTWORK_STATUSES } from "../../config";
import { useIsScreenMobile, useIsScreenMobileSmall } from "../hooks/useMediaQuery";
import { PaymentInviteBuyerDrawer } from "../react-material/drawers";

const StyledWorkDescription = styled(Text)`
  display: -webkit-box;
  max-width: 415px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
  color: #6a6870;
  margin-bottom: 16px;
`;

const StyledPublicViewingRoomDetailsScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

// Issue: https://github.com/styled-components/styled-components/issues/1198
const StyledArrowContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  padding: 16px;
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  &:hover {
    background-color: lightGrey;
  }
`;

const buyingWithSimplifyBenefits = [
  {
    icon: "Lock",
    description: "Secure Payment",
  },
  {
    icon: "UserCheck",
    description: "Verified Sellers",
  },
  {
    icon: "Star",
    description: "Vetted Artists",
  },
];

const RenderDimensions = ({ artwork }) => {
  const { dimensions } = artwork?._id?.artworkValues;
  return (
    <Box>
      {dimensions?.width && (
        <Flex color="grey.80">
          <Text mr="xxxs">{dimensions.width}</Text>
          <Text mr="xxxs">x</Text>
          <Text mr="xxxs">{dimensions.height}</Text>
          {dimensions?.depth && (
            <Flex>
              <Text mr="xxxs">x</Text>
              <Text mr="xxxs">{dimensions.height}</Text>
            </Flex>
          )}
          <Text>{dimensions.units}</Text>
        </Flex>
      )}
      {dimensions?.diameter && (
        <Flex color="grey.80">
          <Text mr="xxxs">{dimensions.diameter}</Text>
          <Text>{dimensions.units}</Text>
        </Flex>
      )}
      {dimensions?.variable && (
        <Flex color="grey.80">
          <Text>Variable dimensions</Text>
        </Flex>
      )}
    </Box>
  );
};

const PublicViewingRoomDetailsScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useIsScreenMobile();
  const isSmallMobile = useIsScreenMobileSmall();
  const params = new URLSearchParams(location.search);
  const [buyButtonClicked, setBuyButtonClicked] = useState(false);
  const [offerButtonClicked, setOfferButtonClicked] = useState(false);
  const [enquiryButtonClicked, setEnquiryButtonClicked] = useState(false);
  const viewingRoomState = useSelector((state) => state.viewing_room);
  const viewingRoomId = params.get("viewingRoomId");
  const viewingRoom = useSelector((state) => selectViewingRoomById(state, viewingRoomId));
  const artworkId = params.get("artworkId");
  const artwork = viewingRoom?.artworks?.filter((artwork) => artwork?._id?.id === artworkId)[0];
  const nameDisplayed = viewingRoom?._user?.hasOwnProperty("organisation")
    ? viewingRoom?._user?.organisation?.name
    : viewingRoom?._user?.name;
  const images = artwork?.images || [];
  const firstImage = images[0];
  const [isWorkDescriptionOpen, setIsWorkDescriptionOpen] = useState(false);
  const [isShippingAndTaxesOpen, setIsShippingAndTaxesOpen] = useState(false);
  const [isBuyingWithSimplifyOpen, setIsBuyingWithSimplifyOpen] = useState(false);
  const [onImageHover, setOnImageHover] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const paymentInviteState = useSelector((state) => state.payment_invite);
  const priceWithoutFee = artwork?._id?.artworkValues?.totalPublicPrice?.value / (1 + viewingRoom?.processingFee / 100);

  useEffect(() => {
    if (!viewingRoom) {
      history.push(`/viewingroom?viewingRoomId=${viewingRoomId}`);
    }
  }, []);

  //Create Payment Invite when the Buy button is clicked
  const handleBuyClick = () => {
    setBuyButtonClicked(true);
    setOfferButtonClicked(false);
    setEnquiryButtonClicked(false);

    return dispatch(
      createPaymentInviteRequest({
        artworkIds: [artworkId],
        isBuyerGenerated: true,
        isMarketPlace: false,
        isMobile,
      })
    );
  };

  //Create Payment Invite when the Offer button is clicked
  const handleOfferClick = () => {
    setBuyButtonClicked(false);
    setOfferButtonClicked(true);
    setEnquiryButtonClicked(false);

    return dispatch(
      createPaymentInviteRequest({
        artworkIds: [artworkId],
        isBuyerGenerated: true,
        isMarketPlace: false,
        isOffer: true,
        isMobile,
      })
    );
  };

  //Create Payment Invite when the Enquiry button is clicked
  const handleEnquiryClick = () => {
    setBuyButtonClicked(false);
    setOfferButtonClicked(false);
    setEnquiryButtonClicked(true);

    return dispatch(
      createPaymentInviteRequest({
        artworkIds: [artworkId],
        isBuyerGenerated: true,
        isMarketPlace: false,
        isEnquiry: true,
        isMobile,
      })
    );
  };

  return (
    <StyledPublicViewingRoomDetailsScreen>
      <Helmet>
        <title>Viewing Room | simplify.art</title>
        <meta name="description" content="Sign in to your simplify.art account to access your collection" />
      </Helmet>
      <Box>
        {/* Header */}
        <Flex
          padding={isMobile ? "12px 20px" : "24px 48px"}
          borderBottom={!isMobile && "2px solid #E0DFE3"}
          boxShadow={isMobile && "0px 2px 8px 0px rgba(0, 0, 0, 0.16)"}
        >
          <Text fontFamily="bodyDmSans" fontWeight="700" fontSize="small" mr="xs">
            {UiLabels.poweredBy}
          </Text>
          <Link href="https://www.simplify.art" target="_blank">
            <Image
              src={process.env.PUBLIC_URL + `/images/brand-identity/logo_horizontal-black.svg`}
              alt="logo"
              width="100px"
            />
          </Link>
        </Flex>
        {/* Main */}
        {viewingRoomState.isLoading || !viewingRoom?.artworks ? (
          <Flex justifyContent="center" alignItems="center" pb="mega" height="100vh">
            <CircularProgress sx={{ color: "#5E54FF" }} />
          </Flex>
        ) : (
          <Box>
            <Flex
              flexDirection={isMobile ? "column" : "row"}
              alignItems={!isMobile && "center"}
              justifyContent={!isMobile && "space-between"}
              padding={isMobile ? "24px 20px" : "24px 48px"}
              borderBottom={isMobile && "1px solid #E8E7EA"}
            >
              <Box>
                <Text fontFamily="Inter" mb="xxxs">
                  {nameDisplayed}
                </Text>
                <Text fontFamily="bodyDmSans" fontSize={isMobile ? "heading2" : "heading1"}>
                  {viewingRoom?.title}
                </Text>
              </Box>
              {/* <Flex>
                <Button buttonStyle="text" icon="ArrowLeft" size="small">
                  {ButtonLabels.prev}
                </Button>
                <Button buttonStyle="text" icon="ArrowRight" size="small" flip={true}>
                  {ButtonLabels.next}
                </Button>
              </Flex> */}
              <Tooltip title="Back to All">
                <Button
                  buttonStyle="text"
                  icon={!isMobile && "Grid"}
                  size="small"
                  iconCursor="pointer"
                  padding="0px"
                  fontWeight="500"
                  marginTop={isMobile && "xxs"}
                  onClick={() => history.push(`/viewingroom?viewingRoomId=${viewingRoomId}`)}
                >
                  {isMobile && ButtonLabels.backToAll}
                </Button>
              </Tooltip>
            </Flex>
            <Box pr={!isMobile && "xxl"} pl={!isMobile && "xxl"} padding={isMobile && "20px"}>
              <Flex alignItems="start" flexDirection={isMobile ? "column" : "row"}>
                {/* Artwork Image */}
                {isMobile && images.length > 1 && <ImageSwiper images={images} />}
                {isMobile && images.length === 1 && (
                  <Flex width="100%" justifyContent="center">
                    <ImageWithCache
                      image={firstImage}
                      artworkId={artwork?.artworkId?.id}
                      urlSuffix="/medium.jpg"
                      maxWidth="100%"
                      userSelect="none"
                      imageKey={images[currentImageIndex].key}
                      cursor={onImageHover && "zoom-in"}
                    />
                  </Flex>
                )}
                {!isMobile && (
                  <Flex
                    onMouseEnter={() => setOnImageHover(true)}
                    onMouseLeave={() => setOnImageHover(false)}
                    mr="50px"
                    alignItems="center"
                    justifyContent="space-between"
                    flex="1"
                  >
                    {/* Left Arrow Container */}
                    <Box>
                      {(!onImageHover || currentImageIndex === 0) && (
                        <Box p="s" width="20px" height="20px" mr="xl"></Box>
                      )}
                      {onImageHover && currentImageIndex !== 0 && (
                        <StyledArrowContainer
                          mr="xl"
                          onClick={() =>
                            setCurrentImageIndex(currentImageIndex === 0 ? images?.length - 1 : currentImageIndex - 1)
                          }
                        >
                          <Icon icon="ChevronLeft" color="grey.80" size="16" cursor="pointer" />
                        </StyledArrowContainer>
                      )}
                    </Box>
                    {/* Image */}
                    {artwork?.images?.length > 0 ? (
                      <ImageWithCache
                        image={currentImageIndex === 0 ? firstImage : artwork?.images[currentImageIndex]}
                        artworkId={artwork?._id?.id}
                        urlSuffix="/medium.jpg"
                        maxHeight="637px"
                        userSelect="none"
                        imageKey={artwork?._id?.id}
                        cursor={onImageHover && "zoom-in"}
                        onClick={() => dispatch(openModal("image_viewer"))}
                      />
                    ) : (
                      <Box pt="l" minWidth="130px" height="500px" mb="s">
                        <Text color="grey.60" fontSize="smaller">
                          No image available
                        </Text>
                      </Box>
                    )}
                    {/* Right Arrow Container */}
                    <Box>
                      {(!onImageHover || !(artwork?.images?.length > currentImageIndex + 1)) && (
                        <Box p="s" width="20px" height="20px" ml="xl"></Box>
                      )}
                      {onImageHover && artwork?.images?.length > currentImageIndex + 1 && (
                        <StyledArrowContainer
                          ml="xl"
                          onClick={() =>
                            setCurrentImageIndex(currentImageIndex === images?.length - 1 ? 0 : currentImageIndex + 1)
                          }
                        >
                          <Icon icon="ChevronRight" color="grey.80" size="16" cursor="pointer" />
                        </StyledArrowContainer>
                      )}
                    </Box>
                  </Flex>
                )}
                {/* Artwork Details */}
                <Box width={isMobile ? "100%" : "415px"} minWidth={!isMobile && "415px"}>
                  <Box mb="s">
                    <Text fontFamily="bodyDmSans" fontSize="heading2">
                      {artwork?._id?.artist.name}
                    </Text>
                    <Text fontFamily="bodyDmSans" fontSize="heading2" color="grey.80" fontStyle="italic">
                      {artwork?._id?.artworkValues?.title}
                    </Text>
                    <Text fontFamily="bodyDmSans" fontSize="heading2" color="grey.80">
                      {artwork?._id?.artworkValues?.year}
                    </Text>
                  </Box>
                  <Box mb="s">
                    <Text color="grey.80" mb="xxxs">
                      {artwork?._id?.artworkValues?.technique}
                    </Text>

                    {artwork?._id?.artworkValues?.hasOwnProperty("dimensions") && (
                      <RenderDimensions artwork={artwork} />
                    )}
                  </Box>
                  {viewingRoom?.showPrice && (
                    <Flex alignItems="baseline" mb="s">
                      <Text fontFamily="bodyDmSans" fontSize="heading2" mr="xxs">
                        {getSymbolFromCurrency(artwork?._id?.artworkValues?.totalPublicPrice?.currency)}
                        {!viewingRoom?.allowBuying && viewingRoom?.processingFee
                          ? Math.round(priceWithoutFee * 100) / 100
                          : artwork?._id?.artworkValues?.totalPublicPrice?.value
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Text>
                      <Text>{`incl. VAT  ${artwork?._id?.artworkValues?.vat}%`}</Text>
                    </Flex>
                  )}
                  <Box>
                    {artwork?._id?.status === "reserved" || artwork?._id?.status === "sold" ? (
                      <Box
                        width="fit-content"
                        p="xxs"
                        mb="xs"
                        borderRadius="4px"
                        backgroundColor={DEFAULT_ARTWORK_STATUSES[artwork?._id?.status].color}
                      >
                        {InputValues[artwork?._id?.status]}
                      </Box>
                    ) : (
                      <Box>
                        {viewingRoom?.allowBuying && (
                          <Flex>
                            <Button
                              buttonStyle="primary"
                              size="large"
                              fontWeight="500"
                              fontSize="body"
                              mb="xxs"
                              flex="1"
                              onClick={handleBuyClick}
                              disabled={paymentInviteState?.isLoading}
                              icon={paymentInviteState?.isLoading && buyButtonClicked ? "Loader" : null}
                              iconSpinning={true}
                              flip={true}
                              iconOffset="xs"
                            >
                              {ButtonLabels.buy}
                            </Button>
                          </Flex>
                        )}
                      </Box>
                    )}
                    <Flex flexDirection={isSmallMobile ? "column" : "row"} alignItems={isSmallMobile && "stretch"}>
                      {viewingRoom?.allowOffer &&
                        artwork?._id?.status !== "reserved" &&
                        artwork?._id?.status !== "sold" && (
                          <Button
                            buttonStyle="secondary"
                            size="large"
                            fontWeight="500"
                            fontSize="body"
                            mr={!isSmallMobile && "xxs"}
                            mb={isSmallMobile && "xxs"}
                            width={isSmallMobile && "100%"}
                            onClick={handleOfferClick}
                            disabled={paymentInviteState?.isLoading}
                            icon={paymentInviteState?.isLoading && offerButtonClicked ? "Loader" : null}
                            iconSpinning={true}
                            flip={true}
                            iconOffset="xs"
                            flex="1"
                            padding={isMobile && "12px"}
                          >
                            {ButtonLabels.makeOffer}
                          </Button>
                        )}
                      <Button
                        buttonStyle="secondary"
                        size="large"
                        fontWeight="500"
                        fontSize="body"
                        width={isSmallMobile && "100%"}
                        onClick={handleEnquiryClick}
                        disabled={paymentInviteState?.isLoading}
                        icon={paymentInviteState?.isLoading && enquiryButtonClicked ? "Loader" : null}
                        iconSpinning={true}
                        flip={true}
                        iconOffset="xs"
                        flex="1"
                        padding={isMobile && "12px"}
                      >
                        {ButtonLabels.sendAnEnquiry}
                      </Button>
                    </Flex>
                  </Box>
                  {/* Work Description */}
                  {viewingRoom?.showWorkDescription && (
                    <Box mt="36px" pt="s" pb="s" borderTop="1px solid #DBDADF" borderBottom="1px solid #DBDADF">
                      <Text mb="xs">{UiLabels.aboutTheWork}</Text>

                      {isWorkDescriptionOpen ? (
                        <Box>
                          <Text mb="s" color="grey.80" fontSize="small">
                            {artwork?._id?.artworkValues?.workDescription}
                          </Text>
                          <Button
                            buttonStyle="text"
                            size="small"
                            icon="ChevronUp"
                            p="0"
                            onClick={() => setIsWorkDescriptionOpen(false)}
                          >
                            {ButtonLabels.showLess}
                          </Button>
                        </Box>
                      ) : (
                        <Box>
                          <StyledWorkDescription> {artwork?._id?.artworkValues?.workDescription}</StyledWorkDescription>
                          <Button
                            buttonStyle="text"
                            size="small"
                            icon="ChevronDown"
                            p="0"
                            onClick={() => setIsWorkDescriptionOpen(true)}
                          >
                            {ButtonLabels.showMore}
                          </Button>
                        </Box>
                      )}
                    </Box>
                  )}
                  {/* Shipping and taxes details */}
                  {viewingRoom?.allowBuying && (
                    <Box pt="s" pb="s" borderBottom="1px solid #DBDADF">
                      <Flex
                        justifyContent="space-between"
                        cursor="pointer"
                        onClick={() => setIsShippingAndTaxesOpen((prevValue) => !prevValue)}
                      >
                        <Text>{UiLabels.shippingAndTaxes}</Text>
                        <Button
                          buttonStyle="text"
                          size="medium"
                          icon={isShippingAndTaxesOpen ? "ChevronUp" : "ChevronDown"}
                          iconCursor="pointer"
                          color="grey.80"
                          hoverColor="grey.80"
                          p="0"
                        ></Button>
                      </Flex>
                      {isShippingAndTaxesOpen && (
                        <Box mt="s" color="grey.80" fontSize="small">
                          <Text mb="xxs">
                            Price is inclusive of VAT, our processing fee and all other costs such as framing or
                            packaging. Duties may apply on delivery.
                          </Text>
                          <Text>
                            {`Ships from ${artwork?._id?.artworkValues?.publicLocation?.address?.country}. You can choose to use our shipping partner Convelio, organise your own shipping or organise a personal handover. Convelio shipping costs are calculated at checkout.`}
                          </Text>
                        </Box>
                      )}
                    </Box>
                  )}
                  {/* Buying with simplify.art details */}
                  {viewingRoom?.allowBuying && (
                    <Box pt="s" pb="s" borderBottom={isBuyingWithSimplifyOpen ? null : "1px solid #DBDADF"}>
                      <Flex
                        justifyContent="space-between"
                        cursor="pointer"
                        onClick={() => setIsBuyingWithSimplifyOpen((prevValue) => !prevValue)}
                      >
                        <Text>{UiLabels.buyingWithSimplify}</Text>
                        <Button
                          buttonStyle="text"
                          size="medium"
                          icon={isBuyingWithSimplifyOpen ? "ChevronUp" : "ChevronDown"}
                          iconCursor="pointer"
                          color="grey.80"
                          hoverColor="grey.80"
                          p="0"
                        ></Button>
                      </Flex>
                      {isBuyingWithSimplifyOpen && (
                        <Box mt="xs">
                          {buyingWithSimplifyBenefits.map((benefit) => {
                            return (
                              <Flex color="grey.80" fontSize="small" mb="xxs" alignItems="center">
                                <Icon icon={benefit.icon} size="18" mr="xxxs" />
                                <Text>{benefit.description}</Text>
                              </Flex>
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Flex>
            </Box>
          </Box>
        )}
        {/* Modals for desktop screens */}
        <Modal id="image_viewer" closeButtonColor="white" closeButtonHoverColor="white" closeButtonBorderColor="white">
          <ImageViewerModal images={artwork?.images} initialIndex={currentImageIndex} />
        </Modal>
        <Modal
          id="payment_invite_buyer"
          p="0px"
          closeButtonColor="white"
          closeButtonBorderColor="white"
          closeButtonHoverColor="white"
        >
          <PaymentInviteBuyerGeneratedGeneralInfoModal placeId={viewingRoomId} />
        </Modal>
        <Modal
          id="payment_invite_buyer.shipping"
          p="0px"
          closeButtonColor="white"
          closeButtonBorderColor="white"
          closeButtonHoverColor="white"
        >
          <PaymentInviteBuyerGeneratedShippingInfoModal />
        </Modal>
        <Modal
          id="payment_invite_buyer.pay"
          p="0px"
          closeButtonColor="white"
          closeButtonBorderColor="white"
          closeButtonHoverColor="white"
        >
          <PaymentInviteBuyerGeneratedPaymentModal />
        </Modal>
        <Modal id="payment_invite_buyer.status" p="0px">
          <PaymentInviteStatusModal />
        </Modal>
        <Modal id="payment_invite_buyer.payment.success">
          <AlertModal
            title={Headings.processedPayment}
            message="You will receive an email confirmation with your invoice shortly."
            acceptButtonText={ButtonLabels.backToViewingRoom}
            onAccept={() => history.push(`/viewingroom?viewingRoomId=${viewingRoomId}`)}
          />
        </Modal>
        <Modal id="payment_invite_buyer.offer" p="0px">
          <PaymentInviteBuyerOfferModal />
        </Modal>
        <Modal
          id="payment_invite_buyer.offer"
          p="0px"
          closeButtonColor="white"
          closeButtonBorderColor="white"
          closeButtonHoverColor="white"
        >
          <PaymentInviteBuyerOfferModal placeId={viewingRoomId} />
        </Modal>
        <Modal id="payment_invite_buyer.offer_sent">
          <SuccessfulBuyerActionModal placeId={viewingRoomId} />
        </Modal>
        <Modal
          id="payment_invite_buyer.enquiry"
          p="0px"
          closeButtonColor="white"
          closeButtonBorderColor="white"
          closeButtonHoverColor="white"
        >
          <PaymentInviteBuyerEnquiryModal placeId={viewingRoomId} />
        </Modal>
        <Modal id="payment_invite_buyer.enquiry_sent">
          <SuccessfulBuyerActionModal />
        </Modal>
        {/* Drawers for mobile screens */}
        <Drawer id="payment_invite_buyer" padding="0">
          <PaymentInviteBuyerDrawer placeId={viewingRoomId} />
        </Drawer>
      </Box>
    </StyledPublicViewingRoomDetailsScreen>
  );
};

export default PublicViewingRoomDetailsScreen;
