import React from "react";
import { Field, reduxForm } from "redux-form";
import { updatePaymentInviteRequest } from "../../store/actions";
import { Box, Text } from "../fundamentals";
import { Button, Input } from "../elements";
import { InputLabels, InputErrors, ButtonLabels, Headings, Info } from "../../localisation";
import { selectPaymentInviteById, selectArtworkById } from "../../store/selectors";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

const Form = ({ handleSubmit, submitting, paymentInviteId }) => {
  const paymentInvite = useSelector((state) => selectPaymentInviteById(state, paymentInviteId));
  const artworkId = paymentInvite?.artworks[0]?._id?.id;
  const artwork = useSelector((state) => selectArtworkById(state, artworkId));

  const enquiryDetails = paymentInvite?.actions.find((item) => item.group === "enquiry");
  const message = enquiryDetails?.values.find((item) => item.key === "message").value;

  const submit = (values, dispatch) => {
    dispatch(
      updatePaymentInviteRequest({
        paymentInviteId,
        status: "EnquiryAnswered",
        actionId: enquiryDetails._id,
        ...values,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box width="540px">
        <Box>
          <Text size="heading3" mb="l" color="black">
            {Headings.enquiryReceived}
          </Text>
          <Text mb="xs" color="grey.80" fontSize="small">
            {Info.enquiryReceived(
              paymentInvite?.contacts[0]?._id?.firstName,
              paymentInvite?.contacts[0]?._id?.lastName,
              DateTime.fromISO(enquiryDetails?.date).toFormat("dd MMM yyyy"),
              artwork?.artworkValues?.title
            )}
          </Text>
          <Text mb="m">{message}</Text>
          <Box fontSize="small" mb="m">
            <Text color="blue.60" fontWeight="500" mb="xs">
              {InputLabels.yourResponse}
            </Text>
            <Field name="enquiryResponse" type="textarea" minHeight="100px" component={Input} />
          </Box>
          <Button
            type="submit"
            buttonStyle="primary"
            size="medium"
            mr="s"
            fontWeight="500"
            disabled={submitting}
            icon={submitting ? "Loader" : null}
            iconSpinning={true}
            flip={true}
            iconOffset="xs"
          >
            {ButtonLabels.sendResponse}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const { enquiryResponse } = values;

  if (!enquiryResponse) {
    errors.enquiryResponse = InputErrors.noMessage;
  }

  return errors;
};

const PaymentInviteEnquiryDetailsModal = reduxForm({
  form: "payment_invite.update",
  validate,
})(Form);

export { PaymentInviteEnquiryDetailsModal };
