import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Flex, Box, Text, Icon, Link } from "../fundamentals";
import { Button, PageTransitions } from "../elements";
import { Headings, ButtonLabels, Info, LinkLabels, UiLabels } from "../../localisation";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Typography } from "@mui/material";
import { SaleDetailsForm } from "../forms/PaymentInviteForms/SaleDetailsForm";
import { ContactDetailsForm } from "../forms/PaymentInviteForms/ContactDetailsForm";

const headerTextStyle = (mobile) => {
  return {
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "24px",
    lineHeight: mobile ? "26px" : "32px",
    marginBottom: "12px",
    fontWeight: "400",
  };
};

const PaymentInviteModal = () => {
  const isMobile = useIsScreenMobile();
  const [page, setPage] = useState("sale_details");
  const [isInfoTextShown, setIsInfoTextShown] = useState(true);

  const initialFormValues = {
    currency: "EUR",
  };

  const goToPage = (page) => {
    setPage(page);
  };

  return (
    <PageTransitions
      page={page}
      initialWidth="880px"
      transition="width 0.2s ease-out"
      timeout="0"
      childAlignment="center"
    >
      {/* Screen 1 */}
      <Box page="sale_details" width="880px">
        {/* Header */}
        <Flex justifyContent="space-between" alignItems="start" boxShadow="0px 15px 10px -15px rgba(0, 0, 0, 0.16)">
          <Box mr="115px" width="70%">
            <Typography sx={headerTextStyle(isMobile)}>{Headings.createPaymentInvite}</Typography>
            {isInfoTextShown && (
              <Text fontSize="14px" lineHeight="18px" marginBottom="12px" fontWeight="400" color="grey.80">
                {Info.paymentInviteInfo}
              </Text>
            )}
            <Flex justifyContent="space-between" width="100%" mb="24px">
              <Link href="https://www.simplify.art/payment-invites" target="_blank">
                <Button buttonStyle="text" fontFamily="Inter" icon="HelpCircle" iconSize="16" padding="0">
                  {LinkLabels.learnMorePaymentInvite}
                </Button>
              </Link>
              <Button
                buttonStyle="text"
                fontFamily="Inter"
                icon={isInfoTextShown ? "ChevronUp" : "ChevronDown"}
                iconSize="16"
                padding="0"
                onClick={() => setIsInfoTextShown(!isInfoTextShown)}
              >
                {isInfoTextShown ? ButtonLabels.hide : ButtonLabels.show}
              </Button>
            </Flex>
          </Box>
          <Flex justifyContent="end" alignItems="end" width="35%" fontSize="small" fontWeight="500">
            <Text color="blue.60" mr="xs">
              {UiLabels.editSaleDetails}
            </Text>
            <Icon icon="ChevronRight" size="14" color="grey.40" mr="xs"></Icon>
            <Text color="grey.40">{UiLabels.shareInvite}</Text>
          </Flex>
        </Flex>
        {/* Main & Footer */}
        <SaleDetailsForm initialValues={initialFormValues} goToPage={goToPage} />
      </Box>
      <ContactDetailsModal page="contact_details" goToPage={goToPage} />
    </PageTransitions>
  );
};

// Screen 2
const ContactDetailsModal = ({ page, goToPage }) => {
  const isMobile = useIsScreenMobile();
  const submitRef = useRef();
  const paymentInviteState = useSelector((state) => state.payment_invite);

  return (
    <Box page={page} width="860px">
      {/* Header */}
      <Flex justifyContent="space-between" alignItems="start">
        <Box mr="115px" width="60%" mb="l">
          <Typography sx={headerTextStyle(isMobile)}>{Headings.sharePaymentInvite}</Typography>
          <Text fontSize="14px" lineHeight="18px" fontWeight="400" color="grey.80">
            {Info.paymentInviteGenerated}
          </Text>
        </Box>
        <Flex justifyContent="end" alignItems="end" width="40%" fontSize="small" fontWeight="500">
          <Flex alignItems="end" mr="xs">
            <Icon icon="Check" size="16" color="blue.40" mr="xxxs"></Icon>
            <Text color="blue.40">{UiLabels.editSaleDetails}</Text>
          </Flex>
          <Icon icon="ChevronRight" size="14" color="grey.40" mr="xs"></Icon>
          <Text color="blue.60">{UiLabels.shareInvite}</Text>
        </Flex>
      </Flex>
      {/* Main */}
      <ContactDetailsForm
        submitRef={submitRef} // By passing the submitRef property the form can be submitted from the parent component
        goToPage={goToPage}
      />
      {/* Footer */}
      <Flex justifyContent="space-between" mt="30px">
        <Button
          type="button"
          buttonStyle="text"
          size="small"
          icon="ArrowLeft"
          iconSize="16"
          padding="0"
          alignSelf="center"
          onClick={() => goToPage("sale_details")}
        >
          {ButtonLabels.back}
        </Button>
        <Button
          buttonStyle="primary"
          size="small"
          fontWeight="500"
          onClick={() => submitRef.current.click()} //The form is submitted from the parent components
          disabled={paymentInviteState?.isLoading}
          icon={paymentInviteState?.isLoading ? "Loader" : null}
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
        >
          {ButtonLabels.share}
        </Button>
      </Flex>
    </Box>
  );
};

export { PaymentInviteModal };
