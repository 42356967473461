import React, { useEffect } from "react";
import styled from "styled-components";
import { Box, Flex, Image } from "../fundamentals";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { sendQrSource } from "../../store/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const StyledRedirectScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const QRCodeRedirectScreen = () => {
  const isMobile = useIsScreenMobile();
  const os = navigator.userAgent;
  const location = useLocation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  let source = 'postcard';
  let whereTo = 'mobile';
  const appStoreLink = "https://apps.apple.com/us/app/simplify-art/id1402067880";
  const googlePlayLink = "https://play.google.com/store/apps/details?id=art.simplify";
  const websiteLink = "https://www.simplify.art/";

  let artworkLink;
  if (process.env.REACT_APP_ENV == "development"){
    artworkLink = "https://app.development.simplify.art/"
  }else if (process.env.REACT_APP_ENV == "local"){
    artworkLink = "http://localhost:3000/"
  }else{
    artworkLink = "https://app.simplify.art/"
  }


  const redirectLink = os.includes("iPhone") || os.includes("iPad") || os.includes("Macintosh") ? appStoreLink : googlePlayLink;

  if (params.has('source')){
    source = params.get('source');
  };

  if (params.has('whereTo')){
    whereTo = params.get('whereTo');
  }

  dispatch(sendQrSource({source: source, whereTo: whereTo}));

  useEffect(() => {
      if (whereTo==='mobile'){
        setTimeout(() => window.location.replace(redirectLink), 0);
      }else{

        if(source==="discovery") {

          let extension='discovery';
          if (params.has('eventId')){
            extension= 'discovery?eventId='+params.get('eventId');
          }else if (params.has('artworkId')) {

            extension = 'discovery/artworks/details?artworkId=' + params.get('artworkId');
          }

          const discoveryLink = artworkLink+extension;

          setTimeout(() => window.location.replace(discoveryLink), 0);

        }else if(source==="viewingroom"){

          let extension='viewingroom';
          if (params.has('viewingRoomId')) {
            extension = extension + '?viewingRoomId=' + params.get('viewingRoomId');
          }

          const viewingroomLink = artworkLink+extension;
          setTimeout(() => window.location.replace(viewingroomLink), 0);
        }else {
          setTimeout(() => window.location.replace(websiteLink), 0);
        }
      };
    }, []);


  return (
    <StyledRedirectScreen bg="white">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Helmet>
          <title>Start simplifying | simplify.art</title>
          <meta
            name="description"
            content="Start simplifying with our art collection app for free!"
          />
        </Helmet>
        <Image
          src={
            process.env.PUBLIC_URL +
            `/images/brand-identity/${isMobile ? "logo-icon-black.svg" : "logo_horizontal-black.svg"}`
          }
          alt="logo"
          width={isMobile ? "25px" : "184px"}
          height={isMobile ? "35px" : ""}
          p={isMobile ? "35px 30px" : "35px 50px"}
        />
      </Box>

    </StyledRedirectScreen>
  );
};

export default QRCodeRedirectScreen;
