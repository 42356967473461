import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Avatar, Box, Button, Link, Stack, Typography } from "@mui/material";
import { Trash2, Edit2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { selectContactById, selectSelectedContactIds } from "../../store/selectors";
import { closeModal, openModal, removeSelectedContact, replaceSelectedContact } from "../../store/actions";

const actionButtonStyle = {
  bgcolor: "transparent",
  color: "#5E54FF",
  borderColor: "#5E54FF",
  borderRadius: "16px",
  padding: "6px 16px",
  fontSize: "14px",
  fontWeight: "500",
  textTransform: "initial",
  border: "1px solid #5E54FF",
  width: "96px",
  height: "28px",
};

const orgTextStyle = {
  color: "black",
  fontFamily: "Inter",
  fontSize: "14px",
  lineHeight: "18px",
};

const tagStyle = {
  fontSize: "12px",
  lineHeight: "16px",
  color: "#2B2A2E",
  backgroundColor: "#DEE1FF",
  padding: "4px 8px",
  fontFamily: "Inter",
  fontWeight: "500",
  borderRadius: "4px",
};

const smallTextStyle = { fontSize: "12px", lineHeight: "16px", color: "#6A6870" };

const textStyle = { color: "#5E54FF", fontSize: "14px", lineHeight: "18px" };

const ContactPreviewModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedContactId = useSelector(selectSelectedContactIds)[0];
  const [contact, setContact] = useState({});
  const tempContact = useSelector((state) => selectContactById(state, selectedContactId));

  const handleOnEditContactClick = () => {
    dispatch(replaceSelectedContact(contact.id));
    dispatch(closeModal());
    history.push("/contacts/edit");
  };

  const clearTempSelectedContact = () => {
    setContact(tempContact);
    dispatch(removeSelectedContact(selectedContactId));
  };

  useEffect(() => {
    if (tempContact) {
      clearTempSelectedContact();
    }
  }, [tempContact]);

  const instaUsernameHandler = () => {
    if (contact?.instagramUrl) {
      const username = `@${contact?.instagramUrl?.split(".com/")[1]?.slice(0, -1)}`;
      return username;
    }
  };


  return (
    <Box sx={{ width: "264px" }}>
      <Stack spacing={1} alignItems="center">
        <Avatar
          alt="avatar"
          src={process.env.PUBLIC_URL + "/images/icons/role-icons/artist_icon.png"}
          sx={{ width: 96, height: 96, marginBottom: "32px" }}
        />

        <Typography variant="h5" sx={{ fontFamily: "DM Sans", color: "#2B2A2E" }}>
          {`${contact?.firstName || ' '} ${contact?.lastName || ' '}`}
        </Typography>

        <Link href={contact?.instagramUrl} underline="none" target="_blank" rel="noopener" sx={orgTextStyle}>
          <Typography sx={textStyle}>{instaUsernameHandler()}</Typography>
        </Link>

        <Typography sx={textStyle}> {contact?.email}</Typography>
        {contact?.types?.length > 0 && (
          <Typography sx={orgTextStyle}>{`${contact?.types[0]} @ ${contact?.organisation || ''}`}</Typography>
        )}
        {contact?.types?.length > 0 && (
              <Stack direction="row" flexWrap="wrap" gap={"4px"}>
                {contact?.types?.map((tag) => (
                  <Typography sx={tagStyle}>{tag}</Typography>
                ))}
              </Stack>
            )}
      </Stack>
      <Stack spacing={1} alignItems="center" sx={{ marginTop: "32px" }}>
        <Typography sx={smallTextStyle}>{contact?.phone}</Typography>
        <Typography sx={smallTextStyle}>{contact?.address?.address1}</Typography>
        <Link
          href={contact?.websiteUrl}
          underline="none"
          target="_blank"
          rel="noopener"
          sx={{ color: "#9AA1FF", fontSize: "12px", lineHeight: "16px" }}
        >
          {contact?.websiteUrl}
        </Link>
       {contact?.vatNumber && <Typography sx={smallTextStyle}>VAT: {contact?.vatNumber}</Typography>}
      </Stack>
      <Stack justifyContent="center" direction="row" spacing={1.8} sx={{ marginTop: "32px", gap: "12px" }}>
        <Button
          onClick={() => dispatch(openModal("delete_contact"))}
          startIcon={<Trash2 style={{ width: "16px", height: "16px" }} />}
          sx={actionButtonStyle}
        >
          Delete
        </Button>
        <Button
          onClick={handleOnEditContactClick}
          startIcon={<Edit2 style={{ width: "16px", height: "16px" }} />}
          sx={{
            ...actionButtonStyle,
            bgcolor: "#7274FF",
            color: "white",
            borderColor: "#7274FF",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
            "&:hover": { backgroundColor: "#7274FF" },
          }}
        >
          Edit
        </Button>
      </Stack>
    </Box>
  );
};

export { ContactPreviewModal };
