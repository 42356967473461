import React from "react";
import styled from "styled-components";
import { space, layout, position, border } from "styled-system";
import { LazyLoadImage } from "react-lazy-load-image-component";
import GifPlayer from "react-gif-player";
import { WithImageCache } from "../hocs";
import "react-lazy-load-image-component/src/effects/opacity.css";

const StyledImage = styled(LazyLoadImage)`
  ${props => `
    user-select: ${props.userSelect};
    user-drag: ${props.userSelect};
    `}
  ${props => props.cursor && `cursor: ${props.cursor};`}
  ${space}
  ${layout}
  ${position}
  ${border}
`;

const StyledGif = styled(GifPlayer)`
  ${space}
  ${layout}
  ${position}
  ${border}
`;

const Image = ({ src, imageKey, ...props }) => {
  return <StyledImage key={imageKey} src={src} {...props} />;
};

export { Image };

const ImageWithCache = WithImageCache(Image);

export { ImageWithCache };

