import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Flex, Image, Text } from "../fundamentals";
import { Button } from "../elements";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { PaymentInviteBuyerConfirmationModal } from "../modals";
import { getPaymentInviteRequest } from "../../store/actions";
import { Instructions, LinkLabels } from "../../localisation";
import { selectPaymentInviteById } from "../..//store/selectors";

const StyledPublicPaymentInviteConfirmationScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const PublicPaymentInviteConfirmationScreen = () => {
  const isMobile = useIsScreenMobile();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const paymentInviteId = params.get("paymentInviteId");
  const paymentInvite = useSelector((state) => selectPaymentInviteById(state, paymentInviteId));
  // check status
  const latestStatus = paymentInvite?.status?.find((item) => item.is_latest === true)?.status_name;

  useEffect(() => {
    dispatch(getPaymentInviteRequest({paymentInviteId}));
  }, []);

  useEffect(() => {
    if (latestStatus !== "Created" && latestStatus !== "Viewed") {
      history.replace(`/paymentinvite/pay?paymentInviteId=${paymentInviteId}`);
    }
  }, []);

  return (
    <StyledPublicPaymentInviteConfirmationScreen bg="white">
      <Helmet>
        <title>Payment Invite | simplify.art</title>
        <meta name="description" content="Sign in to your simplify.art account to access your collection" />
      </Helmet>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Image
          src={
            process.env.PUBLIC_URL +
            `/images/brand-identity/${isMobile ? "logo-icon-black.svg" : "logo_horizontal-black.svg"}`
          }
          alt="logo"
          width={isMobile ? "25px" : "184px"}
          height={isMobile ? "35px" : ""}
          p={isMobile ? "35px 30px" : "35px 50px"}
        />

        <Box display="flex" alignItems="center" mr={isMobile ? "l" : "xxxl"}>
          {!isMobile && (
            <Text size="heading3" mr="s">
              {Instructions.noAccount}
            </Text>
          )}
          <Button
            buttonStyle="default"
            type="button"
            size={isMobile ? "small" : "medium"}
            fontSize={isMobile ? "13px" : "20px"}
            bg="white"
            p={isMobile ? "5px 12px" : "8.5px 20px"}
            fontWeight="700"
            onClick={(e) => {
              e.preventDefault();
              history.push("/register");
            }}
          >
            {LinkLabels.signup}
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" pb="mega">
        
      <Box bg="white" mt="mega" borderRadius="10px" boxShadow="mediumLarge">
        <PaymentInviteBuyerConfirmationModal paymentInviteId={paymentInviteId}/>
      </Box>
      </Box> 
    </StyledPublicPaymentInviteConfirmationScreen>
  );
};

export default PublicPaymentInviteConfirmationScreen;
