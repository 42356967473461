import LocalizedStrings from "react-localization";

export const ApiErrors = new LocalizedStrings({
  en: {
    generic: "Something went wrong. Please try again later",
    noEmailExists: "No account for this email address. Please sign up",
    emailExists: "Account with email already exists",
    noPasswordMatch: "Invalid password",
    invalidPasswordResetCode: "Reset code invalid",
    wrongCurrentPassword: "Current password incorrect",
    failedChangingPassword: "Could not change your password",
    failedToGetCardDetails: "Could not get card details",
  },
});

export const FormErrors = new LocalizedStrings({
  en: {
    checkFormErrors: "The form has highlighted errors you need to fix",
  },
});

export const InputErrors = new LocalizedStrings({
  en: {
    genericRequiredField: "This field is required",
    noEmail: "Enter e-mail address",
    invalidEmail: "Please enter a valid e-mail address",
    noPassword: "Enter your password",
    noConfirmPassword: "Enter your confirm password",
    insecurePassword: "Password must be at least 9 characters",
    confirmPasswordMatch: "Confirm password should match with new password",
    noResetCode: "Enter reset code from email",
    noName: "Enter your full name",
    noRole: "Select your role",
    noOrganisation: "Enter your organisation name",
    noTitle: "Add a title",
    noArtist: "Add an artist",
    notUserArtist: "Artworks in production must be yours",
    noYear: "Enter year",
    noTechnique : "Enter technique",
    noWidth: "Enter width",
    noHeight: "Enter height",
    noDepth: "Enter depth",
    noWeight: "Enter weight",
    noDimensions: "Enter width and height or diameter or select variable dimensions",
    noPlan: "Please select a plan",
    noAddress: "Please enter address",
    noCity: "Please enter city",
    noCountry: "Please select country",
    noPostalCode: "Please enter postal code",
    noCardNumber: "Please enter card number",
    noCardExpiryDate: "Please enter card expiry date",
    noCardCvc: "Please enter card cvc",
    noCurrentPassword: "Enter your current password",
    noNewPassword: "Enter your new password",
    numberYear: "Year must be a number",
    noCollection: "Select a collection",
    genericNoFieldPrefix: "Add a(n)",
    noWidthError: "Enter width",
    noHeightError: "Enter height",
    percetageNot100: "Percentages must add up to 100%",
    noContactGeneral: "Please provide either a First Name, Last Name or Organisation.",
    wrongUrl: "Please type a URL that begins with https:// or http:// or www.",
    invalidYear: "Please enter a valid year",
    noMessage: "Please type your message",
    noPrice: "Please enter a price for this artwork",
    invalidDate: "Please enter a valid date",
    noDate: "Please enter a date",
    futureDate: "Please enter a future date",
    pastDate: "Please enter a past date",
    noContact: "Please enter a contact or email",
    noFirstName: "Please enter your first name",
    noLastName: "Please enter your last name",
    noType: "Please select type",
    noPhoneNumber: "Please enter a phone number",
    invalidPhoneNumber: "Please type + followed by your country code",
    noState: "Please enter state",
    noWebsite: "Please enter website",
    noCompanyName: "Please enter company name",
    noTaxId: "Please enter tax ID",
    noRegistrationNumber: "Please enter company registration number",
    noTrackingNumber: "Please provide a tracking number",
    noArtistName: "Please type the artist's name",
    noDob: "Please enter your date of birth",
    noSubject: "Please type a subject",
    noVat: "Please enter VAT",
    maxVat100: "VAT should not be higher than 100%",
    multipleCurrencies: "Please select a common currency",
    typeForEachDocument: "Please select a type for each uploaded document",
    noTransportMode: "Please select a transport mode",
    noWorkDescription: "Please enter a public work description",
    noPublicPrice: "Please enter a public price",
    noPackaging: "Please add packaging details",
    noImages: "Please add an image",
    noOfferedAmount: "Please enter the amount you are willing to offer",
    noCsvFile: "Please select a CSV file to upload",
    maxOneFile: "Please upload only one file",
    wrongFileTypeCsv: "Please upload a CSV file",
    invalidFields: "One or more of the fields are invalid or incomplete. Please check all error messages."
  },
});

export const DynamicInputErrors = new LocalizedStrings({
  en: {
    minimumArtworkPrice: (currency) => `Minimum artwork price is ${currency}200.`
  },
});

export const Info = new LocalizedStrings({
  en: {
    passwordLength: "Password must be at least 9 characters long.",
    collectorDesc: "You are an art lover and/or you buy and collect art",
    artistDesc: "You make art",
    galleristDesc: "You own, manage or work in a gallery",
    artProfessionalDesc: "You are a curator, art advisor etc.",
    artInstitutionDesc: "You organize exhibitions",
    organisationInfo:
      "Optional. If not filled in, your name will be displayed on exported documents. You can change these details later in your account settings.",
    artistInfo:
      "This will be the default artist name for any artworks you add. You can change this later in your account settings.",
    artInfo:
      "This will be displayed on exported documents. You can change these details later in your account settings.",
    activatePaymentInvites:
      "Activating Payment Invites with simplify.art will take about 10 minutes of your time.",
    paymentInviteInfo:
      "Selling with simplify.art is a safe and secure way to sell your artwork. By keeping bank details and sensitive sale details out of emails you and your buyer are protected against scams.",
    editingPriceInfo: 
    "Editing the price here will be automatically updated in your archive.",
    paymentInviteGenerated: "Your Payment Invite has been generated",
    paymentInviteEmailConfirmationUser:"You will receive an email confirmation with your invoice shortly.",
    paymentInviteEmailConfirmationNonUser: "You will receive an email confirmation with your invoice shortly. Copy the link below and save it in a safe place to check on the status of your purchase. (The email confirmation will also contain this link.)",
    addPurchasedArtworksNonUser:"You can now start an archive with simplify.art for free and add your newly purchased artworks:",
    addPurchasedArtworksUser:"You can now add your newly purchased artworks to your archive:",
    cancelPaymentInviteInfo:"This will cancel the Payment Invite and you will not be able to view it again. If you wish to go ahead with rejecting the Payment Invite, you can leave a message for the seller ",
    providePayoutDetails: "Provide the details for where you would like to receive your payouts (the money you earn from sales).",
    idVerificationFailed: "The verification of your identity was unsuccessful. Someone from simplify.art will be in contact shortly to help resolve the issue.",
    successfullyOnboarded: "You have been successfully onboarded. You can now create Payment Invites.",
    startSelling: "You can now start selling your artworks.",
    confirmPaymentInviteInTransit: "Once you confirm, the buyer will be notified. Please provide a tracking number for the delivery, this will also be passed on to the buyer.",
    publicWorkDescriptionExplanation: "This text will be displayed in Portfolios, Discovery and Viewing Rooms. You can use it to inform any potential buyers about the artwork.",
    publicPriceExplanation: (processingFee) => processingFee === 7 ? `The Public Price is used to calculate the Total Public Price, which is visible to potential buyers in Discovery and Viewing Rooms. It can be a different amount to the Internal Price. For example you could add ${processingFee}% to cover our transaction fee. ` : `The Public Price is used to calculate the Total Public Price, which is visible to potential buyers in Discovery and Viewing Rooms. It can be a different amount to the Internal Price. For example you could add ${processingFee}% to cover our transaction fee. (By upgrading you can reduce this fee to 7%. `,
    totalPublicPriceExplanation: (processingFee) => processingFee === 7 ? `The Total Public Price is the amount that will be visible to potential buyers in Discovery and Viewing Rooms. It is a sum of the Public Price that you set, the extra fees you add and our transaction fee of ${processingFee}%.` : `The Total Public Price is the amount that will be visible to potential buyers in Discovery and Viewing Rooms. It is a sum of the Public Price that you set, the extra fees you add and our transaction fee of ${processingFee}%. (By upgrading you can reduce this fee to 7%. `,
    publicLocationExplanation: "The Public Location tells potential buyers where the artwork ships from and will be displayed in Discovery and Viewing Rooms.",
    publishToDiscoveryExplanation: "We will be launching an updated Discovery area later this year where you can sell and buy artwork. Artworks that you currently have saved in Discovery will be moved to Favorites in Discovery.",
    discoveryDesc: "Discovery is a place to find, buy and sell art online. Publishing your artwork to Discovery will allow it to be seen by collectors and galleries.",
    discountCodeInvalid: "The discount code you submitted is not valid",
    additionalTextExplanation: "Additional Text explanation",
    discoveryFavourites: "Artworks saved as Favourites can be found here. If you had already saved artworks to Discovery prior to September 2023, they can now be found in Favourites.",
    paymentSuccessful:"You will receive an email confirmation with your invoice shortly.",
    discoveryForCollectors: "Collectors are not able to publish to Discovery. If you are a collector who wishes to publish artworks to Discovery, please contact us.",
    discoveryForAvailableArtworks: "Only available artworks can be published to Discovery. Please update the status of the artwork if you want to publish it to Discovery.",
    copyOfOfferEmail: "You will receive a copy of the offer you made via email. You will also receive an email notification once the seller has responded.",
    paymentInviteOffer: "The amount you enter includes our processing fee, VAT and any other costs except for shipping costs that may be added later before payment depending on the transport mode you select.",
    paymentInviteOfferDetails: "You can see the details of the offer below. You can choose whether you want to accept or reject this offer. An email will be sent with your response.",
    copyOfOEnquiryEmail: "You will receive a copy of the message you sent via email. You will also receive an email notification once the seller has responded.",
    acceptOffer: (contactFirstName, contactLastName, artworkTitle) => `You are about to accept an offer made to you by ${contactFirstName} ${contactLastName} for your artwork titled ${artworkTitle}. Are you sure you want to proceed?`,
    rejectOffer: (contactFirstName, contactLastName, artworkTitle) => `You are about to reject an offer made to you by ${contactFirstName} ${contactLastName} for your artwork titled ${artworkTitle}. Are you sure you want to proceed?`,
    enquiryReceived: (contactFirstName, contactLastName, date, artworkTitle) => `${contactFirstName} ${contactLastName} sent the following enquiry on ${date} regarding your artwork titled ${artworkTitle}:`,
    convelioGuidelines: "You can find more info about Convelio's packing guidelines "
  },
});

export const Warnings = new LocalizedStrings({
  en: {
    cancelSubscription: "Are you sure you want to cancel your subscription?",
    cancelSubscriptionDesc: "You can continue to use All-Access until the end of your current billing period. After that you will only have access to free features, including your archive.",
    deleteAccount: "Deleting your account results in all data lost forever.",
    missingSelectedArtworkPrices: "Some artworks selected do not have a price",
    missingSelectedArtworkInsuranceValues: "Some of the selected artworks do not have an insurance value",
    logoutAlertTitle: "Confirm sign out",
    logoutAlertDescription: "You have unsaved changes that will be lost. Connect to internet to save them.",
    popupWarning: "This action cannot be undone.",
    artistDeletionDenied: "This artist can't be deleted because some artworks in your archive are connected to this artist. Please delete the artworks first.",
    noPublicPrice: "The marked artworks are missing a price. Go to the relevant Artwork Details page and update the Public Price field.",
  },
});

export const Notifications = new LocalizedStrings({
  en: {
    generic: { text: "Something went wrong. Please try again later" },
    accountCreatedSuccessfully: { text: "Account created successfully" },
    syncActionOffline: { text: "This action will be synced when back online" },
    exportSuccess: { text: "Export selected artworks successfully" },
    exportFailed: { text: "Could not export selected artworks" },
    createViewingRoomFailed: { text: "Could not create viewing room with selected artworks" },
    offlineQueued: { text: "Something went wrong. Will try again automatically when connection is re-established" },
    passwordResetCodeSent: { text: "Your password reset code has been sent to your email" },
    passwordChangedSuccessfully: { text: "Password changed successfully" },
    passwordResetFailed: { text: "Something went wrong. Could not update your password" },
    accountDetailsUpdateSuccess: { text: "Account details updated successfully" },
    accountDetailsUpdateFailed: { text: "Failed to update account details" },
    organisationDetailsUpdateSuccess: { text: "Your organisation details have updated successfully" },
    organisationDetailsUpdateFailed: { text: "Failed to update organisation details" },
    organisationLogoUploadFailed: { text: "Failed to upload organisation logo" },
    subscriptionSuccessful: { text: "Payment successful! Your subscription has activated" },
    creditCardUpdateSuccessful: { text: "Credit card updated successfully!" },
    subscriptionFailed: { text: "Payment failed. Please try again or email us at contact@simplify.art for support" },
    creditCardUpdateFailed: {
      text: "Could not update your credit card. Please try again or email us at contact@simplify.art for support",
    },
    subscriptionCancelSuccess: {
      text: "Your subscription has been cancelled. You will continue to have access until your current billing period ends",
    },
    subscriptionCancelFailed: {
      text: "Could not cancel your subscription. Try again or email us on contact@simplify.art for support",
    },
    subscriptionChangeSuccess: {
      text: "Your subscription has been changed.",
    },
    subscriptionChangeFailed: {
      text: "Could not change your subscription. Try again or email us on contact@simplify.art for support",
    },
    subscriptionChangeFailedDueToCard: {
      text: "Could not change your subscription. Someone from simplify.art will be in contact shortly to help resolve the issue.",
    },
    subscriptionRestartSuccess: {
      text: "Your subscription has been restarted.",
    },
    subscriptionRestartFailed: {
      text: "Could not restart your subscription. Try again or email us on contact@simplify.art for support",
    },
    changeBillingCardFailed: {
      text: "Could not change billing card. Try again or email us on contact@simplify.art for support",
    },
    changeBillingCardSuccess: {
      text: "Billing card changed successful",
    },
    paymentMethodInvalid: {
      text: "Failed proccessing payment method. Please try a different card or email contact@simplify.art for support",
    },
    failedDropboxUpload: { text: "Could not add dropbox image. Please try again" },
    shareWhenSynced: { text: "Artwork not completely synced. It will be shared when uploads are finished" },
    shareSuccess: { text: "Artwork shared successfully" },
    shareFailure: { text: "Artwork shared failed" },
    saveShareArtworkSuccess: { text: "Saving shared artwork successfully" },
    saveShareArtworkFailure: { text: "Saving shared artwork failed" },
    saveAndCopyArtworkSuccess: { text: "Save and Copy artwork successfully" },
    saveAndCopyArtworkFailure: { text: "Save and Copy artwork failed" },
    saveAndNewArtworkSuccess: { text: "Save and New artwork successfully" },
    saveAndNewArtworkFailure: { text: "Save and New artwork failed" },
    multiEditArtworksSuccess: { text: "Multi Edit artworks successfully" },
    multiEditArtworksFailure: { text: "Multi Edit artworks failed" },
    upgradeSuccessful:{text: "You have upgraded to simplify.art All-Access!"},
    upgradeUnsuccessful: {text: "Unfortunately, the payment wasn’t successful and the upgrade process hasn’t been completed."},
    messageSentSuccess: {text: "Your message has been sent."},
    messageSentFailure: {text: "Message not sent. Please try again."},
    betaRequestPublicSuccess: {text: "Your request to access this feature has been submitted. We will be in contact soon."},
    betaRequestSuccess: {text: "Your request to access this feature has been submitted."},
    invitePaymentSuccess: {text: "Payment successful!"},
    invitePaymentFailure: {text: "Payment failed. Please try again later."},
    activationsCompleted: {text: "Activation completed!"},
    reminderSentSuccessfully: {text: "Reminder sent!"},
    updateSuccessful: {text: "Updated successfully!"},
    invoiceDownloadSuccess: {text: "Invoice downloaded successfully!"},
    deleteArtistFailure: {text: "Artist could not be deleted. Please try again later"},
    disputeSuccessful: {text: "Dispute created successfully"},
    TwoFAFailure: {text: "We are unable to authenticate your payment method. Please choose a different payment method and try again."},
    contactUpdateSuccess: {text: "Contact updated successfully"},
    listUpdateSuccess: {text: "List updated successfully"},
    linkCopied: {text: "Link copied!"},
    missingFormValues: {text: "Some information is missing. Please check the form for any missing fields"},
    noPasswordMatch: {text: "Invalid password"},
    discoveryLoadingError: {text: "There was an error loading Discovery. Please try again later."},
    discoveryFavouritesError: {text: "Could not load your Favourites. Please try again later."},
    artistUpdateSuccess: {text: "Artist updated successfully"},
    paymentInviteCreationFailure: {text: "Your Payment Invite could not be created. Please try again later"},
    paymentInvitesDownloadError: {text: "There was an error while trying to get your Payment Invites. Please try again later."},
    templateDownloadSuccess: {text: "Template downloaded successfully!"},
    profileCreated: {text: "Profile created successfully"},
    exportArchiveRequestSuccess: {text: "Your request to export your archive has been successfully submitted!"},
  },
});

export const DynamicNotifications = new LocalizedStrings({
  en: {
    artworkSyncMaxRetry: (title) => ({ text: `${title} sync failed. Click upload on the artwork to sync manually` }),
  },
});

export const MaxRetryNotifications = new LocalizedStrings({
  en: {},
});
