import { STRIPE_PLANS } from "../config";
import { ROLE_ARTIST, ROLE_ART_INSTITUTION, ROLE_ART_PROFESSIONAL, ROLE_COLLECTOR, ROLE_GALLERIST } from "../constants";

export const returnSelectedPlan = (plan) => {
  switch (plan) {
    case STRIPE_PLANS.artist_monthly.plan:
      return STRIPE_PLANS.artist_monthly;
    case STRIPE_PLANS.artist_yearly.plan:
      return STRIPE_PLANS.artist_yearly;
    case STRIPE_PLANS.collector_monthly.plan:
      return STRIPE_PLANS.collector_monthly;
    case STRIPE_PLANS.collector_yearly.plan:
      return STRIPE_PLANS.collector_yearly;
    case STRIPE_PLANS.gallerist_monthly.plan:
      return STRIPE_PLANS.gallerist_monthly;
    case STRIPE_PLANS.gallerist_yearly.plan:
      return STRIPE_PLANS.gallerist_yearly;
    case STRIPE_PLANS.art_professional_monthly.plan:
      return STRIPE_PLANS.art_professional_monthly;
    case STRIPE_PLANS.art_professional_yearly.plan:
      return STRIPE_PLANS.art_professional_yearly;
    case STRIPE_PLANS.art_institution_monthly.plan:
      return STRIPE_PLANS.art_institution_monthly;
    case STRIPE_PLANS.art_institution_yearly.plan:
      return STRIPE_PLANS.art_institution_yearly;
    default:
      return {
        plan: "",
        price: 0,
        subtotal: 0,
        vat: 0,
        discount: 0,
        label: "",
        billing_period: "",
        mr: "",
      };
  }
};

export const returnStripePlansBasedOnUserRole = (role) => {
  switch (role) {
    case ROLE_ARTIST:
      return {
        [STRIPE_PLANS.artist_yearly.plan]: STRIPE_PLANS.artist_yearly,
        [STRIPE_PLANS.artist_monthly.plan]: STRIPE_PLANS.artist_monthly,
      };
    case ROLE_COLLECTOR:
      return {
        [STRIPE_PLANS.collector_yearly.plan]: STRIPE_PLANS.collector_yearly,
        [STRIPE_PLANS.collector_monthly.plan]: STRIPE_PLANS.collector_monthly,
      };
    case ROLE_GALLERIST:
      return {
        [STRIPE_PLANS.gallerist_yearly.plan]: STRIPE_PLANS.gallerist_yearly,
        [STRIPE_PLANS.gallerist_monthly.plan]: STRIPE_PLANS.gallerist_monthly,
      };
    case ROLE_ART_PROFESSIONAL:
      return {
        [STRIPE_PLANS.art_professional_yearly.plan]: STRIPE_PLANS.art_professional_yearly,
        [STRIPE_PLANS.art_professional_monthly.plan]: STRIPE_PLANS.art_professional_monthly,
      };
    case ROLE_ART_INSTITUTION:
      return {
        [STRIPE_PLANS.art_institution_yearly.plan]: STRIPE_PLANS.art_institution_yearly,
        [STRIPE_PLANS.art_institution_monthly.plan]: STRIPE_PLANS.art_institution_monthly,
      };

    default:
      break;
  }
};

export const returnUpdatedStripePlansBasedOnUserRole = (role, billingPeriod) => {
  switch (role) {
    case ROLE_ARTIST:
      if (billingPeriod === "yearly") {
        return {
          [STRIPE_PLANS.artist_free.plan]: STRIPE_PLANS.artist_free,
          [STRIPE_PLANS.artist_yearly.plan]: STRIPE_PLANS.artist_yearly,
        };
      } else {
        return {
          [STRIPE_PLANS.artist_free.plan]: STRIPE_PLANS.artist_free,
          [STRIPE_PLANS.artist_monthly.plan]: STRIPE_PLANS.artist_monthly,
        };
      }
    case ROLE_COLLECTOR:
      if (billingPeriod === "yearly") {
        return {
          [STRIPE_PLANS.collector_free.plan]: STRIPE_PLANS.collector_free,
          [STRIPE_PLANS.collector_yearly.plan]: STRIPE_PLANS.collector_yearly,
        };
      } else {
        return {
          [STRIPE_PLANS.collector_free.plan]: STRIPE_PLANS.collector_free,
          [STRIPE_PLANS.collector_monthly.plan]: STRIPE_PLANS.collector_monthly,
        };
      }
    case ROLE_GALLERIST:
      if (billingPeriod === "yearly") {
        return {
          [STRIPE_PLANS.gallerist_free.plan]: STRIPE_PLANS.gallerist_free,
          [STRIPE_PLANS.gallerist_yearly.plan]: STRIPE_PLANS.gallerist_yearly,
        };
      } else {
        return {
          [STRIPE_PLANS.gallerist_free.plan]: STRIPE_PLANS.gallerist_free,
          [STRIPE_PLANS.gallerist_monthly.plan]: STRIPE_PLANS.gallerist_monthly,
        };
      }
    case ROLE_ART_PROFESSIONAL:
      if (billingPeriod === "yearly") {
        return {
          [STRIPE_PLANS.art_professional_free.plan]: STRIPE_PLANS.art_professional_free,
          [STRIPE_PLANS.art_professional_yearly.plan]: STRIPE_PLANS.art_professional_yearly,
        };
      } else {
        return {
          [STRIPE_PLANS.art_professional_free.plan]: STRIPE_PLANS.art_professional_free,
          [STRIPE_PLANS.art_professional_monthly.plan]: STRIPE_PLANS.art_professional_monthly,
        };
      }
    case ROLE_ART_INSTITUTION:
      if (billingPeriod === "yearly") {
        return {
          [STRIPE_PLANS.art_institution_free.plan]: STRIPE_PLANS.art_institution_free,
          [STRIPE_PLANS.art_institution_yearly.plan]: STRIPE_PLANS.art_institution_yearly,
        };
      } else {
        return {
          [STRIPE_PLANS.art_institution_free.plan]: STRIPE_PLANS.art_institution_free,
          [STRIPE_PLANS.art_institution_monthly.plan]: STRIPE_PLANS.art_institution_monthly,
        };
      }
    default:
      break;
  }
};
