import { Box, Text } from "../fundamentals";
import { Headings } from "../../localisation";
import { SharingDropdown } from "../modules/SharingDropdown";

export const ActivitySharedArtworksScreen = ({headerTextStyle}) => {
    return (
        <Box>
            <Text style={{ ...headerTextStyle, fontSize: "26px" }} mb="xxxl">{Headings.sharedArtworks}</Text>
            <SharingDropdown />
        </Box>
    )
};