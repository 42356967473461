import React, { useState } from "react";
import { Flex, Box, Text, Icon } from "../../fundamentals";
import { UiLabels, InputLabels, ButtonLabels, Info, InputErrors } from "../../../localisation";
import { useIsScreenMobile } from "../../hooks/useMediaQuery";
import { updatePaymentInviteRequest } from "../../../store/actions";
import { Button, Input, PriceInput } from "../../elements";
import { Field, reduxForm } from "redux-form";
import { Popover } from "../../react-material/Popover";

const Form = ({ handleSubmit, submitting, paymentInviteId, isMarketPlace, placeId }) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const isMobile = useIsScreenMobile();

  const submit = (values, dispatch) => {
    return dispatch(
      updatePaymentInviteRequest({
        paymentInviteId,
        placeId,
        isMarketPlace,
        isOffer: true,
        status: "Offer",
        ...values,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box fontSize="small" mb="m">
        <Text color="blue.60" fontWeight="500" mb="xs">
          {UiLabels.contact}
        </Text>
        <Flex flexDirection={isMobile ? "column" : "row"}>
          <Field
            flex="1"
            minHeight="50px"
            name="contactFirstName"
            placeholder={InputLabels.firstName}
            component={Input}
            mr={!isMobile && "xs"}
            required
          />
          <Field
            flex="1"
            minHeight="50px"
            name="contactLastName"
            placeholder={InputLabels.lastName}
            component={Input}
            required
          />
        </Flex>
        <Field name="contactEmail" placeholder={InputLabels.emailText} component={Input} minHeight="50px" required />
        <Field name="contactPhone" placeholder={InputLabels.phone} component={Input} minHeight="50px" required />
      </Box>
      <Box fontSize="small" mb="m">
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="blue.60" fontWeight="500" mb="xs">
            {UiLabels.offer}
          </Text>
          <Icon
            icon="HelpCircle"
            size="16"
            color="grey.90"
            cursor="pointer"
            onClick={(e) => setPopoverAnchorEl(e.currentTarget)}
          />
          <Popover
            popoverAnchorEl={popoverAnchorEl}
            setPopoverAnchorEl={setPopoverAnchorEl}
            text={Info.paymentInviteOffer}
            icon="Info"
          />
        </Flex>
        <PriceInput
          name="offeredAmount"
          placeholder={InputLabels.amount}
          required
          minHeight="50px"
          currencyInputStyling={{ minHeight: "50px" }}
          valueInputStyling={{ minHeight: "50px" }}
          form="payment_invite.update"
          disabledCurrency={true}
        />
      </Box>
      <Button
        type="submit"
        buttonStyle="primary"
        size="medium"
        fontWeight="500"
        disabled={submitting}
        icon={submitting ? "Loader" : null}
        iconSpinning={true}
        iconOffset="xs"
      >
        {ButtonLabels.sendOffer}
      </Button>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  const { contactFirstName, contactLastName, contactEmail, contactPhone, offeredAmount } = values;

  if (!contactFirstName) {
    errors.contactFirstName = InputErrors.noFirstName;
  }
  if (!contactLastName) {
    errors.contactLastName = InputErrors.noLastName;
  }

  if (!contactEmail) {
    errors.contactEmail = InputErrors.noEmail;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(contactEmail)) {
    errors.contactEmail = InputErrors.invalidEmail;
  }
  if (!contactPhone) {
    errors.contactPhone = InputErrors.noPhoneNumber;
  } else if (!contactPhone.startsWith("+")) {
    errors.contactPhone = InputErrors.invalidPhoneNumber;
  }
  if (!offeredAmount?.value) {
    errors.offeredAmount = { value: InputErrors.noOfferedAmount };
  }

  return errors;
};

const BuyerOfferForm = reduxForm({
  form: "payment_invite.update",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

export { BuyerOfferForm };
