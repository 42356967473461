import produce from "immer";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { 
  CREATE_ONBOARDING_LAYOUT_REQUEST, 
  CREATE_ONBOARDING_LAYOUT_SUCCESS,
  CREATE_MARKETPLACE_ACCOUNT_REQUEST,
  CREATE_MARKETPLACE_ACCOUNT_SUCCESS,
  SIGN_OUT_SUCCESS,
  UPDATE_CARD_ACCOUNT_REQUEST,
  UPDATE_CARD_ACCOUNT_SUCCESS,
} from "../actions/types";

const MARKETPLACE_INITIAL_STATE = {
  isLoading: false,
  loaded: false,
  onboarding: null
};

const marketplaceReducer = (state = MARKETPLACE_INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case CREATE_ONBOARDING_LAYOUT_REQUEST:
        return createOnboardingLayoutRequest(draftState, action);
      case CREATE_ONBOARDING_LAYOUT_SUCCESS:
        return createOnboardingLayoutSuccess(draftState, action);
      case CREATE_MARKETPLACE_ACCOUNT_REQUEST:
        return createMarketplaceAccountRequest(draftState, action);
      case CREATE_MARKETPLACE_ACCOUNT_SUCCESS:
        return createMarketplaceAccountSuccess(draftState, action);
      case SIGN_OUT_SUCCESS:
        return MARKETPLACE_INITIAL_STATE;
      //case UPDATE_CARD_ACCOUNT_REQUEST:
      //  return updateCardAccountRequest(draftState, action);
      case UPDATE_CARD_ACCOUNT_SUCCESS:
        return updateCardAccountSuccess(draftState, action);
      default:
        return state;
    }
  });
};

const createOnboardingLayoutRequest = (draftState, action) => {
  return {
    ...draftState,
    isLoading : true,
    loaded: false,
    onboarding : {
      ...draftState.onboarding,
      country: action.payload.country,
      businessType: action.payload.businessType
    }
  }
};

const createOnboardingLayoutSuccess = (draftState, action) => {
  return {
    ...draftState,
    isLoading: false,
    loaded: true,
    onboarding : {
      ...draftState.onboarding,
      fieldsRequired: action.payload
    }
  }
};

const createMarketplaceAccountRequest = (draftState, action) => {
  return {
    ...draftState,
    isLoading : true,
    loaded: false,
  }
};

const createMarketplaceAccountSuccess = (draftState, action) => {
  return {
    ...draftState,
    isLoading: false,
    loaded: true,
  }
};

/*const updateCardAccountRequest = (draftState, action) => {
  return {
    ...draftState,
    isLoading : true,
    loaded: false,
  }
};*/

const updateCardAccountSuccess = (draftState, action) => {
  return {
    ...draftState,
    isLoading: false,
    loaded: true,
  }
};


export default marketplaceReducer;
